import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import type { Account, AccountRoyalty, AdminFee } from '@greco/finance-accounts';
import { ReconciliationConfig } from '@greco/finance-payments';
import type { AddRoyaltiesDto, CreateAccountDto, UpdateAccountDto } from '@greco/nestjs-finance-accounts';
import { UpdateReconciliationConfigDto } from '@greco/nestjs-finance-payments';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(private http: HttpClient) {}

  async paginateAccounts(query: RequestQueryBuilder, params: PaginatedQueryParams): Promise<PaginatedDto<Account>> {
    return await toPromise(
      this.http.get<PaginatedDto<Account>>('/api/accounts', {
        params: {
          ...query.queryObject,
          ...(params?.page ? { page: params.page?.toString() } : {}),
          ...(params?.limit ? { limit: params.limit?.toString() } : {}),
        },
      })
    );
  }

  async createAccount(dto: CreateAccountDto): Promise<Account> {
    return await toPromise(this.http.post<Account>('/api/accounts', { ...dto }));
  }

  async updateAccount(accountId: string, dto: UpdateAccountDto): Promise<Account> {
    return await toPromise(this.http.post<Account>(`/api/accounts/${accountId}`, { ...dto }));
  }

  async getAccount(accountId: string): Promise<Account | undefined> {
    return await toPromise(this.http.get<Account>(`/api/accounts/${accountId}`)).catch(() => undefined);
  }

  // @Get('with-access/:actionId')
  async accountsWithAccess(resourceId: string, actionId: string): Promise<Account[]> {
    return await toPromise(this.http.get<Account[]>(`/api/accounts/with-access/${resourceId}/${actionId}`));
  }

  // @Get('/:accountId/with-royalties')
  async getAccountWithRoyalties(accountId: string): Promise<Account> {
    return await toPromise(this.http.get<Account>(`/api/accounts/${accountId}/with-royalties`));
  }

  async getAllAccounts(): Promise<Account[]> {
    const accounts: Account[] = [];
    let total = 0,
      page = 1;

    do {
      const data = await this.paginateAccounts(new RequestQueryBuilder(), { limit: 100, page: page++ });
      accounts.push(...data.items);
      total = data.meta.totalItems;
    } while (accounts.length < total);

    return accounts;
  }

  async addRoyalties(accountId: string, dto: AddRoyaltiesDto): Promise<AccountRoyalty> {
    return await toPromise(this.http.post<AccountRoyalty>(`/api/accounts/${accountId}/royalties`, dto));
  }

  async getRoyalties(accountId: string): Promise<AccountRoyalty[]> {
    return await toPromise(this.http.get<AccountRoyalty[]>(`/api/accounts/${accountId}/royalties`));
  }

  async removeRoyalty(accountId: string, royaltyId: string): Promise<void> {
    await toPromise(this.http.delete(`/api/accounts/${accountId}/royalties/${royaltyId}`));
  }

  async updateReconciliationConfig(accountId: string, dto: UpdateReconciliationConfigDto) {
    return toPromise(this.http.post<ReconciliationConfig>(`/api/reconciliation-config/${accountId}`, dto));
  }

  async getReconciliationConfig(accountId: string) {
    return toPromise(this.http.get<ReconciliationConfig>(`/api/reconciliation-config/${accountId}`));
  }

  async getAdminFee(accountId: string) {
    return toPromise(this.http.get<AdminFee | null>(`/api/account-admin-fee/${accountId}`));
  }

  async updateAdminFee(
    accountId: string,
    dto: { label?: string; description?: string; amount?: number; enabled?: boolean }
  ) {
    return toPromise(this.http.post<AdminFee>(`/api/account-admin-fee/${accountId}`, dto));
  }
}
