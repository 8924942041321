import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserCommunityAgreement } from '@greco/community-agreements';
import { UserCommunityAgreementsService } from '../../services';

@Component({
  selector: 'greco-archive-agreement',
  templateUrl: './archive-agreement.dialog.html',
  styleUrls: ['./archive-agreement.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ArchiveAgreementDialog {
  constructor(
    private snacks: MatSnackBar,
    private agreementService: UserCommunityAgreementsService,
    @Inject(MAT_DIALOG_DATA) public data: { agreement: UserCommunityAgreement; restore: boolean }
  ) {}

  getResult = async () => {
    try {
      if (this.data.restore) {
        const agreement = await this.agreementService.restoreAgreement(this.data.agreement.id);

        if (!agreement.archivedOn) {
          this.snacks.open(agreement.signedAgreementTitle + ' has been restored!', 'Ok', {
            duration: 3000,
            panelClass: 'mat-primary',
          });
        }

        return agreement;
      } else {
        const agreement = await this.agreementService.archiveAgreement(this.data.agreement.id);

        if (agreement.archivedOn) {
          this.snacks.open(agreement.signedAgreementTitle + ' has been archived!', 'Ok', {
            duration: 3000,
            panelClass: 'mat-primary',
          });
        }

        return agreement;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };
}
