<div style="display: flex; flex-direction: column; gap: 16px">
  <greco-collapsible-section
    *ngIf="eventVideo"
    [header]="{ icon: 'ondemand_video', title: eventVideo.communityVideo?.video?.title || '' }"
    [disabled]="true"
  >
    <div style="display: flex; gap: 16px; flex-wrap: wrap">
      <greco-box
        style="flex: 1; height: 100%; max-height: 100px; position: relative; display: flex"
        matTooltip="{{ eventVideo.communityVideo?.video?.description }}"
      >
        <img
          *ngIf="eventVideo.communityVideo && eventVideo.communityVideo.video"
          [src]="eventVideo.communityVideo.video.thumbnailUrl || '/assets/lf3/logo.png' | safe: 'resourceUrl'"
          alt="Thumbnail"
          style="width: 120px; aspect-ratio: 16 9; max-height: 100px; object-fit: cover; border-radius: 4px"
        />

        <div style="margin-left: 12px" *ngIf="eventVideo.communityVideo && eventVideo.communityVideo.video">
          <h3 style="margin-bottom: 12px">
            <strong>{{ eventVideo.communityVideo.video.title }}</strong>
          </h3>
          <mat-chip-list>
            <greco-video-tag
              [admin]="true"
              *ngFor="let tag of eventVideo.communityVideo.video.tags"
              [tag]="tag"
              [community]="eventVideo.communityVideo.community || undefined"
            ></greco-video-tag>
          </mat-chip-list>
        </div>
      </greco-box>

      <div style="flex: 2">
        <ng-container *ngIf="eventVideo.event as event">
          <h3 style="margin: 0">Recorded during event:</h3>
          <div
            style="
              padding: 12px 8px;
              gap: 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
            "
            [style.border-bottom]="''"
          >
            <div style="display: flex; gap: 12px">
              <greco-multi-avatar [size]="48" [images]="event.resources | pluck: 'photoURL'"></greco-multi-avatar>
              <div style="display: flex; flex-direction: column; justify-content: space-evenly">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
                    >place</mat-icon
                  >
                  <strong>{{ event.community.name }}</strong>
                </div>
                <h3 style="margin: 0">{{ event.title }}</h3>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
                >event</mat-icon
              >
              <strong>{{ event.startDate | date }} - {{ event.duration }}mins</strong>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </greco-collapsible-section>
  <greco-collapsible-section id="unlocks_section" [header]="{ title: 'Unlocks', icon: 'lock_open' }" [disabled]="true">
    <button
      header
      mat-flat-button
      color="primary"
      style="margin-right: 8px"
      matTooltip="Create a new replay unlock"
      (click)="$event.stopImmediatePropagation(); grant()"
      *ngIf="canGrant$ | async"
    >
      Grant
    </button>
    <div style="display: flex; flex-direction: row; width: 100%; padding-top: 1px">
      <greco-filter-bar
        style="width: 100%"
        [hideSeparator]="true"
        [filterOptions]="filterOptions"
        (changed)="filters$.next($event[1])"
      >
      </greco-filter-bar>
      <button
        header
        mat-stroked-button
        color="primary"
        style="margin-right: 8px; height: 32px"
        matTooltip="Show/Hide Expired Unlocks"
        (click)="toggleExpired()"
      >
        {{ statusFilter ? 'Hide Expired' : 'Show Expired' }}
      </button>
    </div>
    <greco-table [highlight]="true" [data]=" (eventVideoUnlocks$ | async) || []">
      <mat-icon *grecoTableCol="''; fitContent: true; let unlock" [matTooltip]="unlock.id">
        {{ unlock.expiresOn && unlock.expiresOn.getTime() < now ? 'lock' : 'lock_open' }}
      </mat-icon>

      <!-- User -->
      <ng-container>
        <a
          *grecoTableCol="'User'; let unlock"
          [routerLink]="['/admin/community', communityId, unlock.user?.id]"
          [matTooltip]="unlock.user?.contactEmail"
          (click)="$event.stopImmediatePropagation()"
          >{{unlock.user.displayName}}</a
        >
      </ng-container>
      <!-- Unlocked On -->
      <ng-container>
        <p *grecoTableCol="'Unlocked On'; let unlock" [matTooltip]="'Date user unlocked'">
          {{ unlock.created | date }}
        </p>
      </ng-container>
      <!-- Expires -->
      <ng-container>
        <p *grecoTableCol="'Expires On';  let unlock" [matTooltip]="'Date unlock will expire for user'">
          {{ unlock.expiresOn ? (unlock.expiresOn | date) : 'Never' }}
        </p>
      </ng-container>
      <!-- Unlocked With -->
      <ng-container>
        <p *grecoTableCol="'Unlocked With'; let unlock">
          <greco-perk-badge
            *ngIf="unlock.unlockOption"
            [small]="true"
            [icon]="unlock.unlockOption.badge.icon"
            [color]="unlock.unlockOption.badge.color"
            [matTooltip]="unlock.unlockOption.title"
          ></greco-perk-badge>
          <a *ngIf="!unlock.unlockOption">-</a>
        </p>
      </ng-container>
      <!-- Status -->
      <ng-container>
        <p
          *grecoTableCol="'Status'; fitContent: true; let unlock"
          [matTooltip]="unlock.expiresOn && unlock.expiresOn < now ? 'Unlock is expired' : 'Unlock is active'"
        >
          <mat-chip [class.active]="!unlock.expiresOn || unlock.expiresOn > now"
            >{{ unlock.expiresOn && unlock.expiresOn < now ? 'Expired' : 'Unlocked' }}</mat-chip
          >
        </p>
      </ng-container>

      <!-- Options -->
      <ng-container>
        <ng-container *grecoTableCol="''; let unlock; fitContent: true; stickyEnd: true">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matTooltip]="'Edit unlock details'"
            (click)="$event.stopImmediatePropagation(); "
            *ngIf="canUpdate$ | async"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <!-- Manage Unlocks -->
            <button mat-menu-item (click)="update(unlock)" *ngIf="canUpdate$ | async">
              <!--TODO: unlock security-->
              <mat-icon>schedule</mat-icon>
              <span>Manage Expiry Date</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </greco-table>
    <mat-paginator
      *ngIf="pagination?.totalItems"
      showFirstLastButtons
      [length]="pagination!.totalItems || 0"
      [pageSize]="pagination!.itemsPerPage || 10"
      [pageSizeOptions]="[10, 20, 50]"
      (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
    ></mat-paginator>
  </greco-collapsible-section>
</div>
