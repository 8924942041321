import { Pipe, PipeTransform } from '@angular/core';
import { Booking } from '@greco/booking-events';

@Pipe({ name: 'inUndoWindow' })
export class InUndoWindow implements PipeTransform {
  transform(booking: Booking, hours = 24) {
    const timeInMilliseconds = 3600 * hours * 1000;

    const now = new Date();
    const timeAfterEvent = new Date(new Date(booking.event?.startDate).getTime() + timeInMilliseconds);

    return now < timeAfterEvent;
  }
}
