<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup">
    <section>
      <div style="display: flex; margin-top: -16px">
        <!-- Title -->
        <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
          <mat-label>Title</mat-label>
          <input matInput required formControlName="title" />
        </mat-form-field>
      </div>

      <!-- Description (Optional) -->
      <mat-form-field appearance="standard">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" rows="3"></textarea>
      </mat-form-field>
    </section>
  </form>
</greco-simple-dialog>
