<ng-container *ngIf="preview$ | async as preview; else loadingPage">
  <ng-container *grecoLet="variants$ | async as variants">
    <ng-container *grecoLet="inventories$ | async as inventories">
      <div
        *grecoLet="(community$ | async) as community"
        style="margin-bottom: 16px; display: flex; flex-direction: column; gap: 16px; width: 100%"
      >
        <greco-user-profile-details
          style="margin-bottom: -16px"
          *ngIf="preview && !preview?.purchase?.user?.address?.line1 && !$any(preview)?.subscription?.user?.address?.line1"
          [label]="'Missing Profile Information'"
          [danger]="true"
          [preventReload]="true"
          [user]="$any(preview?.purchase?.user) || $any(preview)?.subscription?.user"
        ></greco-user-profile-details>

        <greco-collapsible-section
          *grecoLet="sectionTitle$ | async as sectionTitle"
          id="items-section"
          style="margin-top: 16px"
          [header]="{ title: sectionTitle || 'Your Purchase', icon: 'receipt_long' }"
        >
          <greco-purchase-preview
            *ngIf="preview"
            [communityId]="community?.id || ''"
            [purchase]="$any(preview.purchase)"
            [inventories]="inventories?.inventories || null"
            [subscription]="$any(preview)?.subscription || null"
            [showPurchaseInfo]="false"
            [showHeader]="true"
            [showTotals]="true"
            (userChanged)="user = $event"
          ></greco-purchase-preview>

          <ng-container
            style="padding: 8px; align-self: end"
            *ngIf="(canIgnoreEnrolmentFee$ | async) && $any(preview)?.subscription "
          >
            <div style="padding: 8px; align-self: end">
              <small style="align-self: end; padding-right: 4px">Ignore initiation fee</small>
              <mat-checkbox
                color="primary"
                style="padding: 8px; align-self: end; padding-right: 4px"
                [checked]="false"
                [required]="true"
                [formControl]="ignoreEnrolmentFee"
              ></mat-checkbox>
            </div>
          </ng-container>

          <div *ngIf="user && purchasedBy && preview && preview.purchase" class="payment-method-container">
            <i
              *ngIf="paymentMethodControl.value && paymentMethodControl.value.type === 'bank'"
              style="position: absolute; transform: translateY(-2px)"
            >
              <small>* Selected payment method may result in a delay of purchase by 3 or more business days</small>
            </i>

            <mat-form-field appearance="standard" class="payment-method-picker-form-field">
              <i
                *ngIf="!paymentMethodControl.value"
                style="color: var(--warn-color); position: absolute; right: 0; transform: translateY(-2px)"
              >
                <small style="padding-left: 10px">Add a Payment Method!</small>
              </i>

              <greco-select-payment-method
                #selectPaymentMethodInput
                [required]="true"
                [userId]="purchasedBy.id"
                [allowTerminals]="allowTerminals"
                [formControl]="paymentMethodControl"
                [allowBankPayments]="allowBankPayments"
              ></greco-select-payment-method>

              <greco-add-payment-method-button
                matSuffix
                [onlyIcon]="true"
                [userId]="purchasedBy.id"
                (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
              ></greco-add-payment-method-button>
            </mat-form-field>
          </div>
        </greco-collapsible-section>
        <!-- Scheduling -->
        <ng-container *ngIf="$any(preview)?.subscription">
          <greco-collapsible-section
            *ngIf="canManageSchedule$ | async"
            class="scheduling"
            [expanded]="true"
            [header]="{ title: 'Subscription Scheduling', icon: 'calendar_today' }"
          >
            <mat-radio-group [formControl]="dateRadioButton" aria-label="Start Subscription: ">
              <mat-radio-button style="margin-left: 16px" color="primary" value="now">Now</mat-radio-button>
              <mat-radio-button style="margin-left: 16px" color="primary" value="future"
                >In the Future</mat-radio-button
              >
            </mat-radio-group>

            <mat-form-field *ngIf="dateRadioButton.value == 'future'" style="margin-top: 6px" appearance="fill">
              <mat-label>Choose a date</mat-label>
              <input
                matInput
                [min]="minimalDate"
                [max]="maximalDate"
                [matDatepicker]="picker"
                (click)="picker.open()"
                [formControl]="dateSelection"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </greco-collapsible-section>
        </ng-container>

        <!-- Agreements -->
        <div
          *ngIf="agreements$ | async as agreements"
          [formGroup]="agreementsForm"
          style="display: flex; flex-direction: column; gap: 16px; margin-bottom: 12px"
        >
          <ng-container *ngFor="let usage of agreements">
            <greco-collapsible-section
              #agreementEx
              [expanded]="false"
              [header]="{ title: usage.agreement?.title || ''}"
              (click)="$event.stopImmediatePropagation()"
            >
              <div style="padding: 0 8px">
                <span [innerHtml]="usage.agreement?.text || '' | safe:'html'"></span>
              </div>

              <div style="display: flex; justify-content: center">
                <div style="width: 98%; border-bottom: 1px solid darkgray"></div>
              </div>

              <!-- put checkbox/signature in header if not expanded -->
              <div *ngIf="!agreementEx.expanded" subheader style="display: flex; flex-direction: column">
                <mat-checkbox
                  *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
                  color="primary"
                  style="padding: 8px"
                  formControlName="{{ usage.id }}"
                  [required]="true"
                  [checked]="agreementsForm.get(usage.id)?.value"
                  (click)="setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()"
                >
                  <span style="white-space: normal"> I have read and agree to the terms of the agreement </span>
                </mat-checkbox>

                <mat-checkbox
                  *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
                  color="primary"
                  style="padding: 8px"
                  formControlName="{{ usage.id }}"
                  [required]="true"
                  [checked]="agreementsForm.get(usage.id)?.value"
                  (click)="setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()"
                >
                  <span style="white-space: normal"> I have read and agree to the terms of the agreement </span>
                </mat-checkbox>
                <ng-container *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'">
                  <div
                    *ngIf="!hideSignLater && !agreementsForm.get(usage.id)?.value"
                    style="color: var(--warn-color); margin-bottom: 16px"
                  >
                    * Signature Required
                  </div>
                  <i *ngIf="agreementsForm.get(usage.id)?.value">Signed</i>
                </ng-container>
              </div>

              <mat-checkbox
                *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
                color="primary"
                style="padding: 8px"
                formControlName="{{ usage.id }}"
                [required]="true"
                [checked]="agreementsForm.get(usage.id)?.value"
                (click)="setCheck(usage.id, usage.agreementId);  $event.preventDefault()"
              >
                <span style="white-space: normal"> I have read and agree to the terms of the agreement </span>
              </mat-checkbox>

              <mat-checkbox
                *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
                color="primary"
                style="padding: 8px"
                formControlName="{{ usage.id }}"
                [required]="true"
                [checked]="agreementsForm.get(usage.id)?.value"
                (click)="setCheck(usage.id, usage.agreementId); $event.preventDefault()"
              >
                <span style="white-space: normal"> I have read and agree to the terms of the agreement </span>
              </mat-checkbox>

              <greco-signature
                *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE' && initialUser"
                [user]="user"
                [signedBy]="initialUser"
                [forPurchase]="true"
                formControlName="{{ usage.id }}"
                ngDefaultControl
                (signatureChange)="setSignature($event, usage.id, usage.agreementId)"
              >
              </greco-signature>
            </greco-collapsible-section>

            <greco-alert
              *ngIf="user?.id !== initialUser?.id || (canContinueWithoutSigning$ | async)"
              [config]="{
                title: 'Signature Required',
                type: $any('WARN'),
                description: (user?.displayName || 'This member') + ' will have to sign this agreement before purchasing other items.'
              }"
            ></greco-alert>
          </ng-container>
        </div>

        <greco-alert
          *ngFor="let agreement of unsignedAgreements"
          [config]="{
           title: 'Your ' + agreement.signedAgreementTitle + ' needs to be signed',
           type: $any('DANGER'),
           description: 'You will need to sign the agreement before completing your booking'
          }"
        >
          <button
            mat-stroked-button
            slot="buttons"
            color="error"
            style="border-color: rgba(var(--warn-color-rgb), 0.3)"
            (click)="signAgreement(agreement)"
          >
            Sign
          </button>
        </greco-alert>

        <!-- User Finder -->
        <greco-user-finder
          [communityId]="community?.id || ''"
          (userSelected)="referredById = $event"
        ></greco-user-finder>

        <!-- Transferred From -->
        <greco-user-finder
          *ngIf="isStaff && $any(preview)?.subscription"
          mode="transfer"
          style="margin-top: -24px"
          [communityId]="community?.id || ''"
          (userSelected)="transferredFromId = $event"
        ></greco-user-finder>

        <!-- Warnings -->
        <div
          *ngIf="!(
        $any(preview)?.purchase.total === 0 ?
        (paymentMethodControl.value ||
          ($any(preview)?.subscription ?
            (canZeroSubscription$ | async) :
            (canZeroPurchase$ | async))) :
        paymentMethodControl.value)
      "
          id="error"
        >
          <mat-icon>warning</mat-icon>
          Missing payment method!
        </div>

        <div id="error" *ngIf="preview?.errors?.length">
          <mat-icon>warning</mat-icon>
          <span *ngIf="preview?.errors?.length" [innerHTML]="$any(preview).errors[0] | safe: 'html'"></span>
        </div>

        <div id="warning" *ngIf="preview?.warnings?.length">
          <mat-icon>warning</mat-icon>
          <span *ngIf="preview?.warnings?.length" [innerHTML]="$any(preview).warnings[0] | safe: 'html'"></span>
        </div>
      </div>

      <greco-box *ngIf="preview" class="footer">
        <ng-container *grecoLet="canSellRestrictedVariant$ | async as canSellRestrictedVariant">
          <ng-container *grecoLet="canSellHiddenVariant$ | async as canSellHiddenVariant">
            <div class="footer-content">
              <div style="margin-right: 16px">
                <span
                  *ngIf="!canSellHiddenVariant"
                  style="color: var(--warn-color); margin-right: 25px; position: relative"
                >
                  <i> Cannot Purchase Hidden Variant </i>
                  <mat-icon style="position: absolute; transform: translateY(-2px)">error_outline</mat-icon>
                </span>
                <span
                  *ngIf="!canSellRestrictedVariant"
                  style="color: var(--warn-color); margin-right: 25px; position: relative"
                >
                  <i> Cannot Purchase Restricted Variant </i>
                  <mat-icon style="position: absolute; transform: translateY(-2px)">error_outline</mat-icon>
                </span>

                <span *ngIf="preview.purchase">
                  <span *ngIf="!$any(preview).subscription">Total: </span>
                  <span *ngIf="$any(preview).subscription">First Payment Total: </span>
                  <strong>{{(preview.purchase.total || 0) / 100 | currency}}</strong>
                  <small *ngIf="preview.purchase?.balanceUsed">
                    (-{{((preview.purchase.balanceUsed || 0) / 100) | currency}} from balance)
                  </small>
                </span>

                <span *ngIf="$any(preview).subscription" style="margin-left: 16px">
                  Every {{$any(preview).subscription.recurrence | grecoRecurrence}}:
                  <strong>{{($any(preview).subscription.total || 0) / 100 | currency}}</strong>
                </span>
              </div>

              <ng-container *grecoLet="canZeroPurchase$ | async as canZeroPurchase">
                <ng-container *grecoLet="canZeroSubscription$ | async as canZeroSubscription">
                  <ng-container *grecoLet="userConditionContext$ | async">
                    <ng-container *grecoLet="availabilityExtension$ | async">
                      <div
                        *grecoLet="conditionCanBuy$ | async as conditionCanBuy"
                        [matTooltip]="conditionCanBuy?.result !== true ? (conditionCanBuy?.errors || [] | grecoJoin: ', ') : ''"
                      >
                        <button
                          mat-flat-button
                          color="primary"
                          [matTooltip]="!preview?.purchase?.user?.address?.line1 && !$any(preview)?.subscription?.user?.address?.line1 ? 'Missing Profile Information' : ''"
                          [disabled]="
                        !preview
                        || confirming
                        || !agreementsForm.valid
                        || !dateSelection.valid
                        || !canSellHiddenVariant
                        || !canSellRestrictedVariant
                        || !!preview.errors.length
                        || conditionCanBuy?.result !== true
                        || unsignedAgreements.length > 0
                        || (preview?.purchase?.total === 0 && !paymentMethodControl.value && !$any(preview).subscription && !canZeroPurchase)
                        || (preview?.purchase?.total === 0 && !paymentMethodControl.value && $any(preview).subscription && !canZeroSubscription)
                        || (!preview?.purchase?.user?.address?.line1 && !$any(preview)?.subscription?.user?.address?.line1 && user?.id === self?.id)
                        || (!paymentMethodControl.value && !$any(preview)?.subscription && ($any(preview)?.purchase.total > 0 || user?.id === self?.id))
                        || (inventories?.outOfStock && ((canSellOutOfStock$ | async) !== true))
                      "
                          (click)="confirmPurchase(preview, conditionCanBuy?.messages || null)"
                        >
                          <mat-icon *ngIf="!confirming && conditionCanBuy?.result !== true">lock</mat-icon>

                          <ng-container *ngIf="$any(preview).subscription; else elseTemplate">
                            {{ dateRadioButton.value === 'future' ? 'Schedule Subscription' : 'Start Subscription' }}
                          </ng-container>

                          <ng-template #elseTemplate
                            >{{ 'Confirm' + (confirming ? 'ing' : '') + ' Purchase' }}</ng-template
                          >

                          <mat-icon *ngIf="confirming" style="margin-left: 4px" class="spin">loop</mat-icon>
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </greco-box>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loadingPage>
  <div class="content-placeholder"></div>
  <br />
  <div class="content-placeholder"></div>
</ng-template>
