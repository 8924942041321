import { Injectable } from '@angular/core';
import { SelectFilter } from '@greco/ngx-filters';
import { CondOperator, SFields } from '@nestjsx/crud-request';

type ExpiredStatus = 'Expired';

@Injectable({ providedIn: 'any' })
export class ExpiredFilter extends SelectFilter {
  constructor() {
    super('ExpiredFilter', { description: '', label: 'Expired', shortLabel: 'Expired', properties: ['_'] });
  }

  private allStatus: ExpiredStatus[] = ['Expired'];

  getValueLabel(value: ExpiredStatus | ExpiredStatus[]): string {
    return Array.isArray(value) ? value.join(', ') : value;
  }

  getValueOptions(search?: string): ExpiredStatus[] {
    if (!search) return this.allStatus;
    return this.allStatus.filter(a => a.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

  serializeValue(value: ExpiredStatus | ExpiredStatus[]): string {
    return Array.isArray(value) ? value.join(',') : value;
  }

  deserializeValue(serializedValue: string) {
    return serializedValue.split(',').filter(v => this.allStatus.includes(v as any));
  }

  getPropertySField(_property: string, operator: CondOperator, value: ExpiredStatus[]): SFields {
    return operator === CondOperator.EQUALS
      ? {
          $or: [...(value.includes('Expired') ? [{ expiresOn: { $lte: new Date().toISOString() } }] : [])],
        }
      : operator === CondOperator.NOT_EQUALS
      ? {
          $or: [
            {
              $and: [
                ...(value.includes('Expired')
                  ? [{ $or: [{ expiresOn: { $isnull: true } }, { expiresOn: { $gt: new Date().toISOString() } }] }]
                  : []),
              ],
            },
          ],
        }
      : {};
  }
}
