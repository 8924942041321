import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Account, AdminFee } from '@greco/finance-accounts';
import { PropertyListener } from '@greco/property-listener-util';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from '../../services';

@Component({
  selector: 'greco-account-admin-fee',
  templateUrl: './account-admin-fee.component.html',
  styleUrls: ['./account-admin-fee.component.scss'],
})
export class AccountAdminFeeComponent implements OnInit {
  constructor(private fb: FormBuilder, private accountSvc: AccountService) {}

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  @PropertyListener('account') private account$ = new BehaviorSubject<Account | null>(null);
  @Input() account!: Account;

  @Output() detailsUpdated = new EventEmitter<Account>();

  initialValue = { label: '', description: '', amount: 0 };
  formGroup = this.fb.group({
    label: ['', Validators.required],
    description: ['', Validators.required],
    amount: [0, [Validators.required, Validators.min(0)]],
  });

  public saving = false;

  adminFee: AdminFee | null = null;

  async ngOnInit() {
    if (!this.account) return;
    this.adminFee = await this.accountSvc.getAdminFee(this.account.id);

    if (this.adminFee) {
      this.initialValue = {
        label: this.adminFee.label,
        description: this.adminFee.description,
        amount: this.adminFee.amount / 100,
      };

      this.formGroup.setValue(this.initialValue);
      this.formGroup.markAsPristine();
    }
  }

  onSave = async () => {
    try {
      this.saving = true;
      this.adminFee = await this.accountSvc.updateAdminFee(this.account.id, {
        label: this.formGroup.value.label,
        description: this.formGroup.value.description,
        amount: this.formGroup.value.amount * 100,
      });

      this.initialValue = {
        label: this.adminFee.label,
        description: this.adminFee.description,
        amount: this.adminFee.amount / 100,
      };

      this.formGroup.setValue(this.initialValue);
      this.formGroup.markAsPristine();
    } finally {
      this.saving = false;
    }
  };

  async toggleEnabled(enabled: boolean) {
    this.adminFee = await this.accountSvc.updateAdminFee(this.account.id, { enabled });
  }
}
