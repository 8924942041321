<ng-container *grecoLet="self$ | async as self">
  <ng-container *ngIf="configs?.length || selectedConfig; else noConfigs">
    <greco-gift-card-checkout
      [configs]="configs.length ? configs : selectedConfig ? [selectedConfig] : []"
      [selectedConfig]="selectedConfig"
      [selectedOption]="selectedOption"
      [user]="self"
      [self]="self"
    ></greco-gift-card-checkout>
  </ng-container>

  <ng-template #noConfigs>
    <greco-empty-placeholder></greco-empty-placeholder>
    <button mat-flat-button color="primary" (click)="goToShop()" style="margin-left: auto; margin-right: auto">
      Browse Shop
    </button>
  </ng-template>
</ng-container>
