import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { ChangeProfilePictureDialog } from '@greco/ngx-identity-users';

@Component({
  selector: 'greco-no-profile-picture-card',
  templateUrl: './no-profile-picture-card.component.html',
  styleUrls: ['./no-profile-picture-card.component.scss'],
})
export class NoProfilePictureCardComponent {
  constructor(private dialog: MatDialog) {}
  @Input() user!: User;

  @Output() changes = new EventEmitter();

  async upload() {
    const dialog = this.dialog.open(ChangeProfilePictureDialog, { data: { user: this.user } });
    const response = await toPromise(dialog.afterClosed());
    if (response) this.changes.emit(response);
  }
}
