import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CheckIn, CreateStationsDto, Station, UpdateStationsDto } from '@greco/stations';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
@Injectable({
  providedIn: 'root',
})
export class StationsService {
  constructor(private http: HttpClient) {}

  // @Get()
  async paginateStations(
    queryBuilder: RequestQueryBuilder,
    communityId: string,
    pagination?: PaginatedQueryParams
  ): Promise<PaginatedDto<Station>> {
    return await toPromise(
      this.http.get<PaginatedDto<Station>>('/api/stations', {
        params: {
          ...queryBuilder.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }
  // @Get(':stationId')
  async getOne(stationId: string) {
    return await toPromise(this.http.get<Station>(`/api/stations/${stationId}`));
  }

  // @Post()
  async createStation(createStation: CreateStationsDto): Promise<Station> {
    return await toPromise(this.http.post<Station>('/api/stations', createStation));
  }

  // @Put()
  async updateStation(stationId: string, updateStation: UpdateStationsDto): Promise<Station> {
    return await toPromise(this.http.put<Station>(`/api/stations/${stationId}`, updateStation));
  }
  //@Delete()
  async deleteStation(stationId: string): Promise<Station> {
    return await toPromise(this.http.delete<Station>(`/api/stations/${stationId}`));
  }

  // @Get()
  async getAllCheckedInUsers(stationId: string): Promise<CheckIn[]> {
    return await toPromise(this.http.get<CheckIn[]>(`/api/stations/checkedInUsers/${stationId}`));
  }
}
