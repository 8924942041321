import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { ContactNote, ContactNoteVersion } from '@greco/identity-contacts';
import { CreateContactNoteDto, UpdateContactNoteDto } from '@greco/nestjs-identity-contacts';
import { IPaginationOptions } from 'nestjs-typeorm-paginate';

@Injectable()
export class ContactNotesService {
  constructor(private http: HttpClient) {}

  // @Get(:contactId)
  async paginate(
    contactId: string,
    admin: boolean,
    pagination?: IPaginationOptions
  ): Promise<PaginatedDto<ContactNote>> {
    return await toPromise(
      this.http.get<PaginatedDto<ContactNote>>('/api/contact-notes/' + contactId, {
        params: {
          admin: admin ? 'true' : 'false',
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get(:noteId/versions)
  async paginateVersions(noteId: string, pagination?: IPaginationOptions): Promise<PaginatedDto<ContactNoteVersion>> {
    return await toPromise(
      this.http.get<PaginatedDto<ContactNoteVersion>>(`/api/contact-notes/${noteId}/versions`, {
        params: {
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get(:noteId/versions-all)
  async getVersions(noteId: string): Promise<ContactNoteVersion[]> {
    return await toPromise(this.http.get<ContactNoteVersion[]>(`/api/contact-notes/${noteId}/versions-all`));
  }

  // @Post(:contactId)
  async create(contactId: string, dto: CreateContactNoteDto): Promise<ContactNote> {
    return await toPromise(this.http.post<ContactNote>('/api/contact-notes/' + contactId, dto));
  }

  // @Patch(':noteId')
  async update(noteId: string, dto: UpdateContactNoteDto): Promise<ContactNote> {
    return toPromise(this.http.patch<ContactNote>(`/api/contact-notes/${noteId}`, dto));
  }

  // @Delete(':noteId')
  async delete(noteId: string): Promise<ContactNote> {
    return toPromise(this.http.delete<ContactNote>(`/api/contact-notes/${noteId}`));
  }
}
