import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CollectionVideoUnlock, CollectionVideoUnlockPreview, UnlockCollectionVideoDto } from '@greco/video-library';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'root' })
export class CollectionVideoUnlockService {
  constructor(private http: HttpClient) {}

  private baseUrl = '/api/collection-videos-unlock';

  // @Get('unlocked')
  async getUserUnlockedVideos(
    query: RequestQueryBuilder,
    userId: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<CollectionVideoUnlock>> {
    return await toPromise(
      this.http.get<PaginatedDto<CollectionVideoUnlock>>(`${this.baseUrl}/unlocked`, {
        params: {
          ...query.queryObject,
          userId: userId,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get('video-unlocks')
  async getCollectionVideoUnlocks(
    query: RequestQueryBuilder,
    collectionVideoId: string,
    pagination?: Partial<PaginatedQueryParams>,
    showExpired = false
  ): Promise<PaginatedDto<CollectionVideoUnlock>> {
    return await toPromise(
      this.http.get<PaginatedDto<CollectionVideoUnlock>>(`${this.baseUrl}/video-unlocks`, {
        params: {
          ...query.queryObject,
          collectionVideoId: collectionVideoId,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          showExpired: showExpired ? 'true' : 'false',
        },
      })
    );
  }

  preview_unlockCollectionVideo(dto: UnlockCollectionVideoDto) {
    return toPromise(this.http.post<CollectionVideoUnlockPreview>(`${this.baseUrl}/preview`, dto));
  }

  unlockCollectionVideo(dto: UnlockCollectionVideoDto, hash: string) {
    return toPromise(this.http.post<CollectionVideoUnlock>(`${this.baseUrl}/${hash}`, dto));
  }

  getUserActiveUnlock(collectionVideoId: string) {
    return toPromise(this.http.get<CollectionVideoUnlock>(`${this.baseUrl}/active/${collectionVideoId}`));
  }
}
