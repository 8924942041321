import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import {
  AgreementType,
  CommunityAgreementSecurityActions,
  CommunityAgreementSecurityResource,
  UserAgreementDto,
} from '@greco/community-agreements';
import type { SubscriptionUpdateDto, SubscriptionUpdatePreview } from '@greco/nestjs-sales-subscriptions';
import { UserService } from '@greco/ngx-identity-auth';
import { SignatureService } from '@greco/ngx-identity-users';
import { SecurityService } from '@greco/ngx-security-util';
import { ProductAddon, ProductAgreementUsage, ProductVariant } from '@greco/sales-products';
import { Subscription, SubscriptionResource, SubscriptionResourceAction } from '@greco/sales-subscriptions';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { SubscriptionsService } from '../../services';
import {
  SUBSCRIPTION_HANDLER_FORMS,
  UpdateSubscriptionHandlerForm,
  UpdateSubscriptionHandlerFormComponent,
} from './update-subcsription-handler-form';

@Component({
  selector: 'greco-update-subscription-dialog',
  templateUrl: './update-subscription.dialog.html',
  styleUrls: ['./update-subscription.dialog.scss'],
})
export class UpdateSubscriptionDialog implements OnInit, AfterViewInit {
  constructor(
    private http: HttpClient,
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService,
    private signatureSvc: SignatureService,
    private subscriptionSvc: SubscriptionsService,
    private breakpointObserver: BreakpointObserver,
    private factoryResolver: ComponentFactoryResolver,
    private dialogRef: MatDialogRef<UpdateSubscriptionDialog>,
    @Inject(MAT_DIALOG_DATA) public readonly subscription: Subscription,
    @Inject(SUBSCRIPTION_HANDLER_FORMS) private forms: UpdateSubscriptionHandlerForm[],
    @Inject(MAT_DIALOG_DATA) public readonly data: { subscription: Subscription; mode: 'user' | 'staff' }
  ) {
    this.handlerForm = this.forms.find(form => form.subscriptionType === this.data.subscription.type);
  }

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(bps => bps.matches));

  readonly updateReasons = [
    'Admin Error',
    'Add On',
    'Add On Price Change',
    'Downgrade',
    'Product Change',
    'Price Change',
    'Complimentary Product',
    'Unfreeze',
    'Update to Promotion',
    'Upgrade',
    'Wrong subscription sold',
  ];

  readonly freezeReasons = [
    'Freeze (Financial)',
    'Freeze (Medical)',
    'Freeze (Non-Usage)',
    'Freeze (Other)',
    'Freeze (Vacation)',
    'Freeze (Health)',
    'Freeze (Work)',
  ];

  communityId?: string;

  handlerForm?: UpdateSubscriptionHandlerForm;
  handlerFormFactory?: ComponentFactory<UpdateSubscriptionHandlerFormComponent>;
  handlerFormComponent?: ComponentRef<UpdateSubscriptionHandlerFormComponent>;
  agreementsForm = this.formBuilder.group({});

  @ViewChild('formContainer', { read: ViewContainerRef }) formContainer?: ViewContainerRef;

  dialogData: DialogData = {
    title: 'Update Membership',
    subtitle: 'Select the change you wish to make to the subscription.',
    hideDefaultButton: true,
    showCloseButton: true,
  };

  formGroup = this.formBuilder.group({
    dateSelection: [moment().add(1, 'days').toDate(), Validators.required],
    dateRadioButton: ['now'],
    prorateUpdate: [true],
    updateDetails: [null, Validators.required],
  });

  updatePreview$?: Observable<SubscriptionUpdatePreview | null>;
  updatePreview?: SubscriptionUpdatePreview | null;

  canProrate = false;
  canLeaveUnsigned = false;

  minimalDate = moment().add(1, 'days').toDate();
  maximalDate = moment().add(5, 'years').toDate();

  signature: string | null = null;
  checked = false;
  agreementData: UserAgreementDto[] = [];
  controlsConfigDetails: { [key: string]: any } = {};
  processing = false;

  agreements$?: Observable<any>;

  async ngOnInit() {
    this.canProrate = await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.PRORATE, {
      userId: this.data.subscription.userId,
      accountId: this.data.subscription.accountId,
      createdById: this.data.subscription.createdBy?.id,
    });

    this.canLeaveUnsigned = await this.securitySvc.hasAccess(
      CommunityAgreementSecurityResource.key,
      CommunityAgreementSecurityActions.LEAVE_UNSIGNED,
      {
        userId: this.data.subscription.userId,
        accountId: this.data.subscription.accountId,
        createdById: this.data.subscription.createdBy?.id,
      }
    );

    if (this.data.mode === 'user') {
      setTimeout(() => {
        this.formGroup.get('updateDetails')?.setValue('Self-Serve Update');
      });
    }
  }

  setCheck(usageId: string, agreementId: string) {
    const checked = this.agreementsForm.get(usageId)?.value;

    this.agreementsForm.patchValue({ [`${usageId}`]: !checked });

    const index = this.agreementData.findIndex(agreement => agreement.agreementId == agreementId);
    this.agreementData[index] = { agreementId, signed: !checked };
  }

  setAgreementRequired(usageId: string, checked: boolean) {
    const control = this.agreementsForm.controls[usageId];

    if (checked) control.setValidators(Validators.requiredTrue);
    else {
      control.setErrors(null);
      control.clearValidators();
    }
  }

  setSignature(event: string, usageId: string, agreementId: string) {
    const index = this.agreementData.findIndex(agreement => agreement.agreementId == agreementId);

    this.signature = event;
    if (event != '') {
      this.agreementsForm.patchValue({ [`${usageId}`]: true });
      this.agreementData[index] = { agreementId, signed: true };
    } else {
      this.agreementsForm.patchValue({ [`${usageId}`]: false });
      this.agreementData[index] = { agreementId, signed: false };
    }
  }

  ngAfterViewInit(): void {
    if (this.handlerForm && this.formContainer) {
      this.handlerFormFactory = this.factoryResolver.resolveComponentFactory(this.handlerForm.formComponent);
      this.handlerFormComponent = this.formContainer.createComponent(this.handlerFormFactory);
      this.handlerFormComponent.instance.subscription = this.data.subscription;
      this.handlerFormComponent.instance.mode = this.data.mode;
    }

    this.updatePreview$ = combineLatest([
      this.formGroup.valueChanges.pipe(startWith(this.formGroup.value)),
      this.handlerFormComponent?.instance.form.valueChanges.pipe(
        startWith(this.handlerFormComponent?.instance.form.value)
      ),
    ]).pipe(
      switchMap(async () => {
        const updateDto = await this.generateUpdateDto();
        if (!updateDto) {
          return null;
        } else {
          try {
            const preview = await this.subscriptionSvc.previewUpdateSubscription(this.data.subscription.id, updateDto);
            this.updatePreview = preview;
            return preview;
          } catch (error) {
            return null;
          }
        }
      })
    );

    this.agreements$ = this.updatePreview$?.pipe(
      switchMap(async preview => {
        const variant = preview?.dto.data?.variantId
          ? await toPromise(this.http.get<ProductVariant>(`/api/Products/variants/${preview?.dto.data?.variantId}`))
          : null;
        const productId =
          variant?.productId ||
          (this.data.subscription?.items?.[0] as any)?.variant?.product?.addons?.[0]?.upgradeCandidates?.find(
            (uc: any) => uc.variants?.some((v: any) => v.id === preview?.dto.data?.variantId)
          )?.id;

        const addon = await toPromise(this.http.get<ProductAddon>(`/api/products/${productId}/addons/${'AGREEMENT'}`));
        let usage = addon
          ? await toPromise(
              this.http.get<ProductAgreementUsage[]>(`/api/product_agreement_usage`, {
                params: { prodAddonId: addon.id },
              })
            )
          : null;
        if (usage) usage = usage.filter(usage => usage.productAddOn?.enabled);
        return usage?.length ? usage : null;
      }),
      tap(agreements => {
        this.controlsConfigDetails = {};
        this.agreementData = [];
        agreements?.forEach(agreement => {
          switch (agreement.agreement?.agreementType) {
            case AgreementType.AUTO_CHECKBOX: {
              this.controlsConfigDetails[`${agreement.id}`] = [true, Validators.requiredTrue];
              this.agreementData = [...this.agreementData, { agreementId: agreement.agreementId, signed: true }];
              break;
            }
            case AgreementType.CHECKBOX: {
              this.controlsConfigDetails[`${agreement.id}`] = [false, Validators.requiredTrue];
              this.agreementData = [...this.agreementData, { agreementId: agreement.agreementId, signed: false }];
              break;
            }
            case AgreementType.DIGITAL_SIGNATURE: {
              this.controlsConfigDetails[`${agreement.id}`] = [false, Validators.requiredTrue];
              this.agreementData = [...this.agreementData, { agreementId: agreement.agreementId, signed: false }];
              break;
            }
          }
        });

        this.agreementsForm = this.formBuilder.group({ ...this.controlsConfigDetails });
        if (this.canLeaveUnsigned) agreements?.forEach(agreement => this.setAgreementRequired(agreement.id, false));
      })
    );
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async generateUpdateDto(): Promise<SubscriptionUpdateDto | null> {
    if (this.formGroup.valid && this.handlerFormComponent?.instance.form.valid) {
      const currentUserId = await toPromise(this.userSvc.getUserId());
      const updateDto: SubscriptionUpdateDto = {
        proration: false,
        endOfPeriod: false,
        createdById: currentUserId,
        details: this.formGroup.value.updateDetails || (this.data.mode === 'user' ? 'Self-Serve Update' : ''),
      };

      if (this.canProrate || currentUserId === this.data.subscription.userId) {
        updateDto.proration = this.formGroup.value.prorateUpdate || currentUserId === this.data.subscription.userId;
      }

      if (this.formGroup.value.dateRadioButton === 'endOfPeriod') {
        updateDto.endOfPeriod = true;
      }

      if (this.formGroup.value.dateRadioButton === 'future') {
        const formattedUpdateDate: Date = this.formGroup.value.dateSelection;
        formattedUpdateDate.setHours(3, 0, 0, 0);
        updateDto.updateDate = formattedUpdateDate;
      }

      updateDto.data = await this.handlerFormComponent?.instance.getData();

      return updateDto;
    } else {
      return null;
    }
  }

  async submitUpdate() {
    const agreementControlKeys = Object.keys(this.agreementsForm.controls);
    const leftUnsigned = agreementControlKeys.some(key => !this.agreementsForm.controls[key].value);

    if (leftUnsigned) {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Unsigned Agreements',
          content:
            'There are currently unsigned agreements on this subscription. \nContinuing will create the subscription with an unsigned agreement. The member with the unsigned agreement will not be able to book classes or make other purchases until their agreement is signed. \n\nContinue?',
          buttons: [
            { label: 'Cancel', role: 'no' },
            { label: 'Confirm', role: 'yes', color: 'primary' },
          ],
        } as DialogData,
        width: '500px',
        maxWidth: '100%',
      });

      if ((await toPromise(dialog.afterClosed())) === 'no') return;
    }

    try {
      // Save signature
      if (this.data.subscription.userId && this.signature) {
        let signature: any = null;

        try {
          signature = await this.signatureSvc.getOne(this.data.subscription.userId);
        } catch (err) {
          console.log('No signature found for user, creating default now');
        }
        if (signature) {
          if (this.signature !== signature.signature)
            await this.signatureSvc.update(this.data.subscription.userId, this.signature);
        } else await this.signatureSvc.create({ userId: this.data.subscription.userId, signature: this.signature });
      }

      if (this.updatePreview && this.updatePreview.dto) {
        this.processing = true;
        const updateDto = this.updatePreview.dto;
        await this.subscriptionSvc.updateSubscription(
          this.data.subscription.id,
          updateDto,
          this.updatePreview.hash,
          this.agreementData
        );
        this.snacks.open('Subscription updated successfully', 'Ok', { duration: 6000, panelClass: 'mat-primary' });
        this.processing = false;
        this.dialogRef.close();
      } else {
        this.processing = false;
        throw new Error();
      }
    } catch (error) {
      this.processing = false;
      this.snacks.open('Subscription could not be updated', 'Ok', { duration: 6000, panelClass: 'mat-warn' });
    }
  }
}
