<ng-container *ngIf="selectedConfig">
  <ng-container *grecoLet="selectedOption === 'custom' as customSelected">
    <form
      [formGroup]="giftForm"
      style="
        width: 800px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        padding-bottom: 32px;
        height: 100%;
      "
    >
      <div style="display: flex; flex-direction: column; gap: 12px; width: 100%; align-items: center">
        <img src="assets/lf3/icon_square_pad.png" style="width: 15%" />

        <mat-select
          *ngIf="configs.length > 1; else singleConfig"
          [value]="selectedConfig"
          (valueChange)="setConfig($event)"
          #configSelect
        >
          <mat-select-trigger style="display: flex">
            <h1 class="no-padding" style="width: 100%; text-align: center">
              {{ selectedConfig | giftCardConfigTitle }}
            </h1>
          </mat-select-trigger>
          <mat-option *ngFor="let config of configs" [value]="config">
            <h1 class="no-padding" style="width: 100%; text-align: center">{{ config | giftCardConfigTitle }}</h1>
          </mat-option>
        </mat-select>

        <ng-template #singleConfig>
          <h1 class="no-padding" style="width: 100%; text-align: center">
            {{ selectedConfig | giftCardConfigTitle }}
          </h1>
        </ng-template>

        <div *ngIf="selectedConfig.community" style="display: flex">
          <mat-icon>place</mat-icon>
          <p class="no-padding">{{ selectedConfig.community.name }}</p>
        </div>

        <p *ngIf="selectedConfig.description" [innerHTML]="selectedConfig.description"></p>
      </div>

      <!-- Options -->
      <mat-form-field appearance="standard" (click)="$event.stopImmediatePropagation()">
        <mat-label>Option</mat-label>
        <mat-select
          #select
          [value]="selectedOption"
          (valueChange)="selectedOption = $event"
          [compareWith]="compareOptions"
        >
          <mat-select-trigger *ngIf="select.value">
            <strong *ngIf="!customSelected">{{ $any(selectedOption) | giftCardOptionTitle }}</strong>
            <strong *ngIf="customSelected">Custom Amount</strong>
          </mat-select-trigger>

          <mat-option *ngFor="let option of selectedConfig.options" [value]="option">
            <strong>{{ option | giftCardOptionTitle }}</strong>
          </mat-option>
          <mat-option *ngIf="selectedConfig.allowCustomAmount" [value]="'custom'">
            <strong>Custom Amount</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Option Description -->
      <p *ngIf="!customSelected && $any(selectedOption)?.description" class="info">
        <mat-icon>info</mat-icon>
        <span>{{ $any(selectedOption)?.description }}</span>
      </p>

      <div *ngIf="customSelected && selectedConfig.allowCustomAmount" style="width: 100%">
        <mat-form-field appearance="standard">
          <mat-label
            >Amount (minimum {{ (selectedConfig.minimumCustomAmount || 500) / 100 | currency
            }}{{
              selectedConfig.maximumCustomAmount
                ? ' to maximum ' + (selectedConfig.maximumCustomAmount / 100 | currency)
                : ''
            }})</mat-label
          >
          <input
            matInput
            type="text"
            currencyMask
            [formControl]="customAmountControl"
            [options]="currencyMaskConfig"
            [min]="selectedConfig.minimumCustomAmount || 5"
            [max]="selectedConfig.maximumCustomAmount || undefined"
          />
        </mat-form-field>
        <mat-error
          *ngIf="
            customAmountControl.touched && customAmountControl.value < (selectedConfig.minimumCustomAmount || 500) / 100
          "
        >
          Your amount is too low. Please make sure it is at least
          {{ (selectedConfig.minimumCustomAmount || 500) / 100 | currency }}
        </mat-error>
        <mat-error
          *ngIf="
            selectedConfig.maximumCustomAmount && customAmountControl.value > selectedConfig.maximumCustomAmount / 100
          "
        >
          Your amount is too high. Please make sure it is at most
          {{ selectedConfig.maximumCustomAmount / 100 | currency }}
        </mat-error>
      </div>

      <!-- Delivery Date -->
      <mat-form-field appearance="standard">
        <mat-label>Delivery Date</mat-label>
        <input matInput [matDatepicker]="picker" [min]="today" [value]="today" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <!-- send mode toggle -->
      <div style="display: flex; flex-direction: row; gap: 12px">
        <button *ngIf="mode !== 'self'" mat-stroked-button type="button" color="primary" (click)="mode = 'self'">
          Send to {{ user?.id === self?.id ? 'self' : user?.displayName || 'member' }}
        </button>
        <button *ngIf="mode === 'self'" mat-flat-button type="button" color="primary" (click)="mode = 'self'">
          Send to {{ user?.id === self?.id ? 'self' : user?.displayName || 'member' }}
        </button>
        <button *ngIf="mode !== 'gift'" mat-stroked-button type="button" color="primary" (click)="mode = 'gift'">
          Send as gift
        </button>
        <button *ngIf="mode === 'gift'" mat-flat-button type="button" color="primary" (click)="mode = 'gift'">
          Send as gift
        </button>
      </div>

      <ng-container *ngIf="mode === 'gift'">
        <!-- Send to -->
        <p class="no-padding" style="margin-bottom: -8px">Recipient</p>
        <div style="display: flex; flex-direction: row; gap: 12px; width: 100%; flex-wrap: wrap">
          <!-- name -->
          <mat-form-field appearance="standard" style="flex: 1">
            <mat-label>Name</mat-label>
            <input
              matInput
              type="text"
              formControlName="recipientName"
              required
              placeholder="Name of the person to send the card to"
            />
          </mat-form-field>
          <!-- email -->
          <mat-form-field appearance="standard" style="flex: 1">
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              required
              formControlName="recipientEmail"
              placeholder="Email of the person to send the card to"
            />
          </mat-form-field>
        </div>

        <!-- message -->
        <mat-form-field appearance="standard" floatLabel="always">
          <mat-label>Message (optional)</mat-label>
          <textarea matInput formControlName="transferMessage"></textarea>
        </mat-form-field>

        <!-- transferred from -->

        <ng-container *ngIf="isStaff">
          <p class="no-padding">Sent By</p>
          <div style="display: flex; flex-direction: row; gap: 12px; align-items: center; width: 100%">
            <mat-checkbox
              [checked]="!overrideTransferredFrom"
              (change)="overrideTransferredFrom = !overrideTransferredFrom"
            ></mat-checkbox>
            <div style="display: flex; flex-direction: row; width: 100%; align-items: center; gap: 4px">
              <p style="width: auto !important">Send as</p>
              <greco-user-avatar [user]="user" size="small" [showOnlineStatus]="false"></greco-user-avatar>
              <p style="width: auto !important">{{ user?.displayName || user?.contactEmail }}?</p>
            </div>
          </div>

          <div
            *ngIf="overrideTransferredFrom"
            style="display: flex; flex-direction: row; gap: 12px; width: 100%; flex-wrap: wrap"
          >
            <!-- name -->
            <mat-form-field appearance="standard" style="flex: 1">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="transferredFromName" placeholder="Your name" />
            </mat-form-field>
            <!-- email -->
            <mat-form-field appearance="standard" style="flex: 1">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="transferredFromEmail" placeholder="Your email" />
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>

      <!-- <div style="width: 100%; height: 10000px; background-color: blue"></div> -->
    </form>

    <!-- Footer -->
    <div class="footer">
      <div
        style="
          display: flex;
          flex-direction: row;
          width: 800px;
          max-width: 90%;
          gap: 16px;
          align-items: center;
          border-top: 1px solid rgba(14, 15, 15, 0.12);
        "
      >
        <div style="flex: 1">
          <div *ngIf="selectedOption" style="display: flex; flex-direction: column; gap: 4px">
            <p style="width: fit-content">
              Gift Card Balance:
              <strong *ngIf="selectedOption === 'custom'">{{ customAmountControl.value || 0 | currency }}</strong>
              <strong *ngIf="selectedOption !== 'custom'">{{ $any(selectedOption).amount / 100 | currency }}</strong>
            </p>
            <!-- <mat-divider></mat-divider> -->
            <p style="flex: 1; width: fit-content">
              Total Due:
              <strong *ngIf="selectedOption === 'custom'">{{ customAmountControl.value || 0 | currency }}</strong>
              <strong *ngIf="selectedOption !== 'custom'">{{ $any(selectedOption).price / 100 | currency }}</strong>
            </p>
          </div>
        </div>

        <div *ngIf="user" class="payment-method-container" style="flex: 1">
          <i
            *ngIf="paymentMethodControl.value && paymentMethodControl.value.type === 'bank'"
            style="position: absolute; transform: translateY(-2px)"
          >
            <small>* Selected payment method may result in a delay of purchase by 3 or more business days</small>
          </i>

          <mat-form-field appearance="standard" class="payment-method-picker-form-field">
            <i
              *ngIf="!paymentMethodControl.value"
              style="color: var(--warn-color); position: absolute; right: 0; transform: translateY(-2px)"
            >
              <small style="padding-left: 10px">Add a Payment Method!</small>
            </i>

            <greco-select-payment-method
              #selectPaymentMethodInput
              [required]="true"
              [userId]="user.id"
              [allowTerminals]="true"
              [formControl]="paymentMethodControl"
              [allowBankPayments]="false"
            ></greco-select-payment-method>

            <greco-add-payment-method-button
              matSuffix
              [onlyIcon]="true"
              [userId]="user.id"
              (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
            ></greco-add-payment-method-button>
          </mat-form-field>
        </div>

        <button
          mat-flat-button
          color="primary"
          style="height: 48px; margin: 16px 0px"
          type="button"
          [disabled]="
            loading ||
            !user ||
            !selectedConfig ||
            !selectedOption ||
            (customSelected && customAmountControl.invalid) ||
            (!customSelected && $any(selectedOption).price > 0 && !paymentMethodControl.value) ||
            (mode === 'gift' && giftForm.invalid)
          "
          (click)="confirm()"
        >
          <mat-icon class="spin" *ngIf="loading">loop</mat-icon>
          Confirm
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
