<greco-video-admin-page
  style="padding-bottom: 16px"
  *ngIf="communityVideo.video && (canRead$ | async)"
  [video]="communityVideo.video"
  [community]="communityVideo.community"
>
  <div class="buttons" header style="display: flex; flex-direction: row; justify-content: space-evenly; gap: 8px">
    <button
      *ngIf="(canDelete$ | async) && showRemove"
      matTooltip="Remove from Community Level"
      mat-flat-button
      color="warn"
      (click)="remove(communityVideo)"
    >
      Remove
    </button>

    <button
      *ngIf="(canArchive$ | async) && showChangeStatus"
      matTooltip="Archive Platform Video"
      mat-flat-button
      color="primary"
      (click)="changeStatus(communityVideo)"
    >
      {{(!communityVideo.availableUntil || (communityVideo.availableUntil | grecoDateCompare: 'gt')) ? 'Archive' :
      'Activate'}}
    </button>

    <button
      *ngIf="(canUpdate$ | async) && showUpdate"
      matTooltip="Update Platform Video"
      mat-flat-button
      color="primary"
      (click)="update(communityVideo)"
    >
      Update
    </button>

    <mat-chip *ngIf="showStatus" matTooltip="Status on Community Level">
      {{(!communityVideo.availableUntil || (communityVideo.availableUntil | grecoDateCompare: 'gt')) ? 'Active' :
      'Archived'}}
    </mat-chip>
  </div>

  <div extras>
    <div style="display: flex; flex-direction: column; gap: 16px">
      <ng-content select="[extras]"></ng-content>
    </div>
  </div>
</greco-video-admin-page>
