<div *ngIf="canRead$ | async">
  <greco-collapsible-section
    id="programs_section"
    [header]="{ title: 'Video Collections', icon: 'video_library' }"
    [expanded]="false"
  >
    <greco-table [highlight]="true" [data]="(data$ | async) || []">
      <mat-icon *grecoTableCol="''; fitContent: true; let video" [matTooltip]="video.id"> video_library </mat-icon>

      <!-- Video Title -->
      <ng-container>
        <p *grecoTableCol="'Collection Video Title'; let video" [matTooltip]="'Title of video in the collection'">
          {{ video.titleOverride || communityVideo?.video?.title }}
        </p>
      </ng-container>

      <!-- Collection Title -->
      <ng-container>
        <p *grecoTableCol="'Collection'; let video" [matTooltip]="video.collection?.title">
          {{ video.collection?.title }}
        </p>
      </ng-container>
      <!-- Collection Status -->
      <ng-container>
        <p *grecoTableCol="'Status'; fitContent: true; let video" [matTooltip]="'Status'">
          <ng-container
            *grecoLet="
              video.collection.availableOn &&
              (video.collection.availableOn | grecoDateCompare : 'lt') &&
              (!video.collection.availableUntil ||
                (video.collection.availableUntil | grecoDateCompare : 'gt')) as active
            "
          >
            <ng-container *grecoLet="!video.collection.availableOn && !video.collection.availableUntil as draft">
              <mat-chip [ngClass]="active ? '' : draft ? 'draft' : 'inactive'">{{
                active ? 'Active' : draft ? 'Draft' : 'Archived'
              }}</mat-chip></ng-container
            ></ng-container
          >
        </p>
      </ng-container>

      <!-- Subcollection Title -->
      <ng-container>
        <p *grecoTableCol="'Subcollection'; let video" [matTooltip]="'Subcollection video is in'">
          {{ video.subCollection?.title }}
        </p>
      </ng-container>

      <!-- Subcollection Status -->
      <ng-container>
        <p *grecoTableCol="'Status'; fitContent: true; let video" [matTooltip]="'Status'">
          <ng-container
            *grecoLet="
              video.subCollection.availableOn &&
              (video.subCollection.availableOn | grecoDateCompare : 'lt') &&
              (!video.subCollection.availableUntil ||
                (video.subCollection.availableUntil | grecoDateCompare : 'gt')) as active
            "
          >
            <ng-container *grecoLet="!video.subCollection.availableOn && !video.subCollection.availableUntil as draft">
              <mat-chip [ngClass]="active ? '' : draft ? 'draft' : 'inactive'">{{
                active ? 'Active' : draft ? 'Draft' : 'Archived'
              }}</mat-chip></ng-container
            ></ng-container
          >
        </p>
      </ng-container>

      <!-- Date -->
      <ng-container>
        <p *grecoTableCol="'Created'; let video" [matTooltip]="'Created Date'">
          {{ video.created | date }}
        </p>
      </ng-container>

      <!-- Status -->
      <ng-container>
        <p *grecoTableCol="'Status'; fitContent: true; let video" [matTooltip]="'Status'">
          <ng-container
            *grecoLet="
              video.availableOn &&
              (video.availableOn | grecoDateCompare : 'lt') &&
              (!video.availableUntil || (video.availableUntil | grecoDateCompare : 'gt')) as active
            "
          >
            <ng-container *grecoLet="!video.availableOn && !video.availableUntil as draft">
              <mat-chip>{{ active ? 'Active' : draft ? 'Draft' : 'Archived' }}</mat-chip>
            </ng-container>
          </ng-container>
        </p>
      </ng-container>

      <!-- Options -->
      <ng-container>
        <ng-container *grecoTableCol="''; let video; fitContent: true; stickyEnd: true">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matTooltip]="'Options for ' + video.id"
            (click)="$event.stopImmediatePropagation()"
            *ngIf="(canManageCollection$ | async) || (canManageVideo$ | async)"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <!-- Manage Collection -->
            <button mat-menu-item (click)="navigateToCollection(video)">
              <mat-icon>settings</mat-icon>
              <span>Manage Collection</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </greco-table>
  </greco-collapsible-section>
</div>
