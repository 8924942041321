import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  VideoCollection,
  VideoCollectionSecurityResource,
  VideoCollectionSecurityResourceAction,
} from '@greco/video-library';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProgramsAdminTableComponent } from '../../components';
import { CreateNewProgramDialog } from '../../dialogs';

@Component({
  selector: 'greco-programs-admin-page',
  templateUrl: './programs-admin.page.html',
  styleUrls: ['./programs-admin.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProgramsPage {
  @PropertyListener('community') private _community$ = new BehaviorSubject<Community | null>(null);
  @Input() community!: Community;
  @ViewChild('greco-programs-admin-table') table?: ProgramsAdminTableComponent;

  canCreate$ = this._community$.pipe(
    switchMap(async community => {
      return community
        ? await this.comSecSvc.hasAccess(
            community.id,
            VideoCollectionSecurityResource.key,
            VideoCollectionSecurityResourceAction.MANAGE
          )
        : null;
    })
  );

  constructor(
    private matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private comSecSvc: CommunitySecurityService
  ) {}

  async createNewProgram() {
    if (!this.community) return;

    const dialog = this.matDialog.open(CreateNewProgramDialog, { data: { communityId: this.community.id } });
    const response = await toPromise(dialog.afterClosed());
    if (response) {
      //navigate to program page for further creation of playlists and videos
      await this.manageProgram(response);
    }
  }
  async manageProgram(program: VideoCollection) {
    await this.router.navigate([program.id], { relativeTo: this.route });
  }
}
