<form [formGroup]="form">
  <!-- Booking Window -->
  <mat-form-field appearance="standard">
    <mat-label>Booking Window</mat-label>
    <greco-minutes-input [required]="true" formControlName="bookingWindow"></greco-minutes-input>
  </mat-form-field>

  <!-- Price (Optional) -->
  <mat-form-field appearance="standard">
    <mat-label>Booking Price</mat-label>
    <input matInput formControlName="price" currencyMask [options]="currencyMaskConfig" />
    <mat-icon
      matSuffix
      matTooltip="Price charged to the user at the time of booking. This is not the price of the booking option perk that must be used to book. The user receives the perks by purchasing them as part of products."
    >
      info
    </mat-icon>
  </mat-form-field>

  <!-- Usages -->
  <mat-form-field appearance="standard">
    <mat-label>Daily Usages</mat-label>
    <input matInput type="number" step="1" formControlName="usages" />
    <mat-icon matSuffix matTooltip="The number of times a member can use this particular perk to book each day">
      info
    </mat-icon>
  </mat-form-field>

  <div style="display: flex">
    <!-- Spot Blocking -->
    <mat-form-field appearance="standard" style="flex: 2">
      <mat-label>Additional Spots</mat-label>
      <input matInput type="number" step="1" formControlName="additionalSpots" />
      <mat-icon
        matSuffix
        style="margin-right: 12px"
        matTooltip="The number of extra spots a member can book for themselves and their guests when booking"
      >
        info
      </mat-icon>
    </mat-form-field>

    <!-- Spot Blocking -->
    <mat-form-field appearance="standard" style="flex: 1">
      <mat-label>Spot Cost</mat-label>
      <input matInput formControlName="additionalSpotCost" currencyMask [options]="currencyMaskConfig" />
      <mat-icon matSuffix matTooltip="The additional cost per extra spot booked when a member books additional spots">
        info
      </mat-icon>
    </mat-form-field>
  </div>

  <!-- Allow Pending Bookings -->
  <div style="margin-top: 16px">
    <mat-checkbox formControlName="allowPendingBookings" color="primary"> Allow pending bookings </mat-checkbox>
  </div>

  <!-- Max Boost -->
  <mat-checkbox
    style="margin-top: 24px"
    [style.margin-bottom]="form.get('allowBoost')?.value ? '-8px' : '16px'"
    color="primary"
    formControlName="allowBoost"
    (change)="$event.checked && form.get('maxBoost')?.setValue(null)"
  >
    Allow Booking Window Boosting
  </mat-checkbox>

  <mat-form-field
    style="margin-bottom: 16px"
    appearance="standard"
    class="ignore"
    [style.display]="form.get('allowBoost')?.value ? 'block' : 'none'"
  >
    <mat-label>Boosts Allowed (24hr/boost)</mat-label>
    <input type="number" min="0" step="1" matInput formControlName="maxBoost" />

    <mat-hint *ngIf="form.value.maxBoost; else noBoost">
      Extra {{ 24 * form.value.maxBoost }}hrs ({{ form.value.maxBoost }} days) on top of booking window
    </mat-hint>

    <ng-template #noBoost>
      <mat-hint> Leave blank to allow unlimited booking window boosting. </mat-hint>
    </ng-template>
  </mat-form-field>

  <!-- Sales Category -->
  <mat-form-field appearance="standard" *ngIf="accountId && scBookingVisible">
    <mat-label>Sale Category Booking</mat-label>
    <greco-sale-category-input
      [accountId]="accountId"
      formControlName="saleCategoryBook"
      [required]="false"
    ></greco-sale-category-input>
  </mat-form-field>

  <!-- Taxes -->
  <mat-form-field appearance="standard" floatLabel="always" style="width: 100%" *ngIf="accountId">
    <mat-label>Taxes</mat-label>
    <greco-account-taxes-picker formControlName="bookingTaxes" [accountId]="accountId" [disabled]="ignoreTaxes.checked">
      <mat-checkbox #ignoreTaxes formControlName="bookingIgnoreTaxes" color="primary">No Taxes</mat-checkbox>
    </greco-account-taxes-picker>
  </mat-form-field>

  <div style="display: flex; align-items: center; justify-content: space-between">
    <h3 style="margin-bottom: 8px; margin-top: 12px">Late Cancellation Policy</h3>
    <mat-checkbox labelPosition="before" color="primary" formControlName="cancellation"> Enable </mat-checkbox>
  </div>

  <ng-container *ngIf="form.value.cancellation">
    <!-- Cancellation Window -->
    <mat-form-field appearance="standard">
      <mat-label>Cancellation Cut-Off Window</mat-label>
      <greco-minutes-input formControlName="cancellationWindow" [required]="true"></greco-minutes-input>
    </mat-form-field>

    <!-- Cancellation Cost -->
    <mat-form-field appearance="standard">
      <mat-label>Late Cancellation Cost</mat-label>
      <input matInput formControlName="cancellationPrice" currencyMask [options]="currencyMaskConfig" />
      <mat-checkbox
        matSuffix
        color="primary"
        matTooltip="Refunds the Booking Cost & Perks Used"
        formControlName="refundCancellation"
        style="margin-left: 8px"
      >
        Refund booking
      </mat-checkbox>
    </mat-form-field>

    <!-- Sales Category -->
    <mat-form-field appearance="standard" *ngIf="accountId && scCancelVisible">
      <mat-label>Sale Category Cancellation</mat-label>
      <greco-sale-category-input
        [accountId]="accountId"
        formControlName="saleCategoryCancel"
        [required]="false"
      ></greco-sale-category-input>
    </mat-form-field>

    <!-- Taxes -->
    <mat-form-field appearance="standard" floatLabel="always" style="width: 100%" *ngIf="accountId">
      <mat-label>Taxes</mat-label>
      <greco-account-taxes-picker
        formControlName="cancellationTaxes"
        [accountId]="accountId"
        [disabled]="ignoreTaxes.checked"
      >
        <mat-checkbox #ignoreTaxes formControlName="cancellationIgnoreTaxes" color="primary">No Taxes</mat-checkbox>
      </greco-account-taxes-picker>
    </mat-form-field>
  </ng-container>
</form>
