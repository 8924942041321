import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Injectable, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { SelectVideosDialog, VideoUploadDialog } from '@greco/ngx-community-videos';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { VideoSendingDialog, WatchVideoComponent } from '@greco/ngx-videos';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import {
  CollectionVideo,
  CollectionVideoSecurityResource,
  CollectionVideoSecurityResourceAction,
  UpdateCollectionVideoDto,
  UploadCollectionVideoDto,
  VideoSubCollection,
} from '@greco/video-library';
import { CreateVideoAndInitiateUploadDto } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { CollectionVideoService } from '../../services';

@Injectable({ providedIn: 'any' })
export class VideoTitleSearchFilter extends BuildSearchFilter('VideoTitleSearchFilter', {
  properties: ['titleOverride', 'communityVideo.video.title'],
  propertyLabels: ['Video Title'],
}) {}

@Component({
  selector: 'greco-collection-video-table',
  templateUrl: './collection-video-table.component.html',
  styleUrls: ['./collection-video-table.component.scss'],
})
export class CollectionVideoTableComponent {
  constructor(
    private colVidSvc: CollectionVideoService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private comSecSvc: CommunitySecurityService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @PropertyListener('subcollection') private subcollection$ = new BehaviorSubject<VideoSubCollection | null>(null);
  @Input() subcollection!: VideoSubCollection;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  sortOrderChange: CollectionVideo[] = [];
  loading = false;
  sortLoading = false;
  filterOptions = [VideoTitleSearchFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination?: IPaginationMeta;
  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});

  videos$ = combineLatest([this.subcollection$, this.filters$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([subcollection, query, page]) => {
      return query && subcollection ? await this.colVidSvc.paginate(query, subcollection.id, page) : null;
    }),
    tap(data => (this.pagination = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  readonly canRead$ = this.subcollection$.pipe(
    switchMap(async sub => {
      return sub?.collection?.community
        ? await this.comSecSvc.hasAccess(
            sub.collection.community.id,
            CollectionVideoSecurityResource.key,
            CollectionVideoSecurityResourceAction.READ
          )
        : null;
    })
  );
  readonly canUpload$ = this.subcollection$.pipe(
    switchMap(async sub => {
      return sub?.collection?.community
        ? await this.comSecSvc.hasAccess(
            sub.collection.community.id,
            CollectionVideoSecurityResource.key,
            CollectionVideoSecurityResourceAction.UPLOAD
          )
        : null;
    })
  );

  readonly canUpdate$ = this.subcollection$.pipe(
    switchMap(async sub => {
      return sub?.collection?.community
        ? await this.comSecSvc.hasAccess(
            sub.collection.community.id,
            CollectionVideoSecurityResource.key,
            CollectionVideoSecurityResourceAction.UPDATE
          )
        : null;
    })
  );
  readonly canDelete$ = this.subcollection$.pipe(
    switchMap(async sub => {
      return sub?.collection?.community
        ? await this.comSecSvc.hasAccess(
            sub.collection.community.id,
            CollectionVideoSecurityResource.key,
            CollectionVideoSecurityResourceAction.REMOVE
          )
        : null;
    })
  );

  async addVideo() {
    const proceed = !this.sortOrderChange.length || (await this.warnAboutSortChanges('Upload'));
    if (proceed) {
      if (this.subcollection) {
        const community = this.subcollection.collection?.community;
        const dialog = this.matDialog.open(SelectVideosDialog, {
          data: { community: community, sources: ['vimeo'] },
          width: '1000px',
          maxWidth: '90%',
        });
        const response = await toPromise(dialog.afterClosed());

        if (response?.length) {
          let success = 0;
          let failure = 0;
          for (const item of response) {
            if (item.id) {
              try {
                await this.colVidSvc.linkCommunityVideoToSubcollection(this.subcollection.id, item.id);
                success++;
              } catch (err) {
                console.error(err);
                failure++;
              }
            } else if (item.externalId) {
              try {
                await this.colVidSvc.linkVideoToSubcollection(this.subcollection.id, item);
                success++;
              } catch (err) {
                console.error(err);
                failure++;
              }
            }
          }
          this.snacks.open('Successfully linked ' + success + ' videos', failure === 0 ? 'Ok!' : failure + ' errors!', {
            duration: 2500,
            panelClass: failure === 0 ? 'mat-primary' : 'mat-warn',
          });
        }
      }
      this.refresh();
    }
  }

  async upload() {
    const proceed = !this.sortOrderChange.length || (await this.warnAboutSortChanges('Upload'));
    if (proceed) {
      if (!this.subcollection) return;
      const dialog = this.matDialog.open(VideoUploadDialog, { width: '700px', maxWidth: '90%' });
      dialog.componentInstance.communityId = this.subcollection.collection?.communityId;
      const response: { file: File; dto: CreateVideoAndInitiateUploadDto } = await toPromise(dialog.afterClosed());
      if (response?.dto) {
        const sendingDialog = this.matDialog.open(VideoSendingDialog, {
          data: {},
          width: '450px',
          maxWidth: '90%',
        });
        sendingDialog.disableClose = true;
        const collectionVideoDto: UploadCollectionVideoDto = {
          //TODO: CreateCollectionVideoAndInitiateUploadDto
          source: response.dto.source,
          fileSize: response.dto.fileSize,
          title: response.dto.title,
          videoTagIds: response.dto.videoTagIds,
          description: response.dto.description,
          subCollectionId: this.subcollection.id,
        };
        try {
          await this.colVidSvc.upload(response.file, collectionVideoDto);
        } catch (err) {
          console.error(err);
        }

        sendingDialog.disableClose = false;
        sendingDialog.close();
      }
      this.refresh();
    }
  }

  async updateSortIndex(video: CollectionVideo, sortIndex: number) {
    const proceed = !this.sortOrderChange.length || (await this.warnAboutSortChanges('Update'));
    if (proceed) {
      const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '90%' });
      spinnerDialog.disableClose = true;
      const response = await this.colVidSvc.updateSortIndex(video.id, sortIndex);
      if (response)
        this.snacks.open('Position changed successfully', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      else {
        this.snacks.open('Position failed to update', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      }
      spinnerDialog.close();
      this.refresh();
    }
  }

  refresh() {
    this.sortOrderChange = [];
    this.page$.next(this.page$.value);
  }

  async changeStatus(video: CollectionVideo) {
    const proceed = !this.sortOrderChange.length || (await this.warnAboutSortChanges('Update'));
    if (proceed) {
      const isActive =
        video.availableOn &&
        video.availableOn.getTime() < Date.now() &&
        (!video.availableUntil || video.availableUntil.getTime() > Date.now());
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Confirm ' + (!isActive ? ' Activate' : 'Archive'),
          //subtitle: 'Are you sure you want to remove this video?',
          content: !isActive
            ? 'This video will be visible for unlock by users with the appropriate perks'
            : 'This video will not be visible for unlock, but will remain visible if already unlocked',
          buttons: [
            { label: "No, Don't Update Status", role: 'no' },
            { label: 'Yes, Update Status', role: 'yes' },
          ],
        } as DialogData,
        width: '600px',
        maxWidth: '90%',
      });
      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '90%' });
        spinnerDialog.disableClose = true;
        try {
          const availableOn = isActive ? video.availableOn : new Date();
          const availableUntil = isActive
            ? new Date()
            : video.availableUntil && video.availableUntil.getTime() < Date.now()
            ? null
            : video.availableUntil;
          await this.colVidSvc.updateProgramVideo(video.id, { availableUntil, availableOn });

          this.snacks.open('Status changed successfully', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
        } catch (err) {
          console.error(err);
          this.snacks.open('Oops, something went wrong. Please try again.', 'Ok', {
            duration: 5000,
            panelClass: 'mat-warn',
          });
        }
        spinnerDialog.close();
      }
      this.refresh();
    }
  }

  async remove(video: CollectionVideo) {
    const proceed = !this.sortOrderChange.length || (await this.warnAboutSortChanges('Remove'));
    if (proceed) await this.colVidSvc.removeCollectionVideo(video.id);
  }

  watch(video: CollectionVideo) {
    if (video?.communityVideo?.video) {
      const dialog = this.matDialog.open(WatchVideoComponent, { data: {}, width: '750px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.video = video.communityVideo.video;
    }
  }

  async navigateToCollectionVideo(collectionVideo: any) {
    if (this.sortLoading) return;
    await this.router.navigate([collectionVideo.id], { relativeTo: this.route });
  }
  async dropEvent(event: { event: CdkDragDrop<CollectionVideo[], any>; data: CollectionVideo[] }) {
    const previousListIndex = event.event.previousIndex;
    const currentListIndex = event.event.currentIndex;
    const difference = previousListIndex - currentListIndex;
    const video = event.event.item.data as CollectionVideo;
    const newSortIndex = video.sortIndex - difference;
    if (newSortIndex !== video.sortIndex) {
      this.sortOrderChange = event.data;
    }
  }

  async saveSortOrders() {
    this.sortLoading = true;
    const sendingDialog = this.matDialog.open(MatSpinner, {
      data: {},
      width: 'auto',
      maxWidth: '90%',
    });
    sendingDialog.disableClose = true;
    let successCount = 0;
    let failureCount = 0;
    for (let i = 0; i < this.sortOrderChange.length; i++) {
      const page = this.pagination ? this.pagination.currentPage - 1 : 0;
      const itemsPerPage = this.pagination ? this.pagination.itemsPerPage : 0;
      const index = i + page * itemsPerPage;
      const video = this.sortOrderChange[i];
      if (video.sortIndex !== index) {
        try {
          const response = await this.colVidSvc.updateProgramVideo(video.id, {
            sortIndex: index,
          } as UpdateCollectionVideoDto);
          if (response) {
            successCount++;
          } else {
            failureCount++;
          }
        } catch (err) {
          failureCount++;
        }
      }
    }
    this.snacks.open(
      'Successfully updated ' + successCount + ' videos',
      failureCount === 0 ? 'Ok!' : failureCount + ' errors!',
      {
        duration: 2500,
        panelClass: failureCount === 0 ? 'mat-primary' : 'mat-warn',
      }
    );
    sendingDialog.close();
    this.sortOrderChange = [];
    this.sortLoading = false;
    this.refresh();
  }

  async warnAboutSortChanges(action: string) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm ' + action,
        content: 'Performing this ' + action + ' will revert current changes to the sorting of videos',
        buttons: [
          { label: "No, Don't Continue", role: 'no' },
          { label: 'Yes, Continue', role: 'yes' },
        ],
      } as DialogData,
      width: '100%',
      maxWidth: '400px',
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      this.sortOrderChange = [];
      return true;
    } else return false;
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
}
