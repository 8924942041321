import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  CommunityVideo,
  CreateCommunityVideoAndInitiateUploadDto,
  UpdateCommunityVideoDto,
} from '@greco/community-videos';
import { VideosService } from '@greco/ngx-videos';
import { Video, VideoSourceRequestDto } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'root' })
export class CommunityVideosService {
  constructor(private videosSvc: VideosService, private http: HttpClient) {}

  getOneCommunityVideo(communityVideoId: string, loadExtras = false) {
    return toPromise(
      this.http.get<CommunityVideo>(`/api/community-videos/video/${communityVideoId}`, {
        params: { loadExtras: loadExtras ? 'true' : 'false' },
      })
    );
  }

  paginate(
    query: RequestQueryBuilder,
    communityId: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<CommunityVideo>> {
    return toPromise(
      this.http.get<PaginatedDto<CommunityVideo>>(`/api/community-videos/${communityId}`, {
        params: {
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  async upload(file: File, dto: CreateCommunityVideoAndInitiateUploadDto): Promise<CommunityVideo> {
    dto.fileSize = file.size;
    const response = await toPromise(
      this.http.post<{ communityVideo: CommunityVideo; video: Video; uploadUrl: string }>(
        `/api/community-videos/upload`,
        dto
      )
    );
    await this.videosSvc.clientSideUpload(file, dto.source, response.video, response.uploadUrl);
    return response.communityVideo;
  }

  update(communityVideoId: string, dto: UpdateCommunityVideoDto) {
    return toPromise(this.http.patch<CommunityVideo>(`/api/community-videos/${communityVideoId}`, dto));
  }

  remove(communityVideoId: string) {
    return toPromise(this.http.delete<CommunityVideo | null>(`/api/community-videos/${communityVideoId}`));
  }

  linkVideoToCommunity(communityId: string, dto: VideoSourceRequestDto) {
    return toPromise(
      this.http.post<any>(`/api/community-videos/link`, {
        communityId: communityId,
        dto: dto,
      })
    );
  }

  getCommunityVideosByCommunity(communityId: string): Promise<CommunityVideo[]> {
    return toPromise(this.http.get<any>(`/api/community-videos/all/${communityId}`));
  }

  getCommunityVideosByVideo(videoId: string): Promise<CommunityVideo[]> {
    return toPromise(this.http.get<any>(`/api/community-videos/video/${videoId}`));
  }
}
