<greco-simple-dialog [data]="dialogData" class="create-custom-purchase-dialog">
  <form [formGroup]="form">
    <mat-vertical-stepper #stepper [selectedIndex]="currentStep" (selectionChange)="setStep($event.selectedIndex)">
      <mat-step>
        <ng-template matStepLabel>Select User</ng-template>
        <mat-form-field appearance="standard">
          <mat-label>User</mat-label>
          <greco-contact-picker [withUser]="true" formControlName="contact" [communityId]="communityId">
          </greco-contact-picker>
        </mat-form-field>
        <div>
          <button [disabled]="!form.value.contact" mat-button matStepperNext>Select Product</button>
        </div>
      </mat-step>

      <ng-container *ngIf="form.value.contact">
        <ng-container *ngFor="let variantControl of variantControls; let i = index; let last = last">
          <mat-step [stepControl]="variantControl">
            <ng-template matStepLabel>
              <span>Select Product{{variantControls.length > 1 ? ' ' + (i + 1) : ''}}</span>
            </ng-template>
            <div style="display: flex; flex-direction: row; gap: 8px; width: 100%; align-items: center">
              <mat-form-field appearance="standard">
                <mat-label>Product</mat-label>

                <greco-product-variant-picker
                  style="width: 100%"
                  placeholder="Start typing to search for products..."
                  [communityId]="communityId"
                  [required]="true"
                  [showRestrictedVariants]="(canSellRestrictedVariants$ | async) || false"
                  [formControl]="variantControl"
                  (selectedVariantChange)="variantChanges()"
                  [onlyOneTime]="variantControls.length > 1"
                  [variantIdsToExclude]="(variantIds$ | async) || []"
                ></greco-product-variant-picker>
              </mat-form-field>
              <button
                *ngIf="variantControls.length > 1"
                mat-icon-button
                (click)="$event.stopImmediatePropagation(); removeVariant(i)"
                color="warn"
              >
                <mat-icon style="color: var(--warn-color)">delete</mat-icon>
              </button>
            </div>
            <mat-error *ngIf="variantControl.value?.recurrence?.frequency && variantControls.length > 1"
              >Cannot select multiple products when purchasing recurring products. Please review your
              selections!</mat-error
            >
            <div
              style="display: flex; flex-direction: row; justify-content: space-between"
              *grecoLet="canSelectMultipleVariants$ | async as canSelectMultipleVariants"
            >
              <button *ngIf="!last && variantControl.value" mat-button matStepperNext>Next Product</button>
              <div
                *ngIf="last && variantControl.value"
                [matTooltip]="canSelectMultipleVariants ? 'Add additional one-time products to this purchase' : 'Membership products can only be purchased one at a time'"
              >
                <button
                  mat-button
                  (click)="addAdditionalProduct()"
                  [disabled]="(canSelectMultipleVariants$ | async) !== true"
                >
                  Add Additional Product
                </button>
              </div>

              <button
                *ngIf="variantControl.value"
                mat-button
                (click)="copyCheckoutUrlToClipboard( form.value.contact.user.id)"
              >
                Copy Link
              </button>
            </div>
            <div *ngIf="last && variantControl.value " style="width: 100%; margin-top: 8px">
              <mat-divider></mat-divider>
              <button style="width: 100%; margin-top: 16px" mat-stroked-button color="primary" matStepperNext>
                Preview Purchase
              </button>
            </div>
          </mat-step>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="form.value.contact && allVariantsSelected$ | async">
        <mat-step>
          <ng-template matStepLabel>Checkout</ng-template>
          <greco-checkout-page
            [isStaff]="true"
            [user]="billedTo"
            [purchasedBy]="billedTo"
            [preventRedirect]="true"
            [variantIds]="(variantIds$ | async) || []"
            (purchaseSuccess)="close()"
          ></greco-checkout-page>
        </mat-step>
      </ng-container>
    </mat-vertical-stepper>
  </form>
</greco-simple-dialog>
