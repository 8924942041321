<greco-simple-dialog [data]="dialogData" class="create-custom-charge-dialog">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <!-- User -->
    <mat-form-field appearance="standard">
      <mat-label>User</mat-label>
      <greco-contact-picker [withUser]="true" formControlName="contact" [communityId]="communityId">
      </greco-contact-picker>
    </mat-form-field>

    <!-- Description -->
    <mat-form-field appearance="standard">
      <mat-label>Charge Title</mat-label>
      <input matInput type="text" required formControlName="title" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Charge Description</mat-label>
      <input matInput type="text" formControlName="description" />
    </mat-form-field>

    <!--Sale Category-->
    <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
      <mat-label>Sales Category</mat-label>
      <greco-sale-category-input
        [accountId]="accountId"
        formControlName="saleCategory"
        [required]="true"
      ></greco-sale-category-input>
    </mat-form-field>

    <!-- Amount -->
    <div style="display: flex; align-items: flex-end">
      <mat-form-field appearance="standard">
        <mat-label>Charge Amount</mat-label>
        <input matInput required formControlName="amount" currencyMask [options]="currencyMaskConfig" />
      </mat-form-field>

      <mat-form-field appearance="standard" style="flex: 0">
        <mat-label>Amount is</mat-label>
        <mat-select required formControlName="taxesIncluded">
          <mat-option value="included">After Tax</mat-option>
          <mat-option value="excluded">Before Tax</mat-option>
          <mat-option value="none">Non-Taxable</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Tax Types -->
    <mat-form-field
      *ngIf="form.value.taxesIncluded !== 'none'"
      appearance="standard"
      floatLabel="always"
      style="width: 100%"
    >
      <mat-label>Taxes</mat-label>
      <greco-account-taxes-picker formControlName="taxTypes" [accountId]="accountId"> </greco-account-taxes-picker>
    </mat-form-field>

    <!-- Payment Method -->
    <mat-form-field appearance="standard" floatLabel="always">
      <mat-label>Payment Method</mat-label>
      <greco-select-payment-method
        #selectPaymentMethodInput
        formControlName="paymentMethod"
        [required]="true"
        [allowTerminals]="true"
        [userId]="form.value.contact?.user?.id"
        [accountId]="accountId"
      ></greco-select-payment-method>
    </mat-form-field>

    <!-- Summary -->
    <div
      *grecoLet="preview$ | async as preview"
      style="
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 8px 16px;
        text-align: end;
        margin-top: 16px;
        margin-bottom: 32px;
      "
    >
      <span>Subtotal</span>
      <span>{{preview?.purchase?.subtotal ? ((preview?.purchase?.subtotal || 0) / 100 | currency) : '-'}}</span>
      <ng-container *ngFor="let tax of( taxes$ | async)">
        <span style="color: gray">
          {{(tax.tax.abbreviation || tax.tax.title) + " " + tax.tax.percentage + "% " + (tax.taxNumber ?
          ("("+tax.taxNumber + ")") : '')}}
        </span>
        <span>{{tax.amount ? ((tax.amount || 0) / 100 | currency) : '-'}}</span>
      </ng-container>

      <span>Total Taxes</span>
      <span>{{preview?.purchase?.tax ? ((preview?.purchase?.tax || 0) / 100 | currency) : '-'}}</span>

      <strong>Total</strong>
      <strong>{{preview?.purchase?.total ? ((preview?.purchase?.total || 0) / 100 | currency) : '-'}}</strong>

      <ng-container *ngIf="preview?.purchase?.balanceUsed">
        <span>Balance Applied</span>
        <span
          >-{{preview?.purchase?.balanceUsed ? ((preview?.purchase?.balanceUsed || 0) / 100 | currency) : '-'}}</span
        >

        <strong>Amount Due</strong>
        <strong>
          {{preview?.purchase?.total ? (((preview?.purchase?.total || 0) - (preview?.purchase?.balanceUsed || 0)) / 100
          | currency) : '-'}}
        </strong>
      </ng-container>
    </div>

    <!-- Buttons -->
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button mat-stroked-button type="submit" color="primary" [disabled]="form.invalid || processing" style="flex: 1">
        <span>Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
