import { UpdateVideoDto } from '@greco/videos';
import { Type } from 'class-transformer';
import { IsDate, IsOptional } from 'class-validator';

export class UpdateEventVideoDto extends UpdateVideoDto {
  @IsDate()
  @IsOptional()
  @Type(() => Date)
  availableOn?: Date | null;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  availableUntil?: Date | null;
}
