import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { EventWithUserDetails } from '@greco/booking-events2';
import { AlertConfig, AlertType } from '@greco/ngx-alerts';
import { PropertyListener } from '@greco/property-listener-util';
import moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'alt-event-time-alert',
  templateUrl: './event-time-alert.component.html',
  styleUrls: ['./event-time-alert.component.scss'],
})
export class EventTimeAlertComponent {
  constructor(private breakpointObserver: BreakpointObserver, public eventSvc: EventService) {}

  @PropertyListener('event') private event$ = new BehaviorSubject<EventWithUserDetails | null>(null);
  @Input() event?: EventWithUserDetails;

  @PropertyListener('staffView') private staffView$ = new BehaviorSubject<boolean | null>(null);
  @Input() staffView?: boolean;

  isMobile$ = this.breakpointObserver.observe('(max-width: 399px)').pipe(map(result => result.matches));

  alert$ = combineLatest([this.event$, this.staffView$]).pipe(
    map(([event, staffView]) => {
      if (!event) return null;

      const now = new Date();

      const start = event.startTime;
      const end = new Date(start.getTime() + event.duration * 60 * 1000);

      if (now.getTime() > start.getTime() && now.getTime() < end.getTime()) {
        return {
          title: 'Event is Currently Running',
          type: AlertType.WARN,
          description: 'This event started on ' + moment(start).format('dddd, MMMM Do, @h:mm a'),
        } as AlertConfig;
      } else if (now.getTime() > end.getTime()) {
        return {
          title: 'Event Completed',
          type: staffView ? AlertType.WARN : AlertType.DANGER,
          description: 'This event has been completed',
        };
      } else return null;
    })
  );
}
