import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import {
  VideoCollectionSecurityResource,
  VideoCollectionSecurityResourceAction,
  VideoSubCollection,
} from '@greco/video-library';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { VideoSubCollectionsService } from '../../services';

@Component({
  selector: 'greco-playlist-admin',
  templateUrl: './playlist-admin.component.html',
  styleUrls: ['./playlist-admin.component.scss'],
})
export class PlaylistAdminComponent implements AfterViewInit {
  @Input() expanded = false;
  @Input() scrollTo = false;

  loadVideos = false;

  @Input() set subcollection(subcollection) {
    this.form.setValue({
      title: subcollection?.title || null,
      icon: subcollection?.icon || null,
      description: subcollection?.description || null,
      availableOn: subcollection?.availableOn || null,
      availableUntil: subcollection?.availableUntil || null,
    });
    this.subcollection$.next(subcollection);
  }

  get subcollection() {
    return this.subcollection$.value;
  }
  subcollection$ = new BehaviorSubject<VideoSubCollection | null>(null);

  readonly canManage$ = this.subcollection$.pipe(
    switchMap(async sub => {
      const community = sub?.collection?.communityId;
      return community
        ? await this.comSecSvc.hasAccess(
            community,
            VideoCollectionSecurityResource.key,
            VideoCollectionSecurityResourceAction.MANAGE
          )
        : null;
    })
  );

  readonly canRead$ = this.subcollection$.pipe(
    switchMap(async sub => {
      const community = sub?.collection?.communityId;
      return community
        ? await this.comSecSvc.hasAccess(
            community,
            VideoCollectionSecurityResource.key,
            VideoCollectionSecurityResourceAction.READ
          )
        : null;
    })
  );

  @Input() length!: number;
  @Output() sortChanged = new EventEmitter();

  changed = false;

  constructor(
    private playlistSvc: VideoSubCollectionsService,
    private formBuilder: FormBuilder,
    private comSecSvc: CommunitySecurityService,
    private snacks: MatSnackBar
  ) {}

  form = this.formBuilder.group({
    title: ['', Validators.required],
    icon: [''],
    description: [''],
    availableOn: null,
    availableUntil: null,
  });

  ngAfterViewInit() {
    if (this.scrollTo) {
      this.scrollToId();
    }
  }
  scrollToId() {
    if (!this.subcollection) return;
    const id = this.subcollection?.id;
    console.log('scroll to', id);
    const scrollElement = document.getElementById(id);
    console.log({ scrollElement });
    if (scrollElement) scrollElement.scrollIntoView({ behavior: 'smooth' });
  }

  async updateSortIndex(moveUp: boolean) {
    if (this.subcollection) {
      const sortIndex = moveUp ? this.subcollection.sortIndex - 1 : this.subcollection.sortIndex + 1;
      await this.playlistSvc.updateSortIndex(this.subcollection.id, sortIndex);
      this.sortChanged.emit();
    }
  }

  async changeStatus() {
    if (this.subcollection) {
      const isActive =
        this.subcollection.availableOn &&
        this.subcollection.availableOn.getTime() < Date.now() &&
        (!this.subcollection.availableUntil || this.subcollection.availableUntil.getTime() > Date.now());
      try {
        const availableOn = isActive ? this.subcollection.availableOn : new Date();
        const availableUntil = isActive
          ? new Date()
          : this.subcollection.availableUntil && this.subcollection.availableUntil.getTime() < Date.now()
          ? null
          : this.subcollection.availableUntil;
        const subcollection = await this.playlistSvc.updatePlaylistDetails(this.subcollection.id, {
          availableOn: availableOn,
          availableUntil: availableUntil,
        });
        this.refresh(subcollection);
        this.snacks.open('Status changed successfully', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err) {
        console.error(err);
        this.snacks.open('Oops, something went wrong. Please try again.', 'Ok', {
          duration: 5000,
          panelClass: 'mat-warn',
        });
      }
    }
  }

  async save() {
    if (this.subcollection) {
      const title = this.form.get('title')?.value || this.subcollection.title;
      const icon = this.form.get('icon')?.value || this.subcollection.icon;
      const description = this.form.get('description')?.value || this.subcollection.description;
      const availableOn = this.form.get('availableOn')?.value || null;
      const availableUntil = this.form.get('availableUntil')?.value || null;
      if (!title) return;
      const sub = await this.playlistSvc.updatePlaylistDetails(this.subcollection.id, {
        title: title,
        icon: icon,
        description: description,
        availableOn,
        availableUntil,
      });
      if (sub) {
        this.form.markAsPristine();
        this.changed = false;
        this.subcollection = sub;
        this.snacks.open('Subcollection successfully updated', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      }
    }
  }

  refresh(sub: VideoSubCollection) {
    this.subcollection = null;
    this.subcollection = sub;
  }
}
