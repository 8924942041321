import { ENTER } from '@angular/cdk/keycodes';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import {
  CreateCommunityGiftCardConfigDto,
  GiftCardOptionDto,
  UpdateCommunityGiftCardConfigDto,
} from '@greco/api-gift-cards';
import { CommunityGiftCardConfig, GiftCardsSecurityAction, GiftCardsSecurityResource } from '@greco/gift-cards';
import { Community } from '@greco/identity-communities';
import { CommunityService } from '@greco/ngx-identity-communities';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ConfigureGiftCardOptionDialog } from '../../dialogs';
import { GiftCardConfigService } from '../../services';

@Component({
  selector: 'greco-gift-card-config-page',
  templateUrl: './gift-card-config.page.html',
  styleUrls: ['./gift-card-config.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class GiftCardConfigPage {
  constructor(
    private configSvc: GiftCardConfigService,
    private securitySvc: SecurityService,
    private communitySvc: CommunityService,
    private fb: FormBuilder,
    private snacks: MatSnackBar,
    private dialog: MatDialog
  ) {}

  @PropertyListener('communityId') communityId$ = new BehaviorSubject<string | null>(null);
  @Input() communityId!: string;

  loading = false;

  readonly separatorKeysCodes = [ENTER] as const;
  addOnBlur = true;

  amountInputControl = new FormControl(null as string | null);

  options: GiftCardOptionDto[] = [];
  deletedOptionIds: string[] = [];

  form = this.fb.group({
    communityId: [null as string | null],
    community: [null as Community | null],
    options: [null as string | null],
    deletedOptionIds: [''],
    allowCustomAmount: [false],
    minimumCustomAmount: [null as number | null, Validators.min(5)],
    maximumCustomAmount: [null as number | null, Validators.min(5)],
    enabled: [false],
    title: [null as string | null],
    description: [null as string | null],
  });

  resetValue = {
    communityId: null as string | null,
    community: null as Community | null,
    options: '',
    deletedOptionIds: '',
    allowCustomAmount: false,
    minimumCustomAmount: null as number | null,
    maximumCustomAmount: null as number | null,
    enabled: false,
    title: null as string | null,
    description: null as string | null,
  };

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  community$ = this.communityId$.pipe(
    switchMap(async communityId => {
      return communityId ? await this.communitySvc.getCommunity(communityId) : null;
    }),
    tap(community => {
      if (community) {
        this.form.patchValue({
          communityId: community.id,
          community: community,
        });
      }
    })
  );

  canRead$ = this.communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(GiftCardsSecurityResource.key, GiftCardsSecurityAction.READ_CONFIG, {
            communityId,
          })
        : false;
    })
  );

  canManage$ = this.communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(GiftCardsSecurityResource.key, GiftCardsSecurityAction.MANAGE_CONFIG, {
            communityId,
          })
        : false;
    })
  );

  config$ = this.communityId$.pipe(
    switchMap(async communityId => {
      return communityId ? await this.configSvc.getOne(communityId) : null;
    }),
    tap(config => {
      this.resetValue = {
        communityId: config?.communityId || this.resetValue.communityId,
        community: config?.community || this.resetValue.community,
        options: '',
        deletedOptionIds: '',
        allowCustomAmount: config?.allowCustomAmount || false,
        minimumCustomAmount: config?.minimumCustomAmount ? config.minimumCustomAmount / 100 : null,
        maximumCustomAmount: config?.maximumCustomAmount ? config.maximumCustomAmount / 100 : null,
        enabled: config?.enabled || false,
        title: config?.title || null,
        description: config?.description || null,
      };

      this.options = config?.options || [];
      this.deletedOptionIds = [];
      this.amountInputControl.setValue(null);
      this.form.reset(this.resetValue);
    })
  );

  async openConfigureOptionDialog(option?: GiftCardOptionDto, index: number = -1) {
    const dialog = this.dialog.open(ConfigureGiftCardOptionDialog, {
      data: { option },
      width: '800px',
      maxWidth: '90%',
    });

    const dto = await toPromise(dialog.afterClosed());

    if (!dto) return;

    option ? this.updateOption(dto, index) : this.addOption(dto);
  }

  addOption(option: GiftCardOptionDto) {
    if (this.options.some(opt => opt.amount === option.amount && opt.price === option.price)) {
      this.snacks.open('Amount is already configured!', 'Ok', { duration: 3000, panelClass: 'mat-warn' });
      return;
    }

    this.options = [...this.options, option];
    this.form.patchValue({ options: this.options.map(dto => dto.id || dto.amount).join(',') }, { emitEvent: true });
    this.form.markAsDirty();
  }

  updateOption(option: GiftCardOptionDto, index: number) {
    this.options[index] = option;
    this.options = [...this.options];

    this.form.patchValue({ options: this.options.map(dto => dto.id || dto.amount).join(',') }, { emitEvent: true });
    this.form.markAsDirty();
  }

  removeOption(option: GiftCardOptionDto) {
    if (option.id) {
      this.options = [...this.options.filter(o => o.id !== option.id)];
      this.deletedOptionIds = [...this.deletedOptionIds, option.id];
      this.form.patchValue(
        {
          options: this.options.map(dto => dto.id || dto.amount).join(','),
          deletedOptionIds: this.deletedOptionIds.join(','),
        },
        { emitEvent: true }
      );
    } else {
      this.options = [...this.options.filter(o => !(o.amount === option.amount && o.price === option.price))];
      this.form.patchValue({ options: this.options.map(dto => dto.id || dto.amount).join(',') }, { emitEvent: true });
      this.form.markAsDirty();
    }
  }

  async save(options: GiftCardOptionDto[], deletedOptionIds: string[], values: any) {
    const config = await toPromise(this.config$);

    this.loading = true;
    try {
      if (!config) {
        const data: CreateCommunityGiftCardConfigDto = {
          options,
          allowCustomAmount: values.allowCustomAmount,
          minimumCustomAmount:
            values.allowCustomAmount && values.minimumCustomAmount ? values.minimumCustomAmount * 100 : null,
          maximumCustomAmount:
            values.allowCustomAmount && values.maximumCustomAmount ? values.maximumCustomAmount * 100 : null,
          title: values.title || null,
          description: values.description || null,
        };

        await this.configSvc.create(this.communityId, data);
        this.reset();
        this.communityId$.next(this.communityId);
      } else {
        const data: UpdateCommunityGiftCardConfigDto = {
          options,
          deletedOptionIds,
          allowCustomAmount: values.allowCustomAmount,
          minimumCustomAmount:
            values.allowCustomAmount && values.minimumCustomAmount ? values.minimumCustomAmount * 100 : null,
          maximumCustomAmount:
            values.allowCustomAmount && values.maximumCustomAmount ? values.maximumCustomAmount * 100 : null,
          enabled: values.enabled,
          ...(values.title !== config.title && { title: values.title || null }),
          ...(values.description !== config.description && { description: values.description || null }),
        };

        await this.configSvc.update(this.communityId, data);
        this.reset();
        this.communityId$.next(this.communityId);
      }
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong. Please try again!', 'Ok', { duration: 3000, panelClass: 'mat-warn' });
    }

    this.loading = false;
  }

  reset(config?: CommunityGiftCardConfig | null) {
    this.deletedOptionIds = [];
    this.options = config?.options || [];
    this.form.reset();
    this.form.markAsPristine();
  }
}
