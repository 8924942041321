import { Component, OnInit } from '@angular/core';
import { LegalService } from '@greco/ngx-platform-legal';
import { LegalDocument } from '@greco/platform-legal';

@Component({
  selector: 'greco-privacy-page',
  templateUrl: './privacy.page.html',
  styleUrls: ['./privacy.page.scss'],
})
export class PrivacyPage implements OnInit {
  constructor(private legalSvc: LegalService) {}

  document: LegalDocument | null = null;

  async ngOnInit() {
    this.document = await this.legalSvc.getPrivacyPolicy();
  }
}
