import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  CommunityVideoUnlockOption,
  CreateCommunityVideoUnlockOptionDto,
  UpdateCommunityVideoUnlockOptionDto,
} from '@greco/community-videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationOptions } from 'nestjs-typeorm-paginate';

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}

@Injectable()
export class CommunityVideoUnlockOptionsService {
  constructor(private http: HttpClient) {}

  private basePath = '/api/community-video-unlock-options';

  async paginate(
    query: RequestQueryBuilder,
    communityId: string,
    pagination: IPaginationOptions
  ): Promise<PaginatedDto<CommunityVideoUnlockOption>> {
    return await toPromise(
      this.http.get<PaginatedDto<CommunityVideoUnlockOption>>(`${this.basePath}/paginate`, {
        params: {
          ...query.queryObject,
          communityId: communityId,
          ...paginationQueryParams(pagination),
        },
      })
    );
  }

  paginateUnlockOptions(communityId: string, pagination?: PaginatedQueryParams) {
    return toPromise(
      this.http.get<PaginatedDto<CommunityVideoUnlockOption>>(this.basePath, {
        params: {
          communityId,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  getUnlockOption(unlockOptionId: string) {
    return toPromise(this.http.get<CommunityVideoUnlockOption>(`${this.basePath}/${unlockOptionId}`));
  }

  createCommunityVideoUnlockOption(dto: CreateCommunityVideoUnlockOptionDto) {
    return toPromise(this.http.post<CommunityVideoUnlockOption>(`${this.basePath}`, dto));
  }

  updateCommunityVideoUnlockOption(unlockOptionId: string, dto: UpdateCommunityVideoUnlockOptionDto) {
    return toPromise(this.http.patch<CommunityVideoUnlockOption>(`${this.basePath}/${unlockOptionId}`, dto));
  }
}
