import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { ContactNote, ContactNoteVersion } from '@greco/identity-contacts';
import { SecurityService } from '@greco/ngx-security-util';
import { Subject } from 'rxjs';
import { ContactNotesService } from '../../services';

@Component({
  selector: 'greco-note-versions-dialog',
  templateUrl: './note-versions.dialog.html',
  styleUrls: ['./note-versions.dialog.scss'],
})
export class NoteVersionsDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { note: ContactNote },
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private noteSvc: ContactNotesService,
    private securitySvc: SecurityService,
    private dialogRef: MatDialogRef<NoteVersionsDialog>
  ) {}

  private _onDestroy$ = new Subject<void>();

  dialogData: DialogData = {
    title: 'Version Timeline',
    showCloseButton: true,
  };

  note: ContactNote = this.data.note;
  originalContent!: string;
  versions: ContactNoteVersion[] = [];

  async ngOnInit() {
    this.versions = await this.noteSvc.getVersions(this.data.note.id);
    this.originalContent = this.versions.find(version => version.version === 0)?.originalContent || '';
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
