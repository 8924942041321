<ng-container *ngIf="groups.length">
  <ng-container *ngFor="let group of groups">
    <greco-admin-event-list-group
      [id]="group.id"
      [events]="group.events"
      [dayHeader]="group.day"
      [userBookings]="userBookings"
      [userWaitlist]="userWaitlist"
      [pagination]="eventsCount > 35"
      [contact]="contact || undefined"
      (status)="getStatus($event)"
    ></greco-admin-event-list-group>
  </ng-container>
</ng-container>
