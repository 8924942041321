<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form">
    <!-- Title -->
    <mat-form-field floatLabel="always" appearance="standard" style="width: 45%">
      <mat-label>Filter by Title</mat-label>
      <input matInput (keyup)="onKey()" formControlName="title" placeholder="Search by Video Title" />
    </mat-form-field>
    <mat-form-field style="width: 10%; visibility: hidden">
      <mat-label>Spacer</mat-label>
      <input readOnly="true" matInput formControlName="spacer"
    /></mat-form-field>
    <!-- Tags -->
    <mat-form-field floatLabel="always" appearance="standard" style="width: 45%">
      <mat-label>Filter by Tags</mat-label>
      <mat-select
        matInput
        multiple
        formControlName="tags"
        placeholder="Search by Video Tag"
        panelClass="myPanelClass"
        (selectionChange)="refresh$.next(null)"
      >
        <input
          matInput
          id="tagSearch"
          (keyup)="onKey()"
          placeholder="Find a tag"
          style="padding-top: 5px; padding-bottom: 5px"
        />
        <ng-container *ngFor="let tag of (tagOptions$ | async) || []">
          <mat-option [ngClass]="filterTags(tag) ? '' : 'hidden'" [value]="tag">{{ tag.label }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </form>

  <greco-table [highlight]="true" [data]="(videos$ | async) || []" (rowClick)="(null)">
    <mat-icon *grecoTableCol="''; fitContent: true; let video" [matTooltip]="video.video.title">
      video_settings
    </mat-icon>

    <!-- showVideoName -->
    <ng-container>
      <p *grecoTableCol="'Title'; let video" [matTooltip]="video.video.title">
        {{ video.video.title }}
      </p>
    </ng-container>
    <!-- Date -->
    <ng-container>
      <p *grecoTableCol="'Upload Date'; let video" [matTooltip]="'Upload Date'">
        {{ video.video.created | date }}
      </p>
    </ng-container>
    <!-- Uploader -->
    <ng-container>
      <p *grecoTableCol="'Uploaded By'; let video" [matTooltip]="'Uploaded By'">
        {{ video.video.uploadedBy.displayName }}
      </p>
    </ng-container>
    <!-- events 
    <ng-container>
      <p *grecoTableCol="'Number of Events'; fitCount: true; let video" [matTooltip]="'Tags'">
        {{ (video | videoEvents | async)?.length || 0 }}
      </p>
    </ng-container>-->
    <!-- tags -->
    <ng-container>
      <p *grecoTableCol="'Tags'; fitContent: true; let video" [matTooltip]="'Tags'">
        <greco-video-tag [admin]="true" *ngFor="let tag of video.video.tags" [tag]="tag"></greco-video-tag>
      </p>
    </ng-container>
    <!-- spacer 
  <ng-container>
    <span *grecoTableCol="''; fitContent: false"></span>
  </ng-container>-->
    <!-- watch -->
    <ng-container>
      <button
        *grecoTableCol="''; let video; alignRight: true"
        [matTooltip]="'Watch ' + video.video.title | titlecase"
        mat-stroked-button
        style="height: 32px; line-height: 16; margin-left: auto"
        (click)="watch(video)"
      >
        Watch
      </button>
    </ng-container>
    <!-- Add -->
    <ng-container>
      <ng-container *grecoTableCol="''; let video; fitContent: true; stickyEnd: true">
        <button
          mat-icon-button
          [matTooltip]="'Add ' + video.video.title + ' to current event'"
          (click)="$event.stopImmediatePropagation(); addVideo(video)"
          style="color: --primary"
        >
          <mat-icon>add</mat-icon>
        </button>
      </ng-container>
    </ng-container>
  </greco-table>
</greco-simple-dialog>
