<ng-container *grecoLet="canRevokePerks$ | async as canRevokePerksPermission">
  <ng-container *grecoLet="canUnfreezePerks$ | async as canUnfreezePerks">
    <!-- Permissions async check should always be outside of the table rows -->
    <greco-table [data]="(perks$ | async) || []">
      <greco-perk-badge
        *grecoTableCol="''; let groupedPerk; fitContent: true"
        [shape]="groupedPerk.perk.badge.shape || 'squircle'"
        [icon]="groupedPerk.perk.badge.icon || ''"
        [text]="groupedPerk.perk.badge.text"
        [color]="groupedPerk.perk.badge.color"
        [small]="true"
      ></greco-perk-badge>

      <p *grecoTableCol="'Perk'; let groupedPerk">{{ groupedPerk.perk?.title }}</p>
      <p *grecoTableCol="'Community'; let groupedPerk">{{ groupedPerk.perk.community?.name }}</p>
      <p *grecoTableCol="'Quantity'; let groupedPerk">{{ groupedPerk.quantity }}</p>
      <p *grecoTableCol="'Reusable'; let groupedPerk">{{ groupedPerk.reusable }}</p>
      <p *grecoTableCol="'Frozen'; let groupedPerk">{{ groupedPerk.frozen ? 'Frozen' : '' }}</p>
      <p *grecoTableCol="'Expiry'; let groupedPerk">{{ groupedPerk.expiry }}</p>
      <p *grecoTableCol="'Expiry Notification'; let groupedPerk">{{ groupedPerk.expiryNotification }}</p>

      <ng-container *ngIf="showUsed">
        <p *grecoTableCol="'Removed/Consumed'; let groupedPerk">{{ groupedPerk.deleted }}</p>
      </ng-container>

      <p *grecoTableCol="'Granted By'; let userPerk" [matTooltip]="userPerk.grantedBy">
        <ng-container *ngIf="userPerk.grantedBy | test : '^subitm_'; else fromPurchase">Subscription</ng-container>

        <ng-template #fromPurchase>
          <ng-container *ngIf="userPerk.grantedBy | test : '^puritm_'; else fromStaff">One-Time Purchase</ng-container>
        </ng-template>

        <ng-template #fromStaff>
          {{ userPerk?.grantedByUser?.displayName || (userPerk.grantedBy ? 'Staff' : '') }}
        </ng-template>
      </p>

      <ng-container *ngIf="!showUsed">
        <ng-container *grecoTableCol="''; let groupedPerk; fitContent: true; stickyEnd: true">
          <button
            *ngIf="
              (canRevokePerksPermission && groupedPerk.deleted === '-') || (groupedPerk.frozen && canUnfreezePerks)
            "
            mat-icon-button
            [matMenuTriggerFor]="menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <button
              *ngIf="canRevokePerksPermission && groupedPerk.deleted === '-'"
              mat-menu-item
              [disabled]="
                (groupedPerk.perk.community?.id !== communityId && !(userSvc.user$ | async)?.isSuperAdmin) ||
                !canRevokePerks
              "
              (click)="
                _removePerks(
                  groupedPerk.perk,
                  groupedPerk.expiry,
                  groupedPerk.expiryNotification,
                  groupedPerk.reusable,
                  groupedPerk.frozen,
                  groupedPerk.deleted
                )
              "
            >
              <mat-icon>delete</mat-icon>
              <span>Remove Perk(s)</span>
            </button>
            <button
              *ngIf="groupedPerk.frozen && canUnfreezePerks"
              mat-menu-item
              (click)="forceUnfreezePerks(groupedPerk.perk)"
            >
              <mat-icon>play_circle</mat-icon>
              <span>Force Unfreeze Perk(s)</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </greco-table>
  </ng-container>
</ng-container>
