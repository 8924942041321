import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { CommunityVideoTag } from '@greco/community-videos';

@Injectable({ providedIn: 'root' })
export class CommunityVideoTagsService {
  constructor(private http: HttpClient) {}

  getMany(communityId?: string, search?: string) {
    return toPromise(
      this.http.get<CommunityVideoTag[]>('/api/community-video-tags', {
        params: communityId ? { communityId, search: search || '' } : { search: search || '' },
      })
    );
  }

  create(communityId: string, label: string) {
    return toPromise(this.http.post<CommunityVideoTag>('/api/community-video-tags', { communityId, label }));
  }
}
