import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AlertsModule } from '@greco/ngx-alerts';
import { DataExportsModule } from '@greco/ngx-data-exports';
import { FiltersModule } from '@greco/ngx-filters';
import { AccountsModule } from '@greco/ngx-finance-accounts';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { GrecoFinanceFeatureTaxModule } from '@greco/ngx-finance-tax';
import { HighlightPipeModule } from '@greco/ngx-highlight-pipe';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { UsersModule } from '@greco/ngx-identity-users';
import { JoinPipeModule } from '@greco/ngx-join-pipe';
import { LetModule } from '@greco/ngx-let-util';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { SmartImgModule } from '@greco/ui-smart-img';
import { TableModule } from '@greco/ui-table';
import { TabsModule } from '@greco/ui-tabs';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { AccountLinkingModule } from '@greco/web-account-linking';
import { DynamicFormsMaterialUIModule } from '@ng-dynamic-forms/ui-material';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import {
  BalanceHistorySectionComponent,
  FundUserBalanceButtonComponent,
  PurchaseOptionsComponent,
  PurchasePreviewComponent,
  PurchasesTableComponent,
  PurchaseStatusChipComponent,
  SaleCategoriesTableComponent,
  SaleCategoryInputComponent,
  SaleCategoryPickerComponent,
} from './components';
import { DefaultSaleCategoryComponent } from './components/default-sale-category/default-sale-category.component';
import { PurchasePreviewTableComponent } from './components/purchase-preview-table/purchase-preview-table.component';
import { DEFAULT_SALE_CATEGORY_DEFINITIONS, DefaultSaleCategoryDefinitions } from './default-sale-category.token';
import {
  AdjustBalanceDialog,
  BulkRetryPurchaseDialog,
  BulkVoidPurchaseDialog,
  CreateCustomChargeDialog,
  DefaultSaleCategoriesDialog,
  FundUserBalanceConfimationDialog,
  FundUserBalanceDialog,
  RefundPurchaseDialog,
  RetryFailedPurchaseDialog,
  SaleCategoryDialog,
  SetPurchaseReferredByDialog,
  SetPurchaseSoldByDialog,
  UndoRefundPurchaseDialog,
  VoidPurchaseDialog,
} from './dialogs';
import { ExportPurchasesDirective } from './directives';
import { PurchasePage, PurchasesPage, SaleCategoriesPage, SaleCategoryPage } from './pages';
import {
  ContainsItemPipe,
  FilterCouponPipe,
  PurchaseCommunityIdPipe,
  PurchaseSubtotalPipe,
  PurchaseTaxPipe,
  PurchaseTotalPipe,
  RecurrenceLabelPipe,
  TrimCouponPipe,
} from './pipes';
import { RETURN_TO_INVENTORY_DIALOG } from './return-to-inventory.token';
import {
  CustomChargeServiceModule,
  PaymentHandler,
  PurchaseServiceModule,
  SaleCategoryService,
  UserBalanceService,
} from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormsMaterialUIModule,
    LetModule,

    // Internal
    PurchaseServiceModule,
    CustomChargeServiceModule,

    // Greco
    BoxModule,
    LetModule,
    TabsModule,
    TableModule,
    UsersModule,
    FiltersModule,
    SmartImgModule,
    AccountsModule,
    ContactsModule,
    PaymentsModule,
    DataExportsModule,
    SimpleDialogModule,
    HighlightPipeModule,
    CollapsibleSectionModule,
    FormSaveButtonsModule,
    UserAvatarModule,
    GrecoFinanceFeatureTaxModule,
    AlertsModule,

    // Material
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatRadioModule,
    MatStepperModule,

    // Other
    NgPipesModule,
    SafePipeModule,
    NgxCurrencyModule,
    JoinPipeModule,
    AccountLinkingModule,
  ],
  declarations: [
    // Directives
    ExportPurchasesDirective,

    // Components
    PurchasePreviewComponent,
    PurchasesTableComponent,
    PurchaseOptionsComponent,
    PurchaseStatusChipComponent,
    BalanceHistorySectionComponent,
    SaleCategoriesTableComponent,
    SaleCategoryPickerComponent,

    // Dialogs
    AdjustBalanceDialog,
    CreateCustomChargeDialog,
    RetryFailedPurchaseDialog,
    RefundPurchaseDialog,
    UndoRefundPurchaseDialog,
    SaleCategoryDialog,
    DefaultSaleCategoriesDialog,
    SetPurchaseSoldByDialog,
    SetPurchaseReferredByDialog,
    VoidPurchaseDialog,
    BulkRetryPurchaseDialog,
    BulkVoidPurchaseDialog,

    // Pipes
    PurchaseTaxPipe,
    PurchaseTotalPipe,
    PurchaseSubtotalPipe,
    PurchaseCommunityIdPipe,
    FilterCouponPipe,
    TrimCouponPipe,
    RecurrenceLabelPipe,
    ContainsItemPipe,

    // Pages
    PurchasePage,
    PurchasesPage,
    SaleCategoryInputComponent,
    FundUserBalanceButtonComponent,
    FundUserBalanceDialog,
    FundUserBalanceConfimationDialog,
    SaleCategoriesPage,
    SaleCategoryPage,
    DefaultSaleCategoryComponent,
    PurchasePreviewTableComponent,
  ],
  exports: [
    PurchasePage,
    PurchasesPage,
    PurchasePreviewComponent,
    PurchasesTableComponent,
    ExportPurchasesDirective,
    AdjustBalanceDialog,
    BalanceHistorySectionComponent,
    SaleCategoryInputComponent,
    FundUserBalanceButtonComponent,
    SaleCategoriesPage,
    SaleCategoryPickerComponent,
    PurchaseOptionsComponent,
    PurchaseStatusChipComponent,
    SaleCategoryPage,
    PurchasePreviewTableComponent,

    // Pipes
    PurchaseSubtotalPipe,
    PurchaseTaxPipe,
    PurchaseTotalPipe,
  ],
  providers: [UserBalanceService, SaleCategoryService],
})
export class PurchasesModule {
  static forRoot(
    defaultSaleCategoryDefinitions: DefaultSaleCategoryDefinitions[],
    inventoryReturnDialog: any,
    paymentHandlers?: Type<PaymentHandler>[]
  ) {
    return {
      ngModule: PurchasesModule,
      providers: [
        { provide: DEFAULT_SALE_CATEGORY_DEFINITIONS, useValue: defaultSaleCategoryDefinitions },
        { provide: RETURN_TO_INVENTORY_DIALOG, useValue: inventoryReturnDialog },
        ...(PurchaseServiceModule.forRoot(paymentHandlers).providers ?? []),
      ],
    };
  }
}
