import { Component, Input } from '@angular/core';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { Community } from '@greco/identity-communities';
import { PropertyListener } from '@greco/property-listener-util';
import moment from 'moment';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CheckInService } from '../../services';

@Component({
  selector: 'greco-check-in-table',
  templateUrl: './check-in-table.component.html',
  styleUrls: ['./check-in-table.component.scss'],
})
export class CheckInTableComponent {
  constructor(private checkedInUserSvc: CheckInService) {}

  @PropertyListener('userId') private userId$ = new ReplaySubject<string>();
  @Input() userId!: string;

  @PropertyListener('community') private community$ = new BehaviorSubject<Community | null>(null);
  @Input() community?: Community;

  moment = moment;

  readonly pagination$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({ limit: 5 });

  checkIns$ = combineLatest([this.userId$, this.community$, this.pagination$]).pipe(
    switchMap(async ([userId, community, pagination]) => {
      if (!community) return null;

      return await this.checkedInUserSvc.getUserCheckIns(userId, community.id, pagination);
    })
  );
}
