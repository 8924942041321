import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { UpdateInventoryAddonDto } from '@greco/nestjs-sales-products';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  AddonType,
  InventoryProductAddon,
  Product,
  ProductStatus,
  ProductVariant,
  VariantResource,
  VariantResourceAction,
} from '@greco/sales-products';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest, ReplaySubject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AddonsService, InventoryService, ProductsService, VariantsService } from '../../services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-inventory-addon-page',
  templateUrl: './inventory-addon.page.html',
  styleUrls: ['./inventory-addon.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InventoryAddonPage implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private addonSvc: AddonsService,
    private inventorySvc: InventoryService,
    private variantSvc: VariantsService,
    private productSvc: ProductsService,
    private clipboardSvc: ClipboardService,
    private productsService: ProductsService,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('product') private _product$ = new ReplaySubject<Product>(1);
  @Input() product!: Product;

  @Output() refresh = new EventEmitter();

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  private listeners: Subscription[] = [];

  inventoryResetValue: any = {
    inventories: new Map<string, FormControl>(),
    displayStockNumber: true,
    outOfStockMessage: null,
  };

  inventoryFormGroup = this.formBuilder.group({
    inventories: new Map<string, FormControl>(),
    displayStockNumber: [true],
    outOfStockMessage: [null as string | null],
  });

  processing = false;
  restocking = false;

  resetValue: any = { title: '', description: '', images: null, saleCategory: null, taxes: [], ignoreTaxes: false };

  formGroup = this.formBuilder.group({
    title: ['', Validators.required],
    description: [''],
    images: [null],
    saleCategory: [null, Validators.required],
    taxes: [null],
    ignoreTaxes: [false],
  });

  initialImages: File[] = [];
  initialUrls: string[] = [];

  refresh$ = new BehaviorSubject(null);
  refreshVariants$ = new BehaviorSubject(null);

  canManageInventory$ = this._product$.pipe(
    switchMap(async product => {
      return product?.community?.id
        ? await this.securitySvc.hasAccess(VariantResource.key, VariantResourceAction.MANAGE_INVENTORY, {
            communityId: product.community.id,
          })
        : false;
    })
  );

  inventoryAddon$ = combineLatest([this._product$, this.refresh$]).pipe(
    switchMap(async ([product]) => {
      if (!product) return null;
      return await this.addonSvc.getOneByType(product.id, AddonType.Inventory);
    }),
    tap(addon => {
      if (!addon) return;
      this.inventoryResetValue = {
        inventories: new Map<string, FormControl>(),
        displayStockNumber: (addon as InventoryProductAddon).displayStockNumber || false,
        outOfStockMessage: (addon as InventoryProductAddon).outOfStockMessage || null,
      };
      this.resetInventoryForm(this.inventoryResetValue);
    })
  );

  variantInventories$ = combineLatest([this.inventoryAddon$, this.refreshVariants$]).pipe(
    switchMap(async ([addon]) =>
      addon
        ? (await this.inventorySvc.getVariantInventories(addon.id)).sort(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (v0, v1) => v1.variant!.priority - v0.variant!.priority
          )
        : []
    )
  );

  productVariants$ = combineLatest([this._product$, this.variantInventories$]).pipe(
    switchMap(async ([product, inventories]) => {
      if (!product) return [];

      return (
        await this.variantSvc.paginateVariants(
          { productId: product.id, pagination: { limit: 200 } },

          RequestQueryBuilder.create({
            search: {
              $and: [
                {
                  status: { $in: [ProductStatus.Active, ProductStatus.Draft] },
                  ...(inventories?.length && {
                    id: { $notin: inventories.map(i => i.variantId) },
                  }),
                },
              ],
            },
          })
        )
      ).items;
    })
  );

  resetImages() {
    this.initialImages = [];
    this.initialUrls = [];
    if (this.product?.images && this.product?.images.length) {
      for (const image of this.product.images) {
        const file = new File([], image.imageURL.split('images/').pop() || '');
        this.initialImages.push(file);
        this.initialUrls.push(image.imageURL);
      }
    }
  }

  resetForm() {
    this.resetImages();
    this.formGroup.markAsPristine();
  }

  resetInventoryForm(resetValue: any) {
    this.inventoryFormGroup.reset(resetValue);
    this.inventoryFormGroup.patchValue({ inventories: new Map<string, FormControl>() });
    this.inventoryFormGroup.markAsPristine();
    this.restocking = false;
  }

  async ngOnInit() {
    this.product = await this.productsService.getOneProduct(this.product.id);

    this.resetImages();

    this.formGroup.patchValue({
      title: this.product.title,
      images: this.initialImages,
      description: this.product.description,
      saleCategory: this.product.saleCategory,
      taxes: this.product.taxes || [],
      ignoreTaxes: this.product.ignoreTaxes,
    });

    this.resetValue = {
      title: this.product.title,
      images: this.initialImages,
      description: this.product.description,
      saleCategory: this.product.saleCategory,
      taxes: this.product.taxes || [],
      ignoreTaxes: this.product.ignoreTaxes,
    };
  }

  async addVariant(variant: ProductVariant, addonId: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await this.inventorySvc.createVariantInventory(addonId!, variant.id);

    this.refreshVariants$.next(null);
  }

  addRestock(variantId: string) {
    this.restocking = true;
    const newRestock = new FormControl(0);
    const value = this.inventoryFormGroup.value.inventories;
    value.set(variantId, newRestock);
    this.inventoryFormGroup.setValue({ ...this.inventoryFormGroup.value, inventories: value });
    this.inventoryFormGroup.markAsDirty();
  }

  removeRestock(variantId: string) {
    const value: Map<string, FormControl> = this.inventoryFormGroup.value.inventories;
    value.delete(variantId);
    this.inventoryFormGroup.setValue({ ...this.inventoryFormGroup.value, inventories: value });
    this.inventoryFormGroup.markAsDirty();
    if (!value.size) {
      this.restocking = false;
    }
  }

  save = async () => {
    this.processing = true;
    try {
      const addon = await this.addonSvc.getOneByType(this.product.id, AddonType.Inventory);
      const dto: UpdateInventoryAddonDto = {
        type: AddonType.Inventory,
        displayStockNumber: this.inventoryFormGroup.value.displayStockNumber,
        outOfStockMessage: this.inventoryFormGroup.value.outOfStockMessage,
      };
      await this.addonSvc.updateAddonConfiguration(this.product.id, addon.id, dto);

      const inventories: Map<string, FormControl> = this.inventoryFormGroup.value.inventories;
      await Promise.all(
        Array.from(inventories).map(([id, control]) =>
          control.value ? this.inventorySvc.restockVariant(id, control.value) : {}
        )
      );

      this.snacks.open('Inventories updated successfully!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong!', 'Ok', { duration: 3000, panelClass: 'mat-warn' });
    }

    this.processing = false;

    this.refresh$.next(null);
  };

  async updateAddonStatus(addonId: string, status: boolean) {
    this.processing = true;
    if (this.inventoryFormGroup.dirty) {
      const dialog = this.dialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Changes Present!',
          //subtitle: 'Are you sure you want to remove this video?',
          content: 'Do you wish to save the rest of your changes as well?',
          buttons: [
            { label: 'Cancel', role: 'cancel' },
            { label: 'Discard changes', role: 'no' },
            { label: 'Save changes', role: 'yes' },
          ],
        } as DialogData,
        width: '100%',
        maxWidth: '400px',
      });
      const result = await toPromise(dialog.afterClosed());
      if (result !== 'yes' && result !== 'no') {
        this.processing = false;
        return;
      }

      if (result === 'yes') {
        await this.save();
        this.processing = true;
      }
    }
    await this.addonSvc.updateAddonStatus(this.product.id, addonId, status);

    this.processing = false;

    this.refresh$.next(null);
  }
}
