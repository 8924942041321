<form [formGroup]="formGroup">
  <mat-form-field appearance="standard">
    <mat-label>Collections</mat-label>
    <greco-collections-input [required]="true" formControlName="collections"></greco-collections-input>
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-label>Shop Order Priority</mat-label>
    <input matInput type="number" [required]="true" formControlName="priority" />
    <mat-hint>Higher priority products will be shown first in the shop.</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>Tags</mat-label>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let tag of formGroup.value.tags" removable (removed)="removeTag(tag)">
        {{ tag }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <input
        matInput
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="addTag($event)"
      />
    </mat-chip-list>
  </mat-form-field>
</form>
