<div style="margin-bottom: 32px">
  <!-- <div style="display: flex; flex-direction: row; gap: 8px">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <h1 *ngIf="title">{{ title }}</h1>
  </div> -->
  <greco-collapsible-section
    id="collection-section"
    [header]="{ title: title || '', icon: icon }"
    [disabled]="disabled"
  >
    <mat-chip-list header class="program-count"
      ><mat-chip *ngIf="total > 0">{{ total }} {{ total > 1 ? 'Programs' : 'Program' }}</mat-chip></mat-chip-list
    >
    <div class="collection-grid">
      <greco-video-collection-card
        class="card"
        *ngFor="let collection of collections"
        [collection]="collection"
        [showFeaturedStar]="showFeaturedStar"
        [userOptions]="userOptions"
      ></greco-video-collection-card>
    </div>

    <div *ngIf="!collections || collections.length <= 0" style="width: 100%">
      <p *ngIf="loading === false" style="white-space: nowrap; margin-top: 16px">
        Oops! Looks like there's nothing to see!
      </p>
      <mat-progress-bar *ngIf="loading" [mode]="'indeterminate'" style="width: 100%"></mat-progress-bar>
    </div>
    <button
      mat-stroked-button
      *ngIf="canLoadMore && total > collections.length"
      color="primary"
      style="margin-top: 24px; width: 100%"
      (click)="loadMore()"
    >
      Load More
    </button>
  </greco-collapsible-section>
</div>
