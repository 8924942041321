<greco-alert
  *ngIf="_pendingBooking$ | async as pendingBooking"
  [config]="{
    title: 'Pending Booking For Upcoming Event',
    type: error ? $any('DANGER') : $any('WARN'),
    description: pendingBooking.event.title + ' (' + (pendingBooking.event.startDate | date : 'MMM d, y, h:mm a') + ')'
  }"
>
  <div
    *grecoLet="canUserConfirmBooking$ | async as canUserConfirmBooking"
    slot="buttons"
    class="action"
    style="display: flex"
  >
    <div *ngIf="canUserConfirmBooking" matTooltip="Confirm booking">
      <button
        mat-flat-button
        color="primary"
        (click)="confirm(pendingBooking)"
        style="border-top-right-radius: 0px; border-bottom-right-radius: 0px"
      >
        Confirm Booking
      </button>
    </div>

    <div *ngIf="!isUserSide && !canUserConfirmBooking" matTooltip="Manage booking">
      <button mat-flat-button color="primary" (click)="confirmWithNewBookingOption(pendingBooking)">
        Manage Booking
      </button>
    </div>
  </div>
</greco-alert>
