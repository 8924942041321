import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { CommunityVideo } from '@greco/community-videos';
import { VideoUnlock } from '@greco/videos';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { CommunityVideoUnlockService } from '../../services';

@Component({
  templateUrl: './manage-unlock.dialog.html',
  styleUrls: ['./manage-unlock.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ManageUnlockDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private matDialogRef: MatDialogRef<ManageUnlockDialog>,
    private unlockSvc: CommunityVideoUnlockService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar
  ) {}

  communityVideo!: CommunityVideo;
  updateUnlock!: VideoUnlock;

  private _onDestroy$ = new Subject<void>();

  selectable = true;
  removable = true;

  _creating = false;

  formGroup = this.formBuilder.group({
    userEmail: ['', Validators.required],
    expiryDate: [null],
  });

  now = new Date();
  dialogData: DialogData = {
    title: 'Manage Community Video Unlock',
    //subtitle: 'Grant or update unlock to user',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Save',
        role: 'create',
        resultFn: async () => {
          try {
            const data = this.formGroup.value;
            this._creating = true;
            try {
              if (!this.updateUnlock) {
                if (data.expiryDate && data.expiryDate.getTime() < Date.now()) {
                  this.snacks.open('Selected date is in the past! Please try again.', 'Ok', {
                    duration: 3500,
                    panelClass: 'mat-warn',
                  });
                } else {
                  const unlock = await this.unlockSvc.adminGrantCommunityVideoToUser(
                    data.userEmail,
                    this.communityVideo.id,
                    data.expiryDate
                  );
                  if (!unlock) {
                    this.snacks.open('User already has base video unlocked', 'Ok', {
                      duration: 3500,
                      panelClass: 'mat-warn',
                    });
                  } else {
                    this.snacks.open('Granted video to ' + unlock.user?.displayName, 'Ok', {
                      duration: 3500,
                      panelClass: 'mat-primary',
                    });
                  }

                  this.matDialogRef.close(unlock);
                }
              } else {
                const unlock = await this.unlockSvc.updateUnlockTime(this.updateUnlock.id, data.expiryDate);
                this.snacks.open('Expiry time set to: ' + data.expiryDate, 'Ok', {
                  duration: 3500,
                  panelClass: 'mat-primary',
                });
                this.matDialogRef.close(unlock);
              }
            } catch (err) {
              console.error(err);
              this.snacks.open('Something went wrong. Please try again!', 'Ok', {
                duration: 5000,
                panelClass: 'mat-warn',
              });
            }

            this._creating = false;
          } catch (err) {
            console.error(err);
            return null;
          }
        },
      },
    ],
  };

  async ngOnInit() {
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = this.formGroup.invalid;
          return btn;
        }),
      };
    });
    if (this.updateUnlock)
      this.formGroup.setValue(
        { userEmail: this.updateUnlock.user?.contactEmail, expiryDate: this.updateUnlock.expiresOn },
        { emitEvent: true }
      );
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
