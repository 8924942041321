import { Component, Injectable, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import {
  CommunityVideo,
  CommunityVideoSecurityResource,
  CommunityVideoSecurityResourceAction,
  CreateCommunityVideoAndInitiateUploadDto,
} from '@greco/community-videos';
import { Community } from '@greco/identity-communities';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { VideoSelectComponent, VideoSendingDialog, VideosService, WatchVideoComponent } from '@greco/ngx-videos';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { CreateVideoAndInitiateUploadDto, UpdateVideoDto } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { VideoUploadDialog } from '../../dialogs';
import { CommunityVideosService } from '../../services';

@Injectable({ providedIn: 'any' })
export class CommunityVideoSearchFilter extends BuildSearchFilter('CommunityVideoSearchFilter', {
  properties: ['video.title'],
  propertyLabels: ['Title'],
}) {}

@Injectable({ providedIn: 'any' })
export class TagsLabelSearchFilter extends BuildSearchFilter('TagsLabelSearchFilter', {
  properties: ['video.tags.label'],
  propertyLabels: ['Tag'],
}) {}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-community-video-admin-table',
  templateUrl: './community-video-admin-table.component.html',
  styleUrls: ['./community-video-admin-table.component.scss'],
})
export class CommunityVideoAdminTableComponent {
  constructor(
    private communityVideosSvc: CommunityVideosService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private comSecSvc: CommunitySecurityService,
    private userSvc: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private videosSvc: VideosService
  ) {}

  loading = false;

  filterOptions = [CommunityVideoSearchFilter, TagsLabelSearchFilter];

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  pagination?: IPaginationMeta;

  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});

  @Input() set community(community) {
    this._community$.next(community);
    this.refresh$.next(null);
  }

  get community() {
    return this._community$.value;
  }

  private _community$ = new BehaviorSubject<Community | null>(null);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  now = Date.now();

  refresh$ = new BehaviorSubject(null);

  videos$ = combineLatest([this.filters$, this.page$, this._community$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([query, page, community]) => {
      return query && community ? await this.communityVideosSvc.paginate(query, community.id, page) : null;
    }),
    tap(data => (this.pagination = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  readonly canRead$ = this._community$.pipe(
    switchMap(async community => {
      return community
        ? await this.comSecSvc.hasAccess(
            community.id,
            CommunityVideoSecurityResource.key,
            CommunityVideoSecurityResourceAction.READ
          )
        : null;
    })
  );

  readonly canUpload$ = this._community$.pipe(
    switchMap(async community => {
      return community
        ? await this.comSecSvc.hasAccess(
            community.id,
            CommunityVideoSecurityResource.key,
            CommunityVideoSecurityResourceAction.UPLOAD
          )
        : null;
    })
  );

  readonly canUpdate$ = this._community$.pipe(
    switchMap(async community => {
      return community
        ? await this.comSecSvc.hasAccess(
            community.id,
            CommunityVideoSecurityResource.key,
            CommunityVideoSecurityResourceAction.UPDATE
          )
        : null;
    })
  );

  readonly canDelete$ = this._community$.pipe(
    switchMap(async community => {
      return community
        ? await this.comSecSvc.hasAccess(
            community.id,
            CommunityVideoSecurityResource.key,
            CommunityVideoSecurityResourceAction.REMOVE
          )
        : null;
    })
  );

  user$ = this.userSvc.user$;

  watch(communityVideo: CommunityVideo) {
    if (communityVideo.video) {
      const dialog = this.matDialog.open(WatchVideoComponent, { data: {}, width: '750px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.video = communityVideo.video;
    }
  }

  async upload() {
    if (this.community) {
      const dialog = this.matDialog.open(VideoUploadDialog, { data: {}, width: '750px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.communityId = this.community.id;
      instance.hideAvailableOnInput = true;
      const response: { file: File; dto: CreateVideoAndInitiateUploadDto } = await toPromise(dialog.afterClosed());
      if (response?.dto) {
        const sendingDialog = this.matDialog.open(VideoSendingDialog, {
          data: {},
          width: '450px',
          maxWidth: '90%',
        });
        sendingDialog.disableClose = true;
        const eventVideoDto: CreateCommunityVideoAndInitiateUploadDto = {
          source: response.dto.source,
          fileSize: response.dto.fileSize,
          title: response.dto.title,
          videoTagIds: response.dto.videoTagIds,
          description: response.dto.description,
          communityId: this.community.id,
        };
        try {
          await this.communityVideosSvc.upload(response.file, eventVideoDto);
        } catch (err) {
          console.error(err);
        }
        this.refresh$.next(null);
        sendingDialog.disableClose = false;
        sendingDialog.close();
      }
    }
  }

  async update(communityVideo: CommunityVideo) {
    //open update dialog for video
    const dialog = this.matDialog.open(VideoUploadDialog, { data: {}, width: '100%', maxWidth: '400px' });
    const instance = dialog.componentInstance;
    instance.communityId = this.community?.id;
    instance.video = communityVideo;
    instance.hideAvailableOnInput = true;
    const response = await toPromise(dialog.afterClosed());
    if (response.dto) {
      const dto: UpdateVideoDto = {
        title: response.dto.title || null,
        description: response.dto.description || null,
        videoTagIds: response.dto.videoTagIds || null,
      };
      try {
        await this.communityVideosSvc.update(communityVideo.id, dto);
      } catch (err) {
        console.error(err);
      }
    }
    this.refresh$.next(null);
  }

  async remove(communityVideo: CommunityVideo) {
    //open remove dialog for video and if resolves remove
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Removal',
        //subtitle: 'Are you sure you want to remove this video?',
        content: 'This will remove the video from the community, but not the platform or Vimeo',
        buttons: [
          { label: "No, Don't Remove", role: 'no' },
          { label: 'Yes, Remove Video', role: 'yes' },
        ],
      } as DialogData,
      width: '100%',
      maxWidth: '400px',
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '400px' });
      spinnerDialog.disableClose = true;
      const response = await this.communityVideosSvc.remove(communityVideo.id);
      spinnerDialog.close();
      if (!response) {
        this.snacks.open('Video removed from community', '', { duration: 2500, panelClass: 'mat-primary' });
      } else {
        this.snacks.open('Cannot remove. Archiving...', '', { duration: 2500, panelClass: 'mat-warn' });
      }
      //reload video list
      this.refresh$.next(null);
    }
  }

  async addVideo() {
    if (!this.community) return;
    const dialog = this.matDialog.open(VideoSelectComponent, { data: {}, width: '1000px', maxWidth: '90%' });
    const response = await toPromise(dialog.afterClosed());
    if (response?.length) {
      let success = 0;
      let failure = 0;
      for (const video of response) {
        try {
          await this.communityVideosSvc.linkVideoToCommunity(this.community.id, video);
          success++;
        } catch (err) {
          console.error(err);
          failure++;
        }
      }
      this.snacks.open('Successfully linked ' + success + ' videos', failure === 0 ? 'Ok!' : failure + ' errors!', {
        duration: 2500,
        panelClass: failure === 0 ? 'mat-primary' : 'mat-warn',
      });
      this.refresh$.next(null);
    }
  }

  async rowClick(communityVideo: CommunityVideo) {
    await this.router.navigate([communityVideo.id], { relativeTo: this.route });
  }

  async changeStatus(communityVideo: CommunityVideo) {
    const availableUntil = communityVideo.availableUntil ? null : new Date();
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: availableUntil ? 'Confirm Archive' : 'Confirm Activation',
        //subtitle: 'Are you sure you want to remove this video?',
        content: availableUntil
          ? 'This will hide the video from further use on the platform, but active unlocks will remain'
          : 'This video will be available for use on the platform',
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      } as DialogData,
      width: '100%',
      maxWidth: '400px',
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const response = await this.communityVideosSvc.update(communityVideo.id, { availableUntil });

      if (response) {
        this.snacks.open('Video updated', '', { duration: 2500, panelClass: 'mat-primary' });
      } else {
        this.snacks.open('Something went wrong!', '', { duration: 2500, panelClass: 'mat-warn' });
      }

      this.refresh$.next(null);
    }
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
}
