<div *ngIf="canRead$ | async">
  <div *ngIf="collectionVideo$ | async as collectionVideo">
    <greco-video-admin-page
      style="padding-bottom: 16px"
      *ngIf="collectionVideo.communityVideo && collectionVideo.communityVideo.video"
      [video]="collectionVideo.communityVideo.video"
      [publishedOn]="collectionVideo.availableOn"
      [community]="collectionVideo.communityVideo.community"
    >
      <div class="buttons" header style="display: flex; flex-direction: row; justify-content: space-evenly; gap: 8px">
        <!-- <button matTooltip="Remove from Subcollection" mat-flat-button color="warn" (click)="null">Remove</button> 

      <button matTooltip="Update Community Level Status" mat-flat-button color="primary">
        {{(!collectionVideo.availableUntil || (collectionVideo.availableUntil | grecoDateCompare: 'gt')) ? 'Archive' :
        'Activate'}}
      </button>-->

        <button *ngIf="canUpdate$ | async" matTooltip="Update Video" mat-flat-button color="primary" (click)="update()">
          Update
        </button>

        <mat-chip matTooltip="Status in Subcollection">
          {{(!collectionVideo.availableUntil || (collectionVideo.availableUntil | grecoDateCompare: 'gt')) ? 'Active' :
          'Archived'}}
        </mat-chip>
      </div>

      <div extras>
        <greco-collapsible-section [header]="{title: 'Collection Video Details', icon: 'video_library'}">
          <button
            header
            mat-flat-button
            color="primary"
            (click)="$event.stopImmediatePropagation(); save()"
            *ngIf="changed && (canUpdate$ | async)"
          >
            Save
          </button>
          <!-- <ng-container
          header
          *grecoLet="collectionVideo.availableOn && (collectionVideo.availableOn | grecoDateCompare:'lt' ) 
          && (!collectionVideo.availableUntil || (collectionVideo.availableUntil | grecoDateCompare: 'gt')) as active"
        >
          <ng-container *grecoLet="!collectionVideo.availableOn && !collectionVideo.availableUntil as draft">
            <mat-chip>{{active ? 'Active' : draft ? 'Draft' : 'Archived'}}</mat-chip></ng-container
          ></ng-container
        > -->
          <h3>
            Appears in <a matTooltip="Subcollection">'{{collectionVideo.subCollection?.title}}'</a> of
            <a matTooltip="Collection">'{{collectionVideo.collection?.title}}'</a>
          </h3>
          <form [formGroup]="form" style="display: flex; flex-direction: column">
            <mat-form-field floatLabel="always" appearance="standard">
              <mat-label>Collection Video Overwrite Title</mat-label>
              <input
                matInput
                formControlName="title"
                placeholder="{{collectionVideo.communityVideo?.video?.title}}"
                (keyup)="changeEvent()"
              />
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="standard">
              <mat-label>Collection Video Overwrite Description</mat-label>
              <textarea
                matInput
                formControlName="description"
                rows="3"
                placeholder="{{collectionVideo.communityVideo?.video?.description}}"
                (keyup)="changeEvent()"
              ></textarea>
            </mat-form-field>
          </form>
        </greco-collapsible-section>

        <div style="display: flex; flex-direction: column; gap: 16px">
          <ng-content select="[extras]"></ng-content>
        </div>
      </div>
    </greco-video-admin-page>
  </div>
</div>
