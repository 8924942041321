import { CurrencyPipe, NgStyle } from '@angular/common';
import { Component, DEFAULT_CURRENCY_CODE, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountLink, AccountLinkStatus } from '@greco/account-linking';
import { Tax } from '@greco/finance-tax';
import { ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { AccountTaxService } from '@greco/ngx-finance-tax';
import { CommunityService } from '@greco/ngx-identity-communities';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { InventoryProductAddon, ProductVariantInventory } from '@greco/sales-products';
import { Purchase, PurchaseItemResource, PurchaseStatus, SaleCategory } from '@greco/sales-purchases';
import { Subscription } from '@greco/sales-subscriptions';
import { AccountLinkingService } from '@greco/web-account-linking';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { PurchaseService, SaleCategoryService } from '../../services';

@Component({
  selector: 'greco-purchase-preview',
  templateUrl: './purchase-preview.component.html',
  styleUrls: ['./purchase-preview.component.scss'],
})
export class PurchasePreviewComponent {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD',
    private snackBar: MatSnackBar,
    private purchaseSvc: PurchaseService,
    private securitySvc: SecurityService,
    private categorySvc: SaleCategoryService,
    private linkSvc: AccountLinkingService,
    private comSecSvc: CommunitySecurityService,
    private communitySvc: CommunityService,
    private accTaxSvc: AccountTaxService
  ) {}

  @Output() userChanged = new EventEmitter<User>();

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  @Input() communityId!: string;

  @PropertyListener('purchase') private _purchase$ = new ReplaySubject<Purchase>(1);
  @Input() purchase!: Purchase;

  @Input() subscription?: Subscription;
  @PropertyListener('subscription') private _subscription$ = new BehaviorSubject<Subscription | null>(null);

  @Input() showTotals = false;
  @PropertyListener('showTotals') private _showTotals$ = new ReplaySubject<boolean>(1);

  @Input() showHeader = false;
  @Input() showPurchaseInfo = true;

  @Input() edit = false;

  @Input() disabledEdit = false;

  @Input() inventories: Map<
    string,
    {
      inventory?: ProductVariantInventory;
      addon?: InventoryProductAddon;
      variantId: string;
      itemQuantity: number;
    }
  > | null = null;

  @Output() refresh = new EventEmitter();

  private _currency = new CurrencyPipe(this._locale, this._defaultCurrencyCode);

  accountTaxes$ = this._communityId$.pipe(
    switchMap(async communityId => {
      const community = communityId ? await this.communitySvc.getCommunity(communityId) : undefined;
      return community ? await this.accTaxSvc.getAccountTaxes(community.financeAccountId) : [];
    })
  );

  canUpdate$ = this._purchase$.pipe(
    switchMap(async purchase => {
      return purchase
        ? await this.securitySvc.hasAccess(PurchaseItemResource.key, 'UPDATE', { accountId: purchase.account.id })
        : false;
    })
  );

  hasContactAccess$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.comSecSvc.hasAccess(communityId, ContactResource.key, ContactResourceAction.READ)
        : false;
    })
  );

  readonly linkedAccounts$: Observable<AccountLink[]> = this._purchase$.pipe(
    filter(purchase => !!purchase),
    switchMap(async purchase => {
      if (purchase.status !== PurchaseStatus.PENDING || purchase.payment) return [];

      const links = (await this.linkSvc.getPrivilegeLinksForAccessor(purchase.purchasedById))?.filter(
        link => link.status === AccountLinkStatus.ACTIVE
      );
      return [{ account: purchase.purchasedBy, status: AccountLinkStatus.ACTIVE } as AccountLink, ...links];
    })
  );

  readonly items$ = combineLatest([this._purchase$, this._showTotals$, this._subscription$, this.accountTaxes$]).pipe(
    filter(([purchase]) => !!purchase),
    map(purchase => {
      if (purchase[0].status === PurchaseStatus.VOIDED) {
        purchase[0].total = 0;
        purchase[0].subtotal = 0;
        purchase[0].tax = 0;
      }
      const appliedTaxes: { tax: Tax; total: number; taxNumber: string }[] = [];
      purchase[0].items
        .filter(item => !item.voidedDate)
        .forEach(item => {
          item.taxes?.forEach(tax => {
            if (appliedTaxes.some(aTax => aTax.tax.id === tax.id)) {
              const index = appliedTaxes.findIndex(aTax => aTax.tax.id === tax.id);
              appliedTaxes[index].total += ((item.quantity * item.price) / 100) * (tax.percentage / 100);
            } else {
              appliedTaxes.push({
                tax: tax,
                total: ((item.quantity * item.price) / 100) * (tax.percentage / 100),
                taxNumber: purchase[3].find(accTax => accTax.taxId === tax.id)?.taxNumber || '',
              });
            }
          });
        });
      return { purchase: purchase[0], showTotals: purchase[1], subscription: purchase[2], appliedTaxes };
    }),
    map(data => {
      const purchase = data.purchase;
      const showTotals = data.showTotals;
      const subscription = data.subscription;
      const appliedTaxes = data.appliedTaxes;
      return [
        ...purchase.items.map(item => ({
          id: item.id,
          amount: this._currency.transform((item.quantity * item.price) / 100),
          unitPrice: this._currency.transform(item.price / 100),
          quantity: item.quantity,
          type: item.type,
          saleCategory: item.saleCategory,
          variant: (item as any).variant,
          voidedDate: item.voidedDate,
          description: {
            displayName: item.displayName,
            description: item.description,
            photoURL: item.photoURL,
            frequency: subscription?.recurrence?.frequency ? subscription.recurrence.frequency : null,
            cycles: subscription?.recurrence?.cycles ? subscription.recurrence.cycles : null,
            period: subscription?.recurrence?.period ? subscription.recurrence.period : null,
            minimumCommitmentLength: (item as any)?.variant?.minimumCommitment?.length,
            minimumCommitmentPeriod: (item as any)?.variant?.minimumCommitment?.period,
          },
        })),
        ...(showTotals
          ? [
              {
                description: null,
                quantity: null,
                unitPrice: '',
                unitPriceClass: 'font-bold',
                amount: this._currency.transform(purchase.subtotal / 100),
                amountClass: 'subtotal font-bold',
              },
              //Individual Taxes
              ...appliedTaxes?.map(appliedTax => {
                return {
                  description: null,
                  descriptionClass: 'no-borders',
                  quantity: null,
                  quantityClass: 'no-borders',
                  unitPrice:
                    (appliedTax.tax.abbreviation || appliedTax.tax.title) +
                    ' ' +
                    appliedTax.tax.percentage +
                    '%' +
                    (appliedTax.taxNumber ? ' (' + appliedTax.taxNumber + ')' : ''),
                  unitPriceClass: 'no-borders',
                  amount: appliedTax.total ? this._currency.transform(appliedTax.total) : '-',
                  amountClass: 'no-borders',
                };
              }),
              //Total Taxes
              {
                description: null,
                quantity: null,
                unitPrice: '',
                unitPriceClass: 'font-bold',
                amount: purchase.tax ? this._currency.transform(purchase.tax / 100) : '-',
                amountClass: 'tax font-bold',
              },
              {
                description: null,
                quantity: null,
                unitPrice: '',
                unitPriceClass: 'strong',
                amount: this._currency.transform(purchase.total / 100),
                amountClass: 'total strong',
              },
              ...(purchase.balanceUsed
                ? [
                    {
                      description: null,
                      quantity: null,
                      unitPrice: '',
                      amount: this._currency.transform((purchase.balanceUsed / 100) * -1),
                      amountClass: purchase.balanceUsed > 0 ? 'balance' : 'balanceRefunded',
                    },
                    {
                      description: null,
                      quantity: null,
                      unitPrice: '',
                      amount: this._currency.transform((purchase.total - purchase.balanceUsed) / 100),
                      amountClass: 'due strong',
                    },
                  ]
                : []),
            ]
          : []),
      ];
    })
  );

  readonly accountCategories$ = this._purchase$.pipe(
    switchMap(async purchase => {
      return purchase && purchase.account ? await this.categorySvc.getMany(purchase.account.id) : [];
    })
  );

  columnStyle(item: any): NgStyle['ngStyle'] {
    const style: NgStyle['ngStyle'] = {};

    if (!('id' in item)) {
      style.borderBottom = 'none';
    }

    if (item.amountClass === 'subtotal') {
      style.paddingTop = '16px';
    }

    return style;
  }

  rowStyle(item: any): NgStyle['ngStyle'] {
    return 'id' in item ? {} : { height: 'auto' };
  }

  async updateSaleCategory(item: any, saleCategory: SaleCategory) {
    if (item.saleCategory?.id === saleCategory.id) return;

    try {
      await this.purchaseSvc.updatePurchaseItemSaleCategory(item.id, saleCategory.id);
      this.snackBar.open('Sale Category Updated', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      this.refresh.emit();
    } catch (err) {
      this.snackBar.open('Failed to update: ' + err, 'Ok!', { duration: 2500, panelClass: 'mat-warn' });
    }
  }

  changePurchaseFor(user: User) {
    this.userChanged.emit(user);
  }
}
