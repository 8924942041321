import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@greco/ngx-identity-auth';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'greco-user-courses-wrapper-page',
  template: `<greco-user-courses-page [user]="(user$ | async) || null"></greco-user-courses-page>`,
})
export class UserCoursesWrapperPage implements OnInit {
  constructor(private crumbs: BreadcrumbService, private userSvc: UserService, private route: ActivatedRoute) {}

  user$ = this.route.queryParams.pipe(
    switchMap(params => (params.user ? of(params.user) : this.userSvc.getUserId())),
    switchMap(userId => (userId ? this.userSvc.getUser(userId) : of(null)))
  );
  ngOnInit() {
    setTimeout(() => this.crumbs.set([]));
  }
}
