import { SecurityResource } from '@greco/security';

export const EVENT_VIDEO_UNLOCK_SECURITY_RESOURCE = 'event_video_unlock';

export enum EventVideoUnlockSecurityResourceAction {
  GRANT = 'GRANT',
  UPDATE = 'UPDATE',
  IGNORE_PAYMENT_FREEZE = 'IGNORE_PAYMENT_FREEZE',
}

export const EventVideoUnlockSecurityResource: SecurityResource = {
  key: EVENT_VIDEO_UNLOCK_SECURITY_RESOURCE,
  module: 'videos',

  title: 'Replay Unlock Controls',
  description: 'Manage user unlocks of event videos',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: EventVideoUnlockSecurityResourceAction.GRANT,
      title: 'Grant replays to users',
      description: 'Grant event videos to users',
    },
    {
      key: EventVideoUnlockSecurityResourceAction.UPDATE,
      title: 'Update expiry time of user unlocked replays',
      description: 'Ability to update the expiry time of user unlocked event videos',
    },
    {
      key: EventVideoUnlockSecurityResourceAction.IGNORE_PAYMENT_FREEZE,
      title: 'Ignore payment freeze',
      description: 'Ability to unlock a video and ignore the payment freeze',
    },
  ],
};
