<ng-container *ngIf="user$ | async as user">
  <!-- <greco-tabs
    [tabs]="[
      { label: 'Your Perks', template: yourPerks },
      { label: 'Used/Expired Perks', template: usedPerks }
    ]"
  >
  </greco-tabs>

  <ng-template #yourPerks> -->
  <div *grecoLet="perks$ | async as perks" style="padding: 0 20px">
    <greco-user-perks-grid
      *ngIf="perks?.length; else noPerks"
      [perks]="perks"
      [userId]="user?.id"
    ></greco-user-perks-grid>

    <ng-template #noPerks>
      <p>You currently have no perks! Perks can be used to book in to events and can be purchased in the shop</p>
    </ng-template>
  </div>
  <!-- </ng-template>

  <ng-template #usedPerks>
    <ng-container *grecoLet="usedPerks$ | async as usedPerks">
      <greco-user-perks-table [showUsed]="true" [showFrozen]="true" [userId]="user?.id"></greco-user-perks-table>
    </ng-container>
  </ng-template> -->
</ng-container>
