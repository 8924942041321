<!-- <div style="display: flex; flex-direction: row; padding-top: 16px; padding-left: 16px">
  <div style="width: auto; display: flex; flex-direction: row">
    <mat-icon style="padding-right: 8px">video_library</mat-icon>
    <h2 style="white-space: nowrap">{{ community?.name + ' Video Collections' }}</h2>
  </div>

  <div
    style="width: 100%; padding-right: 16px; display: flex; flex-direction: row; justify-content: flex-end; gap: 16px"
  ></div>
</div> -->
<greco-programs-admin-table [community]="community">
  <button
    *ngIf="canCreate$ | async"
    mat-stroked-button
    color="primary"
    style="height: 32px; line-height: 16px; margin-left: 16px"
    (click)="$event.stopImmediatePropagation(); createNewProgram()"
  >
    <mat-icon>add</mat-icon>
    <span>New Collection</span>
  </button>
</greco-programs-admin-table>
