import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PropertyListener } from '@greco/property-listener-util';
import moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-admin-events-date-filters',
  templateUrl: './admin-events-date-filters.component.html',
  styleUrls: ['./admin-events-date-filters.component.scss'],
})
export class AdminEventsDateFiltersComponent implements OnInit, OnDestroy {
  constructor(private breakpointObs: BreakpointObserver) {}

  private _lastDateRange?: [Date, Date];
  @Output() rangeChanged = new EventEmitter<[Date, Date]>();
  @Output() dayClicked = new EventEmitter<Date>();

  readonly formats$ = this.breakpointObs.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
    map(({ matches, breakpoints }) => ({
      weekDay: breakpoints[Breakpoints.Small] ? 'EEE' : breakpoints[Breakpoints.XSmall] ? 'EEEEE' : 'EEEE',
      date: matches ? 'dd' : 'MMM dd',
    }))
  );

  private activeDay = new Date();
  canNavigateToPreviousWeek = false;
  @Input() canSearchThePast = false;
  @Input() canSearchTheFuture = true;
  @PropertyListener('week') private _week$ = new ReplaySubject<Date[]>(1);
  week: Date[] = [];

  readonly weekLabel$ = this._week$.pipe(
    map(week =>
      week.length
        ? moment(week[0]).format('MMM Do') + ' - ' + moment(week[week.length - 1]).format('MMM Do, YYYY')
        : null
    ),
    switchMap(label =>
      this.breakpointObs.observe('(max-width: 450px)').pipe(map(({ matches }) => (matches ? '' : 'Week of ') + label))
    )
  );

  ngOnInit() {
    // TODO: Sync with URL
    this.today();
  }

  ngOnDestroy() {
    this._week$.complete();
  }

  nextWeek() {
    this.goTo(moment(this.activeDay).add(1, 'week').toDate());
  }

  previousWeek() {
    this.goTo(moment(this.activeDay).subtract(1, 'week').toDate());
  }

  today() {
    this.goTo(new Date());
  }

  goTo(date: Date) {
    this.activeDay = date;

    if (!this.canSearchThePast) {
      if (moment(this.activeDay).isBefore(moment().startOf('week'))) {
        this.activeDay = new Date(); // Ensures you can't go in the past
      }
    }
    if (!this.canSearchTheFuture) {
      if (moment(this.activeDay).isAfter(moment().endOf('week'))) {
        this.activeDay = new Date(); // Ensures you can't go in the future
      }
    }

    this._setupWeek();
  }

  private _setupWeek() {
    const _week = [];

    const startOfWeek = moment(this.activeDay).startOf('week');
    const endOfWeek = moment(this.activeDay).endOf('week').startOf('day');

    while (startOfWeek.isSameOrBefore(endOfWeek)) {
      _week.push(startOfWeek.toDate());
      startOfWeek.add(1, 'day');
    }

    this.week = _week;
    this.canNavigateToPreviousWeek = moment().isBefore(this.week[0]);

    let startDate = new Date();
    if (!this.canSearchThePast) {
      startDate = moment().isAfter(this.week[0]) ? new Date() : this.week[0];
    } else {
      startDate = this.week[0];
    }

    this.emitDateRange([startDate, this.week[this.week.length - 1]]);
  }

  private emitDateRange(dateRange: [Date, Date]) {
    const isSame = (a: Date, b: Date) => moment(a).isSame(moment(b), 'day');
    const isSameRange = (a: [Date, Date], b: [Date, Date]) => isSame(a[0], b[0]) && isSame(a[1], b[1]);

    if (!this._lastDateRange?.length || !isSameRange(dateRange, this._lastDateRange)) {
      this._lastDateRange = dateRange;
      this.rangeChanged.emit(dateRange);
    }
  }
}
