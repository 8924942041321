import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ArrayUtils } from '@greco-fit/util';
import { PersonResource, Resource, Tag } from '@greco/booking-events';
import { EventVideo } from '@greco/event-videos';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { EventService, ResourcesService, TagService } from '@greco/ngx-booking-events';
import { ContactService } from '@greco/ngx-identity-contacts';
import { PropertyListener } from '@greco/property-listener-util';
import { VideoTag } from '@greco/videos';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

interface ResourceGroup {
  name: string;
  groupId: string;
  photoUrl: string;
  resources: PersonResource[];
}
@Component({
  selector: 'greco-user-work-out-library-filters',
  templateUrl: './user-work-out-library-filters.component.html',
  styleUrls: ['./user-work-out-library-filters.component.scss'],
})
export class UserWorkOutLibraryFiltersComponent implements OnDestroy, OnInit {
  mobileBreakpoint$ = this.breakpointObs.observe('(max-width: 600px)');
  constructor(
    private formBuilder: FormBuilder,
    private breakpointObs: BreakpointObserver,
    private resourceSvc: ResourcesService,
    private tagSvc: TagService,
    private contactSvc: ContactService,
    private eventSvc: EventService
  ) {
    this.listenToForm();
  }
  private _onDestroy$ = new Subject<void>();
  //@Output() changed = new EventEmitter<RequestQueryBuilder>(true);
  @Output() trainersChanged = new EventEmitter<PersonResource[]>();
  @Output() eventTagsChanged = new EventEmitter<Tag[]>();
  @Output() videoTagsChanged = new EventEmitter<VideoTag[]>();
  @PropertyListener('user') private _user$ = new ReplaySubject<User>(1);
  @Input() user!: User | null | undefined;

  readonly form = this.formBuilder.group({
    trainers: [[]],
    eventTags: [[]],
    videoTags: [[]],
  });

  @PropertyListener('eventVideos') readonly eventVideos$ = new ReplaySubject<EventVideo[]>(1);
  @Input() eventVideos!: EventVideo[] | null;
  readonly communities$: Observable<Community[]> = this._user$.pipe(
    switchMap(async user => {
      const communities = (await this.contactSvc.getUserContacts(user?.id)).map(contact => contact.community);
      return communities;
    })
  );

  readonly eventTags$: Observable<Tag[]> = this.eventVideos$.pipe(
    switchMap(async eventVideos => {
      if (eventVideos && eventVideos.length) {
        const tags: Tag[] = [];
        for (const video of eventVideos) {
          const event = video.event;
          if (event) {
            for (const tag of event.tags) {
              if (!tags.some(t => t.id === tag.id)) {
                tags.push(tag);
              }
            }
          }
        }
        return tags;
      } else return [];
    })
  );

  /*readonly videoTags$: Observable<VideoTag[]> = this.perks$.pipe(
    switchMap(async perks => {
      const tags: VideoTag[] = [];
      /* perks.forEach(perk =>
        (perk as EventVideoUnlockOption).videoTags.forEach(vTag => {
          if (!tags.some(tag => tag.id === vTag.id)) {
            tags.push(vTag);
          }
        })
      );
      return tags;
    })
  ); */

  readonly trainers$: Observable<ResourceGroup[]> = this.eventVideos$.pipe(
    switchMap(async eventVideos => {
      const getResources = [];
      if (eventVideos && eventVideos.length) {
        for (const video of eventVideos) {
          const resources =
            video.event?.resourceAssignments?.reduce((acc, assignment) => {
              if (assignment?.resource) acc.push(assignment.resource);
              return acc;
            }, [] as Resource[]) || [];

          if (resources) {
            for (const resource of resources) {
              getResources.push(resource);
            }
          }
        }
        const resourceIds = getResources.map(o => o.id);
        const filteredResources = getResources.filter(({ id }, index) => !resourceIds.includes(id, index + 1));
        const grouped = ArrayUtils.groupBy(filteredResources as PersonResource[], r => r.groupId);
        return Object.entries(grouped).map(([groupId, resourceData]) => ({
          photoUrl: this.getPhotoUrl(resourceData),
          name: resourceData[0].name,
          resources: resourceData,
          groupId,
        }));
      } else return [];
    })
  );
  private getPhotoUrl(resourceData: PersonResource[]): string {
    for (const resourceItem of resourceData) {
      if (resourceItem.photoURL) {
        return resourceItem.photoURL;
      }
    }
    return 'assets/lf3/icon_square_pad.png';
  }
  readonly compareTags = (a?: { label: string }, b?: { label: string }) => a?.label === b?.label;
  readonly compareGroups = (a?: ResourceGroup, b?: ResourceGroup) => a?.groupId === b?.groupId;

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit() {
    this.trainersChanged.emit([]);
    this.eventTagsChanged.emit([]);
    this.videoTagsChanged.emit([]);
  }

  private listenToForm() {
    return null;
  }

  emitTrainers(value: PersonResource[]) {
    console.log({ value });
    this.trainersChanged.emit(value);
  }
  emitEventTags(value: Tag[]) {
    this.eventTagsChanged.emit(value);
  }
  emitVideoTags(value: VideoTag[]) {
    this.videoTagsChanged.emit(value);
  }
}
