import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import { ChangeProfilePictureDialog } from '@greco/ngx-identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'greco-expiring-payment-method-alert',
  templateUrl: './expiring-payment-method-alert.component.html',
  styleUrls: ['./expiring-payment-method-alert.component.scss'],
})
export class ExpiringPaymentMethodAlertComponent {
  constructor(private dialog: MatDialog) {}

  @PropertyListener('paymentMethod') paymentMethod$ = new BehaviorSubject<PaymentMethod | null>(null);
  @Input() paymentMethod!: PaymentMethod;

  @Input() user!: User;

  @Input() expired = false;

  @Output() changes = new EventEmitter();

  subtitle$ = this.paymentMethod$.pipe(
    map(paymentMethod => {
      if (!paymentMethod) return '';
      return paymentMethod.label + 'expires soon';
    })
  );

  async upload() {
    const d = this.dialog.open(ChangeProfilePictureDialog, { data: { user: this.user } });
    const response = await toPromise(d.afterClosed());
    if (response) this.changes.emit(response);
  }
}
