import { Component, Injectable, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  EventVideo,
  EventVideoUnlock,
  EventVideoUnlockSecurityResource,
  EventVideoUnlockSecurityResourceAction,
} from '@greco/event-videos';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ManageUnlockDialog } from '../../dialogs';
import { EventVideoUnlockService } from '../../services';

@Injectable({ providedIn: 'any' })
export class UserSearchFilter extends BuildSearchFilter('UserSearchFilter', {
  properties: ['user.displayName'],
  propertyLabels: ['Display Name'],
}) {}

@Component({
  selector: 'greco-admin-manage-event-video-unlocks-page',
  templateUrl: './admin-manage-event-video-unlocks-page.page.html',
  styleUrls: ['./admin-manage-event-video-unlocks-page.page.scss'],
})
export class AdminManageEventVideoUnlocksPage implements OnDestroy {
  constructor(
    private comSecSvc: CommunitySecurityService,
    private unlockSvc: EventVideoUnlockService,
    private matDialog: MatDialog
  ) {}

  @PropertyListener('eventVideo') private eventVideo$ = new ReplaySubject<EventVideo>(1);
  @Input() eventVideo?: EventVideo;
  @Input() communityId!: string;
  set statusFilter(statusFilter) {
    this._statusFilter$.next(statusFilter);
  }

  get statusFilter() {
    return this._statusFilter$.value;
  }
  private _statusFilter$ = new BehaviorSubject<boolean>(false);

  loading = false;
  pagination?: IPaginationMeta;
  now: number = Date.now();
  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});
  filterOptions = [UserSearchFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  eventVideoUnlocks$: Observable<EventVideoUnlock[]> = combineLatest([
    this.filters$,
    this.page$,
    this.eventVideo$,
    this._statusFilter$,
  ]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([query, page, eventVideo, statusFilter]) =>
      query && eventVideo ? await this.unlockSvc.getEventVideoUnlocks(query, eventVideo.id, page, statusFilter) : null
    ),
    tap(data => (this.pagination = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  readonly canGrant$ = this.eventVideo$.pipe(
    switchMap(async eventVideo => {
      return eventVideo.communityVideo
        ? await this.comSecSvc.hasAccess(
            eventVideo.communityVideo.communityId,
            EventVideoUnlockSecurityResource.key,
            EventVideoUnlockSecurityResourceAction.GRANT
          )
        : null;
    })
  );

  readonly canUpdate$ = this.eventVideo$.pipe(
    switchMap(async eventVideo => {
      return eventVideo.communityVideo
        ? await this.comSecSvc.hasAccess(
            eventVideo.communityVideo.communityId,
            EventVideoUnlockSecurityResource.key,
            EventVideoUnlockSecurityResourceAction.UPDATE
          )
        : null;
    })
  );

  private _onDestroy$ = new Subject<void>();

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.eventVideo$.complete();
    this.filters$.complete();
    this.page$.complete();
    this._statusFilter$.complete();
  }

  refresh() {
    this.page$.next(this.page$.value);
  }

  async grant() {
    if (this.eventVideo) {
      const dialog = this.matDialog.open(ManageUnlockDialog, { data: {}, width: '400px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.eventVideo = this.eventVideo;
      await toPromise(dialog.afterClosed());
      this.refresh();
    }
  }

  async update(unlock: EventVideoUnlock) {
    if (this.eventVideo) {
      const dialog = this.matDialog.open(ManageUnlockDialog, { data: {}, width: '400px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.eventVideo = this.eventVideo;
      instance.updateUnlock = unlock;
      await toPromise(dialog.afterClosed());
      this.refresh();
    }
  }

  toggleExpired() {
    this.statusFilter = !this.statusFilter;
  }
}
