import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgreementStatus } from '@greco/community-agreements';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { UserCommunityAgreementsService } from '@greco/ngx-community-agreements';
import { SignatureService } from '@greco/ngx-identity-users';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-sign-agreement.dialog',
  templateUrl: './sign-agreement.dialog.component.html',
  styleUrls: ['./sign-agreement.dialog.component.scss'],
})
export class SignAgreementDialogComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string; contact: Contact; user: User },
    private formBuilder: FormBuilder,
    private userAgreementService: UserCommunityAgreementsService,
    private snacks: MatSnackBar,
    private signatureService: SignatureService
  ) {}

  @ViewChild(SimpleDialog) private container!: SimpleDialog;
  private _onDestroy$ = new Subject<void>();

  signature: string | null = null;

  checked = false;

  user: any;

  selectedAgreement: any;

  formGroup = this.formBuilder.group({
    agreement: ['', Validators.required],
    checkbox: [false, Validators.requiredTrue],
  });

  fillText(event: any) {
    this.selectedAgreement = event;
    if (this.selectedAgreement.agreementType === 'AUTO_CHECKBOX') {
      this.formGroup.patchValue({ checkbox: true });
    } else if (this.selectedAgreement.agreementType != 'AUTO_CHECKBOX') {
      this.formGroup.patchValue({ checkbox: false });
    }
  }

  setUser(_event: any) {
    this.user = this.data.contact.user || null;
  }

  setSignature(event: string) {
    this.signature = event;
    if (this.signature != '') {
      this.formGroup.patchValue({ checkbox: true });
    }
  }

  getResult = async () => {
    if (this.user && this.signature) {
      let signature: any = null;

      try {
        signature = await this.signatureService.getOne(this.user?.id);
      } catch (err) {
        console.log(err);
      }

      if (signature) {
        if (this.signature !== signature.signature) await this.signatureService.update(this.user?.id, this.signature);
      } else await this.signatureService.create({ userId: this.user.id, signature: this.signature });
    }

    try {
      const role = await this.userAgreementService.createUserAgreement({
        userId: this.data.contact ? this.data.contact?.user?.id : this.formGroup.value.contact.user.id,
        signedById: this.data.contact ? this.data.contact?.user?.id : this.formGroup.value.contact.user.id,
        agreementId: this.formGroup.value.agreement.id,
        type: this.formGroup.value.agreement.agreementType,
        signedAgreementTitle: this.formGroup.value.agreement.title,
        signedAgreementText: this.formGroup.value.agreement.text,
        signature: this.signature || null,
        status: AgreementStatus.SIGNED,
      });

      this.snacks.open('New user agreement created!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
      return role;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  ngOnInit() {
    this.checked = false;
    this.formGroup.valueChanges
      .pipe(
        startWith(this.formGroup.value),
        takeUntil(this._onDestroy$),
        map(() => this.container?.data?.buttons?.find(btn => btn.role === 'create'))
      )
      .subscribe(button => button && ((button as any).disabled = this.formGroup.invalid));

    this.formGroup.patchValue({
      contact: this.data.contact,
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
