<ng-container *grecoLet="canRead$ | async as canRead">
  <ng-container *grecoLet="canManage$ | async as canManage">
    <ng-container *ngIf="communityId && canRead">
      <ng-container *grecoLet="config$ | async as config">
        <greco-collapsible-section
          *grecoLet="community$ | async as community"
          (click)="$event.stopImmediatePropagation()"
          id="communityGiftCards"
          [header]="{ title: 'Community Gift Cards', icon: 'redeem'}"
        >
          <ng-container header>
            <mat-checkbox
              *ngIf="config"
              [formControl]="$any(form.get('enabled'))"
              color="primary"
              (click)="$event.stopImmediatePropagation()"
              [disabled]="!canManage"
            >
              Enabled
            </mat-checkbox>
            <ng-container *ngIf="form.dirty && canManage">
              <button
                mat-icon-button
                (click)="reset(config); $event.stopPropagation()"
                matTooltip="Reset to original values"
                [disabled]="loading"
              >
                <mat-icon>restart_alt</mat-icon>
              </button>
              <button
                [disabled]="!form.valid || loading || (!options.length && !form.value.allowCustomAmount)"
                mat-flat-button
                color="primary"
                style="height: 32px; line-height: 32px"
                (click)="save(options, deletedOptionIds, form.value); $event.stopPropagation()"
              >
                <mat-icon *ngIf="loading; else notLoading" class="spin">loop</mat-icon>
                <ng-template #notLoading>Save</ng-template>
              </button>
            </ng-container>
          </ng-container>

          <form [formGroup]="form" style="display: flex; flex-direction: column; gap: 8px">
            <!-- Title -->
            <mat-form-field style="width: 100%" floatLabel="always">
              <mat-label>Title (optional, visible to members)</mat-label>
              <input
                matInput
                formControlName="title"
                [readOnly]="!canManage"
                [placeholder]="'Will be displayed as `' + (form.value | giftCardConfigTitle) + '`'"
              />
            </mat-form-field>

            <!-- Description -->
            <mat-form-field floatLabel="always">
              <mat-label>Description (optional, visible to members)</mat-label>
              <textarea matInput formControlName="description" [readOnly]="!canManage"></textarea>
            </mat-form-field>

            <mat-checkbox
              formControlName="allowCustomAmount"
              color="primary"
              labelPosition="before"
              [disabled]="!canManage"
            >
              Allow Custom Amounts
            </mat-checkbox>

            <div
              *ngIf="form.value.allowCustomAmount"
              style="display: flex; flex-direction: row; gap: 8px; flex-wrap: wrap"
            >
              <div style="flex: 1">
                <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
                  <mat-label>Min Custom Amount</mat-label>
                  <input
                    matInput
                    type="text"
                    currencyMask
                    [options]="currencyMaskConfig"
                    [min]="0"
                    formControlName="minimumCustomAmount"
                    [readonly]="!canManage"
                  />
                  <button
                    *ngIf="canManage && form.value.minimumCustomAmount !== null"
                    mat-icon-button
                    matSuffix
                    type="button"
                    (click)="form.patchValue({ minimumCustomAmount: null })"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </mat-form-field>
                <mat-error *ngIf="form.value.minimumCustomAmount !== null && form.value.minimumCustomAmount < 5">
                  <small>Minimum amount must be at least $5.00</small>
                </mat-error>
              </div>

              <div style="flex: 1">
                <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
                  <mat-label>Max Custom Amount</mat-label>
                  <input
                    matInput
                    type="text"
                    currencyMask
                    [options]="currencyMaskConfig"
                    [min]="form.value.minimumCustomAmount || 0"
                    formControlName="maximumCustomAmount"
                    [readonly]="!canManage"
                  />
                  <button
                    *ngIf="canManage &&form.value.maximumCustomAmount !== null"
                    mat-icon-button
                    matSuffix
                    type="button"
                    (click)="form.patchValue({ maximumCustomAmount: null })"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </mat-form-field>
                <mat-error *ngIf="form.value.maximumCustomAmount !== null && form.value.maximumCustomAmount < 5">
                  <small>Maximum amount must be at least $5.00</small>
                </mat-error>
              </div>
            </div>
          </form>

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-top: 16px;
              margin-bottom: 8px;
              align-items: center;
            "
          >
            <h4 style="margin: 0; padding: 0">Options</h4>
            <button *ngIf="canManage" mat-stroked-button (click)="openConfigureOptionDialog()">
              <mat-icon>add</mat-icon>Add Option
            </button>
          </div>
          <greco-table [data]="options">
            <mat-icon *grecoTableCol="'Visibility'; let option; fitContent: true">
              {{ option.visibility === 'VISIBLE' ? 'visibility' : option.visibility === 'HIDDEN' ? 'visibility_off' :
              'lock' }}
            </mat-icon>

            <p *grecoTableCol="'Amount'; let option; fitContent: true">{{option.amount / 100 | currency}}</p>
            <p *grecoTableCol="'Price'; let option; fitContent: true">{{option.price / 100 | currency}}</p>
            <p *grecoTableCol="'Title'; let option; fitContent: true" [class.autoTitle]="!option.title">
              {{option.title || (option | giftCardOptionTitle)}}
            </p>
            <p *grecoTableCol="'Description'; let option">{{option.description}}</p>
            <ng-container *ngIf="canManage">
              <div
                *grecoTableCol="''; let option; let index = index; stickyEnd: true; fitContent: true"
                style="display: flex; flex-direction: row; gap: 8px; align-items: center"
              >
                <mat-chip-list *ngIf="!option.id"><mat-chip color="primary">New</mat-chip></mat-chip-list>

                <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openConfigureOptionDialog(option, index)">Edit</button>
                  <button mat-menu-item (click)="removeOption(option)">Remove</button>
                </mat-menu>
              </div>
            </ng-container>
          </greco-table>
        </greco-collapsible-section>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
