<div class="video-filters">
  <!--Locked Videos-->
  <greco-user-work-out-library-filters
    [user]="user"
    [eventVideos]="eventVideos$ | async"
    (trainersChanged)="trainers$.next($event)"
    (eventTagsChanged)="eventTags$.next($event)"
    (videoTagsChanged)="videoTags$.next($event)"
  ></greco-user-work-out-library-filters>
  <br />
  <ng-container *grecoLet="items$ | async as items">
    <greco-user-events-date-filters
      id="dateFilter"
      #datefilter
      [canSearchThePast]="true"
      [canSearchTheFuture]="false"
      (rangeChanged)="dateRange$.next($event); openIndex = 0"
      (dayClicked)="dayClicked($event, items || [])"
    ></greco-user-events-date-filters>
  </ng-container>
</div>

<div class="videos-main" *ngIf="items$ | async as items; else loading">
  <ng-container *ngIf="items?.length; else noEvents">
    <ng-container *ngFor="let item of items; let index = index" style="display: flex; flex-direction: row">
      <div style="margin-bottom: 16px" *grecoLet="item.date | date : 'EEEE, MMMM d' as date">
        <greco-collapsible-section
          [id]="item.date | date : 'yyyyMMdd'"
          [header]="{ title: date || '', icon: 'playlist_play' }"
          [expanded]="index == openIndex"
          *grecoLet="item.videos.length as videoCount"
        >
          <mat-chip class="video-count" header *ngIf="videoCount">
            {{ videoCount == 1 ? '1 Video' : videoCount + ' Videos' }}
          </mat-chip>
          <!-- <div *ngIf="!(item.date | isSameDay: items[index - 1]?.date)" class="list-item date">
            <h3 [id]="item.date | date: 'yyyyMMdd'">
              {{ item.date | date: 'EEEE, MMMM d' }}
            </h3>
          </div> -->
          <div class="video-swiper">
            <swiper
              #SwiperComponent
              [slidesPerView]="'auto'"
              [spaceBetween]="16"
              [navigation]="true"
              [slidesOffsetBefore]="mobileView ? 16 : 32"
              [slidesOffsetAfter]="mobileView ? 16 : 32"
            >
              <ng-template swiperSlide *ngFor="let data of item.videos">
                <greco-event-video-list-item
                  [eventVideo]="data.eventVideo"
                  [options]="data.options"
                  [videoUnlock]="$any(data.eventVideo)?.unlock"
                ></greco-event-video-list-item>
              </ng-template>
            </swiper>
          </div>
        </greco-collapsible-section>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noEvents>
    <div class="no-events">
      <p>
        Oops! Looks like there are no videos here at those times. Try a different week or change some filters
        <a *ngIf="!user">, or </a>
        <a [routerLink]="" (click)="signIn()" *ngIf="!user">sign in</a>!
      </p>
    </div>
  </ng-template>
</div>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
