import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommunityGiftCardConfig, CommunityGiftCardOption } from '@greco/gift-cards';

@Component({
  selector: 'greco-gift-card-product-card',
  templateUrl: './gift-card-product-card.component.html',
  styleUrls: ['./gift-card-product-card.component.scss'],
})
export class GiftCardProductCardComponent implements OnInit {
  inf = Infinity;

  constructor(private router: Router) {}

  @Input() giftCardConfig!: CommunityGiftCardConfig;
  @Input() allVisibleCommunities: string[] = [];

  @Output() productBuyClicked = new EventEmitter<void>();

  selectedImageUrl = 'assets/lf3/icon_square_pad.png';
  selectedAmount: CommunityGiftCardOption | string = '';
  smallestAmount = 0;
  largestAmount = 0;

  ngOnInit() {
    if (!this.giftCardConfig.options.length) {
      this.selectedAmount = 'custom';
      if (
        this.giftCardConfig.allowCustomAmount &&
        this.giftCardConfig.minimumCustomAmount &&
        this.giftCardConfig.maximumCustomAmount
      ) {
        this.smallestAmount = this.giftCardConfig.minimumCustomAmount;
        this.largestAmount = this.giftCardConfig.maximumCustomAmount;
      }

      return;
    }

    this.selectedAmount = this.giftCardConfig.options[0];
    if (this.giftCardConfig.allowCustomAmount) {
      if (
        this.giftCardConfig.minimumCustomAmount &&
        this.giftCardConfig.minimumCustomAmount < this.giftCardConfig.options[0].amount
      ) {
        this.smallestAmount = this.giftCardConfig.minimumCustomAmount;
      } else {
        this.smallestAmount = this.giftCardConfig.options[0].amount;
      }
      if (
        this.giftCardConfig.maximumCustomAmount &&
        this.giftCardConfig.maximumCustomAmount >
          this.giftCardConfig.options[this.giftCardConfig.options.length - 1].amount
      ) {
        this.largestAmount = this.giftCardConfig.maximumCustomAmount;
      } else {
        this.largestAmount = this.giftCardConfig.options[this.giftCardConfig.options.length - 1].amount;
      }
    } else {
      this.smallestAmount = this.giftCardConfig.options[0].amount;
      this.largestAmount = this.giftCardConfig.options[this.giftCardConfig.options.length - 1].amount;
    }
  }

  async buyNow(option?: CommunityGiftCardOption, custom: boolean = false) {
    await this.router.navigate(['shop/gift-cards'], {
      queryParams: {
        configId: this.giftCardConfig.id,
        ...(option && { optionId: option.id }),
        custom,
        communities: [this.giftCardConfig.communityId, ...this.allVisibleCommunities],
      },
    });
    this.productBuyClicked.emit();
  }
}
