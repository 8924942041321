<greco-simple-dialog
  [data]="{
  title: 'Configure Gift Card Option',
  subtitle: '',
  showCloseButton: false,
  hideDefaultButton: true
}"
>
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
    <mat-form-field appearance="standard" floatLabel="always">
      <mat-label>Title (optional, visible to members)</mat-label>
      <input
        matInput
        type="text"
        formControlName="title"
        [placeholder]="'Will be displayed as `' + (formGroup.value | giftCardOptionTitle: false) + '`'"
      />
    </mat-form-field>

    <mat-form-field appearance="standard" floatLabel="always">
      <mat-label>Description (optional, visible to members)</mat-label>
      <textarea matInput [matTextareaAutosize]="true" formControlName="description"></textarea>
    </mat-form-field>

    <div style="display: flex; flex-direction: row; gap: 8px; flex-wrap: wrap">
      <div style="flex: 1">
        <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
          <mat-label>Gift Card Amount</mat-label>
          <input matInput type="text" currencyMask [options]="currencyMaskConfig" [min]="0" formControlName="amount" />
        </mat-form-field>
        <mat-error *ngIf="(formGroup.value.amount || 0) < 5">
          <small>Amount must be at least $5.00</small>
        </mat-error>
      </div>

      <div style="flex: 1">
        <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
          <mat-label>Price</mat-label>
          <input matInput type="text" currencyMask [options]="currencyMaskConfig" [min]="0" formControlName="price" />
        </mat-form-field>
      </div>
    </div>

    <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
      <mat-label>Shop Visibility</mat-label>
      <mat-select formControlName="visibility">
        <mat-option value="VISIBLE">Visible</mat-option>
        <mat-option value="HIDDEN">Hidden from shop</mat-option>
        <mat-option value="RESTRICTED">Restricted</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</greco-simple-dialog>

<div class="buttonsDiv">
  <button class="customButton" type="button" mat-stroked-button (click)="close()">Cancel</button>
  <button
    class="customButton"
    type="button"
    mat-stroked-button
    color="primary"
    [disabled]="formGroup.invalid"
    (click)="close(formGroup.value)"
  >
    <span>{{option ? 'Update' : 'Add'}}</span>
  </button>
</div>
