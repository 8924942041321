<mat-radio-group [value]="defaultPaymentMethod || ''" (change)="selectDefaultPaymentMethod($event.value)">
  <greco-table [data]="paymentMethods" [highlight]="true" [loading]="loading" (rowClick)="viewSubscriptions($event)">
    <!-- Icon -->
    <ng-container *grecoTableCol="''; let paymentMethod; fitContent: true">
      <div [matTooltip]="paymentMethod.id" style="display: flex; align-items: center; justify-content: center">
        <img style="width: 36px; min-width: 36px" [src]="'assets/payment-methods/' + paymentMethod.model + '.png'" />
      </div>
    </ng-container>

    <!-- Label -->
    <div *grecoTableCol="'Label'; let paymentMethod" style="line-height: 16px">
      <div>{{ paymentMethod.model | titlecase }}&nbsp;&nbsp;{{ paymentMethod.label }}</div>
      <div *ngIf="paymentMethod.details" style="font-size: 75%">Expires&nbsp;&nbsp;{{ paymentMethod.details }}</div>
    </div>

    <div *grecoTableCol="'Card Holder'; let paymentMethod">
      <div>{{ paymentMethod?.cardHolderName || '-' }}</div>
    </div>

    <!-- Tags -->
    <mat-chip-list *grecoTableCol="''; let paymentMethod">
      <mat-chip *ngIf="usedPaymentMethods?.includes(paymentMethod.id)">In Use</mat-chip>
    </mat-chip-list>

    <!-- Default -->
    <ng-container *ngIf="canChangeDefaultPaymentMethod$ | async">
      <ng-container *grecoTableCol="'Default'; let paymentMethod">
        <mat-radio-button
          color="primary"
          [value]="paymentMethod"
          [disabled]="paymentMethod.model === 'bank'"
          [checked]="defaultPaymentMethod?.id === paymentMethod.id"
          (click)="$event.stopImmediatePropagation()"
        ></mat-radio-button>
      </ng-container>
    </ng-container>

    <!-- Menu -->
    <ng-container *grecoTableCol="''; let paymentMethod; fitContent: true; stickyEnd: true">
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <ng-container *ngIf="canChangeDefaultPaymentMethod$ | async">
          <button
            mat-menu-item
            [disabled]="paymentMethod.id === defaultPaymentMethod || paymentMethod.model === 'bank'"
            (click)="selectDefaultPaymentMethod(paymentMethod)"
          >
            <mat-icon>credit_score</mat-icon>
            <span>Set default</span>
          </button>

          <button
            *ngIf="paymentMethod.model !== 'bank'"
            mat-menu-item
            (click)="updatePaymentMethodExpiry(paymentMethod.id, paymentMethod.details)"
          >
            <mat-icon>history</mat-icon>
            <span>Update Expiry</span>
          </button>
        </ng-container>

        <button
          mat-menu-item
          *ngIf="hasRemoveAccess"
          [disabled]="
            (paymentMethods.length > 1 && paymentMethod.id === defaultPaymentMethod) ||
            usedPaymentMethods?.includes(paymentMethod.id)
          "
          (click)="removePaymentMethod(paymentMethod.id)"
        >
          <mat-icon>delete_forever</mat-icon>
          <span>Remove</span>
        </button>
      </mat-menu>
    </ng-container>
  </greco-table>
</mat-radio-group>
