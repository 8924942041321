import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { AccountResource, AccountResourceAction } from '@greco/finance-accounts';
import { ContactNote } from '@greco/identity-contacts';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { NoteVersionsDialog, ReplyToContactNoteDialog, UpdateContactNoteDialog } from '../../dialogs';
import { ContactNotesService } from '../../services';
//  import type { ContactService } from '@greco/nestjs-identity-contacts';

@Component({
  selector: 'greco-contact-note',
  templateUrl: './contact-note.component.html',
  styleUrls: ['./contact-note.component.scss'],
})
export class ContactNoteComponent {
  constructor(
    private noteSvc: ContactNotesService,
    private dialog: MatDialog,
    private securitySvc: SecurityService,
    private userSvc: UserService
  ) {}

  showReplies = false;

  @Input() note!: ContactNote;
  @Input() canDeleteNotes = false;

  @Input() last = false;
  @Input() first = false;

  @Input() readonly = false;

  @Output() changed = new EventEmitter<ContactNote>();

  localUser$ = this.userSvc.user$;

  isSuper$ = this.securitySvc.hasAccess(AccountResource.key, AccountResourceAction.CREATE, {}, true);

  async reply() {
    this.showReplies = true;

    const dialog = this.dialog.open(ReplyToContactNoteDialog, {
      data: { note: this.note },
      width: '800px',
      maxWidth: '90%',
    });
    const response = await toPromise(dialog.afterClosed());

    if (response) {
      this.note.replies = [...(this.note.replies || []), response];
    }
  }
  async edit() {
    const dialog = this.dialog.open(UpdateContactNoteDialog, {
      data: { note: this.note },
      width: '800px',
      maxWidth: '90%',
    });
    const response = await toPromise(dialog.afterClosed());

    if (response) {
      this.note.content = response.content;
      this.note.edited = true;
    }
    // this.changed.emit(response);
  }
  async update(changes: { admin?: boolean; pinned?: boolean }) {
    const response = await this.noteSvc.update(this.note.id, changes);
    this.changed.emit(response);
  }

  async delete() {
    const dialog = this.dialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Delete Note',
        subtitle: 'Are you sure you want to delete this note?',
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const response = await this.noteSvc.delete(this.note.id);
      this.changed.emit(response);
    }
  }

  replyChanged(reply: ContactNote, index: number) {
    if (reply.deletedOn) {
      this.note.replies = [...(this.note.replies?.filter(r => r.id !== reply.id) || [])];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.note.replies![index] = reply;
    }
  }

  openNoteHistory() {
    this.dialog.open(NoteVersionsDialog, { data: { note: this.note }, width: '1000px', maxWidth: '90%' });
  }
}
