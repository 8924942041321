<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<greco-calendar
  [syncWithUrl]="true"
  [view]="startingView"
  [sortGroups]="sortGroups"
  [dayViewGroupBy]="groupBy"
  [events]="ngCalendarEvents"
  [hideDayView]="hideDayView"
  [hideWeekView]="hideWeekView"
  [hideMonthView]="hideMonthView"
  [eventCardTemplate]="eventCardTemplate"
  [eventListTemplate]="eventListTemplate"
  (eventClicked)="eventClicked.emit($event.meta)"
  [refreshCalendarEmitter]="refreshCalendarEmitter"
  (dateRange)="dateRange.emit($event)"
  (eventTimesChanged)="eventTimesChanged.emit($event)"
  (dateClicked)="dateClicked.emit($event)"
></greco-calendar>

<!-- Month View Day Cell -->
<!-- <ng-template #dayCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
    <span class="cal-day-number">{{ day.date | calendarDate: 'monthViewDayNumber':locale }}</span>
  </div>
  <small style="margin: 5px">There are {{ day.events.length }} events on this day</small>
</ng-template> -->

<!-- List of Collapses Events for a given Day -->
<ng-template #eventListTemplate let-events="events" let-isOpen="isOpen" let-eventClicked="eventClicked">
  <div [@heightExpansion]="isOpen && events.length ? 'expanded' : 'collapsed'">
    <greco-table
      [data]="events || []"
      [highlight]="true"
      [hideEmptyPlaceholder]="true"
      (rowClick)="eventClicked.emit({ event: $event })"
    >
      <mat-icon
        *grecoTableCol="''; let event; fitContent: true"
        [style.color]="event.color.primary"
        [matTooltip]="event.meta.id"
      >
        event
      </mat-icon>

      <p *grecoTableCol="'Time'; let event" [class.strikethrough]="event.meta?.status === 'CANCELLED'">
        {{ event.start | date: 'shortTime' }} - {{ event.end | date: 'shortTime' }}
      </p>

      <p *grecoTableCol="'Title'; let event" [class.strikethrough]="event.meta?.status === 'CANCELLED'">
        {{ event.title }}
      </p>

      <p *grecoTableCol="'Bookings'; let event">
        <ng-container *ngIf="event.meta?.status === 'ACTIVE'">
          {{ event.meta?.bookings || 0 }} / {{ event.meta?.maxCapacity || 0}}
        </ng-container>
      </p>

      <mat-chip-list *grecoTableCol="'Resources'; let event">
        <mat-chip *ngFor="let assignment of event.meta?.resourceAssignments" style="padding-left: 6px">
          <!-- Photo URL -->
          <div *ngIf="assignment?.resource?.photoURL" style="width: 24px; height: 24px">
            <greco-smart-img [src]="assignment?.resource?.photoURL" [border]="false" [round]="true"></greco-smart-img>
          </div>

          <!-- Icon -->
          <mat-icon *ngIf="!assignment?.resource?.photoURL">
            {{ ({ PERSON: 'account_circle', ROOM: 'chair', OTHER: 'texture'})[assignment?.resource?.type + '']}}
          </mat-icon>

          <!-- Name -->
          <span style="margin-left: 6px">{{ assignment?.resource?.name }}</span>
        </mat-chip>
      </mat-chip-list>

      <mat-chip-list *grecoTableCol="'Tags'; let event">
        <mat-chip *ngFor="let tag of event.meta?.tags" [class.strikethrough]="event.meta?.status === 'CANCELLED'">
          {{ tag.label }}
        </mat-chip>
      </mat-chip-list>

      <mat-chip-list *grecoTableCol="'Status'; let event">
        <mat-chip
          [style.background-color]="event.meta.status === 'ACTIVE' ? '' : 'var(--warn-color)'"
          [style.color]="event.meta.status === 'ACTIVE' ? '' : 'white'"
        >
          {{ event.meta?.status | titlecase }}
        </mat-chip>
      </mat-chip-list>
    </greco-table>
  </div>
</ng-template>

<!-- Event Card Template -->
<ng-template #eventCardTemplate let-weekEvent="weekEvent" let-eventClicked="eventClicked">
  <ng-container *grecoLet="weekEvent.event.meta as event">
    <greco-box
      class="event-card"
      (click)="eventClicked.emit({ event: weekEvent }); $event.stopImmediatePropagation()"
      *grecoLet="requiresSub(event) as reqSub"
      [style.color]="event.color+'10c'"
      [style.border-left]="reqSub ? '3px dashed ' + event.color : ''"
      [style.background-color]="reqSub ? event.color+'1a' : event.color+'4c'"
      [style.border-left-color]="reqSub ? event.color : event.color+'9c'"
      [matTooltip]="reqSub ? 'This event requires substitution' : ''"
    >
      <!-- Event Date & Title -->
      <div style="position: relative">
        <div class="card-top-bar-div">
          <p style="text-overflow: ellipsis" [ngClass]="event.status">
            <small class="p-small">
              <span>
                {{ event.startDate | date: 'shortTime' }} - {{ event.endDate | date: 'shortTime' }}
                <ng-container
                  *ngIf="event.status === 'ACTIVE' && event.maxCapacity !== null && event.maxCapacity !== undefined"
                >
                  ({{event.bookings || 0 }}/{{ event.maxCapacity || 0}})
                </ng-container>
              </span>
            </small>
          </p>

          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            class="ellipses-menu-button"
            (click)="$event.stopImmediatePropagation()"
          >
            <mat-icon class="ellipses-menu-icon">more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <button
              *ngIf="(event.bookings < event.maxCapacity) && (canCreateBookings || fromResourceScheduling)"
              mat-menu-item
              (click)="$event.stopImmediatePropagation(); addAttendee(event)"
              style="line-height: 35px; font-size: 14px; height: 35px"
            >
              <mat-icon style="font-size: 18px; margin: -2px 4px 0px 0px">person_add</mat-icon>
              <span>Add Attendee</span>
            </button>

            <button
              mat-menu-item
              (click)="$event.stopImmediatePropagation(); eventClicked.emit({ event: weekEvent })"
              style="line-height: 35px; font-size: 14px; height: 35px"
            >
              <mat-icon style="font-size: 18px; margin: -2px 4px 0px 0px">info</mat-icon>
              <span>View Details</span>
            </button>

            <button
              *ngIf="canCancelEvents"
              mat-menu-item
              (click)="$event.stopImmediatePropagation(); cancelEvent(event.id)"
              style="line-height: 35px; font-size: 14px; height: 35px"
            >
              <mat-icon style="font-size: 18px; margin: -2px 4px 0px 0px">event_busy</mat-icon>
              <span>Cancel event</span>
            </button>

            <button
              *ngIf="!fromResourceScheduling && canUpdateEvents"
              mat-menu-item
              (click)="$event.stopImmediatePropagation(); requestSub(event);"
              style="line-height: 35px; font-size: 14px; height: 35px"
            >
              <mat-icon style="line-height: 18px; font-size: 18px; margin: 2px 4px 0px 0px">pending_actions</mat-icon>
              <span>Update Assignments</span>
            </button>

            <button
              *ngIf="fromResourceScheduling && reqSub"
              mat-menu-item
              (click)="$event.stopImmediatePropagation(); requestSub(event, true);"
              style="line-height: 35px; font-size: 14px; height: 35px"
            >
              <mat-icon style="line-height: 18px; font-size: 18px; margin: -2px 4px 0px 0px">inventory</mat-icon>
              <span>Substitute</span>
            </button>
          </mat-menu>
        </div>
        <p [ngClass]="event.status" class="event-title-p">{{event.title}}</p>
      </div>

      <!-- Resource Images -->
      <div class="resources-image-div">
        <!-- Resource Images -->
        <div style="display: flex; flex-direction: row-reverse; justify-content: flex-end">
          <div
            *ngFor="let assignment of event.resourceAssignments; let first = first"
            [style.margin-right]="first ? '0' : '-13px'"
            style="width: 26px; height: 26px"
          >
            <greco-smart-img
              [style.border-color]="event.color"
              style="background-color: white"
              [src]="assignment?.resource?.photoURL"
              [border]="true"
              [borderDashed]="assignment.resourceStatus !== 'CONFIRMED'"
              [round]="true"
              [matTooltip]="assignment.resource?.name || 'Needs resource'"
            ></greco-smart-img>
          </div>
          <div *ngIf="reqSub" class="needs-sub" [style.border]="'2px solid ' + event.color">
            <mat-icon
              [style.color]="event.color"
              [style.margin-left]="event.resourceAssignments?.length || 0 > 0 ? '3px' : '4px'"
            >
              priority_high
            </mat-icon>
          </div>
        </div>
      </div>
    </greco-box>
  </ng-container>
</ng-template>
