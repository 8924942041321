<!-- Header -->
<div cdkOverlayOrigin #trigger="cdkOverlayOrigin" style="margin-bottom: -16px">
  <!-- <div class="main-header">
    <div *ngIf="userSvc.user$ | async as user">
      <h3>Welcome back <strong>{{user.friendlyName || user.displayName}}</strong>!</h3>
      <p *ngIf="now$ | async as now" style="margin-bottom: 0">{{ now | amDateFormat : 'ddd, MMM Do LTS' }}</p>
    </div>
    <button
      *ngIf="(isMobile$ | async) === false"
      mat-raised-button
      color="accent"
      routerLink="/workouts"
      style="border-radius: 18px; color: white"
    >
      New Booking
    </button>
  </div> -->
</div>

<!-- Upcoming Bookings -->
<greco-dashboard-bookings [trigger]="trigger"></greco-dashboard-bookings>

<!-- High Priority Errors -->
<ng-container *grecoLet="highPriorityWarnings$ | async as highPriorityWarnings">
  <!-- Profile Picture -->
  <ng-container *ngIf="highPriorityWarnings?.noProfilePicture as noProfilePicture">
    <greco-alert
      [config]="{
        title: noProfilePicture.title,
        type: $any('DANGER'),
        description: noProfilePicture.description
      }"
    >
      <button mat-flat-button slot="buttons" color="warn" (click)="navigateToProfile()">Add Photo</button>
    </greco-alert>
  </ng-container>

  <!-- Subscriptions -->
  <ng-container *ngIf="highPriorityWarnings?.noMembership as noMembership">
    <greco-alert
      [config]="{
        title: noMembership.title,
        type: $any('DANGER'),
        description: noMembership.description
      }"
    >
      <button mat-flat-button slot="buttons" color="warn" (click)="navigateToShop()">Purchase Membership</button>
    </greco-alert>
  </ng-container>

  <greco-frozen-subscription-alert
    *ngFor="let warning of highPriorityWarnings?.membershipFrozen"
    displayStyle="card"
    [isUser]="true"
  >
  </greco-frozen-subscription-alert>

  <!-- Purchases/Payments -->
  <greco-outstanding-purchase-card
    *ngFor="let warning of highPriorityWarnings?.failedPurchases"
    [userSide]="true"
    [outstandingPurchase]="$any(warning.data)"
    (refreshPurchase)="refresh$.next(null)"
  ></greco-outstanding-purchase-card>

  <greco-payment-dispute-card
    *ngFor="let warning of highPriorityWarnings?.paymentDisputes"
    [paymentDispute]="$any(warning.data)"
  >
  </greco-payment-dispute-card>

  <!-- Unsigned Agreements -->
  <greco-unsigned-agreement-card
    *ngFor="let warning of highPriorityWarnings?.unsignedAgreements"
    [agreement]="$any(warning.data)"
    (refreshAgreements)="refresh$.next(null)"
  ></greco-unsigned-agreement-card>
</ng-container>

<!-- Low Priority Warnings -->
<ng-container *grecoLet="lowPriorityWarnings$ | async as lowPriorityWarnings">
  <ng-container *ngIf="userSvc.user$ | async as user">
    <greco-expiring-payment-method-alert
      *ngFor="let warning of lowPriorityWarnings?.expiredPaymentMethods"
      [user]="user"
      [expired]="true"
      [paymentMethod]="$any(warning.data)"
    ></greco-expiring-payment-method-alert>

    <greco-expiring-payment-method-alert
      *ngFor="let warning of lowPriorityWarnings?.expiringPaymentMethods"
      [user]="user"
      [paymentMethod]="$any(warning.data)"
    ></greco-expiring-payment-method-alert>

    <greco-unconfirmed-booking-alert
      *ngFor="let warning of lowPriorityWarnings?.pastPendingBookings"
      [isUserSide]="true"
      [booking]="$any(warning.data)"
      (refreshBookings)="refresh$.next(null)"
    ></greco-unconfirmed-booking-alert>
  </ng-container>
</ng-container>

<!-- Favorites -->
<greco-dashboard-favorites></greco-dashboard-favorites>

<!-- Tiles -->
<greco-dashboard-tiles></greco-dashboard-tiles>

<!-- OnDemand Videos -->
<greco-dashboard-videos></greco-dashboard-videos>

<!-- Booking FAB -->
<div *ngIf="isMobile$ | async" class="booking-fab">
  <button mat-raised-button rounded="true" color="accent" routerLink="/workouts" style="color: white">
    New Booking
  </button>
</div>
