<greco-simple-dialog
  [data]="{
    title: (data.restore ? 'Restore ' : 'Archive ') + data.agreement.signedAgreementTitle,
    subtitle: (data.restore ? 'Restore' : 'Archive') + ' this agreement so it ' + (data.restore ? 'will' : 'won\'t') + ' be visible to ' + (data.agreement.user?.displayName || 'the user who signed it'),
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      { label: data.restore ? 'Restore' : 'Archive', role: 'create', resultFn: getResult }
    ]
  }"
>
</greco-simple-dialog>
