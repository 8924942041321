import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import type { CreateUserDto } from '@greco/nestjs-identity-users-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationOptions, Pagination } from 'nestjs-typeorm-paginate';

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private ngAuth: AngularFireAuth) {}

  private _authState$ = this.ngAuth.authState;

  async findOneByEmail(email: string): Promise<Pick<User, 'id' | 'displayName' | 'contactEmail'>> {
    return await toPromise(
      this.http.get<Pick<User, 'id' | 'displayName' | 'contactEmail'>>('/api/users/public/email', { params: { email } })
    );
  }

  async searchUsers(query: string, excludedIds?: string[], onlyStaff?: boolean): Promise<User[]> {
    const data = await this.paginate(
      RequestQueryBuilder.create({
        search: {
          $and: [
            { $or: [{ displayName: { $contL: query } }, { email: { $contL: query } }] },
            ...(excludedIds?.length ? [{ id: { $notin: excludedIds } }] : []),
          ],
        },
      }),
      undefined,
      onlyStaff
    );
    return data.items;
  }

  // @Post()
  async createAndInviteUser(dto: CreateUserDto) {
    return await toPromise(this.http.post<User>('/api/users/createAndInviteUser', dto));
  }

  async searchUsersPublic(
    query: string,
    excludedIds?: string[],
    onlyStaff?: boolean,
    communityId?: string
  ): Promise<User[]> {
    const data = await this.paginatePublic(
      RequestQueryBuilder.create({
        search: {
          $and: [
            { $or: [{ displayName: { $contL: query } }, { email: { $contL: query } }] },
            ...(excludedIds?.length ? [{ id: { $notin: excludedIds } }] : []),
          ],
        },
      }),
      undefined,
      onlyStaff,
      communityId
    );
    return data.items;
  }

  // @Get()
  async paginate(
    queryBuilder: RequestQueryBuilder,
    options?: Partial<Omit<IPaginationOptions, 'route'>>,
    onlyStaff?: boolean
  ) {
    return await toPromise(
      this.http.get<Pagination<User>>('/api/users', {
        params: {
          ...queryBuilder.queryObject,
          page: options?.page?.toString?.() || '1',
          limit: options?.limit?.toString?.() || '10',
          onlyStaff: (onlyStaff ?? false).toString(),
        },
      })
    );
  }

  // @Get('public')
  async paginatePublic(
    queryBuilder: RequestQueryBuilder,
    options?: Partial<Omit<IPaginationOptions, 'route'>>,
    onlyStaff?: boolean,
    communityId?: string
  ) {
    return await toPromise(
      this.http.get<Pagination<User>>('/api/users/public', {
        params: {
          ...queryBuilder.queryObject,
          page: options?.page?.toString?.() || '1',
          limit: options?.limit?.toString?.() || '10',
          onlyStaff: (onlyStaff ?? false).toString(),
          code: (new Date().getTime() * 4321).toString(),
          ...(communityId ? { communityId } : {}),
        },
      })
    );
  }

  // @Get(':id')
  async getUser(userId: string): Promise<User> {
    return await toPromise(this.http.get<User>(`/api/users/${userId}`));
  }

  async getUserAlreadySecured(userId: string): Promise<User> {
    return await toPromise(this.http.get<User>(`/api/users/${userId}/already-secured`));
  }

  async getSelf(): Promise<User | null> {
    const authState = await toPromise(this._authState$);
    return authState ? await this.getUser(authState.uid) : null;
  }
}
