import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventVideo } from '@greco/event-videos';
import { User } from '@greco/identity-users';
import { EventVideosService } from '@greco/ngx-event-videos';
import { UserService } from '@greco/ngx-identity-auth';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

interface TemplateData {
  eventVideo: EventVideo;
  user: User;
}

@Component({
  selector: 'greco-preview-unlock-event-video-wrapper-page',
  template: `<greco-preview-unlock-event-video-page
    #previewPage
    *ngIf="_test$ | async as data"
    [eventVideo]="data.eventVideo"
    [user]="data.user"
    (unlocked)="previewPage.refresh()"
  ></greco-preview-unlock-event-video-page>`,
})
export class PreviewUnlockEventVideoWrapperPage implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private userSvc: UserService,
    private route: ActivatedRoute,
    private crumbs: BreadcrumbService,
    private eventvideoSvc: EventVideosService
  ) {}

  private onDestroy$ = new Subject<void>();

  readonly _templateData$ = new BehaviorSubject<TemplateData | null>(null);
  readonly _test$ = this._templateData$.pipe(distinctUntilChanged());
  loadData() {
    const user$ = this.userSvc.authUser$.pipe(
      switchMap(authUser => (authUser ? this.userSvc.getUser(authUser.uid) : of(null))),
      distinctUntilChanged((previousValue, currentValue) => previousValue?.id === currentValue?.id)
    );

    const eventVideo$ = this.route.params.pipe(
      switchMap(async ({ eventVideoId }) =>
        eventVideoId ? await this.eventvideoSvc.getOneEventVideo(eventVideoId) : null
      ),
      catchError(() => of(null)),
      tap(video => !video && this.router.navigate(['..'])),
      shareReplay(1),
      tap(eventVideo =>
        setTimeout(() =>
          this.crumbs.set([
            { label: 'LF3 GO', routerLink: '/workouts/go', icon: 'reset_tv' },
            {
              label: eventVideo?.event?.title || eventVideo?.communityVideo?.video?.title || '...',
              routerLink: `/workouts/go/${eventVideo?.id}`,
            },
          ])
        )
      )
    );

    combineLatest([user$, eventVideo$])
      .pipe(
        takeUntil(this.onDestroy$),
        filter(([user, eventVideo]) => !!user && !!eventVideo)
      )
      .subscribe(([user, eventVideo]: any[]) => this._templateData$.next({ user, eventVideo }));
  }

  ngOnInit() {
    setTimeout(() => this.crumbs.set([{ label: 'LF3 Go', routerLink: '/workouts/go', icon: 'reset_tv' }]));
    this.loadData();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
