import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountResource, AccountResourceAction } from '@greco/finance-accounts';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { Purchase, PurchaseResource, PurchaseResourceAction, Refund } from '@greco/sales-purchases';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { UndoRefundPurchaseDialog } from '../../dialogs';
import { PurchaseService } from '../../services';

@Component({
  selector: 'greco-purchase-page',
  templateUrl: './purchase.page.html',
  styleUrls: ['./purchase.page.scss'],
})
export class PurchasePage implements OnDestroy {
  constructor(
    private userSvc: UserService,
    private securitySvc: SecurityService,
    private purchaseSvc: PurchaseService,
    private comSecSvc: CommunitySecurityService,
    private dialog: MatDialog
  ) {}

  permissions$ = this.userSvc.authUser$.pipe(
    switchMap(async () => {
      const [
        isSuper,
        canVoid,
        canRetry,
        canManageSoldBy,
        canManageReferredBy,
        canRefundToBalance,
        canRefundToPaymentMethod,
      ] = await Promise.all([
        this.securitySvc.hasAccess(AccountResource.key, AccountResourceAction.CREATE, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.VOID, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.RETRY, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.MANAGE_SOLD_BY, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.MANAGE_REFERRED_BY, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.REFUND_TO_BALANCE, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.REFUND_TO_PAYMENT_METHOD, {}, true),
      ]);

      return {
        isSuper,
        canVoid,
        canRetry,
        canManageSoldBy,
        canManageReferredBy,
        canRefundToBalance,
        canRefundToPaymentMethod,
      };
    }),
    shareReplay(1)
  );

  @Input() extraMenuOptions: { label: string; icon: string; action: () => Promise<any> }[] = [];

  @PropertyListener('communityId') communityId$ = new BehaviorSubject<string>('');
  @Input() communityId?: string;

  @Input() purchase?: Purchase;
  @PropertyListener('purchase') private _purchase$ = new ReplaySubject<Purchase>(1);
  sub = this._purchase$.subscribe(console.log);

  @Input() showOptions = false;
  @Input() disableCollapsible = true;

  @Input() edit = true;

  @Input() expanded = true;

  @Input() disabledEdit = false;

  @Input() isAdminPage = true;

  canUndoRefund$ = this.communityId$.pipe(
    switchMap(async communityId =>
      this.comSecSvc.hasAccess(communityId, PurchaseResource.key, PurchaseResourceAction.UNDO_REFUND_TO_BALANCE)
    )
  );

  canViewDetails$ = this.communityId$.pipe(
    switchMap(async communityId =>
      this.comSecSvc.hasAccess(communityId, PurchaseResource.key, PurchaseResourceAction.READ)
    )
  );

  loadingRefunds = true;
  _refunds$ = this._purchase$.pipe(
    tap(() => (this.loadingRefunds = true)),
    switchMap(async purchase => (purchase ? await this.purchaseSvc.getPurchaseRefunds(purchase.id) : [])),
    tap(() => (this.loadingRefunds = false))
  );

  signature: string[] = [];

  async refresh() {
    this.purchase = this.purchase ? await this.purchaseSvc.getOne(this.purchase.id) : undefined;
  }

  undoRefund(refund: Refund) {
    const dialog = this.dialog.open(UndoRefundPurchaseDialog, { data: { refund } });
    const sub = dialog.afterClosed().subscribe(async result => {
      if (result?.submit) await this.refresh();
      sub?.unsubscribe();
    });
  }

  private arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach(b => (binary += String.fromCharCode(b)));

    return window.btoa(binary);
  }

  private async cropImage(base64Str: string): Promise<string> {
    return new Promise(resolve => {
      const outputImageAspectRatio = 1;
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const inputWidth = img.naturalWidth;
        const inputHeight = img.naturalHeight;

        // get the aspect ratio of the input image
        const inputImageAspectRatio = inputWidth / inputHeight;

        // if it's bigger than our target aspect ratio
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        if (inputImageAspectRatio > outputImageAspectRatio) {
          outputWidth = inputHeight * outputImageAspectRatio;
        } else if (inputImageAspectRatio < outputImageAspectRatio) {
          outputHeight = inputWidth / outputImageAspectRatio;
        }

        // calculate the position to draw the image at
        const outputX = (outputWidth - inputWidth) * 0.5;
        const outputY = (outputHeight - inputHeight) * 0.5;

        // create a canvas that will present the output image
        const outputImage = document.createElement('canvas');

        // set it to the same size as the image
        outputImage.width = outputWidth;
        outputImage.height = outputHeight;

        // draw our image at position 0, 0 on the canvas
        const ctx = outputImage.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, outputX, outputY);
        }

        resolve(outputImage.toDataURL());
      };
    });
  }

  private async fetchPurchaseImages() {
    const imageElements = document.getElementsByClassName('userImageToExport');

    for (let i = 0; i < imageElements.length; i++) {
      const imageElement = imageElements[i].getElementsByTagName('img')[0] as HTMLImageElement;

      const imageUrl = imageElement.src;

      if (imageUrl) {
        await fetch(imageUrl).then(response => {
          response.arrayBuffer().then(async buffer => {
            const base64Flag = 'data:image/png;base64,';

            const imageStr = this.arrayBufferToBase64(buffer);

            let ImageSource = base64Flag + imageStr;

            ImageSource = await this.cropImage(ImageSource);

            imageElement.src = ImageSource;
          });
        });
      }
    }
  }

  async exportPdf() {
    const purchase = this.purchase;
    if (!purchase) return;

    const data = document.getElementById('exportSection');
    if (data) {
      await this.fetchPurchaseImages();

      const elementsToHide = document.getElementsByClassName('hideFromPDF');

      // Wait for view to update before exporting elements
      setTimeout(() => {
        for (let i = 0; i < elementsToHide.length; i++) {
          (elementsToHide[i] as HTMLElement).style.visibility = 'hidden';
          (elementsToHide[i] as HTMLElement).style.display = 'none';
        }
        data.style.width = '800px';
        html2canvas(data).then(canvas => {
          const fileWidth = 190;
          const fileHeight = (canvas.height * fileWidth) / canvas.width;
          const FILEURI = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const position = 10;

          pdf.addImage('assets/lf3/logo.png', 'PNG', position, position, (3.5 * fileHeight) / 16, fileHeight / 16);
          // pdf.setFontSize(32);
          // pdf.text('INVOICE', position + fileWidth / 2, position + fileHeight / 3 + 10, { align: 'center' });
          pdf.setFontSize(12);
          pdf.text(purchase.account.name, fileWidth + 8, position + 3, { align: 'right' });
          pdf.setFontSize(8);
          pdf.setTextColor('#333');
          pdf.text(purchase.account.address.formatted, fileWidth + 8, position + 8, {
            align: 'right',
          });
          pdf.text(purchase.account.email, fileWidth + 8, position + 12, { align: 'right' });
          pdf.text(this.formatPhoneNumber(purchase.account.phone), fileWidth + 8, position + 16, {
            align: 'right',
          });
          pdf.addImage(FILEURI, 'PNG', position, position + 30, fileWidth, fileHeight);

          pdf.save('purchase_export.pdf');
        });
        data.style.width = 'auto';
        for (let i = 0; i < elementsToHide.length; i++) {
          (elementsToHide[i] as HTMLElement).style.visibility = 'visible';
          (elementsToHide[i] as HTMLElement).style.display = 'flex';
        }
      }, 1000);
    }
  }

  private formatPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) return '';
    const match = phoneNumber.replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : '';
  }

  ngOnDestroy() {
    this._purchase$.complete();
  }
}
