<greco-box
  *grecoLet="selectedAmount === 'custom' as customSelected"
  style="cursor: pointer"
  (click)="buyNow(customSelected ? undefined : select.value, customSelected || false)"
>
  <div class="image-section">
    <img [src]="selectedImageUrl" />

    <mat-icon class="info-icon">info</mat-icon>

    <div class="description">
      <!-- <p *ngIf="product.description">
            <small [innerHtml]="product.description | safe : 'html'"></small>
          </p> -->
      <p *ngIf="giftCardConfig.description">
        <small [innerHtml]="giftCardConfig.description | safe : 'html'"></small>
      </p>
    </div>
  </div>
  <div class="info">
    <h4 style="line-height: 20px">
      <!-- <mat-icon>place</mat-icon> -->
      <span>{{ giftCardConfig | giftCardConfigTitle }}</span>
    </h4>
    <p>
      <mat-icon>card_giftcard</mat-icon>
      <small
        *ngIf="
          giftCardConfig.options.length ||
          (giftCardConfig.allowCustomAmount && giftCardConfig.minimumCustomAmount && giftCardConfig.maximumCustomAmount)
        "
      >
        {{ smallestAmount / 100 | currency
        }}{{
          giftCardConfig.options.length > 1 || (giftCardConfig.allowCustomAmount && giftCardConfig.maximumCustomAmount)
            ? ' - ' + (largestAmount / 100 | currency)
            : ''
        }}
      </small>
      <small
        *ngIf="
          !giftCardConfig.options.length &&
          giftCardConfig.allowCustomAmount &&
          !giftCardConfig.minimumCustomAmount &&
          !giftCardConfig.maximumCustomAmount
        "
        >Member's choice</small
      >
      <small
        *ngIf="
          !giftCardConfig.options.length &&
          giftCardConfig.allowCustomAmount &&
          giftCardConfig.minimumCustomAmount &&
          !giftCardConfig.maximumCustomAmount
        "
        >Starting at {{ giftCardConfig.minimumCustomAmount / 100 | currency }}</small
      >
      <small
        *ngIf="
          !giftCardConfig.options.length &&
          giftCardConfig.allowCustomAmount &&
          !giftCardConfig.minimumCustomAmount &&
          giftCardConfig.maximumCustomAmount
        "
        >Up to {{ giftCardConfig.maximumCustomAmount / 100 | currency }}</small
      >
    </p>
    <mat-form-field appearance="standard" (click)="$event.stopImmediatePropagation()">
      <mat-label>Option</mat-label>
      <mat-select #select [value]="selectedAmount" (valueChange)="selectedAmount = $event">
        <mat-select-trigger *ngIf="select.value">
          <strong *ngIf="!customSelected">{{ $any(selectedAmount) | giftCardOptionTitle }}</strong>
          <strong *ngIf="customSelected">Custom Amount</strong>
        </mat-select-trigger>

        <mat-option *ngFor="let option of giftCardConfig.options" [value]="option">
          <strong>{{ option | giftCardOptionTitle }}</strong>
        </mat-option>
        <mat-option *ngIf="giftCardConfig.allowCustomAmount" [value]="'custom'">
          <strong>Custom Amount</strong>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="button-bar">
      <!-- <button mat-stroked-button (click)="addToCart(select.value)">Add to Cart</button> -->
      <!-- <button mat-stroked-button (click)="goToProductPage(selectedVariant)">More Info</button> -->

      <button
        mat-flat-button
        color="primary"
        (click)="buyNow(customSelected ? undefined : select.value, customSelected || false)"
      >
        Buy Now
      </button>
    </div>
  </div>
</greco-box>
