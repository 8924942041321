import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CreateUserCommunityAgreementDto, UserCommunityAgreement } from '@greco/community-agreements';
import { UserEntity } from '@greco/nestjs-identity-users-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'root' })
export class UserCommunityAgreementsService {
  constructor(private http: HttpClient) {}

  async getOneUserAgreement(userAgreementId: string) {
    return await toPromise(this.http.get<UserCommunityAgreement>(`/api/user_community_agreements/${userAgreementId}`));
  }

  async signUserAgreement(userAgreementId: string, signature?: string) {
    return await toPromise(
      this.http.patch<UserCommunityAgreement>(`/api/user_community_agreements/${userAgreementId}/sign`, {
        ...(signature ? { signature } : {}),
      })
    );
  }

  async getManyUserAgreement(userId: string) {
    return await toPromise(
      this.http.get<UserCommunityAgreement[]>(`/api/user_community_agreements/userAgreements/${userId}`)
    );
  }

  async getUnsignedUserAgreements(userId: string) {
    return await toPromise(
      this.http.get<UserCommunityAgreement[]>(`/api/user_community_agreements/userAgreements/${userId}/unsigned`)
    );
  }

  async getMany(communityId: string) {
    return await toPromise(
      this.http.get<UserCommunityAgreement[]>(`/api/user_community_agreements`, {
        params: { communityId },
      })
    );
  }

  // @Get()
  async paginateUserAgreements(
    query: RequestQueryBuilder,
    userId?: string,
    pagination?: Partial<PaginatedQueryParams>,
    includeArchived?: boolean
  ) {
    return await toPromise(
      this.http.get<PaginatedDto<UserCommunityAgreement>>(`/api/user_community_agreements/paginateUserAgreements`, {
        params: {
          ...query.queryObject,
          ...(userId && { userId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 20).toString(),
          ...(includeArchived && { includeArchived: includeArchived ? 'true' : 'false' }),
        },
      })
    );
  }

  async createUserAgreement(dto: CreateUserCommunityAgreementDto) {
    return await toPromise(this.http.post<UserCommunityAgreement>(`/api/user_community_agreements`, dto));
  }

  async getUserDetails(userId: string): Promise<UserEntity> {
    return await toPromise(this.http.get<UserEntity>(`/api/users/${userId}`));
  }

  async getAgreementDetails(userAgreementId: string): Promise<any> {
    return await toPromise(
      this.http.get<UserCommunityAgreement>(`/api/user_community_agreements/details/${userAgreementId}`)
    );
  }

  async archiveAgreement(userAgreementId: string) {
    return await toPromise(
      this.http.patch<UserCommunityAgreement>(`/api/user_community_agreements/${userAgreementId}/archive`, {})
    );
  }

  async restoreAgreement(userAgreementId: string) {
    return await toPromise(
      this.http.patch<UserCommunityAgreement>(`/api/user_community_agreements/${userAgreementId}/restore`, {})
    );
  }
}
