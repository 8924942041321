<div class="picker-container">
  <button *ngIf="tabs$ | async as tabs" class="page-picker" mat-stroked-button (click)="select.open()">
    <mat-icon>{{ select.value?.iconStart }}</mat-icon>
    {{ select.value?.label }}
    <mat-icon style="color: dimgray">arrow_drop_down</mat-icon>
    <mat-select class="hidden-select" #select [value]="getActive(tabs)">
      <mat-option
        *ngFor="let tab of tabs"
        [value]="tab"
        [routerLink]="tab.routerLink"
        [routerLink]="tab.routerLink ? ((basePath$ | async) || []).concat(tab.routerLink) : (basePath$ | async)"
        [queryParams]="tab.queryParams || {}"
      >
        <mat-icon>{{ tab.iconStart }}</mat-icon>
        {{ tab.label }}
      </mat-option>
    </mat-select>
  </button>
</div>
<router-outlet></router-outlet>

<!-- <greco-tab-routes pageTitle="Workouts & Classes" [tabs]="(tabs$ | async) || []">
  <a
    *ngIf="!(mobileBreakpoint$| async)?.matches && (user$ | async)"
    class="perks-link"
    routerLink="/account/perks"
    target="_blank"
    ><span>My Perks</span> <mat-icon style="width: 16px; height: 16px; font-size: 16px">launch</mat-icon></a
  >
</greco-tab-routes> -->
