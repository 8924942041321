import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { Purchase, UserBalanceTransaction } from '@greco/sales-purchases';

export enum GiftCardStatus {
  UNREDEEMED = 'UNREDEEMED',
  REDEEMED = 'REDEEMED',
}

export interface GiftCard {
  id: string;

  //the user the gift card belongs to
  userId: string;
  user?: User;

  //the community the gift card can be used in
  communityId: string;
  community?: Community;

  optionId: string | null;

  //the starting balance of the gift card
  balance: number;

  status: GiftCardStatus;

  purchaseId: string | null;
  purchase?: Purchase | null;

  balanceTransactionId: string | null;
  balanceTransaction?: UserBalanceTransaction | null;

  created: Date;
  modified: Date;

  //the date the gift card becomes available
  deliveryDate: Date;

  //the user that created the gift card (could be the transferer or a staff member)
  createdById: string;
  createdBy?: User;

  //the user that sent the gift card
  transferredFromId: string | null;
  transferredFrom?: User | null;

  //the name of the person that sent the gift card (in case not user)
  transferredFromName: string | null;

  //the email of the person that sent the gift card (in case not user)
  transferredFromEmail: string | null;

  //message sent with the gift card
  transferMessage: string | null;
}
