import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { UpdateUserDto } from '@greco/nestjs-identity-users-util';
import { APP_CONFIG, AppConfig } from '@greco/ngx-app-config';
import { UserService } from '@greco/ngx-identity-auth';
import { WebcamDialog } from '@greco/ngx-identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'greco-onboarding-page',
  templateUrl: './onboarding.page.html',
  styleUrls: ['./onboarding.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OnboardingPage implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private formBuilder: FormBuilder,
    @Inject(APP_CONFIG) public appConfig: AppConfig
  ) {}

  @PropertyListener('user') user$ = new BehaviorSubject<User | null>(null);
  user: User | null = null;

  appName = 'LF3';

  page = 0;
  now = new Date();
  saving = false;

  profilePictureFile?: File;
  paymentMethodControl = new FormControl(null, Validators.required);

  form = this.formBuilder.group({
    photoURL: [null],

    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.required],
    phoneNumber: ['', Validators.required],

    address: [null, Validators.required],

    birthday: [null, Validators.required],
    gender: [null, Validators.required],
    genderOther: [null],

    emergencyContactName: [null, Validators.required],
    emergencyPhoneNumber: ['', Validators.required],
    emergencyContactRelationship: [null, Validators.required],
  });

  resetValue: any = {
    firstName: '',
    lastName: '',
    email: '',
    friendlyName: '',
    phoneNumber: '',
    address: null,
    birthday: null,
    gender: null,
    genderOther: null,
    photoURL: null,
    emergencyContactName: null,
    emergencyPhoneNumber: '',
    emergencyContactRelationship: null,
  };

  userDetails$ = this.user$.pipe(
    map(user => {
      this.form.patchValue({
        firstName: user?.displayName.split(' ')[0],
        lastName: user?.displayName.split(' ')[1],
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        address: user?.address,
        birthday: user?.birthday,
        gender: user?.gender,
        photoURL: user?.photoURL,
        emergencyContactName: user?.emergencyContactName,
        emergencyPhoneNumber: user?.emergencyPhoneNumber,
        emergencyContactRelationship: user?.emergencyContactRelationship,
      });
    })
  );

  nextPage() {
    this.page += 1;
  }

  prevPage() {
    this.page -= 1;
  }

  setProfilePicture(file: File | null) {
    if (file) {
      this.profilePictureFile = file;
      this.form.setValue({ ...this.form.value, photoURL: URL.createObjectURL(file) });
    } else {
      this.profilePictureFile = undefined;
      this.form.setValue({ ...this.form.value, photoURL: this.user?.photoURL || null });
    }
  }

  async takePhoto() {
    const image = await toPromise(this.dialog.open(WebcamDialog).afterClosed());
    this.setProfilePicture(image);
  }

  private reset() {
    this.now = new Date();

    const fullName = this.user?.displayName?.split(' ');
    const firstName = fullName?.shift() || '';
    const lastName = fullName?.join(' ') || '';

    this.resetValue = {
      firstName,
      lastName,
      email: this.user?.email || this.user?.contactEmail,
      friendlyName: this.user?.friendlyName || '',
      phoneNumber: this.user?.phoneNumber || '',
      address: this.user?.address || null,
      gender: this.user?.gender
        ? ['Male', 'Female', 'Non-binary', 'Transgender', 'Intersex', 'Prefer not to say'].includes(this.user?.gender)
          ? this.user?.gender
          : 'Other'
        : null,
      genderOther: this.user?.gender
        ? ['Male', 'Female', 'Non-binary', 'Transgender', 'Intersex', 'Prefer not to say'].includes(this.user?.gender)
          ? null
          : this.user?.gender
        : null,
      birthday: this.user?.birthday || null,
      photoURL: this.user?.photoURL || null,
      emergencyContactName: this.user?.emergencyContactName || null,
      emergencyPhoneNumber: this.user?.emergencyPhoneNumber || '',
      // emergencyContactEmail: this.user?.emergencyContactEmail || null,
      emergencyContactRelationship: this.user?.emergencyContactRelationship || null,
    };

    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }

  async submit() {
    if (!this.user || !this.form.valid) return;

    this.saving = true;

    try {
      const data: UpdateUserDto = {};

      //disable the user's onboarding flag so that they don't route to here again.
      data.isFlaggedForOnboarding = false;

      const fullName = `${this.form.value.firstName.trim()} ${this.form.value.lastName.trim()}`;
      if (fullName !== this.user?.displayName) {
        data.displayName = fullName;
      }

      if (this.form.value.friendlyName !== (this.user?.friendlyName || '')) {
        data.friendlyName = this.form.value.friendlyName;
      }

      if (this.form.value.phoneNumber !== (this.user?.phoneNumber || '')) {
        data.phoneNumber = this.form.value.phoneNumber;
      }

      if ((this.form.value.address?.formatted || '') !== (this.user?.address?.formatted || '')) {
        data.address = this.form.value.address;
      }

      if (this.form.value.birthday?.valueOf() !== this.user?.birthday?.valueOf()) {
        data.birthday = this.form.value.birthday;
      }

      const gender =
        this.form.value.gender?.valueOf() === 'Other'
          ? this.form.value.genderOther?.valueOf() || this.form.value.gender?.valueOf()
          : this.form.value.gender?.valueOf();
      if (gender !== this.user?.gender?.valueOf()) {
        data.gender = gender;
      }

      if (this.form.value.emergencyContactName !== (this.user?.emergencyContactName || '')) {
        data.emergencyContactName = this.form.value.emergencyContactName;
      }

      if (this.form.value.emergencyPhoneNumber !== (this.user?.emergencyPhoneNumber || '')) {
        data.emergencyPhoneNumber = this.form.value.emergencyPhoneNumber;
      }

      if (this.form.value.emergencyContactRelationship !== (this.user?.emergencyContactRelationship || '')) {
        data.emergencyContactRelationship = this.form.value.emergencyContactRelationship;
      }

      const shouldUpdate = Object.keys(data).length > 0;
      if (!shouldUpdate && !this.profilePictureFile) return;

      if (shouldUpdate) {
        this.user = await this.userSvc.update(this.user.id, data);
      }

      if (this.profilePictureFile) {
        const formData = new FormData();
        formData.append('file', this.profilePictureFile);
        this.user = await this.userSvc.uploadUserPicture(this.user.id, formData);
      }

      this.reset();

      this.snacks.open('Profile Updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });

      this.router.navigate(['..']);
    } catch (err) {
      this.snacks.open('' + 'Please input complete info.', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }

    this.saving = false;
  }

  async ngOnInit() {
    this.appName = this.appConfig.name;
    this.user = await this.userSvc.getSelf();
  }
}
