<ng-container *ngIf="userDetails.contact.user">
  <!-- Account -->
  <div style="display: flex; justify-content: space-between">
    <h2>Account Information</h2>
    <greco-form-save-buttons
      *ngIf="!userDetails.readOnly"
      header
      [form]="form"
      [saveAction]="save"
      [resetValue]="resetValue"
      (formReset)="form.markAsPristine()"
    ></greco-form-save-buttons>
  </div>

  <form [formGroup]="form">
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Email Address</mat-label>
      <input matInput required formControlName="email" />
      <mat-error>A valid email address is required</mat-error>
    </mat-form-field>
  </form>

  <div *ngIf="(canManageAuth$ | async) && (!userDetails.readOnly)" style="display: flex; gap: 8px; flex-wrap: wrap">
    <button
      color="primary"
      style="flex: 1"
      mat-stroked-button
      (click)="verifyEmail()"
      *ngIf="!userDetails.contact.user?.emailVerified"
    >
      <mat-icon>check_circle</mat-icon>
      <span>Verify Email</span>
    </button>

    <button mat-stroked-button color="primary" style="flex: 1" (click)="sendResetEmail()">
      <mat-icon>send</mat-icon>
      <span>Send Password Reset Email</span>
    </button>

    <!-- <button
      style="flex: 1"
      color="primary"
      mat-stroked-button
      [disabled]="loadingResetLink"
      (click)="generateResetLink()"
      *ngIf="!userDetails.readOnly"
    >
      <mat-icon>link</mat-icon>
      <span>Generate Password Reset Link</span>
    </button> -->
  </div>
</ng-container>
