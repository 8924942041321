import { UserPerk } from './user-perk';
export enum UserPerkFreezeType {
  SUBSCRIPTION_FREEZE = 'SUBSCRIPTION_FREEZE',
  FAILED_PAYMENT = 'FAILED_PAYMENT',
  ADMIN = 'ADMIN',
}
export interface UserPerkFreeze {
  id: string;

  userPerkId: string;
  userPerk?: UserPerk;

  type: UserPerkFreezeType;

  correlationId: string;

  frozenOn: Date;
  unfrozenOn: Date | null;
}
