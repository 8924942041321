<mat-tab-group (selectedTabChange)="tabChanged.emit($event)">
  <mat-tab class="mat-tab" *ngFor="let tab of tabs; let index = index" [disabled]="tab.disabled">
    <ng-template mat-tab-label>
      <mat-icon *ngIf="tab.iconStart">{{ tab.iconStart }} </mat-icon>
      {{ tab.label }}
      <mat-icon *ngIf="tab.iconEnd">{{ tab.iconEnd }}</mat-icon>
    </ng-template>
    <ng-container *ngIf="tab.template">
      <ng-container *ngTemplateOutlet="tab.template; context: { $implicit: tab, index: index }"></ng-container>
    </ng-container>
  </mat-tab>

  <mat-tab disabled class="button-tab">
    <ng-template mat-tab-label>
      <ng-content
        select="button[mat-flat-button], button[mat-stroked-button], mat-slide-toggle, mat-button-toggle-group"
      ></ng-content>
    </ng-template>
  </mat-tab>
</mat-tab-group>
