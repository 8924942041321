import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { Contact, ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CreateContactNoteDialog } from '../../dialogs';
import { ContactNotesService } from '../../services';

@Component({
  selector: 'greco-contact-notes-section',
  templateUrl: './contact-notes.component.html',
  styleUrls: ['./contact-notes.component.scss'],
})
export class ContactNotesComponent {
  constructor(private noteSvc: ContactNotesService, private securitySvc: SecurityService, private dialog: MatDialog) {}

  expanded = false;

  @PropertyListener('contact') contact$ = new BehaviorSubject<Contact | null>(null);
  @Input() contact!: Contact;

  private refresh$ = new BehaviorSubject(null);

  notePagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  notePaginationMeta?: IPaginationMeta;

  adminPagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  adminPaginationMeta?: IPaginationMeta;

  notes$ = combineLatest([this.contact$, this.notePagination$, this.refresh$]).pipe(
    switchMap(async ([contact, pagination]) => {
      if (!contact) return null;

      return await this.noteSvc.paginate(contact.id, false, pagination);
    }),
    tap(data => (this.notePaginationMeta = data?.meta))
  );

  adminNotes$ = combineLatest([this.contact$, this.adminPagination$, this.refresh$]).pipe(
    switchMap(async ([contact, pagination]) => {
      if (!contact) return null;

      return await this.noteSvc.paginate(contact.id, true, pagination);
    }),
    tap(data => (this.adminPaginationMeta = data?.meta))
  );

  canDeleteNotes$ = this.contact$.pipe(
    switchMap(async contact => {
      if (!contact) return false;

      return await this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.DELETE_NOTE, {
        communityId: contact.community.id,
      });
    })
  );

  async createNote(admin: boolean) {
    const dialog = this.dialog.open(CreateContactNoteDialog, {
      data: { contactId: this.contact.id, admin },
      width: '800px',
      maxWidth: '90%',
    });
    await toPromise(dialog.afterClosed());

    this.refresh();
  }

  refresh() {
    this.refresh$.next(null);
  }
}
