import { Pipe, PipeTransform } from '@angular/core';
import { GiftCardOptionDto } from '@greco/api-gift-cards';
import { CommunityGiftCardOption } from '@greco/gift-cards';

@Pipe({ name: 'giftCardOptionTitle' })
export class GetGiftCardOptionTitlePipe implements PipeTransform {
  transform(option: GiftCardOptionDto | CommunityGiftCardOption, divide = true): string {
    return (
      option.title ||
      (option.amount !== option.price
        ? `${this.formatCurrency(option.amount, divide)} for ${this.formatCurrency(option.price, divide)}`
        : this.formatCurrency(option.amount, divide)) ||
      ''
    );
  }

  private formatCurrency(amount: number, divide = true) {
    return '$' + (divide ? amount / 100 : amount).toFixed(2);
    //.replace(/[.,]00$/, '');
  }
}
