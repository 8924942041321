import { BaseDto } from '@greco/util-dto';
import { Expose } from 'class-transformer';
import { IsArray, IsBoolean, IsHexColor, IsInt, IsNotEmpty, IsOptional, IsString, Min } from 'class-validator';

export class CreateEventVideoUnlockOptionDto extends BaseDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  communityId: string;

  @Expose()
  @IsHexColor()
  hexColor: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  title: string;

  @Expose()
  @IsString()
  @IsOptional()
  description?: string;

  @Min(0)
  @IsInt()
  @Expose()
  price: number;

  @Min(0)
  @IsInt()
  @Expose()
  rentalPeriod: number;

  @Expose()
  @IsArray()
  @IsString({ each: true })
  videoTagIds: string[];

  @Min(0)
  @IsInt()
  @Expose()
  unlockWindow: number;

  @Expose()
  @IsString()
  @IsOptional()
  icon: 'ondemand_video';

  @Expose()
  @IsBoolean()
  @IsOptional()
  availableToAnyone: false;
}
