import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import {
  Booking,
  BookingStatus,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
} from '@greco/booking-events';
import { CommunityAgreementSecurityActions, CommunityAgreementSecurityResource } from '@greco/community-agreements';
import { UserPaymentMethodResource, UserPaymentMethodResourceAction } from '@greco/finance-payments';
import { Community } from '@greco/identity-communities';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { BookingService } from '@greco/ngx-booking-events';
import { CreateUserAgreementDialog } from '@greco/ngx-community-agreements';
import { UserPaymentMethodsComponent } from '@greco/ngx-finance-payments';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { ContactPickerComponent, ContactService, CreateContactDialog } from '@greco/ngx-identity-contacts';
import { GrantPerkDialog, UserPerksTableComponent } from '@greco/ngx-sales-perks';
import {
  AdjustBalanceDialog,
  BalanceHistorySectionComponent,
  FundUserBalanceDialog,
  FundUserBalanceDialogData,
  PurchasesTableComponent,
  SaleCategoryService,
} from '@greco/ngx-sales-purchases';
import { SubscriptionsService, SubscriptionsTableComponent } from '@greco/ngx-sales-subscriptions';
import { SecurityService } from '@greco/ngx-security-util';
import { PerkResource, PerkResourceAction } from '@greco/sales-perks';
import {
  Purchase,
  PurchaseResource,
  PurchaseResourceAction,
  UserBalanceResource,
  UserBalanceResourceAction,
} from '@greco/sales-purchases';
import { Subscription, SubscriptionResource, SubscriptionResourceAction } from '@greco/sales-subscriptions';
import { CheckInSecurityActions, CheckInSecurityResource, Station } from '@greco/stations';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import moment from 'moment';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ActiveSubscriptionDialog, NewBookingComponent, PurchaseInformationDialog } from '../../dialogs';
import { CheckInService } from '../../services';
import { StationCheckinListComponent } from '../station-checkin-list/station-checkin-list.component';
import { UserDetailCardComponent } from '../user-detail-card/user-detail-card.component';
@Component({
  selector: 'greco-station-details',
  templateUrl: './station-details.component.html',
  styleUrls: ['./station-details.component.scss'],
})
export class StationDetailsComponent implements OnInit {
  constructor(
    private router: Router,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private bookingSvc: BookingService,
    private contactSvc: ContactService,
    private securitySvc: SecurityService,
    private checkedInUserSvc: CheckInService,
    private saleCategorySvc: SaleCategoryService,
    private subscriptionSvc: SubscriptionsService,
    private communitySecuritySvc: CommunitySecurityService
  ) {}

  @ViewChild(UserPerksTableComponent) private perksTableComp?: UserPerksTableComponent;
  @ViewChild(BalanceHistorySectionComponent) private balanceSection?: BalanceHistorySectionComponent;
  @ViewChild(UserDetailCardComponent) private userDetailCardComponent!: UserDetailCardComponent;
  @ViewChild(PurchasesTableComponent) private purchasesTableComponent!: PurchasesTableComponent;
  @ViewChild(UserPaymentMethodsComponent) userPaymentMethods!: UserPaymentMethodsComponent;
  @ViewChild(SubscriptionsTableComponent) private subscriptionsTable?: SubscriptionsTableComponent;
  @ViewChild(ContactPickerComponent) contactPickerComponent!: ContactPickerComponent;
  @ViewChild(StationCheckinListComponent) stationCheckinListComponent!: StationCheckinListComponent;

  @ViewChild('subscriptions') private subscriptionsTemplate?: TemplateRef<any>;
  @ViewChild('purchases') private purchasesTemplate?: TemplateRef<any>;
  @ViewChild('perks') private perksTemplate?: TemplateRef<any>;
  @ViewChild('payment') private paymentTemplate?: TemplateRef<any>;
  @ViewChild('balance') private balanceTemplate?: TemplateRef<any>;

  contactId$: Observable<string> = this.route.queryParams.pipe(
    map(({ contact }) => contact),
    shareReplay(1)
  );

  autoCheckIn = true;
  selectedTabIndex = 0;
  selectedTab = '';
  invoiceTableVisibility = true;
  selectedAccountId: string | null = null;
  expanded = false;
  pageSizes = [5, 10, 20, 50];

  readonly _station$ = new BehaviorSubject<Station | null>(null);
  @Input() set station(station: Station | null) {
    this._station$.next(station);
  }
  get station() {
    return this._station$.value;
  }

  readonly staticFilter = new RequestQueryBuilder().search({
    'user.id': '',
  });

  currentUser$ = this._station$?.pipe(
    filter(c => !!c),
    switchMap(async () => {
      return await this.userSvc.getSelf();
    })
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  community$: Observable<Community> = this.route.parent!.parent!.data.pipe(map(data => data.community));

  public readonly userControl = new FormControl(null, Validators.required);

  contact$: Observable<Contact> = this.userControl.valueChanges;

  readonly _contact$ = combineLatest([this.contact$, this.community$, this._station$]).pipe(
    tap(([contact, community, station]) => {
      if (!community || !station) return;
      this.selectInput(contact);
      this.router.navigate([`/admin/community/${community.id}/stations/${station.id}`], {
        queryParams: { contact: contact?.id },
        relativeTo: this.route,
      });
    })
  );

  readonly _refreshBookings$ = new BehaviorSubject<null>(null);

  bookingFilters$ = combineLatest([this.contact$, this._refreshBookings$]).pipe(
    map(([contact]) => {
      return new RequestQueryBuilder().search({
        'user.id': contact?.user?.id,
      });
    })
  );

  saleCategoryIds$ = from(this.saleCategorySvc.getMany()).pipe(
    shareReplay(1),
    map(scs => scs.map(sc => sc.id).join(','))
  );

  saving = false;

  canInviteUser$ = this._station$.pipe(
    filter(c => !!c),
    switchMap(async station => {
      return await this.securitySvc.hasAccess(CheckInSecurityResource.key, CheckInSecurityActions.CREATE, {
        communityId: station?.community?.id,
      });
    }),
    shareReplay(1)
  );

  canSearchAndInviteUser$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(async community => {
      return await this.securitySvc.hasAccess(CheckInSecurityResource.key, CheckInSecurityActions.CREATE, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  canGrantPerks$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(async community => {
      return await this.securitySvc.hasAccess(PerkResource.key, PerkResourceAction.GRANT, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  canRevokePerks$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(async community => {
      return await this.securitySvc.hasAccess(PerkResource.key, PerkResourceAction.REVOKE, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  canReadPerks$ = this.community$.pipe(
    filter(community => !!community),
    switchMap(async community => {
      return await this.securitySvc.hasAccess(PerkResource.key, PerkResourceAction.VIEW_USER_PERKS, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  canReadBalance$ = this.community$.pipe(
    filter(community => !!community),
    switchMap(async community => {
      return await this.communitySecuritySvc.hasAccess(
        community.id,
        UserBalanceResource.key,
        UserBalanceResourceAction.READ
      );
    }),
    shareReplay(1)
  );

  canFundBalance$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(
            community.id,
            UserBalanceResource.key,
            UserBalanceResourceAction.CREATE_TRANSACTION
          )
        : false
    ),
    shareReplay(1)
  );

  canAdjustBalance$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(
            community.id,
            UserBalanceResource.key,
            UserBalanceResourceAction.MANUALLY_ADJUST
          )
        : false
    ),
    shareReplay(1)
  );

  canReadPurchases$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(community.id, PurchaseResource.key, PurchaseResourceAction.READ)
        : false
    ),
    shareReplay(1)
  );

  canReadSubscriptions$ = combineLatest([this.community$, this.currentUser$]).pipe(
    switchMap(async ([community, currentUser]) => {
      return await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.READ, {
        accountId: community?.financeAccount?.id,
        userId: currentUser?.id,
      });
    }),
    shareReplay(1)
  );

  canAddPaymentMethods$ = this.currentUser$.pipe(
    filter(c => !!c),
    switchMap(async currentUser => {
      return await this.securitySvc.hasAccess(UserPaymentMethodResource.key, UserPaymentMethodResourceAction.ADD, {
        userId: currentUser?.id,
      });
    }),
    shareReplay(1)
  );

  canViewPaymentMethods$ = this.currentUser$.pipe(
    filter(c => !!c),
    switchMap(async currentUser => {
      return await this.securitySvc.hasAccess(UserPaymentMethodResource.key, UserPaymentMethodResourceAction.READ, {
        userId: currentUser?.id,
      });
    }),
    shareReplay(1)
  );

  canReadCheckInList$ = this.community$.pipe(
    switchMap(async community => {
      return await this.securitySvc.hasAccess(CheckInSecurityResource.key, CheckInSecurityActions.READ, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  canUpdateAgreement$ = this.community$.pipe(
    switchMap(async community => {
      return community
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.CREATE,
            {
              communityId: community.id,
            }
          )
        : false;
    }),
    shareReplay(1)
  );

  canReadAgreement$ = this.community$.pipe(
    switchMap(async community => {
      return community
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.READ,
            {
              communityId: community.id,
            }
          )
        : false;
    }),
    shareReplay(1)
  );

  canCreateBookings$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(
            community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.CREATE
          )
        : false
    ),
    shareReplay(1)
  );

  canReadBookings$ = this.community$.pipe(
    switchMap(async community =>
      community
        ? await this.communitySecuritySvc.hasAccess(
            community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.LIST
          )
        : false
    ),
    shareReplay(1)
  );

  tabs$ = combineLatest([
    this.canReadSubscriptions$,
    this.canReadPurchases$,
    this.canReadPerks$,
    this.canViewPaymentMethods$,
    this.canReadBalance$,
  ]).pipe(
    map(([subscriptions, purchases, canReadPerks, canViewPaymentMethods, canReadBalance]) => [
      ...(subscriptions ? [{ label: 'Subscriptions', template: this.subscriptionsTemplate }] : []),
      ...(purchases ? [{ label: 'Purchase History', template: this.purchasesTemplate }] : []),
      ...(canReadPerks ? [{ label: 'Access Perks', template: this.perksTemplate }] : []),
      ...(canViewPaymentMethods ? [{ label: 'Payment Methods', template: this.paymentTemplate }] : []),
      ...(canReadBalance ? [{ label: 'Account Balance', template: this.balanceTemplate }] : []),
    ]),
    shareReplay(1)
  );

  async openNewBookingDialog(user: User | null, contact: Contact | null) {
    const dialog = this.matDialog.open(NewBookingComponent, {
      width: '1000px',
      data: {
        showCloseButton: false,
        title: 'New Booking',
        user: user,
        contact: contact,
      } as { title: string; user: User; contact: Contact },
    });

    const booking: Booking[] | undefined = await toPromise(dialog.afterClosed());

    if (booking?.length) {
      this._refreshBookings$.next(null);
      this.userDetailCardComponent.refreshBookings('IsPending');
    }
  }

  async openSignAgreement(contact: Contact | undefined) {
    const dialog = await toPromise(
      this.matDialog
        .open(CreateUserAgreementDialog, {
          width: '750px',
          maxWidth: '90%',
          data: {
            disableContact: true,
            contact,
            communityId: contact?.community.id,
          },
        })
        .afterClosed()
    );

    if (dialog) this.pageSizes = [...this.pageSizes];
  }

  async openSubscriptionSummary(subscription: Subscription, communityId: string, user: User) {
    const dialog = this.matDialog.open(ActiveSubscriptionDialog, {
      data: {
        showCloseButton: false,
        user,
        communityId: communityId,
        subscription,
      } as { user: User; communityId: string; subscription: Subscription },
    });
    await toPromise(dialog.afterClosed());
  }

  async openPurchaseSummary(purchase: Purchase, communityId: string, user: User | undefined) {
    const dialog = this.matDialog.open(PurchaseInformationDialog, {
      width: '1000px',
      data: {
        showCloseButton: false,
        user,
        communityId: communityId,
        purchase,
      } as { user: User; communityId: string; purchase: Purchase },
    });
    await toPromise(dialog.afterClosed());
  }

  async grantPerk(user: User | undefined) {
    if (this.community$) {
      const community = await toPromise(this.community$);
      if (!user || !community?.financeAccount) return;
      const dialog = this.matDialog.open(GrantPerkDialog, {
        data: {
          user,
          communityId: community.id,
        } as { user: User; communityId: string },
      });
      const result = await toPromise(dialog.afterClosed());
      if (result) this.perksTableComp?.refresh?.();
    }
  }

  async adjustBalance(user: User | undefined) {
    if (this.community$) {
      const community = await toPromise(this.community$);

      if (!user || !community.financeAccount) return;

      const dialog = this.matDialog.open(AdjustBalanceDialog, {
        data: {
          user,
          account: community.financeAccount,
        },
      });

      const result = await toPromise(dialog.afterClosed());
      if (result) this.balanceSection?.refresh?.();
    }
  }

  async fundAccount(user: User | undefined) {
    if (this.community$) {
      const community = await toPromise(this.community$);
      if (!user || !community.financeAccount) return;

      const dialog = this.matDialog.open(FundUserBalanceDialog, {
        data: { user, community } as FundUserBalanceDialogData,
      });

      const result = await toPromise(dialog.afterClosed());
      if (result) this.balanceSection?.refresh?.();
    }
  }

  async createContact() {
    const dialog = this.matDialog.open(CreateContactDialog, {
      data: { communityId: 'com_greco', forceUserInvite: true },
      width: '750px',
      maxWidth: '90%',
    });
    const contact: Contact = await toPromise(dialog.afterClosed());
    if (contact?.user?.id) {
      this.userControl.setValue(contact);
    }
  }
  refreshBookingTable(bookingStatus: string) {
    if (bookingStatus === 'TriedConfirming') {
      this._refreshBookings$.next(null);
    }
  }

  refreshPurchaseHistory(purchaseStatus: string) {
    if (purchaseStatus === 'NewPurchase') {
      this.purchasesTableComponent.refresh();
    }
  }

  async pasteMemberNumber(event: ClipboardEvent, communityId: string) {
    if (!this.autoCheckIn) return;

    let foundContact = true;

    const data = event.clipboardData?.getData('text') || '';
    if (data.includes('@')) {
      const contact = await this.contactSvc.getContactByEmail(data, communityId, true).catch(console.error);
      if (contact) {
        this.userControl.setValue(contact);
        this.selectInput(contact);
        return;
      } else foundContact = false;
    } else {
      const contact = await this.contactSvc.getContactByMemberNumber(data).catch(console.error);
      if (contact) {
        this.userControl.setValue(contact);
        this.selectInput(contact);
        return;
      } else foundContact = false;
    }

    if (!foundContact) {
      this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Invalid Member Number!',
          content:
            'The member number that was just scanned does not match any contacts in the system.<br><ul><li><em>Confirm the member number is correctly configured in the member contact you are trying to scan.</em></li></ul>',
          buttons: [{ label: 'Continue', role: 'yes' }],
        } as DialogData,
        width: '100%',
        maxWidth: '400px',
      });
    }

    this.userControl.setValue(null);
    this.selectInput();
  }

  async checkInUser(userId: string | undefined, stationId: string | undefined) {
    if (!userId || !stationId) return;

    await this.checkedInUserSvc
      .createCheckedInUser({
        userId: userId,
        stationId: stationId,
      })
      .then(() => this.stationCheckinListComponent.refreshList());

    // Auto check-in user if event is within the next hour
    const bookings = await this.bookingSvc.getByDate({
      startDate: new Date(),
      endDate: moment(new Date()).add(1, 'hour').toDate(),
      userId,
      statuses: [BookingStatus.CONFIRMED], // Don't check-in pending bookings, they need to be confirmed manually
    });

    if (bookings.length) {
      await Promise.all(bookings.map(async booking => await this.bookingSvc.checkIn(booking.id)));

      this.userDetailCardComponent._refreshBookings$.next(null);
    }
  }

  selectInput(contact?: Contact) {
    if (this.contactPickerComponent?.input) {
      this.contactPickerComponent.input.value = contact?.user?.displayName || '';
      setTimeout(() => this.contactPickerComponent.nativeInput?.nativeElement.select());
    }
  }

  toggleAutoCheckIn() {
    this.autoCheckIn = !this.autoCheckIn;
    localStorage.setItem('autoCheckIn' + this.station?.id, this.autoCheckIn ? 'true' : 'false');
  }

  defaultPaymentMethodUpdated() {
    this.matDialog
      .open(SimpleDialog, {
        data: {
          title: 'Update subscription',
          subtitle: 'Do you wish to change the payment method on your active subscription(s)?',

          showCloseButton: false,
          buttons: [
            { label: 'No', role: 'cancel' },
            { label: 'Yes', role: 'confirm' },
          ],
        },
      })
      .beforeClosed()
      .pipe(
        first(),
        switchMap(data => {
          if (data !== 'confirm') return of(null);
          return this._contact$.pipe(map(([contact]) => contact.user?.id));
        })
      )
      .subscribe(async userId => {
        if (!userId) return;

        await this.subscriptionSvc.updateAllSubscriptionsToDefaultPaymentMethod(userId);
        this.subscriptionsTable?.refresh();
        this.userPaymentMethods?.refresh();
      });
  }

  async ngOnInit() {
    this.autoCheckIn = localStorage.getItem('autoCheckIn' + this.station?.id) === 'false' ? false : true;

    const contactId = await toPromise(this.contactId$);
    if (contactId) {
      const contact = await this.contactSvc.getContact(contactId);
      this.userControl.setValue(contact);
    }
  }
}
