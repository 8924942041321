<ng-container *grecoLet="canCreate$ | async as canCreate">
  <ng-container *grecoLet="canUpdate$ | async as canUpdate">
    <greco-filter-bar (changed)="onFilterApplied($event[0])" [filterOptions]="filterOptions">
      <button *ngIf="canCreate" mat-stroked-button color="primary" style="height: 32px" (click)="createStation()">
        <mat-icon>add</mat-icon>
        <span>Add Station</span>
      </button>
    </greco-filter-bar>

    <greco-table style="cursor: pointer" [data]="(stations$ | async) || []" (rowClick)="openStation($event)">
      <!-- id -->
      <mat-icon *grecoTableCol="''; fitContent: true; let station" [matTooltip]="station.id" [matTooltip]="station.id">
        shopping_cart_checkout
      </mat-icon>

      <!-- title -->
      <p *grecoTableCol="'Title'; let station">{{ station.title }}</p>

      <!-- saleCategories -->
      <mat-chip-list *grecoTableCol="'Sale Categories'; let station">
        <mat-chip *ngIf="station.includeUncategorized">Uncategorized</mat-chip>
        <mat-chip *ngFor="let saleCategory of station.saleCategories">{{ saleCategory.label }}</mat-chip>
      </mat-chip-list>

      <p *grecoTableCol="'Show Uncategorized'; let station">{{ station.includeUncategorized ? 'Yes' : '-' }}</p>

      <p *grecoTableCol="'Default User'; let station">{{ station.defaultUser?.displayName || '-' }}</p>

      <mat-chip-list *grecoTableCol="'Terminals'; let station">
        <mat-chip *ngFor="let terminal of station.terminals; let index"> {{ terminal.label }} </mat-chip>
      </mat-chip-list>

      <!-- actions -->
      <div *grecoTableCol="''; fitContent: true; stickyEnd: true; let station">
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openStation(station)">
            <mat-icon>open_in_browser</mat-icon>
            <span>Open</span>
          </button>

          <button *ngIf="canUpdate" mat-menu-item (click)="updateStation(station)">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
        </mat-menu>
      </div>
    </greco-table>

    <mat-paginator
      *ngIf="metadata?.totalItems"
      showFirstLastButtons
      [pageSizeOptions]="[10, 20, 50]"
      [length]="metadata!.totalItems || 0"
      [pageSize]="metadata!.itemsPerPage || 10"
      (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
    ></mat-paginator>
  </ng-container>
</ng-container>
