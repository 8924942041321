import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { VideoCollection } from '@greco/video-library';
import { QueryFilter, RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({
  providedIn: 'root',
})
export class CollectionsService {
  baseUrl = '/api/video_collections';
  constructor(private http: HttpClient) {}
  //@Get('all/:communityId')
  async paginate(
    query: RequestQueryBuilder,
    communityId: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<VideoCollection>> {
    return await toPromise(
      this.http.get<PaginatedDto<VideoCollection>>(`${this.baseUrl}`, {
        params: {
          ...query.queryObject,
          communityId: communityId,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  async paginateForUser(
    communities: string[],
    title?: string,
    pagination?: Partial<PaginatedQueryParams>,
    featured?: boolean,
    tags: { items: string[]; exclude?: boolean } = { items: [] },
    excludeCollections: string[] = []
  ): Promise<PaginatedDto<VideoCollection>> {
    const query = new RequestQueryBuilder();
    const filters: QueryFilter[] = [
      { field: 'availableOn', operator: '$lt', value: new Date().toISOString() },
      { field: 'availableUntil', operator: '$gt', value: new Date().toISOString() },
    ];
    const or: QueryFilter[] = [
      {
        field: 'availableOn',
        operator: '$lt',
        value: new Date().toISOString(),
      },
      {
        field: 'availableUntil',
        operator: '$isnull',
      },
    ];
    if (title) {
      filters.push({ field: 'title', operator: '$cont', value: title }),
        or.push({ field: 'title', operator: '$cont', value: title });
    }
    if (excludeCollections.length) {
      filters.push({ field: 'id', operator: '$notin', value: excludeCollections }),
        or.push({ field: 'id', operator: '$notin', value: excludeCollections });
    }
    if (featured === true) {
      filters.push({ field: 'featured', operator: '$eq', value: true });
      or.push({ field: 'featured', operator: '$eq', value: true });
    } else if (featured === false) {
      filters.push({ field: 'featured', operator: '$eq', value: false });
      or.push({ field: 'featured', operator: '$eq', value: false });
    }
    if (tags.items.length) {
      if (tags.exclude === true) {
        filters.push({ field: 'collectionTagId', operator: '$notin', value: tags.items }),
          or.push({ field: 'collectionTagId', operator: '$notin', value: tags.items });
      } else {
        filters.push({ field: 'collectionTagId', operator: '$in', value: tags.items }),
          or.push({ field: 'collectionTagId', operator: '$in', value: tags.items });
      }
    }

    query.setFilter(filters).setOr(or);
    query.sortBy({ field: 'title', order: 'ASC' });
    const response = await toPromise(
      this.http.get<PaginatedDto<VideoCollection>>(`${this.baseUrl}`, {
        params: {
          ...query.queryObject,
          communities: communities,
          excludeEmpty: 'true',
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
    return response;
  }

  //@Get(':programId')
  async getOneProgram(programId: string) {
    return await toPromise(this.http.get<VideoCollection>(`${this.baseUrl}/${programId}`));
  }

  async createProgram(dto: any) {
    return await toPromise(this.http.post<VideoCollection>(`${this.baseUrl}`, dto));
  }

  // @Patch(':programId')
  async updateProgram(programId: string, dto: any) {
    return await toPromise(this.http.patch<VideoCollection>(`${this.baseUrl}/${programId}`, dto));
  }

  async updateProgramImage(collectionId: string, formData: FormData) {
    return await toPromise(this.http.patch<VideoCollection>(`${this.baseUrl}/${collectionId}/image`, formData));
  }

  async removeProgramImage(collectionId: string) {
    return await toPromise(this.http.delete<VideoCollection>(`${this.baseUrl}/${collectionId}/image`));
  }
}
