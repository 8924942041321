<ng-container *grecoLet="$any(eventService.dateRange$ | async)[0] as selectedDay">
  <!-- <a href="../workouts" style="display: flex; justify-content: center; text-decoration: none">
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin-bottom: 16px;
        padding: 6px 12px;
        border-radius: 8px;
        color: rgb(var(--primary-color-rgb));
        background-color: rgb(var(--primary-color-rgb), 0.2);
        font-weight: medium;
        overflow: hidden;
      "
    >
      <mat-icon
        style="color: rgb(var(--primary-color-rgb)); width: 20px; height: 20px; line-height: 20px; font-size: 20px"
      >
        info
      </mat-icon>
      <span>
        <span *ngIf="(isMobile$ | async) === false"> This is our <strong>new</strong> booking experience! </span>
        Looking for the <span style="text-decoration: underline">previous booking experience?</span>
      </span>
    </div>
  </a> -->
  <div class="header">
    <h2>
      {{ selectedDay | date : 'MMMM YYYY' }}
      <button
        style="margin-left: 8px"
        mat-icon-button
        [disabled]="selectedDay.getTime() <= today.getTime()"
        (click)="moveBack(selectedDay)"
      >
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button mat-icon-button (click)="moveForward(selectedDay)">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </h2>

    <a
      mat-button
      color="accent"
      class="today-link"
      (click)="eventService.dateRange$.next([today, moment(today).endOf('day').toDate()])"
    >
      Today
    </a>
  </div>

  <div class="days">
    <div
      class="day"
      *ngFor="let day of weekDays$ | async"
      [class.disabled]="day.getTime() < today.getTime()"
      [class.selected]="selectedDay?.getTime() === day.getTime()"
      [class.today]="today.getTime() === day.getTime()"
      [class.weekend]="(day | date : 'EEE') === 'Sat' || (day | date : 'EEE') === 'Sun'"
      (click)="eventService.dateRange$.next([day, moment(day).endOf('day').toDate()])"
    >
      <p>{{ day | date : 'EEE' }}</p>
      <p>{{ day | date : 'dd' }}</p>
    </div>
  </div>
</ng-container>
