import { Component, Input, OnDestroy } from '@angular/core';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { PropertyListener } from '@greco/property-listener-util';
import { Subscription } from '@greco/sales-subscriptions';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-subscription-purchase-history',
  templateUrl: './subscription-purchase-history.component.html',
  styleUrls: ['./subscription-purchase-history.component.scss'],
})
export class SubscriptionPurchaseHistoryComponent implements OnDestroy {
  constructor(private subscriptionSvc: SubscriptionsService) {}

  @PropertyListener('subscription') private subscription$ = new ReplaySubject<Subscription | null>(1);
  @Input() subscription?: Subscription | null;

  _paginationMeta?: IPaginationMeta;
  readonly pagination$ = new BehaviorSubject<PaginatedQueryParams>({ page: 1, limit: 5 });

  purchases$ = combineLatest([this.subscription$, this.pagination$]).pipe(
    switchMap(([subscription, pagination]) => {
      if (!subscription) return [];
      else return this.subscriptionSvc.getSubscriptionPurchases(subscription.id, pagination);
    }),
    tap(data => (this._paginationMeta = data?.meta)),
    map(data => data?.items || [])
  );

  readonly _purchaseTypeMappings: { [key: string]: string } = {
    SubscriptionStartPurchaseEntity: 'Subscription Start',
    SubscriptionRenewalPurchaseEntity: 'Subscription Renewal',
    SubscriptionUnfreezePurchaseEntity: 'Subscription Unfreeze',
  };

  ngOnDestroy() {
    this.pagination$.complete();
  }
}
