import { Pipe, PipeTransform } from '@angular/core';
import { Booking } from '@greco/booking-events';
import moment from 'moment';

@Pipe({
  name: 'IsUnderCheckInWindow',
})
export class CheckInWindowPipe implements PipeTransform {
  transform(booking: Booking): boolean {
    const currentDate = moment(new Date()).toDate();
    const startDate = booking.event.startDate;
    const subtractedDateTime = moment(booking.event.startDate)
      .subtract(booking.event.checkInWindow, 'minutes')
      .toDate();
    return moment(currentDate).isBetween(moment(subtractedDateTime), moment(startDate));
  }
}
