import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CommunityVideoUnlock } from '@greco/community-videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationOptions } from 'nestjs-typeorm-paginate';

@Injectable({ providedIn: 'root' })
export class VideoUnlocksService {
  constructor(private http: HttpClient) {}

  async paginate(
    communityId: string,
    showExpired: boolean,
    query: RequestQueryBuilder,
    pagination?: IPaginationOptions
  ): Promise<PaginatedDto<CommunityVideoUnlock>> {
    return await toPromise(
      this.http.get<PaginatedDto<CommunityVideoUnlock>>(`/api/community-videos-unlock/community`, {
        params: {
          ...query.queryObject,
          communityId: communityId,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          showExpired: showExpired ? 'true' : 'false',
        },
      })
    );
  }
}
