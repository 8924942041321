<div *ngIf="canRead$ | async">
  <div *ngIf="collection$ | async as collection" style="display: flex; flex-direction: column; gap: 16px">
    <greco-collapsible-section [header]="{title: 'Collection Details', icon: 'video_library'}" [disabled]="true">
      <button header *ngIf="changed && (canManage$ | async)" mat-flat-button color="primary" (click)="save()">
        Save
      </button>
      <!-- Status -->
      <ng-container header>
        <ng-container
          *grecoLet="
                collection.availableOn &&
                (collection.availableOn | grecoDateCompare: 'lt') &&
                (!collection.availableUntil || (collection.availableUntil | grecoDateCompare: 'gt')) as active
              "
        >
          <ng-container *grecoLet="!collection.availableOn && !collection.availableUntil as draft">
            <mat-chip-list
              ><mat-chip [ngClass]="active ? '' : draft ? 'draft' : 'inactive'"
                >{{ active ? 'Active' : draft ? 'Draft' : 'Archived' }}</mat-chip
              ></mat-chip-list
            ></ng-container
          ></ng-container
        >
      </ng-container>
      <ng-container header>
        <button
          *ngIf="canManage$ | async"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [matTooltip]="'Options for ' + (collection.id)"
          (click)="$event.stopImmediatePropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu>
          <!-- Activate/Archive -->
          <ng-container *ngIf="canManage$ | async">
            <ng-container *grecoLet=""></ng-container>
            <button
              mat-menu-item
              (click)="changeStatus()"
              *grecoLet="collection.availableOn && (collection.availableOn | grecoDateCompare: 'lt') && (!collection.availableUntil || (collection.availableUntil | grecoDateCompare: 'gt')) as active"
            >
              <mat-icon>{{ !active ? 'unarchive' : 'archive' }}</mat-icon>
              <span>{{ !active ? 'Activate' : 'Archive' }}</span>
            </button></ng-container
          >
          <!-- Remove 
        <button mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>-->
        </mat-menu>
      </ng-container>

      <form [formGroup]="form">
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Title</mat-label>
          <input
            matInput
            [value]="collection.title"
            formControlName="title"
            (keyup)="registerChange()"
            [readonly]="(canManage$ | async) === false"
          />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Icon</mat-label>
          <greco-icon-picker formControlName="icon" [required]="false"></greco-icon-picker>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            formControlName="description"
            [value]="collection.description"
            (keyup)="registerChange()"
            [readonly]="(canManage$ | async) === false"
          ></textarea>
        </mat-form-field>
        <!--Publish Date-->
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Available On</mat-label>
          <mat-datepicker-toggle
            [for]="publishDate"
            matSuffix
            [disabled]="(canManage$ | async) === false"
          ></mat-datepicker-toggle>
          <mat-datepicker #publishDate (closed)="changed = true"></mat-datepicker>
          <input
            [matDatepicker]="publishDate"
            matInput
            autocomplete="false"
            formControlName="availableOn"
            (keyup)="changed= true"
            [readonly]="(canManage$ | async) === false"
          />
        </mat-form-field>
        <!--Archive Date-->
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Available Until</mat-label>
          <mat-datepicker-toggle
            [for]="archiveDate"
            matSuffix
            [disabled]="(canManage$ | async) === false"
          ></mat-datepicker-toggle>
          <mat-datepicker #archiveDate (closed)="changed = true"></mat-datepicker>
          <input
            [matDatepicker]="archiveDate"
            matInput
            autocomplete="false"
            formControlName="availableUntil"
            (keyup)="changed = true"
            [readonly]="(canManage$ | async) === false"
          />
        </mat-form-field>

        <!-- Image -->
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Image</mat-label>
          <greco-image-upload
            [urls]="collection?.imageURL ? [collection.imageURL || ''] : []"
            formControlName="image"
            (click)="changed = true"
          ></greco-image-upload>
        </mat-form-field>

        <!-- Featured -->
        <div style="margin: 16px 0">
          <mat-checkbox
            formControlName="featured"
            color="primary"
            (change)="changed = true"
            [disabled]="(canManage$ | async) === false"
            >Featured</mat-checkbox
          >
        </div>
      </form>
    </greco-collapsible-section>

    <greco-collapsible-section id="playlist_section" [header]="{title: 'Subcollections'}" [disabled]="true">
      <button
        header
        mat-stroked-button
        color="primary"
        style="height: 32px; line-height: 16px; margin-left: 16px"
        (click)="$event.stopImmediatePropagation(); createNewSub()"
        *ngIf="canManage$ | async"
      >
        <mat-icon>add</mat-icon>
        <span>New Subcollection</span>
      </button>
      <div *ngIf="playlists$ | async as playlists" style="display: flex; flex-direction: column">
        <greco-playlist-admin
          class="subcollection"
          [id]="subcollection.id"
          [scrollTo]="true"
          *ngFor="let subcollection of playlists"
          [subcollection]="subcollection"
          [length]="playlists.length"
          (sortChanged)="refresh$.next(null)"
          [expanded]="initialSubId === subcollection.id"
          [scrollTo]="initialSubId === subcollection.id"
        >
        </greco-playlist-admin>
      </div>
      <mat-divider></mat-divider>
    </greco-collapsible-section>
  </div>
</div>
