import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  CreateProductDto,
  CreateProductOptDto,
  UpdateProductDto,
  UpdateProductOptDto,
} from '@greco/nestjs-sales-products';
import { Product, ProductImage, ProductOption } from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { Pagination } from 'nestjs-typeorm-paginate';

@Injectable()
export class ProductsService {
  constructor(private http: HttpClient) {}

  /* Paginate Endpoints */
  async paginateProducts(
    query: RequestQueryBuilder,
    communityId?: string,
    pagination?: PaginatedQueryParams,
    saleCategoryIds?: string[],
    showUncategorized?: boolean
  ): Promise<Pagination<Product>> {
    return await toPromise(
      this.http.post<Pagination<Product>>(
        '/api/products',
        {
          saleCategoryIds: saleCategoryIds || [],
        },
        {
          params: {
            ...query.queryObject,
            ...(communityId && { communityId }),
            ...(showUncategorized && { showUncategorized: showUncategorized ? 'true' : 'false' }),
            page: (pagination?.page || 1).toString(),
            limit: (pagination?.limit || 10).toString(),
          },
        }
      )
    );
  }

  async paginateProductImages(page: number, limit: number, productId: string): Promise<Pagination<ProductImage>> {
    return await toPromise(
      this.http.get<Pagination<ProductImage>>(`/api/products/${productId}/images`, {
        params: {
          page: page.toString(),
          limit: limit.toString(),
        },
      })
    );
  }

  async paginateProductOptions(page: number, limit: number, productId: string): Promise<Pagination<ProductOption>> {
    return await toPromise(
      this.http.get<Pagination<ProductOption>>(`/api/products/${productId}/options`, {
        params: {
          page: page.toString(),
          limit: limit.toString(),
        },
      })
    );
  }

  /* GetOne Endpoints */
  async getOneProduct(id: string): Promise<Product> {
    return await toPromise(this.http.get<Product>(`/api/products/${id}`));
  }

  async getOneImage(imageId: string, productId: string): Promise<ProductImage> {
    return await toPromise(this.http.get<ProductImage>(`/api/products/${productId}/images/${imageId}`));
  }

  async getOneProductOption(optionId: string, productId: string): Promise<ProductOption> {
    return await toPromise(this.http.get<ProductOption>(`/api/products/${productId}/options/${optionId}`));
  }

  /* Product Endpoints */
  async createProduct(data: CreateProductDto): Promise<Product> {
    return await toPromise(this.http.post<Product>('/api/products/new', data));
  }

  async updateProduct(id: string, data: UpdateProductDto): Promise<Product> {
    return await toPromise(this.http.put<Product>(`/api/products/${id}`, data));
  }

  async updateProductImages(id: string, data: FormData): Promise<Product> {
    return await toPromise(this.http.post<Product>(`/api/products/${id}/images`, data));
  }

  async removeProductImages(id: string, images: string[]): Promise<ProductImage[]> {
    return await toPromise(this.http.patch<ProductImage[]>(`/api/products/${id}/images`, images));
  }

  async activateProduct(productId: string): Promise<Product> {
    return await toPromise(this.http.put<Product>(`/api/products/${productId}/activate`, {}));
  }

  async archiveProduct(productId: string): Promise<Product> {
    return await toPromise(this.http.put<Product>(`/api/products/${productId}/archive`, {}));
  }

  /* Product Option Endpoints */
  async createProductOption(productId: string, data: CreateProductOptDto): Promise<ProductOption> {
    return await toPromise(this.http.post<ProductOption>(`/api/products/${productId}/options`, data));
  }

  async updateProductOption(optionId: string, productId: string, data: UpdateProductOptDto): Promise<ProductOption> {
    return await toPromise(this.http.put<ProductOption>(`/api/products/${productId}/options/${optionId}`, data));
  }

  async removeProductOption(optionId: string, productId: string): Promise<ProductOption> {
    return await toPromise(this.http.delete<ProductOption>(`/api/products/${productId}/options/${optionId}`));
  }

  /* Image Endpoints */
  async uploadProductImage(productId: string, formData: FormData): Promise<ProductImage> {
    return await toPromise(this.http.post<ProductImage>(`/api/products/${productId}/images`, formData));
  }

  async removeProductImage(imageId: string, productId: string): Promise<ProductImage> {
    return await toPromise(this.http.delete<ProductImage>(`/api/products/${productId}/images/${imageId}`));
  }
}
