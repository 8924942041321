<form class="user-workout-library-filters-form" [formGroup]="form">
  <mat-form-field *ngIf="!(mobileBreakpoint$ | async)?.matches" appearance="outline">
    <mat-label>Trainers</mat-label>
    <mat-select
      multiple
      formControlName="trainers"
      [compareWith]="compareGroups"
      panelClass="myPanelClass"
      (valueChange)="emitTrainers($event)"
    >
      <div class="wrapper">
        <mat-option *ngFor="let group of trainers$ | async" [value]="group">
          <div style="display: flex; align-items: center">
            <greco-multi-avatar
              style="margin-left: 8px; margin-right: 16px"
              [images]="[group.photoUrl]"
              [size]="24"
            ></greco-multi-avatar>
            <span>{{ group.name }}</span>
          </div>
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Event Tags</mat-label>
    <mat-select
      multiple
      formControlName="eventTags"
      [compareWith]="compareTags"
      panelClass="myPanelClass"
      (valueChange)="emitEventTags($event)"
    >
      <div class="wrapper">
        <mat-option *ngFor="let tag of eventTags$ | async" [value]="tag">
          <div style="display: flex; align-items: center">
            <span>{{ tag.label }}</span>
          </div>
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>

  <!-- <mat-form-field *ngIf="!(mobileBreakpoint$ | async)?.matches" appearance="outline">
    <mat-label>Video Tags</mat-label>
    <mat-select
      multiple
      formControlName="videoTags"
      [compareWith]="compareTags"
      panelClass="myPanelClass"
      (valueChange)="emitVideoTags($event)"
    >
      <div class="wrapper">
        <mat-option *ngFor="let tag of videoTags$ | async" [value]="tag">
          <div style="display: flex; align-items: center">
            <span>{{ tag.label }}</span>
          </div>
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field> -->
</form>
