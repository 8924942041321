<ng-container *ngIf="paymentDispute$ | async as paymentDispute">
  <greco-alert
    [config]="{
      title: 'Payment Dispute',
      type: $any('DANGER'),
      description: (subtitle$ | async) || ''
    }"
  >
    <button mat-flat-button slot="buttons" color="primary" (click)="navigate(paymentDispute.id)">Resolve</button>
  </greco-alert>
</ng-container>
