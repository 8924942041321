import { Component, Input, OnInit } from '@angular/core';
import { AccountLinkStatus } from '@greco/account-linking';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { GiftCardConfigService } from '@greco/ngx-gift-cards';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunityService } from '@greco/ngx-identity-communities';
import { ProductConditionService } from '@greco/ngx-sales-products';
import { PropertyListener } from '@greco/property-listener-util';
import { ProductCollection } from '@greco/sales-products';
import { AccountLinkingService } from '@greco/web-account-linking';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { ShopService } from '../../services';

@Component({
  selector: 'greco-shop',
  templateUrl: './shop.page.html',
  styleUrls: ['./shop.page.scss'],
})
export class ShopPage implements OnInit {
  signedOutCollections: ProductCollection[] = [];

  signedOutMockContext = {
    productPurchases: { id: 1 },
    categoryPurchases: { id: 4 },

    productSubscriptions: { id: 5 },
    categorySubscriptions: { id: 9 },
  };
  constructor(
    private userSvc: UserService,
    private shopSvc: ShopService,
    private communitySvc: CommunityService,
    private conditionSvc: ProductConditionService,
    private linkSvc: AccountLinkingService,
    private giftCardConfigSvc: GiftCardConfigService
  ) {}

  @PropertyListener('user') user$ = new BehaviorSubject<User | undefined>(undefined);
  @Input() user?: User;

  linkedAccounts$ = this.user$.pipe(
    switchMap(async user => {
      return user
        ? (await this.linkSvc.getPrivilegeLinksForAccessor(user.id))?.filter(
            link => link.status === AccountLinkStatus.ACTIVE
          )
        : [];
    })
  );

  @PropertyListener('communities') private _communities$ = new ReplaySubject<Community[]>(1);
  communities: Community[] = [];

  collections$ = this._communities$.pipe(
    switchMap(async communities => await this.shopSvc.getAllCollections(communities.map(com => com.id))),
    map(cols =>
      cols.sort((a, b) => (a.label === 'Featured' ? -1 : b.label === 'Featured' ? 1 : a.label.localeCompare(b.label)))
    )
  );

  giftCardConfigs$ = this._communities$.pipe(
    switchMap(async communities => await this.giftCardConfigSvc.getConfigsForShop(communities.map(com => com.id)))
  );

  userConditionContext$ = this.userSvc.getUserId().pipe(
    distinctUntilChanged(),
    switchMap(userId => this.conditionSvc.getConditionContext(userId)),
    shareReplay(1)
  );

  isSignedIn$ = this.userSvc.isSignedIn$();

  async communitiesChanged(communities: Community[]) {
    this.communities = communities;
  }

  async ngOnInit() {
    this.signedOutCollections = await this.shopSvc.getAllCollections(
      (await this.communitySvc.getAllCommunities()).filter(comm => comm.canBeParent === true).map(comm => comm.id)
    );
  }
}
