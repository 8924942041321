import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { PurchaseService } from '@greco/ngx-sales-purchases';
import { SecurityService } from '@greco/ngx-security-util';
import { Purchase, PurchaseResource, PurchaseResourceAction, PurchaseStatus } from '@greco/sales-purchases';
import { CurrencyMaskConfig } from 'ngx-currency';

@Component({
  selector: 'greco-void-purchase-dialog',
  templateUrl: './void.dialog.html',
  styleUrls: ['./void.dialog.scss'],
})
export class VoidPurchaseDialog {
  voidReasons = ['Bad Debt', 'Admin Error'].sort();

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly purchase: Purchase,
    private dialogRef: MatDialogRef<VoidPurchaseDialog>,
    private securitySvc: SecurityService,
    private purchaseSvc: PurchaseService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar
  ) {}

  readonly dialogData: DialogData = {
    title: 'Void Purchase',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  formGroup = this.formBuilder.group({
    voidReason: ['', [Validators.required]],
  });

  processing = false;

  canVoid$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.VOID, {}, true);

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;

    try {
      const purchase = await this.purchaseSvc.void(this.purchase.id, this.formGroup.value.voidReason);

      if (purchase.status !== PurchaseStatus.VOIDED) {
        console.error('Purchase could not be voided');
        this.snacks.open('Purchase could not be voided', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      } else {
        this.snacks.open('Voided successfully!', 'Ok', { duration: 3000 });
      }
    } catch (err) {
      console.error(err);
    }

    this.processing = false;
    this.close({
      submit: true,
    });
  }
}
