<ng-container *ngIf="station$ | async as station">
  <ng-container *grecoLet="stationProducts$ | async as products">
    <ng-container *grecoLet="stationProductsRecord$ | async as productsRecord">
      <ng-container *grecoLet="canSellOutOfStock$ | async as canSellOutOfStock">
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 8px;
            padding: 8px;
            padding-right: 16px;
            justify-content: space-between;
            margin-bottom: -16px;
          "
        >
          <div style="display: flex; flex-direction: row">
            <button mat-icon-button style="margin-top: -4px" (click)="goBack()">
              <mat-icon>arrow_back</mat-icon>
            </button>
            <h1 class="heading">{{station.title}} Checkout</h1>
          </div>

          <!-- <button mat-stroked-button style="align-self: flex-end">Orders</button> -->
        </div>
        <!-- <mat-divider></mat-divider> -->

        <div class="main">
          <!-- Left -->
          <div class="left-content">
            <mat-form-field appearance="outline" class="memberInput">
              <mat-label style="display: flex; flex-direction: row; gap: 4px; align-items: center; width: fit-content">
                <mat-icon style="margin-top: -5px">search</mat-icon> <span>Search</span>
              </mat-label>
              <input #nativeInput matInput (input)="onInputChange(nativeInput.value)" [formControl]="searchControl" />
            </mat-form-field>

            <ng-container *ngIf="!loading; else loadingBar">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  gap: 8px;
                  overflow-x: auto;
                  padding-bottom: 4px;
                  min-height: fit-content;
                "
              >
                <greco-box (click)="selectProductId(null)" class="product-box" [class.active]="!selectedProductId">
                  <mat-icon>storefront</mat-icon>
                  <p>All Products</p>
                </greco-box>
                <ng-container *ngFor="let product of products">
                  <!-- *ngIf="product.variants.length > 0" -->
                  <greco-box
                    (click)="selectProductId(product.id)"
                    class="product-box"
                    [class.active]="selectedProductId === product.id"
                  >
                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      "
                    >
                      <p>{{product.title}}</p>
                      <p>
                        <small style="color: gray">
                          {{product.variants.length}} item{{product.variants.length === 1 ? '' : 's'}}
                        </small>
                      </p>
                    </div>
                  </greco-box>
                </ng-container>
              </div>

              <h2 class="heading" style="margin-bottom: -8px; margin-top: -8px">Products</h2>

              <div
                *ngIf="productsRecord"
                style="
                  overflow-y: auto;
                  display: grid;
                  gap: 8px;
                  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
                  grid-auto-rows: auto;
                "
              >
                <ng-container *ngIf="!_search$.value; else searchResultVariants">
                  <ng-container *ngIf="selectedProductId; else allVariants">
                    <ng-container *ngIf="productsRecord[selectedProductId] as product">
                      <greco-express-product-card
                        *ngFor="let variant of product.variants"
                        [variant]="variant"
                        [cart]="cart"
                        [inventoryAddon]="$any(product).inventoryAddon"
                        [variantInventory]="$any(variant).inventory"
                        (productClicked)="addToCart(product, variant, canSellOutOfStock || false)"
                      >
                      </greco-express-product-card>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-template #allVariants>
                  <ng-container *ngFor="let product of products">
                    <greco-express-product-card
                      *ngFor="let variant of product.variants"
                      [variant]="variant"
                      [cart]="cart"
                      [inventoryAddon]="$any(product).inventoryAddon"
                      [variantInventory]="$any(variant).inventory"
                      (productClicked)="addToCart(product, variant, canSellOutOfStock || false)"
                    >
                    </greco-express-product-card>
                  </ng-container>
                </ng-template>

                <ng-template #searchResultVariants>
                  <ng-container *ngIf="searchResults$ | async as searchResults">
                    <ng-container *ngFor="let product of searchResults">
                      <greco-express-product-card
                        *ngFor="let variant of product.variants"
                        [variant]="variant"
                        [cart]="cart"
                        [inventoryAddon]="$any(product).inventoryAddon"
                        [variantInventory]="$any(variant).inventory"
                        (productClicked)="addToCart(product, variant, canSellOutOfStock || false)"
                      >
                      </greco-express-product-card>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </div>
            </ng-container>
            <ng-template #loadingBar>
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-template>
          </div>

          <!-- Right -->
          <div class="right-content">
            <mat-form-field appearance="outline" class="memberInput">
              <mat-label>Select a Member</mat-label>
              <greco-contact-picker
                placeholder="Search by name, email, or member number!"
                [withUser]="true"
                [communityId]="communityId"
                [formControl]="memberControl"
              ></greco-contact-picker>

              <button mat-icon-button matSuffix (click)="memberControl.reset()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <greco-box
              style="
                height: 100%;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                position: relative;
                padding-top: 0;
                padding-bottom: 0;
              "
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  position: sticky;
                  top: 0;
                  background-color: white;
                  z-index: 1;
                  padding-top: 12px;
                "
              >
                <div style="display: flex; flex-direction: row; gap: 4px; align-items: center">
                  <mat-icon class="heading">shopping_cart</mat-icon>
                  <h2 class="heading">Cart</h2>
                </div>
                <button *ngIf="cart.length > 0" mat-icon-button (click)="clearCart()" color="warn">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div
                style="flex: 1; display: flex; flex-direction: column; gap: 12px; margin-top: 8px; margin-bottom: 8px"
              >
                <div
                  *ngFor="let item of cart; let index = index"
                  style="
                    min-height: 48px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                  "
                >
                  <img
                    style="height: 48px; width: 48px; object-fit: cover; border-radius: 8px"
                    [src]="$any(item.variant.image)?.imageURL || 'assets/lf3/icon_square_pad.png'"
                  />
                  <div
                    style="
                      flex: 1;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      min-height: 48px;
                    "
                  >
                    <h3 class="heading">
                      {{ item.variant.title || (item.variant.variantOpts || [] | pluck : 'value')?.join(', ') }}
                    </h3>
                    <p
                      style="padding: 0; margin: 0"
                      *grecoLet="($any(item.variant).inventory?.availableInventoryCount ||0 ) as availableInventory"
                    >
                      {{ item.variant.price / 100 | currency }} x {{ item.quantity }}
                      <span
                        *ngIf="$any(item.product).inventoryAddon?.enabled && item.quantity > availableInventory && availableInventory > 0"
                        style="color: var(--warn-color); font-style: italic"
                        >Only {{ $any(item.variant).inventory?.availableInventoryCount || 0 }} available!</span
                      >
                      <span
                        *ngIf="$any(item.product).inventoryAddon?.enabled && item.quantity > availableInventory && availableInventory <= 0"
                        style="color: var(--warn-color); font-style: italic"
                        >Out of stock!</span
                      >
                    </p>
                  </div>

                  <div style="display: flex; flex-direction: row; height: 100%; align-items: center">
                    <button mat-icon-button color="warn" (click)="removeOneFromCart(index)">
                      <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                    <h3 style="margin: 0; font-weight: bold">{{ item.quantity }}</h3>
                    <button mat-icon-button color="primary" (click)="addOneToCart(index, canSellOutOfStock || false)">
                      <mat-icon>add_circle_outline</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <div
                style="
                  position: sticky;
                  bottom: 0;
                  display: flex;
                  flex-direction: column;
                  gap: 12px;
                  width: 100%;
                  background-color: white;
                  margin: -12px;
                  padding: 12px;
                  border-top: 1px solid lightgray;
                  z-index: 1;
                "
              >
                <greco-select-payment-method
                  #selectPaymentMethodInput
                  [required]="true"
                  [allowTerminals]="true"
                  [formControl]="paymentMethodControl"
                  [defaultPaymentMethods]="station.terminals"
                  [accountId]="(community$ | async)?.financeAccountId"
                  [preferDefaultMethod]="!memberControl.value?.user?.id"
                  [userId]="memberControl.value?.user?.id || station.defaultUser?.id"
                  (valueChange)="updateSelectedPaymentMethod($event)"
                ></greco-select-payment-method>

                <div style="display: flex; justify-content: space-between; gap: 8px; align-items: center">
                  <mat-checkbox color="primary" [formControl]="applyCouponsControl">Apply coupons</mat-checkbox>
                  <strong>Subtotal: {{ ((subtotal$ | async) || 0) / 100 | currency }}</strong>
                </div>
              </div>
            </greco-box>

            <button
              mat-flat-button
              color="primary"
              style="width: 100%; height: 64px"
              [disabled]="
                !cart.length 
                || (!memberControl.value?.user && !defaultUser) 
                || (
                  selectedPaymentMethod?.model !== 'terminal' 
                  && $any(selectedPaymentMethod)?.userId !== memberControl.value?.user?.id
                )
              "
              (click)="checkout()"
            >
              <mat-icon>shopping_cart_checkout</mat-icon>
              <span>Checkout</span>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
