import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Booking } from '@greco/booking-events';
import { BookingService } from '@greco/ngx-booking-events';

@Component({
  selector: 'greco-check-in-booking-alert',
  templateUrl: './check-in-booking-alert.component.html',
  styleUrls: ['./check-in-booking-alert.component.scss'],
})
export class CheckInBookingAlertComponent {
  constructor(private bookingSvc: BookingService, private snacks: MatSnackBar) {}

  @Input() booking!: Booking;

  @Output() refreshBookings = new EventEmitter<string>();
  loading = false;

  async confirm() {
    this.loading = true;
    await this.bookingSvc.checkIn(this.booking.id);
    this.loading = false;

    this.snacks.open('Booking has been checked in!', 'Ok', { duration: 5000, panelClass: 'mat-primary' });
    this.refreshBookings.emit('TriedConfirming');
  }

  async cancel(freeOfCharge: boolean) {
    this.loading = true;
    await this.bookingSvc.cancel(this.booking.id, freeOfCharge);
    this.loading = false;

    this.snacks.open('Booking has been cancelled!', 'Ok', { duration: 5000, panelClass: 'mat-primary' });
    this.refreshBookings.emit('TriedConfirming');
  }

  async noShow() {
    this.loading = true;
    await this.bookingSvc.noShow(this.booking.id);
    this.loading = false;

    this.snacks.open('Booking has been marked as No Show!', 'Ok', { duration: 5000, panelClass: 'mat-primary' });
    this.refreshBookings.emit('TriedConfirming');
  }
}
