<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form">
    <ng-container *ngFor="let option of options">
      <mat-form-field>
        <mat-label> {{ option.label }} </mat-label>
        <input matInput type="text" required formControlName="{{ option.id }}" />
      </mat-form-field>
    </ng-container>

    <mat-form-field appearance="standard">
      <mat-label>Shop Visibility</mat-label>
      <mat-select formControlName="visibility">
        <mat-option value="VISIBLE">Visible</mat-option>
        <mat-option value="HIDDEN">Hidden from shop</mat-option>
        <mat-option value="RESTRICTED">Restricted</mat-option>
      </mat-select>
    </mat-form-field>

    <greco-variant-price
      [communityId]="product.community.id"
      [accountId]="product.community.financeAccountId"
      formControlName="variantPrice"
    ></greco-variant-price>

    <mat-form-field appearance="standard" *ngIf="isValidForProrateStart">
      <mat-label>Prorate billing (optional)</mat-label>
      <input
        matInput
        [min]="minimalDate"
        [max]="maximalDate"
        [matDatepicker]="picker"
        (click)="picker.open()"
        formControlName="prorateStart"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>

  <!-- Buttons -->
  <div style="display: flex; align-items: center; gap: 8px">
    <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
      <span>Cancel</span>
    </button>

    <button
      mat-stroked-button
      type="submit"
      color="primary"
      [disabled]="form.invalid || processing"
      (click)="submit()"
      style="flex: 1"
    >
      <span>Confirm</span>
      <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
    </button>
  </div>
</greco-simple-dialog>
