import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Calendar } from '@greco/booking-events';
import { APP_CONFIG, AppConfig } from '@greco/ngx-app-config';
import { CalendarService } from '@greco/ngx-booking-events';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import { Tab } from '@greco/ngx-routes-util';
import { PerkService } from '@greco/ngx-sales-perks';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export function groupBy<T>(collection: T[], predicate: (item: T) => string): { [key: string]: T[] } {
  const result: { [key: string]: T[] } = {};

  for (const item of collection) {
    const key = predicate(item);
    result[key] = (result[key] || []).concat(item);
  }

  return result;
}

@Component({
  selector: 'greco-user-booking-page',
  templateUrl: './user-booking.page.html',
  styleUrls: ['./user-booking.page.scss'],
})
export class UserBookingPage {
  constructor(
    private route: ActivatedRoute,
    private breakpointObs: BreakpointObserver,
    private userSvc: UserService,
    private perkSvc: PerkService,
    private calendarSvc: CalendarService,
    private contactSvc: ContactService,
    @Inject(APP_CONFIG) public appConfig: AppConfig
  ) {}
  user$ = this.userSvc.user$;
  mobileBreakpoint$ = this.breakpointObs.observe('(max-width: 600px)');
  basePath$ = this.route.url.pipe(map(() => this.getBasePath(this.route.pathFromRoot)));
  tabs$: Observable<(Tab & { queryParams?: Params })[]> = combineLatest([
    this.mobileBreakpoint$,
    this.perkSvc.getUserPerks$(),
    this.user$,
    this.user$.pipe(
      switchMap(user => (user ? this.contactSvc.getUserContacts(user.id) : [])),
      switchMap(async contacts => {
        const calendarsByContact = await Promise.all(
          contacts.map(async contact => (await this.calendarSvc.getManyUser(contact.community.id)) || [])
        );
        const calendars = [] as Calendar[];
        for (const contactCalendars of calendarsByContact) {
          (contactCalendars as any)?.forEach((contactCalendar: any) => {
            if (!calendars.includes(contactCalendar) && !contactCalendar.private) calendars.push(contactCalendar);
          });
        }
        const groupedCals = groupBy(calendars, c => c.group || 'default');
        return {
          ...groupedCals,
          default: groupedCals.default || [], // Ensure default is there
        };
      })
    ),
  ]).pipe(
    map(([_mobile, userPerks, user, groupedCals]) => [
      ...Object.entries(groupedCals)
        .sort(([groupA], [groupB]) => {
          if (groupA.includes('LF3') && !groupB.includes('LF3')) return 1;
          if (groupB.includes('LF3') && !groupA.includes('LF3')) return -1;
          if (groupA === 'default') return -1;
          if (groupB === 'default') return 1;
          return groupA.localeCompare(groupB);
        })
        .map(([group, calendars]) => {
          // console.log('default', { calendars });
          return {
            label: group === 'default' ? 'All Events' : group,
            routerLink: '',
            iconStart: 'date_range',
            queryParams: {
              calendar:
                (group === 'default'
                  ? Object.values(groupedCals).reduce((acc, cur) => {
                      for (const cal of cur) {
                        if (!acc.includes(cal.id)) acc.push(cal.id);
                      }
                      return acc;
                    }, [] as string[])
                  : calendars.map(cal => cal.id)) || [],
            },
          };
        }),
      // { label: 'Courses', routerLink: 'courses', iconStart: 'date_range' },
      { label: 'LF3 GO On Demand', routerLink: 'go', iconStart: 'reset_tv' },
      ...(this.appConfig.name !== 'Altea'
        ? [{ label: 'Programs', routerLink: 'collections', iconStart: 'video_library' }]
        : []),
      ...(this.appConfig.name !== 'Altea' && (user?.isSuperAdmin || userPerks.some(p => p.perk.module === 'kajabi'))
        ? [{ label: 'Legacy Library', routerLink: 'library', iconStart: 'ondemand_video' }]
        : []),
    ])
  );

  getActive(tabs: Tab[]) {
    return tabs.find(tab => tab.routerLink === this.route.snapshot.firstChild?.routeConfig?.path);
  }
  private getBasePath(routes: ActivatedRoute[]) {
    return ['/', ...routes.reduce((acc, path) => [...acc, ...path.snapshot.url.map(url => url.path)], [] as string[])];
  }
}
