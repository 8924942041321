<div *ngIf="activeUnlock">
  <greco-collapsible-section
    id="details-section"
    [header]="{
      title: eventVideo.event?.title || eventVideo.communityVideo?.video?.title || '',
      icon: 'ondemand_video'
    }"
    [disabled]="true"
  >
    <div header *ngIf="_expiresIn$ | async as expiresIn">
      <mat-chip>Expires {{ expiresIn }}</mat-chip>
    </div>

    <greco-video-player-wrapper
      *ngIf="eventVideo.communityVideo && eventVideo.communityVideo.video"
      [video]="eventVideo.communityVideo.video"
    ></greco-video-player-wrapper>

    <div>
      <div
        style="display: flex; align-items: center; padding: 24px 16px 8px"
        *ngIf="eventVideo.communityVideo && eventVideo.communityVideo.video"
      >
        <greco-video-tag *ngFor="let tag of eventVideo.communityVideo.video.tags" [tag]="tag" [icon]="'smart_display'">
        </greco-video-tag>
      </div>

      <ng-container *ngIf="_events$ | async as events">
        <ng-container *ngIf="events.length">
          <div
            *ngFor="let event of events; let last = last"
            style="
              padding: 16px;
              gap: 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
            "
            [style.border-bottom]="last ? '' : '1px solid lightgray'"
          >
            <div style="display: flex; gap: 12px">
              <greco-multi-avatar
                [size]="48"
                [images]="event.resourceAssignments | pluck : 'resource.photoURL'"
              ></greco-multi-avatar>
              <div style="display: flex; flex-direction: column; justify-content: space-evenly">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
                    >place</mat-icon
                  >
                  <strong>{{ event.community.name }}</strong>
                </div>
                <h3 style="margin: 0">{{ event.title }}</h3>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
                >event</mat-icon
              >
              <strong>{{ event.startDate | date }} - {{ event.duration }}mins</strong>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </greco-collapsible-section>
  <br />
  <greco-collapsible-section
    *ngIf="eventVideo.communityVideo?.video?.description"
    id="details-section"
    [header]="{ title: 'Description', icon: 'information' }"
    [expanded]="false"
  >
    <p style="padding-left: 16px">{{ eventVideo.communityVideo?.video?.description }}</p>
  </greco-collapsible-section>
</div>
<greco-collapsible-section
  *ngIf="!activeUnlock"
  [header]="{ icon: 'ondemand_video', title: eventVideo.event?.title || eventVideo.communityVideo?.video?.title || '' }"
  [disabled]="true"
>
  <div header>
    <mat-chip *ngIf="user"
      >Will Expire: {{ selectedPerkRentalPeriod ? (selectedPerkRentalPeriod | date) : 'Never' }}</mat-chip
    >
  </div>
  <div style="display: flex; gap: 16px; flex-wrap: wrap">
    <greco-box
      style="flex: 1; height: 100%; position: relative; display: flex"
      matTooltip="{{ eventVideo.communityVideo?.video?.description }}"
    >
      <img
        *ngIf="eventVideo.communityVideo && eventVideo.communityVideo.video"
        [src]="eventVideo.communityVideo.video.thumbnailUrl || '/assets/lf3/logo.png' | safe : 'resourceUrl'"
        alt="Thumbnail"
        style="width: 120px; aspect-ratio: 16 9; max-height: 100px; object-fit: cover; border-radius: 4px"
      />

      <div style="margin-left: 12px; width: 100%" *ngIf="eventVideo.communityVideo && eventVideo.communityVideo.video">
        <h3 style="margin-bottom: 12px">
          <strong>{{ eventVideo.event?.title || eventVideo.communityVideo.video.title }}</strong>
        </h3>
        <mat-chip-list>
          <greco-video-tag
            *ngFor="let tag of eventVideo.communityVideo.video.tags"
            [admin]="false"
            [tag]="tag"
            [icon]="'smart_display'"
          ></greco-video-tag>
        </mat-chip-list>
      </div>
      <p *ngIf="eventVideo.communityVideo && eventVideo.communityVideo.video">
        {{ eventVideo.communityVideo.video.duration ? (eventVideo.communityVideo.video.duration | grecoDuration) : '' }}
      </p>
    </greco-box>

    <div style="flex: 2">
      <ng-container *ngIf="_events$ | async as events">
        <ng-container *ngIf="events.length">
          <h3 style="margin: 0">Recorded during events:</h3>
          <div
            *ngFor="let event of events; let last = last"
            style="
              padding: 12px 8px;
              gap: 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
            "
            [style.border-bottom]="last ? '' : '1px solid lightgray'"
          >
            <div style="display: flex; gap: 12px">
              <greco-multi-avatar
                [size]="48"
                [images]="event.resourceAssignments | pluck : 'resource.photoURL'"
              ></greco-multi-avatar>
              <div style="display: flex; flex-direction: column; justify-content: space-evenly">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
                    >place</mat-icon
                  >
                  <strong>{{ event.community.name }}</strong>
                </div>
                <h3 style="margin: 0">{{ event.title }}</h3>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
                >event</mat-icon
              >
              <strong>{{ event.startDate | date }} - {{ event.duration }}mins</strong>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</greco-collapsible-section>
