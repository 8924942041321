<greco-collapsible-section
  #section
  [header]="{ title: 'Member Notes', icon: 'description' }"
  [expanded]="expanded"
  (changed)="expanded = $event"
  *grecoLet="canDeleteNotes$ | async as canDeleteNotes"
>
  <div header agreementButton style="display: flex; justify-content: flex-end; margin-right: 10px">
    <button
      color="primary"
      mat-flat-button
      (click)="createNote(tabs.selectedIndex === 1); $event.stopImmediatePropagation()"
    >
      <mat-icon>note_add</mat-icon>
      <span>Add Note</span>
    </button>
  </div>
  <mat-tab-group #tabs>
    <mat-tab label="Member Notes">
      <ng-template matTabContent>
        <ng-container *grecoLet="notes$ | async as notes">
          <greco-table *ngIf="!notes?.items?.length; else notesSection" [data]="[]"></greco-table>
          <ng-template #notesSection>
            <div *ngFor="let note of notes?.items; let last = last" style="overflow-x: hidden">
              <div style="padding: 16px; padding-bottom: 4px">
                <greco-contact-note
                  [note]="note"
                  (changed)="refresh()"
                  [canDeleteNotes]="canDeleteNotes || false"
                ></greco-contact-note>
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </div>
            <mat-divider></mat-divider>
            <mat-paginator
              *ngIf="notePaginationMeta?.totalItems"
              showFirstLastButtons
              [length]="notePaginationMeta!.totalItems"
              [pageSize]="notePaginationMeta!.itemsPerPage"
              [pageSizeOptions]="[10, 20, 50]"
              (page)="notePagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
            ></mat-paginator>
          </ng-template>
        </ng-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Admin Notes">
      <ng-template matTabContent>
        <ng-container *grecoLet="adminNotes$ | async as notes">
          <greco-table *ngIf="!notes?.items?.length; else notesSection" [data]="[]"></greco-table>
          <ng-template #notesSection>
            <div *ngFor="let note of notes?.items; let last = last" style="overflow-x: hidden">
              <div style="padding: 8px">
                <greco-contact-note [note]="note" (changed)="refresh()"></greco-contact-note>
              </div>

              <mat-divider *ngIf="!last"></mat-divider>
            </div>
            <mat-divider></mat-divider>
            <mat-paginator
              *ngIf="adminPaginationMeta?.totalItems"
              showFirstLastButtons
              [length]="adminPaginationMeta!.totalItems"
              [pageSize]="adminPaginationMeta!.itemsPerPage"
              [pageSizeOptions]="[10, 20, 50]"
              (page)="adminPagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
            ></mat-paginator>
          </ng-template>
        </ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</greco-collapsible-section>
