<ng-container *ngIf="accountId">
  <div class="stats-wrapper" *ngIf="canSeeStats$ | async">
    <greco-stats-grid [referenceId]="communityId"></greco-stats-grid>
  </div>

  <greco-filter-bar
    [hideSeparator]="true"
    [filterOptions]="filterOptions"
    [saleCategories]="true"
    (changed)="filterBarData($event[0]);subscriptionsTable.onFilterApplied()"
  >
    <greco-sale-category-picker
      saleCategoryPicker
      #saleCategoryPicker
      [accountId]="accountId"
    ></greco-sale-category-picker>

    <div slot="start" [ngClass]="true ? 'variant' :'' ">
      <ng-container *ngTemplateOutlet="productVariant"></ng-container>
    </div>

    <button
      mat-stroked-button
      color="primary"
      style="height: 32px; line-height: 16px"
      [grecoExportSubscriptions]="{
        accountId: accountId,
        action:filters$.value.actions,
        productVariantIds: productVariantIds,
        filters: filters$.value.subscriptions
      }"
    >
      <mat-icon>file_download</mat-icon>
      <span>Export</span>
    </button>

    <ng-container *grecoLet="canBulkStart$| async as canBulkStart">
      <ng-container *grecoLet="canBulkCancel$| async as canBulkCancel">
        <ng-container *grecoLet="canBulkUpdate$| async as canBulkUpdate">
          <div *ngIf="canBulkStart || canBulkCancel || canBulkUpdate">
            <button mat-stroked-button [matMenuTriggerFor]="categoryMenu" color="primary" style="height: 32px">
              Bulk Actions
              <mat-icon>arrow_drop_down</mat-icon>
            </button>

            <mat-menu #categoryMenu>
              <ng-content></ng-content>

              <button
                *ngIf="canBulkStart"
                mat-menu-item
                color="primary"
                (click)="executeAction('start')"
                matTooltip="Start subscriptions for all selected subscriptions"
              >
                <mat-icon>start</mat-icon>
                <span>Start Subscriptions</span>
              </button>

              <button
                *ngIf="canBulkCancel"
                mat-menu-item
                (click)="executeAction('cancel')"
                matTooltip="Cancel subscriptions for all selected subscriptions"
              >
                <mat-icon>cancel</mat-icon>
                <span>Cancel Subscriptions</span>
              </button>

              <button
                *ngIf="canBulkUpdate"
                mat-menu-item
                (click)="executeAction('update')"
                matTooltip="Update subscriptions for all selected subscriptions"
              >
                <mat-icon>update</mat-icon>
                <span>Update Subscriptions</span>
              </button>

              <button
                *ngIf="isSuperAdmin$ | async"
                mat-menu-item
                (click)="fixPerks()"
                matTooltip="Fix missing subscription perks"
              >
                <mat-icon>construction</mat-icon>
                <span>Fix Perks (Super Admin)</span>
              </button>
            </mat-menu>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </greco-filter-bar>

  <greco-subscription-com-table
    #subscriptionsTable
    [accountId]="accountId"
    [communityId]="communityId"
    [queryBuilder]="filters$.value.subscriptions"
    [actionQueryBuilder]="filters$.value.actions"
    [variantIds]="productVariantIds"
    (rowClick)="navigateToSubscription($event.id)"
    (subscriptionsInformation)="getSubscriptionBulkInfo($event)"
  ></greco-subscription-com-table>
</ng-container>
