<ng-container>
  <greco-table
    [loading]="loading"
    [highlight]="true"
    [data]="(subscriptions$ | async) || []"
    (rowClick)="rowClick.next($event)"
  >
    <!-- Icon -->
    <mat-icon *grecoTableCol="''; fitContent: true; let subscription" [matTooltip]="subscription.id">
      autorenew
    </mat-icon>

    <!-- Subscription -->
    <p
      *grecoTableCol="'Subscription'; let subscription"
      [matTooltip]="(subscription.items | pluck : 'displayName' | grecoJoin : ', ') || 'Unknown Product'"
    >
      {{ (subscription.items | filterCoupon : false | pluck : 'displayName' | grecoJoin : ', ') || 'Unknown Product' }}
    </p>

    <!-- Customer -->
    <div *grecoTableCol="'Customer'; let subscription">
      <p style="margin-bottom: 0">
        <a
          [routerLink]="['/admin/community', communityId, subscription.user.id]"
          [matTooltip]="subscription.user.contactEmail"
          (click)="$event.stopImmediatePropagation()"
        >
          {{ subscription.user.displayName }}
        </a>
      </p>
      <small *ngIf="subscription.user.id !== subscription.subscribedById" style="font-style: italic">
        (Purchased By:
        <a
          [routerLink]="['/admin/community', communityId, subscription.subscribedById]"
          [matTooltip]="subscription.subscribedBy?.contactEmail"
          (click)="$event.stopImmediatePropagation()"
        >
          {{ subscription.subscribedBy?.displayName }}
        </a>
        )
      </small>
    </div>

    <!-- Sold By -->
    <ng-container *grecoTableCol="'Sold By'; let subscription">
      <ng-container *ngIf="subscription.soldBy">
        <a
          [routerLink]="['/admin/community', communityId, subscription.soldById]"
          [matTooltip]="subscription.soldBy.contactEmail"
          (click)="$event.stopImmediatePropagation()"
        >
          <p [matTooltip]="subscription.soldBy.contactEmail" style="line-height: 16px; margin: 0">
            {{ subscription.soldBy.displayName || subscription.soldBy.contactEmail }}
          </p>
        </a>
      </ng-container>
    </ng-container>

    <!-- payment method -->
    <ng-container *grecoTableCol="'Payment Method'; let subscription">
      <div *ngIf="subscription.paymentMethod" style="display: flex; align-items: center">
        <img
          style="width: 36px; min-width: 36px"
          [src]="'assets/payment-methods/' + subscription.paymentMethod.model + '.png'"
        />
        <span style="margin-left: 8px">{{ subscription.paymentMethod.label }}</span>
      </div>
    </ng-container>

    <!-- subTotal -->
    <p *grecoTableCol="'Subtotal'; let subscription; alignRight: true">
      {{ subscription.subtotal ? (subscription.subtotal / 100 | currency) : '-' }}
    </p>

    <!-- taxes -->
    <p *grecoTableCol="'Taxes'; let subscription; alignRight: true">
      {{ subscription.tax ? (subscription.tax / 100 | currency) : '-' }}
    </p>

    <!-- total -->
    <strong *grecoTableCol="'Total'; let subscription; alignRight: true">
      {{ subscription.total ? (subscription.total / 100 | currency) : '-' }}
    </strong>

    <!-- Status -->
    <ng-container *grecoTableCol="'Status'; let subscription">
      <ng-container *ngIf="subscription.status != 'FROZEN'; else frozen">
        <div *grecoLet="subscription | nextRenewal | async as label" style="line-height: 16px">
          <div>
            {{
              (
                (subscription.status | titlecase) == 'Voided'
                  ? (subscription.status | titlecase)
                  : (label | test : '^Starting')
              )
                ? 'Not Started'
                : (subscription.status | titlecase) == 'Cancelled'
                ? 'Cancelled'
                : (subscription.status | titlecase) == 'Completed'
                ? 'Completed'
                : (subscription.status | test : '^Updating')
                ? 'Updating'
                : subscription.lockedForProcessing
                ? 'Processing'
                : !subscription.recurrence.cycles
                ? 'Active'
                : 'Cycle ' + subscription.currentPeriod + ' of ' + (subscription.recurrence.cycles + 1)
            }}
          </div>

          <div *ngIf="label | test : '^(?:Expired|Next|Starting|Voided|Updating)'" style="font-size: 75%">
            ({{ label }})
          </div>
        </div>
      </ng-container>

      <ng-template #frozen>
        <div style="line-height: 16px">Frozen</div>
      </ng-template>
    </ng-container>

    <!-- Minimum Commitment -->
    <p *grecoTableCol="'Commitment End Date'; let subscription">
      {{ (subscription?.minimumCommitmentDate | date) || '-' }}
    </p>

    <!-- Referred By -->
    <p *grecoTableCol="'Referred By'; let subscription">
      {{ subscription?.referredBy?.displayName || subscription?.referredByEmail || '-' }}
    </p>

    <!-- Transferred From -->
    <p *grecoTableCol="'Transferred From'; let subscription">
      {{ subscription.transferredFrom?.displayName || '-' }}
    </p>

    <!-- options -->
    <ng-container *grecoTableCol="''; stickyEnd: true; let subscription">
      <greco-subscription-options
        *ngIf="communityId"
        [communityId]="communityId"
        [subscription]="subscription"
        [purchase]="subscription.purchase"
        [canCancelSubscriptions]="(canCancelSubscriptions$ | async) || false"
        [canUpdateSubscriptions]="(canUpdateSubscriptions$ | async) || false"
        (refresh)="refresh()"
        (subscriptionUpdated)="refresh()"
        (paymentMethodUpdated)="refresh()"
        (subscriptionCancelled)="refresh()"
        (click)="$event.stopImmediatePropagation()"
      ></greco-subscription-options>
    </ng-container>
  </greco-table>
</ng-container>

<mat-paginator
  *ngIf="currentPagination?.totalItems"
  showFirstLastButtons
  [length]="currentPagination!.totalItems || 0"
  [pageSize]="currentPagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="paginatedParams$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
