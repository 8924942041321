import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventVideoUnlockOption } from '@greco/event-videos';
import { VideoTagService } from '@greco/ngx-videos';
import { VideoTag } from '@greco/videos';
import { CurrencyMaskConfig } from 'ngx-currency';
import { EventVideoUnlockService } from '../../services';

@Component({
  selector: 'greco-admin-event-video-unlock-option-page',
  templateUrl: './admin-unlock-option.page.html',
  styleUrls: ['./admin-unlock-option.page.scss'],
})
export class AdminEventVideoUnlockOptionPage implements OnInit {
  constructor(
    private eventVideoUnlockSvc: EventVideoUnlockService,
    private videoTagSvc: VideoTagService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private snacks: MatSnackBar
  ) {}

  private _unlockOption?: EventVideoUnlockOption;
  @Input() set unlockOption(option: EventVideoUnlockOption | undefined) {
    this._unlockOption = option;
    this._resetValue = option
      ? {
          color: option.badge.color,
          title: option.title,
          icon: option.badge.icon,
          tags: option.videoTags,
          description: option.description,
          availableToEveryone: option.availableToAnyone,
          unlimitedWindow: !option.unlockWindow || option.unlockWindow <= 0 ? true : false,
          unlockWindow: option.unlockWindow,
          rentalPeriod: option.rentalPeriod,
          unlockToKeep: !option.rentalPeriod || option.rentalPeriod <= 0 ? true : false,
          price: option.price / 100,
        }
      : {
          color: null,
          title: null,
          icon: null,
          tags: [],
          description: null,
          availableToEveryone: false,
          unlimitedWindow: false,
          unlockWindow: 0,
          rentalPeriod: 0,
          unlockToKeep: false,
          price: 0,
        };
    this.reset();
  }
  get unlockOption() {
    return this._unlockOption;
  }

  _resetValue: any = {
    color: null,
    title: null,
    icon: null,
    tags: [],
    description: null,
    availableToEveryone: false,
    unlimitedWindow: false,
    unlockWindow: 0,
    rentalPeriod: 0,
    unlockToKeep: false,
    price: 0,
  };

  _form = this.formBuilder.group({
    color: [null, Validators.required],
    title: [null, Validators.required],
    icon: [null, Validators.required],
    tags: [[], [Validators.required, Validators.minLength(1)]],
    description: [null],
    availableToEveryone: [false, Validators.required],
    unlimitedWindow: false,
    unlockWindow: [0, [Validators.required, Validators.min(0)]],
    rentalPeriod: [0, [Validators.required, Validators.min(0)]],
    unlockToKeep: false,
    price: [0, [Validators.required, Validators.min(0)]],
  });

  readonly _currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  _tagOptions: VideoTag[] = [];
  private tagSearch = '';

  readonly save = async () => {
    try {
      if (!this._unlockOption) throw new Error();

      const update = await this.eventVideoUnlockSvc.updateEventVideoUnlockOption(this._unlockOption.id, {
        videoTagIds: this._form.value.tags.map((t: any) => t.id),
        availableToAnyone: this._form.value.availableToEveryone,
        unlockWindow: this._form.value.unlockWindow,
        rentalPeriod: this._form.value.rentalPeriod,
        description: this._form.value.description,
        price: this._form.value.price * 100,
        hexColor: this._form.value.color,
        title: this._form.value.title,
        icon: this._form.value.icon,
      });

      this.unlockOption = { ...update };
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong, please try again!', 'Ok', { duration: 5000 });
    }
  };

  reset() {
    this._form.reset(this._resetValue);

    this._form.setValue(this._resetValue);
    this._form.markAsPristine();

    if (this.unlockOption) this._form.enable();
    else this._form.disable();
  }

  async ngOnInit() {
    this._tagOptions = await this.videoTagSvc.getTags();
  }

  compareCategoryObjects(object1: VideoTag, object2: VideoTag) {
    return object1 && object2 && object1.id == object2.id;
  }

  filterTags(tag: VideoTag): boolean {
    if (this.tagSearch) {
      return (
        tag.label.toLowerCase().includes(this.tagSearch.toLowerCase()) ||
        (this._form.get('tags')?.value as VideoTag[]).some(t => t.id === tag.id)
      );
    } else {
      return true;
    }
  }

  onKey() {
    this.tagSearch = (document.getElementById('tagSearch') as HTMLInputElement)?.value || '';
  }

  rentalChange(event: any) {
    const checked = event.checked;
    if (checked) {
      this._form.setValue({ ...this._form.value, rentalPeriod: 0 }, { emitEvent: true });
    }
  }
  windowChange(event: any) {
    const checked = event.checked;
    if (checked) {
      this._form.setValue({ ...this._form.value, unlockWindow: 0 }, { emitEvent: true });
    }
  }
}
