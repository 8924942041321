import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { EventVideo, EventVideoUnlockOption, EventVideoUnlockPreview } from '@greco/event-videos';
import { User } from '@greco/identity-users';
import { VideosService } from '@greco/ngx-videos';
import { PropertyListener } from '@greco/property-listener-util';
import { UserPerk } from '@greco/sales-perks';
import { VideoUnlock } from '@greco/videos';
import moment from 'moment';
import { BehaviorSubject, combineLatest, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { EventVideosService, EventVideoUnlockService } from '../../services';

@Component({
  selector: 'greco-preview-unlock-event-video-page',
  templateUrl: './preview-unlock-video.page.html',
  styleUrls: ['./preview-unlock-video.page.scss'],
})
export class PreviewUnlockVideoPage {
  constructor(
    private eventVideoSvc: EventVideosService,
    private breakpoints: BreakpointObserver,
    private unlockSvc: EventVideoUnlockService,
    private videosSvc: VideosService,
    private snacks: MatSnackBar
  ) {}

  private refresh$ = new BehaviorSubject<null>(null);

  _isMobile$ = this.breakpoints.observe('(max-width: 600px)').pipe(
    map(({ matches }) => matches),
    shareReplay(1)
  );

  _confirming = false;
  _paymentMethodControl = new FormControl(null, Validators.required);
  private paymentMethod$ = this._paymentMethodControl.valueChanges.pipe(startWith(null));

  @Output() unlocked = new EventEmitter<VideoUnlock>();

  @PropertyListener('user') private user$ = new ReplaySubject<User>(1);
  @Input() user?: User;

  @PropertyListener('eventVideo') private eventVideo$ = new ReplaySubject<EventVideo>(1);
  @Input() eventVideo?: EventVideo;

  @Input() footerInPage = false;

  _selectedOption?: EventVideoUnlockOption;
  @PropertyListener('_selectedOption') private selectedOption$ = new BehaviorSubject<EventVideoUnlockOption | null>(
    null
  );

  readonly selectedPerkRentalPeriod$ = this.selectedOption$.pipe(
    switchMap(async option => {
      if (!option || option.rentalPeriod === 0) return null;
      return moment().add(option.rentalPeriod, 'minutes').toDate();
    })
  );

  private userPerks$ = combineLatest([this.eventVideo$, this.user$]).pipe(
    switchMap(async ([eventVideo, user]) => {
      if (!eventVideo?.event || !user) return [];

      const video = eventVideo.communityVideo?.video;
      if (!video) return [];

      const userPerks = await this.unlockSvc.getUserUnlockOptions(
        user?.id,
        video.tags?.map(t => t.id)
      );

      const eventDate = eventVideo.event.startDate.getTime();

      return userPerks.filter(up => {
        const unlockOption = up.perk as EventVideoUnlockOption;
        if (unlockOption.unlockWindow === 0) return true;
        return eventDate >= moment().subtract(unlockOption.unlockWindow, 'minutes').valueOf();
      });
    }),
    shareReplay(1)
  );

  private selectedOptionUserPerks$ = this.selectedOption$.pipe(
    switchMap(selected =>
      selected ? this.userPerks$.pipe(map(up => up.filter((p: UserPerk) => p.perk.id === selected.id))) : of([])
    )
  );

  readonly _activeUnlock$ = combineLatest([this.eventVideo$, this.user$, this.refresh$]).pipe(
    switchMap(async ([eventVideo, user]) =>
      eventVideo?.communityVideo && user
        ? await this.videosSvc.hasActiveVideoUnlock(eventVideo.communityVideo.videoId)
        : null
    ),
    map(unlock => unlock || null),
    shareReplay(1)
  );

  readonly _preview$ = combineLatest([
    this.user$,
    this.eventVideo$,
    this.selectedOption$,
    this.paymentMethod$,
    this._activeUnlock$,
  ]).pipe(
    switchMap(async ([user, eventVideo, unlockOption, paymentMethod, unlock]) =>
      user && eventVideo && unlockOption && !unlock
        ? await this.unlockSvc.preview_unlockEventVideo({
            paymentMethodId: paymentMethod?.id,
            unlockOptionId: unlockOption.id,
            eventVideoId: eventVideo.id,
            unlockDate: new Date(),
            userId: user.id,
          })
        : null
    ),

    tap(async preview => {
      const userPerks = await toPromise(this.selectedOptionUserPerks$);
      if (
        preview &&
        !preview.purchase &&
        !preview.errors.length &&
        userPerks.some((up: UserPerk) => !up.consumable && !(up.perk as EventVideoUnlockOption).price)
      ) {
        await this.unlockVideo(preview);
      }
    }),

    shareReplay(1)
  );

  readonly _showPaymentMethodPicker$ = this._preview$.pipe(
    map(preview => {
      if (!preview?.purchase) return false;
      return preview.purchase.total - preview.purchase.balanceUsed > 0;
    })
  );

  readonly _applicableUnlockOptions$ = this.userPerks$.pipe(
    map((userPerks: UserPerk[]) =>
      userPerks.map((up: UserPerk) => up.perk).filter((p, i, a) => i === a.findIndex(v => v.id === p.id))
    ),
    tap(options => setTimeout(() => (this._selectedOption = options[0] as any))),
    shareReplay(1)
  );

  readonly _comparePerks = (a?: EventVideoUnlockOption, b?: EventVideoUnlockOption) => a?.id === b?.id;

  async unlockVideo(preview: EventVideoUnlockPreview) {
    this._confirming = true;

    try {
      const videoId = this.eventVideo?.communityVideo?.videoId;
      if (!videoId) throw new Error();

      const unlock = await this.unlockSvc.unlockEventVideo(preview.dto, preview.hash);
      this.unlocked.emit(unlock);

      this.snacks.open('Video unlocked!', 'Ok', { duration: 3500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
      this.snacks.open('Oops, something went wrong. Please try again later!', 'Ok', {
        duration: 5000,
        panelClass: 'mat-warn',
      });
    }

    this._confirming = false;
  }

  refresh() {
    this.refresh$.next(null);
  }
}
