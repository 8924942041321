import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { Booking } from '@greco/booking-events';
import { User } from '@greco/identity-users';
import { Subscription } from '@greco/sales-subscriptions';

@Component({
  selector: 'greco-active-subscription',
  templateUrl: './active-subscription.dialog.html',
  styleUrls: ['./active-subscription.dialog.scss'],
})
export class ActiveSubscriptionDialog {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User; communityId: string; subscription: Subscription }
  ) {
    this.dialogData = {
      title: 'Subscription',
      buttons: [{ label: 'Dismiss', role: 'cancel' }],
    };
  }

  public dialogData: DialogData;

  closeDialog(status: Booking | undefined) {
    if (status?.event) {
      this.dialogRef.close(status);
    }
  }
}
