import { SecurityResource } from '@greco/security';

export const EVENT_BOOKING_SECURITY_RESOURCE = 'booking_event_booking';

export enum EventBookingSecurityResourceAction {
  LIST = 'LIST',
  CREATE = 'CREATE',
  CANCEL = 'CANCEL',
  CANCEL_FREE_OF_CHARGE = 'CANCEL_FREE_OF_CHARGE',
  NO_SHOW = 'NO_SHOW',
  UNDO_NO_SHOW_2 = 'UNDO_NO_SHOW_2',
  UNDO_NO_SHOW_24 = 'UNDO_NO_SHOW_24',
  CHECK_IN = 'CHECK_IN',
  COMPLIMENTARY = 'COMPLIMENTARY',
  EXPORT = 'EXPORT',
  CREATE_PENDING = 'CREATE_PENDING',
  MANAGE_SPOTS = 'MANAGE_SPOTS',
  CREATE_PENDING_FROM_SUBSCRIPTION = 'CREATE_PENDING_FROM_SUBSCRIPTION',
  IGNORE_PAYMENT_FREEZE = 'IGNORE_PAYMENT_FREEZE',
  OVERRIDE_USAGE_LIMIT = 'OVERRIDE_USAGE_LIMIT',
  OVERRIDE_ADDITIONAL_SPOTS_LIMIT = 'OVERRIDE_ADDITIONAL_SPOTS_LIMIT',
  OVERRIDE_ADDITIONAL_SPOTS_COST = 'OVERRIDE_ADDITIONAL_SPOTS_COST',
}

export const EventBookingSecurityResource: SecurityResource = {
  key: EVENT_BOOKING_SECURITY_RESOURCE,
  module: 'booking',

  title: 'Event Booking',
  description: 'User Bookings',

  context: class {
    userId?: string;
    bookingId?: string;
    communityId?: string;
  },

  actions: [
    { key: EventBookingSecurityResourceAction.LIST, title: 'Read', description: 'Ability to view/list bookings' },
    { key: EventBookingSecurityResourceAction.CREATE, title: 'Create', description: 'Ability to create bookings' },
    { key: EventBookingSecurityResourceAction.CANCEL, title: 'Cancel', description: 'Ability to cancel bookings' },
    {
      key: EventBookingSecurityResourceAction.CREATE_PENDING,
      title: 'Create Pending',
      description: 'Ability to create pending bookings',
    },
    {
      key: EventBookingSecurityResourceAction.CREATE_PENDING_FROM_SUBSCRIPTION,
      title: 'Create Pending From Subscription',
      description:
        'Ability to create pending bookings if the user has a subscription that grants them the required perk',
    },
    {
      key: EventBookingSecurityResourceAction.CANCEL_FREE_OF_CHARGE,
      title: 'Cancel Free of Charge',
      description: 'Ability to cancel users free of charge',
    },
    {
      key: EventBookingSecurityResourceAction.NO_SHOW,
      title: 'Mark as No-Show',
      description: 'Ability to mark users as No-Show',
    },
    {
      key: EventBookingSecurityResourceAction.UNDO_NO_SHOW_2,
      title: 'Undo No-Show 2 hours',
      description: 'Ability to undo No-Show within 2 hours',
    },
    {
      key: EventBookingSecurityResourceAction.UNDO_NO_SHOW_24,
      title: 'Undo No-Show 24 hours',
      description: 'Ability to undo No-Show within 24 hours',
    },
    {
      key: EventBookingSecurityResourceAction.CHECK_IN,
      title: 'Check-In User',
      description: 'Ability to check-in Users',
    },
    {
      key: EventBookingSecurityResourceAction.COMPLIMENTARY,
      title: 'Complimentary',
      description: 'Ability to book members complimentary',
    },
    { key: EventBookingSecurityResourceAction.EXPORT, title: 'Export', description: 'Ability to export bookings' },
    {
      key: EventBookingSecurityResourceAction.IGNORE_PAYMENT_FREEZE,
      title: 'Ignore payment freeze',
      description: 'Ability to create a booking and ignore the payment freeze',
    },

    {
      key: EventBookingSecurityResourceAction.MANAGE_SPOTS,
      title: 'Manage Spots',
      description: 'Ability to change a members booked spot',
    },
    {
      key: EventBookingSecurityResourceAction.OVERRIDE_USAGE_LIMIT,
      title: 'Override Usage Limit',
      description: "Ability to override a booking option's usage limit",
    },
    {
      key: EventBookingSecurityResourceAction.OVERRIDE_ADDITIONAL_SPOTS_LIMIT,
      title: 'Override Additional Spots Limit',
      description: "Ability to override a booking option's additional spots limit",
    },
    {
      key: EventBookingSecurityResourceAction.OVERRIDE_ADDITIONAL_SPOTS_COST,
      title: 'Override Additional Spots Cost',
      description: "Ability to override a booking option's additional spots cost",
    },
  ],
};
