<div id="programs_section" *ngIf="canRead$ | async">
  <greco-filter-bar
    [hideSeparator]="true"
    [filterOptions]="filterOptions"
    (changed)="filters$.next($event[1]); onFilterApplied()"
  >
    <ng-content></ng-content>
  </greco-filter-bar>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <greco-table [highlight]="true" [data]="(data$ | async) || []" (rowClick)="manageProgram($event)">
    <mat-icon *grecoTableCol="''; fitContent: true; let program" [matTooltip]="program.id"> video_library </mat-icon>

    <!-- title -->
    <ng-container>
      <p *grecoTableCol="'Title'; let program" [matTooltip]="program.title">
        {{ program.title }}
      </p>
    </ng-container>
    <!-- created Date -->
    <ng-container>
      <p *grecoTableCol="'Created'; let program" [matTooltip]="'Created Date'">
        {{ program.created | date }}
      </p>
    </ng-container>

    <!-- Subcollections -->
    <ng-container>
      <p *grecoTableCol="'Subcollections'; let program">
        <mat-chip
          style="margin: 1px"
          *ngFor="let subcollection of program.subcollections"
          [matTooltip]="subcollection.id + ', ' + subcollection.videos.length + ' videos'"
          >{{ subcollection.title }}</mat-chip
        >
      </p>
    </ng-container>

    <!-- available Date -->
    <ng-container>
      <p *grecoTableCol="'Available On'; let program" [matTooltip]="'Date collection is visible on the platform'">
        {{
          program.availableOn
            ? (program.availableOn | date) + (program.availableUntil ? ' - ' + (program.availableUntil | date) : '')
            : 'Never'
        }}
      </p>
    </ng-container>
    <!-- archive Date
    <ng-container>
      <p *grecoTableCol="'Available Until'; let program" [matTooltip]="'Date collection is hidden on the platform'">
        {{ program.availableUntil | date }}
      </p>
    </ng-container> -->

    <!-- Status -->
    <ng-container>
      <p *grecoTableCol="'Status'; fitContent: true; let program" [matTooltip]="'Status'">
        <ng-container
          *grecoLet="
            program.availableOn &&
            (program.availableOn | grecoDateCompare : 'lt') &&
            (!program.availableUntil || (program.availableUntil | grecoDateCompare : 'gt')) as active
          "
        >
          <ng-container *grecoLet="!program.availableOn && !program.availableUntil as draft">
            <mat-chip [ngClass]="active ? '' : draft ? 'draft' : 'inactive'">{{
              active ? 'Active' : draft ? 'Draft' : 'Archived'
            }}</mat-chip></ng-container
          ></ng-container
        >
      </p>
    </ng-container>
    <!-- Options -->
    <ng-container>
      <ng-container *grecoTableCol="''; let program; fitContent: true; stickyEnd: true">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [matTooltip]="'Options for ' + program.title"
          (click)="$event.stopImmediatePropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu>
          <!-- Manage Program -->
          <button mat-menu-item (click)="manageProgram(program)">
            <mat-icon>settings</mat-icon>
            <span>Manage</span>
          </button>
        </mat-menu>
      </ng-container>
    </ng-container>
  </greco-table>
  <mat-paginator
    *ngIf="pagination?.totalItems"
    showFirstLastButtons
    [length]="pagination!.totalItems || 0"
    [pageSize]="pagination!.itemsPerPage || 10"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  ></mat-paginator>
</div>
