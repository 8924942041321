<div>
  <div style="margin-bottom: 16px">
    <h2 style="margin-bottom: 8px">Remove Perk - {{data.perk.title}}</h2>
    <p style="margin: 0">Enter the number of perks you want removed out of {{userPerks.length}}</p>
    <form [formGroup]="form">
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Number of Perks to Remove</mat-label>
        <input
          matInput
          required
          type="number"
          min="1"
          [max]="userPerks.length"
          step="1"
          formControlName="perksToRemove"
        />
      </mat-form-field>
    </form>
  </div>

  <div style="margin-top: 16px; display: grid; grid-template-columns: 1fr 1fr; gap: 8px">
    <button type="button" mat-stroked-button style="height: 32px; line-height: 16px" (click)="close()">Cancel</button>
    <button
      type="button"
      color="primary"
      mat-stroked-button
      style="height: 32px; line-height: 16px"
      [disabled]="submitting||form.invalid"
      (click)="submit()"
    >
      <span *ngIf="!submitting">Remove Perks</span>
      <mat-icon *ngIf="submitting" class="spin">loop</mat-icon>
    </button>
  </div>
</div>
