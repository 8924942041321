<a
  *ngIf="(hasLivePerk$ | async) || (hasLF3Perk$ | async) || (hasOnDemandPerk$ | async); else noPerks"
  class="large-button"
  mat-stroked-button
  href="https://lf3.mykajabi.com/library"
  target="_blank"
  color="primary"
>
  Workout Library
  <mat-icon>launch</mat-icon>
</a>
<greco-box *ngIf="(hasLivePerk$ | async) || (hasLF3Perk$ | async)">
  <h1>Legacy LIVE Replays on demand - Never miss a workout!</h1>
  <p>
    You can access all LIVE Replays (recordings of the LIVE Interactive Workouts available to you 24/7 on Demand)
    through this section. Workout on your own time with the flexibility and energy of the LIVE workouts whenever your
    schedule allows!
  </p>
  <h2>Make sure you have the following ready to get the most out of your workouts:</h2>
  <ul>
    <li>Towel, Water & Some Space (6x9 feet or double your wingspan)</li>
    <li>Light Weight 2-10lbs</li>
    <li>Medium Weight 10-20lbs</li>
    <li>Heavy Weight 20+lbs</li>
    <li>Workout Playlist (visit the Workout Beats Section)</li>
  </ul>
</greco-box>
<greco-box *ngIf="(hasOnDemandPerk$ | async)">
  <h1>Legacy On Demand - Prerecorded workouts for all fitness levels</h1>
  <p>
    We’ve created a library of follow-along Legacy pre-recorded workouts, programmed by the brand's best trainers, to
    make sure that every day you are getting closer to where you want to be. The On-Demand workouts have different
    modifications which are suitable for all ability levels. In each workout you will see Trainers using household
    items, body weight and advanced movements for those with equipment at home.
  </p>
  <h2>Make sure you have the following ready to get the most out of your workouts:</h2>
  <ul>
    <li>Towel, Water & Some Space (6x9 feet or double your wingspan)</li>
    <li>Light Weight 2-10lbs</li>
    <li>Medium Weight 10-20lbs</li>
    <li>Heavy Weight 20+lbs</li>
    <li>Workout Playlist (visit the Workout Beats Section)</li>
  </ul>
</greco-box>
<ng-template #noPerks>
  <h1 style="text-align: center">Looking to access workouts anytime and anywhere?</h1>
  <a
    class="large-button"
    mat-stroked-button
    routerLink="/shop/products"
    [queryParams]="{ collection: 'col_featured' }"
    color="primary"
  >
    Unlock Access Here
    <mat-icon>launch</mat-icon>
  </a>
</ng-template>
