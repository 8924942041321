import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { InventoryProductAddon, ProductVariantInventory } from '@greco/sales-products';
import { PurchaseItemResource, SaleCategory } from '@greco/sales-purchases';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PurchaseService } from '../../services';

@Component({
  selector: 'greco-purchase-preview-table',
  templateUrl: './purchase-preview-table.component.html',
  styleUrls: ['./purchase-preview-table.component.scss'],
})
export class PurchasePreviewTableComponent {
  constructor(
    private securitySvc: SecurityService,
    private purchaseSvc: PurchaseService,
    private snackBar: MatSnackBar
  ) {}
  @Input() isPurchaseVoided = false;
  @Input() items!: any;
  @Input() edit = false;

  @Input() disabledEdit = false;

  @Output() refresh = new EventEmitter();

  @Input() categoryData: SaleCategory[] = [];

  @Input() showUsername = false;

  @PropertyListener('accountId') accountId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() accountId?: string;

  @Input() inventories: Map<
    string,
    {
      inventory?: ProductVariantInventory;
      addon?: InventoryProductAddon;
      variantId: string;
      itemQuantity: number;
    }
  > | null = null;

  canUpdate$ = this.accountId$.pipe(
    switchMap(async accountId => {
      return accountId ? await this.securitySvc.hasAccess(PurchaseItemResource.key, 'UPDATE', { accountId }) : false;
    })
  );

  columnStyle(item: any): NgStyle['ngStyle'] {
    const style: NgStyle['ngStyle'] = {};

    if (!('id' in item)) {
      style.borderBottom = 'none';
    }

    if (item.amountClass === 'subtotal') {
      style.paddingTop = '16px';
    }

    return style;
  }

  rowStyle(item: any): NgStyle['ngStyle'] {
    return 'id' in item ? {} : { height: 'auto' };
  }

  async updateSaleCategory(item: any, saleCategory: SaleCategory) {
    if (item.saleCategory?.id === saleCategory.id) return;

    try {
      await this.purchaseSvc.updatePurchaseItemSaleCategory(item.id, saleCategory.id);
      this.snackBar.open('Sale Category Updated', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      this.refresh.emit();
    } catch (err) {
      this.snackBar.open('Failed to update: ' + err, 'Ok!', { duration: 2500, panelClass: 'mat-warn' });
    }
  }
}
