import { Component } from '@angular/core';
import { UserService } from '@greco/ngx-identity-auth';
import { PerkService } from '@greco/ngx-sales-perks';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'greco-library-temp',
  templateUrl: './library-temp.page.html',
  styleUrls: ['./library-temp.page.scss'],
})
export class LibraryTempPage {
  constructor(private perkSvc: PerkService, private userService: UserService) {}

  hasLivePerk$: Observable<boolean> = combineLatest([
    this.perkSvc.getUserPerks$(),
    this.userService.user$.pipe(map(u => u?.isSuperAdmin)),
  ]).pipe(map(([perks, superAdmin]) => superAdmin || perks.some(p => p.perk.id === 'prk_livereplay')));

  hasLF3Perk$: Observable<boolean> = combineLatest([
    this.perkSvc.getUserPerks$(),
    this.userService.user$.pipe(map(u => u?.isSuperAdmin)),
  ]).pipe(map(([perks, superAdmin]) => superAdmin || perks.some(p => p.perk.id === 'prk_lf3ondemand')));

  hasOnDemandPerk$: Observable<boolean> = combineLatest([
    this.perkSvc.getUserPerks$(),
    this.userService.user$.pipe(map(u => u?.isSuperAdmin)),
  ]).pipe(map(([perks, superAdmin]) => superAdmin || perks.some(p => p.perk.id === 'prk_ondemand')));
}
