import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'greco-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss'],
})
export class NavLinkComponent {
  constructor(public router: Router) {}
  @Input() color: 'primary' | 'accent' | 'warn' | null = null;
  @Input() link?: string;
  @Input() icon?: string;
  @Input() activeIcon?: string;
  @Input() tooltip?: string;
  @Input() exact = false;
  @Input() label?: string;
  @Input() full = true;
  @Input() tooltipDelay = 0;
  @Input() disabled?: boolean = false;
  @Input() disabledAction?: () => void;
}
