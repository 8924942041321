<form [formGroup]="form" style="display: flex; flex-direction: row; gap: 8px; width: 100%">
  <mat-form-field class="filter-input" appearance="outline">
    <button mat-icon-button matPrefix (click)="search()">
      <mat-icon>search</mat-icon>
    </button>
    <mat-label>Search</mat-label>
    <input
      #searchInput
      id="searchInput"
      matInput
      (keyup.enter)="search()"
      (keyup.backspace)="checkForClear()"
      placeholder="Search for a collection"
    />
  </mat-form-field>
  <!--Uncomment below when multiple communities have collections-->
  <!-- <mat-form-field class="filter-input" appearance="outline">
    <mat-label>Communities</mat-label>
    <greco-user-community-picker
      [user]="user"
      [required]="true"
      formControlName="communities"
      [autoSelectUserCommunities]="true"
    ></greco-user-community-picker>
  </mat-form-field> -->
</form>
