<ng-container *grecoLet="_contact$ | async">
  <mat-form-field appearance="outline" style="width: 100%" *ngIf="community$ | async as community">
    <mat-label>Check-in Member</mat-label>

    <greco-contact-picker
      [communityId]="community.id"
      [formControl]="userControl"
      [withUser]="true"
      [required]="true"
      [clearOnPaste]="autoCheckIn"
      (paste)="pasteMemberNumber($event, community.id)"
      (contact)="autoCheckIn === true ? checkInUser($event?.user?.id, station?.id) : false"
      placeholder="Search by name, email, or member number!"
    ></greco-contact-picker>

    <button matSuffix mat-stroked-button (click)="createContact()" *ngIf="canSearchAndInviteUser$ | async">
      Invite Member
    </button>
  </mat-form-field>

  <div *ngIf="community$ | async as community" class="container">
    <div *grecoLet="contact$ | async as contact" class="content">
      <div *ngIf="currentUser$ | async as user" class="user-detail-card">
        <!--
          Removed sale category filters based on this: https://app.clickup.com/t/86dqpru6h
          [saleCategoryIds]="((saleCategoryIds$ | async) || []).join(',')"
        -->
        <greco-user-detail-card
          [contact]="contact"
          [currentUser]="user"
          [community]="community"
          [autoCheckIn]="!autoCheckIn"
          (refreshBookingTable)="refreshBookingTable($event)"
          (refreshPurchaseHistory)="refreshPurchaseHistory($event)"
          (checkInUser)="autoCheckIn === false ? checkInUser($event.id, station?.id) : false"
        ></greco-user-detail-card>
      </div>

      <greco-contact-notes-section *ngIf="contact" [contact]="contact"></greco-contact-notes-section>

      <div *grecoLet="canReadBookings$ | async as canReadBookings" class="booking">
        <greco-collapsible-section
          #details
          [header]="{ title: 'Bookings', icon: 'bookings' }"
          [expanded]="expanded"
          style="max-width: 70%"
        >
          <div header agreementButton class="booking-button-container" *ngIf="canCreateBookings$ | async">
            <button
              color="primary"
              *ngIf="contact?.user as user"
              (click)="openNewBookingDialog(user, contact); $event.stopImmediatePropagation()"
              mat-flat-button
            >
              <mat-icon>add</mat-icon>
              <span>Booking</span>
            </button>
          </div>

          <greco-tabs
            [tabs]="[
              { label: 'Today', template: today },
              { label: 'All Bookings', template: allBookings }
            ]"
            (tabChanged)="selectedTabIndex = $event.index"
          ></greco-tabs>

          <ng-template #today>
            <greco-bookings-table
              [hideDate]="true"
              [hideUser]="true"
              [onlyToday]="true"
              [hideResource]="false"
              [sortByEventDate]="true"
              [communityId]="canReadBookings ? community.id : ''"
              [filters]="(bookingFilters$ | async) || staticFilter"
              [onlyStaffCalendars]="true"
              [allowSpotReassignment]="false"
            >
            </greco-bookings-table>
          </ng-template>

          <ng-template #allBookings>
            <greco-bookings-table
              [hideUser]="true"
              [hideResource]="false"
              [sortByEventDate]="true"
              [communityId]="canReadBookings ? community.id : ''"
              [filters]="(bookingFilters$ | async) || staticFilter"
              [allowSpotReassignment]="false"
              [onlyStaffCalendars]="true"
            >
            </greco-bookings-table>
          </ng-template>
        </greco-collapsible-section>
      </div>

      <div class="payment">
        <!-- subscriptions -->
        <ng-template #subscriptions matTabContent>
          <ng-container *ngIf="canReadSubscriptions$ | async">
            <greco-subscriptions-table
              *ngIf="contact?.user as user"
              (rowClick)="openSubscriptionSummary($event, community.id, user)"
              [pageSizes]="[5]"
              [user]="contact?.user"
            ></greco-subscriptions-table>
          </ng-container>
        </ng-template>

        <!-- purchase -->
        <ng-template #purchases matTabContent>
          <ng-container *ngIf="canReadPurchases$ | async">
            <!--
              Removed sale category filters based on this: https://app.clickup.com/t/86dqpru6h
              [saleCategoryIds]="((saleCategoryIds$ | async) || []).join(',')"
            -->
            <greco-purchases-table
              *ngIf="contact?.user as user"
              [userId]="user?.id"
              [accountId]="community.financeAccountId"
              [communityId]="community.id"
              [saleCategoryIds]="(saleCategoryIds$ | async) || ''"
              [columns]="[
                'icon',
                'title',
                'created',
                'status',
                'subtotal',
                'taxes',
                'total',
                'balance_used',
                'user_options'
              ]"
              [pageSizes]="[5]"
              [isAdminPage]="true"
              #purchasesTable
              (rowClick)="openPurchaseSummary($event, community.id, contact?.user)"
            ></greco-purchases-table>
          </ng-container>
        </ng-template>

        <!-- payment -->
        <ng-template #payment matTabContent>
          <ng-container *ngIf="canViewPaymentMethods$ | async">
            <greco-manager-user-payment-methods
              *ngIf="contact?.user as user"
              [userId]="user?.id"
              (defaultPaymentMethodUpdated)="defaultPaymentMethodUpdated()"
            ></greco-manager-user-payment-methods>
          </ng-container>
        </ng-template>

        <!-- perks -->
        <ng-template #perks matTabContent>
          <ng-container *grecoLet="canRevokePerks$ | async as canRevokePerks">
            <ng-container *ngIf="canReadPerks$ | async">
              <greco-user-perks-table
                *ngIf="contact?.user as user"
                [userId]="user?.id"
                [communityId]="community?.id"
                [canRevokePerks]="!!canRevokePerks"
              ></greco-user-perks-table>
            </ng-container>
          </ng-container>
        </ng-template>

        <!-- balance -->
        <ng-template #balance matTabContent>
          <ng-container *ngIf="currentUser$ | async as user">
            <ng-container *ngIf="canReadBalance$ | async">
              <greco-balance-history-section [userId]="contact?.user?.id"></greco-balance-history-section>
            </ng-container>
          </ng-container>
        </ng-template>

        <!-- Payment and Billings Section -->
        <ng-container *ngIf="tabs$ | async as tabs">
          <greco-collapsible-section
            *ngIf="tabs.length"
            [header]="{ title: 'Payments & Billings', icon: 'receipt_long' }"
            [expanded]="expanded"
          >
            <div [ngSwitch]="selectedTab" header class="payment-tabs">
              <ng-container *ngSwitchCase="'Payment Methods'">
                <ng-container *ngIf="(canAddPaymentMethods$ | async) && contact?.user">
                  <greco-add-payment-method-button
                    header
                    style="margin-right: 12px"
                    [userId]="contact?.user?.id"
                    (saved)="userPaymentMethods.refresh()"
                    (click)="$event.stopImmediatePropagation()"
                  ></greco-add-payment-method-button>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'Access Perks'">
                <ng-container *ngIf="(canGrantPerks$ | async) && contact?.user">
                  <button
                    *ngIf="canGrantPerks$ | async"
                    mat-flat-button
                    color="primary"
                    (click)="grantPerk(contact?.user)"
                  >
                    <mat-icon>add</mat-icon>
                    <span>Grant Perks</span>
                  </button>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'Account Balance'">
                <ng-container *grecoLet="canFundBalance$ | async as canFundBalance">
                  <ng-container *grecoLet="canAdjustBalance$ | async as canAdjustBalance">
                    <ng-container *ngIf="(canAdjustBalance || canFundBalance) && contact?.user">
                      <button
                        mat-stroked-button
                        type="button"
                        color="primary"
                        class="quick-actions-button"
                        [matMenuTriggerFor]="accountBalanceMenu"
                        (click)="$event.stopImmediatePropagation()"
                      >
                        <span>Quick Actions</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                      </button>

                      <mat-menu #accountBalanceMenu="matMenu" class="account-balance-menu">
                        <button *ngIf="canAdjustBalance" mat-menu-item (click)="adjustBalance(contact?.user)">
                          <mat-icon>account_balance_wallet</mat-icon>
                          <span>Adjust Balance</span>
                        </button>

                        <button *ngIf="canFundBalance" mat-menu-item (click)="fundAccount(contact?.user)">
                          <mat-icon>credit_card</mat-icon>
                          <span>Fund Account</span>
                        </button>
                      </mat-menu>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>

            <greco-tabs [tabs]="tabs" (tabChanged)="selectedTab = tabs[$event.index].label || ''"></greco-tabs>
          </greco-collapsible-section>
        </ng-container>
      </div>

      <div class="linked-accounts">
        <greco-collapsible-section
          [header]="{ title: 'Linked Accounts', icon: 'supervisor_account' }"
          [expanded]="expanded"
          *ngIf="!contact; else linkedAccounts"
        ></greco-collapsible-section>
        <ng-template #linkedAccounts>
          <greco-linked-accounts-section
            *ngIf="contact && contact.user"
            [user]="contact.user"
            [communityId]="contact.community.id"
            [expanded]="false"
          ></greco-linked-accounts-section>
        </ng-template>
      </div>

      <!-- Check-ins -->
      <greco-collapsible-section
        *ngIf="contact && contact.user"
        id="bookings-section"
        [header]="{ title: 'Check-Ins', icon: 'location_on' }"
        [expanded]="false"
      >
        <greco-check-in-table [userId]="contact.user.id" [community]="contact.community"></greco-check-in-table>
      </greco-collapsible-section>

      <div class="agreements" *ngIf="canReadAgreement$ | async">
        <!--Agreements-->
        <greco-collapsible-section
          #details
          class="agreements"
          [header]="{ title: 'Agreements', icon: 'assignment' }"
          [expanded]="expanded"
        >
          <div header agreementButton class="agreement-button-container" *ngIf="canUpdateAgreement$ | async">
            <button
              *ngIf="contact"
              color="primary"
              mat-flat-button
              (click)="openSignAgreement(contact); $event.stopImmediatePropagation()"
            >
              <mat-icon>edit</mat-icon>
              <span>Sign</span>
            </button>
          </div>

          <greco-user-agreements-table
            #table
            *ngIf="contact?.user as user"
            [userId]="user.id"
            [pageSizes]="pageSizes"
            (rowClick)="table.previewPDF($event)"
          ></greco-user-agreements-table>
        </greco-collapsible-section>
      </div>
    </div>

    <div class="sidebar" *ngIf="canReadCheckInList$ | async">
      <div style="display: flex; justify-content: space-between">
        <div class="heading">Latest Check-Ins</div>

        <mat-checkbox style="margin-top: -2px" color="primary" [checked]="autoCheckIn" (change)="toggleAutoCheckIn()">
          <label>Auto Check-In</label>
        </mat-checkbox>
      </div>

      <greco-station-checkin-list *ngIf="station" [stationId]="station.id" [communityId]="community.id">
      </greco-station-checkin-list>
    </div>
  </div>
</ng-container>
