<greco-simple-dialog [data]="dialogData">
  <p>The payment for this purchase failed. Optionally select a new payment method to retry the payment.</p>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-form-field appearance="standard">
      <!-- <mat-label>Payment Method</mat-label> -->

      <greco-select-payment-method
        #selectPaymentMethodInput
        formControlName="paymentMethod"
        [required]="true"
        [allowTerminals]="true"
        [userId]="purchase.user.id"
      ></greco-select-payment-method>

      <greco-add-payment-method-button
        color=""
        matSuffix
        [onlyIcon]="true"
        [userId]="purchase.user.id"
        (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
      ></greco-add-payment-method-button>
    </mat-form-field>

    <div style="display: flex; justify-content: space-between; margin-bottom: 12px">
      <label>Apply additional balance?</label>
      <mat-checkbox color="primary" formControlName="useBalance"></mat-checkbox>
    </div>

    <ng-container *grecoLet="adminFee$ | async as adminFee">
      <ng-container *ngIf="adminFee && adminFee.enabled && adminFee.amount > 0">
        <p style="margin-top: 16px; color: var(--warn-color)">
          Retrying this purchase will apply an admin fee of {{(adminFee.amount / 100) | currency}}
        </p>
        <ng-container *ngIf="canWaiveAdminFee$ | async">
          <div style="display: flex; justify-content: space-between; margin-bottom: 12px">
            <label>Waive the admin fee?</label>
            <mat-checkbox color="primary" formControlName="waiveAdminFee"></mat-checkbox>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button
        mat-stroked-button
        type="submit"
        color="primary"
        [disabled]="formGroup.invalid || processing"
        style="flex: 1"
      >
        <span>Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
