<div class="event-filters">
  <greco-admin-events-filters
    [user]="user"
    [userCommunities]="(userCommunities$ | async) || ['com_greco']"
    (changed)="filters$.next($event)"
  ></greco-admin-events-filters>
  <h3 style="margin-bottom: 12px; margin-top: 8px">Calendars</h3>
  <ng-container *grecoLet="(userCalendars$ | async) || [] as calendars">
    <div class="grid-container" *ngIf="(calendars?.length || 0) > 1">
      <div *ngFor="let calendar of calendars">
        <greco-box
          [style]="
            calendar.id === selectedCalendar?.id
              ? 'border-color: var(--primary-color); color: var(--primary-color)'
              : null
          "
          (click)="selectCalendar(calendar)"
        >
          <div style="display: flex; flex-direction: column; width: 100%; justify-content: center">
            <mat-icon style="width: 100%">{{ calendar.icon || 'calendar_month' }}</mat-icon>
            <p>{{ calendar.title }}</p>
            <div class="location">
              <mat-icon style="font-size: 16px; width: 16px; height: 16px">place</mat-icon>
              <span>{{ calendar.community?.name }}</span>
            </div>
          </div>
        </greco-box>
      </div>
    </div>
  </ng-container>
  <greco-admin-events-date-filters
    (rangeChanged)="dateRange$.next($event)"
    (dayClicked)="dayClicked($event)"
  ></greco-admin-events-date-filters>
</div>
<div style="position: relative">
  <mat-progress-bar
    *ngIf="loading"
    style="position: absolute; bottom: 0; left: 0; right: 0"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
<greco-admin-events-list
  *ngIf="requiredData$ | async as data"
  [userBookings]="data[1]"
  [userWaitlist]="data[2]"
  [events]="data[0]"
  [user]="user"
  [contact]="contact"
  (status)="getStatus($event)"
></greco-admin-events-list>
