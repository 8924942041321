import { TitleCasePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { Booking, BookingStatus, getCancellationPolicyInnerHtml, ResourceType } from '@greco/booking-events';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import moment from 'moment';
import { BookingService } from '../../services';

@Component({
  selector: 'greco-booking-info',
  templateUrl: './booking-info.dialog.html',
  styleUrls: ['./booking-info.dialog.scss'],
})
export class BookingInfoDialog {
  dialogData!: DialogData;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { booking: Booking },
    private bookingSvc: BookingService,
    private snackbar: MatSnackBar,
    private matDialog: MatDialog,
    private router: Router
  ) {
    this.dialogData = {
      title:
        data.booking.event.title +
        (data.booking.event.resourceAssignments?.length
          ? ' with ' +
            data.booking.event.resourceAssignments
              .reduce((acc, value) => {
                if (value.resource?.type === ResourceType.PERSON && !acc.includes(value.resource.name))
                  acc.push(value.resource.name);
                return acc;
              }, [] as string[])
              .join(', ')
          : ''),
      subtitle:
        moment(data.booking.event.startDate).format('LLL') + ' - ' + moment(data.booking.event.endDate).format('LT'),
      content:
        'Status: <strong>' +
        new TitleCasePipe().transform(data.booking.status) +
        '</strong><br/>' +
        'Booked with: <strong>' +
        data.booking.bookingOption.title +
        '</strong><br/><br/>' +
        getCancellationPolicyInnerHtml(data.booking.bookingOption),
      buttons: [
        ...(data.booking.status === BookingStatus.CONFIRMED ||
        (data.booking.status === BookingStatus.PENDING && moment(data.booking.event.startDate).isAfter(new Date()))
          ? [
              {
                role: 'cancel-booking',
                label: 'Cancel Booking',
                class: 'mat-warn',
                resultFn: () => this.cancel(),
              },
            ]
          : []),
        ...((data.booking.status === BookingStatus.CONFIRMED ||
          data.booking.status === BookingStatus.CHECKED_IN ||
          data.booking.status === BookingStatus.PENDING) &&
        (data.booking.event.zoomMeetingId || data.booking.event.zoomEvent?.meetingID) &&
        moment(data.booking.event.startDate).subtract(10, 'minutes').isBefore(new Date()) &&
        moment(data.booking.event.startDate).add(10, 'minutes').isAfter(new Date())
          ? [
              {
                role: 'join',
                label: 'Join',
                class: 'mat-primary',
                resultFn: () => this.join(data.booking),
              },
            ]
          : []),
        ...((data.booking.status === BookingStatus.CONFIRMED ||
          data.booking.status === BookingStatus.CHECKED_IN ||
          data.booking.status === BookingStatus.PENDING) &&
        moment(data.booking.event.startDate).add(10, 'minutes').isAfter(new Date())
          ? [
              {
                role: 'manage',
                label: 'Manage Booking',
                class: 'mat-primary',
                resultFn: () => this.manage(data.booking),
              },
              {
                role: 'addToCalendar',
                label: 'Add to Calendar',
                class: 'mat-primary',
                resultFn: () => this.addToCalendar(data.booking),
              },
            ]
          : []),
        {
          role: 'cancel',
          label: 'Close',
        },
      ],
    };
  }

  async cancel() {
    try {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          title: 'Cancel Booking',
          subtitle: `${this.data.booking.event.title} - ${moment(this.data.booking.event.startDate).format(
            'll hh:mm A'
          )}`,
          content: `
            ${getCancellationPolicyInnerHtml(this.data.booking.bookingOption)}
            <p>Are you sure you wish to cancel your booking?<p>
          `,
          buttons: [
            { label: 'No, keep my booking', role: 'no' },
            { label: 'Yes, cancel my booking', role: 'yes' },
          ],
          showCloseButton: false,
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        await this.bookingSvc.cancel(this.data.booking.id);
        this.snackbar.open('Your booking has been cancelled.', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }
    } catch (err: any) {
      this.snackbar.open('Error cancelling your booking.', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }
  }

  async join(booking: Booking) {
    if (booking.status === BookingStatus.CONFIRMED) {
      try {
        booking = await this.bookingSvc.checkIn(booking.id);
      } catch (err) {
        console.error(err);
        // FIXME: This should be handled
        // Right now the idea is it shouldn't prevent users from joining zoom
      }
    }

    if (booking.event.zoomMeetingId) {
      window.open(`https://zoom.us/j/${booking.event.zoomMeetingId}`, '_blank');
    }
  }

  manage(booking: Booking) {
    this.router.navigateByUrl(`workouts/${booking.event.id}`);
  }

  async addToCalendar(booking: Booking) {
    const ics = await this.bookingSvc.getIcs(booking.id);
    const blob = new Blob([ics.ics], { type: 'text/calendar;charset=utf-8' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = ics.filename;
    a.dataset.downloadurl = ['text/calendar', a.download, a.href].join(':');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function () {
      URL.revokeObjectURL(a.href);
    }, 1500);
  }
}
