<h3>Browse by Collection</h3>
<div class="grid-container">
  <greco-box *ngFor="let collection of collections" (click)="collectionClicked.emit(collection)">
    <mat-icon *ngIf="collection.icon">{{ collection.icon }}</mat-icon>
    <p>{{ collection.label }}</p>
  </greco-box>
  <greco-box *ngIf="giftCardConfigs?.length" (click)="giftCardClicked.emit()">
    <mat-icon>redeem</mat-icon>
    <p>Gift Cards</p>
  </greco-box>
</div>
