import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResourceType } from '@greco/booking-events';
import { EventVideo, EventVideoUnlockOption } from '@greco/event-videos';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { WatchVideoComponent } from '@greco/ngx-videos';
import { PropertyListener } from '@greco/property-listener-util';
import { VideoUnlock } from '@greco/videos';
import moment from 'moment';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EventVideosService, EventVideoUnlockService } from '../../services';

@Component({
  selector: 'greco-video-unlock-info',
  templateUrl: './video-unlock-info.component.html',
  styleUrls: ['./video-unlock-info.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class VideoUnlockInfoComponent {
  constructor(
    private eventVideoSvc: EventVideosService,
    private matDialog: MatDialog,
    private userSvc: UserService,
    private unlockSvc: EventVideoUnlockService
  ) {}

  currentUserId$ = this.userSvc.getUserId();

  @Output() unlocked = new EventEmitter<VideoUnlock>();

  @PropertyListener('user') private user$ = new ReplaySubject<User>(1);
  @Input() user!: User;

  @PropertyListener('eventVideo') private eventVideo$ = new ReplaySubject<EventVideo>(1);
  @Input() eventVideo!: EventVideo;

  @PropertyListener('activeUnlock') private activeUnlock$ = new BehaviorSubject<VideoUnlock | null>(null);
  @Input() activeUnlock?: VideoUnlock;

  @Input() selectedPerkRentalPeriod?: Date | null;

  readonly _expiresIn$ = this.activeUnlock$.pipe(
    map(unlock => (unlock && unlock.expiresOn ? moment(unlock.expiresOn).fromNow() : null))
  );

  readonly _events$ = this.eventVideo$.pipe(
    tap(eventVideo => {
      eventVideo.event?.resourceAssignments
        ? (eventVideo.event.resourceAssignments = eventVideo.event.resourceAssignments.filter(
            assignment => assignment.resource?.type === ResourceType.PERSON
          ))
        : {};
    }),
    map(eventVideo => (eventVideo.event ? [eventVideo.event] : []))
  );

  userPerks$ = combineLatest([this.eventVideo$, this.user$]).pipe(
    switchMap(async ([eventVideo, user]) => {
      if (!eventVideo?.event || !user) return [];

      const video = eventVideo.communityVideo?.video;
      if (!video) return [];

      const userPerks = await this.unlockSvc.getUserUnlockOptions(
        user?.id,
        video.tags?.map(t => t.id)
      );
      const eventDate = eventVideo.event.startDate.getTime();

      return userPerks.filter(up => {
        const unlockOption = up.perk as EventVideoUnlockOption;
        if (unlockOption.unlockWindow === 0) return true;
        return eventDate >= moment().subtract(unlockOption.unlockWindow, 'minutes').valueOf();
      });
    }),
    shareReplay(1)
  );

  async watch() {
    if (this.eventVideo?.communityVideo?.video && this.activeUnlock) {
      const dialog = this.matDialog.open(WatchVideoComponent, { data: {}, width: '750px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.video = this.eventVideo.communityVideo.video;
    }
  }
}
