import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Calendar,
  CalendarSecurityResource,
  CalendarSecurityResourceAction,
  EventTemplate,
} from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CalendarDetails } from '..';
import { CalendarService, EventTemplateService } from '../../services';

@Component({
  selector: 'greco-calendar-template-details',
  templateUrl: './calendar-template-details.component.html',
  styleUrls: ['./calendar-template-details.component.scss'],
})
export class CalendarTemplateDetailsComponent {
  constructor(
    private router: Router,
    private snacks: MatSnackBar,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private calendarSvc: CalendarService,
    private templateSvc: EventTemplateService,
    private comSecSvc: CommunitySecurityService
  ) {}

  private _calendar$ = new BehaviorSubject<Calendar | null>(null);
  @Input() set calendar(calendar) {
    this._calendar$.next(calendar);
    setTimeout(() => {
      this.resetDetails();
    });
  }
  get calendar() {
    return this._calendar$.value;
  }

  @Input() community!: Community;
  @Input() color = 'primary';

  @Output() changed = new EventEmitter();

  saving = false;

  filteredByCalendar: EventTemplate[] = [];

  templates$ = this._calendar$.pipe(
    switchMap(async calendar => {
      return calendar ? await this.templateSvc.getAllEventTemplates(calendar?.communityId) : [];
    }),
    map(templates => templates.sort((a, b) => a.title.localeCompare(b.title))),
    tap(templates => {
      this.filteredByCalendar = [];
      templates?.forEach(template => {
        this.calendar?.eventTemplates?.forEach(temp => {
          if (template.id === temp.id) {
            this.filteredByCalendar.push(template);
          }
        });
      });
    })
  );

  filteredTemplates$ = this._calendar$.pipe(
    switchMap(async calendar => {
      return calendar ? await this.templateSvc.getAllEventTemplates(calendar?.communityId) : [];
    }),
    map(templates => {
      return templates
        .filter(template => !this.calendar?.eventTemplates?.some(t => t.id === template.id))
        .sort((a, b) => a.title.localeCompare(b.title));
    })
  );

  canUpdate$ = this._calendar$.pipe(
    switchMap(async calendar =>
      calendar
        ? await this.comSecSvc.hasAccess(
            calendar.communityId,
            CalendarSecurityResource.key,
            CalendarSecurityResourceAction.UPDATE
          )
        : false
    )
  );

  canReadCalendar$ = this._calendar$.pipe(
    switchMap(async calendar =>
      calendar
        ? await this.comSecSvc.hasAccess(
            calendar.communityId,
            CalendarSecurityResource.key,
            CalendarSecurityResourceAction.READ
          )
        : false
    ),
    shareReplay(1)
  );

  resetDetailsValue: CalendarDetails = {
    title: '',
    icon: null,
    private: false,
  };

  detailsForm = this.formBuilder.group({ calendarDetails: [null] });

  async navigateToTemplate(templateId: string) {
    await this.router.navigate([`../../event-templates/${templateId}`], { relativeTo: this.route });
  }

  async addTemplate(templateId: string) {
    if (this.calendar) {
      const response = await this.calendarSvc.addTemplate({ templateId: templateId, calendarId: this.calendar?.id });
      if (response) this.changed.emit(response);
    }
  }

  async removeTemplate(templateId: string) {
    if (this.calendar) {
      const response = await this.calendarSvc.removeTemplate({ templateId: templateId, calendarId: this.calendar?.id });
      if (response) this.changed.emit(response);
    }
  }

  saveDetails = async () => {
    this.saving = true;

    try {
      const detailsData = this.detailsForm.value.calendarDetails;
      const calendarId = this.calendar?.id;
      if (!calendarId) return;

      const formData = new FormData();
      formData.append('title', detailsData.title);
      formData.append('icon', detailsData.icon);

      const result = await this.calendarSvc.update(this.community.id, calendarId, formData);
      this.calendar = result;

      this.snacks.open('Updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }

    this.saving = false;
  };

  resetDetails() {
    this.resetDetailsValue = {
      title: this.calendar?.title || null,
      icon: this.calendar?.icon,
      private: this.calendar?.private || false,
    };

    this.detailsForm.reset({ calendarDetails: this.resetDetailsValue });
    this.detailsForm.markAsPristine();

    if (this.calendar) this.detailsForm.enable();
    else this.detailsForm.disable();
  }
}
