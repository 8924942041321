<div class="title-section">
  <h2 class="title">
    <mat-icon>redeem</mat-icon>
    <span>Gift Cards</span>
  </h2>

  <button mat-button class="view-more-button" (click)="viewMore()">
    View More
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<div class="products-swiper">
  <swiper
    [slidesPerView]="'auto'"
    [spaceBetween]="16"
    [navigation]="true"
    [slidesOffsetBefore]="mobileView ? 16 : 32"
    [slidesOffsetAfter]="mobileView ? 16 : 32"
  >
    <ng-template swiperSlide *ngFor="let giftCardConfig of giftCardConfigs">
      <greco-gift-card-product-card [giftCardConfig]="giftCardConfig"> </greco-gift-card-product-card>
    </ng-template>
  </swiper>
</div>
