import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { PaginatedQueryParams, PaginationMetadata } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { AccountResource, AccountResourceAction } from '@greco/finance-accounts';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { Purchase, PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BulkRetryPurchaseDialog, BulkVoidPurchaseDialog, RetryFailedPurchaseDialog } from '../../dialogs';
import { PurchaseService } from '../../services';

export type PurchaseTablecolumn =
  | 'icon'
  | 'title'
  | 'created'
  | 'account'
  | 'customer'
  | 'sold_by'
  | 'subtotal'
  | 'taxes'
  | 'total'
  | 'balance_used'
  | 'status'
  | 'admin_options'
  | 'user_options'
  | 'type'
  | 'sale_category'
  | 'purchased_for'
  | 'purchased_by';

@Component({
  selector: 'greco-purchases-table',
  templateUrl: './purchases-table.component.html',
  styleUrls: ['./purchases-table.component.scss'],
})
export class PurchasesTableComponent implements OnDestroy {
  constructor(
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    private userSvc: UserService,
    private purchaseSvc: PurchaseService,
    private securitySvc: SecurityService
  ) {}

  @Input() isAdminPage!: boolean;

  permissions$ = this.userSvc.authUser$.pipe(
    switchMap(async () => {
      const [
        isSuper,
        canVoid,
        canRetry,
        canManageSoldBy,
        canManageReferredBy,
        canRefundToBalance,
        canRefundToPaymentMethod,
      ] = await Promise.all([
        this.securitySvc.hasAccess(AccountResource.key, AccountResourceAction.CREATE, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.VOID, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.RETRY, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.MANAGE_SOLD_BY, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.MANAGE_REFERRED_BY, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.REFUND_TO_BALANCE, {}, true),
        this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.REFUND_TO_PAYMENT_METHOD, {}, true),
      ]);

      return {
        isSuper,
        canVoid,
        canRetry,
        canManageSoldBy,
        canManageReferredBy,
        canRefundToBalance,
        canRefundToPaymentMethod,
      };
    }),
    shareReplay(1)
  );

  readonly _purchaseTypeMappings: { [key: string]: string } = {
    PurchaseEntity: '',
    CustomChargePurchaseEntity: 'Custom Charge',

    SubscriptionStartPurchaseEntity: 'Subscription Start',
    SubscriptionRenewalPurchaseEntity: 'Subscription Renewal',
    SubscriptionUnfreezePurchaseEntity: 'Subscription Unfreeze',

    CommunityVideoPurchaseEntity: 'Video Unlock',
    EventVideoPurchaseEntity: 'Event Replay',

    BookingPurchaseEntity: 'Booking',
    BookingCancellationPurchaseEntity: 'Booking Cancellation',

    ProductPurchaseEntity: 'One-Time Product',
  };
  @Input() allowLink = true;
  @Output() rowClick = new EventEmitter<any>();
  @Input() communityId!: string;
  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');

  @PropertyListener('variantIds') _variantIds$ = new BehaviorSubject<string>('');
  @Input() variantIds!: string;

  @PropertyListener('saleCategoryIds') _saleCategoryIds$ = new BehaviorSubject<string>('');
  @Input() saleCategoryIds!: string; // Single string to trigger change detection

  @PropertyListener('showUncategorized') _showUncategorized$ = new BehaviorSubject<boolean>(false);
  @Input() showUncategorized = false;

  @Input() columns: PurchaseTablecolumn[] = [
    'icon',
    'title',
    'created',
    'customer',
    'sold_by',
    'subtotal',
    'taxes',
    'total',
    'balance_used',
    'status',
    'admin_options',
    'type',
    'sale_category',
    'purchased_for',
    'purchased_by',
  ];

  private _loadChildren$ = new BehaviorSubject<boolean>(false);

  @Input() set loadChildren(loadChildren: boolean) {
    this._loadChildren$.next(loadChildren || false);
  }
  get loadChildren() {
    return this._loadChildren$.value;
  }

  private _userId$ = new BehaviorSubject<string | null>(null);

  @Input() set userId(userId: string | null | undefined) {
    this._userId$.next(userId || null);
  }
  get userId() {
    return this._userId$.value;
  }

  private _accountId$ = new BehaviorSubject<string | null>(null);

  @Input() set accountId(accountId: string | null | undefined) {
    this._accountId$.next(accountId || null);
  }
  get accountId() {
    return this._accountId$.value;
  }

  @Input() set pageSizes(sizes: number[]) {
    this._pageSizes$.next(sizes?.length ? sizes : [10, 20, 50]);
    const paginatedParams = this.paginatedParams$.value;
    if (!this._pageSizes$.value.includes(paginatedParams.limit || 0)) {
      this.paginatedParams$.next({ ...paginatedParams, limit: this.pageSizes[0] });
    }
  }
  get pageSizes() {
    return this._pageSizes$.value;
  }

  @Input() set queryBuilder(queryBuilder: RequestQueryBuilder) {
    this._queryBuilder$.next(queryBuilder);
  }
  get queryBuilder() {
    return this._queryBuilder$.value;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private _pageSizes$ = new BehaviorSubject<number[]>([10, 20, 50]);
  private _queryBuilder$ = new BehaviorSubject(new RequestQueryBuilder());

  loading = true;
  currentPagination: PaginationMetadata | null = null;
  paginatedParams$ = new BehaviorSubject<PaginatedQueryParams>({ page: 1, limit: 10 });

  purchases$ = combineLatest([
    combineLatest([
      this._queryBuilder$,
      this.paginatedParams$,
      this._userId$,
      this._accountId$,
      this._saleCategoryIds$.pipe(map(saleCatIds => (saleCatIds ? saleCatIds.split(',') : []))),
      this._showUncategorized$,
    ]),
    combineLatest([this._variantIds$.pipe(map(varIds => (varIds ? varIds.split(',') : [])))]),
    combineLatest([this._loadChildren$]),
  ]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    debounceTime(500),
    switchMap(
      ([
        [queryBuilder, params, userId, accountId, saleCategoryIds, showUncategorized],
        [variantIds],
        [loadChildren],
      ]) => {
        return this.purchaseSvc.paginate(
          queryBuilder,
          params,
          {
            ...(accountId && { accountId }),
            ...(userId && { userId }),
            ...(saleCategoryIds.length && { saleCategoryIds }),
            ...(variantIds?.length && { variantIds }),
          },
          showUncategorized,
          loadChildren,
          true
        );
      }
    ),

    tap(({ meta }) => setTimeout(() => (this.currentPagination = meta))),
    map(({ items }) => items),

    tap(() => setTimeout(() => (this.loading = false)))
  );

  ngOnDestroy() {
    // TODO: Complete all subjects
    this._userId$.complete();
    this._accountId$.complete();
    this._pageSizes$.complete();
    this._queryBuilder$.complete();
    this.paginatedParams$.complete();
    this._communityId$.complete();
  }

  refresh() {
    this.paginatedParams$.next(this.paginatedParams$.value);
  }

  async sendAdhocEmailReceipt(purchase: Purchase) {
    try {
      await this.purchaseSvc.sendPurchaseReceipt(purchase.id);

      this.snacks.open('Email with purchase receipt sent!', 'Ok', {
        panelClass: 'mat-primary',
        duration: 3000,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async retryFailedPurchase(purchase: Purchase) {
    await toPromise(this.matDialog.open(RetryFailedPurchaseDialog, { data: purchase }).afterClosed());
    this.refresh();
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  async bulkRetry() {
    const filters = {
      accountId: this.accountId ?? undefined,
      userId: this.userId ?? undefined,
      saleCategoryIds: this._saleCategoryIds$.value?.split(',') ?? undefined,
      variantIds: this._variantIds$.value?.split(',') ?? undefined,
    };
    const total = await this.purchaseSvc.getBulkRetryTotal(this.queryBuilder, filters, this.showUncategorized);

    // Gets the user from the filter bar, if any
    const filteredUsers = JSON.parse(this.queryBuilder.queryObject.s).$and?.filter((i: any) => i['user.id'])?.[0]?.[
      'user.id'
    ]?.$in;
    const userIds = filters.userId ? [filters.userId] : filteredUsers?.length ? filteredUsers : undefined;

    const closed = await toPromise(
      this.matDialog
        .open(BulkRetryPurchaseDialog, {
          data: {
            retryTotal: total,
            pageTotal: this.currentPagination?.totalItems ?? 0,
            filters,
            queryBuilder: this.queryBuilder,
            showUncategorized: this.showUncategorized,
            userIds,
            accountId: this.accountId,
          },
        })
        .afterClosed()
    );
    if (closed?.submit) this.refresh();
  }

  async bulkVoid() {
    const filters = {
      accountId: this.accountId ?? undefined,
      userId: this.userId ?? undefined,
      saleCategoryIds: this._saleCategoryIds$.value?.split(',') ?? undefined,
      variantIds: this._variantIds$.value?.split(',') ?? undefined,
    };
    const total = await this.purchaseSvc.getBulkVoidTotal(this.queryBuilder, filters, this.showUncategorized);

    if (total === 0) {
      this.snacks.open('No purchases to void! Please adjust your filters.', 'Ok', {
        duration: 3000,
        panelClass: 'mat-warn',
      });
      return;
    } else {
      const closed = await toPromise(
        this.matDialog
          .open(BulkVoidPurchaseDialog, {
            data: {
              voidableTotal: total,
              pageTotal: this.currentPagination?.totalItems ?? 0,
              filters,
              queryBuilder: this.queryBuilder,
              showUncategorized: this.showUncategorized,
            },
          })
          .afterClosed()
      );
      if (closed?.submit) this.refresh();
    }
  }
}
