<div *ngIf="canRead$ | async">
  <ng-container *grecoLet="canUpdate$ | async as canUpdate">
    <div style="display: flex; flex-direction: row; padding-top: 16px; width: 100%; padding-left: 16px">
      <h4 style="white-space: nowrap; margin-bottom: 0">Subcollection Videos</h4>
    </div>
    <div *ngIf="videos$ | async as videos">
      <greco-filter-bar
        [hideSeparator]="true"
        [filterOptions]="filterOptions"
        (changed)="filters$.next($event[1]); onFilterApplied()"
      >
        <div class="buttons" style="display: flex; flex-direction: row; justify-content: flex-end; gap: 8px">
          <button
            mat-stroked-button
            color="primary"
            (click)="$event.stopImmediatePropagation(); addVideo()"
            *ngIf="canUpload$ | async"
          >
            Select Existing
          </button>
          <button
            *ngIf="canUpload$ | async"
            mat-stroked-button
            color="primary"
            (click)="$event.stopImmediatePropagation(); upload()"
          >
            Upload
          </button>
          <button
            mat-icon-button
            class="buttons"
            *ngIf="canUpdate && sortOrderChange.length > 0"
            (click)="$event.stopImmediatePropagation(); refresh()"
            [disabled]="sortLoading"
            matTooltip="Undo Changes"
          >
            <mat-icon>replay</mat-icon>
          </button>
          <button
            class="buttons"
            *ngIf="canUpdate && sortOrderChange.length > 0"
            mat-flat-button
            color="primary"
            (click)="$event.stopImmediatePropagation(); saveSortOrders()"
            [disabled]="sortLoading"
          >
            {{ !sortLoading ? 'Save' : 'Saving' }}
          </button>
        </div></greco-filter-bar
      >
      <greco-table
        [data]="videos"
        [highlight]="true"
        (rowClick)="navigateToCollectionVideo($event)"
        [draggable]="(canUpdate !== null ? canUpdate : false) && !sortLoading"
        (dropEvent)="dropEvent($event)"
      >
        <mat-icon *grecoTableCol="''; fitContent: true; let cv; let hovering = hovering" [matTooltip]="cv.id">
          {{ hovering ? 'drag_handle' : 'smart_display' }}
        </mat-icon>

        <!-- video title -->
        <ng-container>
          <p *grecoTableCol="'Video Title'; let cv" [matTooltip]="'Title of video on the platform'">
            {{ cv.communityVideo.video.title }}
          </p>
        </ng-container>

        <!-- collection video title -->
        <ng-container>
          <p *grecoTableCol="'Collection Display Title'; let cv" [matTooltip]="'Title of video in the subcollection'">
            {{ cv.titleOverride }}
          </p>
        </ng-container>

        <!-- Publish Date -->
        <ng-container>
          <p *grecoTableCol="'Publish Date'; let cv" [matTooltip]="'Date base video is published on platform'">
            {{ !cv.availableOn ? 'Never' : (cv.availableOn | date) }}
          </p>
        </ng-container>

        <!-- Status -->
        <ng-container>
          <p *grecoTableCol="'Status'; fitContent: true; let video" [matTooltip]="'Status'">
            <ng-container
              *grecoLet="
                video.availableOn &&
                (video.availableOn | grecoDateCompare : 'lt') &&
                (!video.availableUntil || (video.availableUntil | grecoDateCompare : 'gt')) as active
              "
            >
              <ng-container *grecoLet="!video.availableOn && !video.availableUntil as draft">
                <mat-chip>{{ active ? 'Active' : draft ? 'Draft' : 'Archived' }}</mat-chip>
              </ng-container>
            </ng-container>
          </p>
        </ng-container>
        <!-- Options -->
        <ng-container>
          <ng-container *grecoTableCol="''; let cv; fitContent: true; stickyEnd: true">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [matTooltip]="'Options for ' + cv.id"
              (click)="$event.stopImmediatePropagation()"
              [disabled]="sortLoading"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu>
              <!-- Watch -->
              <button mat-menu-item (click)="watch(cv)">
                <mat-icon>slideshow</mat-icon>
                <span>Watch</span>
              </button>

              <!-- Manage -->
              <button mat-menu-item (click)="navigateToCollectionVideo(cv)">
                <mat-icon>video_settings</mat-icon>
                <span>View Details</span>
              </button>

              <!-- Activate/Archive -->
              <button mat-menu-item (click)="changeStatus(cv)" *ngIf="canUpdate$ | async">
                <ng-container
                  *grecoLet="
                    cv.availableOn &&
                    (cv.availableOn | grecoDateCompare : 'lt') &&
                    (!cv.availableUntil || (cv.availableUntil | grecoDateCompare : 'gt')) as isActive
                  "
                >
                  <mat-icon>{{ !isActive ? 'unarchive' : 'archive' }}</mat-icon>
                  <span>{{ !isActive ? 'Activate' : 'Archive' }}</span>
                </ng-container>
              </button>

              <!-- Remove
          <button mat-menu-item (click)="remove(cv)" *ngIf="canDelete$ | async">
            <mat-icon>delete</mat-icon>
            <span>Remove</span>
          </button> -->

              <!-- Top -->
              <ng-container *ngIf="canUpdate$ | async">
                <button
                  mat-menu-item
                  *ngIf="cv.sortIndex > 0"
                  (click)="updateSortIndex(cv, 0)"
                  matTooltip="Move this video to the top of the subcollection (not just the table)"
                >
                  <mat-icon>vertical_align_top</mat-icon>
                  <span>Move To Top</span>
                </button>

                <!-- Up 
                <button mat-menu-item *ngIf="cv.sortIndex > 0" (click)="updateSortIndex(cv, cv.sortIndex - 1)">
                  <mat-icon>arrow_upwards</mat-icon>
                  <span>Move Up</span>
                </button>-->

                <!-- Down 
                <button
                  mat-menu-item
                  *ngIf="cv.sortIndex < videos.length - 1"
                  (click)="updateSortIndex(cv, cv.sortIndex + 1)"
                >
                  <mat-icon>arrow_downwards</mat-icon>
                  <span>Move Down</span>
                </button>-->
                <!-- Move to bottom -->
                <button mat-menu-item (click)="pagination ? updateSortIndex(cv, pagination.totalItems) : null">
                  <mat-icon>vertical_align_bottom</mat-icon>
                  <span>Move To Bottom</span>
                </button></ng-container
              >
            </mat-menu>
          </ng-container>
        </ng-container>
      </greco-table>
      <mat-paginator
        *ngIf="pagination?.totalItems"
        showFirstLastButtons
        [length]="pagination!.totalItems || 0"
        [pageSize]="pagination!.itemsPerPage || 10"
        [pageSizeOptions]="[10, 20, 50]"
        (page)="sortOrderChange = []; page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
      ></mat-paginator>
    </div>
  </ng-container>
</div>
