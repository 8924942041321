import { Account } from './account';

export const ADMIN_FEE_PURCHASE_ITEM_TYPE = 'Admin Fee';

export interface AdminFee {
  accountId: string;
  account?: Account;

  label: string;
  description: string;

  amount: number; //in cents

  created: Date;
  modified: Date;

  enabled: boolean;
}
