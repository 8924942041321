<greco-simple-dialog [data]="dialogData" *grecoLet="preview$ | async as preview">
  <form [formGroup]="form" (ngSubmit)="submit(preview)">
    <!-- Balance View -->
    <div
      style="display: grid; grid-template-columns: 1fr auto 1fr; margin-top: 24px; margin-bottom: 16px"
      *grecoLet="balance$ | async as balance"
    >
      <div style="text-align: center; padding: 8px">
        <h4 style="margin-bottom: 8px">Current Balance</h4>
        <p style="margin: 0">{{(balance || 0) / 100 | currency}}</p>
      </div>

      <div style="border-left: 1px solid lightgray"></div>

      <div style="text-align: center; padding: 8px">
        <h4 style="margin-bottom: 8px">New Balance</h4>
        <p style="margin: 0">
          {{balance || this.form.value.amount > 0 ? (((balance || 0) + this.form.value.amount * 100)/ 100 | currency) :
          '-'}}
        </p>
      </div>
    </div>

    <!-- Account -->
    <mat-form-field *ngIf="this.showCommunityPicker">
      <mat-label>Community</mat-label>
      <mat-select formControlName="community">
        <mat-option *ngFor="let comm of communities$ | async" [value]="comm">{{comm.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Amount -->
    <mat-form-field appearance="standard">
      <mat-label>Amount</mat-label>
      <input matInput formControlName="amount" currencyMask [options]="currencyMaskConfig" />
      <mat-error *ngIf="this.form.get('amount')?.errors?.min">Funding amount must be greater than $0.50</mat-error>
    </mat-form-field>

    <!-- Payment Method -->
    <mat-form-field appearance="standard">
      <greco-select-payment-method
        #selectPaymentMethodInput
        formControlName="paymentMethod"
        [required]="true"
        [userId]="user.id"
        [allowTerminals]="true"
        [allowBankPayments]="false"
        [accountId]="community?.financeAccountId"
      ></greco-select-payment-method>
      <greco-add-payment-method-button
        matSuffix
        *ngIf="user"
        [onlyIcon]="true"
        [userId]="user.id"
        (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
      ></greco-add-payment-method-button>
    </mat-form-field>

    <!-- Buttons -->
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1" [disabled]="processing">
        <span>Cancel</span>
      </button>

      <button
        mat-stroked-button
        type="submit"
        color="primary"
        style="flex: 1; min-height: 36px"
        [disabled]="form.invalid || processing || !preview || !!preview.errors.length || typing"
      >
        <span *ngIf="!processing">Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="spin">loop</mat-icon>
      </button>
    </div>
  </form>
  <!-- {{"Invalid " + form.invalid + " Processing: " + processing + " No Preview: " + !preview + " Typing: " + typing}} -->
</greco-simple-dialog>
