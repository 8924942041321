import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EventsModule } from '@greco/ngx-booking-events';
import { GrecoIdentityFeatureCommunityVideosModule } from '@greco/ngx-community-videos';
import { FiltersModule } from '@greco/ngx-filters';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { JoinPipeModule } from '@greco/ngx-join-pipe';
import { LetModule } from '@greco/ngx-let-util';
import { PerksModule } from '@greco/ngx-sales-perks';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { VideosModule } from '@greco/ngx-videos';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { IconPickerModule } from '@greco/ui-icon-picker';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { SmartImgModule } from '@greco/ui-smart-img';
import { TableModule } from '@greco/ui-table';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import { SwiperModule } from 'swiper/angular';
import {
  CommunityVideoEventsTableComponent,
  EventVideoListItemComponent,
  EventVideosComponent,
  LockedGalleryComponent,
  MinutesInputComponent,
  UserWorkOutLibraryFiltersComponent,
  VideoUnlockInfoComponent,
} from './components';
import { AddEventVideoSearchComponent, CreateEventVideoUnlockOptionDialog } from './dialogs';
import { ManageUnlockDialog } from './dialogs/manage-unlock/manage-unlock.dialog';
import {
  AdminEventVideoUnlockOptionPage,
  AdminEventVideoUnlockOptionsPage,
  AdminManageEventVideoUnlocksPage,
  PreviewUnlockVideoPage,
  UserEventVideosPage,
} from './pages';
import { MinutesPipe } from './pipes';
import { EventVideosService, EventVideoUnlockService } from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    SwiperModule,

    // Angular Material
    RouterModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,

    // Greco
    LetModule,
    BoxModule,
    PerksModule,
    TableModule,
    EventsModule,
    VideosModule,
    FiltersModule,
    SmartImgModule,
    PaymentsModule,
    PurchasesModule,
    SimpleDialogModule,
    FormSaveButtonsModule,
    CollapsibleSectionModule,
    GrecoIdentityFeatureCommunityVideosModule,
    IconPickerModule,

    // Other
    NgPipesModule,
    SafePipeModule,
    JoinPipeModule,
    MatDividerModule,
  ],
  providers: [EventVideosService, EventVideoUnlockService],
  declarations: [
    // Pipes
    MinutesPipe,

    //Components
    EventVideosComponent,
    MinutesInputComponent,
    VideoUnlockInfoComponent,
    CommunityVideoEventsTableComponent,

    // Dialogs
    LockedGalleryComponent,
    AddEventVideoSearchComponent,
    CreateEventVideoUnlockOptionDialog,
    ManageUnlockDialog,

    // Pages
    UserEventVideosPage,
    PreviewUnlockVideoPage,
    AdminEventVideoUnlockOptionPage,
    AdminEventVideoUnlockOptionsPage,
    UserWorkOutLibraryFiltersComponent,
    EventVideoListItemComponent,
    AdminManageEventVideoUnlocksPage,
  ],
  exports: [
    UserEventVideosPage,
    EventVideosComponent,
    PreviewUnlockVideoPage,
    AdminEventVideoUnlockOptionPage,
    AdminEventVideoUnlockOptionsPage,
    CommunityVideoEventsTableComponent,
    AdminManageEventVideoUnlocksPage,
    VideoUnlockInfoComponent,
  ],
})
export class EventVideosModule {}
