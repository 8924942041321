import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GiftCardOptionDto } from '@greco/api-gift-cards';
import { SecurityService } from '@greco/ngx-security-util';
import { ProductVariantVisibility } from '@greco/sales-products';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-configure-option-dialog',
  templateUrl: './configure-option.dialog.html',
  styleUrls: ['./configure-option.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfigureGiftCardOptionDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { option?: GiftCardOptionDto },
    private dialogRef: MatDialogRef<ConfigureGiftCardOptionDialog>,
    private fb: FormBuilder,
    private securitySvc: SecurityService,
    private snacks: MatSnackBar
  ) {
    this.option = data.option;
  }

  option?: GiftCardOptionDto;

  @ViewChild(SimpleDialog) private container!: SimpleDialog;

  formGroup = this.fb.group({
    title: [null as string | null],
    description: [null as string | null],
    amount: [0, [Validators.required, Validators.min(5)]],
    price: [0, [Validators.required, Validators.min(0)]],
    visibility: [ProductVariantVisibility.VISIBLE, Validators.required],
  });

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  private _onDestroy$ = new Subject<void>();
  refresh$ = new BehaviorSubject(null);

  ngOnInit() {
    this.formGroup.valueChanges
      .pipe(
        startWith(this.formGroup.value),
        takeUntil(this._onDestroy$),
        map(() => this.container?.data?.buttons?.find(btn => btn.role === 'create'))
      )
      .subscribe(button => button && ((button as any).disabled = this.formGroup.invalid));

    if (this.option) {
      this.formGroup.patchValue({
        title: this.option.title || null,
        description: this.option.description || null,
        amount: this.option.amount / 100,
        price: this.option.price / 100,
        visibility: this.option.visibility,
      });
    }
  }

  close(values?: any) {
    this.dialogRef.close(
      values
        ? {
            ...values,
            amount: (values.amount || 0) * 100,
            price: (values.price || 0) * 100,
            id: this.option?.id,
          }
        : undefined
    );
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
