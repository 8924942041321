<greco-table
  *grecoLet="permissions$ | async as permissions"
  [loading]="loading"
  [highlight]="true"
  [data]="(purchases$ | async) || []"
  (rowClick)="rowClick.next($event)"
>
  <!-- Icon -->
  <ng-container *ngIf="columns.includes('icon')">
    <ng-container *grecoTableCol="''; let purchase; fitContent: true">
      <div [matTooltip]="purchase.id" style="display: flex; align-items: center; justify-content: center">
        <ng-container *ngIf="purchase.payment || purchase.balanceUsed; else notPaid">
          <mat-icon
            *ngIf="
              purchase.payment?.paymentMethod && purchase.payment.paymentMethod.model === 'terminal';
              else otherModels
            "
          >
            contactless
          </mat-icon>
          <ng-template #otherModels>
            <img
              style="width: 36px; min-width: 36px"
              [src]="
                purchase.payment?.paymentMethod
                  ? 'assets/payment-methods/' + purchase.payment.paymentMethod.model + '.png'
                  : 'assets/lf3/icon.png'
              "
            />
          </ng-template>
        </ng-container>

        <ng-template #notPaid>
          <mat-icon>payment</mat-icon>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>

  <!-- Title -->
  <ng-container *ngIf="columns.includes('title')">
    <ng-container *grecoTableCol="'Purchase'; let purchase">
      <p
        style="overflow: hidden"
        [matTooltip]="(purchase.items | filterCoupon : false | pluck : 'description').join(', ')"
        [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''"
      >
        {{ (purchase.items | filterCoupon : false | pluck : 'displayName').join(', ') }}
      </p>
      <p
        *ngIf="
          columns.includes('purchased_for') &&
          ((loadChildren && purchase.user.id !== userId) ||
            (purchase.user.id !== userId && purchase.user.id !== purchase.purchasedById))
        "
        [matTooltip]="purchase.user.contactEmail"
      >
        <small style="font-style: italic">(For {{ purchase.user.displayName }})</small>
      </p>
      <p
        *ngIf="
          columns.includes('purchased_by') &&
          !columns.includes('customer') &&
          (purchase.purchasedBy.id !== userId ||
            (purchase.purchasedById !== userId && purchase.user.id !== purchase.purchasedById))
        "
        [matTooltip]="purchase.purchasedBy?.contactEmail"
      >
        <small style="font-style: italic">(Purchased By {{ purchase.purchasedBy?.displayName }})</small>
      </p>
    </ng-container>
  </ng-container>

  <!-- Type -->
  <ng-container *ngIf="columns.includes('type')">
    <ng-container *grecoTableCol="'Purchase Type'; let purchase">
      <p *grecoLet="_purchaseTypeMappings[purchase.type] as purchaseType">
        {{ purchaseType === undefined ? purchase.type : purchaseType }}
      </p>
    </ng-container>
  </ng-container>

  <!-- Category -->
  <ng-container *ngIf="columns.includes('sale_category')">
    <ng-container *grecoTableCol="'Sales Category'; let purchase">
      <p *ngIf="purchase.items && purchase.items.length">
        <ng-container
          *ngFor="let label of purchase.items | filterCoupon : false | pluck : 'saleCategory.label'; let last = last"
        >
          {{ label || 'Uncategorized' }}{{ !last ? ', ' : '' }}
        </ng-container>
      </p>
    </ng-container>
  </ng-container>

  <!-- Date -->
  <ng-container *ngIf="columns.includes('created')">
    <ng-container *grecoTableCol="'Created'; let purchase">
      <p [matTooltip]="(purchase.created | date : 'full') || ''">
        {{ purchase.created | date : 'EEE' }} {{ purchase.created | date }}
      </p>
    </ng-container>
  </ng-container>

  <!-- Account -->
  <ng-container *ngIf="columns.includes('account')">
    <ng-container *grecoTableCol="'Account'; let purchase">
      <p [matTooltip]="purchase.account.id || ''">{{ purchase.account.name }}</p>
    </ng-container>
  </ng-container>

  <!-- Customer -->
  <ng-container *ngIf="columns.includes('customer')">
    <ng-container *grecoTableCol="'Billed To'; let purchase">
      <!-- Ask Zack if I need to change as it is related to purchase -->
      <a
        *ngIf="allowLink; else noLink"
        [routerLink]="['/admin/community', communityId, purchase.purchasedById]"
        [matTooltip]="purchase.purchasedBy.contactEmail"
        (click)="$event.stopImmediatePropagation()"
      >
        <p [matTooltip]="purchase.purchasedBy.contactEmail" style="line-height: 16px">
          {{ purchase.purchasedBy.displayName }}
        </p>
      </a>
      <ng-template #noLink>
        <p [matTooltip]="purchase.purchasedBy.contactEmail" style="line-height: 16px">
          {{ purchase.purchasedBy.displayName }}
        </p>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Sold By -->
  <ng-container *ngIf="columns.includes('sold_by')">
    <ng-container *grecoTableCol="'Sold By'; let purchase">
      <ng-container *ngIf="purchase.soldBy">
        <a
          *ngIf="allowLink; else noLink"
          [routerLink]="['/admin/community', communityId, purchase.soldById]"
          [matTooltip]="purchase.soldBy.contactEmail"
          (click)="$event.stopImmediatePropagation()"
        >
          <p [matTooltip]="purchase.soldBy.contactEmail" style="line-height: 16px">
            {{ purchase.soldBy.displayName || purchase.soldBy.contactEmail }}
          </p>
        </a>

        <ng-template #noLink>
          <p [matTooltip]="purchase.soldBy.contactEmail" style="line-height: 16px">
            {{ purchase.soldBy.displayName || purchase.soldBy.contactEmail }}
          </p>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- Voided By -->
  <ng-container *ngIf="columns.includes('voided_by')">
    <ng-container *grecoTableCol="'Voided By'; let purchase">
      <ng-container *ngIf="purchase.voidedBy">
        <a
          *ngIf="allowLink; else noLink"
          [routerLink]="['/admin/community', communityId, purchase.voidedById]"
          [matTooltip]="purchase.voidedBy.contactEmail"
          (click)="$event.stopImmediatePropagation()"
        >
          <p [matTooltip]="purchase.voidedBy.contactEmail" style="line-height: 16px">
            {{ purchase.voidedBy.displayName || purchase.voidedBy.contactEmail }}
          </p>
        </a>

        <ng-template #noLink>
          <p [matTooltip]="purchase.voidedBy.contactEmail" style="line-height: 16px">
            {{ purchase.voidedBy.displayName || purchase.voidedBy.contactEmail }}
          </p>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- Coupon -->
  <ng-container *ngIf="columns.includes('title')">
    <ng-container *grecoTableCol="'Coupon'; let purchase">
      <p
        style="overflow: hidden"
        [matTooltip]="(purchase.items | filterCoupon : true | pluck : 'description').join(', ')"
        [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''"
      >
        {{ (purchase.items | filterCoupon : true | pluck : 'displayName' | trim).join(', ') }}
      </p>
    </ng-container>
  </ng-container>

  <!-- Subtotal -->
  <ng-container *ngIf="columns.includes('subtotal')">
    <ng-container *grecoTableCol="'Subtotal'; let purchase; alignRight: true">
      <div
        *grecoLet="purchase | purchaseSubtotal as subtotal"
        [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''"
      >
        <p>{{ subtotal ? (subtotal / 100 | currency) : '-' }}</p>
        <p *ngIf="purchase.subtotal && purchase.subtotal !== subtotal" style="font-size: 75%">
          (Out of <strong>{{ purchase.subtotal / 100 | currency }} </strong>)
        </p>
      </div>
    </ng-container>
  </ng-container>

  <!-- Taxes -->
  <ng-container *ngIf="columns.includes('taxes')">
    <ng-container *grecoTableCol="'Taxes'; let purchase; alignRight: true">
      <div
        *grecoLet="purchase | purchaseTax as tax"
        [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''"
      >
        <p>{{ tax ? (tax / 100 | currency) : '-' }}</p>
        <p *ngIf="purchase.tax && purchase.tax !== tax" style="font-size: 75%">
          (Out of <strong>{{ purchase.tax / 100 | currency }} </strong>)
        </p>
      </div>
    </ng-container>
  </ng-container>

  <!-- Total -->
  <ng-container *ngIf="columns.includes('total')">
    <ng-container *grecoTableCol="'Total'; let purchase; alignRight: true">
      <div
        *grecoLet="purchase | purchaseTotal as total"
        [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''"
      >
        <p>
          <strong>{{ total ? (total / 100 | currency) : '-' }} </strong>
        </p>
        <p *ngIf="purchase.total && purchase.total !== total" style="font-size: 75%">
          (Out of <strong>{{ purchase.total / 100 | currency }} </strong>)
        </p>
      </div>
    </ng-container>
  </ng-container>

  <!-- Balance -->
  <ng-container *ngIf="columns.includes('balance_used')">
    <ng-container *grecoTableCol="'Balance Used'; let purchase; alignRight: true">
      <p [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''">
        {{
          purchase.balanceUsed
            ? ($any([purchase.balanceUsed || 0, -1 * (purchase.balanceUsed || 0)] | max) / 100 | currency)
            : '-'
        }}
        <small *ngIf="0 > purchase.balanceUsed"> (Refunded) </small>
      </p>
    </ng-container>
  </ng-container>

  <!-- Amount Due -->
  <ng-container *ngIf="columns.includes('total') || columns.includes('balance_used')">
    <ng-container *grecoTableCol="'Amount Due'; let purchase; alignRight: true">
      <p
        *grecoLet="purchase.total as total"
        [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''"
      >
        {{
          (purchase.status === 'FAILED' ||
            purchase.status === 'PENDING' ||
            purchase.status === 'PROCESSING_PAYMENT' ||
            purchase.status === 'VOIDED') &&
          total
            ? ((total - $any([purchase.balanceUsed || 0, -1 * (purchase.balanceUsed || 0)] | max)) / 100 | currency)
            : '-'
        }}
      </p>
    </ng-container>
  </ng-container>

  <!-- Status -->
  <ng-container *ngIf="columns.includes('status')">
    <ng-container *grecoTableCol="'Status'; let purchase">
      <greco-purchase-status-chip [isAdminPage]="isAdminPage" [purchase]="purchase"></greco-purchase-status-chip>
    </ng-container>
  </ng-container>

  <!-- Balance Refunded -->
  <ng-container>
    <ng-container *grecoTableCol="'Refunded to Balance'; let purchase; alignRight: true">
      <p>
        {{ purchase.balanceRefundedAmount ? (purchase.balanceRefundedAmount / 100 | currency) : '-' }}
      </p>
    </ng-container>
  </ng-container>

  <!-- Payment Amount Refunded -->
  <ng-container>
    <ng-container *grecoTableCol="'Refunded to Payment Method'; let purchase; alignRight: true">
      <p>
        {{ purchase.payment?.refundedAmount ? (purchase.payment?.refundedAmount / 100 | currency) : '-' }}
      </p>
    </ng-container>
  </ng-container>

  <!-- Referred By -->
  <ng-container>
    <ng-container *grecoTableCol="'Referred By'; let purchase; alignRight: true">
      <p>
        {{
          purchase.referredBy
            ? purchase.referredBy.displayName
            : purchase.referredByEmail
            ? purchase.referredByEmail
            : '-'
        }}
      </p>
    </ng-container>
  </ng-container>

  <!-- More -->
  <ng-container *ngIf="columns.includes('admin_options')">
    <ng-container *grecoTableCol="''; let purchase; fitContent: true; stickyEnd: true">
      <greco-purchase-options
        [purchase]="purchase"
        [showOpenOption]="true"
        [isSuper]="permissions?.isSuper || false"
        [canVoid]="permissions?.canVoid || false"
        [canRetry]="permissions?.canRetry || false"
        [canManageSoldBy]="permissions?.canManageSoldBy || false"
        [canRefundToBalance]="permissions?.canRefundToBalance || false"
        [canManageReferredBy]="permissions?.canManageReferredBy || false"
        [canRefundToPaymentMethod]="permissions?.canRefundToPaymentMethod || false"
        (refresh)="refresh()"
      >
      </greco-purchase-options>
    </ng-container>
  </ng-container>

  <!-- User Options -->
  <ng-container *ngIf="columns.includes('user_options')">
    <ng-container *grecoTableCol="''; let purchase; fitContent: true; stickyEnd: true">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [disabled]="
          !(
            purchase.status === 'PAID' ||
            purchase.status === 'PARTIALLY_REFUNDED' ||
            purchase.status === 'FAILED' ||
            purchase.status === 'PAYMENT_DISPUTED' ||
            purchase.status === 'PAYMENT_DISPUTE_LOST'
          )
        "
        (click)="$event.stopImmediatePropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu>
        <button mat-menu-item *ngIf="purchase.status === 'FAILED'" (click)="retryFailedPurchase(purchase)">
          <mat-icon>refresh</mat-icon>
          <span>Retry Payment</span>
        </button>
        <button
          *ngIf="
            purchase.status === 'PAID' ||
            purchase.status === 'PARTIALLY_REFUNDED' ||
            purchase.status === 'PAYMENT_DISPUTED' ||
            purchase.status === 'PAYMENT_DISPUTE_LOST'
          "
          mat-menu-item
          (click)="sendAdhocEmailReceipt(purchase)"
        >
          <mat-icon>forward_to_inbox</mat-icon>
          <span>Send Receipt</span>
        </button>
      </mat-menu>
    </ng-container>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="currentPagination?.totalItems"
  showFirstLastButtons
  [length]="currentPagination!.totalItems || 0"
  [pageSize]="currentPagination!.itemsPerPage || pageSizes[0]"
  [pageSizeOptions]="pageSizes"
  (page)="paginatedParams$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
