import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Booking, BookingOption, Calendar, CalendarEvent, EventStatus, isAvailableNow } from '@greco/booking-events';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { BookingOptionService, BookingService, CalendarService, EventService } from '@greco/ngx-booking-events';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder, SFields } from '@nestjsx/crud-request';
import moment from 'moment';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'greco-admin-event-dialog-body',
  templateUrl: './admin-event-dialog-body.component.html',
  styleUrls: ['./admin-event-dialog-body.component.scss'],
})
export class AdminEventDialogBodyComponent {
  constructor(
    private bookingOptSvc: BookingOptionService,
    private bookingSvc: BookingService,
    private contactSvc: ContactService,
    private eventSvc: EventService,
    private userSvc: UserService,
    private router: Router,
    private calendarSvc: CalendarService
  ) {}

  @Output() status = new EventEmitter<Booking[] | undefined>();

  @PropertyListener('selectedCalendar') selectedCalendar$ = new BehaviorSubject<Calendar | null>(null);
  selectedCalendar: Calendar | null = null;
  loading = true;

  private _contact$ = new BehaviorSubject<Contact | null>(null);
  @Input() get contact() {
    return this._contact$.value;
  }
  set contact(contact) {
    this._contact$.next(contact);
  }

  private _user$ = new BehaviorSubject<User | null>(null);
  @Input() get user() {
    return this._user$.value;
  }
  set user(user) {
    this._user$.next(user);
  }

  readonly userCommunities$ = this.userSvc.user$.pipe(
    switchMap(async user => (user ? await this.contactSvc.getUserContacts(user.id) : [])),
    map(contacts => contacts.map(cnt => cnt.community).map(com => com.id)),
    shareReplay(1)
  );

  readonly filters$ = new ReplaySubject<RequestQueryBuilder>(1);
  readonly dateRange$ = new ReplaySubject<[Date, Date]>(1);

  readonly bookings$ = combineLatest([this.dateRange$, this._user$]).pipe(
    switchMap(async ([[startDate, endDate], user]) => {
      if (!startDate || !endDate || !user) return [];
      const start = moment(startDate).startOf('day').toDate();
      return await this.bookingSvc.getByDate({ endDate, startDate: start, userId: user.id });
    })
  );

  readonly bookingOptions$ = this._user$.pipe(
    switchMap(async user =>
      user
        ? await this.bookingOptSvc.getUserBookingOptions(user.id)
        : await this.bookingOptSvc.getBookingOptionsAvailableToEveryone()
    ),
    shareReplay(1)
  );

  readonly userWaitlist$ = this._user$.pipe(
    switchMap(async user => (user ? await this.bookingSvc.getUserWaitlist(user.id) : []))
  );

  readonly userCalendars$ = this.userCommunities$.pipe(
    switchMap(async communities => {
      return (await this.calendarSvc.getMany(communities))?.filter(calendar => !calendar.private) || [];
    }),
    tap(calendars => {
      if (!this.selectedCalendar && calendars?.length) {
        this.selectedCalendar = calendars[0] || null;
      }
    })
  );

  readonly events$ = combineLatest([
    this.dateRange$,
    this.filters$,
    this.bookingOptions$,
    this.userCommunities$,
    this.selectedCalendar$,
  ]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(250),
    switchMap(async ([[startDate, endDate], filters, options, userCommunities, selectedCalendar]) => {
      const searchObj = JSON.parse(filters.queryObject.s);

      const tagsCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['tags.id']);
      const resourcesCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['resources.id']);

      this.router.navigate([], {
        queryParamsHandling: 'merge',
        queryParams: {
          trainers: resourcesCondition?.['resources.id']?.$in ? resourcesCondition?.['resources.id']?.$in : '',
          tags: tagsCondition?.['tags.id']?.$in ? tagsCondition?.['tags.id']?.$in : '',
        },
      });

      const events = await this.eventSvc.getEventsByDate({
        calendars: selectedCalendar ? [selectedCalendar.id] : [],
        communities: userCommunities || ['com_greco'],
        resources: resourcesCondition?.['resources.id']?.$in,
        endDate: moment(endDate).endOf('day').toDate(),
        tags: tagsCondition?.['tags.id']?.$in,
        statuses: [EventStatus.ACTIVE],
        includePrivate: false,
        includeCourse: true,
        showOnlyCourseInstances: true,
        startDate,
      });

      return events
        .reduce((acc, event) => {
          const eventTags = event.tags.map(tag => tag.id);
          const eventBookingOptions = options.filter(opt =>
            opt.tags.some(tag => eventTags.includes(tag.id))
          ) as (BookingOption & { userPerks: { hasUnlimited: boolean } })[];

          const bookingOption = eventBookingOptions.sort((a, b) => {
            const aAvailable = isAvailableNow(event, a);
            const bAvailable = isAvailableNow(event, b);

            if (aAvailable !== bAvailable) return aAvailable ? -1 : 1;

            // 1. Free before cost
            if (!!a.price !== !!b.price) return a.price ? 1 : -1;

            // 2. Less costly
            if (a.price && b.price) return a.price - b.price;

            // 3. Unlimited before consumable
            if (a.userPerks.hasUnlimited !== b.userPerks.hasUnlimited) {
              return a.userPerks.hasUnlimited ? -1 : 1;
            }

            return a.title.localeCompare(b.title);
          })[0];

          const cutoff = moment(event.startDate).subtract(event.checkInWindow, 'minutes');
          if (bookingOption && moment().isBefore(cutoff)) acc.push({ event, bookingOption });

          return acc;
        }, [] as { event: CalendarEvent; bookingOption: BookingOption }[])
        .filter(e => !e.event.private)
        .sort((a, b) => a.event.startDate.getTime() - b.event.startDate.getTime());
    }),
    tap(() => (this.loading = false))
  );

  readonly requiredData$ = combineLatest([this.events$, this.bookings$, this.userWaitlist$]);

  dayClicked(date: Date) {
    const elementId = moment(date).format('YYYYMMDD');
    const scrollElement = document.getElementById(elementId);
    if (scrollElement) scrollElement.scrollIntoView({ behavior: 'smooth' });
  }

  selectCalendar(calendar: Calendar) {
    if (!this.loading) {
      this.selectedCalendar = calendar;
    }
  }

  getStatus(event: Booking[] | undefined) {
    this.status.emit(event);
  }
}
