<greco-table #stationssTable [highlight]="true">
  <mat-icon *grecoTableCol="''; fitContent: true; let station" [matTooltip]="station.id">place</mat-icon>
  <p *grecoTableCol="'Title'; let station">
    {{ station.title ? station.title : 'Unknown Title' }}
  </p>

  <ng-container *grecoTableCol="''; let resource; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <button mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>Edit Title</span>
      </button>
      <button type="button" mat-menu-item>
        <mat-icon>gavel</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
  </ng-container>
</greco-table>
