import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CommunityVideoUnlockOption } from '@greco/community-videos';
import { EventVideoUnlock } from '@greco/event-videos';
import { UserPerk } from '@greco/sales-perks';
import { VideoUnlock } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationOptions as IIPaginationOptions } from 'nestjs-typeorm-paginate';

type IPaginationOptions = Omit<IIPaginationOptions, 'route'>;

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}

@Injectable({ providedIn: 'root' })
export class CommunityVideoUnlockService {
  constructor(private http: HttpClient) {}

  private baseUrl = '/api/community-videos-unlock';

  // @Get('video-unlocks')
  async getCommunityVideoUnlocks(
    query: RequestQueryBuilder,
    communityVideoId: string,
    showExpired: boolean,
    pagination?: IPaginationOptions
  ): Promise<PaginatedDto<VideoUnlock>> {
    return await toPromise(
      this.http.get<PaginatedDto<VideoUnlock>>(`${this.baseUrl}/video-unlocks`, {
        params: {
          ...query.queryObject,
          communityVideoId,
          ...paginationQueryParams(pagination),
          showExpired: showExpired ? 'true' : 'false',
        },
      })
    );
  }
  adminGrantCommunityVideoToUser(userEmail: string, communityVideoId: string, expiryDate: Date | null) {
    return toPromise(
      this.http.post<EventVideoUnlock>(`${this.baseUrl}/grant/${communityVideoId}`, {
        userEmail: userEmail,
        expiryDate: expiryDate,
      })
    );
  }
  // @Post('update-unlock/:id')
  updateUnlockTime(id: string, expiryDate: Date | null) {
    return toPromise(
      this.http.post<VideoUnlock>(`${this.baseUrl}/update-unlock/${id}`, {
        expiryDate: expiryDate,
      })
    );
  }

  async getUserUnlockOptions(userId?: string, videoTagIds?: string[]) {
    const options = (
      await toPromise(
        this.http.get<UserPerk[]>(`${this.baseUrl}/user-unlock-options`, {
          params: {
            videoTagIds: videoTagIds?.join(',') || '',
            ...(userId && { userId }),
          },
        })
      )
    )
      .sort((a, b) => {
        const optionA = a.perk as CommunityVideoUnlockOption;
        const optionB = b.perk as CommunityVideoUnlockOption;
        return optionA.price - optionB.price;
      })
      .sort((a, b) => Number(b.consumable) - Number(a.consumable));
    return options;
  }
}
