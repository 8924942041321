import { Component, Input, OnDestroy } from '@angular/core';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import {
  RecurrencePeriod,
  Subscription,
  SubscriptionResource,
  SubscriptionResourceAction,
} from '@greco/sales-subscriptions';
import { CollapsibleSectionController, INITIAL_COLLAPSIBLE_SECTION_EXPANDED } from '@greco/ui-collapsible-section';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-subscription',
  templateUrl: './subscription.page.html',
  styleUrls: ['./subscription.page.scss'],
  viewProviders: [CollapsibleSectionController, { provide: INITIAL_COLLAPSIBLE_SECTION_EXPANDED, useValue: 1 }],
})
export class SubscriptionPage implements OnDestroy {
  constructor(
    private securitySvc: SecurityService,
    private comSecSvc: CommunitySecurityService,
    private subscriptionSvc: SubscriptionsService
  ) {}

  readonly _PERIODS = {
    [RecurrencePeriod.Daily]: 'day',
    [RecurrencePeriod.Monthly]: 'month',
    [RecurrencePeriod.Weekly]: 'week',
    [RecurrencePeriod.Yearly]: 'year',
  };

  @PropertyListener('communityId') private communityId$ = new BehaviorSubject<string>('');
  @Input() communityId?: string;

  @PropertyListener('subscription') private subscription$ = new ReplaySubject<Subscription>(1);
  @Input() subscription?: Subscription;

  @Input() hideLifetimeValue = false;

  ltv$ = this.subscription$.pipe(
    switchMap(async subscription => (subscription ? await this.subscriptionSvc.getSubscriptionLtv(subscription.id) : 0))
  );

  canViewPurchaseDetails$ = this.communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.comSecSvc.hasAccess(communityId, PurchaseResource.key, PurchaseResourceAction.READ)
        : false
    ),
    shareReplay(1)
  );

  canCancel$ = this.subscription$.pipe(
    switchMap(async subscription =>
      subscription
        ? await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.CANCEL, {
            accountId: subscription.accountId,
          })
        : false
    ),
    catchError(() => of(false)),
    shareReplay(1)
  );

  canUpdate$ = this.subscription$.pipe(
    switchMap(async subscription =>
      subscription
        ? await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.UPDATE, {
            accountId: subscription.accountId,
          })
        : false
    ),
    catchError(() => of(false)),
    shareReplay(1)
  );

  canFreeze$ = this.subscription$.pipe(
    switchMap(async subscription =>
      subscription
        ? await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.FREEZE, {
            accountId: subscription.accountId,
          })
        : false
    ),
    catchError(() => of(false)),
    shareReplay(1)
  );

  async refresh() {
    if (this.subscription) this.subscription = await this.subscriptionSvc.getSubscription(this.subscription.id);
  }

  ngOnDestroy() {
    this.subscription$.complete();
  }
}
