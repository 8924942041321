<greco-alert
  [config]="{
    type: $any('PRIMARY'),
    title: booking.event.title + ' (' + (booking.event.startDate | date : 'MMM d, y, h:mm a') + ')',
    description: 'Please check in for this booking'
  }"
>
  <div slot="buttons" style="display: flex; flex-direction: row; gap: 8px">
    <button mat-stroked-button color="primary" style="background-color: white" (click)="noShow()" [disabled]="loading">
      No Show
    </button>

    <button
      mat-stroked-button
      color="primary"
      style="background-color: white"
      [matMenuTriggerFor]="cancelMenu"
      [disabled]="loading"
    >
      Cancel
    </button>

    <mat-menu #cancelMenu>
      <button mat-menu-item (click)="cancel(false)" [disabled]="loading">Cancel</button>
      <button mat-menu-item (click)="cancel(true)" [disabled]="loading">Cancel (Free Of Charge)</button>
    </mat-menu>

    <button mat-flat-button color="primary" [disabled]="loading" (click)="confirm()">Check In</button>
  </div>
</greco-alert>
