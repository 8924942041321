<ng-container *grecoLet="checkIns$ | async as checkIns">
  <greco-table *ngIf="checkIns" [data]="checkIns.items">
    <p *grecoTableCol="'Station'; let checkIn">
      {{ checkIn.station.title }}
    </p>

    <p *grecoTableCol="'Time'; let checkIn">
      {{ checkIn.timestamp | date : 'mediumDate' }}
      <span style="color: grey">@ {{ checkIn.timestamp | date : 'shortTime' }}</span>
    </p>
  </greco-table>

  <mat-paginator
    *ngIf="checkIns?.meta?.totalItems"
    showFirstLastButtons
    [pageSizeOptions]="[5, 20, 50]"
    [length]="(checkIns?.meta)!.totalItems || 0"
    [pageSize]="checkIns?.meta?.itemsPerPage || 5"
    (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  ></mat-paginator>
</ng-container>
