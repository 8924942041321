<div
  style="max-width: 100%"
  [style.padding-left]="note.repliedToId ? '16px' : '0px'"
  [style.padding-top]="note.repliedToId && !first ? '8px' : '0px'"
  [style.padding-bottom]="note.repliedToId ? '4px' : '0px'"
  [style.border-bottom]="note.repliedToId && !last ? '2px dashed lightgray' : 'none'"
  *grecoLet="localUser$ | async as localUser"
>
  <div
    style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 4px"
    *grecoLet="isSuper$ | async as isSuper"
  >
    <div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
      <greco-user-avatar
        [user]="note.writtenBy!"
        size="small"
        [defaultToCurrentUser]="false"
        [showOnlineStatus]="false"
      ></greco-user-avatar>
      <div style="display: flex; flex-direction: column">
        <span style="font-weight: bold"
          >{{
            localUser?.id === note.writtenBy?.id ? 'You' : note.writtenById ? note.writtenBy?.displayName : 'System'
          }}

          <small *ngIf="note.edited && !readonly">
            <a style="font-weight: normal; cursor: pointer; color: var(--info-color)" (click)="openNoteHistory()">
              (Edited)
            </a>
          </small>
          <ng-content select="[writtenBy]"></ng-content>
        </span>
        <small>{{ note.writtenOn | date : 'short' }}</small>
      </div>
    </div>
    <div *ngIf="!readonly" style="display: flex; flex-direction: row; align-items: center">
      <mat-icon *ngIf="note.pinned">push_pin</mat-icon>
      <button style="margin-right: -8px" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

    <mat-menu #menu>
      <button *ngIf="note.writtenBy?.id === localUser?.id" mat-menu-item (click)="edit()">Edit</button>
      <button *ngIf="!note.repliedToId" mat-menu-item (click)="update({ pinned: !note.pinned })">
        {{ note.pinned ? 'Unpin' : 'Pin' }}
      </button>
      <button
        *ngIf="!note.repliedToId && (isSuper || note.writtenBy?.id === localUser?.id)"
        mat-menu-item
        (click)="update({ admin: !note.admin })"
      >
        {{ note.admin ? 'Set as member note' : 'Set as admin note' }}
      </button>
      <button mat-menu-item (click)="delete()" *ngIf="canDeleteNotes">Delete</button>
    </mat-menu>
  </div>

  <p style="padding: 0; margin: 0; margin-bottom: 8px">{{ note.content }}</p>

  <div
    *ngIf="note.replies?.length && showReplies"
    style="
      margin-left: 4px;
      border-left: 2px dashed lightgray;
      border-bottom: 2px dashed lightgray;
      border-bottom-left-radius: 8px;
      padding-top: 4px;
      margin-bottom: 4px;
    "
  >
    <greco-contact-note
      *ngFor="let reply of note.replies; let index = index; let last = last; let first = first"
      [note]="reply"
      [last]="last"
      [first]="first"
      [canDeleteNotes]="canDeleteNotes"
      (changed)="replyChanged($event, index)"
    ></greco-contact-note>
  </div>
  <div
    *ngIf="!note.repliedToId && !readonly"
    style="display: flex; flex-direction: row; width: 100%; margin-left: -16px; margin-top: 8px"
  >
    <button mat-button color="primary" style="max-height: fit-content" (click)="reply()">Reply</button>

    <button
      *ngIf="note.replies?.length"
      mat-button
      color="primary"
      style="max-height: fit-content"
      (click)="showReplies = !showReplies"
    >
      {{ showReplies ? 'Hide replies' : 'Show replies' }}
    </button>
  </div>
</div>
