import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import {
  CommunityVideo,
  CommunityVideoSecurityResource,
  CommunityVideoSecurityResourceAction,
} from '@greco/community-videos';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { UpdateVideoDto } from '@greco/videos';
import { ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { VideoUploadDialog } from '../../dialogs';
import { CommunityVideosService } from '../../services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-community-video-admin-page',
  templateUrl: './community-video.page.html',
  styleUrls: ['./community-video.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CommunityVideoAdminPage {
  @PropertyListener('communityVideo') communityVideo$ = new ReplaySubject<CommunityVideo>(1);
  @Input() communityVideo!: CommunityVideo;

  @Input() showRemove = true;
  @Input() showChangeStatus = true;
  @Input() showArchive = true;
  @Input() showUpdate = true;
  @Input() showStatus = true;

  constructor(
    private communityVideosSvc: CommunityVideosService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private comSecSvc: CommunitySecurityService,
    private userSvc: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  readonly canRead$ = this.communityVideo$.pipe(
    switchMap(async communityVideo => {
      return communityVideo
        ? await this.comSecSvc.hasAccess(
            communityVideo.communityId,
            CommunityVideoSecurityResource.key,
            CommunityVideoSecurityResourceAction.READ
          )
        : null;
    })
  );

  readonly canUpdate$ = this.communityVideo$.pipe(
    switchMap(async communityVideo => {
      return communityVideo
        ? await this.comSecSvc.hasAccess(
            communityVideo.communityId,
            CommunityVideoSecurityResource.key,
            CommunityVideoSecurityResourceAction.UPDATE
          )
        : null;
    })
  );

  readonly canArchive$ = this.communityVideo$.pipe(
    switchMap(async communityVideo => {
      return communityVideo
        ? await this.comSecSvc.hasAccess(
            communityVideo.communityId,
            CommunityVideoSecurityResource.key,
            CommunityVideoSecurityResourceAction.ARCHIVE
          )
        : null;
    })
  );
  readonly canDelete$ = this.communityVideo$.pipe(
    switchMap(async communityVideo => {
      return communityVideo
        ? await this.comSecSvc.hasAccess(
            communityVideo.communityId,
            CommunityVideoSecurityResource.key,
            CommunityVideoSecurityResourceAction.REMOVE
          )
        : null;
    })
  );

  async update(communityVideo: CommunityVideo) {
    //open update dialog for video
    const dialog = this.matDialog.open(VideoUploadDialog, { data: {}, width: '100%', maxWidth: '400px' });
    const instance = dialog.componentInstance;
    instance.video = communityVideo;
    instance.communityId = communityVideo.communityId;
    instance.hideAvailableOnInput = true;
    const response = await toPromise(dialog.afterClosed());
    if (response.dto) {
      const dto: UpdateVideoDto = {
        title: response.dto.title || null,
        description: response.dto.description || null,
        videoTagIds: response.dto.videoTagIds || null,
      };
      const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '400px' });
      spinnerDialog.disableClose = true;
      try {
        const response = await this.communityVideosSvc.update(communityVideo.id, dto);
        if (response) {
          this.communityVideo = response;
        }
      } catch (err) {
        console.error(err);
      }
      spinnerDialog.close();
    }
  }
  async remove(communityVideo: CommunityVideo) {
    //open remove dialog for video and if resolves remove
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Removal',
        //subtitle: 'Are you sure you want to remove this video?',
        content: 'This will remove the video from the community, but not the platform or Vimeo',
        buttons: [
          { label: "No, Don't Remove", role: 'no' },
          { label: 'Yes, Remove Video', role: 'yes' },
        ],
      } as DialogData,
      width: '100%',
      maxWidth: '400px',
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '400px' });
      spinnerDialog.disableClose = true;
      try {
        const response = await this.communityVideosSvc.remove(communityVideo.id);
        spinnerDialog.close();
        if (!response) {
          this.snacks.open('Video removed from community', '', { duration: 2500, panelClass: 'mat-primary' });
          await this.router.navigate(['..'], { relativeTo: this.route });
        } else {
          this.communityVideo = response;
          this.snacks.open('Cannot remove. Archiving...', '', { duration: 2500, panelClass: 'mat-warn' });
        }
      } catch (err) {
        spinnerDialog.close();
        console.error(err);
      }
    }
  }

  async changeStatus(video: CommunityVideo) {
    //open  dialog for video and if resolves update status
    const isActive = !video.availableUntil || video.availableUntil.getTime() > Date.now();

    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm ' + (!isActive ? 'Activate' : 'Archive'),
        //subtitle: 'Are you sure you want to remove this video?',
        content: !isActive
          ? 'This video will be visible for unlock by users with the appropriate perks'
          : 'This video will not be visible for unlock, but will remain visible if already unlocked',
        buttons: [
          { label: "No, Don't Update Status", role: 'no' },
          { label: 'Yes, Update Status', role: 'yes' },
        ],
      } as DialogData,
      width: '600px',
      maxWidth: '90%',
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '400px' });
      spinnerDialog.disableClose = true;

      try {
        this.communityVideo = await this.communityVideosSvc.update(video.id, {
          availableUntil: isActive ? new Date() : null,
        });

        this.snacks.open('Status changed successfully', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err) {
        console.error(err);
        this.snacks.open('Oops, something went wrong. Please try again.', 'Ok', {
          duration: 5000,
          panelClass: 'mat-warn',
        });
      }

      spinnerDialog.close();
    }
  }
}
