import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { CommunityVideoUnlockOption } from '@greco/community-videos';
import { User } from '@greco/identity-users';
import { SignInComponent, UserService } from '@greco/ngx-identity-auth';
import { VideosService } from '@greco/ngx-videos';
import { PropertyListener } from '@greco/property-listener-util';
import { UserPerk } from '@greco/sales-perks';
import { CollectionVideo } from '@greco/video-library';
import { VideoUnlock, VideoWatchTime } from '@greco/videos';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-collection-video-item',
  templateUrl: './collection-video-item.component.html',
  styleUrls: ['./collection-video-item.component.scss'],
})
export class CollectionVideoItemComponent implements OnInit {
  @PropertyListener('collectionVideo') private _collectionVideo$ = new BehaviorSubject<CollectionVideo | null>(null);
  @Input() collectionVideo!: CollectionVideo;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private videoSvc: VideosService,
    private matDialog: MatDialog,
    private userService: UserService
  ) {}
  @PropertyListener('options') private _options$ = new BehaviorSubject<UserPerk[] | null>(null);
  @Input() options!: UserPerk[];
  @Input() videoUnlock!: VideoUnlock;
  @Input() watchTime!: VideoWatchTime | undefined;
  @Input() user?: User | null;
  label = 'Unlock';

  readonly validOptions$ = combineLatest([this._options$, this._collectionVideo$]).pipe(
    switchMap(async ([options, collectionVideo]) => {
      if (options?.length && collectionVideo) {
        return options.filter(perk => {
          const option = perk.perk as CommunityVideoUnlockOption;
          let valid = false;
          collectionVideo?.communityVideo?.video?.tags?.forEach(vTag => {
            if (option.videoTags.some(tag => tag.id === vTag.id)) valid = true;
          });
          return valid;
        });
      } else return [];
    })
  );

  async unlock() {
    let user = await toPromise(this.userService.isSignedIn$());
    if (!user) {
      await this.signIn();
      user = await toPromise(this.userService.isSignedIn$());
    }
    if (user) {
      await this.router.navigate([this.collectionVideo.id], { relativeTo: this.route });
    }
  }

  async ngOnInit() {
    for (const perk of this.options) {
      if (!(perk.perk as CommunityVideoUnlockOption).price && !perk.consumable) {
        this.label = 'Watch';
        break;
      }
    }
    if (this.videoUnlock) this.label = 'Watch';
    if (!this.user) this.label = 'Sign In';
    /*
    if (this.eventVideo.communityVideo) {
      const unlock = await this.videoSvc.hasActiveVideoUnlock(this.eventVideo.communityVideo?.videoId);
      if (unlock) this.label = 'Watch';
    }
    */
  }

  async signIn() {
    const _dialog = this.matDialog.open(SignInComponent, { data: {}, width: '100%', maxWidth: '400px' });
    await toPromise(_dialog.afterClosed());
  }
}
