import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@greco/identity-users';
import { CommunityVideoUnlockService } from '@greco/ngx-community-videos';
import { UserService } from '@greco/ngx-identity-auth';
import { WatchVideoComponent } from '@greco/ngx-videos';
import { PropertyListener } from '@greco/property-listener-util';
import { CollectionVideo } from '@greco/video-library';
import { VideoUnlock } from '@greco/videos';
import moment from 'moment';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { CollectionVideoService, CollectionVideoUnlockService } from '../../../services';

@Component({
  selector: 'greco-video-unlock-info',
  templateUrl: './video-unlock-info.component.html',
  styleUrls: ['./video-unlock-info.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class VideoUnlockInfoComponent {
  constructor(
    private eventVideoSvc: CollectionVideoService,
    private matDialog: MatDialog,
    private userSvc: UserService,
    private unlockSvc: CollectionVideoUnlockService,
    private comUnlockSvc: CommunityVideoUnlockService
  ) {}

  currentUserId$ = this.userSvc.getUserId();

  @Output() unlocked = new EventEmitter<VideoUnlock>();

  @PropertyListener('user') private user$ = new ReplaySubject<User>(1);
  @Input() user!: User;

  @PropertyListener('collectionVideo') private collectionVideo$ = new ReplaySubject<CollectionVideo>(1);
  @Input() collectionVideo!: CollectionVideo;

  @PropertyListener('activeUnlock') private activeUnlock$ = new BehaviorSubject<VideoUnlock | null>(null);
  @Input() activeUnlock?: VideoUnlock;

  @Input() selectedPerkRentalPeriod?: Date | null;

  @Input() disabled = true;

  @Input() expanded = true;

  @Input() canMakeChange = false;

  readonly _expiresIn$ = this.activeUnlock$.pipe(
    map(unlock => (unlock && unlock.expiresOn ? moment(unlock.expiresOn).fromNow() : null))
  );

  userPerks$ = combineLatest([this.collectionVideo$, this.user$]).pipe(
    switchMap(async ([collectionVideo, user]) => {
      if (!collectionVideo?.collection || !user) return [];

      const video = collectionVideo.communityVideo?.video;
      if (!video) return [];

      const userPerks = await this.comUnlockSvc.getUserUnlockOptions(
        user?.id,
        video.tags?.map(t => t.id)
      );

      return userPerks;
    }),
    shareReplay(1)
  );

  async watch() {
    if (this.collectionVideo?.communityVideo?.video && this.activeUnlock) {
      const dialog = this.matDialog.open(WatchVideoComponent, { data: {}, width: '750px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.video = this.collectionVideo.communityVideo.video;
    }
  }
}
