import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Perk, UserPerk } from '@greco/sales-perks';
import moment from 'moment';
import { PerkService } from '../../services';

function isIntValidator(control: AbstractControl) {
  if (!control.value || Number.isInteger(control.value)) {
    return null;
  }
  return { isInt: 'Value entered is not an Integer' };
}
@Component({
  selector: 'greco-remove-perk-dialog',
  templateUrl: './remove-perk.dialog.html',
  styleUrls: ['./remove-perk.dialog.scss'],
})
export class RemovePerkDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      userId: string;
      perk: Perk;
      expiry?: string;
      expiryNotification?: string;
      reusable?: boolean;
      frozen?: boolean;
    },
    private dialogRef: MatDialogRef<RemovePerkDialog>,
    private perkSvc: PerkService,
    private formBuilder: FormBuilder
  ) {}

  submitting = false;

  userPerks: UserPerk[] = [];

  form = this.formBuilder.group({
    perksToRemove: [null, [Validators.min(1), isIntValidator, Validators.required]],
  });

  async ngOnInit() {
    const userPerks = (await this.perkSvc.getUserPerks(this.data.userId, { includeFrozen: true })).filter(userPerk => {
      if (userPerk.perk.id !== this.data.perk.id) return false;
      if (typeof this.data.reusable === 'boolean' && userPerk.consumable !== !this.data.reusable) return false;
      if (this.data.expiry === 'No Expiry' && userPerk.expiryDate) return false;
      else if (
        this.data.expiry !== 'No Expiry' &&
        typeof this.data.expiry === 'string' &&
        this.data.expiry !== moment(userPerk.expiryDate).format('MMM D, yyyy').toString()
      ) {
        return false;
      }

      if (this.data.expiryNotification === '-' && userPerk.expiryNotificationDate) return false;
      if (
        this.data.expiryNotification !== '-' &&
        typeof this.data.expiryNotification === 'string' &&
        this.data.expiryNotification !== moment(userPerk.expiryNotificationDate).format('MMM D, yyyy').toString()
      ) {
        return false;
      }

      if (this.data.frozen && !userPerk.frozen) return false;
      else if (!this.data.frozen && userPerk.frozen) return false;

      return true;
    });

    if (!userPerks.length) return this.dialogRef.close();

    this.userPerks = userPerks.sort((a, b) => {
      if (!a.expiryDate !== !b.expiryDate) return a.expiryDate ? -1 : 1;
      return (a.expiryDate?.getTime() || 0) - (b.expiryDate?.getTime() || 0);
    });

    this.form
      .get('perksToRemove')
      ?.setValidators([Validators.min(1), isIntValidator, Validators.required, Validators.max(this.userPerks.length)]);
  }

  close() {
    this.dialogRef.close();
  }

  async submit() {
    this.submitting = true;

    try {
      const numberOfperksToRemove: number = this.form.value.perksToRemove;
      const perksToRemove = this.userPerks.slice(0, numberOfperksToRemove).map(userPerk => {
        return userPerk.id;
      });

      await this.perkSvc.removePerks(this.data.perk.id, this.data.userId, perksToRemove);

      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
    }

    this.submitting = false;
  }
}
