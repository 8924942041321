<div
  class="clickable"
  (click)="selected()"
  matTooltip="Go to program '{{ collection.title }}'"
  matTooltipPosition="above"
  *ngIf="collection && $any(collection).subcollections?.length && $any(collection).subcollections[0].videos?.length"
>
  <greco-box class="box">
    <div
      class="image-section"
      style="width: 100%; height: 250px; display: flex; flex-direction: column; justify-content: center"
    >
      <img
        style="object-fit: contain"
        [src]="
          collection.imageURL ||
            $any(collection).subcollections[0].videos[0].communityVideo?.video?.thumbnailUrl ||
            '/assets/lf3/logo.png' | safe : 'resourceUrl'
        "
        alt="Thumbnail not found"
        class="thumb"
      />
    </div>
    <div class="title-section">
      <div style="display: flex; flex-direction: row; gap: 1px">
        <mat-icon *ngIf="showFeaturedStar && collection.featured" style="font-size: 18px" [matTooltip]="'Featured'"
          >star</mat-icon
        >
        <mat-icon
          *ngIf="(isAvailable$ | async) === true"
          style="font-size: 18px"
          [matTooltip]="'You have a perk related to this collection'"
          >confirmation_number</mat-icon
        >
        <h4 style="margin-bottom: 0">
          <strong>{{ collection.title }}</strong>
        </h4>
      </div>
      <div style="display: flex; align-items: center; font-size: 14px">
        <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
          >people</mat-icon
        >
        <strong>{{ collection.community?.name }}</strong>
      </div>
    </div>
    <button mat-flat-button color="primary">View Program</button>
  </greco-box>
</div>
