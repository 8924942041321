import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Account } from '@greco/finance-accounts';
import { InitiateExportDialog } from '@greco/ngx-data-exports';
import { FilterBarComponent } from '@greco/ngx-filters';
import { CommunityService } from '@greco/ngx-identity-communities';
import { SaleCategoryPickerComponent } from '@greco/ngx-sales-purchases';
import { SecurityService } from '@greco/ngx-security-util';
import {
  AddonType,
  InventoryProductAddon,
  Product,
  ProductStatus,
  VariantResource,
  VariantResourceAction,
} from '@greco/sales-products';
import { SaleCategory } from '@greco/sales-purchases';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { ConfigureAddonDialog } from '../../dialogs';
import { AddonsService, InventoryService, ProductsService } from '../../services';
import { ProductSearchFilter } from '../products';

@Component({
  selector: 'greco-inventories-page',
  templateUrl: './inventories.page.html',
  styleUrls: ['./inventories.page.scss'],
})
export class InventoriesPage implements OnDestroy {
  constructor(
    private router: Router,
    private dialogs: MatDialog,
    private snacks: MatSnackBar,
    private route: ActivatedRoute,
    private productSvc: ProductsService,
    private communitySvc: CommunityService,
    private inventorySvc: InventoryService,
    private securitySvc: SecurityService,
    private addonSvc: AddonsService
  ) {}

  @ViewChild(FilterBarComponent) private filterBar?: FilterBarComponent;
  @ViewChild('saleCategoryPicker') saleCategoryPicker!: SaleCategoryPickerComponent;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() account!: Account;

  private _communityId$ = new BehaviorSubject<string | null>(null);
  @Input() get communityId() {
    return this._communityId$.value;
  }
  set communityId(communityId: string | null) {
    this._communityId$.next(communityId);
  }

  private accountId$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId ? (await this.communitySvc.getCommunity(communityId))?.financeAccountId : undefined;
    })
  );

  canReadInventories$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(VariantResource.key, VariantResourceAction.READ_INVENTORIES, { communityId })
        : false;
    })
  );

  canManageInventories$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(VariantResource.key, VariantResourceAction.MANAGE_INVENTORY, { communityId })
        : false;
    })
  );

  exporting = false;

  loading = true;
  active = ProductStatus.Active;

  pagination: null | IPaginationMeta = null;

  productsToActivate: Product[] = [];
  listIncludesInactiveProducts = false;

  filterOptions = [ProductSearchFilter];

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<Required<PaginatedQueryParams>>({ page: 1, limit: 10 });

  showUncategorized$ = new BehaviorSubject<boolean>(true);
  selectedCategories$ = new ReplaySubject<SaleCategory[]>(1);

  products$ = combineLatest([
    // this.sort$,
    this.filters$,
    this._communityId$,
    this.page$,
    this.selectedCategories$,
    this.showUncategorized$,
  ]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([filters, communityId, pagination, categories, showUncategorized]) => {
      const categoryIds = categories.map(category => category.id);
      return communityId
        ? await this.inventorySvc.paginateProducts(filters, communityId, pagination, categoryIds, showUncategorized)
        : null;
    }),
    tap(data => (this.pagination = data?.meta || null)),
    map(data => data?.items || []),
    tap(data =>
      data?.filter(product => product.status !== ProductStatus.Active)?.length
        ? (this.listIncludesInactiveProducts = true)
        : (this.listIncludesInactiveProducts = false)
    ),
    tap(() => (this.loading = false))
  );

  async openProduct(product: Product) {
    await this.router.navigate([product.id], { relativeTo: this.route });
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  ngOnDestroy() {
    this._communityId$.complete();
  }

  async exportInventories(communityId: string) {
    this.exporting = true;
    try {
      InitiateExportDialog.open(this.dialogs, {
        processorId: 'InventoryDataExportProcessor',
        initiateExport: () => {
          return this.inventorySvc.export(communityId);
        },
      });
    } catch (err) {
      console.error(err);
    }

    this.exporting = false;
  }

  refresh() {
    this.filters$.next(this.filters$.value);
  }

  async updateAddonStatus(productId: string, addonId: string, enabled: boolean) {
    // this.processing = true;
    try {
      await this.addonSvc.updateAddonStatus(productId, addonId, enabled);
      this.refresh();
    } catch (err) {
      console.error(err);
    }
    // this.processing = false;
  }

  async configureAddon(product: Product) {
    const dialog = this.dialogs.open(ConfigureAddonDialog, {
      width: '750px',
      maxWidth: '90%',
      data: { addon: { type: AddonType.Inventory }, productId: product.id, communityId: this.communityId },
    });

    await toPromise(dialog.afterClosed());
    this.refresh();
  }

  async updateAddon(product: Product, addon: InventoryProductAddon) {
    const dialog = this.dialogs.open(ConfigureAddonDialog, {
      width: '750px',
      maxWidth: '90%',
      data: { addon, productId: product.id, communityId: this.communityId },
    });

    await toPromise(dialog.afterClosed());
    this.refresh();
  }
}
