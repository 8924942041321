<greco-simple-dialog [data]="dialogData" style="width: 800px; max-width: 90%">
  <greco-contact-note [note]="note" [readonly]="true">
    <small writtenBy style="font-weight: normal; font-style: italic">(Current Version)</small>
  </greco-contact-note>
  <mat-divider></mat-divider>
  <greco-table [data]="versions" title="Note History">
    <p *grecoTableCol="'Authored Date'; let version; fitContent: true; stickyStart: true ">
      {{version.writtenOn | date: 'short'}}
    </p>
    <p *grecoTableCol="'Note'; let version" style="max-width: 100%; white-space: wrap">{{version.originalContent}}</p>
  </greco-table>
</greco-simple-dialog>

<!-- <div class="dialog-buttons">
  <button mat-button [disabled]="saveCalled" (click)="cancel()">Cancel</button>
  <button mat-button [disabled]="formGroup.invalid || saveCalled" (click)="update()">
    <mat-icon *ngIf="saveCalled" class="spin">loop</mat-icon> Save
  </button>
</div> -->
