<greco-alert
  [config]="{
    title: expired ? 'Payment Method Expired' : 'Payment Method Expiring Soon',
    type: expired ? $any('DANGER') : $any('WARN'),
    description: expired
      ? paymentMethod.label + ' is in use and expired'
      : paymentMethod.label + ' is in use and expires on ' + paymentMethod.details
  }"
>
  <mat-icon
    slot="buttons"
    matTooltip="Please remove the card and update all billing information accordingly to avoid future issues"
    [class]="expired ? 'expired' : 'expiring'"
  >
    information
  </mat-icon>
</greco-alert>
