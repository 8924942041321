import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { CreateCommunityGiftCardConfigDto, UpdateCommunityGiftCardConfigDto } from '@greco/api-gift-cards';
import { CommunityGiftCardConfig } from '@greco/gift-cards';

@Injectable({ providedIn: 'root' })
export class GiftCardConfigService {
  constructor(private http: HttpClient) {}

  async getOne(communityId: string): Promise<CommunityGiftCardConfig | null> {
    return await toPromise(this.http.get<CommunityGiftCardConfig | null>(`/api/gift-cards/config/${communityId}`));
  }

  async getConfigsForShop(communityIds?: string[]) {
    return await toPromise(
      this.http.get<CommunityGiftCardConfig[]>(`/api/gift-cards/config`, {
        params: { ...(!!communityIds?.length && { communityIds: communityIds.join(',') }) },
      })
    );
  }

  async create(communityId: string, dto: CreateCommunityGiftCardConfigDto) {
    return await toPromise(this.http.post<CommunityGiftCardConfig>(`/api/gift-cards/config/${communityId}`, dto));
  }

  async update(communityId: string, dto: UpdateCommunityGiftCardConfigDto) {
    return await toPromise(this.http.patch<CommunityGiftCardConfig>(`/api/gift-cards/config/${communityId}`, dto));
  }
}
