import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { AddonDto, AddShopAddonDto } from '@greco/nestjs-sales-products';
import { PropertyListener } from '@greco/property-listener-util';
import { ShopProductAddon } from '@greco/sales-products';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VariantsService } from '../../../../services';

export type ShopAddonConfig = Omit<AddShopAddonDto, keyof AddonDto>;

@Component({
  selector: 'greco-shop-configuration-form',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: ShopFormComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: ShopFormComponent, multi: true },
  ],
})
export class ShopFormComponent implements OnDestroy, ControlValueAccessor, Validator {
  constructor(private formBuilder: FormBuilder, private variantSvc: VariantsService) {}

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  private listeners: Subscription[] = [];

  @PropertyListener('productId') private productId$ = new BehaviorSubject<string | null>(null);
  @Input() productId!: string;

  readonly formGroup = this.formBuilder.group({
    collections: [[], Validators.required],
    priority: [1, Validators.required],
    tags: [[]],
  });

  ngOnDestroy() {
    this.listeners.forEach(listener => listener.unsubscribe());
  }

  writeValue(value: ShopAddonConfig) {
    this.formGroup.setValue(
      {
        tags: value?.tags || [],
        collections: value?.collections || [],
        priority: value?.priority || 1,
      },
      { emitEvent: false }
    );
  }

  registerOnChange(fn: (value: ShopAddonConfig) => void) {
    this.listeners.push(this.formGroup.valueChanges.subscribe(value => fn(value)));
  }

  registerOnTouched(_fn: () => void): void {
    // console.log('TODO: registerOnTouched', fn);
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return this.formGroup.valid ? null : { required: true };
  }

  removeTag(tag: string) {
    this.formGroup.setValue({
      ...this.formGroup.value,
      tags: this.formGroup.value.tags.filter((t: string) => t !== tag),
    });
  }

  addTag(event: MatChipInputEvent) {
    event.input.value = '';
    this.formGroup.setValue({
      ...this.formGroup.value,
      tags: [...this.formGroup.value.tags, event.value],
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static GetConfig(addon?: ShopProductAddon): ShopAddonConfig {
    return {
      collections: (addon?.collections || []) as any,
      tags: addon?.tags || [],
      priority: addon?.priority || 1,
    };
  }
}
