import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    phoneNumber = '+1' + phoneNumber;

    const countryCodeStr = phoneNumber.slice(0, 2);
    const areaCodeStr = phoneNumber.slice(2, 5);
    const midSectionStr = phoneNumber.slice(5, 8);
    const lastSectionStr = phoneNumber.slice(8);

    return `${countryCodeStr} (${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
  }
}
