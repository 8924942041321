import { SecurityResource } from '@greco/security';

export const EVENT_VIDEOS_SECURITY_RESOURCE = 'event_videos';

export enum EventVideoSecurityResourceAction {
  READ = 'READ',
  UPLOAD = 'UPLOAD',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
}

export const EventVideoSecurityResource: SecurityResource = {
  key: EVENT_VIDEOS_SECURITY_RESOURCE,
  module: 'videos',

  title: 'Event Videos',
  description: 'Manage videos in an event',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: EventVideoSecurityResourceAction.READ,
      title: 'Read videos in events',
      description: 'Ability to read videos in events',
    },
    {
      key: EventVideoSecurityResourceAction.UPLOAD,
      title: 'Upload videos to events',
      description: 'Ability to upload videos to an event',
    },
    {
      key: EventVideoSecurityResourceAction.UPDATE,
      title: 'Update videos in events',
      description: 'Ability to update a video in an event',
    },
    {
      key: EventVideoSecurityResourceAction.REMOVE,
      title: 'Remove videos from events',
      description: 'Ability to remove videos from an event',
    },
  ],
};
