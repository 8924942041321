import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import {
  Subscription,
  SubscriptionActionType,
  SubscriptionCancellationAction,
  SubscriptionFreezePeriod,
  SubscriptionResource,
  SubscriptionResourceAction,
  SubscriptionStatus,
  SubscriptionUpdateAction,
} from '@greco/sales-subscriptions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  CancelSubscriptionDialog,
  ConfirmDialog,
  FreezeSubscriptionDialog,
  UpdateSubscriptionDialog,
} from '../../dialogs';
import { SubscriptionActionsService, SubscriptionFreezeService, SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-subscription-schedule-section',
  templateUrl: './schedule-section.component.html',
  styleUrls: ['./schedule-section.component.scss'],
})
export class SubscriptionScheduleSectionComponent implements OnDestroy {
  constructor(
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private comSecSvc: CommunitySecurityService,
    private actionSvc: SubscriptionActionsService,
    private subscriptionSvc: SubscriptionsService,
    private freezeSvc: SubscriptionFreezeService
  ) {}

  @Output() scheduledChanged = new EventEmitter<void>();

  @PropertyListener('subscription') private subscription$ = new ReplaySubject<Subscription | null>(1);
  @Input() subscription?: Subscription | null;

  @PropertyListener('communityId') private communityId$ = new ReplaySubject<string | null>(1);
  @Input() communityId?: string | null;

  @Input() canCancel?: boolean | null;

  @Input() canMakeChange?: boolean | null = false;

  @Input() canFreeze?: boolean | null = false;

  @Input() disabled = false;

  private refresh$ = new BehaviorSubject<null>(null);

  canViewDetails$ = this.communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.comSecSvc.hasAccess(communityId, PurchaseResource.key, PurchaseResourceAction.READ)
        : false
    ),
    shareReplay(1)
  );

  canUpdateSubscription$ = this.communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.comSecSvc.hasAccess(communityId, SubscriptionResource.key, SubscriptionResourceAction.UPDATE)
        : false
    ),
    shareReplay(1)
  );

  _loading = true;
  _paginationMeta?: IPaginationMeta;
  readonly pagination$ = new BehaviorSubject<PaginatedQueryParams>({ page: 1, limit: 5 });

  readonly actions$ = combineLatest([this.subscription$, this.pagination$, this.refresh$]).pipe(
    tap(() => (this._loading = true)),

    switchMap(async ([subscription, pagination]) =>
      subscription ? await this.actionSvc.paginateActions(subscription.id, new RequestQueryBuilder(), pagination) : null
    ),
    tap(data => (this._paginationMeta = data?.meta)),
    map(data => data?.items || []),
    map(actions =>
      actions.map(action => {
        const updatedAction = { ...action };
        if (action.type == SubscriptionActionType.UPDATE) {
          return {
            ...updatedAction,
            itemsDisplayTitle: (action as SubscriptionUpdateAction).items
              ?.map(item => item.displayName + ' - ' + item.description)
              .join(', '),
            prorate: (action as SubscriptionUpdateAction).proration,
          };
        }
        if (action.type == SubscriptionActionType.CANCELLATION) {
          return { ...updatedAction, prorate: (action as SubscriptionCancellationAction).proration };
        }
        return updatedAction;
      })
    ),
    tap(() => (this._loading = false))
  );

  canManuallyProcess$ = combineLatest([this.actions$, this.subscription$]).pipe(
    switchMap(async ([actions, subscription]) => {
      const user = await this.userSvc.getSelf();

      let actionLocked = false;
      actions.forEach(action => {
        if (action.lockedForProcessing) {
          actionLocked = true;
        }
      });

      if (user?.isSuperAdmin === false) {
        return false;
      }

      if (subscription?.lockedForProcessing || actionLocked) return true;
      else return false;
    })
  );

  currentFreezes$ = combineLatest([this.subscription$, this.refresh$]).pipe(
    switchMap(async ([subscription]) => {
      if (!subscription || subscription.status !== SubscriptionStatus.FROZEN) return [];

      const now = new Date().getTime();
      return (await this.freezeSvc.getAllFreezes(subscription.id)).filter(
        freeze => freeze.startDate.getTime() < now && (freeze.endDate === null || freeze.endDate.getTime() > now)
      );
    })
  );

  ngOnDestroy() {
    this.refresh$.complete();
    this.pagination$.complete();
  }

  async cancelSubscription() {
    if (!this.subscription) return;

    const dialog = this.matDialog.open(CancelSubscriptionDialog, { data: this.subscription });
    await toPromise(dialog.afterClosed());

    this.scheduledChanged.emit();
    this.refresh();
  }

  async updateSubscription() {
    if (!this.subscription) return;

    const dialog = this.matDialog.open(UpdateSubscriptionDialog, {
      data: { mode: 'staff', subscription: this.subscription },
    });
    dialog.componentInstance.communityId = this.communityId || undefined;
    await toPromise(dialog.afterClosed());

    this.scheduledChanged.emit();
    this.refresh();
  }

  async freezeSubscription() {
    if (!this.subscription) return;

    const dialog = this.matDialog.open(FreezeSubscriptionDialog, {
      data: { mode: 'staff', subscription: this.subscription, freeze: undefined },
    });
    dialog.componentInstance.communityId = this.communityId || undefined;
    await toPromise(dialog.afterClosed());

    this.scheduledChanged.emit();
    this.refresh();
  }

  async unfreezeSubscription(_currentFreezes: SubscriptionFreezePeriod[]) {
    if (!this.subscription) return;

    await this.freezeSvc.unfreeze(this.subscription.id);

    this.scheduledChanged.emit();
    this.refresh();
  }

  async forceProcessSubscription() {
    const dialog = this.matDialog.open(ConfirmDialog, {
      data: { title: 'Confirm processing', message: 'The processing is permanent and cannot be reverted.' },
    });
    const response = await toPromise(dialog.afterClosed());
    if (response === 'confirm') {
      if (this.subscription?.id) await this.subscriptionSvc.manualProcessing(this.subscription?.id);
      this.snacks
        .open('Processing started!', 'Ok', { duration: 3000 })
        .afterDismissed()
        .subscribe(() => window.location.reload());
    }
  }

  refresh() {
    this.refresh$.next(null);
  }
}
