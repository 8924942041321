<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup">
    <section>
      <!-- <h3 style="margin-bottom: 8px">Unlock Option Details</h3> -->

      <div style="display: flex; margin-top: -16px">
        <!-- Color 
        <div matPrefix style="margin-bottom: 8px; position: relative">
          <mat-form-field style="width: 48px" appearance="standard">
            <input #colorInput matInput type="color" required formControlName="color" />
            <mat-icon
              style="
                position: absolute;
                top: 12px;
                left: 4px;
                width: 100%;
                height: 100%;
                color: white;
                pointer-events: none;
              "
            >
              palette
            </mat-icon>
          </mat-form-field>
        </div>-->

        <!-- Title -->
        <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
          <mat-label>Title</mat-label>
          <input matInput required formControlName="title" />
        </mat-form-field>
      </div>
      <!-- Icon 
      <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
        <mat-label>Icon</mat-label>
        <div style="display: flex; flex-direction: row">
          <mat-icon>{{formGroup.value.icon}}</mat-icon>&nbsp;
          <mat-select matInput required formControlName="icon" disableOptionCentering panelClass="myPanelClass">
            <div class="wrapper">
              <mat-option value="ondemand_video"> <mat-icon>ondemand_video</mat-icon></mat-option>
              <mat-option value="replay"> <mat-icon>replay</mat-icon></mat-option>
              <mat-option value="videocam"> <mat-icon>videocam</mat-icon></mat-option>
              <mat-option value="video_library"> <mat-icon>video_library</mat-icon></mat-option>
              <mat-option value="music_video"> <mat-icon>music_video</mat-icon></mat-option>
              <mat-option value="camera_indoor"> <mat-icon>camera_indoor</mat-icon></mat-option>
              <mat-option value="slideshow"> <mat-icon>slideshow</mat-icon></mat-option>
              <mat-option value="movie"> <mat-icon>movie</mat-icon></mat-option>
              <mat-option value="camera_outdoor"> <mat-icon>camera_outdoor</mat-icon></mat-option>
            </div>
          </mat-select>
        </div>
      </mat-form-field>-->

      <!-- Description (Optional) -->
      <mat-form-field appearance="standard">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" rows="3"></textarea>
      </mat-form-field>

      <mat-form-field floatLabel="always" appearance="standard">
        <mat-label>Image</mat-label>
        <greco-image-upload formControlName="image"></greco-image-upload>
      </mat-form-field>
    </section>
  </form>
</greco-simple-dialog>
