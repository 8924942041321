import { SecurityResource } from '@greco/security';

export enum StationResourceAction {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const StationSecurityResource: SecurityResource = {
  key: 'identity_station',
  module: 'community',

  title: 'station',
  description: 'Community Stations',

  context: class {
    communityId: string;
  },

  actions: [
    { key: StationResourceAction.READ, title: 'Read', description: 'Ability to show stations' },
    { key: StationResourceAction.CREATE, title: 'Create', description: 'Ability to create stations' },
    { key: StationResourceAction.UPDATE, title: 'Update', description: "Ability to update a station's information" },
    { key: StationResourceAction.DELETE, title: 'Delete', description: 'Ability to remove a station' },
  ],
};
