<div *ngIf="canRead$ | async">
  <greco-collapsible-section id="unlocks_section" [header]="{ title: 'Unlocks', icon: 'lock_open' }" [expanded]="false">
    <button
      header
      mat-flat-button
      color="primary"
      style="margin-right: 8px"
      matTooltip="Create a new replay unlock"
      (click)="$event.stopImmediatePropagation(); grant()"
      *ngIf="canUpdate$ | async"
    >
      Grant
    </button>
    <div style="display: flex; flex-direction: row; width: 100%; padding-top: 1px">
      <greco-filter-bar
        style="width: 100%"
        [hideSeparator]="true"
        [filterOptions]="filterOptions"
        (changed)="filters$.next($event[1])"
      >
      </greco-filter-bar>
    </div>
    <greco-table [highlight]="true" [data]="(videoUnlocks$ | async) || []">
      <mat-icon *grecoTableCol="''; fitContent: true; let unlock" [matTooltip]="unlock.id">
        {{ unlock.expiresOn && unlock.expiresOn.getTime() < now ? 'lock' : 'lock_open' }}
      </mat-icon>

      <!-- User -->
      <ng-container>
        <a
          *grecoTableCol="'User'; let unlock"
          [routerLink]="['/admin/community', communityId, unlock.user?.id]"
          [matTooltip]="unlock.user?.contactEmail"
          (click)="$event.stopImmediatePropagation()"
        >
          {{ unlock.user.displayName }}
        </a>
      </ng-container>
      <!-- Unlock Date -->
      <ng-container>
        <p *grecoTableCol="'Unlock Date'; let unlock" [matTooltip]="'Date user unlocked'">
          {{ unlock.created | date }}
        </p>
      </ng-container>

      <!-- Expiry Date -->
      <ng-container>
        <p *grecoTableCol="'Expiry Date'; let unlock" [matTooltip]="'Date unlock expires for user'">
          {{ unlock.expiresOn ? (unlock.expiresOn | date) : 'Never' }}
        </p>
      </ng-container>

      <!-- Type -->
      <ng-container>
        <p *grecoTableCol="'Unlocked/Granted Via'; let unlock" [matTooltip]="'Type of unlock'">
          {{
            unlock.eventVideo
              ? 'Events'
              : unlock.programVideo
              ? 'Programs'
              : unlock.communityVideo
              ? 'Community'
              : 'Video'
          }}
        </p>
      </ng-container>

      <!-- Unlocked With -->
      <ng-container>
        <p *grecoTableCol="'Unlocked With'; let unlock">
          <greco-perk-badge
            *ngIf="unlock.unlockOption"
            [small]="true"
            [icon]="unlock.unlockOption.badge.icon"
            [color]="unlock.unlockOption.badge.color"
            [matTooltip]="unlock.unlockOption.title"
          ></greco-perk-badge>
          <a *ngIf="!unlock.unlockOption">Staff</a>
        </p>
      </ng-container>

      <!-- Status -->
      <ng-container>
        <p
          *grecoTableCol="'Status'; fitContent: true; let unlock"
          [matTooltip]="unlock.expiresOn && unlock.expiresOn < now ? 'Unlock is expired' : 'Unlock is active'"
        >
          <mat-chip [class.active]="!unlock.expiresOn || unlock.expiresOn > now">{{
            unlock.expiresOn && unlock.expiresOn < now ? 'Expired' : 'Unlocked'
          }}</mat-chip>
        </p>
      </ng-container>

      <!-- Options -->
      <ng-container *ngIf="canUpdate$ | async">
        <ng-container *grecoTableCol="''; let unlock; fitContent: true; stickyEnd: true">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matTooltip]="'Options for ' + unlock.user.displayName"
            (click)="$event.stopImmediatePropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <!-- Update Expiry Date -->
            <button mat-menu-item (click)="update(unlock)" *ngIf="canUpdate$ | async">
              <mat-icon>schedule</mat-icon>
              <span>Update Expiry Date</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </greco-table>
    <mat-paginator
      *ngIf="paginationMeta?.totalItems"
      showFirstLastButtons
      [length]="paginationMeta!.totalItems || 0"
      [pageSize]="paginationMeta!.itemsPerPage || 10"
      [pageSizeOptions]="[10, 20, 50]"
      (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
    ></mat-paginator>
  </greco-collapsible-section>
</div>
