import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Booking } from '@greco/booking-events';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';

@Component({
  selector: 'greco-new-booking',
  templateUrl: './new-booking.component.html',
  styleUrls: ['./new-booking.component.scss'],
})
export class NewBookingComponent {
  @Input() public data: { title: string; user: User; contact: Contact };

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: { title: string; user: User; contact: Contact },
    public dialogRef: MatDialogRef<NewBookingComponent>,
    private userSvc: UserService,
    private route: ActivatedRoute
  ) {
    this.data = {
      ...this.dialogData,
    };
  }

  closeDialog(status: Booking[] | undefined) {
    if (status?.length) {
      this.dialogRef.close(status);
    }
  }
}
