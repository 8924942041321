import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { EventsModule } from '@greco/ngx-booking-events';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { AuthModule } from '@greco/ngx-identity-auth';
import { LetModule } from '@greco/ngx-let-util';
import { CustomerSupportModule } from '@greco/ngx-platform-customer-support';
import { WelcomeFormResolver } from '@greco/ngx-platform-dashboard';
import { PromotionsModule } from '@greco/ngx-promotions';
import { GrecoRouterModule } from '@greco/ngx-routes-util';
import { ShopModule } from '@greco/ngx-shop';
import { TypeformModule } from '@greco/ngx-typeform';
import { AddressInputModule } from '@greco/ui-address-input';
import { PlatformLayoutModule } from '@greco/ui-platform-layout';
import { SmartImgModule } from '@greco/ui-smart-img';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { NgxMaskModule } from 'ngx-mask';
import { OnboardingFormComponent } from './components/onboarding-form/onboarding-form.component';
import { PlatformComponent } from './components/platform/platform.component';
import { OnboardingGuard } from './guards';
import { OnboardingPage } from './pages';
import { AgreementTempPage } from './pages/agreement-temp/agreement-temp.page';
import { PrivacyPage } from './pages/privacy/privacy.page';
import { ScheduleWrapperPage } from './pages/schedule-wrapper/schedule-wrapper.page';
import { TermsPage } from './pages/terms/terms.page';
import { routes } from './platform-root.routes';

export * from './components/platform/platform.component';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),

    // Angular Material
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,

    // Greco
    AuthModule,
    ShopModule,
    EventsModule,
    TypeformModule,
    PlatformLayoutModule,
    CustomerSupportModule,
    PromotionsModule,
    LetModule,
    GrecoRouterModule.forRoot(routes, { initialNavigation: 'enabled' }),
    UserAvatarModule,
    AddressInputModule,
    PaymentsModule,
    SmartImgModule,
  ],
  exports: [RouterModule],
  providers: [OnboardingGuard, WelcomeFormResolver],
  declarations: [
    PlatformComponent,
    AgreementTempPage,
    OnboardingFormComponent,
    ScheduleWrapperPage,
    OnboardingPage,
    TermsPage,
    PrivacyPage,
  ],
})
export class PlatformRootModule {}
