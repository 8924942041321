<div *grecoLet="_videos$ | async as data">
  <div style="margin-top: 16px" *ngIf="showHeader">
    <mat-divider></mat-divider>
    <div style="display: flex; flex-direction: row; gap: 8px; margin-top: 8px; margin-left: 24px">
      <mat-icon>{{ subcollection.icon || 'playlist_play' }}</mat-icon>
      <h2 class="title" style="white-space: nowrap">{{ subcollection.title }}</h2>
      <div class="video-count" style="display: flex; flex-direction: row; width: 100%; justify-content: flex-end">
        <mat-chip header>{{ total || 0 }} videos</mat-chip>
      </div>
    </div>
    <mat-divider style="margin-top: 8px"></mat-divider>
  </div>
  <p *ngIf="subcollection.description" style="margin-bottom: 24px">{{ subcollection.description }}</p>
  <div class="products-swiper">
    <swiper
      #SwiperComponent
      [slidesPerView]="'auto'"
      [spaceBetween]="16"
      [navigation]="true"
      [slidesOffsetBefore]="mobileView ? 16 : 32"
      [slidesOffsetAfter]="mobileView ? 16 : 32"
    >
      <ng-template swiperSlide *ngFor="let video of videos">
        <greco-collection-video-item
          [collectionVideo]="video"
          [videoUnlock]="$any(video).unlock"
          [watchTime]="$any(video).watchTime"
          (click)="$event.stopImmediatePropagation()"
          [options]="unlockOptions"
          [user]="user"
        ></greco-collection-video-item>
      </ng-template>
      <ng-template swiperSlide *ngIf="!loading && videos.length < total">
        <div class="box">
          <button
            mat-stroked-button
            color="primary"
            (click)="$event.stopImmediatePropagation(); loadMore()"
            style="position: absolute; top: 50%; left: 0; width: 100%"
          >
            Load More
          </button>
        </div>
      </ng-template>
      <ng-template swiperSlide *ngIf="loading">
        <div class="box">
          <mat-spinner style="position: absolute; top: 30%; left: 15%"></mat-spinner>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>
