import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AlertsModule } from '@greco/ngx-alerts';
import { EventsModule } from '@greco/ngx-booking-events';
import { ClipboardModule } from '@greco/ngx-clipboard-util';
import { FeatureCommunityAgreementsModule } from '@greco/ngx-community-agreements';
import { FiltersModule } from '@greco/ngx-filters';
import { InvoicesModule } from '@greco/ngx-finance-invoices';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { UsersModule } from '@greco/ngx-identity-users';
import { LetModule } from '@greco/ngx-let-util';
import { PerksModule } from '@greco/ngx-sales-perks';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { SubscriptionsModule } from '@greco/ngx-sales-subscriptions';
import { ShopModule } from '@greco/ngx-shop';
import { AddressInputModule } from '@greco/ui-address-input';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { SmartImgModule } from '@greco/ui-smart-img';
import { TableModule } from '@greco/ui-table';
import { TabsModule } from '@greco/ui-tabs';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { AccountLinkingModule } from '@greco/web-account-linking';
import { NgxMaskModule } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import {
  CheckInBookingAlertComponent,
  CheckInTableComponent,
  ExpiringPaymentMethodAlertComponent,
  MembersListComponent,
  NoProfilePictureCardComponent,
  StationCheckinListComponent,
  StationDetailsComponent,
  StationDetailsInputComponent,
  UnconfirmedBookingAlertComponent,
  UnsignedAgreementCardComponent,
} from './components';
import { AdminEventDialogBodyComponent } from './components/admin-event-dialog-body/admin-event-dialog-body.component';
import { AdminEventListGroupComponent } from './components/admin-event-list-group/admin-event-list-group.component';
import { AdminEventListItemComponent } from './components/admin-event-list-item/admin-event-list-item.component';
import { AdminEventsDateFiltersComponent } from './components/admin-events-date-filters/admin-events-date-filters.component';
import { AdminEventsFiltersComponent } from './components/admin-events-filters/admin-events-filters.component';
import { AdminEventsListComponent } from './components/admin-events-list/admin-events-list.component';
import { OutstandingPurchaseCardComponent } from './components/outstanding-purchase-card/outstanding-purchase-card.component';
import { PaymentDisputeCardComponent } from './components/payment-dispute-card/payment-dispute-card.component';
import { PendingBookingCardComponent } from './components/pending-booking-card/pending-booking-card.component';
import { UserDetailCardComponent } from './components/user-detail-card/user-detail-card.component';
import {
  ActiveSubscriptionDialog,
  CreateStationDialog,
  ProfileDetailsUpdateDialog,
  PurchaseInformationDialog,
  UserAccountDetailsDialog,
  VoidPurchaseDialog,
} from './dialogs';
import { NewBookingComponent } from './dialogs/new-booking/new-booking.component';
import { SignAgreementDialogComponent } from './dialogs/sign-agreement.dialog/sign-agreement.dialog.component';
import { StationPageComponent, StationsPageComponent } from './pages';
import { CheckInWindowPipe } from './pipes/check-in-window.pipe';
import { IsInThePastPipe } from './pipes/is-in-past.pipe';
import { IsTodayPipe } from './pipes/is-today.pipe';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { CheckInService, StationsService } from './services';

@NgModule({
  imports: [
    UsersModule,
    PaymentsModule,
    CommonModule,
    RouterModule,
    TableModule,
    FiltersModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDialogModule,
    SimpleDialogModule,
    MatDatepickerModule,
    RouterModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    BoxModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    LetModule,
    SmartImgModule,
    CollapsibleSectionModule,
    FormSaveButtonsModule,
    ClipboardModule,
    InvoicesModule,
    UserAvatarModule,
    MatSlideToggleModule,
    MatCardModule,
    MatListModule,
    MatGridListModule,
    MatChipsModule,
    SafePipeModule,
    NgPipesModule,
    MatProgressBarModule,
    MatSelectModule,
    ContactsModule,
    EventsModule,
    FeatureCommunityAgreementsModule,
    ShopModule,
    TabsModule,
    SubscriptionsModule,
    PurchasesModule,
    PerksModule,
    UserAvatarModule,
    AccountLinkingModule,
    FormSaveButtonsModule,
    AddressInputModule,
    NgxMaskModule,
    AlertsModule,
  ],
  providers: [StationsService, CheckInService],
  declarations: [
    StationDetailsComponent,
    StationsPageComponent,
    StationPageComponent,
    CreateStationDialog,
    StationDetailsInputComponent,
    MembersListComponent,
    StationCheckinListComponent,
    NewBookingComponent,
    AdminEventsListComponent,
    AdminEventListGroupComponent,
    AdminEventListItemComponent,
    AdminEventDialogBodyComponent,
    AdminEventsFiltersComponent,
    AdminEventsDateFiltersComponent,
    IsTodayPipe,
    IsInThePastPipe,
    SignAgreementDialogComponent,
    VoidPurchaseDialog,
    UserDetailCardComponent,
    OutstandingPurchaseCardComponent,
    PaymentDisputeCardComponent,
    ActiveSubscriptionDialog,
    PurchaseInformationDialog,
    PendingBookingCardComponent,
    PhoneFormatPipe,
    ProfileDetailsUpdateDialog,
    UserAccountDetailsDialog,
    CheckInWindowPipe,
    NoProfilePictureCardComponent,
    ExpiringPaymentMethodAlertComponent,
    UnconfirmedBookingAlertComponent,
    CheckInBookingAlertComponent,
    CheckInTableComponent,
    UnsignedAgreementCardComponent,
  ],
  exports: [
    CheckInWindowPipe,
    StationsPageComponent,
    StationPageComponent,
    StationDetailsComponent,
    CheckInTableComponent,
    OutstandingPurchaseCardComponent,
    PaymentDisputeCardComponent,
    UnsignedAgreementCardComponent,
    ExpiringPaymentMethodAlertComponent,
    PendingBookingCardComponent,
    UnconfirmedBookingAlertComponent,
  ],
})
export class FeatureStationsModule {}
