import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { ContactNote } from '@greco/identity-contacts';
import { SecurityService } from '@greco/ngx-security-util';
import { Subject } from 'rxjs';
import { ContactNotesService } from '../../services';

@Component({
  selector: 'greco-reply-to-note-dialog',
  templateUrl: './reply-to-note.dialog.html',
  styleUrls: ['./reply-to-note.dialog.scss'],
})
export class ReplyToContactNoteDialog implements OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { note: ContactNote },
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private noteSvc: ContactNotesService,
    private securitySvc: SecurityService,
    private dialogRef: MatDialogRef<ReplyToContactNoteDialog>
  ) {}

  saveCalled = false;

  private _onDestroy$ = new Subject<void>();

  formGroup = this.formBuilder.group({
    note: ['', Validators.required],
  });

  dialogData: DialogData = {
    title: 'Replying to',
    subtitle: this.data.note.content,
    showCloseButton: false,
  };

  cancel() {
    this.dialogRef.close();
  }

  async save() {
    try {
      if (this.saveCalled) return;
      this.saveCalled = true;

      const response = await this.noteSvc.create(this.data.note.contactId, {
        content: this.formGroup.value.note,
        admin: this.data.note.admin,
        repliedToId: this.data.note.id,
        pinned: false,
      });

      this.snacks.open('Replied!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.dialogRef.close(response);
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
