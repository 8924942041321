import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EventFilter, EventLocation, EventsByCalendar, EventTag, EventTrainer } from '@greco/booking-events2';
import { SignInComponent, UserService } from '@greco/ngx-identity-auth';
import { heightExpansion } from '@greco/ui-animations';
import moment from 'moment';
import { Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { flattenAndCombineFilters } from '../../pipes';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'alt-workouts-layout',
  templateUrl: './workouts-layout.page.html',
  styleUrls: ['./workouts-layout.page.scss'],
  animations: [heightExpansion],
})
export class WorkoutsLayoutPage implements OnDestroy {
  constructor(
    private router: Router,
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private eventSvc: EventService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    public bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver
  ) {
    this.route.queryParams.subscribe(() => this.loadFiltersFromQueryParams());
  }

  showFilters = false;

  loadingCalendarTypes = true;

  externalLinks = [
    {
      icon: 'reset_tv',
      label: 'LF3 GO (On Demand)',
      path: 'go',
    },
    {
      icon: 'video_library',
      label: 'LF3 Programs (Legacy Content)',
      path: 'collections',
    },
  ];

  eventsAndFilters = this.formBuilder.group({
    eventsByCalendar: [null as EventsByCalendar | null],
    locations: [[] as EventLocation[]],
    filters: [[] as EventFilter[]],
  });

  destroyed$ = new Subject();
  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(bps => bps.matches));

  allEventsByCalendar$ = this.userService.user$.pipe(
    takeUntil(this.destroyed$),
    filter(user => {
      if (!user) this.signIn();
      return !!user;
    }),
    switchMap(() => this.eventSvc.getCalendarTypes()),
    tap(eventsByCalendars => {
      // Clear cached data
      if (this.route.snapshot.queryParamMap?.keys?.length) {
        localStorage.removeItem('booking-eventsByCalendar');
        localStorage.removeItem('booking-locations');
        localStorage.removeItem('booking-filters');
      }

      if (eventsByCalendars.length) {
        const storedCalendarType = localStorage.getItem('booking-eventsByCalendar');
        if (storedCalendarType && eventsByCalendars.some(calendarType => calendarType.key === storedCalendarType)) {
          this.eventsAndFilters
            .get('eventsByCalendar')
            ?.patchValue(eventsByCalendars.find(calendarType => calendarType.key === storedCalendarType));
        } else {
          this.eventsAndFilters.get('eventsByCalendar')?.patchValue(eventsByCalendars[0]);
        }
        this.eventsByCalendarSelectionChanged(this.eventsAndFilters.get('eventsByCalendar')?.value);
      }
      this.loadingCalendarTypes = false;
    }),
    shareReplay(1)
  );

  async signIn() {
    const ref = this.matDialog.open(SignInComponent, {
      data: {},
      width: '100%',
      maxWidth: '400px',
      disableClose: true,
    });
    if (!(await ref.afterClosed().toPromise())) {
      this.router.navigate(['']);
    }
  }

  eventsByCalendarSelectionChanged(eventsByCalendar: EventsByCalendar) {
    const storedLocations = JSON.parse(localStorage.getItem('booking-locations') || '{}');
    const newLocations =
      storedLocations && storedLocations[eventsByCalendar.key]?.length
        ? eventsByCalendar.locations.filter(location => storedLocations[eventsByCalendar.key].includes(location.id))
        : eventsByCalendar.locations;

    localStorage.setItem('booking-eventsByCalendar', eventsByCalendar.key);

    this.eventSvc.calendars$.next(eventsByCalendar.calendars);
    this.locationSelectionChanged(newLocations);
  }

  locationSelectionChanged(locations: EventLocation | EventLocation[]) {
    const locationsArray = Array.isArray(locations) ? locations : [locations];
    const storedLocations = JSON.parse(localStorage.getItem('booking-locations') || '{}');
    localStorage.setItem(
      'booking-locations',
      JSON.stringify({
        ...(storedLocations || {}),
        [this.eventsAndFilters.get('eventsByCalendar')?.value?.key]: locationsArray.map(location => location.id),
      })
    );

    this.eventsAndFilters.get('locations')?.patchValue(locationsArray);
    this.eventSvc.locations$.next(locationsArray);
    this.loadFiltersFromQueryParams();
    this.checkUpdateFilters();
  }

  checkUpdateFilters(
    filters = localStorage.getItem('booking-filters') ? JSON.parse(localStorage.getItem('booking-filters') as any) : []
  ) {
    const locations = this.eventsAndFilters.get('locations')?.value || [];
    const validTags = flattenAndCombineFilters('tags')(locations);
    const validTrainers = flattenAndCombineFilters('trainers')(locations);

    // Ensure that the filters are still valid based on the selected locations (include tags of selected locations only)
    const updatedFilters = filters.filter((filter: EventFilter) => {
      if (['tag', 'trainer'].includes(filter.key)) {
        const valid =
          validTags.find(tag => tag.id.split('-').some(id => filter.value.split('-').includes(id))) ||
          validTrainers.find(trainer => trainer.id.split('-').some(id => filter.value.split('-').includes(id)));
        if (!valid) return false;
        if (valid.id !== filter.value) filter.value = valid.id;
      }
      return true;
    });

    localStorage.setItem(
      'booking-filters',
      JSON.stringify(filters.map((f: any) => updatedFilters.find((uf: EventFilter) => uf.label === f.label) || f))
    );

    this.eventsAndFilters.get('filters')?.patchValue(updatedFilters);
    this.eventSvc.filters$.next(updatedFilters);
  }

  filterSelectionChanged(selected: boolean, filter: EventTag | EventTrainer, type: 'tag' | 'trainer') {
    if (selected) {
      this.addFilter({ key: type, value: filter.id, label: filter.label, imageUrl: (filter as any).imageUrl });
    } else {
      this.removeFilter(filter.id);
    }
  }

  addFilter(filter: EventFilter) {
    const filters = this.eventsAndFilters.get('filters')?.value || [];
    const existingFilter = filters.find((f: EventFilter) => f.value === filter.value);
    if (!existingFilter) {
      filters.push(filter);
      this.checkUpdateFilters(filters);
    }
  }

  removeFilter(filterValue: string) {
    const currentFilters = this.eventsAndFilters.get('filters')?.value || [];
    const updatedFilters = currentFilters.filter((f: EventFilter) => f.value !== filterValue);
    this.checkUpdateFilters(updatedFilters);
  }

  clearFilters() {
    this.checkUpdateFilters([]);
  }

  navigate(path: string) {
    this.router.navigate(['workouts', path]);
  }

  copied() {
    this.snacks.open('Link copied to clipboard!', 'Ok', { panelClass: 'mat-primary', duration: 2500 });
  }

  getFilterLink() {
    const trainers: EventFilter[] = [];
    const tags: EventFilter[] = [];

    this.eventsAndFilters.value.filters.forEach((filter: EventFilter) => {
      if (filter.key === 'trainer') trainers.push(filter);
      else if (filter.key === 'tag') tags.push(filter);
    });

    return (
      window.location.host +
      '/workouts?' +
      (trainers?.length ? 'trainers=' + trainers.map(trainer => trainer.label + ':' + trainer.value).join(',') : '') +
      (trainers?.length && tags?.length ? '&' : '') +
      (tags?.length ? 'tags=' + tags.map(tag => tag.label + ':' + tag.value).join(',') : '')
    );
  }

  loadFiltersFromQueryParams() {
    const filtersToAdd: EventFilter[] = [];
    const storedFilters = this.eventsAndFilters.get('filters')?.value || [];

    const filterTags: string = this.route.snapshot.queryParams['tags'];
    filterTags?.split(',')?.forEach(tag => {
      const [label, id] = tag.split(':');
      if (label && id) {
        const existingFilter = storedFilters.find((storedFilter: EventFilter) => storedFilter.value === id);
        if (!existingFilter) filtersToAdd.push({ key: 'tag', value: id, label });
      }
    });

    const filterTrainers: string = this.route.snapshot.queryParams['trainers'];
    filterTrainers?.split(',')?.forEach(trainer => {
      const [label, id] = trainer.split(':');
      if (label && id) {
        const existingFilter = storedFilters.find((storedFilter: EventFilter) => storedFilter.value === id);
        if (!existingFilter) filtersToAdd.push({ key: 'trainer', value: id, label });
      }
    });

    if (filtersToAdd.length) this.checkUpdateFilters(filtersToAdd);

    const dateParam = this.route.snapshot.queryParams['date'];
    if (dateParam && moment(dateParam).toDate().getTime() > new Date().getTime()) {
      this.eventSvc.dateRange$.next([
        moment(dateParam).startOf('day').toDate(),
        moment(dateParam).endOf('day').toDate(),
      ]);
    }

    this.router.navigate([], { queryParams: {} });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
