import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { EventVideo, EventVideoUnlock, EventVideoUnlockOption } from '@greco/event-videos';
import { SignInComponent, UserService } from '@greco/ngx-identity-auth';
import { VideosService } from '@greco/ngx-videos';
import { UserPerk } from '@greco/sales-perks';

@Component({
  selector: 'greco-event-video-list-item',
  templateUrl: './event-video-list-item.component.html',
  styleUrls: ['./event-video-list-item.component.scss'],
})
export class EventVideoListItemComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private videoSvc: VideosService,
    private matDialog: MatDialog,
    private userService: UserService
  ) {}

  @Input() eventVideo!: EventVideo;
  @Input() options!: UserPerk[];
  @Input() videoUnlock!: EventVideoUnlock;
  label = 'Unlock';

  async unlock() {
    let user = await toPromise(this.userService.isSignedIn$());
    if (!user) {
      await this.signIn();
      user = await toPromise(this.userService.isSignedIn$());
    }
    if (user) {
      await this.router.navigate([this.eventVideo.id], { relativeTo: this.route });
    }
  }

  async ngOnInit() {
    for (const perk of this.options) {
      if (!(perk.perk as EventVideoUnlockOption).price && !perk.consumable) {
        this.label = 'Watch';
        break;
      }
    }
    if (this.videoUnlock) this.label = 'Watch';
    /*
    if (this.eventVideo.communityVideo) {
      const unlock = await this.videoSvc.hasActiveVideoUnlock(this.eventVideo.communityVideo?.videoId);
      if (unlock) this.label = 'Watch';
    }
    */
  }

  async signIn() {
    const _dialog = this.matDialog.open(SignInComponent, { data: {}, width: '100%', maxWidth: '400px' });
    await toPromise(_dialog.afterClosed());
  }
}
