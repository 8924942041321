import { Component, Injectable, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { Community } from '@greco/identity-communities';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import {
  VideoCollection,
  VideoCollectionSecurityResource,
  VideoCollectionSecurityResourceAction,
} from '@greco/video-library';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { CollectionsService } from '../../services';

@Injectable({ providedIn: 'any' })
export class ProgramTitleSearchFilter extends BuildSearchFilter('ProgramTitleSearchFilter', {
  properties: ['title'],
  propertyLabels: ['Program Title'],
}) {}

@Injectable({ providedIn: 'any' })
export class PlaylistTitleSearchFilter extends BuildSearchFilter('PlaylistTitleSearchFilter', {
  properties: ['subcollections.title'],
  propertyLabels: ['Playlist Title'],
}) {}

@Component({
  selector: 'greco-programs-admin-table',
  templateUrl: './programs-admin-table.component.html',
  styleUrls: ['./programs-admin-table.component.scss'],
})
export class ProgramsAdminTableComponent {
  constructor(
    private snacks: MatSnackBar,
    private comSecSvc: CommunitySecurityService,
    private router: Router,
    private route: ActivatedRoute,
    private programSvc: CollectionsService
  ) {}
  loading = false;
  filterOptions = [ProgramTitleSearchFilter, PlaylistTitleSearchFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination?: IPaginationMeta;
  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});

  @Input() set community(community) {
    this._community$.next(community);
    this.refresh$.next(null);
  }

  get community() {
    return this._community$.value;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private _community$ = new BehaviorSubject<Community | null>(null);

  now = Date.now();
  refresh$ = new BehaviorSubject(null);
  data$ = combineLatest([this.filters$, this.page$, this._community$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([query, page, community]) => {
      return query && community ? await this.programSvc.paginate(query, community.id, page) : null;
    }),
    tap(data => (this.pagination = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  canRead$ = this._community$.pipe(
    switchMap(async community => {
      return community
        ? await this.comSecSvc.hasAccess(
            community.id,
            VideoCollectionSecurityResource.key,
            VideoCollectionSecurityResourceAction.READ
          )
        : null;
    })
  );

  canManage$ = this._community$.pipe(
    switchMap(async community => {
      return community
        ? await this.comSecSvc.hasAccess(
            community.id,
            VideoCollectionSecurityResource.key,
            VideoCollectionSecurityResourceAction.MANAGE
          )
        : null;
    })
  );

  refresh() {
    this.refresh$.next(null);
  }
  async manageProgram(program: VideoCollection) {
    await this.router.navigate([program.id], { relativeTo: this.route });
  }

  async changeStatus(collection: VideoCollection) {
    const isActive =
      collection.availableOn &&
      collection.availableOn.getTime() < Date.now() &&
      (!collection.availableUntil || collection.availableUntil.getTime() > Date.now());
    try {
      const availableOn = isActive ? collection.availableOn : new Date();
      const availableUntil = isActive
        ? new Date()
        : collection.availableUntil && collection.availableUntil.getTime() < Date.now()
        ? null
        : collection.availableUntil;

      await this.programSvc.updateProgram(collection.id, { availableOn: availableOn, availableUntil: availableUntil });
      this.refresh();
      this.snacks.open('Status changed successfully', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
      this.snacks.open('Oops, something went wrong. Please try again.', 'Ok', {
        duration: 5000,
        panelClass: 'mat-warn',
      });
    }
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
}
