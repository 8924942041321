import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { UserCommunityAgreement } from '@greco/community-agreements';
import { SignAgreementDialog } from '@greco/ngx-community-agreements';

@Component({
  selector: 'greco-unsigned-agreement-card',
  templateUrl: './unsigned-agreement-card.component.html',
  styleUrls: ['./unsigned-agreement-card.component.scss'],
})
export class UnsignedAgreementCardComponent {
  constructor(private dialog: MatDialog) {}

  @Input() agreement!: UserCommunityAgreement;

  @Output() refreshAgreements = new EventEmitter();

  async sign() {
    const dialog = this.dialog.open(SignAgreementDialog, { data: this.agreement });
    const response = await toPromise(dialog.afterClosed());
    if (response) this.refreshAgreements.emit(response);
  }
}
