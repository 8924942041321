/* eslint-disable @typescript-eslint/member-ordering */
import { BreakpointObserver } from '@angular/cdk/layout';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsService } from '@greco/ngx-alerts';
import { UserService } from '@greco/ngx-identity-auth';
import { BehaviorSubject, combineLatest, interval } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-new-dashboard',
  templateUrl: './new-dashboard.page.html',
  styleUrls: ['./new-dashboard.page.scss'],
  providers: [{ provide: OverlayContainer, useClass: FullscreenOverlayContainer }],
})
export class NewDashboardPage {
  constructor(
    private router: Router,
    public userSvc: UserService,
    private alertSvc: AlertsService,
    private breakpointObserver: BreakpointObserver
  ) {}

  now$ = interval(1000).pipe(
    startWith(new Date()),
    map(() => new Date())
  );

  refresh$ = new BehaviorSubject<null>(null);

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(bps => bps.matches));

  highPriorityWarnings$ = combineLatest([this.userSvc.user$, this.refresh$]).pipe(
    switchMap(([user]) => (user ? this.alertSvc.getHighPriorityAlerts(user.id) : []))
  );

  lowPriorityWarnings$ = combineLatest([this.userSvc.user$, this.refresh$]).pipe(
    switchMap(([user]) => (user ? this.alertSvc.getLowPriorityAlerts(user.id) : []))
  );

  navigateToShop() {
    this.router.navigate(['/shop']);
  }

  navigateToProfile() {
    this.router.navigate(['/account']);
  }
}
