import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { CommunityGiftCardConfig } from '@greco/gift-cards';
import { Community } from '@greco/identity-communities';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunityService, UserCommunityPickerComponent } from '@greco/ngx-identity-communities';
import { ContactService } from '@greco/ngx-identity-contacts';
import { PropertyListener } from '@greco/property-listener-util';
import { ProductCollection } from '@greco/sales-products';
import { BehaviorSubject, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-shop-filters',
  templateUrl: './shop-filters.component.html',
  styleUrls: ['./shop-filters.component.scss'],
})
export class ShopFiltersComponent implements OnInit {
  constructor(
    private router: Router,
    private userSvc: UserService,
    private route: ActivatedRoute,
    private communitySvc: CommunityService,
    private contactSvc: ContactService
  ) {
    this.communitiesControl.valueChanges.subscribe(communities => {
      this.communities = communities;
    });
  }

  @Output() communitiesChanged = new EventEmitter<Community[]>(true);

  @PropertyListener('userId') private _userId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() userId?: string;

  @Input() searchValue?: string | null;

  @Input() communities: Community[] = [];

  @Input() collections?: ProductCollection[];

  @Input() giftCardConfigs?: CommunityGiftCardConfig[];
  @HostBinding('class.show-collections') get showCollections() {
    return !!this.collections?.length || !!this.giftCardConfigs?.length;
  }

  user$ = this._userId$.pipe(
    switchMap(userId => this.userSvc.getUserId(userId)),
    switchMap(userId => (userId ? this.userSvc.getUser(userId) : of(null))),
    shareReplay(1)
  );

  communitiesControl = new FormControl([]);

  @ViewChild(UserCommunityPickerComponent) private _communityPicker?: UserCommunityPickerComponent;

  async ngOnInit() {
    const params = await toPromise(this.route.queryParams);
    this.searchValue = params.search || '';

    //try get subscribed communities
    try {
      if (!this.communities?.length) {
        this.communities = (await this.contactSvc.getUserContacts()).map(contact => contact.community);
        this._emitChange();
      }
    } catch (e) {
      //
    }

    //if no length, get parent communities
    if (!this.communities?.length) {
      this.communities = (await this.communitySvc.getAllCommunities()).filter(comm => comm.canBeParent === true);
      this._emitChange();
    }
  }

  async search() {
    await this.router.navigate(['/shop', 'products'], {
      queryParamsHandling: 'merge',
      queryParams: { search: this.searchValue || null },
    });
  }

  async pickCommunities() {
    this._communityPicker?.openSelect();
  }

  async openCollection(collection: any) {
    await this.router.navigate(['/shop', 'products'], {
      queryParamsHandling: 'merge',
      queryParams: { collection: collection.id },
    });
  }

  async openGiftCards() {
    await this.router.navigate(['/shop', 'gift-cards'], {
      queryParams: { communities: this.communities.map(comm => comm.id) },
    });
  }

  @PropertyListener('communities')
  private _emitChange() {
    this.communitiesChanged.emit(this.communities || []);
  }
}
