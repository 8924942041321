import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogModule } from '@greco-fit/scaffolding/ngx';
import { FiltersModule } from '@greco/ngx-filters';
import { LetModule } from '@greco/ngx-let-util';
import { AddressInputModule } from '@greco/ui-address-input';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { ImageUploadModule } from '@greco/ui-image-upload';
import { TableModule } from '@greco/ui-table';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import {
  AddPaymentMethodComponent,
  PAYMENT_METHOD_DIALOGS,
  PAYMENT_METHOD_FORMS,
  PaymentDisputeResponseFormComponent,
  PaymentDisputesTableComponent,
  PaymentMethodForm,
  SelectAccountPaymentMethodComponent,
  SelectPaymentMethodComponent,
  UserPaymentMethodsComponent,
} from './components';
import { CreatePaymentMethodDialog, PaymentMethodSubscriptionsDialog, UpdatePaymentMethodDialog } from './dialogs';
import { CreatePaymentMethodFormContainerDirective } from './dialogs/create-payment-method/create-payment-method.directive';
import { PaymentDisputePage, PaymentDisputesPage } from './pages';
import {
  AccountPaymentMethodService,
  PaymentDisputeService,
  PaymentMethodDialogs,
  UserPaymentMethodService,
} from './services';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatChipsModule,
    TableModule,
    MatRadioModule,
    MatTooltipModule,
    FiltersModule,
    BoxModule,
    MatDividerModule,
    CollapsibleSectionModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    ImageUploadModule,
    NgPipesModule,
    SafePipeModule,
    AddressInputModule,
    LetModule,
    DialogModule,
    MatCheckboxModule,
  ],
  providers: [UserPaymentMethodService, PaymentMethodDialogs, AccountPaymentMethodService, PaymentDisputeService],
  declarations: [
    AddPaymentMethodComponent,
    CreatePaymentMethodFormContainerDirective,
    SelectPaymentMethodComponent,
    SelectAccountPaymentMethodComponent,
    UserPaymentMethodsComponent,
    PaymentDisputesTableComponent,
    PaymentDisputeResponseFormComponent,
    PaymentDisputePage,
    PaymentDisputesPage,

    // Dialogs
    CreatePaymentMethodDialog,
    UpdatePaymentMethodDialog,
    PaymentMethodSubscriptionsDialog,
  ],
  exports: [
    AddPaymentMethodComponent,
    CreatePaymentMethodDialog,
    SelectPaymentMethodComponent,
    SelectAccountPaymentMethodComponent,
    UserPaymentMethodsComponent,
    PaymentDisputesTableComponent,
    PaymentDisputeResponseFormComponent,
    PaymentDisputePage,
    PaymentDisputesPage,
  ],
})
export class PaymentsModule {
  static forRoot(
    paymentMethodForms: PaymentMethodForm[],
    paymentMethodDialogs: { label: string; icon: string; dialog: Type<unknown> }[],
    paymentMethodValidators: Provider[]
  ): ModuleWithProviders<PaymentsModule> {
    return {
      ngModule: PaymentsModule,
      providers: [
        { provide: PAYMENT_METHOD_FORMS, useValue: paymentMethodForms },
        { provide: PAYMENT_METHOD_DIALOGS, useValue: paymentMethodDialogs },
        ...paymentMethodValidators,
      ],
    };
  }
}
