import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@greco/identity-users';
import { Purchase } from '@greco/sales-purchases';

@Component({
  selector: 'greco-purchase-information',
  templateUrl: './purchase-information.dialog.html',
  styleUrls: ['./purchase-information.dialog.scss'],
})
export class PurchaseInformationDialog {
  @Input() public data: { user: User; communityId: string; purchase: Purchase };

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: { user: User; communityId: string; purchase: Purchase },
    public dialogRef: MatDialogRef<any>
  ) {
    this.data = {
      ...this.dialogData,
    };
  }
}
