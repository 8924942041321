import { BaseDto } from '@greco/util-dto';
import { Expose } from 'class-transformer';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class UnlockEventVideoDto extends BaseDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  userId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  eventVideoId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  unlockOptionId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  unlockDate: Date;

  @Expose()
  @IsString()
  @IsOptional()
  paymentMethodId?: string;
}
