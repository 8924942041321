<ng-container *grecoLet="optionTags$ | async as userOptions">
  <ng-container *grecoLet="search$ | async as search">
    <ng-container *grecoLet="collections$ |async">
      <!--Filters-->
      <greco-user-collections-filters
        class="video-filters"
        (searchEvent)="this.searchChange($event); "
        (changed)="this.communityChange($event);"
        [user]="user"
      ></greco-user-collections-filters>

      <div class="main">
        <!--search-->
        <div *ngIf="search" style="display: flex; flex-direction: row">
          <mat-icon class="icon">search</mat-icon>
          <p>Filtering Programs By '{{search}}'</p>
        </div>

        <!--Browsable Sections-->
        <div>
          <div *ngIf="available.length || loading">
            <greco-browsable-collections-section
              [title]="'My Programs'"
              [icon]="'library_add_check'"
              [collections]="available || []"
              [loading]="loading"
              [canLoadMore]="true"
              (loadMoreEvent)="loadMoreAvailable()"
              [total]="availablePagination?.totalItems || 0"
              [showFeaturedStar]="true"
            >
            </greco-browsable-collections-section>
            <mat-divider style="margin-bottom: 32px" *ngIf="featured.length || collections.length"></mat-divider>
          </div>

          <div *ngIf="featured.length || loading">
            <greco-browsable-collections-section
              [title]="'Featured'"
              [icon]="'star'"
              [collections]="featured"
              [loading]="loading"
              [canLoadMore]="true"
              (loadMoreEvent)="loadMoreFeatured()"
              [total]="featuredPagination?.totalItems || 0"
              [userOptions]="userOptions || []"
            >
            </greco-browsable-collections-section>
            <mat-divider style="margin-bottom: 32px" *ngIf="collections.length"></mat-divider>
          </div>

          <greco-browsable-collections-section
            *ngIf="collections.length || loading"
            [title]="available.length || featured.length ? 'You may also like' : 'You may like'"
            [icon]="'video_library'"
            [collections]="collections || []"
            [loading]="loading"
            [canLoadMore]="true"
            (loadMoreEvent)="loadMoreCollections()"
            [total]="collectionPagination?.totalItems || 0"
          >
          </greco-browsable-collections-section>

          <greco-browsable-collections-section
            *ngIf="!collections.length && !available.length && !featured.length && !loading "
            [title]="'Browse Programs'"
            [icon]="'category'"
            [collections]="[]"
            [disabled]="true"
          >
          </greco-browsable-collections-section>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
