import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { VideoSubCollectionsService } from '../../services';

@Component({
  templateUrl: './create-new-sub-collection.dialog.html',
  styleUrls: ['./create-new-sub-collection.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateNewSubCollectionDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { collectionId: string; sortIndex: number },
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar,
    private playlistSvc: VideoSubCollectionsService
  ) {}

  private _onDestroy$ = new Subject<void>();

  selectable = true;
  removable = true;

  _creating = false;

  formGroup = this.formBuilder.group({
    title: ['', Validators.required],
    //color: ['#ff0000', Validators.required],
    description: [null],
    //icon: [],
  });

  dialogData: DialogData = {
    title: 'Create New Sub Collection',
    subtitle: 'Provide the details for the sub collection',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Create',
        role: 'create',
        resultFn: async () => {
          return await this.createSub();
        },
      },
    ],
  };

  async createSub() {
    try {
      const data = this.formGroup.value;
      this._creating = true;

      try {
        const sub = await this.playlistSvc.createPlaylist({
          collectionId: this.data.collectionId,
          description: data.description || null,
          title: data.title,
          sortIndex: this.data?.sortIndex || 0,
        });
        this.snacks.open('Subcollection created!', 'Ok', { duration: 3500, panelClass: 'mat-primary' });
        return sub;
      } catch (err) {
        console.error(err);
        this.snacks.open('Something went wrong. Please try again!', 'Ok', {
          duration: 5000,
          panelClass: 'mat-warn',
        });
      }

      this._creating = false;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async ngOnInit() {
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = this.formGroup.invalid;
          return btn;
        }),
      };
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
