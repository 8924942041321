import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaginatedQueryParams, PaginationMetadata } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { StationResourceAction, StationSecurityResource } from '@greco/stations';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CheckInService } from '../../services';
@Component({
  selector: 'greco-station-checkin-list',
  templateUrl: './station-checkin-list.component.html',
  styleUrls: ['./station-checkin-list.component.scss'],
})
export class StationCheckinListComponent implements OnDestroy {
  constructor(
    private checkedInUserSvc: CheckInService,
    private securitySvc: SecurityService,
    private commSecSvc: CommunitySecurityService,
    private dialog: MatDialog
  ) {}

  isLoading = false;

  private _pageSizes$ = new BehaviorSubject<number[]>([10, 20, 50]);
  @PropertyListener('stationId') private _stationId$ = new BehaviorSubject<string | null>(null);
  @Input() stationId!: string;

  @Input() communityId!: string;

  @Input() set pageSizes(sizes: number[]) {
    this._pageSizes$.next(sizes?.length ? sizes : [10, 20, 50]);
    const paginatedParams = this.paginatedParams$.value;
    if (!this._pageSizes$.value.includes(paginatedParams.limit || 0)) {
      this.paginatedParams$.next({ ...paginatedParams, limit: this.pageSizes[0] });
    }
  }
  get pageSizes() {
    return this._pageSizes$.value;
  }

  currentPagination: PaginationMetadata | null = null;

  paginatedParams$ = new BehaviorSubject<PaginatedQueryParams>({ page: 1, limit: 10 });

  canDelete$ = this._stationId$.pipe(
    switchMap(async stationId => {
      return stationId
        ? await this.commSecSvc.hasAccess(this.communityId, StationSecurityResource.key, StationResourceAction.DELETE)
        : null;
    })
  );
  checkedInUsers$ = this.paginatedParams$.pipe(
    switchMap(params => {
      return this.checkedInUserSvc.paginateCheckIns(this.communityId || '', this.stationId || '', params);
    }),
    tap(({ meta }) => setTimeout(() => (this.currentPagination = meta)))
  );

  async deleteCheckIn(checkInId: string) {
    this.isLoading = true;
    const result = await this.checkedInUserSvc.deleteCheckedInUser(checkInId);
    this.isLoading = false;
    if (result.id) {
      this.refreshList();
    }
  }

  async openDialog(checkInId: string) {
    const dialog = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Remove Check In Action',
            content: `<p> Are you sure you want to remove this check in? <b>This action cannot be undone! <b><p>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (dialog === 'confirm') this.deleteCheckIn(checkInId || '');
  }

  refreshList() {
    this.paginatedParams$.next(this.paginatedParams$.value);
  }

  ngOnDestroy(): void {
    this.paginatedParams$.complete();
  }
}
