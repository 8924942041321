import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { BookingService, EventService } from '@greco/ngx-booking-events';
import { SignInComponent, UserService } from '@greco/ngx-identity-auth';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-preview-booking-wrapper-page',
  styles: [
    `
      :host {
        margin-top: -64px;
        display: block;
        .full-alert {
          position: relative;
          z-index: 999;
          width: calc(100% - 32px);
          background-color: var(--warn-color);
          color: white;
          text-align: center;
          padding: 12px 16px;
          p {
            margin-bottom: 0;
          }
        }
      }
    `,
  ],
  template: `
    <ng-container *ngIf="templateData$ | async as data">
      <div *ngIf="showFullAlert$ | async" class="full-alert">
        <p>
          You just missed it! The available spot has been booked but keep an eye out in case more spots become
          available.
        </p>
      </div>
      <div id="container">
        <greco-multi-booking-preview
          [user]="data.user"
          [event]="data.event"
          style="height: 100%"
        ></greco-multi-booking-preview>
      </div>
    </ng-container>
  `,
})
export class PreviewBookingWrapperPage implements OnInit {
  fromWaitlist = false;

  constructor(
    private router: Router,
    private userSvc: UserService,
    private route: ActivatedRoute,
    private eventSvc: EventService,
    private crumbs: BreadcrumbService,
    private dialog: MatDialog,
    private bookingSvc: BookingService
  ) {
    this.fromWaitlist = this.route.snapshot.queryParams.fromWaitlist === 'true';
  }

  refresh$ = new BehaviorSubject<void>(undefined);

  private user$ = this.refresh$.pipe(
    switchMap(() => this.userSvc.getUserId().pipe(switchMap(uid => (uid ? this.userSvc.getUser(uid) : of(null)))))
  );

  private event$ = combineLatest([this.route.params, this.user$]).pipe(
    switchMap(async ([{ eventId }, user]) => (eventId && user ? await this.eventSvc.getOneEvent(eventId) : null)),
    catchError(() => of(null)),
    shareReplay(1)
  );

  showFullAlert$ = this.event$.pipe(
    switchMap(async e => {
      if (!this.fromWaitlist) return false;
      if (!e) return false;
      const eventBookings = await this.bookingSvc.getByEvent(e.id);
      return e.maxCapacity === eventBookings?.length;
    })
  );

  readonly templateData$ = combineLatest([this.event$, this.user$]).pipe(
    map(([event, user]) => (event && user ? { event, user } : null))
  );

  booked() {
    this.router.navigate(['/']);
  }

  async ngOnInit() {
    setTimeout(async () => {
      this.crumbs.set([{ label: 'Classes', routerLink: '/workouts', icon: 'date_range' }]);

      const event = await toPromise(this.event$);
      const user = await toPromise(this.user$);
      if (!user) this.dialog.open(SignInComponent);
      if (!event || !user) return await this.router.navigate(['..'], { relativeTo: this.route });

      return this.crumbs.set([
        { label: 'Classes', routerLink: '/workouts', icon: 'date_range' },
        { label: event.title, routerLink: `/workouts/${event.id}` },
      ]);
    });
  }
}
