<greco-simple-dialog [data]="dialogData">
  <mat-tab-group>
    <mat-tab label="Community">
      <greco-select-community-videos
        [community]="data.community"
        (confirmed)="confirmed($event)"
      ></greco-select-community-videos>
    </mat-tab>
    <mat-tab *ngFor="let source of data.sources" label="{{source | titlecase}}">
      <greco-select-from-source (confirmed)="confirmed($event)" [source]="source"></greco-select-from-source>
    </mat-tab>
  </mat-tab-group>
</greco-simple-dialog>
