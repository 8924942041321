<ng-container *ngIf="purchase">
  <div id="exportSection" *grecoLet="permissions$ | async as permissions">
    <greco-collapsible-section
      id="summary-section"
      [disabled]="disableCollapsible"
      [header]="{ title: 'Purchase Receipt', icon: 'receipt' }"
      [expanded]="expanded"
      style="display: block; margin-bottom: 12px"
    >
      <div header style="display: flex; align-items: center">
        <greco-purchase-status-chip
          style="margin-right: 16px"
          [isAdminPage]="isAdminPage"
          [purchase]="purchase"
        ></greco-purchase-status-chip>

        <button mat-icon-button class="hideFromPDF" (click)="exportPdf();$event.stopImmediatePropagation();">
          <mat-icon>file_download</mat-icon>
        </button>

        <greco-purchase-options
          *ngIf="showOptions"
          class="hideFromPDF"
          [purchase]="purchase"
          [showOpenOption]="false"
          [isSuper]="permissions?.isSuper || false"
          [canVoid]="permissions?.canVoid || false"
          [canRetry]="permissions?.canRetry || false"
          [canManageSoldBy]="permissions?.canManageSoldBy || false"
          [canManageReferredBy]="permissions?.canManageReferredBy || false"
          [canRefundToBalance]="permissions?.canRefundToBalance || false"
          [canRefundToPaymentMethod]="permissions?.canRefundToPaymentMethod || false"
          (refresh)="refresh()"
          [disabled]="!(
            purchase.status === 'PAID' ||
            purchase.status === 'PARTIALLY_REFUNDED' ||
            purchase.status === 'FAILED' ||
            purchase.status === 'PAYMENT_DISPUTED' ||
            purchase.status === 'PAYMENT_DISPUTE_LOST'
          )"
        >
          <button *ngFor="let option of extraMenuOptions" mat-menu-item (click)="option.action()">
            <mat-icon *ngIf="option.icon">{{option.icon}}</mat-icon>
            <span>{{option.label}}</span>
          </button>
        </greco-purchase-options>
      </div>

      <greco-purchase-preview
        [communityId]="communityId!"
        [purchase]="purchase"
        [showHeader]="true"
        [showTotals]="true"
        (refresh)="refresh()"
        [edit]="edit"
        [disabledEdit]="disabledEdit"
      ></greco-purchase-preview>

      <div
        *ngIf="(purchase.payment?.paymentMethod || purchase.balanceUsed) && purchase.completedOn"
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 16px 32px;
          gap: 12px;
          border-top: 1px solid lightgray;
        "
      >
        <mat-icon
          *ngIf="
              purchase.payment?.paymentMethod?.model === 'terminal';
              else otherModels
            "
        >
          contactless
        </mat-icon>

        <ng-template #otherModels>
          <img
            style="width: 38px; vertical-align: middle; margin: -10px 0 -10px 0"
            [src]="
                purchase.payment?.paymentMethod
                  ? 'assets/payment-methods/' + purchase.payment?.paymentMethod?.model + '.png'
                  : 'assets/lf3/icon.png'
              "
          />
        </ng-template>

        <div>
          {{purchase.payment?.paymentMethod ? ((purchase.payment!.paymentMethod.model | titlecase) + ' '+
          purchase.payment!.paymentMethod.label) : 'Balance'}}
        </div>
      </div>
    </greco-collapsible-section>

    <greco-collapsible-section
      id="refunds-section"
      *ngIf="purchase.status === 'REFUNDED' || purchase.status === 'PARTIALLY_REFUNDED' || ((_refunds$ | async)?.length || 0) > 0"
      [header]="{ title: 'Refunds', icon: 'undo' }"
      style="display: block; margin-bottom: 12px"
    >
      <greco-table [data]="(_refunds$ | async) || []" [loading]="loadingRefunds">
        <mat-icon *grecoTableCol="''; fitContent: true">receipt</mat-icon>

        <p *grecoTableCol="'Date'; let refund">{{refund.timestamp | date}} {{refund.timestamp | date: 'shortTime'}}</p>

        <ng-container *ngIf="canViewDetails$ | async">
          <p *grecoTableCol="'Reason'; let refund">{{refund.refundReason}}</p>

          <p *grecoTableCol="'Refunded By' let refund">{{refund.refundedBy?.displayName || 'System'}}</p>
        </ng-container>

        <p *grecoTableCol="'Refunded Item'; let refund">{{refund.itemId ? refund.item?.displayName : '-'}}</p>

        <p
          *grecoTableCol="'Refunded to Balance'; let refund; alignRight: true"
          [matTooltip]="refund.balanceUndoneAmount ? 'This balance refund has been reversed/undone. Strikedtrough is the original refund amount, and after is the remaining refund amount after undoing in full or in part. ' + (refund.balanceUndoneAmount / 100 | currency) + ' of the refund was reversed.' : ''"
        >
          <ng-container *ngIf="refund.balanceUndoneAmount; else notUndoned">
            <s>{{ refund.balanceRefundedAmount / 100 | currency }}</s>
            {{ (refund.balanceRefundedAmount - refund.balanceUndoneAmount) / 100 | currency }}
            <mat-icon
              style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; line-height: 14px; color: grey"
              >undo</mat-icon
            >
          </ng-container>
          <ng-template #notUndoned>
            {{ refund.balanceRefundedAmount ? (refund.balanceRefundedAmount / 100 | currency) : '-' }}
          </ng-template>
        </p>

        <p *grecoTableCol="'Refunded to Payment Method'; let data; alignRight: true">
          {{ data.paymentMethodRefundedAmount ? (data.paymentMethodRefundedAmount / 100 | currency) : '-' }}
        </p>

        <button
          mat-icon-button
          *grecoTableCol="'Actions'; let refund"
          matTooltip="Undo refund to balance"
          (click)="undoRefund(refund)"
          [disabled]="(refund.balanceRefundedAmount - refund.balanceUndoneAmount <= 0) || (canUndoRefund$ | async) === false"
        >
          <mat-icon>undo</mat-icon>
        </button>
      </greco-table>
    </greco-collapsible-section>
  </div>
</ng-container>
