<ng-container *ngIf="event">
  <div class="top-section">
    <img [src]="event.imageUrl || 'assets/greco/placeholder.png'" alt="Event Image" />

    <div class="text-content">
      <h3>{{ event.title }}</h3>

      <div class="large-chips">
        <div class="time-box">
          <div class="day">
            <p>{{ event.startTime | date : 'E' }}</p>
            <p>{{ event.startTime | date : 'dd' }}</p>
          </div>

          <div class="divider"></div>

          <div class="time">
            <p>{{ event.startTime | date : 'h:mm a' }}</p>
            <p>{{ event.duration }}mins</p>
          </div>
        </div>

        <div class="location-box">
          <mat-icon *ngIf="(isSmallMobile$ | async) !== true">location_on</mat-icon>
          <div class="location-text">
            <p>{{ event.calendar.title }}</p>
            <p>{{ event.location.label }}</p>
          </div>
        </div>
      </div>
    </div>

    <button
      *grecoLet="isFavorite$ | async as isFavorite"
      mat-icon-button
      class="favorite"
      (click)="$event.stopImmediatePropagation(); isFavorite ? removeFavorite() : addFavorite()"
    >
      <mat-icon [style.color]="isFavorite ? '#B73030' : ''">favorite</mat-icon>
    </button>
  </div>

  <div class="bottom-section">
    <div class="avatars" *ngIf="(event.trainers | pluck : 'imageUrl' | truthify)?.length">
      <ng-container *ngFor="let trainer of event.trainers">
        <img
          *ngIf="$any(trainer).imageUrl || $any(trainer).user?.photoUrl || 'assets/lf3/icon.png' as imageUrl"
          alt="Trainer Image"
          class="trainer-image"
          [src]="imageUrl"
        />
      </ng-container>
    </div>

    <ng-container *ngIf="event.trainers?.length">
      <span class="trainer-names">{{ (event.trainers | pluck : 'label')?.join(', ') }}</span>
      <div class="divider"></div>
    </ng-container>

    <span *ngIf="event.capacityRemaining > 0; else full">
      {{ event.capacityRemaining }} Spot{{ event.capacityRemaining === 1 ? '' : 's' }} Left
    </span>

    <ng-template #full>
      <span class="full">Full</span>
    </ng-template>

    <ng-container [ngSwitch]="event.userStatusInfo">
      <ng-container *ngSwitchCase="'booked'">
        <button mat-button color="primary" class="main-action-button">
          Manage Booking
          <mat-icon>free_cancellation</mat-icon>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'available'">
        <ng-container *ngIf="event.capacityRemaining > 0; else full">
          <button mat-button color="primary" class="main-action-button">
            Book Now
            <mat-icon>event</mat-icon>
          </button>
        </ng-container>

        <ng-template #full>
          <button
            mat-button
            color="primary"
            class="main-action-button"
            (click)="$event.stopImmediatePropagation(); joinWaitlist()"
          >
            Join Waitlist
            <mat-icon>playlist_add</mat-icon>
          </button>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'waitlisted'">
        <button mat-button color="accent" class="main-action-button">
          Leave Waitlist
          <mat-icon>playlist_remove</mat-icon>
        </button>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
