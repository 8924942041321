import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommunityVideoUnlockOption } from '@greco/community-videos';
import { User } from '@greco/identity-users';
import { CommunityVideoUnlockService } from '@greco/ngx-community-videos';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { VideoCollection } from '@greco/video-library';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CollectionsService } from '../../../services';

@Component({
  selector: 'greco-browse-collections-page',
  templateUrl: './browse-collections.page.html',
  styleUrls: ['./browse-collections.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BrowseCollectionsPage {
  constructor(
    private collectionsSvc: CollectionsService,
    private perkSvc: CommunityVideoUnlockService,
    private matDialog: MatDialog,
    private userService: UserService
  ) {}

  @PropertyListener('user') private _user$ = new BehaviorSubject<User | null>(null);
  @Input() user?: User | null;
  loading = true;
  communityIds$ = new BehaviorSubject<string[]>([]);
  collectionPagination?: IPaginationMeta;
  availablePagination?: IPaginationMeta;
  featuredPagination?: IPaginationMeta;
  search$ = new BehaviorSubject<string>('');

  collectionPage = 1;
  featuredPage = 1;
  availablePage = 1;

  refresh$ = new BehaviorSubject(null);
  limit = 15;

  optionTags$ = this._user$.pipe(
    switchMap(async user => {
      return await this.perkSvc.getUserUnlockOptions(user?.id);
    }),
    map(perks => {
      const options = perks.map(up => up.perk as CommunityVideoUnlockOption);
      const tagIds: string[] = [];
      options.forEach(option =>
        option.videoTags.forEach(tag => (!tagIds.some(id => tag.id === id) ? tagIds.push(tag.id) : {}))
      );
      return tagIds;
    })
  );
  collections$ = combineLatest([this.communityIds$, this.optionTags$, this.search$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([communities, tags, search]) => {
      return { communities, tags, search };
    }),
    tap(async data => {
      await this.getData(data.communities, data.tags, data.search).then(() => (this.loading = false));
    })
  );

  featured: VideoCollection[] = [];
  collections: VideoCollection[] = [];
  available: VideoCollection[] = [];

  communityChange(next: string[]) {
    this.clearCollectionsData();
    this.communityIds$.next(next);
  }

  searchChange(next: string) {
    this.clearCollectionsData();
    this.search$.next(next);
  }
  async getData(communities: string[], tags: string[] = [], search?: string) {
    await this.getAvailableCollections(communities, tags, search);
    await this.getOtherCollections(communities, tags, search);
    await this.getFeaturedCollections(communities, search);
  }

  async getAvailableCollections(communities: string[], tags: string[] = [], search?: string) {
    !this.availablePagination
      ? (this.availablePagination = { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 })
      : {};
    if (!tags || !tags.length) return [];
    let total = 0;
    let count = 0;
    if (communities && !communities.length) communities.push('com_greco');
    else if (!communities) communities = ['com_greco'];
    const response =
      communities && communities.length
        ? await this.collectionsSvc.paginateForUser(
            communities,
            search,
            {
              page: this.availablePage,
              limit: this.limit,
            },
            undefined,
            { items: tags }
          )
        : null;
    if (response) {
      total += response.meta.totalItems;
      count += response.meta.itemCount;
    }
    this.availablePagination.totalItems = total;
    this.availablePagination.itemCount = count;
    this.availablePagination.currentPage = this.collectionPage;
    if (response?.items.length) {
      this.available.push(...response.items.filter(col => !this.available.some(d => d.id === col.id)));
      // this.featured = this.featured.filter(featured => !this.available.some(available => available.id === featured.id)); //should available and featured have duplicates?
    }
    return response?.items;
  }

  async getFeaturedCollections(communities: string[], search?: string) {
    // const excludeCollections = this.available
    //   .filter(collection => collection.featured)
    //   .map(collection => collection.id);
    !this.featuredPagination
      ? (this.featuredPagination = { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 })
      : {};
    let total = 0;
    let count = 0;
    if (communities && !communities.length) communities.push('com_greco');
    else if (!communities) communities = ['com_greco'];
    const response = await this.collectionsSvc.paginateForUser(
      communities,
      search,
      {
        page: this.featuredPage,
        limit: this.limit,
      },
      true,
      undefined
      //excludeCollections
    );
    total += response.meta.totalItems;
    count += response.meta.itemCount;
    this.featuredPagination.totalItems = total;
    this.featuredPagination.itemCount = count;
    this.featuredPagination.currentPage = this.featuredPage;

    response?.items.length
      ? this.featured.push(...response.items.filter(col => !this.featured.some(d => d.id === col.id)))
      : {};
    return response?.items;
  }
  async getOtherCollections(communities: string[], tags: string[] = [], search?: string) {
    !this.collectionPagination
      ? (this.collectionPagination = { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 })
      : {};
    let total = 0;
    let count = 0;
    if (communities && !communities.length) communities.push('com_greco');
    else if (!communities) communities = ['com_greco'];
    const response = await this.collectionsSvc.paginateForUser(
      communities,
      search,
      {
        page: this.collectionPage,
        limit: this.limit,
      },
      false,
      { items: tags, exclude: true }
    );
    if (response) {
      total += response.meta.totalItems;
      count += response.meta.itemCount;
    }
    this.collectionPagination.totalItems = total;
    this.collectionPagination.itemCount = count;
    this.collectionPagination.currentPage = this.collectionPage;
    response?.items.length
      ? this.collections.push(...response.items.filter(col => !this.collections.some(d => d.id === col.id)))
      : {};
    return response?.items;
  }

  clearCollectionsData() {
    this.collectionPagination = { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 };
    this.collections = [];
    this.collectionPage = 1;
    this.featuredPagination = { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 };
    this.featured = [];
    this.featuredPage = 1;
    this.availablePagination = { currentPage: 1, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 };
    this.available = [];
    this.availablePage = 1;
  }

  loadMoreCollections() {
    if (this.collectionPagination && this.collectionPagination.totalItems > this.collections.length) {
      this.collectionPage++;
      this.refresh$.next(null);
    }
  }

  loadMoreFeatured() {
    if (this.featuredPagination && this.featuredPagination.totalItems > this.featured.length) {
      this.featuredPage++;
      this.refresh$.next(null);
    }
  }
  loadMoreAvailable() {
    if (this.availablePagination && this.availablePagination.totalItems > this.available.length) {
      this.availablePage++;
      this.refresh$.next(null);
    }
  }
}
