import { Component, Injectable, Input, OnChanges, OnDestroy, SimpleChanges, Type, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { BuildDateFilter, BuildTextFilter, Filter } from '@greco/ngx-filters';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { Station, StationResourceAction, StationSecurityResource } from '@greco/stations';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { CreateStationDialog } from '../../dialogs';
import { StationsService } from '../../services';
@Injectable({ providedIn: 'root' })
export class StationTitleFilter extends BuildTextFilter('StationTitleFilter', {
  label: 'Title',
  shortLabel: 'Title',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW, CondOperator.EQUALS_LOW],
  properties: ['title'],
}) {}

@Injectable({ providedIn: 'root' })
export class StationCreatedDateFilter extends BuildDateFilter('StationCreatedDateFilter', {
  label: 'Created Date',
  shortLabel: 'Created',
  description: '',
  properties: ['created'],
}) {}

@Component({
  selector: 'greco-stations-page',
  templateUrl: './stations.page.html',
  styleUrls: ['./stations.page.scss'],
})
export class StationsPageComponent implements OnChanges, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private securitySvc: SecurityService,
    private userSvc: UserService,
    private stationsSvc: StationsService,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = formBuilder.group({});
  }

  @PropertyListener('community') community$ = new ReplaySubject<Community>(1);
  @Input() community!: Community;

  isChecked = true;

  formGroup: FormGroup;

  filterOptions: Type<Filter>[] = [StationTitleFilter, StationCreatedDateFilter];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  sort$ = new BehaviorSubject<Sort>({ active: 'deleted', direction: 'asc' });
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });

  stations$ = combineLatest([this.sort$, this.filters$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([sort, queryBuilder, pagination]) => {
      queryBuilder = RequestQueryBuilder.create({
        sort: sort.active ? [{ field: sort.active, order: sort.direction === 'desc' ? 'DESC' : 'ASC' }] : [],
        search: JSON.parse(queryBuilder.queryObject.s || '{}'),
      });
      return this.community?.id
        ? await this.stationsSvc.paginateStations(queryBuilder, this.community.id, pagination)
        : null;
    }),
    tap(data => (this.pagination = data?.meta || null)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  canCreateStation$ = this.community$.pipe(
    filter(c => !!c),

    switchMap(async community => {
      return await this.securitySvc.hasAccess(StationSecurityResource.key, StationResourceAction.CREATE, {
        communityId: community.id,
      });
    })
  );

  isSuperAdmin$ = this.userSvc.user$.pipe(map(u => u?.isSuperAdmin));

  canUpdateStations$ = this.community$.pipe(
    filter(c => !!c),
    switchMap(async community => {
      return await this.securitySvc.hasAccess(StationSecurityResource.key, StationResourceAction.UPDATE, {
        communityId: community?.id,
      });
    })
  );

  pagination: null | IPaginationMeta = null;

  loading = true;
  invited = false;
  inviteLoading = false;

  onFormSubmit(formValue: any) {
    alert(JSON.stringify(formValue, null, 2));
  }
  async createStation() {
    const dialog = this.dialog.open(CreateStationDialog, {
      data: {
        communityId: this.community.id,
        title: '',
        mode: 'create',
        stationId: '',
      },
      width: '750px',
      maxWidth: '90%',
    });
    const station = await toPromise(dialog.afterClosed());
    if (station) await this.router.navigate([station.id], { relativeTo: this.route });
    await this.refresh();
  }

  async updateStation(resource: any) {
    const dialog = this.dialog.open(CreateStationDialog, {
      data: {
        communityId: this.community.id,
        title: resource.title,
        mode: 'update',
        stationId: resource.id,
      },
      width: '750px',
      maxWidth: '90%',
    });
    await toPromise(dialog.afterClosed());
    await this.refresh();
  }

  async deleteStation(resource: any) {
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `<p>You are about to remove <strong>${resource.title}</strong> from your stations list.</p><p>This action is NOT reversible!</p>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      await this.stationsSvc.deleteStation(resource.id);
      this.snacks.open('Station Deleted!', 'Ok', { duration: 3000 });
      await this.refresh();
    }
  }

  async openStation(station: Station) {
    return this.router.navigate([station?.id], {
      relativeTo: this.route,
    });
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  ngOnDestroy() {
    this.filters$.complete();
    this.page$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.community.previousValue !== changes.community.currentValue) {
      this.refresh();
    }
  }

  private async refresh() {
    this.filters$.next(this.filters$.value);
  }
}
