<greco-box
  *grecoLet="$any(product).inventoryAddon as inventoryAddon"
  style="cursor: pointer"
  (click)="goToProductPage(selectedVariant)"
>
  <ng-container *grecoLet="conditionCanBuy$ | async as conditionCanBuy">
    <ng-container
      *grecoLet="
        (selectedVariant || product.variants[0]).id | variantInventory : inventoryAddon?.inventories as variantInventory
      "
    >
      <div class="image-section">
        <ng-container *ngIf="conditionCanBuy?.result === true; else unavailable">
          <mat-chip-list
            *ngIf="inventoryAddon?.enabled && (variantInventory?.availableInventoryCount || 0) >= 0"
            class="inventoryBadge"
          >
            <mat-chip *grecoLet="variantInventory?.availableInventoryCount || 0 as stockCount">
              {{
                (stockCount || 0) > 0 && inventoryAddon?.displayStockNumber
                  ? variantInventory?.availableInventoryCount + ' In Stock'
                  : (stockCount || 0) > 0
                  ? 'In Stock'
                  : 'Out of Stock'
              }}
            </mat-chip>
          </mat-chip-list>
        </ng-container>

        <ng-template #unavailable>
          <mat-chip-list class="inventoryBadge">
            <mat-chip> Unavailable </mat-chip>
          </mat-chip-list>
        </ng-template>

        <img [src]="selectedImageUrl" />

        <mat-icon class="info-icon">info</mat-icon>

        <div class="description">
          <div class="perks">
            <div class="perk" *ngFor="let varPerk of (selectedVariant || product.variants[0])?.variantPerks">
              <greco-perk-badge
                [small]="true"
                [color]="varPerk.perk.badge.color"
                [text]="varPerk.perk.badge.text"
                [shape]="$any(varPerk.perk.badge.shape)"
                [icon]="varPerk.perk.badge.icon || ''"
                [quantity]="varPerk.reusable ? inf : varPerk.quantity"
                [matTooltip]="
                  varPerk.perk.title +
                  (varPerk.perk.description ? ': ' + varPerk.perk.description : '') +
                  (varPerk.reusable ? ' (This perk is reusable, it does not get consumed when used!)' : '')
                "
              ></greco-perk-badge>
            </div>
          </div>
          <p *ngIf="product.description">
            <small [innerHtml]="product.description | safe : 'html'"></small>
          </p>
        </div>
      </div>
      <div class="info">
        <h4 style="line-height: 20px">{{ product.title }}</h4>
        <p>
          <mat-icon>place</mat-icon>
          <small>{{ product.community.name }}</small>
        </p>
        <mat-form-field appearance="standard" (click)="$event.stopImmediatePropagation()">
          <mat-label>Option</mat-label>
          <mat-select
            #select
            [value]="selectedVariant"
            (valueChange)="selectedVariant = $event; changeImage(selectedVariant)"
          >
            <mat-select-trigger>
              <strong>{{ select.value | variantPrice }}</strong> -
              {{
                select.value.title
                  ? select.value.title + ' (' + (select.value.variantOpts | pluck : 'value')?.join(', ') + ')'
                  : (select.value.variantOpts | pluck : 'value')?.join(', ')
              }}
            </mat-select-trigger>

            <mat-option
              *ngFor="let variant of sortedVariants$ | async"
              [value]="variant"
              matTooltip="{{
                variant.title
                  ? variant.title + ' (' + (variant.variantOpts | pluck : 'value')?.join(', ') + ')'
                  : (variant.variantOpts | pluck : 'value')?.join(', ')
              }}"
              [matTooltipShowDelay]="500"
            >
              <strong>{{ variant | variantPrice }}</strong> -
              {{
                variant.title
                  ? variant.title + ' (' + (variant.variantOpts | pluck : 'value')?.join(', ') + ')'
                  : (variant.variantOpts | pluck : 'value')?.join(', ')
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="linkedAccounts?.length; else buttonBar">
          <div
            class="button-bar"
            [matTooltip]="conditionCanBuy?.result !== true ? (conditionCanBuy?.errors || [] | grecoJoin : ', ') : ''"
          >
            <!-- <button mat-stroked-button (click)="addToCart(select.value)">Add to Cart</button> -->
            <!-- <button mat-stroked-button (click)="goToProductPage(selectedVariant)">More Info</button> -->

            <button
              mat-flat-button
              color="primary"
              [disabled]="
                conditionCanBuy?.result !== true ||
                (inventoryAddon?.enabled && (variantInventory?.availableInventoryCount || 0) < 1)
              "
              (click)="buyNow(select.value)"
            >
              <mat-icon
                *ngIf="
                  conditionCanBuy?.result !== true ||
                  (inventoryAddon?.enabled && (variantInventory?.availableInventoryCount || 0) < 1)
                "
                >lock</mat-icon
              >
              Buy Now
            </button>
            <greco-linked-account-buy-button
              [linkedAccounts]="linkedAccounts"
              [userId]="userId"
              [selectedVariant]="selectedVariant!"
              [conditionCanBuy]="conditionCanBuy?.result !== true"
            ></greco-linked-account-buy-button>
          </div>
          <button mat-stroked-button style="width: 100%; margin-top: 8px" (click)="goToProductPage(selectedVariant)">
            More Info
          </button>
        </div>
        <ng-template #buttonBar>
          <div
            class="button-bar"
            [matTooltip]="conditionCanBuy?.result !== true ? (conditionCanBuy?.errors || [] | grecoJoin : ', ') : ''"
          >
            <!-- <button mat-stroked-button (click)="addToCart(select.value)">Add to Cart</button> -->
            <button mat-stroked-button (click)="goToProductPage(selectedVariant)">More Info</button>

            <button
              mat-flat-button
              color="primary"
              [disabled]="
                conditionCanBuy?.result !== true ||
                (inventoryAddon?.enabled && (variantInventory?.availableInventoryCount || 0) < 1)
              "
              (click)="buyNow(select.value)"
            >
              <mat-icon
                *ngIf="
                  conditionCanBuy?.result !== true ||
                  (inventoryAddon?.enabled && (variantInventory?.availableInventoryCount || 0) < 1)
                "
                >lock</mat-icon
              >
              Buy Now
            </button>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</greco-box>
