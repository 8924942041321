import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  VideoCollection,
  VideoCollectionSecurityResource,
  VideoCollectionSecurityResourceAction,
} from '@greco/video-library';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CreateNewSubCollectionDialog } from '../../dialogs';
import { CollectionsService, VideoSubCollectionsService } from '../../services';
@Component({
  selector: 'greco-program-admin-page',
  templateUrl: './program-admin.page.html',
  styleUrls: ['./program-admin.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProgramAdminPage {
  constructor(
    private plistSvc: VideoSubCollectionsService,
    private programSvc: CollectionsService,
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private comSecSvc: CommunitySecurityService,
    private snacks: MatSnackBar
  ) {}

  form = this.formBuilder.group({
    title: ['', Validators.required],
    description: [''],
    icon: [''],
    availableOn: null,
    availableUntil: null,
    image: [null],
    featured: null,
  });

  changed = false;

  @PropertyListener('collection') collection$ = new ReplaySubject<VideoCollection>(1);
  @Input() collection!: VideoCollection | null;

  @PropertyListener('initialSubId') initialSubId$ = new ReplaySubject<string>(1);
  @Input() initialSubId!: string | null;

  refresh$ = new BehaviorSubject(null);

  playlists$ = combineLatest([this.collection$, this.refresh$]).pipe(
    switchMap(async ([collection]) => {
      this.form.setValue({
        title: collection?.title || null,
        icon: collection?.icon || null,
        description: collection?.description || null,
        availableOn: collection?.availableOn || null,
        availableUntil: collection?.availableUntil || null,
        image: collection?.imageURL ? [new File([], collection.imageURL)] : null,
        featured: collection.featured ?? null,
      });
      return (await this.plistSvc.getPlaylists(collection.id)).sort((a, b) => a.sortIndex - b.sortIndex) || [];
    })
  );

  canRead$ = this.collection$.pipe(
    switchMap(async collection => {
      return collection
        ? await this.comSecSvc.hasAccess(
            collection.communityId,
            VideoCollectionSecurityResource.key,
            VideoCollectionSecurityResourceAction.READ
          )
        : null;
    })
  );

  canManage$ = this.collection$.pipe(
    switchMap(async collection => {
      return collection
        ? await this.comSecSvc.hasAccess(
            collection.communityId,
            VideoCollectionSecurityResource.key,
            VideoCollectionSecurityResourceAction.MANAGE
          )
        : null;
    })
  );

  async createNewSub() {
    if (!this.collection) return;
    const length = (await toPromise(this.playlists$)).length;
    const dialog = this.matDialog.open(CreateNewSubCollectionDialog, {
      data: { collectionId: this.collection.id, sortIndex: length },
    });
    const subCollection = await toPromise(dialog.afterClosed());
    if (subCollection.id) {
      this.refresh$.next(null);
      this.snacks.open('Subcollection successfully created', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
    }
  }

  registerChange() {
    this.changed = true;
  }

  async save() {
    if (this.collection) {
      const title = this.form.get('title')?.value || this.collection.title;
      const icon = this.form.get('icon')?.value || this.collection.icon;
      const description = this.form.get('description')?.value || this.collection.description;
      const availableOn = this.form.get('availableOn')?.value || null;
      const availableUntil = this.form.get('availableUntil')?.value || null;
      const image = this.form.get('image')?.value || null;
      const featured = this.form.get('featured')?.value;
      if (!title) return;

      if (image && image.name !== this.collection.imageURL) {
        const formData = new FormData();
        formData.append('file', image[0] as File);
        await this.programSvc.updateProgramImage(this.collection.id, formData);
      } else if (!image && this.collection.imageURL) {
        await this.programSvc.removeProgramImage(this.collection.id);
      }

      const response = await this.programSvc.updateProgram(this.collection.id, {
        title,
        icon,
        description,
        availableOn,
        availableUntil,
        featured,
      });
      if (response) {
        this.form.markAsPristine();
        this.changed = false;
        this.collection = response;
        this.refresh$.next(null);
        this.snacks.open('Collection successfully updated', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      }
    }
  }

  async changeStatus() {
    if (this.collection) {
      const isActive =
        this.collection.availableOn &&
        this.collection.availableOn.getTime() < Date.now() &&
        (!this.collection.availableUntil || this.collection.availableUntil.getTime() > Date.now());
      try {
        const availableOn = isActive ? this.collection.availableOn : new Date();
        const availableUntil = isActive
          ? new Date()
          : this.collection.availableUntil && this.collection.availableUntil.getTime() < Date.now()
          ? null
          : this.collection.availableUntil;

        const response = await this.programSvc.updateProgram(this.collection.id, {
          availableOn: availableOn,
          availableUntil: availableUntil,
        });
        if (response) {
          this.collection = response;
          this.refresh$.next(null);
          this.snacks.open('Status changed successfully', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
        }
      } catch (err) {
        console.error(err);
        this.snacks.open('Oops, something went wrong. Please try again.', 'Ok', {
          duration: 5000,
          panelClass: 'mat-warn',
        });
      }
    }
  }
}
