<div *ngIf="activeUnlock">
  <greco-collapsible-section
    id="details-section"
    [header]="{
      title: collectionVideo.titleOverride || collectionVideo.communityVideo?.video?.title || '',
      icon: 'ondemand_video'
    }"
    [disabled]="disabled"
    [expanded]="expanded"
  >
    <div header *ngIf="_expiresIn$ | async as expiresIn">
      <mat-chip>Expires {{ expiresIn }}</mat-chip>
    </div>
    <greco-video-player-wrapper
      *ngIf="collectionVideo.communityVideo && collectionVideo.communityVideo.video"
      [video]="collectionVideo.communityVideo.video"
    ></greco-video-player-wrapper>

    <!-- <div id="iframe-container">
      <iframe
        *ngIf="collectionVideo.communityVideo && collectionVideo.communityVideo.video"
        [src]="collectionVideo.communityVideo.video.embedUrl | safe: 'resourceUrl'"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      ></iframe>
    </div> -->

    <div>
      <div
        style="display: flex; align-items: center; padding: 24px 16px 8px"
        *ngIf="collectionVideo.communityVideo && collectionVideo.communityVideo.video"
      >
        <greco-video-tag
          *ngFor="let tag of collectionVideo.communityVideo.video.tags"
          [tag]="tag"
          [icon]="'smart_display'"
        >
        </greco-video-tag>
      </div>

      <ng-container *grecoLet="collectionVideo.collection as collection">
        <ng-container *grecoLet="collectionVideo.subCollection as subcollection">
          <div
            style="
              padding: 16px;
              gap: 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
            "
          >
            <div style="display: flex; gap: 12px">
              <div style="display: flex; flex-direction: column; justify-content: space-evenly">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
                    >place</mat-icon
                  >
                  <strong>{{ collection?.community?.name }}</strong>
                </div>
                <h3 style="margin: 0">{{ subcollection?.title + ' of ' + collection?.title }}</h3>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </greco-collapsible-section>
  <br *ngIf="!canMakeChange" />
  <greco-collapsible-section
    *ngIf="collectionVideo.descriptionOverride || collectionVideo.communityVideo?.video?.description"
    id="details-section"
    [header]="{ title: 'Description', icon: 'information' }"
    [expanded]="false"
  >
    <p style="padding-left: 16px">
      {{ collectionVideo.descriptionOverride || collectionVideo.communityVideo?.video?.description }}
    </p>
  </greco-collapsible-section>
</div>
<greco-collapsible-section
  *ngIf="!activeUnlock"
  [header]="{
    icon: 'ondemand_video',
    title: collectionVideo.titleOverride || collectionVideo.communityVideo?.video?.title || ''
  }"
  [disabled]="true"
>
  <div header>
    <mat-chip *ngIf="user"
      >Will Expire: {{ selectedPerkRentalPeriod ? (selectedPerkRentalPeriod | date) : 'Never' }}</mat-chip
    >
  </div>
  <div style="display: flex; gap: 16px; flex-wrap: wrap">
    <greco-box
      style="flex: 1; height: 100%; position: relative; display: flex"
      matTooltip="{{ collectionVideo.descriptionOverride || collectionVideo.communityVideo?.video?.description }}"
    >
      <img
        *ngIf="collectionVideo.communityVideo && collectionVideo.communityVideo.video"
        [src]="collectionVideo.communityVideo.video.thumbnailUrl || '/assets/lf3/logo.png' | safe : 'resourceUrl'"
        alt="Thumbnail"
        style="width: 120px; aspect-ratio: 16 9; max-height: 100px; object-fit: cover; border-radius: 4px"
      />

      <div
        style="margin-left: 12px; width: 100%"
        *ngIf="collectionVideo.communityVideo && collectionVideo.communityVideo.video"
      >
        <h3 style="margin-bottom: 12px">
          <strong>{{ collectionVideo.titleOverride || collectionVideo.communityVideo.video.title }}</strong>
        </h3>
        <mat-chip-list>
          <greco-video-tag
            *ngFor="let tag of collectionVideo.communityVideo.video.tags"
            [admin]="false"
            [tag]="tag"
            [icon]="'smart_display'"
          ></greco-video-tag>
        </mat-chip-list>
      </div>
      <p *ngIf="collectionVideo.communityVideo && collectionVideo.communityVideo.video">
        {{
          collectionVideo.communityVideo.video.duration
            ? (collectionVideo.communityVideo.video.duration | grecoDuration)
            : ''
        }}
      </p>
    </greco-box>

    <div style="flex: 2">
      <ng-container>
        <ng-container>
          <h3 style="margin: 0">From Collection:</h3>
          <div
            *grecoLet="collectionVideo.collection as collection"
            style="
              padding: 12px 8px;
              gap: 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
            "
          >
            <div style="display: flex; gap: 12px">
              <div style="display: flex; flex-direction: column; justify-content: space-evenly">
                <h3 style="margin: 0">{{ collectionVideo.subCollection?.title + ' of ' + collection?.title }}</h3>
                <div style="display: flex; align-items: center; font-size: 14px">
                  <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
                    >place</mat-icon
                  >
                  <strong>{{ collection?.community?.name }}</strong>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</greco-collapsible-section>
