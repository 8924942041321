<greco-collapsible-section
  [header]="{
    title: 'Admin Fee (Failed Purchases)',
    icon: 'production_quantity_limits'
  }"
>
  <mat-checkbox
    header
    *ngIf="adminFee"
    color="primary"
    labelPosition="before"
    style="margin-right: 16px"
    [checked]="adminFee.enabled"
    (click)="$event.stopImmediatePropagation(); toggleEnabled(!adminFee.enabled)"
  >
    Enabled
  </mat-checkbox>
  <greco-form-save-buttons
    header
    [form]="formGroup"
    [saveAction]="onSave"
    [resetValue]="initialValue"
  ></greco-form-save-buttons>
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Label (shown on invoice as line item)</mat-label>
      <input matInput formControlName="label" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Description (shown on invoice as line item)</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Amount</mat-label>
      <input matInput formControlName="amount" autocomplete="off" currencyMask [options]="currencyMaskConfig" />
    </mat-form-field>
  </form>
</greco-collapsible-section>
