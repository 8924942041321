import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { Resource, Tag } from '@greco/booking-events';
import { EventVideo, EventVideoUnlockOption } from '@greco/event-videos';
import { User } from '@greco/identity-users';
import { UserEventsDateFiltersComponent } from '@greco/ngx-booking-events';
import { SignInComponent } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { UserPerk } from '@greco/sales-perks';
import { VideoTag } from '@greco/videos';
import moment from 'moment';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { SwiperComponent } from 'swiper/angular';
import { EventVideosService, EventVideoUnlockService } from '../../services';

@Component({
  selector: 'greco-locked-gallery',
  templateUrl: './locked-gallery.component.html',
  styleUrls: ['./locked-gallery.component.scss'],
})
export class LockedGalleryComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private videosSvc: EventVideosService,
    private unlockSvc: EventVideoUnlockService,
    private matDialog: MatDialog,
    private breakpoints: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  readonly dateRange$ = new ReplaySubject<[Date, Date]>(1);
  readonly trainers$ = new ReplaySubject<Resource[]>(1);
  readonly eventTags$ = new ReplaySubject<Tag[]>(1);
  readonly videoTags$ = new ReplaySubject<VideoTag[]>(1);
  @PropertyListener('user') private _user$ = new ReplaySubject<User>(1);
  @Input() user?: User | null;

  @ViewChild(UserEventsDateFiltersComponent) dateFilter?: UserEventsDateFiltersComponent;
  @ViewChild(SwiperComponent) swiper?: SwiperComponent;

  mobileView = false;
  openIndex = 0;

  private _onDestroy$ = new Subject<void>();
  private readonly unlockOptions$ = this._user$.pipe(switchMap(user => this.unlockSvc.getUserUnlockOptions(user?.id)));

  readonly eventVideos$ = combineLatest([this.unlockOptions$, this.dateRange$]).pipe(
    switchMap(async ([unlockOptions, dateRange]) => {
      const optionTags: string[] = [];
      for (const perk of unlockOptions) {
        const option = perk.perk as EventVideoUnlockOption;
        for (const tag of option.videoTags) {
          if (!optionTags.some(id => id === tag.id)) {
            optionTags.push(tag.id);
          }
        }
      }
      const startDate = moment(dateRange[0]).startOf('day').toDate();
      const endDate = moment(dateRange[1]).endOf('day').toDate();
      return await this.videosSvc.getByEventdate({
        startDate: startDate,
        endDate: endDate.getTime() > Date.now() ? new Date() : endDate,
        //userId: true
        //trainerIds,
        //eventTagsIds,
        videoTagIds: optionTags,
      });
    })
  );

  readonly items$ = combineLatest([this.unlockOptions$, this.eventVideos$, this.trainers$, this.eventTags$]).pipe(
    switchMap(async ([unlockOptions, eventVideos, trainers, eventTags]) => {
      //if (!unlockOptions.length) return [];
      const items: {
        date: Date;
        videos: { eventVideo: EventVideo; options: UserPerk[] }[];
      }[] = [];
      const trainerIds: string[] = trainers?.length ? trainers.map(trainer => trainer.groupId) : [];
      const eventTagsIds: string[] = eventTags?.length ? eventTags.map(tag => tag.id) : [];
      for (const eventVideo of eventVideos) {
        const event = eventVideo.event;
        const unlock = (eventVideo as any).unlock;
        if (!event || (!unlockOptions.length && !unlock)) continue;
        if (eventTagsIds.length) {
          //filter event tags
          const tags: string[] = [];
          for (const tagId of eventTagsIds) {
            if (eventVideo.event?.tags.some(eventTag => eventTag.id === tagId)) {
              tags.push(tagId);
            }
          }
          if (tags.length < 1) continue;
        }

        //filter trainers
        if (trainerIds.length) {
          const trainers: string[] = [];
          for (const trainerId of trainerIds) {
            if (
              eventVideo.event?.resourceAssignments?.some(
                a => a?.resource?.groupId === trainerId && !a?.resource?.disabled
              )
            ) {
              trainers.push(trainerId);
            }
          }
          if (trainers.length < 1) continue;
        }
        const date = moment(eventVideo.event?.endDate).startOf('day').toDate();

        if (!items.some(item => item.date.getTime() === date.getTime())) {
          //This event is a new date in the list so we need to add it completely
          items.push({ date: date, videos: [] });
        }
        const dateIndex = items.findIndex(item => item.date.getTime() === date.getTime());
        items[dateIndex].videos.push({
          eventVideo: eventVideo,
          options: unlockOptions.filter(up => {
            const unlockOption = up.perk as EventVideoUnlockOption;
            if (
              event.startDate < moment().subtract(unlockOption.unlockWindow, 'minutes').startOf('day').toDate() &&
              unlockOption.unlockWindow > 0
            ) {
              return false;
            }
            if (
              !eventVideo.communityVideo?.video?.tags?.some(tag => {
                return unlockOption.videoTags.some(t => t.id === tag.id);
              })
            ) {
              return false;
            }
            return true;
          }),
        });
      }
      return items;
    }),
    map(items => items.sort((a, b) => b.date.getTime() - a.date.getTime()))
  );

  dayClicked(date: Date, items: { date: Date }[]) {
    const elementId = moment(date).format('YYYYMMDD');
    const scrollElement = document.getElementById(elementId);
    if (scrollElement) scrollElement.scrollIntoView({ behavior: 'smooth' });

    this.openIndex = items.findIndex(itemDate => itemDate.date.valueOf() === date.valueOf());
  }

  async signIn() {
    const _dialog = this.matDialog.open(SignInComponent, { data: {}, width: '100%', maxWidth: '400px' });
    await toPromise(_dialog.afterClosed());
  }

  async initialNavigation() {
    const options = (await toPromise(this.unlockOptions$))
      .map(perk => perk.perk as EventVideoUnlockOption)
      .sort((a, b) => b.unlockWindow - a.unlockWindow);
    const optionTags: string[] = [];
    for (const option of options) {
      for (const tag of option.videoTags) {
        if (!optionTags.some(id => id === tag.id)) {
          optionTags.push(tag.id);
        }
      }
    }
    const longestWindow = options.length ? options[0].unlockWindow : 0;
    const longestDate = moment().subtract(longestWindow, 'minutes').toDate();
    const startDate = moment(longestDate).startOf('day').toDate();
    const endDate = moment(new Date()).endOf('day').toDate();
    const eventVideos = await this.videosSvc.getRecentByDate({
      startDate: startDate,
      endDate: endDate.getTime() > Date.now() ? new Date() : endDate,
      videoTagIds: optionTags,
    });
    const navigateTo = eventVideos && eventVideos.length ? eventVideos[0].event?.startDate : new Date();
    navigateTo ? this.dateFilter?.goTo(navigateTo) : {};
  }

  async ngOnInit() {
    this.breakpoints
      .observe('(max-width: 600px)')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(({ matches }) => {
        this.mobileView = matches;
        this.changeDetectorRef.detectChanges();
      });
  }

  async ngAfterViewInit() {
    await this.initialNavigation();
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
