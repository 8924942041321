<div *ngIf="canRead$ | async">
  <greco-collapsible-section id="videos_section" [header]="{ title: 'Events', icon: 'event' }" [expanded]="false">
    <greco-table [highlight]="true" [data]="(data$ | async) || []">
      <mat-icon *grecoTableCol="''; fitContent: true; let video" [matTooltip]="video.id"> video_settings </mat-icon>

      <!-- Event Title -->
      <ng-container>
        <p *grecoTableCol="'Event'; let video" [matTooltip]="video.event.title">
          {{ video.event.title }}
        </p>
      </ng-container>
      <!-- Date -->
      <ng-container>
        <p *grecoTableCol="'Start Date'; let video" [matTooltip]="'Start Date of Event'">
          {{ video.event.startDate | date }}
        </p>
      </ng-container>

      <!-- tags -->
      <ng-container>
        <p *grecoTableCol="'Tags'; let video" [matTooltip]="'Tags'">
          <mat-chip style="margin-right: 3px" *ngFor="let tag of video.event.tags">{{ tag.label }}</mat-chip>
        </p>
      </ng-container>

      <!-- Status -->
      <ng-container>
        <p *grecoTableCol="'Status'; fitContent: true; let video" [matTooltip]="'Status'">
          <mat-chip>{{
            video.availableOn &&
            (video.availableOn | grecoDateCompare : 'lt') &&
            (!video.availableUntil || (video.availableUntil | grecoDateCompare : 'gt'))
              ? 'Active'
              : !video.availableOn && !video.availableUntil
              ? 'Draft'
              : 'Archived'
          }}</mat-chip>
        </p>
      </ng-container>

      <!-- Options -->
      <ng-container *ngIf="(canDelete$ | async) || (canUpdate$ | async)">
        <ng-container *grecoTableCol="''; let video; fitContent: true; stickyEnd: true">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matTooltip]="'Options for ' + video.event.title"
            (click)="$event.stopImmediatePropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <!-- Manage Event -->
            <button mat-menu-item (click)="manage(video)">
              <mat-icon>event</mat-icon>
              <span>Manage Event</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </greco-table>
  </greco-collapsible-section>
</div>
