import { AfterViewInit, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { FilterBarComponent, FilterBarValue } from '@greco/ngx-filters';
import { UserService } from '@greco/ngx-identity-auth';
import { SaleCategoryPickerComponent } from '@greco/ngx-sales-purchases';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SubscriptionResource, SubscriptionResourceAction } from '@greco/sales-subscriptions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BulkCancelSubscriptionDialog, BulkUpdateSubscriptionDialog } from '../../dialogs';
import { BulkStartSubscriptionDialog } from '../../dialogs/bulk-start-subscription-dialog/bulk-start-subscription-dialog';
import { SubscriptionsService } from '../../services';
import {
  ShowProcessingFilter,
  SubscriptionMinimumCommitmentFilter,
  SubscriptionRenewalDateFilter,
  SubscriptionSoldByFilter,
  SubscriptionUpdateDateFilter,
} from './filters';
import { SubscriptionCustomerFilter } from './filters/customer';
import { SubscriptionSearchFilter } from './filters/search';
import { SubscriptionStartDateFilter } from './filters/start-date';
import { SubscriptionStatusFilter } from './filters/status';

interface Actions {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'greco-subscriptions-page',
  templateUrl: './subscriptions.page.html',
  styleUrls: ['./subscriptions.page.scss'],
})
export class SubscriptionsPage implements AfterViewInit {
  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService,
    private filter: SubscriptionCustomerFilter,
    private subscriptionSvc: SubscriptionsService,
    private soldByFilter: SubscriptionSoldByFilter,
    private userSvc: UserService
  ) {}

  @ViewChild(FilterBarComponent) private filterBar?: FilterBarComponent;
  @ViewChild('saleCategoryPicker') saleCategoryPicker!: SaleCategoryPickerComponent;

  @PropertyListener('productVariantIds') private _productVariantIds$ = new BehaviorSubject<string[]>([]);
  @Input() productVariantIds!: string[];

  @Input() productVariant!: TemplateRef<any>;

  @Input() accountId?: string;
  @Input() communityId?: string;

  actions: Actions[] = [
    { value: 'start', viewValue: 'Start Subscription' },
    { value: 'cancel', viewValue: 'Cancel Subscription' },
    { value: 'update', viewValue: 'Update Product' },
    { value: 'stop-update', viewValue: 'Stop Update' },
    { value: 'stop-cancellation', viewValue: 'Stop Cancellation' },
  ];

  selectedAction = this.actions[0].viewValue;

  bulkSubscriptionInfo = new BehaviorSubject<any>('');

  filterOptions = [
    SubscriptionSearchFilter,
    SubscriptionCustomerFilter,
    SubscriptionSoldByFilter,
    SubscriptionStatusFilter,
    SubscriptionStartDateFilter,
    SubscriptionUpdateDateFilter,
    SubscriptionRenewalDateFilter,
    SubscriptionMinimumCommitmentFilter,
    ShowProcessingFilter,
  ];

  form = this.formBuilder.group({
    variants: [[], Validators.minLength(1)],
  });

  canSeeStats$ = this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.SEE_STATS, {}, true);
  canBulkStart$ = this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.BULK_START, {}, true);
  canBulkCancel$ = this.securitySvc.hasAccess(
    SubscriptionResource.key,
    SubscriptionResourceAction.BULK_CANCEL,
    {},
    true
  );

  canBulkUpdate$ = this.securitySvc.hasAccess(
    SubscriptionResource.key,
    SubscriptionResourceAction.BULK_UPDATE,
    {},
    true
  );

  filters$ = new BehaviorSubject<{ subscriptions: RequestQueryBuilder; actions: RequestQueryBuilder }>({
    subscriptions: new RequestQueryBuilder(),
    actions: new RequestQueryBuilder(),
  });

  isSuperAdmin$ = this.userSvc.user$.pipe(switchMap(async user => user?.isSuperAdmin || false));

  @PropertyListener('communityId')
  private async _communityUpdated() {
    this.filter.communityId = this.communityId;
    this.soldByFilter.communityId = this.communityId;

    this.filter.description;
    if (this.filterBar) {
      this.filterBar.values = [];
    }
  }

  filterBarData(filterValues: FilterBarValue[]) {
    const subscriptionFilters: FilterBarValue[] = [];
    const actionFilters: FilterBarValue[] = [];

    for (const filterValue of filterValues) {
      if (
        filterValue.filter instanceof SubscriptionStartDateFilter ||
        filterValue.filter instanceof SubscriptionUpdateDateFilter ||
        filterValue.filter instanceof SubscriptionRenewalDateFilter
      ) {
        actionFilters.push(filterValue);
      } else {
        subscriptionFilters.push(filterValue);
      }
    }
    const selectedSCs = this.saleCategoryPicker.selected$.value;

    const variantIds = this.productVariantIds.filter(id => !!id);
    this.filters$.next({
      subscriptions: new RequestQueryBuilder().search({
        $and: [
          ...(variantIds.length ? [{ 'items.variantId': { $in: variantIds } }] : []),
          ...subscriptionFilters.map(({ filter, operator, value }) =>
            filter.getQueryBuilderSearchOptions(operator, value)
          ),
          {
            'saleCategory.id': {
              $in: selectedSCs.length ? selectedSCs.map(s => s.id) : ['not_a_sale_category'],
            },
          },
        ],
      }),
      actions: new RequestQueryBuilder().search({
        $and: actionFilters.map(({ filter, operator, value }) => filter.getQueryBuilderSearchOptions(operator, value)),
      }),
    });
  }

  navigateToSubscription(subscriptionId: string) {
    return this.router.navigate([subscriptionId], { relativeTo: this.route });
  }

  async getSubscriptionBulkInfo($event: any) {
    this.bulkSubscriptionInfo.next($event);
  }

  async executeAction(action: string) {
    if (!this.accountId) return;

    const ids = this.productVariantIds.filter(id => !!id);
    this.filters$.next({
      subscriptions: new RequestQueryBuilder().search({
        $and: [
          ...(ids.length ? [{ 'items.variantId': { $in: ids } }] : []),
          ...((this.filters$.value.subscriptions.queryObject.s &&
            JSON.parse(this.filters$.value.subscriptions.queryObject.s).$and.filter(
              (c: any) => !c['saleCategory.id']
            )) ||
            []),
          {
            'saleCategory.id': {
              $in: this.saleCategoryPicker.selected$.value.length
                ? this.saleCategoryPicker.selected$.value.map(s => s.id)
                : ['not_a_sale_category'],
            },
          },
        ],
      }),
      actions: new RequestQueryBuilder().search({
        $and: this.filters$.value.actions.queryObject.s
          ? JSON.parse(this.filters$.value.actions.queryObject.s).$and
          : [],
      }),
    });

    switch (action) {
      case 'start':
        await toPromise(
          this.matDialog
            .open(BulkStartSubscriptionDialog, {
              data: {
                title: 'Bulk Start Subscriptions',
                subtitle: `When should the subscriptions be started? `,
                stats: this.bulkSubscriptionInfo.value,
                accountId: this.accountId,
                filters: this.filters$.value,
              } as any,
            })
            .afterClosed()
        );
        this.filters$.next(this.filters$.value);
        break;

      case 'cancel':
        await toPromise(
          this.matDialog
            .open(BulkCancelSubscriptionDialog, {
              data: { accountId: this.accountId, filters: this.filters$.value } as any,
            })
            .afterClosed()
        );
        break;

      case 'update':
        await toPromise(
          this.matDialog
            .open(BulkUpdateSubscriptionDialog, {
              data: {
                accountId: this.accountId,
                filters: this.filters$.value,
                stats: this.bulkSubscriptionInfo.value,
              } as any,
            })
            .afterClosed()
        );
        break;
      case 'stop-update':
        this.subscriptionSvc.bulkstopUpdateSubscription(this.accountId, this.filters$.value);
        break;
      case 'stop-cancellation':
        this.subscriptionSvc.bulkStopCancellation(this.accountId, this.filters$.value);
        break;
      default:
        'Unknown action';
        break;
    }
  }

  ngAfterViewInit() {
    this.saleCategoryPicker.selected$.subscribe(selectedSCs => {
      const newRequest = new RequestQueryBuilder();
      const variantIds = this.productVariantIds.filter(id => !!id);
      newRequest.search({
        $and: [
          ...(variantIds.length ? [{ 'items.variantId': { $in: variantIds } }] : []),
          ...((this.filters$.value.subscriptions.queryObject.s &&
            JSON.parse(this.filters$.value.subscriptions.queryObject.s).$and.filter(
              (c: any) => !c['saleCategory.id']
            )) ||
            []),
          {
            'saleCategory.id': {
              $in: selectedSCs.length ? selectedSCs.map(s => s.id) : ['not_a_sale_category'],
            },
          },
        ],
      });
      this.filters$.next({
        ...this.filters$.value,
        subscriptions: newRequest,
      });
    });
  }

  async fixPerks() {
    if (!this.accountId) return;

    await this.subscriptionSvc.startFixPerks(this.accountId);
  }
}
