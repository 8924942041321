import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { PaymentMethod, UserPaymentMethodResource, UserPaymentMethodResourceAction } from '@greco/finance-payments';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PaymentMethodSubscriptionsDialog, UpdatePaymentMethodDialog } from '../../dialogs';
import { UserPaymentMethodService } from '../../services';

@Component({
  selector: 'greco-manager-user-payment-methods',
  templateUrl: './manage-payment-methods.component.html',
  styleUrls: ['./manage-payment-methods.component.scss'],
})
export class UserPaymentMethodsComponent implements OnChanges {
  constructor(
    private paymentMethodSvc: UserPaymentMethodService,
    private securitySvc: SecurityService,
    private dialog: MatDialog
  ) {}

  @PropertyListener('userId') private _userId$ = new BehaviorSubject<string>('');
  @Input() userId?: string;

  @Output() defaultPaymentMethodUpdated = new EventEmitter<PaymentMethod>();

  hasRemoveAccess = false;

  loading = true;
  paymentMethods: PaymentMethod[] = [];
  defaultPaymentMethod?: PaymentMethod;
  usedPaymentMethods?: string[];

  canChangeDefaultPaymentMethod$ = this._userId$.pipe(
    switchMap(async userId => {
      return await this.securitySvc.hasAccess(
        UserPaymentMethodResource.key,
        UserPaymentMethodResourceAction.SET_DEFAULT,
        { userId }
      );
    })
  );

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userId?.currentValue !== changes.userId?.previousValue) {
      await this.refresh();
    }
  }

  async selectDefaultPaymentMethod(paymentMethod: PaymentMethod) {
    this.defaultPaymentMethod = paymentMethod;

    this.defaultPaymentMethodUpdated.emit(this.defaultPaymentMethod);
    await this.paymentMethodSvc.setDefault(paymentMethod.id);

    await this.refresh();
  }

  async updatePaymentMethodExpiry(paymentMethodId: string, expiry: string) {
    const result = await toPromise(
      this.dialog.open(UpdatePaymentMethodDialog, { data: { paymentMethodId, expiry } }).afterClosed()
    );
    if (result) await this.refresh();
  }

  viewSubscriptions(paymentMethod: PaymentMethod) {
    this.dialog.open(PaymentMethodSubscriptionsDialog, { data: { paymentMethod, userId: this.userId } });
  }

  async refresh() {
    await Promise.all([
      this.loadDefaultPaymentMethod(),
      this.loadPaymentMethods(),
      this.loadUsedPaymentMethods(),
      this.loadHasRemoveAccess(),
    ]);
  }

  async removePaymentMethod(paymentMethodId: string) {
    await this.paymentMethodSvc.removePaymentMethod(paymentMethodId);
    await this.refresh();
  }

  private async loadDefaultPaymentMethod() {
    if (this.userId) {
      this.defaultPaymentMethod = await this.paymentMethodSvc.getDefault(this.userId, true).catch(() => undefined);
    } else {
      this.defaultPaymentMethod = undefined;
    }
  }

  private async loadPaymentMethods() {
    setTimeout(() => (this.loading = true));

    if (this.userId) {
      this.paymentMethods = await this.paymentMethodSvc.getAll(this.userId).catch(() => []);
    } else {
      this.paymentMethods = [];
    }

    setTimeout(() => (this.loading = false));
  }

  private async loadUsedPaymentMethods() {
    if (this.userId) this.usedPaymentMethods = await this.paymentMethodSvc.getUsedPaymentMethods(this.userId);
    else this.usedPaymentMethods = [];
  }

  private async loadHasRemoveAccess() {
    if (this.userId) {
      this.hasRemoveAccess = await this.securitySvc.hasAccess(
        UserPaymentMethodResource.key,
        UserPaymentMethodResourceAction.REMOVE,
        { userId: this.userId }
      );
    } else this.hasRemoveAccess = false;
  }
}
