import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import {
  AgreementStatus,
  CommunityAgreementSecurityActions,
  CommunityAgreementSecurityResource,
} from '@greco/community-agreements';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SignatureService } from '@greco/ngx-identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserCommunityAgreementsService } from '../../services/user-community-agreements.service';

@Component({
  selector: 'greco-create-user-agreement',
  templateUrl: './create-user-agreement.dialog.html',
  styleUrls: ['./create-user-agreement.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateUserAgreementDialog implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private data: { communityId?: string; disableContact?: boolean; contact?: Contact },
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private signatureSvc: SignatureService,
    private securitySvc: CommunitySecurityService,
    private userAgreementService: UserCommunityAgreementsService
  ) {
    data.communityId ? (this.communityId = data.communityId) : null;
    data.disableContact ? (this.disableContact = data.disableContact) : false;
    data.contact ? (this.contact = data.contact) : null;
  }

  @ViewChild(SimpleDialog) private container!: SimpleDialog;

  @PropertyListener('communityId') communityId$ = new BehaviorSubject<string | null>(null);
  communityId: string | null = null;

  contact: Contact | null = null;

  user: User | null = null;

  signature: string | null = null;

  checked = false;

  disableContact = false;

  selectedAgreement: any;

  private _onDestroy$ = new Subject<void>();

  formGroup = this.formBuilder.group({
    contact: ['', Validators.required],
    agreement: ['', Validators.required],
    checkbox: [false, Validators.requiredTrue],
  });

  canLeaveUnsigned$ = this.communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.securitySvc.hasAccess(
            communityId,
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.LEAVE_UNSIGNED
          )
        : false
    ),
    tap(canLeaveUnsigned => {
      if (canLeaveUnsigned) {
        const control = this.formGroup.controls['checkbox'];

        control.setErrors(null);
        control.clearValidators();
        this.formGroup.patchValue({});
      }
    })
  );

  fillText(event: any) {
    this.selectedAgreement = event;
    if (this.selectedAgreement.agreementType === 'AUTO_CHECKBOX') {
      this.formGroup.patchValue({ checkbox: true });
    } else if (this.selectedAgreement.agreementType != 'AUTO_CHECKBOX') {
      this.formGroup.patchValue({ checkbox: false });
    }
  }

  setUser(event: any) {
    this.user = event?.user || null;
  }

  setSignature(event: string) {
    this.signature = event;
    if (this.signature != '') {
      this.formGroup.patchValue({ checkbox: true });
    }
  }

  ngOnInit() {
    this.checked = false;
    this.formGroup.valueChanges
      .pipe(
        startWith(this.formGroup.value),
        takeUntil(this._onDestroy$),
        map(() => this.container?.data?.buttons?.find(btn => btn.role === 'create'))
      )
      .subscribe(button => button && ((button as any).disabled = this.formGroup.invalid));

    this.formGroup.patchValue({
      contact: this.contact,
    });
  }

  getResult = async () => {
    if (!this.formGroup.value.checkbox) {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Unsigned Agreements',
          content:
            'This agreement is currently unsigned. \nContinuing will still create the agreement. The member with the unsigned agreement will not be able to book classes purchases until their agreement is signed. \n\nContinue?',
          buttons: [
            { label: 'Cancel', role: 'no' },
            { label: 'Confirm', role: 'yes', color: 'primary' },
          ],
        } as DialogData,
        width: '500px',
        maxWidth: '100%',
      });

      if ((await toPromise(dialog.afterClosed())) === 'no') return;
    }

    //saves/updates signature to user for future reference
    if (this.user && this.signature) {
      let signature: any = null;

      try {
        signature = await this.signatureSvc.getOne(this.user?.id);
      } catch (err) {
        console.log('No signature found for user, creating default now');
      }

      if (signature) {
        if (this.signature !== signature.signature) await this.signatureSvc.update(this.user?.id, this.signature);
      } else await this.signatureSvc.create({ userId: this.user.id, signature: this.signature });
    }

    try {
      const role = await this.userAgreementService.createUserAgreement({
        userId: this.contact ? this.contact?.user?.id : this.formGroup.value.contact.user.id,
        signedById: this.contact ? this.contact?.user?.id : this.formGroup.value.contact.user.id,
        agreementId: this.formGroup.value.agreement.id,
        type: this.formGroup.value.agreement.agreementType,
        signedAgreementTitle: this.formGroup.value.agreement.title,
        signedAgreementText: this.formGroup.value.agreement.text,
        signature: this.signature || null,
        status: this.formGroup.value.checkbox ? AgreementStatus.SIGNED : AgreementStatus.UNSIGNED,
      });

      this.snacks.open('New user agreement created!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
      return role;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  ngAfterViewInit(): void {
    return;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
