<greco-collapsible-section
  *ngIf="subscription"
  id="subscription-purchase-history"
  [header]="{ title: 'Purchase History', icon: 'list' }"
>
  <greco-table [data]="(purchases$ | async) || []">
    <p
      *grecoTableCol="'Purchase'; let purchase"
      style="overflow: hidden"
      [matTooltip]="(purchase.items | filterCoupon : false | pluck : 'description').join(', ')"
      [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''"
    >
      {{ (purchase.items | filterCoupon : false | pluck : 'displayName').join(', ') }}
    </p>

    <ng-container *grecoTableCol="'Purchase Type'; let purchase">
      <p *grecoLet="_purchaseTypeMappings[purchase.type] as purchaseType">
        {{ purchaseType === undefined ? purchase.type : purchaseType }}
      </p>
    </ng-container>

    <p *grecoTableCol="'Created'; let purchase" [matTooltip]="(purchase.created | date : 'full') || ''">
      {{ purchase.created | date : 'EEE' }} {{ purchase.created | date }}
    </p>

    <ng-container *grecoTableCol="'Subtotal'; let purchase; alignRight: true">
      <div
        *grecoLet="purchase | purchaseSubtotal as subtotal"
        [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''"
      >
        <p>{{ subtotal ? (subtotal / 100 | currency) : '-' }}</p>
        <p *ngIf="purchase.subtotal && purchase.subtotal !== subtotal" style="font-size: 75%">
          (Out of <strong>{{ purchase.subtotal / 100 | currency }}</strong
          >)
        </p>
      </div>
    </ng-container>

    <ng-container *grecoTableCol="'Taxes'; let purchase; alignRight: true">
      <div
        *grecoLet="purchase | purchaseTax as tax"
        [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''"
      >
        <p>{{ tax ? (tax / 100 | currency) : '-' }}</p>
        <p *ngIf="purchase.tax && purchase.tax !== tax" style="font-size: 75%">
          (Out of <strong>{{ purchase.tax / 100 | currency }}</strong
          >)
        </p>
      </div>
    </ng-container>

    <ng-container *grecoTableCol="'Total'; let purchase; alignRight: true">
      <div
        *grecoLet="purchase | purchaseTotal as total"
        [style.text-decoration]="purchase.status === 'VOIDED' ? 'line-through' : ''"
      >
        <p>
          <strong>{{ total ? (total / 100 | currency) : '-' }}</strong>
        </p>
        <p *ngIf="purchase.total && purchase.total !== total" style="font-size: 75%">
          (Out of <strong>{{ purchase.total / 100 | currency }}</strong
          >)
        </p>
      </div>
    </ng-container>

    <greco-purchase-status-chip
      *grecoTableCol="'Status'; let purchase"
      [isAdminPage]="true"
      [purchase]="purchase"
    ></greco-purchase-status-chip>
  </greco-table>

  <mat-paginator
    *ngIf="_paginationMeta?.totalItems"
    showFirstLastButtons
    style="border-top: 1px solid lightgrey"
    [pageSizeOptions]="[5, 10]"
    [length]="_paginationMeta!.totalItems || 0"
    [pageSize]="_paginationMeta!.itemsPerPage || 5"
    (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  ></mat-paginator>
</greco-collapsible-section>
