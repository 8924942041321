import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { GetHighPriorityAlertsResponse, GetLowPriorityAlertsResponse } from '@greco/domain-alerts';

@Injectable()
export class AlertsService {
  constructor(private http: HttpClient) {}

  async getHighPriorityAlerts(userId: string): Promise<GetHighPriorityAlertsResponse> {
    return await toPromise(this.http.get<GetHighPriorityAlertsResponse>(`/api/alerts/${userId}/high-priority-alerts`));
  }

  async getLowPriorityAlerts(
    userId: string,
    communityId?: string,
    startDate?: Date,
    endDate?: Date,
    timezone?: string
  ): Promise<GetLowPriorityAlertsResponse> {
    return await toPromise(
      this.http.get<GetLowPriorityAlertsResponse>(`/api/alerts/${userId}/low-priority-alerts/${communityId ?? 'all'}`, {
        params: {
          startDate: startDate?.toISOString() ?? '',
          endDate: endDate?.toISOString() ?? '',
          timezone: timezone ?? 'UTC',
        },
      })
    );
  }
}
