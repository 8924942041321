<greco-simple-dialog [data]="dialogData">
  <p>Are you sure you want to void the ${{purchase.total / 100}} purchase of {{purchase.user.displayName}}?</p>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-radio-group formControlName="voidAll" style="display: flex; gap: 8px">
      <mat-radio-button [value]="true">Void Entire Purchase</mat-radio-button>
      <mat-radio-button [value]="false">Void Specific Item(s)</mat-radio-button>
    </mat-radio-group>

    <greco-table *ngIf="!formGroup.value?.voidAll" [data]="(voidableItems$ | async) || []">
      <div *grecoTableCol="''; let item; fitContent: true; sticky: true">
        <mat-checkbox
          [checked]="item.id | containsItem: formGroup.value.items"
          (change)="$event.checked ? selectItem(item) : removeItem(item)"
        ></mat-checkbox>
      </div>
      <div *grecoTableCol="'Description'; let item">
        <p style="padding: 0; margin: 0">{{ item.displayName }}</p>
        <small>{{ item.description }}</small>
      </div>
      <mat-chip-list *grecoTableCol="'Coupons'; let item">
        <mat-chip *ngFor="let coupon of item.coupons">{{ coupon.coupon?.title }}</mat-chip>
      </mat-chip-list>
      <mat-chip *grecoTableCol="'Sale Category'; let item">{{item.saleCategory?.label || 'None'}}</mat-chip>
      <p *grecoTableCol="'Qty'; let item">{{item.quantity}}</p>
      <p *grecoTableCol="'Unit Price'; let item; alignRight: true">{{ (item.price / 100) | currency }}</p>
      <p *grecoTableCol="'Amount'; let item; alignRight: true; stickyEnd: true">
        {{ (item.amountWithCoupons / 100) | currency }}
      </p>
    </greco-table>

    <mat-form-field appearance="standard">
      <mat-label>Void Reason</mat-label>
      <mat-select formControlName="voidReason" [required]="true" [disabled]="processing">
        <mat-option *ngFor="let reason of voidReasons" [value]="reason">{{ reason }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox
      *ngIf="purchase.type === 'ProductPurchaseEntity'
  || purchase.type === 'SubscriptionStartPurchaseEntity'
  || purchase.type === 'SubscriptionUpdatePurchaseEntity'"
      style="margin-bottom: 16px"
      formControlName="returnToInventory"
      [disabled]="processing"
    >
      Return item(s) to inventory if applicable
    </mat-checkbox>

    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button
        mat-stroked-button
        type="submit"
        color="primary"
        [disabled]="formGroup.invalid || processing"
        style="flex: 1"
      >
        <span>Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
