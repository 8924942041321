import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StationDetailsIntfc } from '@greco/stations';
import { Subject } from 'rxjs';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-station-details-input',
  templateUrl: './station-details-input.component.html',
  styleUrls: ['./station-details-input.component.scss'],
})
export class StationDetailsInputComponent implements OnDestroy {
  constructor(private formBuilder: FormBuilder) {
    this._form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => this.onChanged?.(this.value));

    const zoomControl = this._form.get('enableZoom');
    if (zoomControl) {
      zoomControl.valueChanges
        .pipe(startWith(false), takeUntil(this._onDestroy$), distinctUntilChanged())
        .subscribe(value => {
          if (value) this._form.get('zoomUserInfo')?.enable();
          else this._form.get('zoomUserInfo')?.disable();
        });
    }
  }

  private onChanged?: (value: StationDetailsIntfc | null) => void;
  private onTouched?: () => void;

  private _onDestroy$ = new Subject<void>();

  readonly stateChanges = new Subject<void>();

  _form = this.formBuilder.group({
    title: ['', [Validators.required]],
  });

  _required = false;

  @Input() readonly = false;
  @Input() communityId!: string | null;

  @Input() get value() {
    const data: StationDetailsIntfc = this._form.value;
    return this._form.valid
      ? ({
          title: data.title,
        } as StationDetailsIntfc)
      : null;
  }

  set value(value: StationDetailsIntfc | null) {
    this._form.patchValue({
      title: value?.title || null,
    });

    this.stateChanges.next();
  }

  @Input() get required() {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    const validator = [...(this._required ? [Validators.required] : [])];
    this._form.get('title')?.setValidators(validator);
    this.stateChanges.next();
  }

  writeValue(value: StationDetailsIntfc): void {
    this.value = value;
  }

  registerOnChange(fn: (value: StationDetailsIntfc | null) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
