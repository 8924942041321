import { Component, Injectable, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { Community } from '@greco/identity-communities';
import { Contact } from '@greco/identity-contacts';
import { AutocompleteFilter, BuildDateFilter, BuildSearchFilter, SelectFilter } from '@greco/ngx-filters';
import { ContactService } from '@greco/ngx-identity-contacts';
import { CondOperator, RequestQueryBuilder, SFields } from '@nestjsx/crud-request';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { VideoUnlocksService } from '../../services/video-unlocks.service';

@Injectable({ providedIn: 'any' })
export class CommunityVideoSearchFilter extends BuildSearchFilter('CommunityVideoSearchFilter', {
  properties: ['video.title', 'video.tags.label'],
  propertyLabels: ['Title', 'Tags'],
}) {}

@Injectable({ providedIn: 'any' })
export class CommunityVideoUnlockUserFilter extends AutocompleteFilter {
  constructor(private contactSvc: ContactService) {
    super('CommunityVideoUnlockUserFilter', {
      description: '',
      label: 'User',
      properties: ['user.id'],
      shortLabel: 'User',
      canOpen: true,
      onlyOne: true,
    });
  }

  public communityId?: string;

  getImageUrl(value: Contact) {
    return value?.user?.photoURL ?? 'assets/lf3/icon.png';
  }

  getAutocompleteHtml(value: Contact): string {
    return value.user ? `${value.user.displayName} <small>(${value.email})</small>` : value.email;
  }

  getValueLabel(value: Contact): string {
    return value?.displayName;
  }

  async getValueOptions(search?: string): Promise<Contact[]> {
    if (!this.communityId) return [];

    const contacts = await this.contactSvc.paginateContacts(
      new RequestQueryBuilder().search({
        $and: [
          { 'user.id': { $notnull: true } },
          { $or: [{ email: { $contL: search } }, { displayName: { $contL: search } }] },
        ],
      }),
      this.communityId,
      { limit: 5 }
    );

    return contacts.items;
  }

  serializeValue(value: Contact): string {
    return value.id;
  }

  async deserializeValue(serializedValue: string) {
    return await this.contactSvc.getContact(serializedValue);
  }

  getPropertySField(property: string, operator: CondOperator, value: Contact): SFields {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { [property]: { [operator]: value.user!.id } };
  }
}

@Injectable({ providedIn: 'any' })
export class CommunityVideoUnlockDateFilter extends BuildDateFilter('CommunityVideoUnlockDateFilter', {
  label: 'Unlock Date',
  shortLabel: 'Unlock Date',
  properties: ['created'],
  description: '',
}) {}

type CommunityVideoUnlockStatus = 'Unlocked' | 'Rented' | 'Expired';
@Injectable({ providedIn: 'any' })
export class CommunityVideoUnlockStatusFilter extends SelectFilter {
  constructor() {
    super('CommunityVideoUnlockStatusFilter', {
      description: '',
      label: 'Status',
      shortLabel: 'Status',
      properties: ['_'],
    });
  }

  private allStatus: CommunityVideoUnlockStatus[] = ['Unlocked', 'Rented', 'Expired'];

  getValueLabel(value: CommunityVideoUnlockStatus | CommunityVideoUnlockStatus[]): string {
    return Array.isArray(value) ? value.join(', ') : value;
  }

  getValueOptions(search?: string): CommunityVideoUnlockStatus[] {
    if (!search) return this.allStatus;
    return this.allStatus.filter(a => a.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

  serializeValue(value: CommunityVideoUnlockStatus | CommunityVideoUnlockStatus[]): string {
    return Array.isArray(value) ? value.join(',') : value;
  }

  deserializeValue(serializedValue: string) {
    return serializedValue.split(',').filter(v => this.allStatus.includes(v as any));
  }

  getPropertySField(_property: string, operator: CondOperator, value: CommunityVideoUnlockStatus[]): SFields {
    return operator === CondOperator.EQUALS
      ? {
          $or: [
            ...(value.includes('Expired') ? [{ expiresOn: { $lte: new Date().toISOString() } }] : []),
            ...(value.includes('Rented') ? [{ expiresOn: { $gt: new Date().toISOString() } }] : []),
            ...(value.includes('Unlocked') ? [{ expiresOn: { $isnull: true } }] : []),
          ],
        }
      : operator === CondOperator.NOT_EQUALS
      ? {
          $or: [
            {
              $and: [
                ...(value.includes('Expired')
                  ? [{ $or: [{ expiresOn: { $isnull: true } }, { expiresOn: { $gt: new Date().toISOString() } }] }]
                  : []),
                ...(value.includes('Rented')
                  ? [{ $or: [{ expiresOn: { $isnull: true } }, { expiresOn: { $lte: new Date().toISOString() } }] }]
                  : []),
                ...(value.includes('Unlocked') ? [{ expiresOn: { $notnull: true } }] : []),
              ],
            },
          ],
        }
      : {};
  }
}

@Component({
  selector: 'greco-fit-video-unlocks',
  templateUrl: './video-unlocks.component.html',
  styleUrls: ['./video-unlocks.component.scss'],
})
export class VideoUnlocksComponent {
  constructor(private videoUnlocksSvc: VideoUnlocksService, private userFilter: CommunityVideoUnlockUserFilter) {}

  @Input() set community(community) {
    this.filters$.next(new RequestQueryBuilder()); // Clear filters
    this.userFilter.communityId = community?.id; // Update user filter community
    this._community$.next(community);
    this.refresh$.next(null);
  }

  get community() {
    return this._community$.value;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  now = Date.now();

  loading = false;

  filterOptions = [
    CommunityVideoSearchFilter,
    CommunityVideoUnlockUserFilter,
    CommunityVideoUnlockDateFilter,
    CommunityVideoUnlockStatusFilter,
  ];

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  // s = this.filters$.subscribe(f => console.log(JSON.parse(f.queryObject.s || '{}')));

  paginationMeta?: IPaginationMeta;

  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  refresh$ = new BehaviorSubject(null);

  private _community$ = new BehaviorSubject<Community | null>(null);

  videos$ = combineLatest([this.filters$, this.pagination$, this._community$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([query, pagination, community]) =>
      query && community ? await this.videoUnlocksSvc.paginate(community.id, false, query, pagination) : null
    ),
    tap(data => (this.paginationMeta = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
}
