<greco-simple-dialog
  [data]="dialogData"
  class="create-custom-purchase-dialog"
  *grecoLet="canSellRestrictedVariants$ | async"
>
  <form [formGroup]="form">
    <mat-vertical-stepper #stepper [selectedIndex]="currentStep" (selectionChange)="setStep($event.selectedIndex)">
      <mat-step>
        <ng-template matStepLabel>Select User</ng-template>
        <mat-form-field appearance="standard">
          <mat-label>User</mat-label>
          <greco-contact-picker [withUser]="true" formControlName="contact" [communityId]="communityId">
          </greco-contact-picker>
        </mat-form-field>
        <div>
          <button [disabled]="!form.value.contact" mat-button matStepperNext>Select Gift Card</button>
        </div>
      </mat-step>

      <ng-container *ngIf="form.value.contact">
        <mat-step>
          <ng-template matStepLabel>Select Gift Card</ng-template>
          <ng-container *grecoLet="selectedConfig$ | async as selectedConfig">
            <greco-gift-card-checkout
              *ngIf="selectedConfig?.enabled; else noConfig"
              [selectedConfig]="selectedConfig"
              [selectedOption]="selectedConfig?.options?.length ? selectedConfig!.options[0] : selectedConfig?.allowCustomAmount ? 'custom' :null"
              [configs]="selectedConfig ? [selectedConfig] : []"
              [isStaff]="true"
              [user]="form.value.contact.user"
              [self]="self$ | async"
              (purchaseSuccess)="close()"
            ></greco-gift-card-checkout>
            <ng-template #noConfig>
              <greco-empty-placeholder text="No gift cards available for this community"></greco-empty-placeholder>
            </ng-template>
          </ng-container>
        </mat-step>
      </ng-container>
    </mat-vertical-stepper>
  </form>
</greco-simple-dialog>
