import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { UsersModule } from '@greco/ngx-identity-users';
import { LetModule } from '@greco/ngx-let-util';
import { SecurityUtilsModule } from '@greco/ngx-security-util';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { TableModule } from '@greco/ui-table';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { NgxCurrencyModule } from 'ngx-currency';
import { GiftCardCheckoutComponent } from './components';
import { ConfigureGiftCardOptionDialog } from './dialogs';
import { GiftCardConfigPage, GiftCardShopPage } from './pages';
import { GetGiftCardConfigTitlePipe, GetGiftCardOptionTitlePipe } from './pipes';
import { GiftCardConfigService, GiftCardService } from './services';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
    // Material
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatRippleModule,
    MatSelectModule,
    MatTooltipModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    //Greco
    CollapsibleSectionModule,
    SecurityUtilsModule,
    LetModule,
    FormSaveButtonsModule,
    BoxModule,
    TableModule,
    SimpleDialogModule,
    PaymentsModule,
    UsersModule,
    UserAvatarModule,
  ],
  providers: [GiftCardConfigService, GiftCardService],
  declarations: [
    GiftCardConfigPage,
    GiftCardShopPage,
    ConfigureGiftCardOptionDialog,
    GetGiftCardOptionTitlePipe,
    GetGiftCardConfigTitlePipe,
    GiftCardCheckoutComponent,
  ],
  exports: [
    GiftCardConfigPage,
    GiftCardShopPage,
    GetGiftCardOptionTitlePipe,
    GetGiftCardConfigTitlePipe,
    GiftCardCheckoutComponent,
  ],
})
export class GiftCardsWebModule {}
