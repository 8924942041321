<div class="video-card-wrapper" style="position: relative" *grecoLet="validOptions$ | async as validOptions">
  <greco-video-card
    [class.locked]="!$any(collectionVideo).unlock && validOptions?.length"
    *ngIf="collectionVideo.communityVideo && collectionVideo.communityVideo.video"
    [video]="collectionVideo.communityVideo.video"
    [title]="collectionVideo.titleOverride"
    [progress]="watchTime?.watchDuration"
    matTooltip="{{
      !validOptions?.length && !$any(collectionVideo).unlock ? 'Oops! Looks like you need a perk to unlock this!' : ''
    }}"
  >
    <div lock class="info-icon" *ngIf="!$any(collectionVideo).unlock" style="margin-top: -4px; margin-left: 2px">
      <mat-icon style="color: var(--warn-color)">lock</mat-icon>
    </div>
    <div
      header
      *ngIf="!$any(collectionVideo).unlock"
      class="perk"
      style="position: absolute; top: 0; margin-top: 20px; margin-left: 20px; width: auto"
    >
      <greco-perk-badge
        *ngIf="validOptions && validOptions.length"
        [icon]="validOptions[0].perk.badge.icon"
        [color]="validOptions[0].perk.badge.color"
        [shape]="validOptions[0].perk.badge.shape"
        [small]="true"
      ></greco-perk-badge>
      <small *ngIf="validOptions && validOptions?.length">Recommended Option: {{ validOptions[0].perk.title }}</small>
    </div>
    <div
      lock
      *ngIf="$any(collectionVideo).unlock"
      style="margin-top: -4px; margin-left: 2px"
      matTooltip="This video has been unlocked!"
    >
      <mat-icon style="color: var(--primary-color)">lock_open</mat-icon>
    </div>
    <div style="height: 100%; display: flex; flex-direction: column-reverse" footer>
      <button
        mat-button
        style="width: 100%; background-color: var(--accent2-color)"
        (click)="unlock()"
        [disabled]="!validOptions?.length && !$any(collectionVideo).unlock"
      >
        <mat-icon>{{ label === 'Unlock' || label === 'Sign In' ? 'lock' : 'play_arrow' }}</mat-icon>
        {{
          label === 'Sign In'
            ? 'Sign In'
            : label === 'Unlock'
            ? validOptions && $any(validOptions[0].perk)?.rentalPeriod > 0
              ? 'Rent'
              : label
            : (watchTime?.watchDuration || 0) / (collectionVideo.communityVideo.video.duration || 0) > 0.9
            ? 'Watch Again'
            : (watchTime?.watchDuration || 0) / (collectionVideo.communityVideo.video.duration || 0) > 0.05
            ? 'Continue Watching'
            : 'Watch'
        }}
      </button>
    </div>
  </greco-video-card>
</div>
