import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { User } from '@greco/identity-users';
import { CommunityVideoUnlockService } from '@greco/ngx-community-videos';
import { PropertyListener } from '@greco/property-listener-util';
import { VideoCollection, VideoSubCollection } from '@greco/video-library';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { VideoSubCollectionsService } from '../../../services';

@Component({
  selector: 'greco-user-collection',
  templateUrl: './user-collection.page.html',
  styleUrls: ['./user-collection.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UserCollectionPage {
  constructor(
    private subcollectionSvc: VideoSubCollectionsService,
    private perkSvc: CommunityVideoUnlockService,
    private scrollDispatcher: ScrollDispatcher,
    private el: ElementRef
  ) {
    // this.scrollDispatcher.scrolled().subscribe(x => {
    //   const scrollTop = this.el.nativeElement.scrollTop;
    //   const scrollHeight = this.el.nativeElement.scrollHeight;
    //   this.checkScroll(scrollTop, scrollHeight);
    // });
  }
  lastChecked = new Date();
  selectedIndex = 0;

  @ViewChild('load') load?: ElementRef;

  @PropertyListener('collection') private _collection$ = new BehaviorSubject<VideoCollection | null>(null);
  @Input() collection!: VideoCollection;

  @PropertyListener('user') private _user$ = new BehaviorSubject<User | null>(null);
  @Input() user?: User | null;

  @PropertyListener('page') private page$ = new BehaviorSubject<number>(1);
  page = 1;
  data: VideoSubCollection[] = [];
  limit = 5;
  total = 0;
  private lastScroll = 9999;
  loading = true;
  subcollections$ = combineLatest([this._collection$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([collection, page]) => {
      const response = collection
        ? await this.subcollectionSvc.paginateForUser(collection.id, { page, limit: this.limit })
        : null;
      if (response) {
        this.total = response.meta.totalItems;
        const subcollections: VideoSubCollection[] = response.items.filter(
          item => !this.data.some((sub: VideoSubCollection) => sub.id === item.id)
        );
        this.data = [...this.data, ...subcollections];
      }
    }),
    tap(() => (this.loading = false))
  );

  unlockOptions$ = this._user$.pipe(
    switchMap(async user => {
      return await this.perkSvc.getUserUnlockOptions(user?.id);
    })
  );

  loadMore() {
    if (this.data.length < this.total) {
      console.log('load more');
      this.page++;
    } else console.log('limit reached');
  }

  async checkScroll(scrollTop: number, scrollHeight: number) {
    const scrollPosition = scrollHeight - scrollTop;
    //if (this.lastScroll > scrollPosition) {
    if (this.load) {
      const elementPostion = this.load.nativeElement.getBoundingClientRect();
      console.log(
        'scrollTop',
        scrollTop,
        'scrollHeight',
        scrollHeight,
        'position',
        scrollPosition,
        'element',
        elementPostion.y
      );
      if (scrollPosition <= elementPostion.y) {
        console.log('in view');
        this.loadMore();
      } else console.log('out of view');
    }
    // }
    // this.lastScroll = scrollPosition;
  }
}
