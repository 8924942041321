import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CreateVideoSubCollectionDto, UpdateVideoSubCollectionDto, VideoSubCollection } from '@greco/video-library';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({
  providedIn: 'root',
})
export class VideoSubCollectionsService {
  private baseUrl = '/api/video_sub_collections';
  constructor(private http: HttpClient) {}
  //@Get('')
  async getPlaylists(programId: string) {
    return await toPromise(
      this.http.get<VideoSubCollection[]>(`${this.baseUrl}`, { params: { collectionId: programId } })
    );
  }

  async paginateForUser(
    collectionId: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<VideoSubCollection>> {
    const query = new RequestQueryBuilder();
    query
      .setFilter([
        {
          field: 'availableOn',
          operator: '$lt',
          value: new Date().toISOString(),
        },
        { field: 'availableUntil', operator: '$gt', value: new Date().toISOString() },
      ])
      .setOr([
        {
          field: 'availableOn',
          operator: '$lt',
          value: new Date().toISOString(),
        },
        {
          field: 'availableUntil',
          operator: '$isnull',
        },
      ]);

    query.sortBy({ field: 'sortIndex', order: 'ASC' });
    const response = await toPromise(
      this.http.get<PaginatedDto<VideoSubCollection>>(`${this.baseUrl}/paginate`, {
        params: {
          ...query.queryObject,
          collectionId: collectionId,
          excludeEmpty: 'true',
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
    return response;
  }

  //@Get(':playlistId')
  async getOnePlaylist(playlistId: string) {
    return await toPromise(this.http.get<VideoSubCollection>(`${this.baseUrl}/${playlistId}`));
  }
  //@Post()
  async createPlaylist(dto: CreateVideoSubCollectionDto) {
    return await toPromise(this.http.post<VideoSubCollection>(`${this.baseUrl}`, dto));
  }

  //@Patch(':playlistId')
  async updatePlaylistDetails(playlistId: string, dto: UpdateVideoSubCollectionDto) {
    return await toPromise(this.http.patch<VideoSubCollection>(`${this.baseUrl}/${playlistId}`, dto));
  }

  //@Patch(':playlistId/sortIndex')
  async updateSortIndex(playlistId: string, sortIndex: number) {
    return await toPromise(
      this.http.patch<VideoSubCollection>(`${this.baseUrl}/${playlistId}`, { sortIndex: sortIndex })
    );
  }
}
