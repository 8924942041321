import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  CreateEventVideoUnlockOptionDto,
  EventVideoUnlock,
  EventVideoUnlockOption,
  EventVideoUnlockPreview,
  UnlockEventVideoDto,
  UpdateEventvideoUnlockOptionDto,
} from '@greco/event-videos';
import { UserPerk } from '@greco/sales-perks';
import { VideoUnlock } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationOptions, Pagination } from 'nestjs-typeorm-paginate';

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}
@Injectable({ providedIn: 'root' })
export class EventVideoUnlockService {
  constructor(private http: HttpClient) {}

  private baseUrl = '/api/event-videos-unlock';

  // @Get('unlocked')
  async getUserUnlockedVideos(
    query: RequestQueryBuilder,
    userId: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<EventVideoUnlock>> {
    return await toPromise(
      this.http.get<PaginatedDto<EventVideoUnlock>>(`${this.baseUrl}/unlocked`, {
        params: {
          ...query.queryObject,
          userId: userId,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get('video-unlocks')
  async getEventVideoUnlocks(
    query: RequestQueryBuilder,
    eventVideoId: string,
    pagination?: Partial<PaginatedQueryParams>,
    showExpired = false
  ): Promise<PaginatedDto<EventVideoUnlock>> {
    return await toPromise(
      this.http.get<PaginatedDto<EventVideoUnlock>>(`${this.baseUrl}/video-unlocks`, {
        params: {
          ...query.queryObject,
          eventVideoId: eventVideoId,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          showExpired: showExpired ? 'true' : 'false',
        },
      })
    );
  }

  preview_unlockEventVideo(dto: UnlockEventVideoDto) {
    return toPromise(this.http.post<EventVideoUnlockPreview>(`${this.baseUrl}/preview`, dto));
  }

  unlockEventVideo(dto: UnlockEventVideoDto, hash: string) {
    return toPromise(this.http.post<EventVideoUnlock>(`${this.baseUrl}/${hash}`, dto));
  }
  adminGrantEventVideoToUser(userEmail: string, eventVideoId: string, expiryDate: Date | null) {
    return toPromise(
      this.http.post<EventVideoUnlock>(`${this.baseUrl}/grant/${eventVideoId}`, {
        userEmail: userEmail,
        expiryDate: expiryDate,
      })
    );
  }
  // @Post('update-unlock/:id')
  updateUnlockTime(id: string, expiryDate: Date | null) {
    return toPromise(
      this.http.post<VideoUnlock>(`${this.baseUrl}/update-unlock/${id}`, {
        expiryDate: expiryDate,
      })
    );
  }

  async getUserUnlockOptions(userId?: string, videoTagIds?: string[]) {
    const options = (
      await toPromise(
        this.http.get<UserPerk[]>(`${this.baseUrl}/user-unlock-options`, {
          params: {
            videoTagIds: videoTagIds?.join(',') || '',
            ...(userId && { userId }),
          },
        })
      )
    )
      .sort((a, b) => {
        const optionA = a.perk as EventVideoUnlockOption;
        const optionB = b.perk as EventVideoUnlockOption;
        return optionA.price - optionB.price;
      })
      .sort((a, b) => Number(b.consumable) - Number(a.consumable));
    return options;
  }

  paginate(
    query: RequestQueryBuilder,
    communityId: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<EventVideoUnlockOption>> {
    return toPromise(
      this.http.get<PaginatedDto<EventVideoUnlockOption>>(`${this.baseUrl}/unlock-options`, {
        params: {
          communityId,
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  async paginateUnlockOptions(query: RequestQueryBuilder, communityId: string, pagination?: IPaginationOptions) {
    return await toPromise(
      this.http.get<Pagination<EventVideoUnlockOption>>(`${this.baseUrl}/unlock-options`, {
        params: {
          ...query.queryObject,
          communityId,
          ...paginationQueryParams(pagination),
        },
      })
    );
  }

  getUnlockOption(unlockOptionId: string) {
    return toPromise(this.http.get<EventVideoUnlockOption>(`${this.baseUrl}/unlock-options/${unlockOptionId}`));
  }

  createEventVideoUnlockOption(dto: CreateEventVideoUnlockOptionDto) {
    return toPromise(this.http.post<EventVideoUnlockOption>(`${this.baseUrl}/unlock-options`, dto));
  }

  updateEventVideoUnlockOption(unlockOptionId: string, dto: UpdateEventvideoUnlockOptionDto) {
    return toPromise(this.http.patch<EventVideoUnlockOption>(`${this.baseUrl}/unlock-options/${unlockOptionId}`, dto));
  }

  getUserActiveUnlock(eventVideoId: string) {
    return toPromise(this.http.get<EventVideoUnlock>(`${this.baseUrl}/active/${eventVideoId}`));
  }
}
