<div id="dialog-header">
  <div style="display: flex; align-items: center">
    <div>
      <h2 mat-dialog-title [innerHtml]="data.title || ''"></h2>
    </div>
  </div>
</div>
<greco-admin-event-dialog-body
  [user]="data.user || null"
  [contact]="data.contact || null"
  (status)="closeDialog($event)"
></greco-admin-event-dialog-body>
