import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import moment from 'moment';
import { map, shareReplay } from 'rxjs/operators';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'alt-event-day-picker',
  templateUrl: './event-day-picker.component.html',
  styleUrls: ['./event-day-picker.component.scss'],
})
export class EventDayPickerComponent {
  constructor(public eventService: EventService, private breakpointObserver: BreakpointObserver) {}

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(result => result.matches));

  min = Math.min;
  moment = moment;
  today = moment(new Date()).startOf('day').toDate();

  weekDays$ = this.eventService.dateRange$.pipe(
    map(([day]) => {
      return new Array(7).fill(null).map((_, i) => moment(day).startOf('week').add(i, 'days').startOf('day').toDate());
    }),
    shareReplay(1)
  );

  moveBack(selectedDay: Date) {
    const numDays = Math.min(7, moment(selectedDay).diff(moment(this.today), 'days'));
    this.eventService.dateRange$.next([
      moment(selectedDay).subtract(numDays, 'days').toDate(),
      moment(selectedDay).subtract(numDays, 'days').endOf('day').toDate(),
    ]);
  }

  moveForward(selectedDay: Date) {
    const numDays = 7 - moment(selectedDay).isoWeekday() || 7;
    this.eventService.dateRange$.next([
      moment(selectedDay).add(numDays, 'days').toDate(),
      moment(selectedDay).add(numDays, 'days').endOf('day').toDate(),
    ]);
  }
}
