<ng-container *grecoLet="permissions$ | async as permissions">
  <ng-container *grecoLet="_self$ | async as userId">
    <greco-table
      *grecoLet="bookings$ | async as bookings"
      [highlight]="true"
      [loading]="loading"
      [data]="bookings || []"
      (rowClick)="rowClick.emit($event)"
    >
      <ng-container
        *ngIf="
          !hideCheckIn &&
            totalCheckInBookings &&
            bookings?.length &&
            bookings &&
            (permissions?.checkIn || (bookings[0] | bookingIncludesResource : userId));
          else noCheckIn
        "
      >
        <mat-checkbox
          *grecoTableCol="''; let booking; fitContent: true; sticky: true"
          color="primary"
          [disabled]="booking.status === 'PENDING'"
          [checked]="bookingsToCheckIn.includes(booking.id)"
          (change)="toggleBookingToCheckIn(booking)"
          (click)="$event.stopImmediatePropagation()"
        ></mat-checkbox>
      </ng-container>

      <ng-template #noCheckIn>
        <mat-icon *grecoTableCol="''; let booking; fitContent: true" [matTooltip]="booking.id">
          {{ booking | bookingIcon }}
        </mat-icon>
      </ng-template>

      <!-- Spot Number -->
      <ng-container *ngIf="!hideSpot">
        <ng-container *grecoTableCol="'Spot'; let booking; fitContent: true; let index = index">
          <ng-container *grecoLet="bookedSpotIds$ | async as bookedSpotIds">
            <ng-container *grecoLet="booking?.spot as spot">
              <ng-container *ngIf="eventHasRoom || spot || bookedSpotIds?.length; else spotNumber">
                <span [matTooltip]="spot?.name || 'No spot assigned'">
                  {{ spot?.spotNumber || '?' }}
                  <button *ngIf="permissions?.manageSpots" mat-icon-button [matMenuTriggerFor]="spotMenu">
                    <mat-icon style="margin-top: 1px">arrow_drop_down</mat-icon>
                  </button>
                </span>

                <mat-menu #spotMenu>
                  <p style="margin: 8px 24px 12px 12px">Reassign Spot</p>
                  <ng-container *ngFor="let spot of getRoom()?.spots || []">
                    <ng-container *ngIf="booking.spotId !== spot.id">
                      <button
                        *grecoLet="bookedSpotIds?.includes(spot.id) as alreadyBooked"
                        mat-menu-item
                        [style.color]="alreadyBooked ? 'var(--text-accent-lighter-color)' : ''"
                        [style.background-color]="alreadyBooked ? 'var(--background-color-light)' : ''"
                        (click)="updateSpot(booking, spot, bookings || [], !!alreadyBooked)"
                      >
                        {{ spot.spotNumber }} - {{ spot.name }}
                      </button>
                    </ng-container>
                  </ng-container>
                </mat-menu>
              </ng-container>
            </ng-container>

            <ng-template #spotNumber>
              <ng-container *ngIf="showSpotNumber">
                <p>#{{ ((metadata?.currentPage || 1) - 1) * (metadata?.itemsPerPage || 0) + index + 1 }}</p>
              </ng-container>
              <ng-container *ngIf="!showSpotNumber"> <p>-</p> </ng-container>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Community -->
      <ng-container *ngIf="!communityId || !hideCommunity">
        <p *grecoTableCol="'Community'; let booking" [matTooltip]="booking.event.community.name">
          {{ booking.event.community.name }}
        </p>
      </ng-container>

      <!-- Event -->
      <ng-container *ngIf="!hideEvent">
        <ng-container *grecoTableCol="'Event'; let booking">
          <p [matTooltip]="booking.event.title">{{ booking.event.title }}</p>
          <p *ngIf="booking.bookedById !== booking.user.id" [matTooltip]="booking.bookedBy?.contactEmail">
            <small>
              <i>(Purchased By {{ booking.bookedBy.displayName }})</i>
            </small>
          </p>
        </ng-container>
      </ng-container>

      <!-- Date -->
      <ng-container *ngIf="!hideDate">
        <p
          *grecoTableCol="'Event Date'; let booking"
          [matTooltip]="'Booked: ' + (booking.created | date : 'MMM d, h:mma')"
        >
          {{ booking.event?.startDate | date : 'MMM d, h:mma' }}
        </p>
      </ng-container>

      <!-- User -->
      <ng-container *ngIf="!hideUser || (showLinkedBookings$ | async)">
        <div
          *grecoTableCol="'Member'; let booking"
          [matTooltip]="booking.user?.contactEmail"
          style="display: flex; align-items: center; gap: 8px"
        >
          <greco-multi-avatar [images]="[booking.user.photoURL]" [size]="28"></greco-multi-avatar>

          <div style="display: flex; flex-direction: column">
            <span *ngIf="booking.user?.id">
              <a
                [routerLink]="['/admin/community', booking.event.community.id, booking.user.id]"
                (click)="$event.stopImmediatePropagation()"
              >
                {{ booking.user.displayName }}
                <ng-container *ngIf="booking.user.friendlyName"> ({{ booking.user.friendlyName }})</ng-container>
              </a>
            </span>
            <p
              *ngIf="booking.bookedById !== booking.user.id && hideEvent"
              style="margin: 0; line-height: 12px"
              [matTooltip]="booking.bookedBy?.contactEmail"
            >
              <small>
                <i>(Purchased By {{ booking.bookedBy.displayName }})</i>
              </small>
            </p>
          </div>
        </div>
      </ng-container>

      <div *grecoTableCol="'Equipment'; let booking" style="display: flex; flex-direction: column">
        <small *ngIf="booking.equipment"> {{ booking.event.equipmentTitle }} </small>
        <p>{{ booking.equipment || '-' }}</p>
      </div>

      <p *grecoTableCol="'Additional Spots'; let booking">
        {{ booking.spotsTaken > 1 ? booking.spotsTaken - 1 : '-' }}
      </p>

      <!-- Resource(s) -->
      <ng-container *ngIf="!hideResource">
        <div *grecoTableCol="'Resource/Trainer'; let booking" style="display: flex; align-items: center; gap: 8px">
          <ng-container *ngIf="booking.event.resourceAssignments[0]?.resource as resource">
            <greco-multi-avatar [images]="[resource.photoURL]" [size]="28"></greco-multi-avatar>
            <div style="display: flex; flex-direction: column">
              <a [routerLink]="['/admin/resource', resource.id]" (click)="$event.stopImmediatePropagation()">
                {{ resource.name }}
              </a>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Requirements -->
      <mat-chip-list *grecoTableCol="'Forms & Requirements'; let booking">
        <mat-chip
          *ngFor="let sub of booking.requirementSubmissions | grecoFilterBookingRequirementSubmissions"
          style="cursor: pointer"
          [matTooltip]="sub.response | typeformResponseTooltip | grecoJoin : ', '"
          (click)="previewFormResponse(sub.response.response, sub.form.formTitle)"
        >
          {{ sub.form.formTitle }}
        </mat-chip>
      </mat-chip-list>

      <!-- Booking Option -->
      <p
        *grecoTableCol="'Booking Option'; let booking"
        style="display: flex; gap: 4px; align-items: center"
        [matTooltip]="
          (booking.bookingOptionConsumed
            ? ''
            : 'This booking option is reusable and was not consumed for this booking. ( ') +
          booking.bookingOption.id +
          (booking.bookingOptionConsumed ? '' : ')')
        "
      >
        {{ booking.bookingOption.title }}

        <small *ngIf="booking.boostersUsed + booking.boostersPurchased">
          &nbsp;(+{{ booking.boostersUsed + booking.boostersPurchased }} Boosts)
        </small>

        <mat-icon *ngIf="!booking.bookingOptionConsumed" style="width: 18px; height: 18px; font-size: 18px">
          restart_alt
        </mat-icon>
      </p>

      <!-- Modified By -->
      <p *grecoTableCol="'Last Modified By'; let booking" [matTooltip]="booking.modified">
        {{ booking.modifiedBy?.displayName || 'System' }}
      </p>

      <!-- Booking Tags -->
      <ng-container *ngIf="!hideTags">
        <mat-chip-list
          *grecoTableCol="'Booking Tags'; let booking"
          style="display: flex; gap: 4px; align-items: center"
        >
          <mat-chip *ngFor="let tag of booking.bookingOption.tags">
            {{ tag.label }}
          </mat-chip>
        </mat-chip-list>
      </ng-container>

      <mat-chip-list *grecoTableCol="'Status'; let booking; fitContent: true; stickyEnd: true">
        <mat-chip
          *grecoLet="(menu?._allItems?.length || 0) > 0 as menuHasItems"
          [matMenuTriggerFor]="menu"
          [style.pointer-events]="menuHasItems ? '' : 'none'"
          [style.background-color]="getColor(booking.status)"
          [style.color]="
            booking.status === 'CANCELLED' ||
            booking.status === 'NO-SHOW' ||
            booking.status === 'LATE_CANCELLED' ||
            booking.status === 'CHECKED_IN'
              ? 'white'
              : ''
          "
          (click)="$event.stopImmediatePropagation()"
        >
          {{ booking.status.replace('CONFIRMED', 'Booked') | removeUnderscores | titlecase }}
          <mat-icon *ngIf="menuHasItems">arrow_drop_down</mat-icon>
        </mat-chip>

        <mat-menu #menu>
          <!-- Manage User -->
          <button
            *ngIf="permissions?.manageUser"
            mat-menu-item
            [routerLink]="['/admin', 'community', booking.event.community.id, booking.user.id]"
          >
            <mat-icon>people</mat-icon>
            Manage User
          </button>

          <ng-container *ngIf="booking.status === 'PENDING'">
            <!-- Manage Booking -->
            <ng-container>
              <button mat-menu-item (click)="confirmWithNewBookingOption(booking)">
                <mat-icon>update</mat-icon>
                Manage Booking
              </button>
            </ng-container>

            <!-- Confirm -->
            <ng-container>
              <button mat-menu-item (click)="confirm(booking)">
                <mat-icon>how_to_reg</mat-icon>
                Confirm
              </button>
            </ng-container>
          </ng-container>

          <ng-container *grecoLet="booking | bookingIncludesResource : userId as bookingIncludesResource">
            <!-- Check-In -->
            <ng-container *ngIf="booking.status === 'CONFIRMED'">
              <ng-container *ngIf="permissions?.checkIn || bookingIncludesResource">
                <button
                  *ngIf="
                    moment(booking.event?.startDate).subtract(booking.event?.checkInWindow, 'minutes').isBefore(now)
                  "
                  mat-menu-item
                  (click)="checkIn(booking)"
                >
                  <mat-icon>how_to_reg</mat-icon>
                  Check In
                </button>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="booking.status === 'PENDING' || booking.status === 'CONFIRMED' || booking.status === 'CHECKED_IN'"
            >
              <!-- Cancel -->
              <ng-container *ngIf="permissions?.cancel || bookingIncludesResource">
                <button mat-menu-item (click)="cancel(booking, false)">
                  <mat-icon>event_busy</mat-icon>
                  Cancel
                </button>
              </ng-container>

              <!-- Cancel (Free of Charge) -->
              <ng-container *ngIf="permissions?.cancelFree">
                <button mat-menu-item (click)="cancel(booking, true)">
                  <mat-icon>event_busy</mat-icon>
                  Cancel (Free of Charge)
                </button>
              </ng-container>

              <!-- No-Show -->
              <ng-container *ngIf="permissions?.noShow || bookingIncludesResource">
                <button
                  *ngIf="moment(booking.event?.startDate).subtract(10, 'minutes').isBefore(now)"
                  mat-menu-item
                  (click)="noShow(booking)"
                >
                  <mat-icon>person_off</mat-icon>
                  No-Show
                </button>
              </ng-container>
            </ng-container>

            <!-- Undo Check-In -->
            <button
              *ngIf="
                booking.status === 'CHECKED_IN' &&
                (booking | inUndoWindow) &&
                (permissions?.checkIn || bookingIncludesResource)
              "
              mat-menu-item
              (click)="undoCheckIn(booking)"
            >
              <mat-icon>person_off</mat-icon>
              Undo Check-In
            </button>

            <!-- Undo No-Show -->
            <button
              *ngIf="
                booking.status === 'NO_SHOW' &&
                ((permissions?.noShow2 && (booking | inUndoWindow : 2)) ||
                  (permissions?.noShow24 && (booking | inUndoWindow : 24))) &&
                (permissions?.noShow || bookingIncludesResource)
              "
              mat-menu-item
              (click)="undoNoShow(booking, permissions?.noShow24 ? 24 : permissions?.noShow2 ? 2 : 0)"
            >
              <mat-icon>person_off</mat-icon>
              Undo No-Show
            </button>
          </ng-container>
        </mat-menu>
      </mat-chip-list>
    </greco-table>

    <mat-paginator
      *ngIf="metadata?.totalItems"
      showFirstLastButtons
      [pageSizeOptions]="[15, 25, 50]"
      [length]="metadata!.totalItems || 0"
      [pageSize]="metadata?.itemsPerPage || 50"
      (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
    ></mat-paginator>
  </ng-container>
</ng-container>
