import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { EventService } from '@greco/ngx-booking-events';
import { SignInComponent, UserService } from '@greco/ngx-identity-auth';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-user-course-wrapper-page',
  styles: [
    `
      #container {
        padding: 24px 32px 0px;
      }
      @media screen and (max-width: 821px) {
        #container {
          padding: 24px 16px 72px;
        }
      }
    `,
  ],
  template: `
    <div id="container">
      <greco-user-course-page
        *ngIf="templateData$ | async as data"
        [event]="data.event"
        [user]="data.user"
        (booked)="booked()"
        (refreshed)="refresh$.next()"
      ></greco-user-course-page>
    </div>
  `,
})
export class UserCourseWrapperPage implements OnInit {
  constructor(
    private router: Router,
    private userSvc: UserService,
    private route: ActivatedRoute,
    private eventSvc: EventService,
    private crumbs: BreadcrumbService,
    private dialog: MatDialog
  ) {}

  refresh$ = new BehaviorSubject<void>(undefined);

  private user$ = this.refresh$.pipe(
    switchMap(() => this.userSvc.getUserId().pipe(switchMap(uid => (uid ? this.userSvc.getUser(uid) : of(null)))))
  );

  event$ = combineLatest([this.route.params, this.user$]).pipe(
    switchMap(async ([{ courseId }, user]) => {
      return courseId && user ? await this.eventSvc.getOneSeries(courseId) : null;
    }),
    catchError(() => of(null)),
    shareReplay(1)
  );

  readonly templateData$ = combineLatest([this.event$, this.user$]).pipe(
    map(([event, user]) => (event && user ? { event, user } : null))
  );

  booked() {
    this.router.navigate(['/']);
  }

  async ngOnInit() {
    setTimeout(async () => {
      this.crumbs.set([{ label: 'Courses', routerLink: '/workouts/courses', icon: 'date_range' }]);

      const event = await toPromise(this.event$);
      const user = await toPromise(this.user$);
      if (!user) this.dialog.open(SignInComponent);
      if (!event || !user) return await this.router.navigate(['..'], { relativeTo: this.route });

      return this.crumbs.set([
        { label: 'Courses', routerLink: '/workouts/courses', icon: 'date_range' },
        { label: event.title, routerLink: `/workouts/courses/${event.id}` },
      ]);
    });
  }
}
