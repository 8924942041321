import { Component, OnDestroy, OnInit } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-user-video-gallery-wrapper',
  templateUrl: './user-gallery.page.html',
  styleUrls: ['./user-gallery.page.scss'],
})
export class UserVideoGalleryWrapperPage implements OnInit, OnDestroy {
  constructor(private userSvc: UserService, private crumbs: BreadcrumbService) {}

  user?: null | User;

  private _onDestroy$ = new Subject<void>();

  ngOnInit() {
    setTimeout(async () => {
      this.crumbs.set([]);
    });
    this.userSvc.user$.pipe(takeUntil(this._onDestroy$)).subscribe(user => (this.user = user));
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async refresh() {
    this.user = await toPromise(this.userSvc.user$);
  }
}
