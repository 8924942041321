<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup">
    <section>
      <h3 style="margin-bottom: 8px">Unlock Option Details</h3>

      <div style="display: flex; margin-top: -16px">
        <!-- Color -->
        <div matPrefix style="margin-bottom: 8px; position: relative">
          <mat-form-field style="width: 48px" appearance="standard">
            <input #colorInput matInput type="color" required formControlName="color" />
            <mat-icon
              style="
                position: absolute;
                top: 12px;
                left: 4px;
                width: 100%;
                height: 100%;
                color: white;
                pointer-events: none;
              "
            >
              palette
            </mat-icon>
          </mat-form-field>
        </div>

        <!-- Title -->
        <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
          <mat-label>Title</mat-label>
          <input matInput required formControlName="title" />
        </mat-form-field>
      </div>
      <!-- Icon -->
      <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
        <mat-label>Icon</mat-label>
        <greco-icon-picker formControlName="icon" [required]="true"></greco-icon-picker>
      </mat-form-field>

      <!-- Tags -->
      <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
        <mat-label>Tags</mat-label>
        <greco-community-video-tags-input
          [communityId]="data.communityId"
          [allowLockedTags]="true"
          [allowHiddenTags]="true"
          formControlName="tags"
        ></greco-community-video-tags-input>
      </mat-form-field>

      <!-- Description (Optional) -->
      <mat-form-field appearance="standard">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" rows="3"></textarea>
      </mat-form-field>

      <!-- Available to everyone -->
      <div style="margin-top: 16px">
        <mat-checkbox formControlName="availableToAnyone" color="primary">Available to everyone</mat-checkbox>
      </div>
    </section>

    <section>
      <h3 style="margin-bottom: 8px">Video Rental</h3>

      <!--Rental Period-->
      <mat-form-field appearance="standard">
        <mat-label>Rental Period</mat-label>
        <greco-minutes-input
          [required]="true"
          formControlName="rentalPeriod"
          [disabled]="formGroup.value.unlockToKeep"
        ></greco-minutes-input>
        <div matSuffix style="display: flex; flex-direction: row; gap: 16px; margin-left: 16px">
          <mat-checkbox formControlName="unlockToKeep" color="primary" (change)="rentalChange($event)"
            >Unlimited</mat-checkbox
          >
          <mat-icon
            matTooltip="The amount of time the user has to watch the video after unlocking it. Having a period of 0 means the user will have an infinite rental period"
          >
            info
          </mat-icon>
        </div>
      </mat-form-field>

      <!-- Price (Optional)-->
      <mat-form-field appearance="standard">
        <mat-label>Unlock Video Price</mat-label>
        <input matInput formControlName="price" currencyMask [options]="currencyMaskConfig" />
        <mat-icon
          matSuffix
          matTooltip="Price charged to the user at the time of video unlock. This is not the price of the video unlock option that must be used to unlock. The user receives the perks by purchasing them as part of products."
          >info</mat-icon
        >
      </mat-form-field>
    </section>
  </form>
</greco-simple-dialog>
