<greco-collapsible-section
  id="videos_section"
  [header]="{ title: 'Videos', icon: 'smart_display' }"
  [expanded]="false"
  *ngIf="canRead$ | async"
>
  <button
    *ngIf="canUpload$ | async"
    header
    mat-stroked-button
    color="primary"
    style="height: 32px; line-height: 16px; margin-left: 16px"
    (click)="$event.stopImmediatePropagation(); addVideo()"
  >
    <span>Link Video</span>
  </button>
  <button
    *ngIf="canUpload$ | async"
    header
    mat-stroked-button
    color="primary"
    style="height: 32px; line-height: 16px"
    (click)="$event.stopImmediatePropagation(); upload()"
  >
    <span>Upload</span>
  </button>
  <greco-table [highlight]="true" [data]="(videos$ | async) || []" (rowClick)="(null)">
    <mat-icon *grecoTableCol="''; fitContent: true; let video" [matTooltip]="video.id"> video_settings </mat-icon>

    <!-- showVideoName -->
    <ng-container>
      <p *grecoTableCol="'Title'; let video" [matTooltip]="video.communityVideo.video.title">
        {{ video.communityVideo.video.title }}
      </p>
    </ng-container>
    <!-- Date -->
    <ng-container>
      <p *grecoTableCol="'Upload Date'; let video" [matTooltip]="'Upload Date'">
        {{ video.communityVideo.video.created | date }}
      </p>
    </ng-container>
    <!-- Uploader -->
    <ng-container>
      <p *grecoTableCol="'Uploaded By'; let video" [matTooltip]="'Uploaded By'">
        {{ video.communityVideo.video.uploadedBy.displayName }}
      </p>
    </ng-container>
    <!-- tags -->
    <ng-container>
      <p *grecoTableCol="'Tags'; fitContent: true; let video">
        <greco-video-tag
          *ngFor="let tag of video.communityVideo.video.tags"
          [admin]="true"
          [tag]="tag"
          [community]="video.communityVideo.community"
        ></greco-video-tag>
      </p>
    </ng-container>

    <!-- Status -->
    <ng-container>
      <p *grecoTableCol="'Status'; fitContent: true; let video" [matTooltip]="'Status'">
        <mat-chip>{{
          video.availableOn &&
          (video.availableOn | grecoDateCompare : 'lte') &&
          (!video.availableUntil || (video.availableUntil | grecoDateCompare : 'gt'))
            ? 'Active'
            : video.availableOn &&
              (video.availableOn | grecoDateCompare : 'gte') &&
              (!video.availableUntil || (video.availableUntil | grecoDateCompare : 'gt'))
            ? 'Draft'
            : 'Archived'
        }}</mat-chip>
        <!-- 
            : !video.availableOn && (!video.availableUntil || video.availableUntil > now)
            ? 'Draft'
          -->
      </p>
    </ng-container>

    <!-- Publish Date -->
    <ng-container>
      <p
        *grecoTableCol="'Available On'; fitContent: true; let video"
        [matTooltip]="'Date video will be visible in LF3 GO'"
      >
        <mat-chip>{{ (video.availableOn | date) || 'Never' }}</mat-chip>
      </p>
    </ng-container>

    <!--Watch-->
    <ng-container *ngIf="canUpload$ || canUpdate$ || canDelete$ | async">
      <button
        *grecoTableCol="''; let video; alignRight: true"
        [matTooltip]="'Watch ' + video.communityVideo.video.title"
        mat-stroked-button
        style="height: 32px; line-height: 16px; margin-left: auto"
        (click)="watch(video)"
      >
        Watch
      </button>
    </ng-container>
    <!-- Options -->
    <ng-container>
      <ng-container *grecoTableCol="''; let video; fitContent: true; stickyEnd: true">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [matTooltip]="'Options for ' + video.communityVideo.video.title"
          (click)="$event.stopImmediatePropagation()"
          *ngIf="canDelete$ || canUpdate$ | async"
        >
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu>
          <!-- Manage Unlocks 
          <button mat-menu-item (click)="manageUnlocks(video)" *ngIf="canManageUnlock$ | async">
            <mat-icon>lock_open</mat-icon>
            <span>Manage Unlocks</span>
          </button>-->
          <!-- Update Video -->
          <button mat-menu-item (click)="update(video)" *ngIf="canUpdate$ | async">
            <mat-icon>upload</mat-icon>
            <span>Update</span>
          </button>

          <!-- Activate/Archive -->
          <button mat-menu-item (click)="changeStatus(video)" *ngIf="canUpload$ || canUpdate$ | async">
            <ng-container
              *grecoLet="
                video.availableOn &&
                (video.availableOn | grecoDateCompare : 'lte') &&
                (!video.availableUntil || (video.availableUntil | grecoDateCompare : 'gt')) as isActive
              "
            >
              <mat-icon>{{ !isActive ? 'unarchive' : 'archive' }}</mat-icon>
              <span>{{ !isActive ? 'Activate' : 'Archive' }}</span>
            </ng-container>
          </button>

          <ng-container>
            <!-- Remove -->
            <ng-container>
              <button mat-menu-item (click)="remove(video)" *ngIf="canDelete$ | async">
                <mat-icon>remove</mat-icon>
                <span>Remove</span>
              </button>
            </ng-container>
          </ng-container>
          <ng-container>
            <!-- Delete 
            <ng-container>
              <button mat-menu-item (click)="delete(video)" *ngIf="this.user?.isSuperAdmin">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </ng-container>-->
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>
  </greco-table>
</greco-collapsible-section>
