<div class="video-card-wrapper" style="position: relative">
  <greco-video-card
    [class.locked]="!$any(eventVideo).unlock && options.length"
    *ngIf="eventVideo.communityVideo && eventVideo.communityVideo.video"
    [video]="eventVideo.communityVideo.video"
    [title]="eventVideo.event?.title"
    [progress]="$any(eventVideo).watchTime?.watchDuration"
    matTooltip="{{
      !options.length && !$any(eventVideo).unlock ? 'Oops! Looks like you need a perk to unlock this!' : ''
    }}"
  >
    <div
      lock
      class="info-icon"
      *ngIf="!$any(eventVideo).unlock"
      style="margin-top: -4px; margin-left: 2px"
      matTooltip="This video is locked!"
    >
      <mat-icon style="color: var(--warn-color)">lock</mat-icon>
    </div>
    <div
      header
      *ngIf="!$any(eventVideo).unlock"
      class="perk"
      style="position: absolute; top: 0; margin-top: 20px; margin-left: 20px; width: auto"
    >
      <greco-perk-badge
        *ngIf="options.length"
        [icon]="options[0].perk.badge.icon"
        [color]="options[0].perk.badge.color"
        [shape]="options[0].perk.badge.shape"
        [small]="true"
      ></greco-perk-badge>
      <small *ngIf="options.length">Recommended Option: {{ options[0].perk.title }}</small>
    </div>
    <div
      lock
      *ngIf="$any(eventVideo).unlock"
      style="margin-top: -4px; margin-left: 2px"
      matTooltip="This video has been unlocked!"
    >
      <mat-icon style="color: var(--primary-color)">lock_open</mat-icon>
    </div>
    <mat-chip-list body>
      <div *ngFor="let assignment of eventVideo.event?.resourceAssignments">
        <mat-chip
          *ngIf="assignment?.resource?.type === 'PERSON' && assignment?.resource?.disabled === false"
          matTooltip="Event Trainer"
        >
          <mat-icon
            *ngIf="!assignment?.resource?.photoURL"
            style="width: 18px; height: 18px; font-size: 18px; margin-right: 8px"
          >
            person
          </mat-icon>
          <greco-multi-avatar
            *ngIf="assignment?.resource?.photoURL"
            style="margin-left: 1px; margin-right: 8px"
            [images]="[assignment?.resource?.photoURL || '']"
            [size]="24"
          ></greco-multi-avatar>
          {{ assignment?.resource?.name }}
        </mat-chip>
      </div>
    </mat-chip-list>

    <div style="height: 100%; display: flex; flex-direction: column-reverse" footer>
      <button
        mat-button
        style="width: 100%; background-color: var(--accent2-color)"
        (click)="unlock()"
        [disabled]="!options.length && !$any(eventVideo).unlock"
      >
        <mat-icon>{{ label === 'Unlock' ? 'lock' : 'play_arrow' }}</mat-icon>
        {{
          label === 'Unlock'
            ? label
            : $any(eventVideo).watchTime?.watchDuration / (eventVideo.communityVideo.video.duration || 0) > 0.9
            ? 'Watch Again'
            : $any(eventVideo).watchTime?.watchDuration / (eventVideo.communityVideo.video.duration || 0) > 0.05
            ? 'Continue Watching'
            : 'Watch'
        }}
      </button>
    </div>
  </greco-video-card>
</div>
