import { SecurityResource } from '@greco/security';

export enum VariantResourceAction {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  SELL_RESTRICTED = 'SELL_RESTRICTED',
  READ_INVENTORIES = 'READ_INVENTORIES',
  MANAGE_INVENTORY = 'MANAGE_INVENTORY',
}

export const VariantResource: SecurityResource = {
  key: 'sales_product',
  module: 'Sales',

  title: 'Variant',
  description: 'Manage variants',

  context: class {
    variantId?: string;
    productId?: string;
    communityId?: string;
  },

  actions: [
    { key: VariantResourceAction.READ, title: 'Read variants', description: 'Ability to read variants' },
    { key: VariantResourceAction.CREATE, title: 'Create variants', description: 'Ability to create variants' },
    { key: VariantResourceAction.UPDATE, title: 'Update variants', description: 'Ability to update variants' },
    {
      key: VariantResourceAction.SELL_RESTRICTED,
      title: 'Sell restricted variants',
      description: 'Ability to sell restricted Variants',
    },
    {
      key: VariantResourceAction.READ_INVENTORIES,
      title: 'Read inventories',
      description: 'Ability to read inventories',
    },
    {
      key: VariantResourceAction.MANAGE_INVENTORY,
      title: 'Manage inventories',
      description: 'Ability to manage inventories',
    },
  ],
};
