import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import type { StartSubscriptionDto } from '@greco/nestjs-sales-subscriptions';
import { UserService } from '@greco/ngx-identity-auth';
import { PurchasePreviewComponent } from '@greco/ngx-sales-purchases';
import { PropertyListener } from '@greco/property-listener-util';
import {
  Subscription,
  SubscriptionAction,
  SubscriptionActionStatus,
  SubscriptionActionType,
} from '@greco/sales-subscriptions';
import { DateInputDialog, DateInputDialogData, SimpleDialog } from '@greco/ui-dialog-simple';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CancelSubscriptionDialog, FreezeSubscriptionDialog } from '../../dialogs';
import { SubscriptionFreezeService, SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-subscription-action-options-menu',
  templateUrl: './action-options-menu.component.html',
  styleUrls: ['./action-options-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriptionActionOptionsMenuComponent implements OnInit, OnDestroy {
  constructor(
    private userSvc: UserService,
    private matDialog: MatDialog,
    private subscriptionSvc: SubscriptionsService,
    private freezeSvc: SubscriptionFreezeService
  ) {}

  @Output() scheduleChanged = new EventEmitter<void>();

  @PropertyListener('action') private action$ = new ReplaySubject<SubscriptionAction>(1);
  @Input() action?: SubscriptionAction;

  @PropertyListener('subscription') private subscription$ = new ReplaySubject<Subscription>(1);
  @Input() subscription?: Subscription;

  @Input() communityId?: string | null;

  @PropertyListener('currentUserId') private currentUserId$ = new BehaviorSubject<string | null>(null);
  currentUserId!: string;

  hasAnyAction$ = combineLatest([this.action$, this.subscription$, this.currentUserId$]).pipe(
    map(([action, subscription, currentUserId]) => {
      if (!subscription) return false;
      if (!currentUserId) return false;

      switch (action?.type) {
        case SubscriptionActionType.UPDATE:
          return subscription.subscribedById !== currentUserId && action.status !== SubscriptionActionStatus.CANCELLED;

        case SubscriptionActionType.CANCELLATION:
          return (
            (subscription.subscribedById !== currentUserId && action.status === SubscriptionActionStatus.SCHEDULED) ||
            (action.status === SubscriptionActionStatus.COMPLETED && !!(action as any).purchaseId)
          );

        case SubscriptionActionType.RENEWAL:
        case SubscriptionActionType.START:
          return action.status === SubscriptionActionStatus.SCHEDULED;
        case SubscriptionActionType.FREEZE:
        case SubscriptionActionType.UNFREEZE:
          return subscription.subscribedById !== currentUserId && action.status === SubscriptionActionStatus.SCHEDULED;

        default:
          return false;
      }
    })
  );

  freezePeriod$ = this.action$.pipe(
    switchMap(async action => {
      if (!action || (action.type !== SubscriptionActionType.FREEZE && action.type !== SubscriptionActionType.UNFREEZE))
        return null;
      return await this.freezeSvc.getFreeze((action as any).freezePeriodId);
    })
  );

  async ngOnInit() {
    this.currentUserId = (await toPromise(this.userSvc.getUserId())) || '';
  }

  openPurchasePreview = async () => {
    if (!this.subscription || !this.action) return;

    const purchase = await this.subscriptionSvc.getSubscriptionPurchasePreview(this.subscription.id, this.action.id);
    if (!purchase) return;

    const dialog = this.matDialog.open(PurchasePreviewComponent, {
      panelClass: 'greco-subscription-action-options-menu-purchase-preview-dialog-panel',
    });
    if (this.communityId) dialog.componentInstance.communityId = this.communityId;
    dialog.componentInstance.showTotals = true;
    dialog.componentInstance.showHeader = true;
    dialog.componentInstance.showPurchaseInfo = true;
    dialog.componentInstance.subscription = this.subscription;
    dialog.componentInstance.purchase = purchase;
  };

  async startNow() {
    if (!this.subscription) return;
    const currentUserId = await toPromise(this.userSvc.getUserId());
    await this.subscriptionSvc.startSubscription(this.subscription.id, { createdById: currentUserId });
    this.scheduleChanged.emit();
  }

  async rescheduleStart() {
    const dialogResult = await toPromise(
      this.matDialog
        .open(DateInputDialog, {
          data: {
            title: 'Reschedule Subscription Start',
            subtitle: 'Select a new date to start the subscription',
            min: new Date(),
            buttons: [
              { role: 'cancel', label: 'Cancel' },
              { role: 'submit', label: 'Reschedule', color: 'primary' },
            ],
          } as DateInputDialogData,
        })
        .afterClosed()
    );
    if (!this.subscription || !dialogResult || dialogResult.role !== 'submit' || !dialogResult.value) return;

    const currentUserId = await toPromise(this.userSvc.getUserId());
    const formattedSubscriptionDate: Date = dialogResult.value;
    formattedSubscriptionDate.setHours(3, 0, 0, 0);

    const startSubscriptionDto: StartSubscriptionDto = {
      startDate: formattedSubscriptionDate,
      createdById: currentUserId,
    };
    await this.subscriptionSvc.startSubscription(this.subscription.id, startSubscriptionDto, true);
    this.scheduleChanged.emit();
  }

  async cancelSubscription(date?: Date) {
    if (!this.subscription) return;

    const dialog = this.matDialog.open(CancelSubscriptionDialog, { data: this.subscription });
    if (date) {
      const periodEnd = date.getTime() === this.subscription.periodEnd?.getTime();
      dialog.componentInstance.formGroup.get('dateRadioButton')?.reset(periodEnd ? 'endOfPeriod' : 'future');
      if (!periodEnd) dialog.componentInstance.formGroup.get('dateSelection')?.reset(date);
    }

    await toPromise(dialog.afterClosed());
    this.scheduleChanged.emit();
  }

  async stopCancellation(actionId: string) {
    if (!this.subscription) return;
    await this.subscriptionSvc.stopCancellation(this.subscription.id, actionId);
    this.scheduleChanged.emit();
  }

  async stopUpdate(actionId: string) {
    if (!this.subscription) return;
    await this.subscriptionSvc.stopUpdate(this.subscription.id, actionId);
    this.scheduleChanged.emit();
  }

  //TODO: On a Renewal Action, add option to schedule update.

  // TODO: Reschedule update: We could pass it the action which contains date and items.
  ngOnDestroy() {
    this.action$.complete();
  }

  async cancelFreeze(freezeId: string) {
    if (!this.subscription) return;
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Cancel Freeze',
        subtitle:
          'Are you sure you want to cancel this freeze? If the freeze has already begun, this will reschedule the unfreeze to now. If the freeze has not already begun, it will be deleted.',
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      await this.freezeSvc.unfreeze(this.subscription.id, freezeId);

      this.scheduleChanged.emit();
    }
  }

  async rescheduleFreeze(freezeId: string, startOrEnd: 'start' | 'end') {
    if (!this.subscription) return;
    const freeze = await this.freezeSvc.getFreeze(freezeId);
    if (!freeze) return;

    const dialog = this.matDialog.open(FreezeSubscriptionDialog, {
      data: {
        subscription: this.subscription,
        freeze,
        disableReason: false,
        disableStart: startOrEnd === 'end',
        // disableEnd: startOrEnd === 'start',
      },
    });
    dialog.componentInstance.communityId = this.communityId || undefined;
    await toPromise(dialog.afterClosed());

    this.scheduleChanged.emit();
  }
}
