import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Booking, BookingOption, CalendarEvent } from '@greco/booking-events';
import { Contact } from '@greco/identity-contacts';

@Component({
  selector: 'greco-admin-event-list-group',
  templateUrl: './admin-event-list-group.component.html',
  styleUrls: ['./admin-event-list-group.component.scss'],
})
export class AdminEventListGroupComponent {
  @Input() contact?: Contact;
  @Input() pagination = true;

  @Input() id?: string;

  @Input() dayHeader?: string;

  @Input() userWaitlist?: string[];
  @Input() userBookings: Booking[] = [];

  @Input() events: { event: CalendarEvent; bookingOption: BookingOption }[] = [];

  @Output() status = new EventEmitter<Booking[] | undefined>();

  pageIndex = 0;
  displayRange = this.calculateRange();

  trackById(_: number, row: { event: CalendarEvent; bookingOption: BookingOption }) {
    return row.event.id;
  }

  changePage(index: number) {
    this.pageIndex = index;
    this.displayRange = this.calculateRange();
  }

  private calculateRange() {
    return [this.pageIndex * 5, this.pageIndex * 5 + 5];
  }

  getStatus(event: Booking[] | undefined) {
    this.status.emit(event);
  }
}
