import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ArrayUtils, toPromise } from '@greco-fit/util';
import { Calendar, PersonResource, ResourceType, Tag } from '@greco/booking-events';
import { User } from '@greco/identity-users';
import { BookingOptionService, ResourcesService, TagService } from '@greco/ngx-booking-events';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';

interface ResourceGroup {
  name: string;
  groupId: string;
  photoUrl: string;
  resources: PersonResource[];
}

interface FormModel {
  tags: { tags: Tag[]; label: string }[];
  trainers: ResourceGroup[];
}

@Component({
  selector: 'greco-admin-events-filters',
  templateUrl: './admin-events-filters.component.html',
  styleUrls: ['./admin-events-filters.component.scss'],
})
export class AdminEventsFiltersComponent implements OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private resourceSvc: ResourcesService,
    private bookingOptSvc: BookingOptionService,
    private breakpointObs: BreakpointObserver,
    private userSvc: UserService,
    private tagSvc: TagService,
    private route: ActivatedRoute
  ) {
    this._listenToForm();
  }

  mobileBreakpoint$ = this.breakpointObs.observe('(max-width: 600px)');

  private _onDestroy$ = new Subject<void>();

  @Output() changed = new EventEmitter<RequestQueryBuilder>(true);

  @PropertyListener('user') private _user$ = new ReplaySubject<User>(1);
  @Input() user!: User | null;
  @PropertyListener('userCommunities') private _userCommunities$ = new ReplaySubject<string[]>(1);
  @Input() userCommunities!: string[];

  readonly _form = this.formBuilder.group({
    trainers: [[]],
    tags: [[]],
  });

  readonly bookingOptions$ = this.userSvc.user$.pipe(
    switchMap(async user =>
      user
        ? await this.bookingOptSvc.getUserBookingOptions(user.id)
        : await this.bookingOptSvc.getBookingOptionsAvailableToEveryone()
    ),
    shareReplay(1)
  );

  readonly tags$ = this.bookingOptions$.pipe(
    map(options =>
      options.reduce(
        (acc, option) => [...acc, ...option.tags.filter(tag => !acc.some(t => t.id === tag.id))],
        [] as Tag[]
      )
    ),
    map(tags => Object.entries(ArrayUtils.groupBy(tags, tag => tag.label)).map(([label, tags]) => ({ label, tags }))),
    shareReplay(1)
  );

  readonly trainers$: Observable<ResourceGroup[]> = combineLatest([
    this._userCommunities$,
    this._form.valueChanges,
  ]).pipe(
    map(([userCommunities]) => {
      const value = this._form.value;
      const communities: string[] = [];

      value.schedules?.forEach((schedule: Calendar) =>
        schedule.community ? communities.push(schedule.community.id) : {}
      );
      if (!communities.length) return userCommunities;
      return communities;
    }),
    switchMap(async communities => {
      if (!communities?.length) return [];

      const resources = await this.resourceSvc.paginateResources(
        RequestQueryBuilder.create({
          search: {
            type: ResourceType.PERSON,
            disabled: false,
            'ResourceEntity.communityId': {
              $in: communities,
            },
          },
        }),
        undefined,
        { limit: 500 }
      );

      const grouped = ArrayUtils.groupBy(resources.items as PersonResource[], r => r.groupId);
      return Object.entries(grouped).map(([groupId, resources]) => ({
        photoUrl: this.getPhotoUrl(resources),
        name: resources[0].name,
        resources,
        groupId,
      }));
    })
  );

  readonly compareTags = (a?: { label: string }, b?: { label: string }) => a?.label === b?.label;

  readonly compareGroups = (a?: ResourceGroup, b?: ResourceGroup) => a?.groupId === b?.groupId;

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private getPhotoUrl(resourceData: PersonResource[]): string {
    for (const resourceItem of resourceData) {
      if (resourceItem.photoURL) {
        return resourceItem.photoURL;
      }
    }
    return 'assets/lf3/icon_square_pad.png';
  }

  private async getPreSelectedTrainers(trainersId: string[]) {
    const resources = await this.resourceSvc.paginateResources(
      RequestQueryBuilder.create({
        search: {
          type: ResourceType.PERSON,
          disabled: false,
          'ResourceEntity.id': {
            $in: trainersId.length ? trainersId.map(trainerId => trainerId) : [],
          },
        },
      }),
      undefined,
      { limit: 500 }
    );

    const grouped = ArrayUtils.groupBy(resources.items as PersonResource[], r => r.groupId);

    const selectedTrainers = Object.entries(grouped).map(([groupId, resourceData]) => ({
      photoUrl: this.getPhotoUrl(resourceData),
      name: resourceData[0].name,
      resources: resourceData,
      groupId,
    }));

    return selectedTrainers;
  }

  private async getPreSelectedTags(tagsId: string[]) {
    const tags: Tag[] = (
      await this.tagSvc.paginate(
        RequestQueryBuilder.create({
          search: {
            id: { $in: tagsId },
          },
        }),
        undefined,
        { limit: 100 }
      )
    ).items;

    const groupTags = Object.entries(ArrayUtils.groupBy(tags, tag => tag.label)).map(([label, tags]) => ({
      label,
      tags,
    }));

    return groupTags;
  }

  private async initializeFormWithRouteData() {
    const query = await toPromise(this.route.queryParamMap);
    const trainersId = query.getAll('trainers');
    const tagsId = query.getAll('tags');

    const [trainers, tags] = await Promise.all([
      trainersId.length ? this.getPreSelectedTrainers(trainersId) : Promise.resolve([]),
      tagsId.length ? this.getPreSelectedTags(tagsId) : Promise.resolve([]),
    ]);
    this._form.patchValue({ trainers, tags });
  }

  private _listenToForm() {
    this.initializeFormWithRouteData();

    this._form.valueChanges
      .pipe(
        takeUntil(this._onDestroy$),
        startWith(this._form.value),
        switchMap(value => combineLatest([of(value), this.tags$])),
        map<[FormModel, { label: string; tags: Tag[] }[]], RequestQueryBuilder>(([{ trainers, tags }, allTags]) =>
          RequestQueryBuilder.create({
            search: {
              $and: [
                ...(allTags.length || tags.length
                  ? [
                      {
                        'tags.id': {
                          $in: (tags.length ? tags : allTags).reduce(
                            (acc, group) => [...acc, ...group.tags.map(tag => tag.id)],
                            [] as string[]
                          ),
                        },
                      },
                    ]
                  : []),
                ...(trainers?.length
                  ? [
                      {
                        'resources.id': {
                          $in: trainers.reduce(
                            (acc, group) => [...acc, ...group.resources.map(r => r.id).filter(id => !acc.includes(id))],
                            [] as string[]
                          ),
                        },
                      },
                    ]
                  : []),
              ],
            },
          })
        )
      )
      .subscribe(query => {
        this.changed.emit(query);
      });
  }
}
