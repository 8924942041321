<greco-simple-dialog [data]="dialogData" style="width: 800px; max-width: 90%">
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column">
    <mat-form-field>
      <mat-label>Note</mat-label>
      <textarea matInput required formControlName="note"></textarea>
    </mat-form-field>

    <div style="display: flex; flex-direction: column; align-items: center; gap: 8px">
      <mat-checkbox formControlName="admin" color="primary" style="width: 100%">Set as admin note?</mat-checkbox>
      <mat-checkbox formControlName="pinned" color="primary" style="width: 100%">Pin to top?</mat-checkbox>
    </div>
  </form>
</greco-simple-dialog>

<div class="dialog-buttons">
  <button mat-button [disabled]="createCalled" (click)="cancel()">Cancel</button>
  <button mat-button [disabled]="formGroup.invalid || createCalled" (click)="create()">
    <mat-icon *ngIf="createCalled" class="spin">loop</mat-icon> Create
  </button>
</div>
