import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { CollectionsService } from '@greco/ngx-video-library';
import { VideoCollection } from '@greco/video-library';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

interface TemplateData {
  collection: VideoCollection;
  user: User;
}

@Component({
  selector: 'greco-video-collection-wrapper-page',
  template: `<greco-user-collection
    *ngIf="_templateData$ | async as data"
    [collection]="data.collection"
    [user]="data.user"
  ></greco-user-collection>`,
})
export class VideoCollectionWrapperPage implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private userSvc: UserService,
    private route: ActivatedRoute,
    private crumbs: BreadcrumbService,
    private collectionSvc: CollectionsService
  ) {}

  private onDestroy$ = new Subject<void>();

  readonly _templateData$ = new BehaviorSubject<TemplateData | null>(null);
  readonly _test$ = this._templateData$.pipe(distinctUntilChanged());
  loadData() {
    const user$ = this.userSvc.authUser$.pipe(
      tap(() => this._templateData$.next(null)),
      switchMap(authUser => (authUser ? this.userSvc.getUser(authUser.uid) : of(null)))
    );

    const collection$ = this.route.params.pipe(
      tap(() => this._templateData$.next(null)),
      switchMap(async ({ collectionId }) =>
        collectionId ? await this.collectionSvc.getOneProgram(collectionId) : null
      ),
      catchError(() => of(null)),
      tap(collection => !collection && this.router.navigate(['..'])),
      shareReplay(1),
      tap(collection =>
        setTimeout(() =>
          this.crumbs.set([
            { label: 'Video Library', routerLink: '/workouts/collections', icon: 'video_library' },
            {
              label: collection?.title || '...',
              routerLink: `/workouts/collections/${collection?.id}`,
              icon: collection?.icon,
            },
          ])
        )
      )
    );

    combineLatest([user$, collection$])
      .pipe(
        takeUntil(this.onDestroy$),
        filter(collection => !!collection)
      )
      .subscribe(([user, collection]: any[]) => this._templateData$.next({ user, collection }));
  }

  ngOnInit() {
    setTimeout(() =>
      this.crumbs.set([{ label: 'Programs', routerLink: '/workouts/collections', icon: 'video_library' }])
    );
    this.loadData();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
