import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CommunityVideoTag } from '@greco/community-videos';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { CommunityVideoTagsService } from '../../services';

type NString = string | null | undefined;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-community-video-tags-input',
  templateUrl: './video-tags-input.component.html',
  styleUrls: ['./video-tags-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CommunityVideoTagsInputComponent }],
})
export class CommunityVideoTagsInputComponent
  implements MatFormFieldControl<CommunityVideoTag[]>, ControlValueAccessor, OnDestroy
{
  private static ID = 0;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private tagSvc: CommunityVideoTagsService,
    private formBuilder: FormBuilder,
    private _elementRef: ElementRef
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  private onDestroy$ = new Subject<void>();

  @Input() allowLockedTags = false;
  @Input() allowHiddenTags = false;

  @Input() communityId?: string;
  @PropertyListener('communityId') private communityId$ = new BehaviorSubject<NString>(null);

  _form = this.formBuilder.group({
    search: [null],
    tags: [[]],
  });

  get currentSearchValue() {
    return (this._form.get('search')?.value as string) || null;
  }

  readonly _tags$ = combineLatest([
    this.tagsControl.valueChanges.pipe(startWith([])),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._form.get('search')!.valueChanges.pipe(startWith(null)),
    this.communityId$,
  ]).pipe(
    switchMap(async ([current, search, communityId]) => {
      let tags = await this.tagSvc.getMany(communityId || undefined, search);
      if (!this.allowLockedTags) tags = tags.filter(tag => !tag.locked);
      if (!this.allowHiddenTags) tags = tags.filter(tag => !tag.hidden);
      if (current?.length) tags = tags.filter(tag => !current.some((c: CommunityVideoTag) => c.id === tag.id));
      return tags;
    })
  );

  @ViewChild(MatInput) private matInput?: MatInput;

  private get tagsControl() {
    return this._form.get('tags') as AbstractControl;
  }

  readonly stateChanges = new Subject<void>();

  readonly controlType = 'greco-community-video-tags-input';
  @HostBinding() readonly id = `${CommunityVideoTagsInputComponent.ID++}-${this.controlType}`;

  set value(value: CommunityVideoTag[] | null) {
    this.tagsControl.setValue(value);
    this.stateChanges.next();
  }
  get value(): CommunityVideoTag[] | null {
    return this.tagsControl.value;
  }

  get focused() {
    return !!this.matInput?.focused;
  }

  get empty() {
    const control = this._form.get('tags');
    return !!control?.value?.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    this.tagsControl.setValidators(this._required ? [Validators.required, Validators.minLength(1)] : []);
    this._form.updateValueAndValidity();

    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;

    this.tagsControl;

    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return this.tagsControl.invalid && this._form.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';
  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-community-video-tags-input-container');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this.matInput?.onContainerClick();
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.tagsControl.valueChanges.pipe(startWith([]), takeUntil(this.onDestroy$)).subscribe(() => fn(this.value));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(_fn: any) {}

  optionSelected(event: MatAutocompleteSelectedEvent) {
    if (!this.value?.some(r => r.id === event.option.value.id)) {
      this.value = [...(this.value || []), event.option.value];
    }
  }

  removeTag(tag: CommunityVideoTag) {
    this.value = this.value ? this.value.filter(r => r.id !== tag.id) : null;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.stateChanges.complete();
  }
}
