<greco-filter-bar
  [filterOptions]="_filterOptions"
  [hideSeparator]="true"
  (changed)="_filters$.next($event[1]); onFilterApplied()"
>
  <button
    *ngIf="canManageUnlockOptions$ | async"
    mat-stroked-button
    color="primary"
    style="height: 32px; line-height: 16px"
    (click)="createNewUnlockOption()"
  >
    <mat-icon>add</mat-icon>
    <span>New Option</span>
  </button>
</greco-filter-bar>

<greco-table [data]="(_unlockOptions$ | async) || []" [loading]="_loading" [highlight]="true">
  <greco-perk-badge
    *grecoTableCol="''; let option; fitContent: true"
    [small]="true"
    [matTooltip]="option.id"
    [text]="option.badge.text"
    [icon]="option.badge.icon"
    [color]="option.badge.color"
    [shape]="option.badge.shape"
  ></greco-perk-badge>

  <p *grecoTableCol="'Unlock Option'; let option" [matTooltip]="option.title">{{ option.title }}</p>

  <p
    *grecoTableCol="'Rental Period'; let option"
    [matTooltip]="option.rentalPeriod ? (option.rentalPeriod | grecoMinutes) : ''"
  >
    {{ !option.rentalPeriod ? 'Forever' : (option.rentalPeriod | grecoMinutes) }}
  </p>

  <p
    *grecoTableCol="'Per Video Price'; let option; alignRight: true"
    [matTooltip]="option.price ? (option.price / 100 | currency) + ' per video unlock' : ''"
  >
    {{ option.price ? (option.price / 100 | currency) : '-' }}
  </p>

  <mat-icon *grecoTableCol="'Available to Everyone'; let option; alignCenter: true">
    {{ option.availableToAnyone ? 'check_circle' : '' }}
  </mat-icon>

  <mat-chip-list *grecoTableCol="'Video Tags'; let option">
    <mat-chip *ngFor="let tag of option.videoTags"> {{ tag.label }} </mat-chip>
  </mat-chip-list>

  <ng-container *grecoTableCol="''; let videoPerk; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <a mat-menu-item [routerLink]="videoPerk.id">
        <mat-icon>create</mat-icon>
        <span>Edit Configuration</span>
      </a>

      <!-- <button mat-menu-item *ngIf="!videoPerk.used" (click)="removeVideoPerk(videoPerk)">
        <mat-icon>delete</mat-icon>
        <span>Remove</span>
      </button> -->
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="_paginationMeta?.totalItems"
  showFirstLastButtons
  [length]="_paginationMeta!.totalItems"
  [pageSize]="_paginationMeta!.itemsPerPage"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="_pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
