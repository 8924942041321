/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { AccountLinkStatus } from '@greco/account-linking';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { UserService } from '@greco/ngx-identity-users';
import { SecurityService } from '@greco/ngx-security-util';
import { Product, ProductVariant, VariantResource, VariantResourceAction } from '@greco/sales-products';
import { AccountLinkingService } from '@greco/web-account-linking';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-fit-create-quick-purchase',
  templateUrl: './create-quick-purchase.dialog.html',
  styleUrls: ['./create-quick-purchase.dialog.scss'],
})
export class CreateQuickPurchaseDialog implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<CreateQuickPurchaseDialog>,
    private formBuilder: FormBuilder,
    private clipboardSvc: ClipboardService,
    private linkSvc: AccountLinkingService,
    private securitySvc: SecurityService,
    private userSvc: UserService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      variant: ProductVariant;
      communityId: string;
      accountId: Product;
      contact?: Contact;
    }
  ) {
    this.communityId = data.communityId;
    if (data.contact) {
      this.form.setValue({
        contact: data.contact,
        variant: null,
        variants: [new FormControl(null, Validators.required)],
      });
    }
    this.canSellRestrictedVariants$ = this.securitySvc.hasAccess(
      VariantResource.key,
      VariantResourceAction.SELL_RESTRICTED,
      {
        communityId: this.communityId,
      }
    );
  }
  private user: User | null = null;

  private onDestroy$ = new Subject<void>();
  communityId: string;

  billedTo?: User;

  dialogData: DialogData = {
    title: 'Quick Purchase',
    subtitle: 'Checkout a product on the behalf of a user.',
    showCloseButton: false,
    hideDefaultButton: true,
  };

  currentStep = 0;

  form = this.formBuilder.group({
    contact: [null, Validators.required],
  });

  variantControls: FormControl[] = [new FormControl(null, Validators.required)];

  canSellRestrictedVariants$: Promise<boolean> | null = null;

  private _variants$ = new BehaviorSubject<(ProductVariant | null)[]>([]);

  canSelectMultipleVariants$ = this._variants$.pipe(
    map(variants => {
      if (!variants.length) return false;

      return !variants.some(v => v?.recurrence?.frequency);
    })
  );

  allVariantsSelected$ = this._variants$.pipe(
    map(variants => {
      if (!variants.length) return false;

      if (!variants.map((v): v is ProductVariant => !!v).every(v => v)) {
        return false;
      }
      if (variants.length > 1 && variants.some(v => v?.recurrence?.frequency)) {
        return false; //cannot purchase multiple products when selecting a recurring product
      }

      return true;
    })
  );

  variantIds$ = this._variants$.pipe(
    map(variants => {
      return variants.filter((v): v is ProductVariant => !!v).map(v => v.id);
    })
  );

  variantChanges() {
    this._variants$.next([...this.variantControls.map(c => c.value ?? null)]);
  }

  addAdditionalProduct() {
    this.variantControls.push(new FormControl(null, Validators.required));
    this.variantChanges();
    this.currentStep = this.variantControls.length;
  }

  removeVariant(index: number) {
    this.variantControls.splice(index, 1);
    this.variantControls = [...this.variantControls];
    this.variantChanges();
    this.currentStep = this.variantControls.length;
  }

  async close() {
    return this.dialogRef.close();
  }

  async ngOnInit() {
    this.form.valueChanges.pipe(startWith(this.form.value), takeUntil(this.onDestroy$)).subscribe(async () => {
      const contact = this.form.value.contact as Contact;
      this.billedTo = undefined;

      // This setTimeout is required for weird timing issues. The accounts for the user and the purchasedBy update seperately so
      //  this makes sure to wait for them both to be updated by the above line before giving them new values
      setTimeout(async () => {
        if (contact?.user!.email) {
          this.billedTo = contact.user;
          return;
        } else {
          const parents = (await this.linkSvc.getGivenLinksByAccount(contact?.user!.id))
            ?.filter(link => link.status === AccountLinkStatus.ACTIVE)
            .map(link => link.accessor!);

          if (parents.length) {
            this.billedTo = parents[0];
            return;
          } else {
            this.billedTo = undefined;
          }
        }
      }, 200);
    });

    this.user = await this.userSvc.getSelf();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async copyCheckoutUrlToClipboard(userId: string) {
    const variants = await toPromise(this.variantIds$);
    this.clipboardSvc.copy(
      `${window.location.origin}/shop/checkout?userId=${userId}&soldById=${this.user?.id}` +
        variants.map(v => `&items=${v}`).join(''),
      'Checkout URL'
    );
  }

  setStep(index: number) {
    if (this.currentStep === index) return;

    this.currentStep = index;
  }
}
