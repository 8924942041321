import { SecurityResource } from '@greco/security';

export const EVENT_VIDEO_UNLOCK_OPTION_SECURITY_RESOURCE = 'event_video_unlock_option';

export enum EventVideoUnlockOptionSecurityResourceAction {
  READ = 'READ',
  MANAGE = 'MANAGE',
}

export const EventVideoUnlockOptionSecurityResource: SecurityResource = {
  key: EVENT_VIDEO_UNLOCK_OPTION_SECURITY_RESOURCE,
  module: 'videos',

  title: 'Event Video Unlock Options',
  description: 'Manage event video unlock options',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: EventVideoUnlockOptionSecurityResourceAction.READ,
      title: 'Read Event Video Unlock Options',
      description: 'Ability to read unlock options for an event video',
    },
    {
      key: EventVideoUnlockOptionSecurityResourceAction.MANAGE,
      title: 'Manage Event Video Unlock Options',
      description: 'Ability to manage video unlock options for an event video',
    },
  ],
};
