<div
  *ngIf="user$ | async as user"
  style="
    position: fixed;
    top: 48px;
    left: 0;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - 48px);
    z-index: 10000;
  "
>
  <form *grecoLet="userDetails$ | async" class="pages-container" [formGroup]="form">
    <div
      class="page"
      style="transition: all; transition-duration: 500ms"
      [style]="'margin-top: -' + (page * 100) + 'vh'"
    >
      <div class="content">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 300px;
            height: 300px;
            border: 5px solid var(--primary-color);
            border-radius: 50%;
            overflow: hidden;
          "
        >
          <img src="assets/lf3/icon.png" style="width: 256px" />
        </div>

        <div style="display: flex; flex-direction: column; align-items: center; width: 100%">
          <p style="margin-top: 32px; font-size: 32px; max-width: 80%; text-align: center">Welcome to MyAltea!</p>
          <p style="margin-top: 16px; font-size: 24px; max-width: 80%; text-align: center">Let's get you started</p>
        </div>
      </div>

      <div class="footer">
        <button mat-flat-button color="primary" (click)="nextPage()">Next</button>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <p class="header">First, let's set up your profile picture</p>
        <p style="margin-bottom: 32px">
          Add a clear headshot - no filters, sunglasses, hats or pets! Please note you can only upload your photo here
          so make it a good one.
        </p>

        <div style="width: 256px; height: 256px; margin-bottom: 16px">
          <greco-smart-img
            round
            border
            borderColor="primary"
            [src]="form.value?.photoURL || 'assets/lf3/icon_square_pad.png'"
          ></greco-smart-img>
        </div>

        <mat-form-field id="picture-form-field" floatLabel="always" appearance="outline" style="display: none">
          <mat-label style="padding-left: 24px">Profile Picture</mat-label>
          <input matInput readonly [value]="profilePictureFile?.name || ''" />

          <input #fileInput type="file" accept="image/*" (change)="setProfilePicture($any($event.target).files[0])" />
        </mat-form-field>

        <div style="display: flex; gap: 12px">
          <button mat-flat-button color="primary" (click)="fileInput.click()">Select File</button>
          <button mat-flat-button color="primary" (click)="takePhoto()">Take Picture</button>
        </div>
      </div>

      <div class="footer">
        <button mat-stroked-button color="primary" (click)="prevPage()">Back</button>
        <button mat-flat-button color="primary" [disabled]="!form.value.photoURL" (click)="nextPage()">Next</button>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <p class="header">A quick check before we continue</p>
        <p style="margin-bottom: 32px">
          Confirm your details below to continue. If you need to update your email, please contact
          <a href="mailto:membership@alteaactive.com" target="_blank">membership&#64;alteaactive.com</a> or stop by the
          front desk in club.
        </p>

        <mat-form-field readonly style="width: 100%; max-width: 100%; margin-bottom: 16px">
          <mat-label>Email Address</mat-label>
          <input readonly matInput type="email" formControlName="email" placeholder="What's your email address?" />
        </mat-form-field>

        <div style="flex: 1; display: flex; flex-wrap: wrap; gap: 8px; max-height: fit-content">
          <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" placeholder="What's your first name?" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" placeholder="What's your last name?" />
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%; max-width: 100%">
            <mat-label>Phone Number</mat-label>
            <input
              matInput
              type="tel"
              mask="(000) 000-0000"
              formControlName="phoneNumber"
              placeholder="At what number can we reach you?"
            />
          </mat-form-field>
        </div>
      </div>

      <div *grecoLet="form.value as value" class="footer">
        <button mat-stroked-button color="primary" (click)="prevPage()">Back</button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!value.firstName || !value.lastName || !value.email || !value.phoneNumber"
          (click)="nextPage()"
        >
          Next
        </button>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <p class="header">Next, let's {{ form.value.address ? 'confirm' : 'add' }} your address</p>

        <mat-form-field appearance="outline" style="width: 100%; max-width: 100%">
          <mat-label>Address</mat-label>
          <greco-address-input formControlName="address"></greco-address-input>
        </mat-form-field>
      </div>

      <div class="footer">
        <button mat-stroked-button color="primary" (click)="prevPage()">Back</button>
        <button mat-flat-button color="primary" [disabled]="!form.value.address" (click)="nextPage()">Next</button>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <p class="header">And just a couple more small details...</p>

        <mat-form-field appearance="outline" style="width: 100%; max-width: 100%">
          <mat-label>Birthday</mat-label>
          <input
            matInput
            required
            [max]="now"
            [matDatepicker]="birthday"
            formControlName="birthday"
            placeholder="Birthday"
            (click)="birthday.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="birthday"></mat-datepicker-toggle>
          <mat-datepicker startView="multi-year" #birthday></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%; max-width: 100%">
          <mat-label>Gender</mat-label>
          <mat-select #select formControlName="gender" required>
            <mat-option value="Male">Male</mat-option>
            <mat-option value="Female">Female</mat-option>
            <mat-option value="Non-binary">Non-binary</mat-option>
            <mat-option value="Transgender">Transgender</mat-option>
            <mat-option value="Intersex">Intersex</mat-option>
            <mat-option value="Prefer not to say">Prefer not to say</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="form.controls.gender.value === 'Other'"
          appearance="outline"
          class="other-field"
          [floatLabel]="'always'"
          [style.overflow]="select.value === 'Other' ? 'auto' : 'hidden'"
          [@heightExpansion]="select.value === 'Other' ? 'expanded' : 'collapsed'"
        >
          <mat-label style="margin-left: 12px">Other</mat-label>
          <input matInput placeholder="Please Specify..." formControlName="genderOther" />
        </mat-form-field>
      </div>

      <div *grecoLet="form.value as value" class="footer">
        <button mat-stroked-button color="primary" (click)="prevPage()">Back</button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!form.value.gender || !form.value.birthday"
          (click)="nextPage()"
        >
          Next
        </button>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <p class="header">Add your emergency contact information</p>

        <mat-form-field appearance="outline" style="width: 100%; max-width: 100%">
          <mat-label>
            Emergency Contact Name
            <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
          </mat-label>
          <input matInput formControlName="emergencyContactName" />
        </mat-form-field>

        <!-- Emergency Phone Number -->
        <mat-form-field appearance="outline" style="width: 100%; max-width: 100%">
          <mat-label>Emergency Contact Phone Number</mat-label>
          <input
            matInput
            required
            type="tel"
            mask="(000) 000-0000"
            formControlName="emergencyPhoneNumber"
            placeholder="At what number can we reach you in case of an emergency?"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%; max-width: 100%">
          <mat-label>
            Emergency Contact Relationship
            <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
          </mat-label>
          <input matInput formControlName="emergencyContactRelationship" />
        </mat-form-field>
      </div>

      <div *grecoLet="form.value as value" class="footer">
        <button mat-stroked-button color="primary" (click)="prevPage()">Back</button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!form.value.emergencyContactName || !form.value.emergencyPhoneNumber || !form.value.emergencyContactRelationship"
          (click)="nextPage()"
        >
          Next
        </button>
      </div>
    </div>

    <div class="page">
      <div class="content">
        <p class="header">Let's add your payment method</p>
        <p *ngIf="!paymentMethodControl.value">This payment method will be used for your membership.</p>
        <p
          *ngIf="paymentMethodControl.value && paymentMethodControl.value.model === 'bank'"
          style="margin-bottom: 32px; color: var(--warning-color)"
        >
          Thank you for adding your bank account, this can be used for your membership. However, we also need a credit
          card on your account. Select or add one below.
        </p>

        <mat-form-field appearance="outline" style="width: 100%; max-width: 100%">
          <greco-select-payment-method
            #selectPaymentMethodInput
            [userId]="user.id"
            [allowBankPayments]="true"
            [formControl]="paymentMethodControl"
          ></greco-select-payment-method>

          <greco-add-payment-method-button
            matSuffix
            [onlyIcon]="true"
            [userId]="user.id"
            (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
          ></greco-add-payment-method-button>
        </mat-form-field>
      </div>

      <div class="footer">
        <button mat-stroked-button color="primary" (click)="prevPage()">Back</button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!paymentMethodControl.value?.id || paymentMethodControl.value.model === 'bank'"
          (click)="nextPage()"
        >
          Next
        </button>
      </div>
    </div>

    <div class="page">
      <div class="content" style="margin-bottom: 64px">
        <p class="header">You're all done!</p>
        <p>
          Thank you for confirming your details. Feel free to double check your information before you hit "Submit".
        </p>
      </div>
      <div class="footer">
        <button mat-stroked-button color="primary" (click)="prevPage()">Back</button>
        <button mat-flat-button color="primary" [disabled]="saving" (click)="submit()">Submit</button>
      </div>
    </div>
  </form>
</div>
