<ng-container *ngIf="user && collectionVideo">
  <greco-video-unlock-info
    id="video-info"
    [user]="user"
    [collectionVideo]="collectionVideo"
    [activeUnlock]="(_activeUnlock$ | async) || undefined"
    [selectedPerkRentalPeriod]="selectedPerkRentalPeriod$ | async"
  ></greco-video-unlock-info>

  <ng-container *ngIf="(_activeUnlock$ | async) === null">
    <ng-container *grecoLet="_preview$ | async as preview">
      <ng-container *ngIf="preview">
        <greco-collapsible-section
          *ngIf="preview.purchase"
          id="purchase-preview-section"
          [header]="{ title: 'Payment', icon: 'payment' }"
        >
          <greco-purchase-preview
            [communityId]="collectionVideo.communityVideo?.communityId"
            [purchase]="preview.purchase"
            [showHeader]="false"
            [showTotals]="true"
          ></greco-purchase-preview>

          <div *ngIf="_showPaymentMethodPicker$ | async" class="payment-method-container">
            <mat-form-field appearance="standard" class="payment-method-picker-form-field">
              <mat-label *ngIf="!_paymentMethodControl.value"><i>Add a Payment Method</i></mat-label>
              <greco-select-payment-method
                #selectPaymentMethodInput
                [formControl]="_paymentMethodControl"
                [userId]="user.id"
                [required]="true"
                [allowBankPayments]="false"
              ></greco-select-payment-method>
              <greco-add-payment-method-button
                matSuffix
                [onlyIcon]="true"
                [userId]="user.id"
                (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
              ></greco-add-payment-method-button>
            </mat-form-field>
          </div>
        </greco-collapsible-section>

        <div id="error" *ngIf="preview?.errors?.length">
          <mat-icon>warning</mat-icon>
          <span *ngIf="preview?.errors?.length" [innerHTML]="$any(preview).errors[0] | safe: 'html'"></span>
        </div>

        <div id="warning" *ngIf="preview?.warnings?.length">
          <mat-icon>warning</mat-icon>
          <span *ngIf="preview?.warnings?.length" [innerHTML]="$any(preview).warnings[0] | safe: 'html'"> </span>
        </div>
      </ng-container>

      <ng-container *ngIf="_applicableUnlockOptions$ | async as perks">
        <div class="footer" [class.in-page]="footerInPage" *ngIf="perks.length">
          <mat-divider></mat-divider>

          <div class="footer-content">
            <div *ngIf="_selectedOption" style="cursor: pointer" (click)="perkPicker.open()">
              <strong
                style="display: block; overflow: hidden; max-width: 200px; white-space: nowrap; text-overflow: ellipsis"
              >
                {{_selectedOption.title}}
              </strong>
            </div>

            <div style="margin-right: 16px">
              <mat-select
                #perkPicker
                [value]="_selectedOption"
                [compareWith]="_comparePerks"
                (valueChange)="_selectedOption = $event"
              >
                <mat-option *ngFor="let option of perks" [value]="option"> {{option.title}} </mat-option>
              </mat-select>
            </div>

            <div
              [matTooltip]="preview ? (preview.errors | grecoJoin: '; ') ? (preview.errors | grecoJoin: '; ') : (preview.warnings | grecoJoin: '; ')  : ''"
            >
              <button
                mat-flat-button
                color="primary"
                [disabled]="_confirming || !preview || !!preview?.errors?.length"
                (click)="preview && unlockVideo(preview)"
              >
                <span>{{(_isMobile$ | async) ? 'Unlock' : 'Unlock Video'}}</span>
                <mat-icon *ngIf="_confirming" style="margin-left: 4px" class="spin">loop</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
