import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { UserPerk } from '@greco/sales-perks';
import { CollectionVideo, VideoSubCollection } from '@greco/video-library';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { SwiperComponent } from 'swiper/angular';
import { CollectionVideoService } from '../../../services';

@Component({
  selector: 'greco-user-subcollection-viewer',
  templateUrl: './user-subcollection-viewer.component.html',
  styleUrls: ['./user-subcollection-viewer.component.scss'],
})
export class UserSubcollectionViewerComponent implements OnInit, OnDestroy {
  constructor(
    private videosSvc: CollectionVideoService,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpoints: BreakpointObserver
  ) {}
  @ViewChild(SwiperComponent) swiper?: SwiperComponent;
  @Input() unlockOptions: UserPerk[] = [];
  @Input() last = false;
  @Input() showHeader = false;
  @PropertyListener('user') private _user$ = new BehaviorSubject<User | null | undefined>(null);
  @Input() user?: User | null;
  @PropertyListener('page') private page$ = new BehaviorSubject<number>(1);
  page = 1;
  limit = 5;
  total = 999;
  @PropertyListener('subcollection') _subcollection$ = new BehaviorSubject<VideoSubCollection | null>(null);
  @Input() subcollection!: VideoSubCollection;
  mobileView = false;
  private _onDestroy$ = new Subject<void>();

  videos: CollectionVideo[] = [];

  loading = true;

  _videos$ = combineLatest([this._subcollection$, this.page$, this._user$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([sub, page, user]) => {
      const userId = user ? user.id : undefined;
      const response =
        sub && page > 0 ? await this.videosSvc.paginateForUser(sub.id, userId, { page, limit: this.limit }) : null;
      if (response) {
        this.total = response.meta.totalItems;
        this.videos.push(...response.items.filter(v => !this.videos.some(vd => vd.id === v.id)));
        this.videos = this.videos.sort((a, b) => a.sortIndex - b.sortIndex);
        //this.swiper?.swiperRef.slideNext(100);
      }
    }),
    tap(() => (this.loading = false))
  );

  async ngOnInit() {
    this.breakpoints
      .observe('(max-width: 600px)')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(({ matches }) => {
        this.mobileView = matches;
        this.changeDetectorRef.detectChanges();
      });
  }
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this._subcollection$.complete();
  }

  loadMore() {
    if (this.loading) return;
    const index = this.swiper?.swiperRef.activeIndex;
    const realIndex = this.swiper?.swiperRef.realIndex;
    if (this.videos.length < this.total) {
      this.page++;
      console.log(this.page);
    }

    console.log('swiper index', index, realIndex);
  }
}
