import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { VideosService } from '@greco/ngx-videos';
import { CollectionVideo, UploadCollectionVideoDto } from '@greco/video-library';
import { Video, VideoSourceRequestDto } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({
  providedIn: 'root',
})
export class CollectionVideoService {
  private baseUrl = '/api/video_collection_videos';
  constructor(private http: HttpClient, private videosSvc: VideosService) {}
  //@Get()
  async paginate(
    query: RequestQueryBuilder,
    playlistId: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<CollectionVideo>> {
    return await toPromise(
      this.http.get<PaginatedDto<CollectionVideo>>(`${this.baseUrl}`, {
        params: {
          ...query.queryObject,
          subCollectionId: playlistId,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }
  //@Get()
  async getBySubCollection(playlistId: string): Promise<CollectionVideo[]> {
    return await toPromise(
      this.http.get<CollectionVideo[]>(`${this.baseUrl}/all`, {
        params: {
          subCollectionId: playlistId,
        },
      })
    );
  }
  //@Get(':programVideoId')
  async getOneVideo(pVidId: string) {
    return await toPromise(this.http.get<CollectionVideo>(`${this.baseUrl}/${pVidId}`));
  }

  //@Post('upload')
  async upload(file: File, dto: UploadCollectionVideoDto): Promise<CollectionVideo> {
    //TODO: CreateCollectionVideoAndInitiateUploadDto
    dto.fileSize = file.size;
    const response = await toPromise(
      this.http.post<{ collectionVideo: CollectionVideo; video: Video; uploadUrl: string }>(`${this.baseUrl}`, dto)
    );
    await this.videosSvc.clientSideUpload(file, dto.source, response.video, response.uploadUrl);
    return response.collectionVideo;
  }

  // @Post('link')
  linkCommunityVideoToSubcollection(subcollectionId: string, communityVideoId: string) {
    return toPromise(
      this.http.post<any>(`${this.baseUrl}/add`, {
        subCollectionId: subcollectionId,
        communityVideoId: communityVideoId,
      })
    );
  }
  // @Post('link')
  linkVideoToSubcollection(subcollectionId: string, dto: VideoSourceRequestDto) {
    return toPromise(this.http.post<any>(`${this.baseUrl}/add/video`, { subCollectionId: subcollectionId, dto }));
  }

  //@Patch(':programVideoId')
  async updateProgramVideo(pVidId: string, dto: any) {
    return await toPromise(this.http.patch<CollectionVideo>(`${this.baseUrl}/${pVidId}`, dto));
  }

  //@Patch(':videoId/sortIndex')
  async updateSortIndex(videoId: string, sortIndex: number) {
    return await toPromise(this.http.patch<CollectionVideo>(`${this.baseUrl}/${videoId}`, { sortIndex: sortIndex }));
  }

  async removeCollectionVideo(id: string) {
    return await toPromise(this.http.delete<CollectionVideo | null>(`${this.baseUrl}/${id}`));
  }

  async paginateForUser(
    subcollectionId: string,
    userId?: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<CollectionVideo>> {
    const query = new RequestQueryBuilder();
    query
      .setFilter([
        {
          field: 'availableOn',
          operator: '$lt',
          value: new Date().toISOString(),
        },
        { field: 'availableUntil', operator: '$gt', value: new Date().toISOString() },
      ])
      .setOr([
        {
          field: 'availableOn',
          operator: '$lt',
          value: new Date().toISOString(),
        },
        {
          field: 'availableUntil',
          operator: '$isnull',
        },
      ]);

    query.sortBy({ field: 'sortIndex', order: 'ASC' });
    const response = await toPromise(
      this.http.get<PaginatedDto<CollectionVideo>>(`${this.baseUrl}`, {
        params: {
          ...query.queryObject,
          subCollectionId: subcollectionId || '',
          ...(userId && { userId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          excludeArchived: 'true',
        },
      })
    );
    return response;
  }

  // async sortIndexBatchUpdate(startingIndex: number, collectionVideos: CollectionVideo[]) {
  //   const colVidIds = collectionVideos.map(vid => vid.id);
  //   return await toPromise(this.http.patch<any>(`${this.baseUrl}`, { startingIndex, collectionVideos: colVidIds }));
  // }
}
