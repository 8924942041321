import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { Booking } from '@greco/booking-events';
import { BookingService } from '@greco/ngx-booking-events';
import { PerkService } from '@greco/ngx-sales-perks';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-unconfirmed-booking-alert',
  templateUrl: './unconfirmed-booking-alert.component.html',
  styleUrls: ['./unconfirmed-booking-alert.component.scss'],
})
export class UnconfirmedBookingAlertComponent {
  constructor(
    private snacks: MatSnackBar,
    private perkSvc: PerkService,
    private matDialog: MatDialog,
    private bookingSvc: BookingService
  ) {}

  @PropertyListener('booking') booking$ = new BehaviorSubject<Booking | null>(null);
  @Input() booking!: Booking;

  @Input() isUserSide = false;

  @Output() refreshBookings = new EventEmitter<string>();

  loading = false;
  perkUsed = '';
  totalPerksOfType = 0;

  userPerks$ = this.booking$.pipe(switchMap(pendingBooking => this.perkSvc.getUserPerks(pendingBooking?.user.id)));

  canUserConfirmBooking$ = combineLatest([this.booking$, this.userPerks$]).pipe(
    map(([pendingBooking, userPerks]) => {
      const perkUsed = userPerks.find(userPerk => userPerk.perk.id === pendingBooking?.bookingOption.id);
      if (!perkUsed) return false;

      this.perkUsed = perkUsed.perk.title || '';
      this.totalPerksOfType = userPerks.filter(userPerk => userPerk.perk.id === perkUsed.perk.id)?.length || 0;

      return true;
    })
  );

  async confirm() {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Booking',
        subtitle: 'Are you sure you want to confirm this booking?',
        content: `You will be using 1 ${this.perkUsed} perk of ${this.totalPerksOfType} to book this event`,
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const confirmed = await this.bookingSvc.confirmPending(this.booking.id);
      this.refreshBookings.emit('TriedConfirming');

      if (confirmed) {
        this.snacks.open('Booking confirmed!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }
    }
  }

  async cancel(freeOfCharge: boolean) {
    if (this.isUserSide) return;

    this.loading = true;
    await this.bookingSvc.cancel(this.booking.id, freeOfCharge);
    this.loading = false;

    this.refreshBookings.emit('TriedConfirming');
  }
}
