import { BaseDto } from '@greco/util-dto';
import { Expose, Transform, Type } from 'class-transformer';
import { IsArray, IsDate, IsOptional, IsString } from 'class-validator';

export class QueryEventVideosByEventDate extends BaseDto {
  @Expose()
  @IsDate()
  @Type(() => Date)
  startDate: Date;

  @Expose()
  @IsDate()
  @Type(() => Date)
  endDate: Date;

  @Expose()
  @IsArray()
  @IsOptional()
  @IsString({ each: true })
  @Transform(({ value }) => (typeof value === 'string' ? value.split(',').filter(Boolean) : value))
  videoTagIds?: string[];

  @Expose()
  @IsString()
  @IsOptional()
  userId?: string;
}
