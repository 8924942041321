import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { CommunityVideoUnlockOption } from '@greco/community-videos';
import { User } from '@greco/identity-users';
import { CommunityVideoUnlockService } from '@greco/ngx-community-videos';
import { VideosService } from '@greco/ngx-videos';
import { PropertyListener } from '@greco/property-listener-util';
import { UserPerk } from '@greco/sales-perks';
import { CollectionVideo, CollectionVideoUnlockPreview } from '@greco/video-library';
import { VideoUnlock } from '@greco/videos';
import moment from 'moment';
import { BehaviorSubject, combineLatest, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { CollectionVideoService, CollectionVideoUnlockService } from '../../../services';

@Component({
  selector: 'greco-preview-unlock-collection-video-page',
  templateUrl: './preview-unlock-video.page.html',
  styleUrls: ['./preview-unlock-video.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PreviewUnlockVideoPage {
  constructor(
    private collectionVideoSvc: CollectionVideoService,
    private breakpoints: BreakpointObserver,
    private unlockSvc: CollectionVideoUnlockService,
    private comUnlockSvc: CommunityVideoUnlockService,
    private videosSvc: VideosService,
    private snacks: MatSnackBar
  ) {}

  private refresh$ = new BehaviorSubject<null>(null);

  _isMobile$ = this.breakpoints.observe('(max-width: 600px)').pipe(
    map(({ matches }) => matches),
    shareReplay(1)
  );

  _confirming = false;
  _paymentMethodControl = new FormControl(null, Validators.required);
  private paymentMethod$ = this._paymentMethodControl.valueChanges.pipe(startWith(null));

  @Output() unlocked = new EventEmitter<VideoUnlock>();

  @PropertyListener('user') private user$ = new ReplaySubject<User>(1);
  @Input() user?: User;

  @PropertyListener('collectionVideo') private collectionVideo$ = new ReplaySubject<CollectionVideo>(1);
  @Input() collectionVideo?: CollectionVideo;

  @Input() footerInPage = false;

  _selectedOption?: CommunityVideoUnlockOption;
  @PropertyListener('_selectedOption') private selectedOption$ = new BehaviorSubject<CommunityVideoUnlockOption | null>(
    null
  );

  readonly selectedPerkRentalPeriod$ = this.selectedOption$.pipe(
    switchMap(async option => {
      if (!option || option.rentalPeriod === 0) return null;
      return moment().add(option.rentalPeriod, 'minutes').toDate();
    })
  );

  private userPerks$ = combineLatest([this.collectionVideo$, this.user$]).pipe(
    switchMap(async ([collectionVideo, user]) => {
      if (!collectionVideo || !user) return [];

      const video = collectionVideo.communityVideo?.video;
      if (!video) return [];

      const userPerks = await this.comUnlockSvc.getUserUnlockOptions(
        user?.id,
        video.tags?.map(t => t.id)
      );

      return userPerks;
    }),
    shareReplay(1)
  );

  private selectedOptionUserPerks$ = this.selectedOption$.pipe(
    switchMap(selected =>
      selected ? this.userPerks$.pipe(map(up => up.filter((p: UserPerk) => p.perk.id === selected.id))) : of([])
    )
  );

  readonly _activeUnlock$ = combineLatest([this.collectionVideo$, this.user$, this.refresh$]).pipe(
    switchMap(async ([collectionVideo, user]) =>
      collectionVideo?.communityVideo && user
        ? await this.videosSvc.hasActiveVideoUnlock(collectionVideo.communityVideo.videoId)
        : null
    ),
    map(unlock => unlock || null),
    shareReplay(1)
  );

  readonly _preview$ = combineLatest([
    this.user$,
    this.collectionVideo$,
    this.selectedOption$,
    this.paymentMethod$,
  ]).pipe(
    switchMap(async ([user, collectionVideo, unlockOption, paymentMethod]) =>
      user && collectionVideo && unlockOption
        ? await this.unlockSvc.preview_unlockCollectionVideo({
            paymentMethodId: paymentMethod?.id,
            unlockOptionId: unlockOption.id,
            collectionVideoId: collectionVideo.id,
            unlockDate: new Date(),
            userId: user.id,
          })
        : null
    ),

    tap(async preview => {
      const userPerks = await toPromise(this.selectedOptionUserPerks$);
      if (
        preview &&
        !preview.purchase &&
        !preview.errors.length &&
        userPerks.some((up: UserPerk) => !up.consumable && !(up.perk as CommunityVideoUnlockOption).price)
      ) {
        await this.unlockVideo(preview);
      }
    }),

    shareReplay(1)
  );

  readonly _showPaymentMethodPicker$ = this._preview$.pipe(
    map(preview => {
      if (!preview?.purchase) return false;
      return preview.purchase.total - preview.purchase.balanceUsed > 0;
    })
  );

  readonly _applicableUnlockOptions$ = this.userPerks$.pipe(
    map((userPerks: UserPerk[]) =>
      userPerks.map((up: UserPerk) => up.perk).filter((p, i, a) => i === a.findIndex(v => v.id === p.id))
    ),
    tap(options => setTimeout(() => (this._selectedOption = options[0] as any))),
    shareReplay(1)
  );

  readonly _comparePerks = (a?: CommunityVideoUnlockOption, b?: CommunityVideoUnlockOption) => a?.id === b?.id;

  async unlockVideo(preview: CollectionVideoUnlockPreview) {
    this._confirming = true;

    try {
      const videoId = this.collectionVideo?.communityVideo?.videoId;
      if (!videoId) throw new Error();

      const unlock = await this.unlockSvc.unlockCollectionVideo(preview.dto, preview.hash);
      this.unlocked.emit(unlock);

      this.snacks.open('Video unlocked!', 'Ok', { duration: 3500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
      this.snacks.open('Oops, something went wrong. Please try again later!', 'Ok', {
        duration: 5000,
        panelClass: 'mat-warn',
      });
    }

    this._confirming = false;
  }

  refresh() {
    this.refresh$.next(null);
  }
}
