import { Component, Injectable, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  CommunityVideo,
  CommunityVideoUnlockSecurityResource,
  CommunityVideoUnlockSecurityResourceAction,
} from '@greco/community-videos';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { VideoUnlock } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ManageUnlockDialog } from '../../dialogs';
import { ExpiredFilter } from '../../filters';
import { CommunityVideoUnlockService } from '../../services';
import { CommunityVideoSearchFilter } from '../video-unlocks/video-unlocks.component';

@Injectable({ providedIn: 'any' })
export class UserSearchFilter extends BuildSearchFilter('UserSearchFilter', {
  properties: ['user.displayName'],
  propertyLabels: ['Display Name'],
}) {}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-community-video-unlock-table',
  templateUrl: './community-video-unlock-table.component.html',
  styleUrls: ['./community-video-unlock-table.component.scss'],
})
export class CommunityVideoUnlockTableComponent implements OnDestroy {
  constructor(
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private comSecSvc: CommunitySecurityService,
    private unlockSvc: CommunityVideoUnlockService
  ) {}

  @PropertyListener('communityVideo') private communityVideo$ = new ReplaySubject<CommunityVideo>(1);
  @Input() communityVideo?: CommunityVideo;
  @Input() communityId!: string;
  private _onDestroy$ = new Subject<void>();
  loading = false;
  paginationMeta?: IPaginationMeta;
  now: number = Date.now();
  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });

  filterOptions = [CommunityVideoSearchFilter, ExpiredFilter];

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  set statusFilter(statusFilter) {
    this._statusFilter$.next(statusFilter);
  }

  get statusFilter() {
    return this._statusFilter$.value;
  }
  private _statusFilter$ = new BehaviorSubject<boolean>(false);

  readonly canRead$ = this.communityVideo$.pipe(
    switchMap(async communityVideo => {
      return communityVideo
        ? await this.comSecSvc.hasAccess(
            communityVideo.communityId,
            CommunityVideoUnlockSecurityResource.key,
            CommunityVideoUnlockSecurityResourceAction.READ
          )
        : null;
    })
  );

  readonly canUpdate$ = this.communityVideo$.pipe(
    switchMap(async communityVideo => {
      return communityVideo
        ? await this.comSecSvc.hasAccess(
            communityVideo.communityId,
            CommunityVideoUnlockSecurityResource.key,
            CommunityVideoUnlockSecurityResourceAction.MANAGE
          )
        : null;
    })
  );

  videoUnlocks$: Observable<VideoUnlock[]> = combineLatest([
    this.filters$,
    this.pagination$,
    this.communityVideo$,
    this._statusFilter$,
    this.page$,
  ]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([filters, options, communityVideo, statusFilter]) =>
      filters && communityVideo
        ? await this.unlockSvc.getCommunityVideoUnlocks(filters, communityVideo.id, statusFilter, options)
        : null
    ),
    tap(data => (this.paginationMeta = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.communityVideo$.complete();
    this.filters$.complete();
    this.page$.complete();
    this._statusFilter$.complete();
  }

  refresh() {
    this.page$.next(this.page$.value);
  }

  async grant() {
    if (this.communityVideo) {
      const dialog = this.matDialog.open(ManageUnlockDialog, { data: {}, width: '400px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.communityVideo = this.communityVideo;
      await toPromise(dialog.afterClosed());
      this.refresh();
    }
  }

  async update(unlock: VideoUnlock) {
    if (this.communityVideo) {
      const dialog = this.matDialog.open(ManageUnlockDialog, { data: {}, width: '400px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.communityVideo = this.communityVideo;
      instance.updateUnlock = unlock;
      await toPromise(dialog.afterClosed());
      this.refresh();
    }
  }

  toggleExpired() {
    this.statusFilter = !this.statusFilter;
  }
}
