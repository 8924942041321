import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@greco/ngx-identity-auth';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-user-video-collections-wrapper',
  templateUrl: './user-video-collections-wrapper.page.html',
  styleUrls: ['./user-video-collections-wrapper.page.scss'],
})
export class UserVideoCollectionsWrapperPage implements OnInit, OnDestroy {
  constructor(private userSvc: UserService, private crumbs: BreadcrumbService) {}

  private refresh$ = new BehaviorSubject<null>(null);

  user$ = this.refresh$.pipe(switchMap(() => this.userSvc.user$));

  ngOnInit() {
    setTimeout(() => this.crumbs.set([]));
    // this.videoUnlockSvc.getUserUnlockOptions().then(unlockOptions => console.log({ unlockOptions }));
  }

  ngOnDestroy() {
    this.refresh$.complete();
  }

  refresh() {
    this.refresh$.next(null);
  }
}
