import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommunityGiftCardConfig } from '@greco/gift-cards';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShopService } from '../../services';

@Component({
  selector: 'greco-gift-card-slides',
  templateUrl: './gift-card-slides.component.html',
  styleUrls: ['./gift-card-slides.component.scss'],
})
export class GiftCardSlidesComponent implements OnInit, OnDestroy {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private breakpoints: BreakpointObserver,
    private shopSvc: ShopService,
    private router: Router
  ) {}

  @Input() giftCardConfigs: CommunityGiftCardConfig[] = [];

  mobileView = false;

  private _onDestroy$ = new Subject<void>();

  async viewMore() {
    await this.router.navigate(['shop', 'gift-cards']);
  }

  async ngOnInit() {
    this.breakpoints
      .observe('(max-width: 600px)')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(({ matches }) => {
        this.mobileView = matches;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
