import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@greco/property-listener-util';
import { VideoCollection, VideoSubCollection } from '@greco/video-library';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-video-collection-card',
  templateUrl: './video-collection-card.component.html',
  styleUrls: ['./video-collection-card.component.scss'],
})
export class VideoCollectionCardComponent {
  constructor(private router: Router, private route: ActivatedRoute) {}

  @Input() showFeaturedStar = false;
  @PropertyListener('userOptions') private _userOptions$ = new BehaviorSubject<string[] | null>(null);
  @Input() userOptions: string[] = [];
  @Input() set collection(collection) {
    if ((collection as any).subcollections)
      (collection as any).subcollections = (collection as any).subcollections.filter(
        (sub: VideoSubCollection) =>
          sub.availableOn &&
          sub.availableOn.getTime() <= Date.now() &&
          (!sub.availableUntil || sub.availableUntil.getTime() > Date.now())
      );
    this._collection$.next(collection);
  }

  get collection() {
    return this._collection$.value;
  }
  private _collection$ = new BehaviorSubject<VideoCollection | null>(null);

  isAvailable$ = combineLatest([this._collection$, this._userOptions$]).pipe(
    switchMap(async ([collection, userOptions]) => {
      return collection && userOptions ? userOptions.some(up => up === collection.collectionTagId) : false;
    })
  );

  async selected() {
    if (this.collection) await this.router.navigate([this.collection.id], { relativeTo: this.route });
  }
}
