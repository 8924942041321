import { SecurityResource } from '@greco/security';

export const GIFT_CARDS_SECURITY_RESOURCE = 'gift-cards';

export enum GiftCardsSecurityAction {
  READ_CONFIG = 'READ_CONFIG',
  MANAGE_CONFIG = 'MANAGE_CONFIG',
}

export const GiftCardsSecurityResource: SecurityResource = {
  key: GIFT_CARDS_SECURITY_RESOURCE,
  module: 'Sales',
  title: 'Gift Cards',
  description: 'Manage gift cards',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: GiftCardsSecurityAction.READ_CONFIG,
      title: 'View Gift Card Settings',
      description: 'Ability to view community gift card settings',
    },
    {
      key: GiftCardsSecurityAction.MANAGE_CONFIG,
      title: 'Manage Gift Card Settings',
      description: 'Ability to manage community gift card settings',
    },
  ],
};
