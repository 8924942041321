import { Component, Input } from '@angular/core';
import { Station } from '@greco/stations';

@Component({
  selector: 'greco-station-page',
  templateUrl: './station.page.html',
  styleUrls: ['./station.page.scss'],
})
export class StationPageComponent {
  @Input() station!: Station;
}
