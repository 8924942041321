import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BookingOption } from '@greco/booking-events';
import { Tax } from '@greco/finance-tax';
import { CurrencyMaskConfig } from 'ngx-currency';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookingOptionService } from '../../services';

@Component({
  selector: 'greco-booking-option-booking-and-cancellation-form',
  templateUrl: './booking-option-booking-cancellation-form.component.html',
  styleUrls: ['./booking-option-booking-cancellation-form.component.scss'],
})
export class BookingOptionBookingAndCancellationFormComponent implements OnDestroy {
  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  public scBookingVisible = false;
  public scCancelVisible = false;

  constructor(private formBuilder: FormBuilder, private bookingOptionSvc: BookingOptionService) {
    this.form.disable();

    this.form
      .get('cancellation')
      ?.valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe(cancellation => {
        if (cancellation) {
          this.form.get('refund')?.enable();
          this.form.get('cancellationPrice')?.enable();
          this.form.get('cancellationWindow')?.enable();
        } else {
          this.form.get('refund')?.disable();
          this.form.get('cancellationPrice')?.disable();
          this.form.get('cancellationWindow')?.disable();
        }
      });

    this.form
      .get('price')
      ?.valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe(price => {
        if (price > 0) {
          this.form.get('saleCategoryBook')?.enable();
          this.scBookingVisible = true;
        } else {
          this.form.get('saleCategoryBook')?.disable();
          this.form.get('saleCategoryBook')?.setValue(null);
          this.scBookingVisible = false;
        }
      });

    this.form
      .get('cancellationPrice')
      ?.valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe(cancellationPrice => {
        if (cancellationPrice > 0) {
          this.form.get('saleCategoryCancel')?.enable();
          this.scCancelVisible = true;
        } else {
          this.form.get('saleCategoryCancel')?.disable();
          this.form.get('saleCategoryCancel')?.setValue(null);
          this.scCancelVisible = false;
        }
      });
  }

  private _onDestroy$ = new Subject<void>();

  @Output() saved = new EventEmitter<BookingOption>(true);

  resetValue: any = {
    bookingWindow: 60,
    maxBoost: null,
    allowBoost: false,
    price: 0,
    cancellation: false,
    refundCancellation: false,
    cancellationWindow: 0,
    cancellationPrice: 0,
    saleCategoryBook: null,
    saleCategoryCancel: null,
    allowPendingBookings: false,
    bookingTaxes: [],
    bookingIgnoreTaxes: false,
    cancellationTaxes: [],
    cancellationIgnoreTaxes: false,
  };

  private _bookingOption?: BookingOption;
  @Input() set bookingOption(bookingOption) {
    this._bookingOption = bookingOption;
    this.resetForm();
  }
  get bookingOption() {
    return this._bookingOption;
  }

  accountId?: string;

  form = this.formBuilder.group({
    bookingWindow: [60, [Validators.required, Validators.min(0)]],
    maxBoost: [null],
    allowBoost: [false, Validators.nullValidator],
    price: [0, Validators.min(0)],
    usages: [null],
    additionalSpots: [null],
    additionalSpotCost: [null],
    cancellation: [false, Validators.required],
    refundCancellation: [{ value: false, disabled: true }, Validators.required],
    cancellationWindow: [null, Validators.min(0)],
    cancellationPrice: [0, Validators.min(0)],
    saleCategoryBook: [null],
    saleCategoryCancel: [null],
    allowPendingBookings: [false],
    bookingTaxes: [[]],
    bookingIgnoreTaxes: [false],
    cancellationTaxes: [[]],
    cancellationIgnoreTaxes: [false],
  });

  resetForm() {
    this.accountId = this.bookingOption?.community?.financeAccountId;

    this.resetValue = {
      cancellationPrice: (this.bookingOption?.cancellationPrice || 0) / 100 || null,
      cancellationWindow: this.bookingOption?.cancellationWindow || null,
      cancellation: this.bookingOption?.cancellation || false,
      refundCancellation: this.bookingOption?.refund || false,
      bookingWindow: this.bookingOption?.bookingWindow || 60,
      price: (this.bookingOption?.price || 0) / 100 || 0,
      usages: this.bookingOption?.usages || null,
      additionalSpots: this.bookingOption?.additionalSpots || null,
      additionalSpotCost: (this.bookingOption?.additionalSpotCost || 0) / 100 || null,
      maxBoost: this.bookingOption?.maxBoost || null,
      allowBoost: this.bookingOption?.maxBoost !== -1,
      saleCategoryBook: this.bookingOption?.saleCategoryBook || null,
      saleCategoryCancel: this.bookingOption?.saleCategoryCancel || null,
      allowPendingBookings: this.bookingOption?.allowPendingBookings || false,
      bookingTaxes: this.bookingOption?.bookingTaxes || [],
      bookingIgnoreTaxes: this.bookingOption?.bookingIgnoreTaxes || false,
      cancellationTaxes: this.bookingOption?.cancellationTaxes || [],
      cancellationIgnoreTaxes: this.bookingOption?.cancellationIgnoreTaxes || false,
    };

    this.form.setValue(this.resetValue);
    this.form.markAsPristine();

    if (this.bookingOption) this.form.enable();
    else this.form.disable();
  }

  save = async () => {
    if (!this.bookingOption) return;

    const options = {
      cancellationPrice: (this.form.value.cancellationPrice || 0) * 100,
      cancellationWindow: this.form.value.cancellationWindow || 0,
      refund: this.form.value.refundCancellation || false,
      cancellation: this.form.value.cancellation || false,
      bookingWindow: this.form.value.bookingWindow || 0,
      price: Math.round(this.form.value.price * 100),
      usages: this.form.value.usages || null,
      additionalSpots: this.form.value.additionalSpots || null,
      additionalSpotCost: Math.round(this.form.value.additionalSpotCost * 100),
      maxBoost: this.form.value.allowBoost ? this.form.value.maxBoost || 0 : -1,
      saleCategoryBookId: this.form.value.saleCategoryBook?.id ? this.form.value.saleCategoryBook.id : null,
      saleCategoryCancelId: this.form.value.saleCategoryCancel?.id ? this.form.value.saleCategoryCancel.id : null,
      allowPendingBookings: this.form.value.allowPendingBookings || false,
      bookingTaxes: this.form.value.bookingTaxes.map((tax: Tax) => tax.id),
      bookingIgnoreTaxes: this.form.value.bookingIgnoreTaxes,
      cancellationTaxes: this.form.value.cancellationTaxes.map((tax: Tax) => tax.id),
      cancellationIgnoreTaxes: this.form.value.cancellationIgnoreTaxes,
    };

    try {
      const option = await this.bookingOptionSvc.update(this.bookingOption.id, options);
      this.saved.emit(option);
    } catch (err) {
      console.error(err);
    }
  };

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
