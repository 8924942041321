import { BaseDto } from '@greco/util-dto';
import { CheckInWarning } from './get-high-priority-warnings.dto';

export class GetLowPriorityWarningsResponse extends BaseDto {
  expiredPaymentMethods?: CheckInWarning[];
  expiringPaymentMethods?: CheckInWarning[];
  futurePendingBookings?: CheckInWarning[];
  pastPendingBookings?: CheckInWarning[];
  bookingsToCheckIn?: CheckInWarning[];
}
