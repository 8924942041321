import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrayUtils } from '@greco-fit/util';
import { Booking, BookingOption, CalendarEvent } from '@greco/booking-events';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import moment from 'moment';

@Component({
  selector: 'greco-admin-events-list',
  templateUrl: './admin-events-list.component.html',
  styleUrls: ['./admin-events-list.component.scss'],
})
export class AdminEventsListComponent {
  @Input() contact!: Contact | null;
  @Input() user!: User | null;
  @Input() userWaitlist?: string[];
  @Input() userBookings: Booking[] = [];

  @Output() status = new EventEmitter<Booking[] | undefined>();

  eventsCount = 0;
  groups: { id: string; day: string; events: { event: CalendarEvent; bookingOption: BookingOption }[] }[] = [];

  @Input() set events(events: { event: CalendarEvent; bookingOption: BookingOption }[]) {
    this.eventsCount = events.length;

    const grouped = ArrayUtils.groupBy(events, event => moment(event.event.startDate).format('YYYY-MM-DD'));
    this.groups = Object.entries(grouped).reduce(
      (acc, [key, value]) => [
        ...acc,
        { id: key.replace(/-/g, ''), day: moment(key).format('dddd, MMMM Do'), events: value },
      ],
      [] as { id: string; day: string; events: { event: CalendarEvent; bookingOption: BookingOption }[] }[]
    );
  }

  getStatus(event: Booking[] | undefined) {
    this.status.emit(event);
  }
}
