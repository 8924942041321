import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LetModule } from '@greco/ngx-let-util';
import { AlertComponent } from './components';
import { AlertsService } from './services';

@NgModule({
  imports: [
    CommonModule,

    // Mat
    MatIconModule,
    MatButtonModule,

    // Greco
    LetModule,
  ],
  declarations: [AlertComponent],
  exports: [AlertComponent],
  providers: [AlertsService],
})
export class AlertsModule {}
