import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { Booking } from '@greco/booking-events';
import { AddBookingOptionDialog, BookingService } from '@greco/ngx-booking-events';
import { PerkService } from '@greco/ngx-sales-perks';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-pending-booking-card',
  templateUrl: './pending-booking-card.component.html',
  styleUrls: ['./pending-booking-card.component.scss'],
})
export class PendingBookingCardComponent {
  constructor(private bookingSvc: BookingService, private matDialog: MatDialog, private perkSvc: PerkService) {}

  readonly _pendingBooking$ = new BehaviorSubject<Booking | null>(null);
  @Input() set pendingBookings(pendingBooking: Booking | null) {
    this._pendingBooking$.next(pendingBooking);
  }
  get pendingBookings() {
    return this._pendingBooking$.value;
  }

  @Input() error = false;

  @Input() isUserSide = false;

  @Output() refreshBookings = new EventEmitter<string>();

  perkUsed = '';
  totalPerksOfType = 0;

  userPerks$ = this._pendingBooking$.pipe(
    switchMap(pendingBooking => this.perkSvc.getUserPerks(pendingBooking?.user.id))
  );

  canUserConfirmBooking$ = combineLatest([this._pendingBooking$, this.userPerks$]).pipe(
    map(([pendingBooking, userPerks]) => {
      const perkUsed = userPerks.find(userPerk => userPerk.perk.id === pendingBooking?.bookingOption.id);
      if (!perkUsed) return false;

      this.perkUsed = perkUsed.perk.title || '';
      this.totalPerksOfType = userPerks.filter(userPerk => userPerk.perk.id === perkUsed.perk.id)?.length || 0;

      return true;
    })
  );

  async confirm(booking: Booking) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Booking',
        subtitle: 'Are you sure you want to confirm this booking?',
        content: `You will be using 1 ${this.perkUsed} perk of ${this.totalPerksOfType} to book this event`,
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      await this.bookingSvc.confirmPending(booking.id);
      this.refreshBookings.emit('TriedConfirming');
    }
  }

  async confirmWithNewBookingOption(booking: Booking) {
    if (this.isUserSide) return;

    const dialog = this.matDialog.open(AddBookingOptionDialog, {
      data: { event: booking.event, user: booking.user, booking },
      width: '750px',
      maxWidth: '90%',
    });

    const response = await toPromise(dialog.afterClosed());
    if (response === 'yes') {
      this.refreshBookings.emit('TriedConfirming');
    }
  }
}
