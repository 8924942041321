import { SecurityResource } from '@greco/security';

export const CHECK_IN_SECURITY_RESOURCE = 'check_in';
export enum CheckInSecurityActions {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const CheckInSecurityResource: SecurityResource = {
  key: CHECK_IN_SECURITY_RESOURCE,
  module: 'community',

  title: 'Checked In User',
  description: 'Mange checked In user options',

  context: class {
    communityId: string;
  },

  actions: [
    { key: CheckInSecurityActions.CREATE, title: 'Create', description: 'Ability to create checked in user' },
    { key: CheckInSecurityActions.READ, title: 'Read', description: 'Ability to read checked in user details' },
    {
      key: CheckInSecurityActions.UPDATE,
      title: 'Update',
      description: "Ability to update a checked in user's information",
    },
    { key: CheckInSecurityActions.DELETE, title: 'Delete', description: 'Ability to remove a checked in user' },
  ],
};
