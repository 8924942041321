import { Component, Input } from '@angular/core';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { PerkService } from '@greco/ngx-sales-perks';
import { PropertyListener } from '@greco/property-listener-util';
import { ReplaySubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-user-perks-page',
  templateUrl: './user-perks.page.html',
  styleUrls: ['./user-perks.page.scss'],
})
export class UserPerksPage {
  constructor(private userSvc: UserService, private perkSvc: PerkService) {}

  @PropertyListener('user') _user$ = new ReplaySubject<User | null>(1);
  @Input() user?: User | null;

  readonly user$ = this._user$.pipe(switchMap(user => (user ? of(user) : this.userSvc.getSelf())));

  perks$ = this.user$.pipe(switchMap(async user => (user ? await this.perkSvc.getUserPerks(user.id) : null)));

  usedPerks$ = this.user$.pipe(switchMap(async user => (user ? await this.perkSvc.getUserUsedPerks(user.id) : null)));
}
