import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { VideoUploadDialog } from '@greco/ngx-community-videos';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  CollectionVideo,
  CollectionVideoSecurityResource,
  CollectionVideoSecurityResourceAction,
  UpdateCollectionVideoDto,
} from '@greco/video-library';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CollectionVideoService } from '../../services';

@Component({
  selector: 'greco-collection-video-page',
  templateUrl: './collection-video.page.html',
  styleUrls: ['./collection-video.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CollectionVideoPage implements OnInit {
  @PropertyListener('collectionVideo') collectionVideo$ = new BehaviorSubject<CollectionVideo | null>(null);
  @Input() collectionVideo!: CollectionVideo;
  constructor(
    private comSecSvc: CommunitySecurityService,
    private formBuilder: FormBuilder,
    private videoSvc: CollectionVideoService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar
  ) {}

  form = this.formBuilder.group({
    title: [''],
    description: [''],
  });

  changed = false;

  readonly canRead$ = this.collectionVideo$.pipe(
    switchMap(async video => {
      const community = video?.collection?.communityId;
      return community
        ? await this.comSecSvc.hasAccess(
            community,
            CollectionVideoSecurityResource.key,
            CollectionVideoSecurityResourceAction.READ
          )
        : null;
    })
  );

  readonly canUpdate$ = this.collectionVideo$.pipe(
    switchMap(async video => {
      const community = video?.collection?.communityId;
      return community
        ? await this.comSecSvc.hasAccess(
            community,
            CollectionVideoSecurityResource.key,
            CollectionVideoSecurityResourceAction.UPDATE
          )
        : null;
    })
  );
  readonly canRemove$ = this.collectionVideo$.pipe(
    switchMap(async video => {
      const community = video?.collection?.communityId;
      return community
        ? await this.comSecSvc.hasAccess(
            community,
            CollectionVideoSecurityResource.key,
            CollectionVideoSecurityResourceAction.REMOVE
          )
        : null;
    })
  );

  ngOnInit() {
    this.form.setValue({
      title: this.collectionVideo.titleOverride || '',
      description: this.collectionVideo.descriptionOverride || '',
    });
  }

  async save() {
    const title = this.form.get('title')?.value;
    const description = this.form.get('description')?.value;
    const response = await this.videoSvc.updateProgramVideo(this.collectionVideo.id, {
      titleOverride: title,
      descriptionOverride: description,
    });
    if (response) {
      this.collectionVideo = response;
      this.form.markAsPristine();
      this.changed = false;
      this.snacks.open('Collection Video details successfully updated', 'Ok!', {
        duration: 2500,
        panelClass: 'mat-primary',
      });
    } else {
      this.snacks.open('Something went wrong!', '', {
        duration: 2500,
        panelClass: 'mat-warn',
      });
    }
  }

  changeEvent() {
    const title = this.form.get('title')?.value;
    const description = this.form.get('description')?.value;
    if (title != this.collectionVideo.titleOverride || description != this.collectionVideo.descriptionOverride) {
      this.changed = true;
    } else {
      this.changed = false;
      this.form.markAsPristine();
    }
  }

  async update() {
    //open update dialog for video
    if (!this.collectionVideo) return;
    const dialog = this.matDialog.open(VideoUploadDialog, { data: {}, width: '100%', maxWidth: '400px' });
    const instance = dialog.componentInstance;
    instance.video = this.collectionVideo.communityVideo;
    instance.communityId = this.collectionVideo.communityVideo?.communityId;
    instance.availableOn = this.collectionVideo.availableOn || undefined;

    const response = await toPromise(dialog.afterClosed());
    if (response.dto) {
      const dto: UpdateCollectionVideoDto = {
        title: response.dto.title || null,
        description: response.dto.description || null,
        videoTagIds: response.dto.videoTagIds || null,
        availableOn: response.dto.availableOn,
      };

      const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '400px' });
      spinnerDialog.disableClose = true;
      try {
        const response = await this.videoSvc.updateProgramVideo(this.collectionVideo.id, dto);
        if (response) this.collectionVideo$.next(response);
      } catch (err) {
        console.error(err);
      }
      spinnerDialog.close();
    }
  }

  changeStatus() {
    //
  }
  remove() {
    //
  }
}
