import { Account } from '@greco/finance-accounts';
import { Payment } from '@greco/finance-payments';
import { Tax } from '@greco/finance-tax';
import { User } from '@greco/identity-users';
import { PurchaseItem } from './purchase-item';
import { UserBalanceTransaction } from './user-balance-txn';

export enum PurchaseStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',

  PAID = 'PAID',
  PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',

  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PROCESSING_REFUND = 'PROCESSING_REFUND',

  VOIDED = 'VOIDED',
  PROCESSING_VOID = 'PROCESSING_VOID',

  PAYMENT_DISPUTED = 'PAYMENT_DISPUTED',
  PAYMENT_DISPUTE_LOST = 'PAYMENT_DISPUTE_LOST',
}

export interface Purchase {
  id: string;
  type: string;

  created: Date;
  modified: Date;

  account: Account;
  status: PurchaseStatus;
  completedOn: Date | null;

  user: User;
  createdBy: User | null;

  soldById: string | null;
  soldBy: User | null;

  referredByEmail?: string | null;
  referredById?: string | null;
  referredBy?: User | null;

  purchasedById: string;
  purchasedBy?: User;

  taxes: Tax[];
  items: PurchaseItem[];

  subtotal: number;
  tax: number;
  total: number;

  balanceUsed: number;
  balanceRefundedAmount?: number;
  balanceTransaction: UserBalanceTransaction | null;

  failureDate: Date | null;
  failureReason: string | null;

  voidedDate: Date | null;
  voidedReason: string | null;

  voidedById: string | null;
  voidedBy?: User | null;

  payment: Payment | null;

  previousStatus?: PurchaseStatus;

  retryCount: number;
}
