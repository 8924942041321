import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PropertyListener } from '@greco/property-listener-util';
import { Reader } from '@stripe/terminal-js';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { TerminalService } from '../../services';

@Component({
  selector: 'greco-manage-terminals',
  templateUrl: './manage-terminals.dialog.html',
  styleUrls: ['./manage-terminals.dialog.scss'],
})
export class ManageTerminalsDialog implements OnDestroy {
  @PropertyListener('accountId') accountId$ = new BehaviorSubject<string | null>(null);
  accountId: string | null = null; //gets set through the generic payment method dialog system

  private refresh$ = new BehaviorSubject<null>(null);

  accountLocation$ = this.accountId$.pipe(
    switchMap(async accountId => (!accountId ? null : await this.terminalSvc.getAccountLocation(accountId)))
  );

  private terminals$ = combineLatest([this.accountId$, this.refresh$]).pipe(
    switchMap(([accountId]) => this.terminalSvc.getTerminalPaymentMethods(accountId ?? undefined)),
    shareReplay(1)
  );

  private readers$ = combineLatest([this.accountLocation$, this.refresh$]).pipe(
    switchMap(async ([accountLocation]) => {
      const readers = this.terminalSvc.discoverReaders();

      if (accountLocation) {
        return (await readers).filter(
          reader =>
            reader.location &&
            (typeof reader.location === 'string'
              ? reader.location === accountLocation.locationId
              : reader.location.id === accountLocation.locationId)
        );
      }

      return readers;
    }),
    tap(readers => console.log({ readers })),
    catchError(() => of(<Reader[]>[])),
    shareReplay(1)
  );

  readonly terminalsWithStatus$ = this.terminals$.pipe(
    switchMap(terminals =>
      this.readers$.pipe(
        map(readers =>
          terminals.map(terminal => ({
            ...terminal,
            status: readers.find(reader => reader.id === terminal.externalId)?.status ?? 'offline',
          }))
        )
      )
    )
  );

  readonly readersToConnect$ = this.readers$.pipe(
    switchMap(readers =>
      this.terminals$.pipe(
        map(terminals => readers.filter(reader => !terminals.some(term => term.externalId === reader.id)))
      )
    )
  );

  constructor(private dialogRef: MatDialogRef<ManageTerminalsDialog>, private terminalSvc: TerminalService) {}

  ngOnDestroy() {
    this.refresh$.complete();
  }

  close() {
    this.dialogRef.close();
  }

  refresh() {
    this.refresh$.next(null);
  }

  async connectReader(reader: Reader) {
    try {
      await this.terminalSvc.addReaderAsPaymentMethod(reader.id);
      this.refresh();
    } catch (err) {
      console.error(err);
    }
  }

  async updateReaderLabel(id: string) {
    await this.terminalSvc.updateReaderLabel(id).then(() => this.refresh());
  }
}
