<div
  style="display: flex; flex-direction: column; gap: 16px"
  *grecoLet="canManageInventory$ | async as canManageInventory"
>
  <form [formGroup]="formGroup">
    <mat-form-field appearance="standard">
      <mat-label>Out of Stock Message</mat-label>
      <input matInput type="text" formControlName="outOfStockMessage" />
    </mat-form-field>

    <mat-checkbox formControlName="displayStockNumber" color="primary">Display inventory amount in shop</mat-checkbox>
  </form>

  <div style="display: flex; flex-direction: column; gap: 16px; position: relative">
    <ng-container *ngIf="addonId; else notConfigured">
      <ng-container *grecoLet="inventories$ | async as inventories">
        <greco-table *ngIf="inventories; else loading" [data]="inventories || [] | inventory">
          <p *grecoTableCol="'Variant'; let inventory">{{ inventory.variant | variantTitle }}</p>
          <!-- <p *grecoTableCol="'Lifetime Stock'; let inventory">{{ inventory.totalStock }}</p>
          <p *grecoTableCol="'Lifetime Sold'; let inventory">{{ inventory.quantitySold || 0 }}</p> -->
          <p *grecoTableCol="'Available'; let inventory">
            {{ inventory.availableInventoryCount || 0 }}
          </p>
          <ng-container *ngIf="canManageInventory">
            <ng-container *ngIf="restocking">
              <p *grecoTableCol="'New Total'; let inventory; stickyEnd: true">
                {{
                  (inventory.availableInventoryCount || 0) +
                    (formGroup.get('inventories')?.value.get(inventory.variantId)?.value || 0)
                }}
              </p>
            </ng-container>

            <span *grecoTableCol="''; let inventory; stickyEnd: true; fitContent: true" style="width: 48px">
              <ng-container *grecoLet="formGroup.get('inventories')?.value.get(inventory.variantId) as restockControl">
                <button
                  *ngIf="!restockControl"
                  mat-icon-button
                  type="button"
                  color="primary"
                  style="
                    border-radius: 100%;
                    background-color: var(--primary-color);
                    --icon-size: 28px;
                    height: var(--icon-size);
                    width: var(--icon-size);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                  (click)="addRestock(inventory.variantId)"
                >
                  <span style="color: white; font-size: 12px">+/-</span>
                </button>
                <div
                  *ngIf="restockControl"
                  style="display: flex; flex-direction: row; justify-content: space-between; gap: 4px"
                >
                  <input
                    matInput
                    style="width: 48px; text-align: center"
                    type="number"
                    [formControl]="restockControl"
                    [min]="(inventory.availableInventoryCount || 0) * -1"
                  />
                  <button mat-icon-button type="button" color="warn" (click)="removeRestock(inventory.variantId)">
                    <mat-icon
                      style="
                        color: var(--warn-color);
                        --icon-size: 28px;
                        height: var(--icon-size);
                        width: var(--icon-size);
                        font-size: var(--icon-size);
                      "
                      >cancel</mat-icon
                    >
                  </button>
                </div>
              </ng-container>
            </span>
          </ng-container>
        </greco-table>
        <ng-template #loading>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-template>
      </ng-container>

      <ng-container *grecoLet="productVariants$ | async as productVariants">
        <ng-container *ngIf="productVariants?.length">
          <button
            mat-button
            style="width: 100%; height: 100%; margin-top: 16px"
            type="button"
            [matMenuTriggerFor]="variantPicker"
          >
            <mat-icon
              style="--icon-size: 20px; height: var(--icon-size); width: var(--icon-size); font-size: var(--icon-size)"
            >
              add_circle_outline
            </mat-icon>
            <span>Add Variant</span>
          </button>
          <mat-menu #variantPicker>
            <button mat-menu-item *ngFor="let variant of productVariants" (click)="addVariant(variant)">
              <mat-icon>style</mat-icon>
              <span>{{ variant | variantTitle }}</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #notConfigured>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <mat-hint>Auto generating the variant inventories. This may take a few minutes.</mat-hint>
    </ng-template>
    <div style="width: 100%; background: white; position: sticky; bottom: 0; z-index: 20">
      <greco-alert
        *ngIf="formGroup.dirty"
        [config]="{
          type: $any('WARN'),
          title: 'Save your changes! Please click confirm to save any changes before closing the window.'
        }"
      ></greco-alert>
    </div>
  </div>
</div>
