import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { Contact } from '@greco/identity-contacts';
import { UserResource, UserResourceAction } from '@greco/identity-users';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import { SecurityService } from '@greco/ngx-security-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';

@Component({
  selector: 'greco-user-account-details-dialog',
  templateUrl: './user-account-details.dialog.html',
  styleUrls: ['./user-account-details.dialog.scss'],
})
export class UserAccountDetailsDialog implements OnInit {
  @Input() public userDetails!: { title: string; contact: Contact; readOnly: boolean };

  constructor(
    private formBuilder: FormBuilder,
    private contactSvc: ContactService,
    private snacks: MatSnackBar,
    private dialog: MatDialog,
    private userSvc: UserService,
    private clipboardSvc: ClipboardService,
    private securitySvc: SecurityService,
    public dialogRef: MatDialogRef<UserAccountDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { title: string; contact: Contact; readOnly: boolean }
  ) {
    this.userDetails = {
      ...this.dialogData,
    };
  }

  canManageAuth$ = this.securitySvc.hasAccess(UserResource.key, UserResourceAction.MANAGE_AUTH, {}, true);

  // loadingResetLink = false;
  exporting = false;

  resetValue = { email: '' };
  form = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  ngOnInit(): void {
    this.reset();
  }

  save = async () => {
    if (this.userDetails.readOnly) return;
    if (!this.userDetails.contact.user) return;

    try {
      const dialog = this.dialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Confirm Email Update',
          subtitle: 'Are you sure you want to update email?',
          content:
            "Note: Changing the user's email here may break 3rd party integrations. Please make sure to also update the user's email where needed.",
          buttons: [
            { label: 'No', role: 'no' },
            { label: 'Yes, Update Email', role: 'yes' },
          ],
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        this.userDetails.contact.user = await this.userSvc.update(this.userDetails.contact.user.id, {
          email: this.form.value.email,
        });
        this.reset();

        this.snacks.open('Updated email address!', 'Ok', { duration: 2000, panelClass: 'mat-primary' });
      }
    } catch (err: any) {
      if (err instanceof HttpErrorResponse) {
        console.error((err as HttpErrorResponse).error.message);
        this.snacks.open((err as HttpErrorResponse).error.message, 'Ok', { duration: 6000, panelClass: 'mat-warn' });
      } else {
        console.error(err);
        this.snacks.open('Unable to update email address!', 'Ok', { duration: 6000, panelClass: 'mat-warn' });
      }
    }
  };

  async refresh() {
    if (this.userDetails.contact?.id) {
      this.userDetails.contact = await this.contactSvc.getContact(this.userDetails.contact.id);
    }
  }

  async verifyEmail() {
    if (!this.userDetails.contact.user) return;

    this.userDetails.contact.user = await this.userSvc.verifyEmail(this.userDetails.contact.user.id);
    this.refresh();
    this.dialogRef.close();
    this.snacks.open('Email verified!', 'Ok', { duration: 2000, panelClass: 'mat-primary' });
  }

  // async generateResetLink() {
  //   if (!this.userDetails.contact.user) return;

  //   this.loadingResetLink = true;

  //   try {
  //     const { url } = await this.userSvc.getPasswordResetLink(this.userDetails.contact.user.id);
  //     this.clipboardSvc.copy(url, 'Reset link');
  //   } catch (err) {
  //     console.error(err);
  //   }
  //   this.dialogRef.close();
  //   this.loadingResetLink = false;
  // }

  private reset() {
    this.resetValue = { email: this.userDetails.contact.user?.email || '' };
    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }

  async sendResetEmail() {
    if (!this.userDetails.contact.user) return;
    await this.userSvc.sendResetPasswordEmail(this.userDetails.contact.user.email);
    this.dialogRef.close();
    this.snacks.open('Email sent!', 'Ok', { duration: 2000 });
  }
}
