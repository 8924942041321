import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { AddonType, ProductAddon } from '@greco/sales-products';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConfigureAddonDialog } from '../../dialogs';
import { AddonsService, ProductAgreementAddonsService } from '../../services';

@Component({
  selector: 'greco-product-addons-section',
  templateUrl: './product-addons.component.html',
  styleUrls: ['./product-addons.component.scss'],
})
export class ProductAddonsComponent implements OnDestroy {
  constructor(
    private addonSvc: AddonsService,
    private prodAgreementAddonSvc: ProductAgreementAddonsService,
    private dialogs: MatDialog
  ) {}

  processing = false;

  @Output() inventoryUpdated = new EventEmitter();

  @Input() communityId!: string;

  @Input() set productId(productId: string | null) {
    this.productId$.next(productId);
  }
  get productId() {
    return this.productId$.value;
  }

  private productId$ = new BehaviorSubject<string | null>(null);

  addons$ = combineLatest([this.productId$]).pipe(
    switchMap(async ([productId]) =>
      productId ? await this.addonSvc.paginate(productId, { page: 1, limit: 100 }) : null
    ),
    map(data => Object.values(AddonType).map(type => data?.items?.find(a => a.type === type) || { type }))
  );

  agreementTags$ = this.addons$.pipe(
    switchMap(async addons => {
      const agreementAddon: any = addons.find((x: { type: string }) => x.type === 'AGREEMENT');
      return await this.prodAgreementAddonSvc.getManyByProdAddonId(agreementAddon.id);
    })
  );

  ngOnDestroy() {
    this.productId$.complete();
  }

  async updateAddonStatus(addon: ProductAddon) {
    if (!this.productId) return;
    this.processing = true;
    try {
      await this.addonSvc.updateAddonStatus(this.productId, addon.id, !addon.enabled);
      this.refresh();
    } catch (err) {
      console.error(err);
    }
    this.processing = false;
  }

  async updateAddonConfiguration(addon: ProductAddon) {
    this.processing = true;
    try {
      const dialog = this.dialogs.open(ConfigureAddonDialog, {
        width: '750px',
        maxWidth: '90%',
        data: { addon, productId: this.productId, communityId: this.communityId },
      });

      const result = await toPromise(dialog.afterClosed());
      if (result || addon.type === AddonType.Inventory) this.refresh(addon.type === AddonType.Inventory);
    } catch (err) {
      console.error(err);
    }
    this.processing = false;
  }

  private refresh(emitEvent: boolean = false) {
    this.productId$.next(this.productId);
    if (emitEvent) {
      this.inventoryUpdated.emit();
    }
  }
}
