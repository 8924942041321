import { Tax } from '@greco/finance-tax';
import { Product } from '@greco/sales-products';
import { SaleCategory } from '@greco/sales-purchases';
import { ProductStatus } from './product';
import { ProductImage } from './product-image';
import { ProductVariantMinimumCommitment, ProductVariantRecurrence } from './product-variant-recurrence';
import { VariantOption } from './variant-option';
import { VariantPerk } from './variant-perk';
import { VariantTransfer } from './variant-transfer';

export enum ProductVariantVisibility {
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
  RESTRICTED = 'RESTRICTED',
}

export interface ProductVariant {
  id: string;

  status: ProductStatus;

  priority: number;

  title: string | null;

  product?: Product;
  productId: string;

  image: ProductImage;

  price: number;

  recurrence: ProductVariantRecurrence | null;

  minimumCommitment: ProductVariantMinimumCommitment | null;

  variantOpts: VariantOption[];

  variantPerks: VariantPerk[];

  transfers: VariantTransfer[];

  paymentTypes?: string[];

  autoUpgradeVariantId: string | null;
  autoUpgradeVariant?: ProductVariant;

  prorateStart?: Date | null;

  enrolmentFee?: number | null;
  enrolmentFeeName?: string | null;
  enrolmentFeeDescription?: string | null;

  enrolmentFeeSaleCategortyId?: string | null;
  enrolmentFeeSaleCategory?: SaleCategory;

  enrolmentFeeEnabled?: boolean | null;

  visibility: ProductVariantVisibility;

  taxes?: Tax[];
  ignoreTaxes: boolean;
}
