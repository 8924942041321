import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { CollectionVideoService } from '@greco/ngx-video-library';
import { CollectionVideo } from '@greco/video-library';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

interface TemplateData {
  collectionVideo: CollectionVideo;
  user: User;
}

@Component({
  selector: 'greco-preview-unlock-event-video-wrapper-page',
  template: `<greco-preview-unlock-collection-video-page
    #previewPage
    *ngIf="_test$ | async as data"
    [collectionVideo]="data.collectionVideo"
    [user]="data.user"
    (unlocked)="previewPage.refresh()"
  ></greco-preview-unlock-collection-video-page>`,
})
export class PreviewUnlockCollectionVideoWrapperPage implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private userSvc: UserService,
    private route: ActivatedRoute,
    private crumbs: BreadcrumbService,
    private collectionVideoSvc: CollectionVideoService
  ) {}

  private onDestroy$ = new Subject<void>();

  readonly _templateData$ = new BehaviorSubject<TemplateData | null>(null);
  readonly _test$ = this._templateData$.pipe(distinctUntilChanged());
  loadData() {
    const user$ = this.userSvc.authUser$.pipe(
      switchMap(authUser => (authUser ? this.userSvc.getUser(authUser.uid) : of(null))),
      distinctUntilChanged((previousValue, currentValue) => previousValue?.id === currentValue?.id)
    );

    const collectionVideo$ = this.route.params.pipe(
      switchMap(async ({ collectionVideoId }) =>
        collectionVideoId ? await this.collectionVideoSvc.getOneVideo(collectionVideoId) : null
      ),
      catchError(() => of(null)),
      tap(video => !video && this.router.navigate(['..'])),
      shareReplay(1),
      tap(collectionVideo =>
        setTimeout(() =>
          this.crumbs.set([
            {
              label: 'Programs',
              routerLink: `/workouts/collections/`,
              icon: 'video_library',
            },
            {
              label: collectionVideo?.collection?.title || '...',
              routerLink: `/workouts/collections/${collectionVideo?.collectionId}`,
              icon: collectionVideo?.collection?.icon,
            },
            {
              label: collectionVideo?.titleOverride || collectionVideo?.communityVideo?.video?.title || '...',
              routerLink: `/workouts/collections/${collectionVideo?.collectionId}/${collectionVideo?.id}`,
            },
          ])
        )
      )
    );

    combineLatest([user$, collectionVideo$])
      .pipe(
        takeUntil(this.onDestroy$),
        filter(([user, collectionVideo]) => !!user && !!collectionVideo)
      )
      .subscribe(([user, collectionVideo]: any[]) => this._templateData$.next({ user, collectionVideo }));
  }

  ngOnInit() {
    setTimeout(() =>
      this.crumbs.set([
        { label: 'Programs', routerLink: '/workouts/collections', icon: 'video_library' },
        { label: '...', routerLink: '/workouts/collections' },
      ])
    );
    this.loadData();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
