import { BaseDto } from '@greco/util-dto';

export class GetHighPriorityWarningsResponse extends BaseDto {
  noMembership?: CheckInWarning;
  membershipOnHold?: CheckInWarning[];
  membershipFrozen?: CheckInWarning[];
  failedPurchases?: CheckInWarning[];
  paymentDisputes?: CheckInWarning[];
  unsignedAgreements?: CheckInWarning[];
}
export interface CheckInWarning {
  title: string;
  description: string;
  data?: any;
}
