<form class="user-events-filters-form" [formGroup]="_form">
  <mat-form-field *ngIf="!(mobileBreakpoint$ | async)?.matches" appearance="outline">
    <mat-label>Trainers</mat-label>
    <mat-select multiple formControlName="trainers" [compareWith]="compareGroups">
      <mat-option *ngFor="let group of trainers$ | async" [value]="group">
        <div style="display: flex; align-items: center">
          <greco-multi-avatar
            style="margin-left: 8px; margin-right: 16px"
            [images]="[group.photoUrl]"
            [size]="24"
          ></greco-multi-avatar>
          <span>{{ group.name }}</span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="!(mobileBreakpoint$ | async)?.matches" appearance="outline">
    <mat-label>Tags</mat-label>
    <mat-select multiple formControlName="tags" [compareWith]="compareTags">
      <mat-option *ngFor="let tag of tags$ | async" [value]="tag">
        {{ tag.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
