import { Component, Injectable, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { EventVideoUnlockOption } from '@greco/event-videos';
import { Community } from '@greco/identity-communities';
import { BuildSearchFilter, BuildTextFilter } from '@greco/ngx-filters';
import { PropertyListener } from '@greco/property-listener-util';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, first, map, switchMap, tap } from 'rxjs/operators';
import { CreateEventVideoUnlockOptionDialog } from '../../dialogs';
import { EventVideoUnlockService } from '../../services';

@Injectable({ providedIn: 'root' })
export class CommunityOptionsSearchTitleFilter extends BuildTextFilter('CommunityOptionsSearchTitleFilter', {
  label: 'Replay Option',
  shortLabel: 'Replay Option',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW, CondOperator.EQUALS_LOW],
  properties: ['title'],
}) {}

@Injectable({ providedIn: 'root' })
export class CommunityOptionsSearchTagFilter extends BuildTextFilter('CommunityOptionsSearchTagFilter', {
  label: 'Video Tags',
  shortLabel: 'Video Tags',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW, CondOperator.EQUALS_LOW],
  properties: ['videoTags.label'],
}) {}

@Injectable({ providedIn: 'root' })
export class CommunityOptionsSearchFilter extends BuildSearchFilter('CommunityOptionsSearchFilter', {
  properties: ['title', 'videoTags.label'],
  propertyLabels: ['Replay Option', 'Video Tags'],
}) {}

@Component({
  selector: 'greco-admin-event-video-unlock-options-page',
  templateUrl: './admin-unlock-options.page.html',
  styleUrls: ['./admin-unlock-options.page.scss'],
})
export class AdminEventVideoUnlockOptionsPage implements OnDestroy {
  constructor(
    private unlockOptSvc: EventVideoUnlockService,
    private matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() community!: Community;
  @PropertyListener('community') private community$ = new ReplaySubject<Community | null>(1);

  _loading = true;
  _paginationMeta?: IPaginationMeta;
  _filterOptions = [CommunityOptionsSearchTitleFilter, CommunityOptionsSearchTagFilter, CommunityOptionsSearchFilter];
  _filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  _refresh$ = new BehaviorSubject(null);
  _pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });

  _unlockOptions$ = combineLatest([this._filters$, this.community$, this._pagination$, this._refresh$]).pipe(
    tap(() => (this._loading = true)),
    debounceTime(500),
    switchMap(async ([query, community, page]) => {
      return query && community ? await this.unlockOptSvc.paginateUnlockOptions(query, community.id, page) : null;
    }),
    tap(data => (this._paginationMeta = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this._loading = false))
  );

  createNewUnlockOption() {
    if (!this.community) return;

    this.matDialog
      .open(CreateEventVideoUnlockOptionDialog, { data: { communityId: this.community.id } })
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.refresh());
  }

  refresh() {
    this._pagination$.next(this._pagination$.value);
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  ngOnDestroy() {
    this.community$.complete();
    this._pagination$.complete();
  }

  async navigateTo(option: EventVideoUnlockOption) {
    await this.router.navigate([option.id], { relativeTo: this.route });
  }
}
