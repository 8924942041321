<div *ngIf="subscription" class="subscription-container">
  <ng-container *ngIf="subscription.status !== 'VOIDED'">
    <div style="display: flex; align-items: stretch; flex-wrap: wrap; gap: 8px">
      <!-- LTV -->
      <greco-box *ngIf="!hideLifetimeValue" style="flex: 1; min-width: 200px">
        <h3 style="margin: 0">Lifetime Value</h3>
        <p style="margin: 0" *grecoLet="ltv$ | async as ltv">{{ltv ? (ltv / 100 | currency) : '-'}}</p>
        <p style="margin: 0; font-size: 75%">Amount with taxes</p>
      </greco-box>

      <!-- Current Period -->
      <greco-box style="flex: 1; min-width: 200px">
        <h3 style="margin: 0">Current Period</h3>
        <p style="margin: 0">{{subscription.currentPeriod | ceil}}</p>
        <p style="margin: 0; font-size: 75%">
          Periods of {{subscription.recurrence.frequency}}
          {{_PERIODS[subscription.recurrence.period]}}{{subscription.recurrence.frequency > 1 ? 's' : ''}}
        </p>
      </greco-box>

      <!-- Next Invoice -->
      <greco-box style="flex: 1; min-width: 200px">
        <h3 style="margin: 0">Next Invoice</h3>
        <p style="margin: 0">{{subscription.status === 'ACTIVE' ? (subscription.periodEnd | date) : '-'}}</p>
        <p style="margin: 0; font-size: 75%">
          Expecting {{subscription.status === 'ACTIVE' ? (subscription.total / 100 | currency) : 'no further'}} payment
        </p>
      </greco-box>
    </div>

    <greco-subscription-schedule-section
      [canCancel]="canCancel$ | async"
      [canMakeChange]="(canUpdate$ | async) || false"
      [canFreeze]="(canFreeze$ | async) || false"
      [subscription]="subscription"
      [communityId]="communityId"
      (scheduledChanged)="refresh()"
    ></greco-subscription-schedule-section>

    <greco-subscription-purchase-history
      *ngIf="canViewPurchaseDetails$ | async"
      [subscription]="subscription"
    ></greco-subscription-purchase-history>
  </ng-container>

  <greco-subscription-info-section
    [canCancel]="canCancel$ | async"
    [subscription]="subscription"
    [communityId]="communityId"
    [disabled]="true"
    (subscriptionChanged)="refresh()"
  ></greco-subscription-info-section>
</div>
