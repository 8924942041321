<greco-simple-dialog *grecoLet="terminals$ | async as terminals" [data]="dialogData">
  <form *ngIf="station$ | async as station" [formGroup]="form">
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" />
    </mat-form-field>

    <mat-form-field appearance="standard" style="width: 100%; margin-bottom: -16px">
      <mat-label>Sale Categories</mat-label>
      <mat-select multiple formControlName="saleCategories" [compareWith]="compareSaleCategories">
        <mat-option *ngFor="let saleCategory of saleCategories$ | async" [value]="saleCategory">
          {{ saleCategory.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox color="primary" formControlName="includeUncategorized" style="margin-bottom: 8px">
      Include Uncategorized Products
    </mat-checkbox>

    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Default User</mat-label>
      <greco-user-picker formControlName="defaultUser" [communityId]="communityId"></greco-user-picker>
    </mat-form-field>

    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Terminals</mat-label>
      <mat-select multiple formControlName="terminals">
        <mat-option *ngFor="let terminal of terminals" [value]="terminal"> {{ terminal.label }} </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</greco-simple-dialog>
