<greco-alert
  *grecoLet="canUserConfirmBooking$ | async as canUserConfirmBooking"
  [config]="{
    title:
      'Pending Booking For Past Event' +
      (booking.user.id !== booking.bookedById ? '(For ' + booking.user.displayName + ')' : ''),
    type: $any('WARN'),
    description: booking.event.title + ' (' + (booking.event.startDate | date : 'MMM d, y, h:mm a') + ')'
  }"
>
  <div slot="buttons" style="display: flex; flex-direction: row; gap: 8px">
    <button *ngIf="!isUserSide" mat-flat-button color="warn" [matMenuTriggerFor]="cancelMenu" [disabled]="loading">
      Cancel
    </button>

    <mat-menu #cancelMenu>
      <button mat-menu-item (click)="cancel(false)" [disabled]="loading">Cancel</button>
      <button mat-menu-item (click)="cancel(true)" [disabled]="loading">Cancel (Free Of Charge)</button>
    </mat-menu>

    <div
      [matTooltip]="(isUserSide ? 'You do ' : 'User does ') + 'not have the required perks to confirm this booking'"
      [matTooltipDisabled]="canUserConfirmBooking"
    >
      <button mat-flat-button color="primary" (click)="confirm()" [disabled]="loading || !canUserConfirmBooking">
        Confirm Booking
      </button>
    </div>
  </div>
</greco-alert>
