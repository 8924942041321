import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventVideoUnlockService } from '@greco/ngx-event-videos';
import { UserService } from '@greco/ngx-identity-auth';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-user-video-store-wrapper',
  templateUrl: './user-video-store.page.html',
  styleUrls: ['./user-video-store.page.scss'],
})
export class UserVideoStoreWrapperPage implements OnInit, OnDestroy {
  constructor(
    private userSvc: UserService,
    private crumbs: BreadcrumbService,
    private videoUnlockSvc: EventVideoUnlockService
  ) {}

  private refresh$ = new BehaviorSubject<null>(null);

  user$ = this.refresh$.pipe(switchMap(() => this.userSvc.user$));

  ngOnInit() {
    setTimeout(() => this.crumbs.set([]));
    this.videoUnlockSvc.getUserUnlockOptions();
  }

  ngOnDestroy() {
    this.refresh$.complete();
  }

  refresh() {
    this.refresh$.next(null);
  }
}
