<greco-simple-dialog
  [data]="{
    title: 'Sign User Agreement',
    subtitle: 'Please review the agreement and sign',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      { label: 'Create', role: 'create', resultFn: getResult }
    ]
  }"
>
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
    <mat-form-field style="width: 100%">
      <mat-label>Agreement</mat-label>
      <greco-community-agreement-picker
        formControlName="agreement"
        [communityId]="data.communityId || ''"
        (agreement)="fillText($event)"
      ></greco-community-agreement-picker>
    </mat-form-field>

    <ng-container *ngIf="selectedAgreement">
      <div style="padding: 0 8px">
        <span [innerHtml]="selectedAgreement.text | safe : 'html'"></span>
      </div>
      <div style="display: flex; justify-content: center">
        <div style="width: 98%; border-bottom: 1px solid darkgray"></div>
      </div>

      <mat-checkbox
        *ngIf="selectedAgreement.agreementType !== 'DIGITAL_SIGNATURE'"
        [checked]="checked"
        [required]="true"
        style="padding: 8px"
        color="primary"
        formControlName="checkbox"
        >I have read and agree to the terms of the agreement</mat-checkbox
      >

      <greco-signature
        *ngIf="selectedAgreement.agreementType === 'DIGITAL_SIGNATURE'"
        [user]="data.user"
        [forPurchase]="true"
        (signatureChange)="setSignature($event)"
      >
      </greco-signature>
    </ng-container>
  </form>
</greco-simple-dialog>
