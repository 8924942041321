import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SecurityService } from '@greco/ngx-security-util';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { StationsService } from '../../services';
@Component({
  selector: 'greco-create-station-dialog',
  templateUrl: './create-station.dialog.html',
  styleUrls: ['./create-station.dialog.scss'],
})
export class CreateStationDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      communityId: string;
      title: string;
      mode: string;
      stationId: string;
    },
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private stationsSvc: StationsService,
    private securitySvc: SecurityService,
    private security: CommunitySecurityService,
    private snacks: MatSnackBar,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}
  @ViewChild(SimpleDialog) private container!: SimpleDialog;

  private _onDestroy$ = new Subject<void>();

  duplicateEmail = false;

  formGroup = this.formBuilder.group({
    title: [this.data.title ? this.data.title : '', Validators.required],
  });
  dialogData: DialogData = {
    title: this.data.mode == 'create' ? 'Create New Station' : 'Update Station',
    subtitle:
      this.data.mode == 'create'
        ? 'Provide the details for the new station'
        : 'Update the details for the station you selected',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: '' },
      {
        label: this.data.mode == 'create' ? 'Create' : 'Update',
        role: this.data.mode == 'create' ? 'create' : 'update',
        resultFn: async () => {
          try {
            const station =
              this.data.mode == 'create'
                ? await this.stationsSvc.createStation({
                    title: this.formGroup.value.title,
                    communityId: this.data.communityId,
                  })
                : await this.stationsSvc.updateStation(this.data.stationId, {
                    title: this.formGroup.value.title,
                    communityId: this.data.communityId,
                  });

            this.snacks.open(`${this.data.mode == 'create' ? 'New station created' : 'Station updated'}`, 'Ok', {
              duration: 2500,
              panelClass: 'mat-primary',
            });
            return station;
          } catch (err) {
            console.error(err);
            return null;
          }
        },
      },
    ],
  };

  async ngOnInit() {
    setTimeout(() => {
      this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
        this.dialogData = {
          ...this.dialogData,
          buttons: this.dialogData.buttons?.map(btn => {
            if (btn.role === 'create') btn.disabled = !this.formGroup.valid;
            return btn;
          }),
        };
        this.changeDetectorRefs.detectChanges();
      });
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
