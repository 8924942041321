import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { combineLatest, of, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-user-collections-filters',
  templateUrl: './user-collections-filters.component.html',
  styleUrls: ['./user-collections-filters.component.scss'],
})
export class UserCollectionsFiltersComponent implements OnInit, OnDestroy {
  @Output() searchEvent = new EventEmitter<string>();
  value = '';

  @Input() user?: User | null;

  constructor(private formBuilder: FormBuilder) {
    this._listenToForm();
  }
  form = this.formBuilder.group({
    search: '',
    communities: [[], [Validators.required, Validators.minLength(1)]],
  });

  private _onDestroy$ = new Subject<void>();

  @Output() changed = new EventEmitter<string[]>();
  ngOnInit() {
    this.searchEvent.emit('');
  }
  search() {
    const value = (<HTMLInputElement>document.getElementById('searchInput')).value;
    this.searchEvent.emit(value);
  }
  checkForClear() {
    const value = (<HTMLInputElement>document.getElementById('searchInput')).value;
    if (!value) this.searchEvent.emit();
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _listenToForm() {
    this.form.valueChanges
      .pipe(
        takeUntil(this._onDestroy$),
        startWith(this.form.value),
        switchMap(value => combineLatest([of(value)])),
        map(([{ communities }]) => {
          return communities.map((com: Community) => com.id);
        })
      )
      .subscribe(query => this.changed.emit(query));
  }
}
