import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { SignInComponent } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'greco-user-event-videos-page',
  templateUrl: './user-event-videos.page.html',
  styleUrls: ['./user-event-videos.page.scss'],
})
export class UserEventVideosPage {
  @Input() user?: User | null;
  @PropertyListener('user') private user$ = new BehaviorSubject<User | null>(null);

  constructor(private matDialog: MatDialog) {}
  async signIn() {
    const _dialog = this.matDialog.open(SignInComponent, { data: {}, width: '100%', maxWidth: '400px' });
    await toPromise(_dialog.afterClosed());
  }
}
