<greco-stats-grid [referenceId]="product.id"></greco-stats-grid>

<!-- Product Details -->
<greco-collapsible-section #section [header]="{ title: 'Product Details', icon: 'info' }">
  <ng-container header>
    <mat-chip [matMenuTriggerFor]="menu" style="cursor: pointer">
      {{ product.status | titlecase }}
      <mat-icon style="font-size: 22px; width: 22px; height: 22px; margin-bottom: -2px; margin-right: -4px">
        arrow_drop_down
      </mat-icon>
    </mat-chip>

    <mat-menu #menu>
      <!-- Archive (only if active) -->
      <button *ngIf="product.status === 'ACTIVE'" mat-menu-item color="primary" (click)="archiveProduct()">
        <mat-icon>archive</mat-icon>
        Archive
      </button>

      <!-- Activate -->
      <button *ngIf="product.status !== 'ACTIVE'" mat-menu-item color="primary" (click)="activateProduct()">
        <mat-icon>check_circle</mat-icon>
        {{ product.status === 'DRAFT' ? 'Activate' : 'Re-activate' }}
      </button>
    </mat-menu>
  </ng-container>

  <greco-form-save-buttons
    *ngIf="section.expanded"
    header
    [form]="formGroup"
    [saveAction]="save"
    [resetValue]="resetValue"
    (formReset)="resetForm() "
  ></greco-form-save-buttons>

  <form [formGroup]="formGroup">
    <!-- Title -->
    <mat-form-field style="width: 100%">
      <mat-label>Title</mat-label>
      <input matInput type="text" required formControlName="title" />
    </mat-form-field>

    <!--Sale Category-->
    <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
      <mat-label>Sales Category</mat-label>
      <greco-sale-category-input
        [accountId]="product.community.financeAccountId"
        formControlName="saleCategory"
        [required]="true"
      ></greco-sale-category-input>
    </mat-form-field>

    <!-- Images -->
    <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
      <mat-label>Images</mat-label>
      <greco-image-upload
        [multiple]="true"
        [urls]="initialUrls"
        (click)="formGroup.markAsDirty()"
        formControlName="images"
      ></greco-image-upload>
    </mat-form-field>

    <!-- Description -->
    <mat-form-field style="width: 100%">
      <mat-label>Description</mat-label>
      <textarea matInput type="text" formControlName="description" rows="6"></textarea>
    </mat-form-field>

    <!-- Taxes -->
    <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
      <mat-label>Taxes</mat-label>
      <greco-account-taxes-picker
        formControlName="taxes"
        [accountId]="product.community.financeAccountId"
        [disabled]="ignoreTaxes.checked"
      >
        <mat-checkbox #ignoreTaxes formControlName="ignoreTaxes" [checked]="product.ignoreTaxes" color="primary"
          >No Taxes</mat-checkbox
        >
      </greco-account-taxes-picker>
    </mat-form-field>
  </form>
</greco-collapsible-section>

<!-- Variants -->
<greco-collapsible-section id="variants-section" [header]="{ title: 'Variants (Prices)', icon: 'style' }">
  <!-- Create Variant Button -->
  <button
    header
    mat-flat-button
    color="primary"
    style="height: 32px; line-height: 16px"
    (click)="$event.stopImmediatePropagation(); createVariant()"
  >
    <mat-icon style="margin-left: -4px">add</mat-icon>
    <span>Variant</span>
  </button>

  <!-- Variant Options Chips -->
  <form style="padding-right: 18px; padding-left: 18px">
    <mat-form-field style="width: 100%">
      <mat-label>Variant Differentiators (Picker Options)</mat-label>
      <mat-chip-list #chipList aria-label="Product Option selection">
        <mat-chip
          *ngFor="let optionLabel of (optionLabels$ | async)"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="removeOption(optionLabel)"
          (click)="updateOption(optionLabel)"
        >
          {{ optionLabel }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>

        <input
          id="input"
          placeholder="New option..."
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addOption($event)"
        />
      </mat-chip-list>

      <div
        *ngIf="options.toCreate.length || options.toUpdate.length || options.toRemove.length"
        matSuffix
        style="display: flex; gap: 16px; align-items: center"
      >
        <button mat-icon-button type="button" (click)="resetProductOptions()">
          <mat-icon>restart_alt</mat-icon>
        </button>

        <button
          type="button"
          color="primary"
          mat-stroked-button
          style="height: 32px; line-height: 16px"
          (click)="saveOptions()"
        >
          <span>Save</span>
        </button>
      </div>
    </mat-form-field>
  </form>

  <greco-filter-bar
    (changed)="variantFilters$.next($event[1])"
    [filterOptions]="variantFilterOptions"
    [hideSeparator]="true"
  >
    <button
      *ngIf="listIncludesInactiveVariants && variantsToActivate.length"
      mat-flat-button
      color="primary"
      style="margin-right: 8px"
      (click)="activateBulk()"
    >
      <mat-icon>check_circle</mat-icon>
      <span>Activate {{ variantsToActivate.length }} Variant{{ variantsToActivate.length > 1 ? 's' : '' }}</span>
    </button>

    <mat-checkbox color="primary" (change)="includeHiddenVariants$.next($event.checked)">Restricted</mat-checkbox>
    <mat-checkbox color="primary" (change)="includeArchivedVariants$.next($event.checked)">Archived</mat-checkbox>
  </greco-filter-bar>

  <!-- Greco Table -->
  <greco-table
    [data]="(variants$ | async) || []"
    [highlight]="true"
    [loading]="processing"
    (rowClick)="openVariant($event)"
    *grecoLet="canManageInventory$| async as canManageInventory"
  >
    <div
      *grecoTableCol="''; let variant; fitContent: true"
      [matTooltip]="variant.status === active ? 'This variant is already active, no bulk actions to perform' : ''"
    >
      <mat-checkbox
        color="primary"
        [checked]="variantInList(variant)"
        [disabled]="variant.status === active"
        (click)="$event.stopImmediatePropagation()"
        (change)="toggleActivateVariant(variant)"
      >
      </mat-checkbox>
    </div>

    <ng-container *grecoTableCol="''; fitContent: true; let variant">
      <div *ngIf="variant.image" style="display: flex; flex-direction: row; justify-content: center">
        <img [src]="variant.image.imageURL" [matTooltip]="variant.id" />
      </div>
      <mat-icon *ngIf="!variant.image" [matTooltip]="variant.id">view_list</mat-icon>
    </ng-container>

    <p *grecoTableCol="'Priority'; fitContent: true; let variant;">{{variant.priority }}</p>

    <p *grecoTableCol="'Title'; let variant;">
      {{variant.title || (variant.variantOpts | pluck: 'value')?.join(', ') }}
    </p>

    <ng-container *ngFor="let option of productOptions">
      <p *grecoTableCol="option.label; let variant">{{variant.variantOpts | options: option.id}}</p>
    </ng-container>

    <div *grecoTableCol="'Perks'; let variant" class="perks-container">
      <ng-container *ngFor="let variantPerk of variant.variantPerks">
        <greco-perk-badge
          *ngIf="variantPerk.status === 'ACTIVE'"
          [small]="true"
          [text]="variantPerk.perk.badge.text"
          [color]="variantPerk.perk.badge.color"
          [shape]="variantPerk.perk.badge.shape"
          [icon]="variantPerk.perk.badge.icon"
          [quantity]="variantPerk.quantity === 1 ? null : variantPerk.quantity"
          [matTooltip]="variantPerk.id + ' - ' + variantPerk.perk.id"
        >
        </greco-perk-badge>
      </ng-container>
    </div>

    <ng-container *grecoTableCol="'Fees & Referrals'; let variant">
      <p [innerHTML]="variant | grecoVariantTransfers: product.community.financeAccount.id"></p>
    </ng-container>

    <p *grecoTableCol="'Price'; let variant; alignRight: true; fitContent: true">{{variant.price / 100 | currency}}</p>

    <mat-chip-list *grecoTableCol="'Status'; let variant; fitContent: true">
      <mat-chip> {{ variant.status | titlecase }} </mat-chip>
    </mat-chip-list>

    <mat-icon
      *grecoTableCol="'Visibility'; let variant; alignCenter: true; fitContent: true"
      [matTooltip]="'Toggle visibility (' + (variant.visibility | titlecase)+ ')'"
      (click)="toggleVariantHidden(variant); $event.stopImmediatePropagation()"
    >
      {{ variant.visibility === 'VISIBLE' ? 'visibility' : variant.visibility === 'HIDDEN' ? 'visibility_off' : 'lock'
      }}
    </mat-icon>

    <ng-container *ngIf="inventoryAddon$ | async as inventoryAddon">
      <ng-container *ngIf="inventoryAddon?.enabled">
        <ng-container *grecoLet="variantInventories$ | async as variantInventories">
          <p *grecoTableCol="'Inventory'; let variant; alignCenter: true; fitContent: true">
            {{(variant.id | variantInventory: variantInventories || [])?.availableInventoryCount || 0}}
          </p>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let variant">
      <button
        mat-icon-button
        [disabled]="variant.status !== 'ACTIVE' || product.status !== 'ACTIVE'"
        (click)="$event.stopImmediatePropagation(); copyCheckoutUrlToClipboard(variant)"
      >
        <mat-icon>link</mat-icon>
      </button>

      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu>
        <button *ngIf="variant.status === 'DRAFT'" mat-menu-item color="primary" (click)="activateVariant(variant)">
          <mat-icon>check_circle</mat-icon>
          <span>Activate</span>
        </button>

        <button *ngIf="variant.status === 'ACTIVE'" mat-menu-item color="primary" (click)="archiveVariant(variant)">
          <mat-icon>archive</mat-icon>
          <span>Archive</span>
        </button>

        <button mat-menu-item color="primary" (click)="openVariant(variant)">
          <mat-icon>open_in_new</mat-icon>
          <span>View Details</span>
        </button>

        <button mat-menu-item color="primary" (click)="duplicateVariant(variant)">
          <mat-icon>content_copy</mat-icon>
          <span>Duplicate</span>
        </button>

        <ng-container *ngIf="canManageInventory">
          <ng-container *ngIf="inventoryAddon$ | async as inventoryAddon">
            <button
              *ngIf="inventoryAddon?.enabled"
              mat-menu-item
              color="primary"
              (click)="openInventory(inventoryAddon.id, variant.id)"
            >
              <mat-icon>shopping_cart</mat-icon>
              <span>Manage Inventory</span>
            </button>
          </ng-container>
        </ng-container>
      </mat-menu>
    </ng-container>
  </greco-table>

  <mat-paginator
    *ngIf="variantsMetadata?.totalItems"
    showFirstLastButtons
    [length]="variantsMetadata!.totalItems || 0"
    [pageSize]="variantsMetadata!.itemsPerPage || 5"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="variantPagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  ></mat-paginator>
</greco-collapsible-section>

<!-- Addons -->
<greco-product-addons-section
  [productId]="product.id"
  [communityId]="product.community.id"
  (inventoryUpdated)="refresh.emit()"
></greco-product-addons-section>
