import { Component, Input } from '@angular/core';
import { Community } from '@greco/identity-communities';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-video-unlocks-page',
  templateUrl: './video-unlocks.page.html',
  styleUrls: ['./video-unlocks.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class VideoUnlocksPage {
  @Input() community?: Community;
}
