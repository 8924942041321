import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { VideoTagService } from '@greco/ngx-videos';
import { VideoTag } from '@greco/videos';
import { CurrencyMaskConfig } from 'ngx-currency';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { CommunityVideoUnlockOptionsService } from '../../services';

@Component({
  templateUrl: './create-unlock-option.dialog.html',
  styleUrls: ['./create-unlock-option.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateCommunityVideoUnlockOptionDialog implements OnInit, OnDestroy {
  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };
  tagOptions: VideoTag[] = [];
  tagSearch = '';

  constructor(
    private matDialogRef: MatDialogRef<CreateCommunityVideoUnlockOptionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private videoPerkSvc: CommunityVideoUnlockOptionsService,
    private formBuilder: FormBuilder,
    private tagSvc: VideoTagService,
    private snacks: MatSnackBar
  ) {}

  private _onDestroy$ = new Subject<void>();

  selectable = true;
  removable = true;

  _creating = false;

  formGroup = this.formBuilder.group({
    availableToAnyone: [false, Validators.required],
    price: [0],
    title: ['', Validators.required],
    color: ['#ff0000', Validators.required],
    description: [null],
    icon: [],
    tags: [[], Validators.required],
    rentalPeriod: [null, Validators.required],
    unlockToKeep: false,
  });

  dialogData: DialogData = {
    title: 'Create New Event Video Unlock Option',
    subtitle: 'Provide the details for the new unlock option',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Create',
        role: 'create',
        resultFn: async () => {
          try {
            const data = this.formGroup.value;

            this._creating = true;

            try {
              const perk = await this.videoPerkSvc.createCommunityVideoUnlockOption({
                availableToAnyone: data.availableToAnyone || false,
                description: data.description || null,
                communityId: this.data.communityId,
                price: data.price * 100 || 0,
                videoTagIds: (data.tags as VideoTag[]).map(tag => tag.id) || [],
                title: data.title,
                hexColor: data.color,
                icon: data.icon,
                rentalPeriod: data.rentalPeriod,
              });
              this.snacks.open('Unlock option created!', 'Ok', { duration: 3500, panelClass: 'mat-primary' });
              this.matDialogRef.close(perk);
            } catch (err) {
              console.error(err);
              this.snacks.open('Something went wrong. Please try again!', 'Ok', {
                duration: 5000,
                panelClass: 'mat-warn',
              });
            }

            this._creating = false;
          } catch (err) {
            console.error(err);
            return null;
          }
        },
      },
    ],
  };

  async ngOnInit() {
    this.tagOptions = await this.tagSvc.getTags();
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = this.formGroup.invalid;
          return btn;
        }),
      };
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareCategoryObjects(object1: VideoTag, object2: VideoTag) {
    return object1 && object2 && object1.id == object2.id;
  }
  filterTags(tag: VideoTag): boolean {
    if (this.tagSearch)
      return (
        tag.label.toLowerCase().includes(this.tagSearch.toLowerCase()) ||
        (this.formGroup.get('tags')?.value as VideoTag[]).some(t => t.id === tag.id)
      );
    else return true;
  }

  onKey() {
    this.tagSearch = (document.getElementById('tagSearch') as HTMLInputElement)?.value || '';
  }

  rentalChange(event: any) {
    const checked = event.checked;
    if (checked) {
      this.formGroup.setValue({ ...this.formGroup.value, rentalPeriod: 0 }, { emitEvent: true });
    }
  }
}
