import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { GrantPerkDto, UserPerkEntity } from '@greco/nestjs-sales-perks';
import { UserService } from '@greco/ngx-identity-auth';
import { FindPerksDto, Perk, UserPerk, UserPerkStats } from '@greco/sales-perks';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { Pagination } from 'nestjs-typeorm-paginate';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class PerkService {
  constructor(private http: HttpClient, private userService: UserService) {}

  getUserPerks$() {
    return this.userService
      .getUserId()
      .pipe(switchMap(userId => this.http.get<UserPerk[]>('/api/perks/userPerks/' + userId)));
  }

  // @Get()
  async getUserPerks(userId?: string, data?: FindPerksDto): Promise<UserPerk[]> {
    const _userId = userId || (await toPromise(this.userService.getUserId()));
    return await toPromise(
      this.http.get<UserPerk[]>('/api/perks/userPerks/' + _userId, {
        params: {
          includeFrozen: data?.includeFrozen?.toString() ?? 'false',
          includeDeleted: data?.includeDeleted?.toString() ?? 'false',
        },
      })
    );
  }

  // @Get()
  async getUserUsedPerks(userId?: string, data?: FindPerksDto): Promise<UserPerk[]> {
    const _userId = userId || (await toPromise(this.userService.getUserId()));
    return await toPromise(
      this.http.get<UserPerk[]>('/api/perks/user-used-perks/' + _userId, {
        params: {
          includeFrozen: data?.includeFrozen?.toString() ?? 'false',
          includeDeleted: data?.includeDeleted?.toString() ?? 'false',
        },
      })
    );
  }

  async getUserPerksStats(communityId: string): Promise<UserPerkStats> {
    return await toPromise(this.http.get<UserPerkStats>('/api/perks/stats', { params: { communityId } }));
  }

  // @Get()
  async paginate(
    query: RequestQueryBuilder,
    communityId?: string,
    pagination?: PaginatedQueryParams
  ): Promise<Pagination<Perk>> {
    return await toPromise(
      this.http.get<Pagination<Perk>>('/api/perks', {
        params: {
          ...query.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }
  // @Get()
  async paginateUserPerks(
    query: RequestQueryBuilder,
    communityId?: string,
    pagination?: PaginatedQueryParams
  ): Promise<Pagination<UserPerk>> {
    return await toPromise(
      this.http.get<Pagination<UserPerk>>('/api/perks/userPerks', {
        params: {
          ...query.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Post()
  async toggleAvailability(perkId: string) {
    return await toPromise(this.http.post<Perk>('/api/perks/' + perkId + '/community-availability', {}));
  }

  // @Post()
  async updatePerkBadge(perkId: string, { text, icon, color }: { text?: string; icon?: string; color?: string }) {
    return await toPromise(this.http.post<Perk>('/api/perks/' + perkId + '/update-badge', { text, icon, color }));
  }

  // @Post('grant')
  async grantPerk(dto: GrantPerkDto) {
    return await toPromise(this.http.post<UserPerkEntity[]>('/api/perks/grant', dto));
  }

  // @Post('remove')
  async removePerks(perkId: string, userId: string, userPerks: string[]) {
    return await toPromise(this.http.post<UserPerkEntity[]>('/api/perks/remove', { userId, perkId, userPerks }));
  }
  // @Post('available')
  async availablePerks(userId: string, perkTypes?: string[], perkIds?: string[]) {
    return await toPromise(this.http.post<any[]>('/api/perks/available', { userId, perkTypes, perkIds }));
  }
  // @Post('recurring')
  async recurringPerks(userId: string, perkTypes?: string[], perkIds?: string[]) {
    return await toPromise(this.http.post<any[]>('/api/perks/recurring', { userId, perkTypes, perkIds }));
  }
  //@Post('numberOfPerks')
  async numberOfPerks(userId: string, perkTypes?: string[], perkIds?: string[]) {
    return await toPromise(this.http.post<any[]>('/api/perks/numberOfPerks/', { userId, perkTypes, perkIds }));
  }

  //@Put('force-unfreeze')
  async forceUnfreeze(userId: string, perkId: string) {
    await toPromise(this.http.put('/api/perks/force-unfreeze', { userId, perkId }));
  }
}
