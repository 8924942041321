import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import {
  Subscription,
  SubscriptionActionStatus,
  SubscriptionActionType,
  SubscriptionCancellationAction,
  SubscriptionFreezePeriod,
  SubscriptionResource,
  SubscriptionResourceAction,
  SubscriptionStatus,
  SubscriptionUpdateAction,
} from '@greco/sales-subscriptions';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  CancelSubscriptionDialog,
  ConfirmDialog,
  FreezeSubscriptionDialog,
  UpdateSubscriptionDialog,
} from '../../dialogs';
import { SubscriptionActionsService, SubscriptionFreezeService, SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-subscription-schedule-section',
  templateUrl: './schedule-section.component.html',
  styleUrls: ['./schedule-section.component.scss'],
})
export class SubscriptionScheduleSectionComponent implements OnDestroy {
  constructor(
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private comSecSvc: CommunitySecurityService,
    private actionSvc: SubscriptionActionsService,
    private subscriptionSvc: SubscriptionsService,
    private freezeSvc: SubscriptionFreezeService
  ) {}

  @Output() scheduledChanged = new EventEmitter<void>();

  @PropertyListener('subscription') private subscription$ = new ReplaySubject<Subscription | null>(1);
  @Input() subscription?: Subscription | null;

  @PropertyListener('communityId') private communityId$ = new ReplaySubject<string | null>(1);
  @Input() communityId?: string | null;

  @Input() canCancel?: boolean | null;

  @Input() canMakeChange?: boolean | null = false;

  @Input() canFreeze?: boolean | null = false;

  @Input() disabled = false;

  private refresh$ = new BehaviorSubject<null>(null);

  canViewDetails$ = this.communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.comSecSvc.hasAccess(communityId, PurchaseResource.key, PurchaseResourceAction.READ)
        : false
    ),
    shareReplay(1)
  );

  canUpdateSubscription$ = this.communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.comSecSvc.hasAccess(communityId, SubscriptionResource.key, SubscriptionResourceAction.UPDATE)
        : false
    ),
    shareReplay(1)
  );

  canTriggerSubscription$ = this.communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.comSecSvc.hasAccess(communityId, SubscriptionResource.key, SubscriptionResourceAction.TRIGGER)
        : false
    ),
    shareReplay(1)
  );

  isSuperAdmin$ = this.userSvc.user$.pipe(switchMap(async user => user?.isSuperAdmin || false));

  _loading = true;
  _paginationMeta?: IPaginationMeta;
  readonly pagination$ = new BehaviorSubject<PaginatedQueryParams>({ page: 1, limit: 5 });

  readonly actions$ = combineLatest([this.subscription$, this.pagination$, this.refresh$]).pipe(
    tap(() => (this._loading = true)),

    switchMap(async ([subscription, pagination]) =>
      subscription
        ? await this.actionSvc.paginateActions(
            subscription.id,
            RequestQueryBuilder.create({
              search: {
                'SubscriptionActionEntity.status': { $ne: SubscriptionActionStatus.CANCELLED },
              },
            }),
            pagination
          )
        : null
    ),
    tap(data => (this._paginationMeta = data?.meta)),
    map(data => data?.items || []),
    map(actions =>
      actions.map(action => {
        const updatedAction = { ...action };
        if (action.type == SubscriptionActionType.UPDATE) {
          return {
            ...updatedAction,
            itemsDisplayTitle: (action as SubscriptionUpdateAction).items
              ?.map(item => item.displayName + ' - ' + item.description)
              .join(', '),
            prorate: (action as SubscriptionUpdateAction).proration,
          };
        }
        if (action.type == SubscriptionActionType.CANCELLATION) {
          return { ...updatedAction, prorate: (action as SubscriptionCancellationAction).proration };
        }
        return updatedAction;
      })
    ),
    tap(() => (this._loading = false))
  );

  canManuallyForceProcess$ = combineLatest([this.actions$, this.subscription$]).pipe(
    switchMap(async ([actions, subscription]) => {
      const user = await this.userSvc.getSelf();

      let actionLocked = false;
      actions.forEach(action => {
        if (action.lockedForProcessing) {
          actionLocked = true;
        }
      });

      if (user?.isSuperAdmin === false) {
        return false;
      }

      if (subscription?.lockedForProcessing || actionLocked) return true;
      else return false;
    })
  );

  canManuallyTryProcess$ = combineLatest([
    this.actions$,
    this.subscription$,
    this.canManuallyForceProcess$,
    this.canTriggerSubscription$,
  ]).pipe(
    switchMap(async ([actions, subscription, canManuallyForceProcess, canTriggerSubscription]) => {
      if (
        !subscription ||
        canManuallyForceProcess || //no need for this option to appear when force process already does
        !canTriggerSubscription || //this is the permission required to trigger process
        ![SubscriptionStatus.ACTIVE, SubscriptionStatus.FROZEN].includes(subscription.status) //only active/frozen subscriptions
      ) {
        return false;
      }

      if (subscription.lockedForProcessing || actions.some(action => action.lockedForProcessing)) {
        //hide this option if stuck in processing
        return false;
      }

      //if a scheduled action is in the past (meaning its cloud task didn't trigger for some reason), we want this option to appear
      return actions.some(
        action =>
          action.status === SubscriptionActionStatus.SCHEDULED && action.timestamp.getTime() < new Date().getTime()
      );
    })
  );

  currentFreezes$ = combineLatest([this.subscription$, this.refresh$]).pipe(
    switchMap(async ([subscription]) => {
      if (!subscription || subscription.status !== SubscriptionStatus.FROZEN) return [];

      const now = new Date().getTime();
      return (await this.freezeSvc.getAllFreezes(subscription.id)).filter(
        freeze => freeze.startDate.getTime() < now && (freeze.endDate === null || freeze.endDate.getTime() > now)
      );
    })
  );

  ngOnDestroy() {
    this.refresh$.complete();
    this.pagination$.complete();
  }

  async cancelSubscription() {
    if (!this.subscription) return;

    const dialog = this.matDialog.open(CancelSubscriptionDialog, { data: this.subscription });
    await toPromise(dialog.afterClosed());

    this.scheduledChanged.emit();
    this.refresh();
  }

  async updateSubscription() {
    if (!this.subscription) return;

    const dialog = this.matDialog.open(UpdateSubscriptionDialog, {
      data: { mode: 'staff', subscription: this.subscription },
    });
    dialog.componentInstance.communityId = this.communityId || undefined;
    await toPromise(dialog.afterClosed());

    this.scheduledChanged.emit();
    this.refresh();
  }

  async freezeSubscription() {
    if (!this.subscription) return;

    const dialog = this.matDialog.open(FreezeSubscriptionDialog, {
      data: { mode: 'staff', subscription: this.subscription, freeze: undefined },
    });
    dialog.componentInstance.communityId = this.communityId || undefined;
    await toPromise(dialog.afterClosed());

    this.scheduledChanged.emit();
    this.refresh();
  }

  async unfreezeSubscription(_currentFreezes: SubscriptionFreezePeriod[]) {
    if (!this.subscription) return;
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Unfreeze Subscription',
        subtitle:
          'Are you sure you wish to unfreeze this subscription? The subscription schedule will be unfrozen, which may result in the member receiving prorated refunds for their unused time, and additional charges as the subscription may renew.',
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      await this.freezeSvc.unfreeze(this.subscription.id);

      this.scheduledChanged.emit();
      this.refresh();
    }
  }

  async forceProcessSubscription() {
    const dialog = this.matDialog.open(ConfirmDialog, {
      data: { title: 'Confirm processing', message: 'The processing is permanent and cannot be reverted.' },
    });
    const response = await toPromise(dialog.afterClosed());
    if (response === 'confirm') {
      if (this.subscription?.id) await this.subscriptionSvc.manualProcessing(this.subscription?.id);
      this.snacks
        .open('Processing started!', 'Ok', { duration: 3000 })
        .afterDismissed()
        .subscribe(() => window.location.reload());
    }
  }

  async tryProcessSubscription() {
    const dialog = this.matDialog.open(ConfirmDialog, {
      data: { title: 'Confirm processing', message: 'The processing is permanent and cannot be reverted.' },
    });
    const response = await toPromise(dialog.afterClosed());
    if (response === 'confirm') {
      if (this.subscription?.id) await this.subscriptionSvc.manualProcessing(this.subscription?.id, false);
      this.snacks
        .open('Processing started!', 'Ok', { duration: 3000 })
        .afterDismissed()
        .subscribe(() => window.location.reload());
    }
  }

  refresh() {
    this.refresh$.next(null);
  }

  async fixPerks() {
    if (!this.subscription) return;
    await this.subscriptionSvc.fixSubscriptionPerks(this.subscription.id);
  }
}
