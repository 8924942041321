import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Contact } from '@greco/identity-contacts';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder, SConditionAND, SFields } from '@nestjsx/crud-request';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ContactService } from '../../services';

@Component({
  selector: 'greco-contact-picker',
  templateUrl: './contact-picker.component.html',
  styleUrls: ['./contact-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ContactPickerComponent }],
})
export class ContactPickerComponent implements MatFormFieldControl<Contact>, ControlValueAccessor, OnDestroy {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _el: ElementRef,
    private contactSvc: ContactService
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  private static ID = 0;

  private _onChange?: (value: Contact | null) => void;
  private _onTouched?: () => void;

  readonly stateChanges = new Subject<void>();

  readonly controlType = 'greco-contact-picker';
  @HostBinding() readonly id = `${this.controlType}-${ContactPickerComponent.ID++}`;

  @ViewChild('nativeInput') nativeInput?: ElementRef<HTMLInputElement>;
  @ViewChild(MatInput) input?: MatInput;
  @ViewChild(MatAutocompleteTrigger) autoTrigger?: MatAutocompleteTrigger;

  @PropertyListener('withUser') private _withUser$ = new ReplaySubject<boolean>(1);
  @Input() withUser = false;

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string>(1);
  @Input() communityId!: string;

  @PropertyListener('memberNumber') private _memberNumber$ = new ReplaySubject<string>(1);
  @Input() memberNumber = '';

  @Input() extraConditions?: (SFields | SConditionAND)[];

  @Input() clearOnPaste?: boolean;

  @Output() contact = new EventEmitter<Contact | null>();

  readonly searchQuery$ = new BehaviorSubject<string>('');
  readonly contacts$: Observable<Contact[]> = combineLatest([
    this.searchQuery$,
    this._withUser$,
    this._communityId$,
    this._memberNumber$,
  ]).pipe(
    switchMap(async ([search, withUser, communityId]) => {
      if (!communityId) return [];
      const page = await this.contactSvc.paginateContacts(
        new RequestQueryBuilder()
          .search({
            $and: [
              ...(withUser ? [{ 'user.id': { $notnull: true } }] : []),
              {
                $or: [
                  { displayName: { $contL: search } },
                  { email: { $contL: search } },
                  ...[{ memberNumber: { $contL: search } }],
                ],
              },
              ...(this.extraConditions || []),
            ],
          })
          .sortBy({ field: 'created', order: 'DESC' }),
        communityId,
        { limit: search ? 15 : 5 }
      );
      // If a results is exactly the member number or email, select it
      if (
        search &&
        page.items.some(
          c =>
            c.memberNumber === search || (c.email.toLocaleLowerCase() === search.toLocaleLowerCase() && c.user?.email)
        )
      ) {
        this.value =
          page.items.find(
            c =>
              c.memberNumber === search || (c.email.toLocaleLowerCase() === search.toLocaleLowerCase() && c.user?.email)
          ) || null;
        this.autoTrigger?.closePanel();
      }
      return page.items;
    })
  );

  private _value: Contact | null = null;
  @Input() set value(value: Contact | null) {
    this._value = value;
    this.contact.emit(value);
    this._onChange?.(value);
    this.stateChanges.next();
    if (this.input) this.input.value = '';
  }
  get value() {
    return this._value;
  }

  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;

  get empty() {
    return !this._value && !this.searchQuery$.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState() {
    return false;
  }

  get focused() {
    return this.input?.focused || false;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';
  setDescribedByIds(ids: string[]) {
    const el = this._el.nativeElement.querySelector('.greco-contact-picker-container');
    if (el) el.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this.input?.onContainerClick();
    this.nativeInput?.nativeElement.focus();
  }

  writeValue(value: Contact | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: Contact | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  displayWith(value: Contact | null): string {
    return value?.displayName || value?.email || value?.memberNumber || '';
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value || null;
  }

  onPaste() {
    setTimeout(() => {
      if (this.input && this.clearOnPaste) this.input.value = '';
    }, 100);
  }

  @PropertyListener('required')
  @PropertyListener('disabled')
  @PropertyListener('placeholder')
  private _stateChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.searchQuery$.complete();
  }
}
