<greco-simple-dialog [data]="dialogData" [class.hidden]="_creating">
  <form [formGroup]="formGroup">
    <section>
      <h3 style="margin-bottom: 8px" *ngIf="updateUnlock">Update Expiry Date for {{updateUnlock.user?.displayName}}</h3>
      <h3 style="margin-bottom: 8px" *ngIf="!updateUnlock">Select a user and an expiry date for the replay grant</h3>
      <!-- User -->
      <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
        <mat-label>User Email</mat-label>
        <input
          matInput
          required
          type="email"
          formControlName="userEmail"
          [disabled]="updateUnlock ? true : false"
          [readonly]="updateUnlock ? true : false"
        />
      </mat-form-field>

      <!-- Expiry Date -->
      <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
        <mat-label matTooltip="An empty expiry date means the replay will never expire for the user"
          >Expiry Date</mat-label
        >

        <mat-datepicker #expiryDate></mat-datepicker>
        <input [matDatepicker]="expiryDate" matInput autocomplete="false" formControlName="expiryDate" />
        <mat-datepicker-toggle [for]="expiryDate" matSuffix></mat-datepicker-toggle>
      </mat-form-field>
    </section>
  </form>
</greco-simple-dialog>
<div [class.hidden]="!_creating">
  <div style="width: 100%; display: flex; justify-content: center">
    <mat-spinner></mat-spinner>
  </div>
</div>
