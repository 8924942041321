import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { PaymentMethod } from '@greco/finance-payments';
import { PropertyListener } from '@greco/property-listener-util';
import { Subscription } from '@greco/sales-subscriptions';
import { BehaviorSubject } from 'rxjs';
import { UserPaymentMethodService } from '../../services';

@Component({
  selector: 'greco-payment-method-subscriptions',
  templateUrl: './payment-method-subscriptions.component.html',
  styleUrls: ['./payment-method-subscriptions.component.scss'],
})
export class PaymentMethodSubscriptionsDialog implements OnInit {
  constructor(
    private paymentMethodSvc: UserPaymentMethodService,
    private dialogRef: MatDialogRef<PaymentMethodSubscriptionsDialog>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: { paymentMethod: PaymentMethod; userId: string; updateDefault?: boolean }
  ) {}

  @PropertyListener('subscriptions') subscriptions$ = new BehaviorSubject<Subscription[]>([]);
  subscriptions: Subscription[] = [];

  subscriptionsToUpdate: Subscription[] = [];

  dialogData: DialogData = {
    title: 'Subscriptions Using ' + this.data.paymentMethod.label,
    subtitle: this.data.updateDefault
      ? 'Select which subscriptions to update to the new default payment method'
      : 'All subscriptions using this payment method, including linked accounts',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  updateSubscriptions(subscription: Subscription) {
    if (this.subscriptionsToUpdate.map(sub => sub.id).includes(subscription.id)) {
      this.subscriptionsToUpdate = this.subscriptionsToUpdate.filter(sub => sub.id !== subscription.id);
    } else {
      this.subscriptionsToUpdate.push(subscription);
    }
  }

  async updateAll() {
    this.dialogRef.close({ subscriptions: this.subscriptions });
  }

  close() {
    this.dialogRef.close({ subscriptions: this.subscriptionsToUpdate });
  }

  async ngOnInit() {
    if (this.data.updateDefault) {
      const paymentMethods = await this.paymentMethodSvc.getAll(this.data.userId);
      const subscriptionsToShow: Subscription[] = [];

      await Promise.all(
        paymentMethods?.map(async paymentMethod => {
          const subscriptions = await this.paymentMethodSvc.getPaymentMethodSubscriptions(paymentMethod.id);
          if (subscriptions.length) subscriptionsToShow.push(...subscriptions);
        })
      );

      this.subscriptions = subscriptionsToShow;
    } else {
      this.subscriptions = await this.paymentMethodSvc.getPaymentMethodSubscriptions(this.data.paymentMethod.id);
    }
  }
}
