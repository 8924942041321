import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import {
  CreateEventVideoAndInitiateUploadDto,
  EventVideo,
  QueryEventVideosByEventDate,
  UpdateEventVideoDto,
} from '@greco/event-videos';
import { VideosService } from '@greco/ngx-videos';
import { Video, VideoSourceRequestDto } from '@greco/videos';

@Injectable({ providedIn: 'root' })
export class EventVideosService {
  constructor(private videosSvc: VideosService, private http: HttpClient) {}

  // @Get('event-date')
  getByEventdate(dto: QueryEventVideosByEventDate, userId?: string) {
    return toPromise(
      this.http.get<EventVideo[]>('/api/event-videos/event-date', {
        params: {
          videoTagIds: (dto.videoTagIds || []).join(','),
          startDate: dto.startDate.toISOString(),
          endDate: dto.endDate.toISOString(),
          ...(userId && { userId }),
        },
      })
    );
  }

  // @Get('recent')
  getRecentByDate(dto: QueryEventVideosByEventDate, userId?: string) {
    return toPromise(
      this.http.get<EventVideo[]>('/api/event-videos/recent', {
        params: {
          videoTagIds: (dto.videoTagIds || []).join(','),
          startDate: dto.startDate.toISOString(),
          endDate: dto.endDate.toISOString(),
          ...(userId && { userId }),
        },
      })
    );
  }

  // @Get(':eventVideoId')
  getOneEventVideo(eventVideoId: string) {
    return toPromise(this.http.get<EventVideo>(`/api/event-videos/${eventVideoId}`));
  }

  // @Get('event/:eventId')
  getEventVideosByEvent(eventId: string) {
    return toPromise(this.http.get<any>(`/api/event-videos/event/${eventId}`));
  }

  // @Post('upload')
  async upload(file: File, dto: Omit<CreateEventVideoAndInitiateUploadDto, 'fileSize'>) {
    type Response = { eventVideo: EventVideo; video: Video; uploadUrl: string };
    const response = await toPromise(
      this.http.post<Response>('/api/event-videos/upload', { ...dto, fileSize: file.size })
    );

    await this.videosSvc.clientSideUpload(file, dto.source, response.video, response.uploadUrl);
    return response.eventVideo;
  }

  // @Post('link')
  linkCommunityVideoToEvent(eventId: string, communityVideoId: string) {
    return toPromise(this.http.post<any>(`/api/event-videos/link`, { eventId, communityVideoId }));
  }
  // @Post('link')
  linkVideoToEvent(eventId: string, dto: VideoSourceRequestDto) {
    return toPromise(this.http.post<any>(`/api/event-videos/link/video`, { eventId, dto }));
  }

  // @Patch('update/:eventVideoId')
  update(eventVideoId: string, dto: UpdateEventVideoDto) {
    return toPromise(this.http.patch<any>(`/api/event-videos/update/${eventVideoId}`, dto));
  }

  // @Delete(':eventVideoId')
  remove(eventVideoId: string) {
    return toPromise(this.http.delete<EventVideo | null>(`/api/event-videos/${eventVideoId}`));
  }
}
