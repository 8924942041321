<div
  *grecoLet="booking$ | async as booking"
  (click)="!booking && bookNow()"
  style="display: flex; gap: 12px; align-items: center; justify-content: space-between; flex-wrap: wrap"
>
  <div style="display: flex; gap: 12px; align-items: center">
    <greco-multi-avatar
      style="flex-shrink: 0"
      [style.width]="(isMobile$ | async)?.matches ? '28px' : '36px'"
      [style.height]="(isMobile$ | async)?.matches ? '28px' : '36px'"
      [images]="(photoUrls$ | async) || ['assets/lf3/icon_square_pad.png']"
      [size]="44"
    ></greco-multi-avatar>
    <div *grecoLet="trainers$ | async as trainers" style="display: flex; flex-direction: column">
      <h3 style="margin-bottom: 0">
        {{ event.title }} <small *ngIf="trainers?.length">with</small>
        {{ trainers && trainers?.length ? (trainers | pluck : 'name').join(', ') : '' }} •
      </h3>
      <mat-chip-list>
        <mat-chip>
          <mat-icon style="font-size: 16px; width: 16px; height: 16px">event</mat-icon>
          {{ event.startDate | date : 'shortTime' }} - {{ event.duration }}mins
        </mat-chip>
        <mat-chip>
          <mat-icon style="font-size: 16px; width: 16px; height: 16px">place</mat-icon>{{ event.community.name }}
        </mat-chip>
        <mat-chip *ngIf="!(isMobile$ | async)?.matches">
          <mat-icon style="font-size: 16px; width: 16px; height: 16px">{{
            event.calendar?.icon || 'calendar_month'
          }}</mat-icon>
          {{ event.calendar?.title }}
        </mat-chip>
        <mat-chip *ngIf="!(isMobile$ | async)?.matches">
          Limit:
          {{ event.maxCapacity }}
        </mat-chip>
        <mat-chip *ngIf="!(isMobile$ | async)?.matches">
          Bookings:
          {{ $any(event).bookings }}
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div
    style="display: flex; gap: 12px"
    (mouseenter)="hoveringPerk = true"
    (mouseleave)="hoveringPerk = false"
    [style.width]="(isMobile$ | async)?.matches ? '100%' : 'auto'"
  >
    <div
      class="perk-badge-container"
      style="pointer-events: none"
      *ngIf="booking?.bookingOption || bookingOption as bookingOpt"
      [style.border-color]="bookingOpt.badge.color | safe : 'style'"
    >
      <div class="perk-tooltip" [style.opacity]="hoveringPerk ? '1' : '0'">
        <h3 class="title">Book with: {{ bookingOpt.title }}</h3>
        <div class="subtitle" *ngIf="requiredBoosts$ | async as requiredBoosts">
          <ng-container *ngIf="bookingOpt.price"> + {{ bookingOpt.price / 100 | currency }} </ng-container>
        </div>
        <div class="subtitle" *ngIf="requiredBoosts$ | async as requiredBoosts">
          <ng-container *ngIf="bookingOpt.maxBoost && requiredBoosts > bookingOpt.maxBoost; else withBoosts">
            Available {{ availableIn$ | async }}
          </ng-container>
          <ng-template #withBoosts>
            + {{ requiredBoosts }} booking window booster{{ requiredBoosts > 1 ? 's' : '' }}
          </ng-template>
        </div>
        <div class="subtitle"></div>
      </div>

      <div style="display: flex; gap: 12px; align-items: center"></div>
    </div>

    <a
      *ngIf="booking; else noBooking"
      mat-stroked-button
      color="primary"
      [routerLink]="['/']"
      [style.width]="(isMobile$ | async)?.matches ? '100%' : 'auto'"
      >Already Booked <mat-icon>launch</mat-icon></a
    >
    <ng-template #noBooking>
      <button
        mat-raised-button
        color="primary"
        [disabled]="(canBook$ | async) !== true"
        style="display: flex; flex-direction: column"
        [style.width]="(isMobile$ | async)?.matches ? '100%' : 'auto'"
      >
        <div class="btn-text-container">
          <p>
            <span>{{
              event.maxCapacity > $any(event).bookings
                ? 'Add Attendee'
                : (inWaitlist$ | async)
                ? 'Already in Waitlist'
                : 'Join Waitlist'
            }}</span>
          </p>
          <p>
            <small
              *ngIf="
                !(isMobile$ | async)?.matches &&
                event.community?.id !== 'com_greco' &&
                event.maxCapacity - $any(event).bookings
              "
              >Only <strong>{{ event.maxCapacity - $any(event).bookings }}</strong> spot{{
                event.maxCapacity - $any(event).bookings === 1 ? '' : 's'
              }}
              left!
            </small>
          </p>
        </div>
      </button>
    </ng-template>
  </div>
</div>
