<div *ngIf="subcollection && (canRead$ | async)" [id]="this.subcollection.id">
  <greco-collapsible-section
    id="playlist_section"
    [header]="{ title: subcollection.title, icon: 'list' }"
    [expanded]="expanded"
    (changed)="$event ? (loadVideos = true) : (loadVideos = false)"
  >
    <button
      header
      *ngIf="changed && (canManage$ | async)"
      mat-flat-button
      color="primary"
      (click)="$event.stopImmediatePropagation(); save()"
    >
      Save
    </button>
    <!-- Status -->
    <ng-container header>
      <ng-container
        *grecoLet="
          subcollection.availableOn &&
          (subcollection.availableOn | grecoDateCompare : 'lt') &&
          (!subcollection.availableUntil || (subcollection.availableUntil | grecoDateCompare : 'gt')) as active
        "
      >
        <ng-container *grecoLet="!subcollection.availableOn && !subcollection.availableUntil as draft">
          <mat-chip-list>
            <mat-chip [ngClass]="active ? '' : draft ? 'draft' : 'inactive'">{{
              active ? 'Active' : draft ? 'Draft' : 'Archived'
            }}</mat-chip></mat-chip-list
          ></ng-container
        ></ng-container
      >
    </ng-container>
    <ng-container header>
      <button
        *ngIf="canManage$ | async"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [matTooltip]="'Options for ' + subcollection.id"
        (click)="$event.stopImmediatePropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <!-- Activate/Archive -->
        <ng-container *ngIf="canManage$ | async">
          <ng-container *grecoLet=""></ng-container>
          <button
            mat-menu-item
            (click)="changeStatus()"
            *grecoLet="
              subcollection.availableOn &&
              (subcollection.availableOn | grecoDateCompare : 'lt') &&
              (!subcollection.availableUntil || (subcollection.availableUntil | grecoDateCompare : 'gt')) as active
            "
          >
            <mat-icon>{{ !active ? 'unarchive' : 'archive' }}</mat-icon>
            <span>{{ !active ? 'Activate' : 'Archive' }}</span>
          </button></ng-container
        >

        <!-- Remove 
        <button mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>-->
        <!-- Up -->
        <button
          mat-menu-item
          *ngIf="subcollection.sortIndex > 0 && (canManage$ | async)"
          (click)="updateSortIndex(true)"
        >
          <mat-icon>move_up</mat-icon>
          <span>Move Up</span>
        </button>

        <!-- Down -->
        <button
          mat-menu-item
          *ngIf="subcollection.sortIndex < length - 1 && (canManage$ | async)"
          (click)="updateSortIndex(false)"
        >
          <mat-icon>move_down</mat-icon>
          <span>Move Down</span>
        </button>
      </mat-menu>
    </ng-container>

    <div style="padding-left: 16px; padding-right: 16px">
      <form [formGroup]="form">
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Title</mat-label>
          <input
            matInput
            value="{{ subcollection.title }}"
            formControlName="title"
            (keyup)="changed = true"
            [readonly]="(canManage$ | async) === false"
          />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Icon</mat-label>
          <greco-icon-picker formControlName="icon" [required]="false"></greco-icon-picker>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            formControlName="description"
            (keyup)="changed = true"
            [readonly]="(canManage$ | async) === false"
          ></textarea>
        </mat-form-field>
        <!--Publish Date-->
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Available On</mat-label>
          <mat-datepicker-toggle
            [for]="publishDate"
            matSuffix
            [disabled]="(canManage$ | async) === false"
          ></mat-datepicker-toggle>
          <mat-datepicker #publishDate (closed)="changed = true"></mat-datepicker>
          <input
            [matDatepicker]="publishDate"
            matInput
            autocomplete="false"
            formControlName="availableOn"
            (keyup)="changed = true"
            [readonly]="(canManage$ | async) === false"
          />
        </mat-form-field>
        <!--Archive Date-->
        <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Available Until</mat-label>
          <mat-datepicker-toggle
            [for]="archiveDate"
            matSuffix
            [disabled]="(canManage$ | async) === false"
          ></mat-datepicker-toggle>
          <mat-datepicker #archiveDate (closed)="changed = true"></mat-datepicker>
          <input
            [matDatepicker]="archiveDate"
            matInput
            autocomplete="false"
            formControlName="availableUntil"
            (keyup)="changed = true"
            [readonly]="(canManage$ | async) === false"
          />
        </mat-form-field>
      </form>
    </div>
    <greco-collection-video-table *ngIf="loadVideos" [subcollection]="subcollection"></greco-collection-video-table>
  </greco-collapsible-section>
</div>
