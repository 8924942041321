<div id="videos_section" *ngIf="canRead$ | async">
  <ng-container *grecoLet="canUpdate$ | async as canUpdate">
    <ng-container *grecoLet="canDelete$ | async as canDelete">
      <greco-filter-bar
        [hideSeparator]="true"
        [filterOptions]="filterOptions"
        (changed)="filters$.next($event[1]); onFilterApplied()"
      >
        <button
          *ngIf="canUpdate$ | async"
          header
          mat-stroked-button
          color="primary"
          style="height: 32px; line-height: 16px; margin-left: 16px"
          (click)="$event.stopImmediatePropagation(); addVideo()"
        >
          <span>Select Existing</span>
        </button>
        <button
          *ngIf="canUpload$ | async"
          header
          mat-stroked-button
          color="primary"
          style="height: 32px; line-height: 16px; margin-left: 8px"
          (click)="$event.stopImmediatePropagation(); upload()"
        >
          <span>Upload</span>
        </button>
      </greco-filter-bar>
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      <greco-table [highlight]="true" [data]="(videos$ | async) || []" (rowClick)="rowClick($event)">
        <mat-icon *grecoTableCol="''; fitContent: true; let video" [matTooltip]="video.id"> video_settings </mat-icon>

        <!-- Video Title -->
        <ng-container>
          <p *grecoTableCol="'Title'; let video" [matTooltip]="video.video.title">
            {{ video.video.title }}
          </p>
        </ng-container>

        <!-- Date -->
        <ng-container>
          <p *grecoTableCol="'Upload Date'; let video" [matTooltip]="'Upload Date'">
            {{ video.video.created | date }}
          </p>
        </ng-container>
        <!-- Uploader -->
        <ng-container>
          <p *grecoTableCol="'Uploaded By'; let video" [matTooltip]="'Uploaded By'">
            {{ video.video.uploadedBy.displayName }}
          </p>
        </ng-container>

        <!-- Video Duration -->
        <ng-container>
          <p *grecoTableCol="'Duration'; let video" [matTooltip]="'Length of Video'">
            {{ video.video.duration | grecoDuration }}
          </p>
        </ng-container>

        <!-- # of events -->
        <ng-container>
          <p *grecoTableCol="'# of Events'; let video" [matTooltip]="'Number of Events using this video'">
            {{ video.eventVideos?.length || '-' }}
          </p>
        </ng-container>
        <!-- # of programs -->
        <ng-container>
          <p
            *grecoTableCol="'# of Subcollections'; let video"
            [matTooltip]="'Number of Subcollections using this video'"
          >
            {{ video.collectionVideos?.length || '-' }}
          </p>
        </ng-container>
        <!-- # of active unlocks -->
        <ng-container>
          <p *grecoTableCol="'Active Unlocks'; let video" [matTooltip]="'Number of currently active unlocks'">
            {{ video.activeUnlocks?.length || '-' }}
          </p>
        </ng-container>
        <!-- total unlocks -->
        <ng-container>
          <p *grecoTableCol="'Total Unlocks'; let video" [matTooltip]="'Total number of unlocks, active and expired'">
            {{ video.totalUnlocks?.length || '-' }}
          </p>
        </ng-container>

        <!-- Status -->
        <ng-container *grecoTableCol="'Status'; fitContent: true; let video">
          <p [matTooltip]="video.availableUntil ? 'Archived on ' + (video.availableUntil | date) : ''">
            <mat-chip
              *grecoLet="!video.availableUntil || (video.availableUntil | grecoDateCompare : 'gte') as active"
              [class.active]="active === true"
            >
              {{ active === true ? 'Active' : 'Archived' }}
            </mat-chip>
          </p>
        </ng-container>

        <!-- tags -->
        <ng-container>
          <p *grecoTableCol="'Tags'; fitContent: true; let video">
            <greco-video-tag
              *ngFor="let tag of video.video.tags"
              [admin]="true"
              [tag]="tag"
              [community]="video.community"
            ></greco-video-tag>
          </p>
        </ng-container>

        <!-- Options -->
        <ng-container>
          <ng-container *grecoTableCol="''; let video; fitContent: true; stickyEnd: true">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [matTooltip]="'Options for ' + video.video.title"
              (click)="$event.stopImmediatePropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu>
              <!--Watch
          <ng-container *ngIf="canUpload$ || canUpdate$ || canDelete$ | async">
            <button [matTooltip]="'Watch ' + video.video.title" mat-menu-item (click)="watch(video)">
              <mat-icon>slideshow</mat-icon>
              <span>Watch</span>
            </button>
          </ng-container>-->
              <!-- Manage Video -->
              <button mat-menu-item (click)="rowClick(video)">
                <mat-icon>video_settings</mat-icon>
                <span>View Details</span>
              </button>
              <!-- Update Video -->
              <button mat-menu-item (click)="update(video)" *ngIf="canUpdate">
                <mat-icon>upload</mat-icon>
                <span>Update</span>
              </button>

              <!-- Activate/Archive -->
              <ng-container *ngIf="canUpdate">
                <button
                  mat-menu-item
                  (click)="changeStatus(video)"
                  *grecoLet="!video.availableUntil || (video.availableUntil | grecoDateCompare : 'gt') as active"
                >
                  <mat-icon>{{ !active ? 'unarchive' : 'archive' }}</mat-icon>
                  <span>{{ !active ? 'Activate' : 'Archive' }}</span>
                </button></ng-container
              >

              <ng-container>
                <!-- Remove -->
                <ng-container>
                  <button mat-menu-item (click)="remove(video)" *ngIf="canDelete">
                    <mat-icon>delete</mat-icon>
                    <span>Remove</span>
                  </button>
                </ng-container>
              </ng-container>
              <ng-container>
                <!-- Delete 
            <ng-container>
              <button mat-menu-item (click)="delete(video)" *ngIf="this.user?.isSuperAdmin">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </ng-container>-->
              </ng-container>
            </mat-menu>
          </ng-container>
        </ng-container>
      </greco-table>
      <mat-paginator
        *ngIf="pagination?.totalItems"
        showFirstLastButtons
        [length]="pagination!.totalItems || 0"
        [pageSize]="pagination!.itemsPerPage || 10"
        [pageSizeOptions]="[10, 20, 50]"
        (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
      ></mat-paginator></ng-container
  ></ng-container>
</div>
