<div [formGroup]="_form" class="greco-community-video-tags-input-container">
  <mat-chip-list #chipsList>
    <mat-chip
      *ngFor="let tag of value"
      selectable
      [removable]="
        !disabled &&
        (allowLockedTags || !tag.locked) &&
        (allowHiddenTags || !tag.hidden) &&
        tag.communityId === communityId
      "
      (removed)="removeTag(tag)"
    >
      {{ tag.label }}
      <mat-icon
        *ngIf="
          !disabled &&
          (allowLockedTags || !tag.locked) &&
          (allowHiddenTags || !tag.hidden) &&
          tag.communityId === communityId
        "
        matChipRemove
        >cancel</mat-icon
      >
    </mat-chip>

    <input
      #input
      matInput
      type="text"
      [disabled]="disabled"
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      [matChipInputFor]="chipsList"
      (input)="_form.get('search')?.setValue(input.value)"
    />
    <!-- (blur)="touched()" -->

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event); input.value = ''">
      <mat-option *ngFor="let tag of _tags$ | async" [value]="tag">
        {{ tag.label }}
      </mat-option>
    </mat-autocomplete>
  </mat-chip-list>
</div>
