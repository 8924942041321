import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommunityGiftCardConfig, CommunityGiftCardOption } from '@greco/gift-cards';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { Purchase, PurchaseStatus } from '@greco/sales-purchases';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject } from 'rxjs';
import { GiftCardService } from '../../services';

type SelectedOption = CommunityGiftCardOption | 'custom';

@Component({
  selector: 'greco-gift-card-checkout',
  templateUrl: './gift-card-checkout.component.html',
  styleUrls: ['./gift-card-checkout.component.scss'],
})
export class GiftCardCheckoutComponent implements OnInit {
  constructor(
    private giftCardSvc: GiftCardService,
    private router: Router,
    private fb: FormBuilder,
    private snacks: MatSnackBar,
    private userSvc: UserService
  ) {}

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  @Output() purchaseSuccess = new EventEmitter<Purchase>();

  @Input() configs: CommunityGiftCardConfig[] = [];
  @Input() selectedConfig?: CommunityGiftCardConfig | null = null;
  @Input() selectedOption?: SelectedOption | null = null;

  user$ = new BehaviorSubject<User | null>(null);
  @Input() set user(user: User | null) {
    this.user$.next(user);
    // this.giftForm.patchValue({
    //   transferredfromName: user?.displayName || null,
    //   transferredFromEmail: user?.email || null,
    // });
  }
  get user() {
    return this.user$.value;
  }

  @PropertyListener('self') self$ = new BehaviorSubject<User | null>(null);
  @Input() self: User | null = null;

  @Input() isStaff = false;

  loading = false;

  mode: 'self' | 'gift' = 'self';

  overrideTransferredFrom = false;

  today = new Date();

  customAmountControl = new FormControl(0, Validators.required);

  paymentMethodControl = new FormControl(null, Validators.required);

  giftForm = this.fb.group({
    recipientName: [null as string | null, [Validators.required]],
    recipientEmail: [null as string | null, [Validators.required, Validators.email]],
    transferMessage: [null as string | null, [Validators.maxLength(250)]],
    transferredFromEmail: [null as string | null, [Validators.email]],
    transferredFromName: [null as string | null],
  });

  ngOnInit() {
    if (!this.configs?.length) {
      return;
    }

    if (!this.selectedConfig) {
      this.selectedConfig = this.configs[0];
    }

    if (
      !this.selectedOption ||
      (this.selectedOption !== 'custom' && this.selectedOption.configId !== this.selectedConfig.id)
    ) {
      this.selectedOption = this.selectedConfig.options[0];
    }

    const customValidators = [
      Validators.required,
      Validators.min((this.selectedConfig?.minimumCustomAmount || 500) / 100),
    ];
    if (this.selectedConfig?.maximumCustomAmount) {
      customValidators.push(Validators.max(this.selectedConfig.maximumCustomAmount / 100));
    }
    this.customAmountControl = new FormControl(0, customValidators);
  }

  compareOptions(a: SelectedOption, b: SelectedOption) {
    return a === b || (typeof a !== 'string' && typeof b !== 'string' && a.id === b.id);
  }

  async confirm() {
    if (!this.selectedConfig || !this.selectedOption || !this.user) return;

    this.loading = true;

    try {
      const response = await this.giftCardSvc.create({
        communityId: this.selectedConfig.communityId,
        ...(this.mode === 'self'
          ? { recipientId: this.user.id }
          : {
              recipientName: this.giftForm.value.recipientName,
              recipientEmail: this.giftForm.value.recipientEmail,
              transferMessage: this.giftForm.value.transferMessage || null,
              ...(this.overrideTransferredFrom
                ? {
                    transferredFromId: undefined,
                    transferredFromEmail: this.giftForm.value.transferredFromEmail,
                    transferredFromName: this.giftForm.value.transferredFromName,
                  }
                : {
                    transferredFromId: this.user.id,
                  }),
            }),
        purchasedById: this.user.id,
        quantity: 1,
        ...(this.selectedOption === 'custom'
          ? { customAmount: this.customAmountControl.value * 100 }
          : { optionId: this.selectedOption.id }),
        paymentMethodId: this.paymentMethodControl.value?.id,
      });

      console.log('response', response);

      if (
        response.status === PurchaseStatus.PAID ||
        response.status === PurchaseStatus.PENDING ||
        response.status === PurchaseStatus.PROCESSING_PAYMENT
      ) {
        if (response.status === PurchaseStatus.PAID) {
          this.snacks.open(
            'Your purchase was successful! Your gift card will be available on the chosen delivery date',
            'Ok',
            { duration: 2500, panelClass: 'mat-primary' }
          );
        } else {
          this.snacks.open(
            'Your purchase is delayed. If successful, your gift card will be available on the chosen delivery date',
            'Ok',
            { duration: 2500, panelClass: 'mat-primary' }
          );
        }

        this.loading = false;

        this.purchaseSuccess.emit(response);

        if (!this.isStaff) {
          this.router.navigate(['shop']);
        }

        return;
      } else {
        this.snacks.open(response.failureReason || 'Your purchase could not be completed, please try again', 'Ok', {
          duration: 2500,
          panelClass: 'mat-warn',
        });
      }
    } catch (err) {
      console.error(err);
      this.snacks.open('' + (err as any).error.message, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }

    this.loading = false;
  }

  setConfig(config: CommunityGiftCardConfig) {
    if (this.selectedConfig?.id === config.id) {
      return;
    }

    this.selectedConfig = config;
    this.selectedOption = config.options.length ? config.options[0] : config.allowCustomAmount ? 'custom' : null;
    this.customAmountControl.setValue(0);
  }
}
