import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { CreateGiftCardPurchaseDto } from '@greco/api-gift-cards';
import { Purchase } from '@greco/sales-purchases';

@Injectable({ providedIn: 'root' })
export class GiftCardService {
  constructor(private http: HttpClient) {}

  async create(dto: CreateGiftCardPurchaseDto) {
    return await toPromise(this.http.post<Purchase>(`/api/gift-cards/purchase`, dto));
  }
}
