import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoCollection } from '@greco/video-library';

@Component({
  selector: 'greco-browsable-collections-section',
  templateUrl: './browsable-collections-section.component.html',
  styleUrls: ['./browsable-collections-section.component.scss'],
})
export class BrowsableCollectionsSectionComponent {
  constructor(private router: Router, private route: ActivatedRoute) {}
  @Input() disabled = false;
  @Input() title?: string;
  @Input() icon?: string;
  @Input() collections: VideoCollection[] = [];
  @Input() loading = false;
  @Input() canLoadMore = false;
  @Input() total = 0;
  @Output() loadMoreEvent = new EventEmitter<null>();
  @Input() showFeaturedStar = false;
  @Input() userOptions: string[] = [];

  loadMore() {
    this.loadMoreEvent.emit(null);
  }
}
