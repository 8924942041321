<div class="main" *grecoLet="subcollections$ | async as subcollections">
  <div style="display: flex; flex-direction: row; gap: 8px">
    <mat-icon>{{collection.icon || 'video_library'}}</mat-icon>
    <h1 style="width: 100%; margin-bottom: 0">{{collection.title}}</h1>
    <div class="community-info" style="display: flex; align-items: center; font-size: 18px">
      <mat-icon style="line-height: 18px; font-size: 18px; width: 18px; height: 18px; margin-right: 4px"
        >people</mat-icon
      >
      <strong style="white-space: nowrap">{{ collection.community?.name }}</strong>
    </div>
  </div>
  <p style="margin-bottom: 24px; margin-top: 8px">{{collection.description}}</p>
  <div *grecoLet="unlockOptions$ | async as unlockOptions">
    <div *ngFor="let sub of data; let last = last; let index = index" style="margin-bottom: 16px">
      <greco-collapsible-section
        [header]="{title: sub.title, icon: sub.icon || 'playlist_play'}"
        [expanded]="selectedIndex === index"
        (changed)="selectedIndex = $event ? index : -1"
      >
        <mat-chip class="video-count" header *ngIf="subViewer.total !== 999">{{subViewer.total || 0}} videos</mat-chip>
        <greco-user-subcollection-viewer
          #subViewer
          [subcollection]="sub"
          [unlockOptions]="unlockOptions || []"
          [user]="user"
          [last]="last"
        ></greco-user-subcollection-viewer>
      </greco-collapsible-section>
    </div>
  </div>
  <div #load id="load">
    <button
      *ngIf="this.total > this.data.length"
      mat-stroked-button
      color="primary"
      (click)="loadMore()"
      style="width: 100%"
    >
      Load more
    </button>
  </div>
</div>
