<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form">
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" />
    </mat-form-field>

    <mat-form-field appearance="standard" style="width: 100%; margin-bottom: -16px">
      <mat-label>Sale Categories</mat-label>
      <mat-select multiple formControlName="saleCategories">
        <mat-option *ngFor="let saleCategory of saleCategories$ | async" [value]="saleCategory">
          {{ saleCategory.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox color="primary" formControlName="includeUncategorized" style="margin-bottom: 8px">
      Include Uncategorized Products
    </mat-checkbox>

    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Default User</mat-label>
      <greco-contact-picker formControlName="contact" [communityId]="communityId"></greco-contact-picker>
    </mat-form-field>
  </form>
</greco-simple-dialog>
