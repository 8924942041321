<greco-simple-dialog [data]="dialogData" style="width: 800px; max-width: 90%">
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column">
    <mat-form-field>
      <mat-label>Reply</mat-label>
      <textarea matInput required formControlName="note"></textarea>
    </mat-form-field>
  </form>
</greco-simple-dialog>

<div class="dialog-buttons">
  <button mat-button [disabled]="saveCalled" (click)="cancel()">Cancel</button>
  <button mat-button [disabled]="formGroup.invalid || saveCalled" (click)="save()">
    <mat-icon *ngIf="saveCalled" class="spin">loop</mat-icon> Save
  </button>
</div>
