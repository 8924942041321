/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { CommunityVideo } from '@greco/community-videos';
import { Community } from '@greco/identity-communities';
import { VideoSourceRequestDto } from '@greco/videos';
import { BehaviorSubject } from 'rxjs';

@Component({
  templateUrl: './select-videos.dialog.html',
  styleUrls: ['./select-videos.dialog.scss'],
})
export class SelectVideosDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { community: Community; sources: string[] },
    public dialogRef: MatDialogRef<SelectVideosDialog>
  ) {}

  dialogData: DialogData = {
    title: 'Select Videos to Add',
    showCloseButton: true,
  };

  selected$ = new BehaviorSubject<VideoSourceRequestDto[] | null>(null);

  confirmed(items: VideoSourceRequestDto[] | CommunityVideo[]) {
    this.dialogRef.close(items);
  }
}
