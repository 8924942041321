import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { DialogData } from '@greco-fit/scaffolding';
import { CalendarEvent } from '@greco/booking-events';
import { CommunityVideo } from '@greco/community-videos';
import { EventService } from '@greco/ngx-booking-events';
import { CommunityVideosService } from '@greco/ngx-community-videos';
import { VideoTagService, WatchVideoComponent } from '@greco/ngx-videos';
import { PropertyListener } from '@greco/property-listener-util';
import { VideoTag } from '@greco/videos';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EventVideosService } from '../../services';
@Component({
  selector: 'greco-add-event-video-search',
  templateUrl: './add-event-video-search.component.html',
  styleUrls: ['./add-event-video-search.component.scss'],
})
export class AddEventVideoSearchComponent {
  @PropertyListener('event') private _event$ = new ReplaySubject<CalendarEvent>();
  @Input() event?: CalendarEvent;

  tagSearch = '';
  pagination?: IPaginationMeta;
  now: number = Date.now();
  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});
  refresh$ = new BehaviorSubject(null);
  tagOptions$: Observable<VideoTag[]> = this._event$.pipe(switchMap(() => this.tagSvc.getTags()));
  videos$: Observable<CommunityVideo[]> = combineLatest([this._event$, this.refresh$]).pipe(
    switchMap(async ([event]) => {
      const eventVideos = await this.eventVideosSvc.getEventVideosByEvent(event.id);
      const communityVideos = await this.communityVideosSvc.getCommunityVideosByCommunity(event.community.id);
      return this.filterVideos(communityVideos).filter(
        comVid => !eventVideos.some((e: any) => e.communityVideoId === comVid.id)
      );
    })
  );
  dialogData: DialogData = {
    title: 'Add a Video',
    showCloseButton: true,
  };
  form = this.formBuilder.group({
    title: [''],
    tags: [[]],
    spacer: [null],
  });
  constructor(
    private communityVideosSvc: CommunityVideosService,
    private matDialog: MatDialog,
    private eventSvc: EventService,
    private dialogRef: MatDialogRef<AddEventVideoSearchComponent>,
    private tagSvc: VideoTagService,
    private formBuilder: FormBuilder,
    private eventVideosSvc: EventVideosService
  ) {}

  filterVideos(videos: CommunityVideo[]): CommunityVideo[] {
    let filteredVideos = videos;
    const titleFilter: string = this.form.get('title')?.value || '';
    const tagsFilter: VideoTag[] = this.form.get('tags')?.value || [];
    if (titleFilter)
      filteredVideos = filteredVideos.filter(video =>
        video.video?.title.toLowerCase().includes(titleFilter.toLowerCase())
      );
    if (tagsFilter.length) {
      const foo: CommunityVideo[] = [];
      filteredVideos.forEach(video =>
        tagsFilter.forEach(tag => {
          if (video.video?.tags?.some(t => t.id === tag.id) && !foo.includes(video)) foo.push(video);
        })
      );
      return foo;
    }
    return filteredVideos;
  }
  watch(video: CommunityVideo) {
    if (video.video) {
      const dialog = this.matDialog.open(WatchVideoComponent, { data: {}, width: '100%', maxWidth: '50%' });
      const instance = dialog.componentInstance;
      instance.video = video.video;
    }
  }

  async addVideo(video: CommunityVideo) {
    if (this.event) {
      await this.eventVideosSvc.linkCommunityVideoToEvent(this.event?.id, video.id);
      this.dialogRef.close();
    }
  }
  filterTags(tag: VideoTag): boolean {
    if (this.tagSearch)
      return (
        tag.label.toLowerCase().includes(this.tagSearch.toLowerCase()) ||
        (this.form.get('tags')?.value as VideoTag[]).some(t => t.id === tag.id)
      );
    else return true;
  }

  onKey() {
    this.tagSearch = (document.getElementById('tagSearch') as HTMLInputElement)?.value || '';
    this.refresh$.next(null);
  }
}
