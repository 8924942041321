import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { PaymentMethod } from '@greco/finance-payments';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { Subscription, SubscriptionStatus } from '@greco/sales-subscriptions';
import { Subscription as RxjsSubscription } from 'rxjs';
import { SubscriptionScheduleDialog, UpdateSubscribedByDialog } from '../../dialogs';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-subscription-info-section',
  templateUrl: './info-section.component.html',
  styleUrls: ['./info-section.component.scss'],
})
export class SubscriptionInfoSectionComponent implements OnInit {
  constructor(
    private subscriptionSvc: SubscriptionsService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private userSvc: UserService
  ) {}

  @Input() subscription?: Subscription | null;

  @Input() subscriptionInfo!: TemplateRef<any>;

  @Output() subscriptionChanged = new EventEmitter<Subscription>();

  @Input() communityId?: string | null;

  @Input() canCancel?: boolean | null;

  @Input() disabled?: boolean | null;

  @Input() hidePaymentMethod?: boolean = false;

  @Input() canMakeChange?: boolean = false;

  @Input() expanded = true;

  allowBankPayments = false;

  isNotPurchaser = true;

  _paymentMethodControl = new FormControl(null, Validators.required);
  private paymentMethodSub?: RxjsSubscription;

  @PropertyListener('subscription')
  private _updatePaymentMethodControl() {
    this.paymentMethodSub?.unsubscribe();
    this._paymentMethodControl.reset(this.subscription?.paymentMethod);

    if (this.subscription) {
      this.paymentMethodSub = this._paymentMethodControl.valueChanges.subscribe((paymentMethod?: PaymentMethod) => {
        if (paymentMethod) this.updatePaymentMethod(paymentMethod);
      });
      this.allowBankPayments = this.updateAllowBankPayments();
    } else this.allowBankPayments = false;
  }

  async checkSubscriptionSubscribedBy() {
    if (this.subscription) {
      const currentUser = await this.userSvc.getSelf();
      return !(
        currentUser?.id === this.subscription.userId && this.subscription.userId !== this.subscription.subscribedById
      );
    }
    return false;
  }

  private async updatePaymentMethod(paymentMethod: PaymentMethod | null) {
    if (!paymentMethod?.id || !this.subscription) return;

    const isSame = this.subscription.paymentMethod?.id === paymentMethod.id;
    if (isSame || this.subscription.status !== SubscriptionStatus.ACTIVE) return;

    try {
      this.subscription = await this.subscriptionSvc.updatePaymentMethod(this.subscription.id, paymentMethod.id);

      this.snacks.open('Payment Method Updated!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
      this.subscriptionChanged.emit(this.subscription);
    } catch (err) {
      console.error(err);
      this.snacks.open('Oops something went wrong. Please try again later.', 'Ok', {
        duration: 5000,
        panelClass: 'mat-warn',
      });
    }
  }

  updateAllowBankPayments() {
    return this.subscription?.paymentTypes ? this.subscription.paymentTypes.some(pt => pt === 'bank') : false;
  }

  async viewSubscriptionSchedule() {
    if (!this.subscription) return;
    const dialog = this.matDialog.open(SubscriptionScheduleDialog, {
      data: { subscription: this.subscription, communityId: this.communityId },
    });
    await toPromise(dialog.afterClosed());
  }

  async assumeDialog() {
    if (!this.subscription) return;
    const dialog = this.matDialog.open(UpdateSubscribedByDialog, {
      data: {
        subscriptionId: this.subscription.id,
        userId: this.subscription.userId,
      },
    });
    const result = await toPromise(dialog.afterClosed());
    if (result) {
      this.subscription = result;
      this.subscriptionChanged.emit(result);
    }
  }

  async ngOnInit() {
    this.isNotPurchaser = !(await this.checkSubscriptionSubscribedBy());
  }
}
