import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BookingOptionAgreementUsage, EventAgreementUsage } from '@greco/booking-events';
import { AgreementType } from '@greco/community-agreements';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'alt-agreements-requirement',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss'],
})
export class AgreementsRequirementComponent implements OnInit {
  constructor(public eventSvc: EventService, private formGroup: FormBuilder, public bottomSheet: MatBottomSheet) {}

  @PropertyListener('agreements') agreements$ = new BehaviorSubject<EventAgreementUsage[]>([]);
  @Input() agreements: EventAgreementUsage[] = [];

  @PropertyListener('bookingOptionAgreements') bookingOptionAgreements$ = new BehaviorSubject<
    BookingOptionAgreementUsage[]
  >([]);
  @Input() bookingOptionAgreements: BookingOptionAgreementUsage[] = [];

  @PropertyListener('user') user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User;

  @PropertyListener('canLeaveUnsigned') canLeaveUnsigned$ = new BehaviorSubject<boolean>(false);
  @Input() canLeaveUnsigned = false;

  @Input() readonly = false;

  @Output() bottomSheetOpened = new EventEmitter<TemplateRef<any>>();
  @Output() signaturesUpdated = new EventEmitter<{ agreementId: string; signature: string }[]>();

  loading = false;
  loaded = false;
  hideSignLater = false;

  agreementsForm = this.formGroup.group({});
  controlsConfigDetails: { [key: string]: any } = {};
  signatures: { agreementId: string; signature: string }[] = [];

  combinedAgreements$ = combineLatest([this.agreements$, this.bookingOptionAgreements$]).pipe(
    tap(([_, bookingOptionAgreements]) => {
      bookingOptionAgreements.forEach(agreement => {
        const index = this.eventSvc.agreementSubmissions$.value.findIndex(
          data => data.agreementId === agreement.agreementId && data.userId === this.user.id
        );

        let value = false;
        if (index !== -1) value = this.eventSvc.agreementSubmissions$.value[index].signed;

        this.controlsConfigDetails[`${agreement.id}`] = [value, Validators.requiredTrue];
      });

      this.agreementsForm = this.formGroup.group({ ...this.controlsConfigDetails });
    }),
    map(([agreements, bookingOptionAgreements]) => [
      ...agreements.filter(a0 => !bookingOptionAgreements.some(a1 => a0.agreementId === a1.agreementId)),
      ...bookingOptionAgreements.filter(agreement => (agreement as any)?.userId === this.user.id),
    ])
  );

  canContinueWithoutSigning$ = combineLatest([
    this.user$,
    this.eventSvc.accounts$,
    this.combinedAgreements$,
    this.canLeaveUnsigned$,
  ]).pipe(
    switchMap(async ([bookedFor, accounts, agreements, canLeaveUnsigned]) => {
      const controls = this.agreementsForm.controls;

      if (canLeaveUnsigned) {
        Object.keys(controls).forEach(control => {
          const agreementId = agreements.find(usage => usage.id === control)?.agreementId;
          if (agreementId) this.setAgreementRequired(control, agreementId, false);
        });

        return true;
      } else {
        if (!bookedFor) return false;
        const bookedBy = accounts[0];

        if (bookedFor.id !== bookedBy.user.id && bookedFor.contactEmail !== bookedBy.user.contactEmail) {
          Object.keys(controls).forEach(control => {
            const agreementId = agreements.find(usage => usage.id === control)?.agreementId;
            if (agreementId) this.setAgreementRequired(control, agreementId, false);
          });

          this.hideSignLater = true;
        } else {
          Object.keys(controls).forEach(control => {
            const agreementId = agreements.find(usage => usage.id === control)?.agreementId;
            if (agreementId) this.setAgreementRequired(control, agreementId, true);
          });
        }

        const ownBooking = accounts.map(acc => acc.user.id).includes(bookedFor?.id || '');
        if (bookedFor?.id !== bookedBy.user.id && ownBooking) return true;

        return false;
      }
    })
  );

  ngOnInit(): void {
    [
      ...this.agreements,
      ...this.bookingOptionAgreements.filter(agreement => (agreement as any)?.userId === this.user.id),
    ].forEach(agreement => {
      const index = this.eventSvc.agreementSubmissions$.value.findIndex(
        data => data.agreementId === agreement.agreementId && data.userId === this.user.id
      );

      let value = false;

      if (index !== -1) {
        value = this.eventSvc.agreementSubmissions$.value[index].signed;
      } else {
        switch (agreement.agreement?.agreementType) {
          case AgreementType.AUTO_CHECKBOX: {
            value = true;
            this.eventSvc.agreementSubmissions$.value.push({
              userId: this.user.id,
              agreementId: agreement.agreementId,
              signed: true,
            });
            break;
          }
          case AgreementType.CHECKBOX: {
            value = false;
            this.eventSvc.agreementSubmissions$.value.push({
              userId: this.user.id,
              agreementId: agreement.agreementId,
              signed: false,
            });
            break;
          }
          case AgreementType.DIGITAL_SIGNATURE: {
            value = false;
            this.eventSvc.agreementSubmissions$.value.push({
              userId: this.user.id,
              agreementId: agreement.agreementId,
              signed: false,
            });
            break;
          }
        }
      }

      this.controlsConfigDetails[`${agreement.id}`] = [value, Validators.requiredTrue];
    });

    this.loaded = true;
    this.agreementsForm = this.formGroup.group({ ...this.controlsConfigDetails });
    this.eventSvc.agreementSubmissions$.next(this.eventSvc.agreementSubmissions$.value);
  }

  setCheck(usageId: string, agreementId: string) {
    const checked = this.agreementsForm.get(usageId)?.value;
    const index = this.eventSvc.agreementSubmissions$.value.findIndex(
      agreement => agreement.agreementId == agreementId && agreement.userId == this.user.id
    );
    this.agreementsForm.patchValue({ [`${usageId}`]: !checked });
    this.eventSvc.agreementSubmissions$.value[index] = { userId: this.user.id, agreementId, signed: !checked };
    this.eventSvc.agreementSubmissions$.next(this.eventSvc.agreementSubmissions$.value);
  }

  setSignature(signature: string, usageId: string, agreementId: string) {
    const index = this.eventSvc.agreementSubmissions$.value.findIndex(
      agreement => agreement.agreementId == agreementId && agreement.userId == this.user.id
    );

    const linkedSignature = this.signatures.findIndex(signature => signature.agreementId === agreementId);
    if (linkedSignature !== -1) this.signatures[linkedSignature] = { agreementId, signature };
    else this.signatures.push({ agreementId, signature });

    if (signature !== '') {
      this.agreementsForm.patchValue({ [`${usageId}`]: true });
      this.eventSvc.agreementSubmissions$.value[index] = { userId: this.user.id, agreementId, signed: true, signature };
    } else {
      this.agreementsForm.patchValue({ [`${usageId}`]: false });
      this.eventSvc.agreementSubmissions$.value[index] = {
        userId: this.user.id,
        agreementId,
        signed: false,
        signature: undefined,
      };
    }
    this.eventSvc.agreementSubmissions$.next(this.eventSvc.agreementSubmissions$.value);
    this.signaturesUpdated.emit(this.signatures);
  }

  setAgreementRequired(usageId: string, agreementId: string, required: boolean) {
    const index = this.eventSvc.agreementSubmissions$.value.findIndex(
      agreement => agreement.agreementId == agreementId && agreement.userId == this.user.id
    );

    const control = this.agreementsForm.controls[usageId];
    const agreementSubmission = this.eventSvc.agreementSubmissions$.value[index];

    if (required) {
      control.setValidators(Validators.requiredTrue);
      if (agreementSubmission) agreementSubmission.unsigned = false;
    } else {
      if (agreementSubmission) agreementSubmission.unsigned = true;
      control.setErrors(null);
      control.clearValidators();
    }

    this.eventSvc.agreementSubmissions$.next(this.eventSvc.agreementSubmissions$.value);
  }

  emitBottomSheetOpened(bottomSheetRef: TemplateRef<any>) {
    this.bottomSheetOpened.emit(bottomSheetRef);
  }
}
