<ng-container *grecoLet="dashboardFavoritesSvc.entries$ | async as entries">
  <div *ngIf="entries?.length; else noFavorites" class="favorites">
    <div>
      <h2>Your Favourites</h2>
      <a mat-button color="accent"></a>
    </div>

    <div class="favorite-grid">
      <a
        *ngFor="let favourite of entries"
        class="favorite"
        [href]="favourite[1].url ? favourite[1].url : 'workouts?tags=' + favourite[1].tags?.join(',')"
      >
        <img [src]="favourite[1].imageUrl || 'assets/greco/placeholder.png'" />

        <p>
          <strong> {{ favourite[1].title }} </strong>
        </p>

        <button
          class="favourite-button favorite-heart"
          mat-icon-button
          (click)="$event.preventDefault(); dashboardFavoritesSvc.unfavorite(favourite[0])"
        >
          <mat-icon>favorite</mat-icon>
        </button>
      </a>
    </div>
  </div>

  <ng-template #noFavorites>
    <div class="no-favorites"></div>
  </ng-template>
</ng-container>
