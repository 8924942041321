import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryEventsByDateDto } from '@greco/nestjs-booking-events';
import { CalendarEvent } from 'calendar-utils';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { EventService } from '../../services';
import { ResourceSubstitutionFilterType } from '../events/filters';

@Component({
  selector: 'greco-schedule-page',
  templateUrl: './schedule.page.html',
  styleUrls: ['./schedule.page.scss'],
})
export class SchedulePage implements OnInit, OnDestroy {
  constructor(private router: Router, private route: ActivatedRoute, private eventSvc: EventService) {}

  private _onDestroy$ = new Subject();

  private _refresh$ = new BehaviorSubject(null);
  // get filters from route
  filters$ = new BehaviorSubject<QueryEventsByDateDto | null>(null);

  filters: QueryEventsByDateDto = {
    startDate: new Date(),
    endDate: moment(new Date().setDate(new Date().getDate() + 14))
      .endOf('day')
      .toDate(),
  };

  // get all events according to filters
  events: CalendarEvent[] = [];
  eventsAndDates$ = combineLatest([this.filters$, this._refresh$]).pipe(
    switchMap(async ([filters, _refresh]) => {
      if (filters) return await this.eventSvc.getEventsForSchedule(filters);
      else return [];
    }),
    map(events => events.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())),
    map(events => events.filter(event => event.startDate.getTime() > new Date().getTime())),
    map(events => {
      const dates: Date[] = [];
      events?.forEach(event => {
        const date = new Date(moment(event.startDate).startOf('day').toString());
        if (!dates.some(d => d.toString() === date.toString())) dates.push(date);
      });

      return { dates, events };
    })
  );

  setFilter(filter: string) {
    const data = filter.split(']||[');
    const type = data[0].substring(1);
    const value = data[2].substring(0, data[2].length - 1);

    switch (type) {
      case 'IncludePrivateFilter':
        this.filters.includePrivate = value === 'true' ? true : false;
        break;

      case 'ResourceFilter':
        this.filters.resources = value.split(',');
        break;

      case 'EventTitleFilter':
        this.filters.title = value;
        break;

      case 'EventDescriptionFilter':
        this.filters.description = value;
        break;

      case 'ResourceSubstitutionFilter':
        this.filters.eventHasConfirmedResource = value
          .split(',')
          .includes(ResourceSubstitutionFilterType.RESOURCE_CONFIRMED);
        this.filters.eventNeedsSubstitution = value
          .split(',')
          .includes(ResourceSubstitutionFilterType.NEEDS_SUBSTITUTION);
        this.filters.eventNeedsResource = value.split(',').includes(ResourceSubstitutionFilterType.NEEDS_RESOURCE);
        break;

      case 'IncludeOpenEventsFilter':
        this.filters.includeOpenEvents = value === 'true' ? true : false;
        break;

      case 'ResourceTagIdsFilter':
        this.filters.resourceTagIds = value.split(',');
        break;

      case 'TagsSelectFilter':
        this.filters.tags = value.split(',');
        break;

      case 'IncludeCourseFilter':
        this.filters.includeCourse = value === 'true' ? true : false;
        break;

      case 'EventSearchFilter':
        this.filters.search = value;
        break;
    }
  }

  ngOnInit(): void {
    const communityId = 'com_' + this.router.url.split('com_')[1].split('?')[0];
    this.route.queryParams.subscribe(params => {
      if (params.filters) {
        if (typeof params.filters === 'string') {
          this.setFilter(params.filters);
        } else {
          (params.filters as string[]).forEach(filter => {
            this.setFilter(filter);
          });
        }
      }

      this.filters.showCourseInstances = true;
      this.filters.includeCourse = true;
      this.filters.calendars = params.calendarIds?.split(',') ?? [];
      this.filters.communities = [communityId];
      this.filters$.next(this.filters);
    });

    this.eventsAndDates$.pipe(startWith(null), takeUntil(this._onDestroy$)).subscribe(async () => {
      // await new Promise(res => setTimeout(res, 30000));
      // this._refresh$.next(null);
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
