<form [formGroup]="_form">
  <div class="fields-section">
    <h3>Station Details</h3>
    <div class="color-and-title">
      <!-- Title -->
      <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
        <mat-label>Title</mat-label>
        <input matInput type="text" [required]="required" formControlName="title" />
      </mat-form-field>
    </div>
  </div>
</form>
