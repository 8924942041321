<greco-alert
  [config]="{
    title:
      'Outstanding Payment' +
      (outstandingPurchase.user.id !== outstandingPurchase.purchasedById
        ? ' (For ' + outstandingPurchase.user.displayName + ')'
        : ''),
    type: $any('DANGER'),
    description:
      (outstandingPurchase.total / 100 | currency) +
      ' ' +
      (outstandingPurchase.items | pluck : 'displayName').join(', ') +
      ' (' +
      (outstandingPurchase.created | date : 'MMM d, y, h:mm a') +
      ')'
  }"
>
  <div slot="buttons" style="display: flex; gap: 16px; height: 32px">
    <button
      *ngIf="!userSide"
      mat-stroked-button
      color="primary"
      style="background-color: white"
      (click)="sendPurchaseFailedEmail(outstandingPurchase.id)"
    >
      <span>Send Email</span>
    </button>

    <button *ngIf="!userSide" mat-flat-button color="primary" [matMenuTriggerFor]="processMenu">
      <span>Process Now</span>
      <mat-icon>expand_more</mat-icon>
    </button>

    <button
      *ngIf="userSide"
      mat-flat-button
      color="warn"
      style="height: 48px"
      (click)="retryFailedPurchase(outstandingPurchase)"
    >
      <mat-icon>refresh</mat-icon>
      <span>Retry</span>
    </button>

    <mat-menu #processMenu="matMenu" xPosition="before">
      <button
        mat-menu-item
        style="height: 48px"
        (click)="retryFailedPurchase(outstandingPurchase)"
        *ngIf="canRetry$ | async"
      >
        <mat-icon>refresh</mat-icon>
        <span>Retry</span>
      </button>

      <button mat-menu-item style="height: 48px" (click)="voidPurchase(outstandingPurchase)" *ngIf="canVoid$ | async">
        <mat-icon>block</mat-icon>
        <span>Void</span>
      </button>
    </mat-menu>
  </div>
</greco-alert>
