<greco-simple-dialog [data]="dialogData">
  <div id="formDiv" name="formDiv">
    <form [formGroup]="form">
      <!-- Title -->
      <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
        <mat-label>Title</mat-label>
        <input matInput required formControlName="title" placeholder="Video Title" />
      </mat-form-field>

      <!-- file -->
      <mat-form-field *ngIf="!video" floatLabel="always" appearance="standard" style="width: 100%">
        <mat-label>Video File</mat-label>
        <button matSuffix mat-flat-button color="white" style="height: 32px" (click)="handleClick()">Get a file</button>
        <input
          readonly="true"
          matInput
          required
          id="upload-name"
          name="upload-name"
          formControlName="fileName"
          placeholder="Upload a video file"
        />
        <input
          class="hidden"
          type="file"
          id="upload-file"
          name="upload-file"
          formControlName="path"
          accept=".mp4, .mov, .wmv, .avi, .flv"
          (change)="addAttachment($event)"
        />
      </mat-form-field>

      <!-- Tags -->
      <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
        <mat-label>Tags</mat-label>
        <greco-community-video-tags-input
          #thisguy
          formControlName="tags"
          [communityId]="communityId"
        ></greco-community-video-tags-input>

        <button
          matSuffix
          mat-icon-button
          type="button"
          color="primary"
          *ngIf="thisguy.currentSearchValue"
          (click)="$event.stopImmediatePropagation(); newTag(thisguy.currentSearchValue)"
          matTooltip="Create new tag"
        >
          <mat-icon>new_label</mat-icon>
        </button>
      </mat-form-field>

      <!--Publish Date-->
      <mat-form-field
        *ngIf="!hideAvailableOnInput"
        [ngClass]="video ? '': 'hidden'"
        floatLabel="always"
        appearance="standard"
        style="width: 100%"
      >
        <mat-label>Publish Date</mat-label>
        <mat-datepicker-toggle [for]="publishDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #publishDate></mat-datepicker>
        <input [matDatepicker]="publishDate" matInput autocomplete="false" formControlName="availableOn" />
      </mat-form-field>

      <!-- Description -->
      <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" rows="3"></textarea>
      </mat-form-field>

      <!-- Source -->
      <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
        <mat-label>Host</mat-label>
        <mat-select matInput formControlName="source" panelClass="myPanelClass" [disabled]="true"
          ><!--When there are multiple sources, set to disabled on update only-->
          <mat-option value="vimeo">Vimeo</mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div style="display: flex; flex-direction: row; justify-content: space-evenly">
      <button mat-flat-button color="primary" (click)="submit()" [innerHtml]="video ? 'Update' : 'Upload'"></button>
      <button matSuffix mat-flat-button (click)="close()" [innerHtml]="'Cancel'"></button>
    </div>
  </div>
</greco-simple-dialog>
