import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FiltersModule } from '@greco/ngx-filters';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { JoinPipeModule } from '@greco/ngx-join-pipe';
import { LetModule } from '@greco/ngx-let-util';
import { PerksModule } from '@greco/ngx-sales-perks';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { VideosModule } from '@greco/ngx-videos';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { IconPickerModule } from '@greco/ui-icon-picker';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { SmartImgModule } from '@greco/ui-smart-img';
import { TableModule } from '@greco/ui-table';
import { NgxCurrencyModule } from 'ngx-currency';
import { SafePipeModule } from 'safe-pipe';
import {
  AdminCommunityVideoUnlockOptionPage,
  AdminCommunityVideoUnlockOptionsPage,
  CommunityVideoAdminTableComponent,
  CommunityVideoTagsInputComponent,
  CommunityVideoUnlockTableComponent,
  SelectCommunityVideosComponent,
} from './components';
import { MinutesInputComponent } from './components/minutes-input';
import { VideoUnlocksComponent } from './components/video-unlocks/video-unlocks.component';
import {
  CreateCommunityVideoUnlockOptionDialog,
  ManageUnlockDialog,
  SelectVideosDialog,
  VideoUploadDialog,
} from './dialogs';
import { CommunityVideoAdminPage } from './pages';
import { VideoUnlocksPage } from './pages/video-unlocks/video-unlocks.page';
import { MinutesPipe } from './pipes';
import {
  CommunityVideosService,
  CommunityVideoTagsService,
  CommunityVideoUnlockOptionsService,
  CommunityVideoUnlockService,
} from './services';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxCurrencyModule,

    // Angular Material
    RouterModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,

    SafePipeModule,

    // Greco
    LetModule,
    BoxModule,
    PerksModule,
    TableModule,
    VideosModule,
    FiltersModule,
    JoinPipeModule,
    SmartImgModule,
    PaymentsModule,
    PurchasesModule,
    SimpleDialogModule,
    FormSaveButtonsModule,
    CollapsibleSectionModule,
    IconPickerModule,
  ],
  providers: [
    CommunityVideosService,
    CommunityVideoUnlockService,
    CommunityVideoTagsService,
    CommunityVideoUnlockOptionsService,
  ],
  declarations: [
    CommunityVideoAdminTableComponent,
    CommunityVideoAdminPage,
    CommunityVideoUnlockTableComponent,
    ManageUnlockDialog,
    SelectVideosDialog,
    SelectCommunityVideosComponent,
    CommunityVideoTagsInputComponent,
    VideoUploadDialog,
    AdminCommunityVideoUnlockOptionsPage,
    AdminCommunityVideoUnlockOptionPage,
    MinutesPipe,
    CreateCommunityVideoUnlockOptionDialog,
    MinutesInputComponent,
    VideoUnlocksPage,
    VideoUnlocksComponent,
  ],
  exports: [
    CommunityVideoAdminTableComponent,
    CommunityVideoAdminPage,
    CommunityVideoUnlockTableComponent,
    SelectVideosDialog,
    CommunityVideoTagsInputComponent,
    VideoUploadDialog,
    AdminCommunityVideoUnlockOptionsPage,
    AdminCommunityVideoUnlockOptionPage,
    CommunityVideoTagsInputComponent,
    VideoUnlocksPage,
  ],
})
export class GrecoIdentityFeatureCommunityVideosModule {}
